import React, { Component, useState } from "react";
import { Slider } from "react-semantic-ui-range";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
//import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Sanarheader";
import PatientFooter from "../patient/Patientfooter";
import Diagnosticheader from "../patient/Diagnosticheader";
import { Dropdown } from "semantic-ui-react";
import { Multiselect } from "multiselect-react-dropdown";
import * as SecureLS from "secure-ls";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientGetLocations from "../patient/PatientGetLocaitons";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class Diagnosticsupload extends Component {
  constructor(props) {
    super(props);
    //const appointmentId = localStorage.getItem("appointment_id");
    const appointmentId = ls.get("appointmentIdEnc");
    this.state = {
      cartItemsCount: "0",
      cartDetailsPopup: "",
      isParentChecked: true,
      isDisabled: false,
      disabledRequired: false,
      fields: {},
      errors: {},
      patientId: "",
      medicineOptions: "",
      medicineProductsView: "",
      home: "collapse-show",
      searchproductslist: "collapse-hide",
      remove_icon: "remove_icon collapse-hide clear_icon",
      search_address: "",
      detected_address: "",
      cartId: "",
      cartDetailsPopup: "",
      cartDetailsPage: "",
      cartDetailsPageSection: "collapse-hide",
      mostPopularTests: "",
      popularTests: [],
      suggestedProducts: "",
      ordersView: "",
      manufacturers: "",
      brandOptions: [],
      formOptions: [],
      downloadSection: "collapse-show",
    };
    this.handleChange = this.handleChange.bind(this);
    this.fetchmedicinedata = this.fetchmedicinedata.bind(this);
  }

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");

    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        // console.log("notnull " + this.state.Language);
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
      //console.log(this.state.Language);
    }
  };

  // To get detais after first render
  componentDidMount = () => {
    this.getCurrentLocation();
    const { handle } = this.props.match.params;
    let userData = "";
    let savedmedicinesRedirect = "";
    userData = ls.get("userObjEnc");
    let cartData = "";

    cartData = ls.get("cartIdEnc");
    this.setState({
      isDisabled: false,
      disabledRequired: false,
    });
    var retrievedObject = ls.get("userObjEnc");
    if (cartData != "") {
      this.state.cartId = cartData;
      this.forceUpdate();
    }
    if (userData) {
      if (Object.keys(userData).length > 0) {
        this.state.patientId = userData.patient_id;
        console.log("--112--", this.state.patientId);
        this.forceUpdate();
        //this.getLasterOrderDetails(userData.patient_id);
      }
    } else {
    }

    savedmedicinesRedirect = localStorage.getItem("savedMedicinesRedirect");
    if (savedmedicinesRedirect == 1) {
      this.miniCart();

      localStorage.removeItem("savedMedicinesRedirect");
    }

    this.mostPopularTests(this.state.patientId);
    this.getCartDetails();

    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCuMJ3dhADqNoE4tGuWTI3_NlwBihj5BtE&callback=initMap";
    document.head.appendChild(script);
  };
  handleAddressChange = (search_address) => {
    this.setState({ search_address });
  };

  // For set the latlong for address

  setSearchAddressLatLong = (latLng) => {
    let search_address_latitude = latLng.lat;
    let search_address_longitude = latLng.lng;
    this.setState({
      search_latitude: search_address_latitude,
      search_longitude: search_address_longitude,
    });
    ls.set("search_latitudeEnc", search_address_latitude);
    ls.set("search_longitudeEnc", search_address_longitude);

    if (this.state.detected_address) {
    }
  };

  // For address select
  handleAddressSelect = (search_address) => {
    geocodeByAddress(search_address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => this.setSearchAddressLatLong(latLng))
      .catch((error) => console.error("Error", error));
  };

  //get current location
  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var url =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&key=AIzaSyDf9nNe-DZ5ICYu1tIPqRVfOaAMz16mNfw";
        $.getJSON(url, function (data, textStatus) {
          if (data.results.length > 0) {
            var streetaddress = data.results[0].formatted_address;
            ls.set("detected_addressEnc", streetaddress);
          }
        });
      });

      let detected_address = ls.get("detected_addressEnc");
      if (detected_address) {
        this.setState({ detected_address: detected_address });
        this.handleAddressSelect(this.state.detected_address);
        setTimeout(function () {
          ls.remove("detected_addressEnc");
        }, 3000);
      }
    }
  };
  // select retailer
  selectRetailer = () => {
    let patientId = this.state.patientId;
    if (patientId) {
      window.location.href = "./Diagnosticcart";
    }
  };

  // Redirec to login page

  loginRedirect = () => {
    localStorage.setItem("ordermedicineredirect", "1");
    window.location.href = "./PatientLogin";
  };

  //  fetch master medicine data on search
  fetchmedicinedata(event) {
    let search = event.target.value;
    let medicinesList = [];
    if (search.length > 0) {
      this.state.remove_icon = "remove_icon collapse-show";
      this.forceUpdate();
    } else {
      this.state.remove_icon = "remove_icon collapse-remove_icon";
      this.forceUpdate();
    }

    if (search.length > 2) {
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/MedicineDetails/MedicineSearchDetails",
        { medicine_name: search, patient_id: this.state.patientId }
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.result).length;
        this.state.search = search;
        if (Object.keys(response.data.result).length > 0) {
          const medicineDetailsView = response.data.result.map(
            (finalLoadedData, num) => {
              return (
                <li id={finalLoadedData.id} onClick={this.handleChange}>
                  {" "}
                  {finalLoadedData.medicinename +
                    " " +
                    finalLoadedData.strength +
                    " " +
                    finalLoadedData.size}
                </li>
              );
            }
          );

          this.state.medicineOptions = "";
          this.state.medicineOptions = medicineDetailsView;
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          this.state.medicineProductsView = "";
        }
      });
    } else {
      $(".clear_icon").hide();
      this.state.medicineOptions = "";
      this.state.medicineProductsView = "";
      this.state.search = search;
      this.state.searchCount = 0;
      this.forceUpdate();
    }
  }

  handleClear = () => {
    $("#medicineList").val("");
    this.state.search = "";
    this.state.medicineOptions = "";
    this.state.remove_icon = "remove_icon collapse-hide";
    this.forceUpdate();
  };

  getCartDetails = () => {
    let cartId = "";
    cartId = this.state.cartId;

    let total = 0;
    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/Cart/findAllCartDetails/" + cartId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.cartItemsCount = response.data.cart_count;
          this.forceUpdate();
          const cartDetailsView = response.data.data.map((LoadedData, num) => {
            total =
              parseInt(total) +
              LoadedData.products_master_tbl.mrp * LoadedData.quantity;

            return (
              <React.Fragment>
                <p>
                  {LoadedData.products_master_tbl.medicinename}{" "}
                  <span>Qty: {LoadedData.quantity}</span>
                </p>
              </React.Fragment>
            );
          });
          this.state.cartTotal = total;
          this.state.cartDetailsPopup = cartDetailsView;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  getProductDetailsById = (productId) => {
    if (productId != "") {
      Httpconfig.httptokenget(
        Constant.siteurl + "api/productMaster/fetchdata/" + productId
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.data).length;
        if (Object.keys(response.data.data).length > 0) {
          const medicineProductsView = response.data.data.map(
            (product, num) => {
              var medicineID = product.medicineid;
              console.log("--318--", medicineID);
              this.getAlternateMedicine(medicineID);
              return (
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="diagnostics_breadcrumbs">
                        <ul class="breadcrumbs_list">
                          <li>
                            <a href="#">Home</a>
                          </li>
                          <li>
                            <a href="#">Lab Tests</a>
                          </li>
                          <li>
                            <a href="#" className="active">
                              {product.medicinename}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testdetail_block">
                    <div className="testdetail_decription">
                      <div className="testtitle_block">
                        <div className="test_title">
                          <h5>{product.medicinename}</h5>
                          <p> {product.description}</p>
                        </div>
                        <div className="testdetail_price">
                          <p className="main_price">
                            {" "}
                            {Constant.currency} {product.mrp}
                          </p>
                          <span className="discount_price">
                            <del>₹5000</del> 50% off
                          </span>
                        </div>
                      </div>
                      <div className="testtitle_alt">
                        <span>Also known as:</span>
                        <p>
                          Complete Blood Picture, Complete Blood Profile, CBP
                        </p>
                      </div>
                      <div className="last_cart">
                        <div className="testdetail_cat">
                          <span className="test_cat">Category:</span>
                          <span className="testcat_name"> Lab Test</span>
                        </div>
                        <div className="testdetail_cart">
                          <button
                            className="addedtocart"
                            id={product.id}
                            onClick={this.addToCart}
                          >
                            Add to Cart
                          </button>
                          {/* <button className="removecart"> <img src="./images/patient/img/diagnostics/checkmark.svg"></img>Added</button> */}
                        </div>
                      </div>
                    </div>

                    <div className="testdetail_singletest">
                      <div className="singletest_type">
                        <span className="type">Sample Type:</span>
                        <span className="type_title">{product.form}</span>
                      </div>
                      <div className="singletest_preparations">
                        <span className="preparations">Preparations:</span>
                        <span className="preparations_title">
                          {" "}
                          Fasting from early morning
                        </span>
                      </div>
                      <div className="singletest_about">
                        <span className="about">About:</span>
                        <p className="about_description" id="description_about">
                          N/A
                        </p>
                        <div className="read_option">
                          <span id="readmore_about">Read More</span>
                        </div>
                      </div>
                    </div>

                    <div className="testdetail_packagetest">
                      <div className="package_total">
                        <h5>Total Tests Included</h5>
                      </div>
                      <div className="package_list" id="package_description">
                        <ol>
                          <li>N/A</li>
                        </ol>
                      </div>
                      <div className="read_option">
                        <span id="readmore_packagelist">Read More</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          );
          this.setState({
            medicineProductsView: medicineProductsView,
          });
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          this.state.medicineProductsView = "";
          this.forceUpdate();
        }
      });
    } else {
      this.state.medicineOptions = "";
      this.state.medicineProductsView = "";
      this.forceUpdate();
    }
  };

  getAlternateMedicine = (medicineID) => {
    var postData = {
      medicineId: medicineID,
    };
    if (postData != "") {
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/MedicineDetails/alternativeMedicines/",
        postData
      ).then((response) => {
        console.log("--1953--", response.data.result);

        const alerternateMedicine = response.data.result.map(
          (LoadedData, num) => {
            var medicineID = LoadedData.medicineid;
            console.log("--1825--", medicineID);
            //this.getAlternateMedicine(medicineID);
            return (
              <div className="relatedtests_block">
                <div className="relatedtests_build">
                  <div className="relatedtests_img">
                    {/* <img src="./images/patient/img/diagnostics/testimage.svg"></img> */}
                    <img src={Constant.imgurl + LoadedData.image} />
                  </div>
                  <div className="relatedtests_heading">
                    <h5
                    // onClick={this.getProductDiscription.bind(
                    //   this,
                    //   LoadedData.id
                    // )}
                    >
                      {LoadedData.medicinename}
                    </h5>
                  </div>
                  <div className="relatedtests_des">
                    <p>{LoadedData.description}</p>
                  </div>
                  <div className="relatedtests_include">
                    <span>Includes 65 tests</span>
                  </div>
                  <div className="relatedtests_footer">
                    <div className="relatedtests_price">
                      <p className="main_price">
                        {Constant.currency} {LoadedData.mrp}
                      </p>
                      <span className="discount_price">
                        <del>₹5000</del> 50% off
                      </span>
                    </div>
                    <div className="relatedtests_cart">
                      <button
                        className="addedtocart"
                        id={LoadedData.id}
                        onClick={this.addToCart}
                      >
                        Add to Cart
                      </button>
                      <button
                        className="removecart"
                        style={{ display: "none" }}
                      >
                        {" "}
                        <img src="./images/patient/img/diagnostics/checkmark.svg"></img>
                        Added
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        );
        this.setState({
          alerternateMedicine: alerternateMedicine,
        });
        this.forceUpdate();
      });
    } else {
      this.state.alerternateMedicine = "";
      this.forceUpdate();
    }
  };
  // Remove items from cart
  removeItem = (id) => {
    let cartId = this.state.cartId;
    let productId = id;
    Httpconfig.httptokendelete(Constant.siteurl + "api/OM/Cart/" + productId)
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success(response.data.message);
          this.miniCart();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // show cart popup
  miniCart = () => {
    let cartId = this.state.cartId;
    let total = 0;
    let productTotal = 0;

    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/Cart/findAllCartDetails/" + cartId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          console.log("--544--", response);
          this.state.cartItemsCount = response.data.cart_count;
          this.forceUpdate();

          const cartDetailsPage = response.data.data.map((LoadedData, num) => {
            productTotal = 0;
            total =
              parseFloat(total) +
              LoadedData.products_master_tbl.mrp * LoadedData.quantity;
            productTotal =
              parseFloat(productTotal) +
              LoadedData.products_master_tbl.mrp * LoadedData.quantity;

            return (
              <div class="cart_list_box">
                <div class="row">
                  <div class="col-md-2">
                    <div class="cart_img">
                      <img
                        src={
                          LoadedData.products_master_tbl.image != null
                            ? Constant.imgurl +
                              LoadedData.products_master_tbl.image
                            : this.getProductImage(
                                LoadedData.products_master_tbl.form
                              )
                        }
                      />
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="cart_item_det">
                      <h2>
                        {LoadedData.products_master_tbl.medicinename +
                          " " +
                          LoadedData.products_master_tbl.strength}
                      </h2>
                      <p>
                        {" "}
                        <span class="mrp">
                          MRP:{Constant.currency}{" "}
                          {LoadedData.products_master_tbl.mrp}
                        </span>
                        <span>
                          {Constant.currency}{" "}
                          {productTotal
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </span>
                      </p>
                      <h4>{LoadedData.products_master_tbl.size}</h4>
                      <h6>{LoadedData.products_master_tbl.manufacturer}</h6>

                      {/* <div class="input-group number-spinner">
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default minus_btn"
                            data-dir="dwn"
                            id={"minus-" + LoadedData.medicine_id}
                            onClick={this.qtyDecrementUpdate.bind(
                              this,
                              LoadedData.medicine_id,
                              LoadedData.id
                            )}
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                        </div>
                        <input
                          type="text"
                          class="form-control text-center"
                          readOnly="readOnly"
                          value={LoadedData.quantity}
                          id={"qty-" + LoadedData.medicine_id}
                        />
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default plus_btn"
                            data-dir="up"
                            id={"plus-" + LoadedData.medicine_id}
                            onClick={this.qtyIncrementUpdate.bind(
                              this,
                              LoadedData.medicine_id,
                              LoadedData.id
                            )}
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="cart_item_btm">
                  <a
                    href="#"
                    id={LoadedData.id}
                    onClick={this.removeItem.bind(this, LoadedData.id)}
                  >
                    <p>Remove</p>
                  </a>
                  <a
                    href="#"
                    id={LoadedData.medicine_id}
                    onClick={this.saveForLater}
                  >
                    <h3>
                      {" "}
                      <img src="../images/patient/img/Ordermedicine/saveforLater.svg" />{" "}
                      Save For Later
                    </h3>
                  </a>
                </div>
              </div>
            );
          });
          this.state.cartDetailsPageSection = "collapse-show";
          this.state.home = "collapse-hide";
          this.state.downloadSection = "collapse-hide";
          this.state.searchproductslist = "collapse-hide";
          this.state.cartTotal = total;
          this.state.cartDetailsPage = cartDetailsPage;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  getProductImage = (form) => {
    let productimg = "";
    let images = [
      { name: "capsule", image: "med_img_capsul_strip.png" },
      { name: "capsule bottle", image: "med_img_capsulBottle.png" },
      { name: "condom", image: "med_img_condom.png" },
      { name: "cream", image: "med_img_cream.png" },
      { name: "eardrops", image: "med_img_earDrop.png" },
      { name: "eyedrops", image: "med_img_eyeDrop.png" },
      { name: "facewash", image: "med_img_facewash.png" },
      { name: "inhelar", image: "med_img_inhelar.png" },
      { name: "injection", image: "med_img_injection.png" },
      { name: "oil", image: "med_img_oil.png" },
      { name: "powder", image: "med_img_powder.png" },
      { name: "soap", image: "med_img_soap.png" },
      { name: "sprey", image: "med_img_spery.png" },
      { name: "syrup", image: "med_img_syrup.png" },
      { name: "tablet", image: "med_img_tablet.png" },
      { name: "condoms", image: "001-condom.svg" },
      { name: "toothbrush", image: "002-toothbrush.svg" },
      { name: "skin", image: "003-skin.svg" },
      { name: "lotion", image: "004-lotion.svg" },
      { name: "spray", image: "005-spray.svg" },
      { name: "lipbalm", image: "007-lip-balm.svg" },
      { name: "soap", image: "008-soap.svg" },
      { name: "oral powder", image: "med_img_powder.png" },
      { name: "Tablet Oral Suspension", image: "med_img_syrup.png" },
      { name: "Oral Suspension", image: "med_img_syrup.png" },
      { name: "handwash", image: "handwash.svg" },
    ];

    const result = images
      .filter((images) =>
        form != null
          ? images.name.toLowerCase().trim() == form.toLowerCase().trim()
          : ""
      )
      .map((filteredImage) => {
        return filteredImage.image;
      });

    if (result != "") {
      productimg = "../images/productimages/" + result;
    } else {
      productimg = "./images/retailer/no-product.png";
    }
    return productimg;
  };
  //  onchanges on master search
  handleChange = (event) => {
    let searched = event.currentTarget.id;
    this.getProductDetailsById(searched);
    let medicineName = event.currentTarget.innerText;
    this.state.selelcteMedicineName = medicineName;
    this.state.home = "collapse-hide";
    this.state.searchproductslist = "collapse-show";
    this.state.cartDetailsPageSection = "collapse-hide";
    this.state.downloadSection = "collapse-hide";

    $("#medicineList").val(medicineName);
    this.state.search = medicineName;
    this.state.medicineOptions = "";
    this.forceUpdate();
  };
  //  quanatity decrement

  qtyIncrement = (event) => {
    let data = event.currentTarget.id.split("-");
    let id = data[0];
    let orderId = data[1];
    let qty = $("#qty-" + orderId).val();

    if (qty >= 0 && qty < 50) {
      qty = parseInt(qty) + 1;
      $("#qty-" + orderId).val(qty);
    } else {
      if (qty >= 50) {
        toast.error("Qunatity should not be more that 50");
        return false;
      }
    }
  };

  // quanatity Increment
  qtyDecrement = (event) => {
    let data = event.currentTarget.id.split("-");
    let id = data[0];
    let orderId = data[1];
    let qty = $("#qty-" + orderId).val();
    if (qty > 0) {
      qty = parseInt(qty) - 1;
      $("#qty-" + orderId).val("");
      $("#qty-" + orderId).val(qty);
    }
  };

  // Remove items from cart
  removeItem = (id) => {
    let cartId = this.state.cartId;
    let productId = id;
    Httpconfig.httptokendelete(Constant.siteurl + "api/OM/Cart/" + productId)
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success(response.data.message);
          this.miniCart();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For save later
  saveForLater = (event) => {
    let cartId = this.state.cartId;
    let productId = event.currentTarget.id;
    let quanatity = $("#qty-" + productId).val();
    Httpconfig.httptokenpost(Constant.siteurl + "api/OM/Cart/wishlist", {
      cart_id: JSON.parse(cartId),
      patient_id: this.state.patientId,
      medicine_id: JSON.parse(productId),
      quantity: JSON.parse(quanatity),
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success(response.data.message);
          this.miniCart();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Update quantity
  updateQuantity = (productId, rowId) => {
    let cartId = this.state.cartId;
    let quanatity = $("#qty-" + productId).val();
    Httpconfig.httptokenput(Constant.siteurl + "api/OM/Cart/" + rowId, {
      cart_id: cartId,
      patient_id: this.state.patientId,
      medicine_id: productId,
      quantity: quanatity,
      shop_id: 0,
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.miniCart();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  quanatity decrement

  qtyIncrementUpdate = (prodcutId, rowId) => {
    let qty = $("#qty-" + prodcutId).val();
    if (qty >= 0 && qty < 50) {
      qty = parseInt(qty) + 1;
      $("#qty-" + prodcutId).val("");
      $("#qty-" + prodcutId).val(qty);
      this.updateQuantity(prodcutId, rowId);
    }

    if (qty >= 50) {
      toast.error("Quantity should not be more than 50");
      return false;
    }
  };

  // quanatity Increment
  qtyDecrementUpdate = (prodcutId, rowId) => {
    let qty = $("#qty-" + prodcutId).val();
    if (qty > 1) {
      qty = parseInt(qty) - 1;
      $("#qty-" + prodcutId).val("");
      $("#qty-" + prodcutId).val(qty);
      this.updateQuantity(prodcutId, rowId);
    }
  };

  // add to cart
  addToCart = (event) => {
    let medicineId = event.currentTarget.id;

    let quantity = $("#qty-" + medicineId).val();

    let cartId = "";
    let cartItemsCount = this.state.cartItemsCount;
    if (quantity == 0) {
      toast.error("Quantity should not be Zero");
      return false;
    }
    if (this.state.cartId) {
      cartId = this.state.cartId;
    }
    if (quantity > 50) {
      toast.error("Quantity should not be more than 50");
      return false;
    }
    if (cartItemsCount >= 30) {
      toast.error("You can add medicines upto 30 only for an order");
      return false;
    }

    Httpconfig.httptokenpost(Constant.siteurl + "api/OM/Cart", {
      cart_id: cartId,
      shop_id: 0,
      patient_id: this.state.patientId,
      medicine_id: medicineId,
      quantity: quantity,
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (cartId == "") {
            this.state.cartId = response.data.cart_id;
            this.state.cartItemsCount = response.data.cart_items;

            this.forceUpdate();

            ls.set("cartIdEnc", response.data.cart_id);
          } else {
            if (this.state.cartId != response.data.cart_id) {
              this.state.cartId = response.data.cart_id;
              this.state.cartItemsCount = response.data.cart_items;
              ls.set("cartIdEnc", response.data.cart_id);
              this.forceUpdate();
            }
          }
          this.getCartDetails();
          toast.success(response.data.message, {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    $("#order_pro_toast").show();
    setTimeout(function () {
      $("#order_pro_toast").hide();
    }, 3000);
  };
  //Popular tests
  mostPopularTests = (patient_id) => {
    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/MedicineDetails/getMostPopularHealthPackages",
      {
        patient_id: patient_id,
        view_all: "view_all",
      }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.searchCount = Object.keys(response.data.data).length;
          this.state.mostPopularTests = response.data.data;
          this.state.popularTests = this.state.mostPopularTests[0].popularTests;
          console.log("--928--", this.state.popularTests);
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  getProductDiscription = (product_id) => {
    // window.location.href = "/productdescription" + "?id=" + product_id;
    if (product_id != "") {
      window.open("/Diagnostictestdetail" + "?id=" + product_id, "_blank");
      localStorage.setItem("OrderMedicineId", product_id);
    }
  };
  render() {
    const {
      isDisabled,
      disabledRequired,
      isParentChecked,
      fields,
      tempDisplayText,
    } = this.state;
    let patient_id = "";

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section
            id="order_medicine_section"
          >
            <div class="order_search">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <PlacesAutocomplete
                      value={
                        this.state.detected_address
                          ? this.state.detected_address
                          : this.state.search_address
                      }
                      onChange={this.handleAddressChange}
                      onSelect={this.handleAddressSelect}
                      // searchOptions={searchOptions}
                      shouldFetchSuggestions={
                        this.state.search_address.length > 4
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="ord_search">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input order_select",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>

                    {/* <div class="input-group-btn detect_btn">
                      <button
                        class="btn btn-default"
                        type=""
                        onClick={this.getCurrentLocation}
                      >
                        <img
                          class="det_img"
                          src="../images/patient/img/Ordermedicine/detectLocation.svg"
                        />
                        Detect
                      </button>
                    </div> */}
                    <span class="loc_img">
                      <img src="../images/patient/img/Ordermedicine/locationIcon.svg" />
                    </span>
                  </div>

                  <div class="col-md-7">
                    <div class="order_input">
                      <input
                        type="text"
                        class="form-control"
                        id="medicineList"
                        placeholder="Search  1,50,000 + Medicines"
                        onKeyUp={this.fetchmedicinedata}
                        autocomplete="off"
                      />
                      <img
                        class="search_img"
                        src="../images/patient/img/Ordermedicine/search.svg"
                      />
                      {/* <img
                        class="remove_icon"
                        src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                        onClick={this.handleClear}
                      /> */}
                      {/* <span>
                        <img
                          class="search_img"
                          src="../images/patient/img/Ordermedicine/search.svg"
                        />
                      </span> */}
                      <ul id="searchResult">{this.state.medicineOptions}</ul>
                    </div>
                  </div>
                  {/* <div class="col-md-2 order_bar">
                    <button type="button">Search</button>
                 <a href="#" onClick={this.miniCart}>
                      <img
                        class="cart_ico"
                        src="../images/patient/img/Ordermedicine/shopping-cart.svg"
                      />
                      <div class="cart_bar">{this.state.cartItemsCount}</div>
                      <span>cart</span>
                    </a> 
                  </div> */}
                  <div class="col-md-2 order_cart" id="all_cart_item">
                    <a href="#" onClick={this.selectRetailer}>
                      <img
                        class="cart_img"
                        src="./images/patient/img/cart.svg"
                      />
                      <div class="cart_bar">{this.state.cartItemsCount}</div>
                    </a>
                  </div>
                  <div
                    class="col-md-2 order_cart"
                    id="cart_items"
                    style={{ display: "none" }}
                  >
                    <a href="#">
                      <img
                        class="cart_ico"
                        src="../images/patient/img/Ordermedicine/shopping-cart.svg"
                      />
                      <div class="cart_bar">0</div>
                      <span>cart</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
         
            <section id="upload_prescription">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="diagnostics_breadcrumbs">
                      <ul class="breadcrumbs_list">
                        <li>
                          <a href="#">Home</a>
                        </li>
                        <li>
                          <a href="#" className="active">
                          Upload
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


<div className="row">
    <div className="col-md-8">
        <div className="upload_block">
        <div className="upload_header">
    <h2>Upload Prescription(s)</h2>
</div>
<div className="upload_files">

  
<div class="file upload_file_image">
							Upload
							<input type="file" name="file"/>
                           
						</div>
                        <div className="upload_nofile">
                        <span>No files Choosen</span>
                        </div>
                        
</div>

<div className="uploadfile_images">
<div className="upload_pre_img">
  <img className="file_cross" src="../images/patient/img//diagnostics/fileclosecircle.svg"></img>
  <img src="../images/patient/img//diagnostics/defaultfile.svg"></img>
  </div>
  <div className="upload_pre_img">
  <img className="file_cross" src="../images/patient/img//diagnostics/fileclosecircle.svg"></img>
  <img src="../images/patient/img//diagnostics/defaultfile.svg"></img>
  </div>
  <div className="upload_pre_img">
  <img className="file_cross" src="../images/patient/img//diagnostics/fileclosecircle.svg"></img>
  <img src="../images/patient/img//diagnostics/defaultfile.svg"></img>
  </div>
</div>

<div className="select_address">
<p>Select Address</p>
<img src="./images/patient/img/blue-arrowright.svg"></img>
</div>
<div className="select_uploadaddress">
<div className="heading">
<h2>Address</h2>
<span>Change</span>
</div>
<div className="upload_select_address">
    <h5>John Doe</h5>
        <h5>House No: 1-24,</h5> 
        <h5> New Street Colony,</h5>
        <h5> Madhapur Hyderabad - 500081</h5> 
        
</div>
</div>
<div className="file_submit_btn">
  <button>Submit</button>
</div>
        </div>

    </div>
    <div className="col-md-4">
        <div className="uploadprescri_img">
            <img src="../images/patient/img//diagnostics/precription.svg"></img>
        </div>
    </div>
</div>



                </div>

</section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
