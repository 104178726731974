import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
//import Httpconfig from "../helpers/HttpconfigAdmin";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import DiagnosisHeader from "../diagnostics/DiagnosisHeader";
import DiagnosisMenu from "../diagnostics/DiagnosisMenu";
import { reactLocalStorage } from "reactjs-localstorage";
import Footer from "../patient/Patientfooter";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const moment = require("moment");

export default class CreateAgents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {status: "",},
      errors: {},
      retailer_data: "",
      type: "password",
      options: [],
      img: "/images/doctor-img/Login screens/hide_icon.svg",
    };
    this.showPassword = this.showPassword.bind(this);
  }
  //  for setStartTimeOnChange
  setStartTimeOnChange = (field, event) => {
    let expDate = event.target.value;
    this.setState({ registration_expirity: expDate });
    this.state.fields["registration_expirity"] =
      moment(expDate).format("YYYY-MM-DD");
    let count = expDate.search("-");
    if (count > 0) {
      let dateString = expDate.split("-");
      if (dateString[0].length != 4) {
        toast.error("Invalid expired date");
        return;
      }
    }

    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
    this.state.errors[field] = "";
  };
  // for showPassword
  showPassword() {
    let type = this.state.type;
    if (this.state.type) {
      if (this.state.type == "input") {
        this.state.type = "password";
        this.state.img = "/images/doctor-img/Login screens/hide_icon.svg";
      } else {
        this.state.type = "input";
        this.state.img = "/images/doctor-img/Login screens/unhide_icon.svg";
      }
    }
    this.forceUpdate();
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.getDiagnosisInfo(handle);
    this.fetchCountryCurrencyDetails();

    let userData = reactLocalStorage.getObject("retuserObj");
    if (userData) {
      if (userData != "") {
        this.setState({
          retailerId: userData.diagnosis_id,
        });

        this.forceUpdate();
      }
    }
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  //  for emailValidation
  emailValidation = (field, event) => {
    var email = document.getElementById("txtEmailId").value;
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
      toast.error("Please provide a valid email address");
      return false;
    }
  };
  // To get all the ResponderInfo
  getDiagnosisInfo(handle) {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/diagnosis/getAgentInfo/" + handle
    )
      .then((response) => {
       // console.log("--87--", response);
        this.state.name = response.data.data[0].name;
        this.setState({
          fields: {
            name: response.data.data[0].name,
            mobile_number: response.data.data[0].mobile_number,
            email: response.data.data[0].email,
            gender: response.data.data[0].gender,
            password: response.data.data[0].tbl_user.password,
            age: response.data.data[0].age,
            registration_expirity: response.data.data[0].registration_expirity,
            address: response.data.data[0].address,
            status: response.data.data[0].tbl_user.status,
          },
        });
        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateRetailers(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createRetailer(event);
    } else {
      //toast.warn("Form has errors.");
    }
  }
  //  for getting country currency details
  fetchCountryCurrencyDetails() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country")
      .then((response) => {
        this.setState({
          countryData: response.data.data,
        });
      })
      .catch((error) => {});
  }

  // creates new controller
  createRetailer = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    Httpconfig.httptokenpost(Constant.siteurl + "api/diagnosis/createAgent", {
      name: fields["name"],
      email: fields["email"],
      gender: fields["gender"],
      mobile_number: fields["mobile_number"],
      password: fields["password"],
      age: fields["age"],
      address: fields["address"],
      diagnosis_id: this.state.retailerId,
      status: fields["status"],
      is_available: fields["status"],
    })
      .then((response) => {



        if (response.data.status == 200) {
          toast.success("Successfully Created Agent");
          setTimeout(
            () => this.props.history.push("/diagnostics/ViewAgents"),
            1000
          );
        } 
        else if (response.data.status == 204 && response.data.error === true) {
         // toast.error("Email or Mobile Number already assosiated with us...");
         toast.error(response.data.message);
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateRetailers = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    Httpconfig.httptokenput(
      Constant.siteurl + "api/diagnosis/updateAgent/" + handle,
      {
        name: fields["name"],
        email: fields["email"],
        gender: fields["gender"],
        mobile_number: fields["mobile_number"],
        age: fields["age"],
        address: fields["address"],
        password: fields["password"],
        status: fields["status"],
        is_available: fields["status"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          toast.success(response.data.message);
          setTimeout(
            () => this.props.history.push("/diagnostics/Viewagents"),
            2000
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "name cannot be empty";
    }
    if (!fields["mobile_number"]) {
      formIsValid = false;
      errors["mobile_number"] = "Mobile Number cannot be empty";
    }
    if (!fields["gender"]) {
      formIsValid = false;
      errors["gender"] = "Please Select Gender";
    }
    if (!fields["age"]) {
      formIsValid = false;
      errors["age"] = "Registration Number cannot be empty";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = "status cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors, coupon_data, countryData } = this.state;
    return (
      <main id="main">
        <DiagnosisHeader />
        <DiagnosisMenu />
        <section id="Pharmacy_dashboard">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-10 col-md-10 Pharma_Content">
        {/* <section id="main_dashboard">
          <div className="container" id="main_front">
            <div className="row">
              <div className="col-md-12"> */}
                {/* <div className="dash-section">
                  <div className="section-header">
                    <ol className="breadcrumb">
                      <li className="active">
                        <Link to="/admin"> Dashboard</Link> &gt;
                        <a> Diagnostics </a>
                      </li>
                    </ol>
                  </div>
                </div> */}

                <section id="CMS_tab">
                  <div className="CMS_content">
                    <div className="container">
                      <div className="row">
                        <div className="tab-header">
                          <h3>Create Agent </h3>
                        </div>
                        <div id="reg_form">
                          <form onSubmit={this.checkSubmit.bind(this)}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Agent Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={this.state.fields["name"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "name"
                                    )}
                                    className="form-control"
                                    placeholder="Name"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["name"]}
                                  </span>
                                </div>
                              </div>
                              {/* </div> */}

                              {/* <div className="row"> */}
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Mobile Number</label>
                                  <input
                                    type="text"
                                    name="mobile_number"
                                    value={
                                      this.state.fields["mobile_number"] || ""
                                    }
                                    onChange={this.handleChange.bind(
                                      this,
                                      "mobile_number"
                                    )}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    minLength="10"
                                    maxlength="10"
                                    className="form-control"
                                    placeholder="Mobile Number"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["mobile_number"]}
                                  </span>
                                </div>
                              </div>
                              {/* </div> */}

                              {/* <div className="row"> */}
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Email address</label>
                                  <input
                                    type="text"
                                    name="email"
                                    id="txtEmailId"
                                    autoComplete="new-password"
                                    value={this.state.fields["email"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "email"
                                    )}
                                    className="form-control"
                                    placeholder="Email address"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["email"]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Enter Password</label>
                                  <input
                                    type={this.state.type}
                                    name="password"
                                    autoComplete="new-password"
                                    value={this.state.fields["password"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "password"
                                    )}
                                    className="form-control"
                                    placeholder="Enter Password"
                                  />
                                  <img
                                    // style={{ marginTop: "25px" }}
                                    class="password_view_retailer"
                                    onClick={this.showPassword}
                                    src={this.state.img}
                                  />
                                  <span className="cRed">
                                    {this.state.errors["password"]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Gender</label>
                                  <select
                                    name="gender"
                                    className="form-control"
                                    value={this.state.fields["gender"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "gender"
                                    )}
                                  >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                  <span className="cRed">
                                    {this.state.errors["gender"]}
                                  </span>
                                </div>
                              </div>

                              {/* </div> */}

                              {/* <div className="row"> */}
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Age</label>
                                  <input
                                    type="number"
                                    name="age"
                                    value={this.state.fields["age"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "age"
                                    )}
                                    className="form-control"
                                    placeholder="Age"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["age"]}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address"
                                    value={this.state.fields["address"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "address"
                                    )}
                                    className="form-control"
                                    placeholder="Address"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["address"]}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  for="customRadio2"
                                  class="custom-control-label"
                                >
                                  Status
                                </label>
                                <div
                                  class="custom-control custom-radio"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "status"
                                  )}
                                >
                                  <input
                                    type="radio"
                                    value="0"
                                    checked={this.state.fields["status"] == "0"}
                                    name="status"
                                  />{" "}
                                  Active &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="1"
                                    checked={this.state.fields["status"] == "1"}
                                    name="status"
                                  />{" "}
                                  In Active
                                  <span className="cRed">
                                    {this.state.errors["status"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                            </div>

                           

                            <div className="row">
                              <div className="form-group col-md-12">
                                <button
                                  type="submit"
                                  className="btn  btn-primary save_btn"
                                  onClick={this.emailValidation.bind(this, "")}
                                >
                                  Save Agent
                                </button>{" "}
                                &nbsp;
                                <Link
                                  to="/diagnostics/ViewAgents"
                                  className="cancel_btn"
                                >
                                  Cancel
                                </Link>{" "}
                                &nbsp; &nbsp;
                              </div>
                            </div>
                          </form>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </section>
              {/* </div>
            </div>
          </div>
        </section> */}
        </div>
                    </div>
                  </div>
                </section>
        <Footer />
      </main>
    );
  }
}
