import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigDoctorAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import DoctorAdminHeader from "../doctorAdmin/DoctorAdminHeader";

import DoctorSideMenu from "./DoctorSideMenu";
import { I18nPropvider, LOCALES } from "../../i18nProvider";

import { Link } from "react-router-dom";
import $ from "jquery";

export default class DoctorAdmincreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slots_data: "",
      Doctorid: "",
      datatable: {
        columns: [
          {
            label: "Doctor Name",
            field: "doctor_name",
            sort: "asc",
            width: 150,
          },
          {
            label: "From Date",
            field: "from_date",
            sort: "asc",
            width: 150,
          },
          {
            label: "To Date",
            field: "to_date",
            sort: "asc",
            width: 150,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
      },
    };
    this.deleteDoctorSlots = this.deleteDoctorSlots.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    var retrievedObject = localStorage.getItem("doctorAdmin");
    let userData = JSON.parse(retrievedObject);
    this.setState({ Doctorid: userData.doctor_id });
    console.log("userData", userData.doctor_id);
    this.fetchDoctorSlotsdata(userData.doctor_id);
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fetchDoctorSlotsdata(Doctorid) {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Doctor/getDoctorsSlotListDetails",
      { doctorId: Doctorid }
    )
      .then((response) => {
        if (response.data.error == true) {
          toast.error(response.data.messsage);
          return;
        }
        if (Object.keys(response.data.data).length == 0) {
          $("#loading").html("No Slots found");
        }
        this.setState({
          slots_data: response.data.data,
        });
        let assignvalues = [];
        this.items = response.data.data.map((item, key) => {
          assignvalues.push({
            doctor_name: item.doctor_name,
            from_date: item.doctor_appointment_schedule_tbls[0].from_date,
            to_date: item.doctor_appointment_schedule_tbls[0].to_date,
            actions: (
              <div>
                <span>
                  <Link
                    to={"/doctoradmin/doctoradmineditslots/" + item.id}
                    className="fa fa-edit point-cursor"
                    title={"Update " + item.doctor_name}
                  ></Link>
                </span>{" "}
                &nbsp;
                <span>
                  <i
                    onClick={() =>
                      this.deleteDoctorSlots(item.id, item.doctor_name)
                    }
                    className="fa fa-trash point-cursor"
                    title={"Delete " + item.doctor_name}
                  ></i>
                </span>{" "}
                &nbsp;
              </div>
            ),
          });
        });
        let couponState = Object.assign({}, this.state);
        couponState.datatable.rows = assignvalues;
        this.setState(couponState);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //delete controller
  deleteDoctorSlots(doctorId, name) {
    var isConfirm = window.confirm(
      "Are you sure to delete " + name + " slots ?"
    );
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/doctor/deleteAllScheduledSlots/" + doctorId
      )
        .then((response) => {
          toast.success("Successfully Deleted Doctor Slots");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <main id="main">
        <DoctorAdminHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="doctor_dashboard">
            <div class="container-fluid">
              <div class="row">
                <DoctorSideMenu onSelectLanguage={this.handleLanguage} />
                <div class="col-lg-10 col-md-9 no_padding">
                  <div class="overview_left">
                    <section>
                      <div className="container" id="main_front">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="CMS_content">
                              <div className="container no_padding">
                                <div className="row">
                                  <div className="create_head">
                                    <h3> Create Doctor Slots </h3>
                                  </div>
                                  <div id="reg_form">
                                    <form>
                                      <div className="row">
                                        <div className="form-group col-md-12">
                                          <Link
                                            to="/doctoradmin/doctoradmineditslots"
                                            className="btn  create_btn fright"
                                          >
                                            Create Slot
                                          </Link>
                                        </div>
                                      </div>
                                    </form>
                                    {datatable.rows.length === 0 ? (
                                      <p id="loading">Loading...... </p>
                                    ) : (
                                      <MDBDataTable
                                        striped
                                        bordered
                                        small
                                        data={datatable}
                                      />
                                    )}
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div
                                          className="update_btn"
                                          style={{ textAlign: "right" }}
                                        ></div>
                                      </div>
                                    </div>
                                    <ToastContainer />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </I18nPropvider>
      </main>
    );
  }
}
