import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctor";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import DoctorHeader from "../doctor/DoctorHeader";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

export default class DoctorTelemedicineAppointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      telemedInprogressAppointmentList: "",
      telemedAppointmentUpcomingList: "",
      telemedAppointmentPastList: "",
      modalvisible: "modal collapse-hide",
    };
    this.modalShow = this.modalShow.bind(this);
    this.joinAppointment = this.joinAppointment.bind(this);
    this.close = this.close.bind(this);
  }
  componentDidMount = () => {
    var retrievedObject = ls.get("DocuserObj");
    let userData = retrievedObject;
    this.state.doctor_id = userData.doctor_id;
    this.getTelemedicineInprogessAppointmentList();
    this.getTelemedicineUpcomingAppointmentList();
    this.getTelemedicinePastAppointmentList();
    this.getTelemedicineFutureAppointmentList();
    const interval = setInterval(() => {
      this.getTelemedicineInprogessAppointmentList();
      this.getTelemedicineUpcomingAppointmentList();
      this.getTelemedicinePastAppointmentList();
    }, 10000);
  };

  // To get future appointments of a doctor
  getTelemedicineFutureAppointmentList = () => {
    let telemedAppointmentFutureList = "";
    var patientImage = "";
    var today = moment(new Date());
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      status: 1,
      orderBy: "ASC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          console.log("appoint data", response);
          let futureAppointmentCount = Object.keys(response.data.data).length;
          if (futureAppointmentCount > 0) {
            telemedAppointmentFutureList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage = appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    if (
                      appointmentdetails.patient_tbl.gender.toLowerCase() ==
                      "male"
                    ) {
                      patientImage =
                        "../images/patient/img/Profile/Male_patient.svg";
                    } else {
                      patientImage =
                        "../images/patient/img/Profile/Female_patient.svg";
                    }
                  }
                }
                let patientAge = "";
                if (appointmentdetails.patient_tbl.dob != null) {
                  let dob = appointmentdetails.patient_tbl.dob.split("T");
                  let age = dob[0];
                  let ageYears = moment().diff(age, "years");
                  let ageMonths = moment().diff(age, "months");
                  let ageDays = moment().diff(age, "days");
                  if (ageYears > 0) {
                    patientAge = ageYears + " Years";
                  } else if (ageMonths > 0) {
                    patientAge = ageMonths + " Months";
                  } else if (ageDays > 0) {
                    patientAge = ageDays + " Days";
                  }
                }
                return (
                  <React.Fragment>
                    <div class="app_date">
                      <h3>
                        {moment(appointmentdetails.appointment_datetime).format(
                          "ddd DD MMM, YYYY"
                        )}
                      </h3>
                    </div>
                    <div class="pending_sec">
                      <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                          <div class="doc_details">
                            <img src="../images/patient/img/Profile/Male_patient.svg" />
                            <div class="doc_name">
                              <h4>
                                {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                "male"
                                  ? "Mr."
                                  : "Ms."}
                                {appointmentdetails.patient_tbl.name}
                              </h4>
                              <p>
                                {appointmentdetails.patient_tbl.gender
                                  .charAt(0)
                                  .toUpperCase() +
                                  appointmentdetails.patient_tbl.gender.slice(
                                    1
                                  )}{" "}
                                , <span>{patientAge}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <p class="doc_time">
                            {moment(
                              appointmentdetails.appointment_datetime
                            ).format("LT")}
                          </p>
                        </div>
                      </div>
                      <div class="doc_app_time">
                        <div class="row">
                          <div
                            class="col-lg-5 col-md-5 "
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.modalShow}
                          >
                            <p class="med_det">
                              <img src="../images/doctor-img/medical-detail.svg" />
                              Medical Details
                            </p>
                          </div>
                          <div class="col-lg-7 col-md-7">
                            <p class="join_now">
                              {" "}
                              Appointment{" "}
                              <span>
                                {" "}
                                {moment(
                                  appointmentdetails.appointment_datetime
                                ).fromNow()}{" "}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            );
          } else {
            telemedAppointmentFutureList = "";
          }
          this.setState({
            telemedFutureAppointmentList: telemedAppointmentFutureList,
          });
          this.forceUpdate();
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  getTelemedicineInprogessAppointmentList = () => {
    let telemedAppointmentInprogressList = "";
    var patientImage = "";
    var today = moment(new Date());
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: todayDate,
      status: 2,
      orderBy: "ASC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let inprogressAppointmentCount = Object.keys(
            response.data.data
          ).length;
          if (inprogressAppointmentCount > 0) {
            telemedAppointmentInprogressList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage = appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }
                var mindiff = moment().diff(
                  appointmentdetails.appointment_datetime,
                  "minutes"
                );
                let patientAge = "";
                if (appointmentdetails.patient_tbl.dob != null) {
                  let dob = appointmentdetails.patient_tbl.dob.split("T");
                  let age = dob[0];
                  let ageYears = moment().diff(age, "years");
                  let ageMonths = moment().diff(age, "months");
                  let ageDays = moment().diff(age, "days");
                  if (ageYears > 0) {
                    patientAge = ageYears + " Years";
                  } else if (ageMonths > 0) {
                    patientAge = ageMonths + " Months";
                  } else if (ageDays > 0) {
                    patientAge = ageDays + " Days";
                  }
                }
                if (mindiff == -5) {
                  return (
                    <div class="telemed_box">
                      <div class="doc_available">
                        <div class="row">
                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                            <div class="doc_details">
                              <img src={patientImage} />
                              <div class="doc_name">
                                <h4>
                                  {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                  "male"
                                    ? "Mr."
                                    : "Ms."}
                                  {appointmentdetails.patient_tbl.name}
                                </h4>
                                <p>
                                  {appointmentdetails.patient_tbl.gender
                                    .charAt(0)
                                    .toUpperCase() +
                                    appointmentdetails.patient_tbl.gender.slice(
                                      1
                                    )}
                                  , <span>{patientAge}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <p class="doc_time">
                              {moment(
                                appointmentdetails.appointment_datetime
                              ).format("LT")}
                            </p>
                          </div>
                        </div>

                        <div class="doc_app_time">
                          <div class="row">
                            <div
                              class="col-lg-6 col-md-6"
                              id={
                                appointmentdetails.appointment_confirm_id +
                                "-" +
                                appointmentdetails.patient_id
                              }
                              onClick={this.modalShow}
                            >
                              <p class="med_det">
                                {" "}
                                <img src="https://image.flaticon.com/icons/png/512/14/14879.png" />{" "}
                                Medical Details
                              </p>
                            </div>
                            <div
                              class="col-lg-6 col-md-6"
                              id={
                                appointmentdetails.appointment_confirm_id +
                                "-" +
                                appointmentdetails.patient_id
                              }
                              onClick={this.joinAppointment.bind(
                                this,
                                appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                              )}
                            >
                              <p class="join_now"> Join Now</p>
                            </div>
                          </div>
                        </div>
                        <div class="write_section">
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <p>
                                <img
                                  class="pres_img"
                                  src="img/Doctors_List/Doctor_confirmation.svg"
                                />{" "}
                                Write Prescription
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  let patientId = appointmentdetails.patient_id;
                  let meetingId = appointmentdetails.appointment_confirm_id;
                  Httpconfig.httptokenput(
                    Constant.siteurl +
                      "api/PatientAppointment/callStartedNotificationStatus",
                    {
                      user_id: JSON.parse(patientId),
                      type: "Call Started",
                      title: "Telemedicine patient appointment confirmation",
                      message:
                        "Doctor is Joined to the call waiting for you to join.",
                      appointment_id: JSON.parse(meetingId),
                      appointment_status: JSON.parse(4),
                    }
                  ).then((response) => {});
                }
              }
            );
          }
          this.setState({
            telemedAppointmentInprogressList: telemedAppointmentInprogressList,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    console.log(this.state.telemedAppointmentInprogressList);
  };
  getTelemedicineUpcomingAppointmentList = () => {
    let telemedAppointmentUpcomingList = "";
    var today = moment(new Date());
    var patientImage = "";
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: todayDate,
      status: 1,
      orderBy: "ASC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let upComingAppointmentCount = Object.keys(response.data.data).length;
          if (upComingAppointmentCount > 0) {
            telemedAppointmentUpcomingList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage = appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }
                var mindiff = moment().diff(
                  appointmentdetails.appointment_datetime,
                  "minutes"
                );
                let patientAge = "";
                if (appointmentdetails.patient_tbl.dob != null) {
                  let dob = appointmentdetails.patient_tbl.dob.split("T");
                  let age = dob[0];
                  let ageYears = moment().diff(age, "years");
                  let ageMonths = moment().diff(age, "months");
                  let ageDays = moment().diff(age, "days");
                  if (ageYears > 0) {
                    patientAge = ageYears + " Years";
                  } else if (ageMonths > 0) {
                    patientAge = ageMonths + " Months";
                  } else if (ageDays > 0) {
                    patientAge = ageDays + " Days";
                  }
                }
                return (
                  <div class="upcoming_box">
                    <div class="row">
                      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                        <div class="doc_details">
                          <img src={patientImage ? patientImage : ""} />
                          <div class="doc_name">
                            <h4>
                              {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                              "male"
                                ? "Mr."
                                : "Ms."}
                              {appointmentdetails.patient_tbl.name}
                            </h4>
                            <p>
                              {appointmentdetails.patient_tbl.gender
                                .charAt(0)
                                .toUpperCase() +
                                appointmentdetails.patient_tbl.gender.slice(1)}
                              , <span>{patientAge}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <p class="doc_time">
                          {moment(
                            appointmentdetails.appointment_datetime
                          ).format("LT")}
                        </p>
                      </div>
                    </div>
                    <div class="doc_app_time">
                      <div class="row">
                        <div
                          class="col-lg-5 col-md-5"
                          id={
                            appointmentdetails.appointment_confirm_id +
                            "-" +
                            appointmentdetails.patient_id
                          }
                          onClick={this.modalShow}
                        >
                          <p
                            class="med_det"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                          >
                            <img src="../images/doctor-img/medical-detail.svg" />
                            Medical Details
                          </p>
                        </div>

                        {moment().diff(
                          appointmentdetails.appointment_datetime,
                          "minutes"
                        ) >= 0 ||
                        appointmentdetails.module_type == "consult_now" ? (
                          <div
                            class="col-lg-7 col-md-7"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.joinAppointment.bind(
                              this,
                              appointmentdetails.appointment_confirm_id +
                                "-" +
                                appointmentdetails.patient_id
                            )}
                          >
                            <p class="join_now"> Join Now</p>
                          </div>
                        ) : (
                          <div class="col-lg-7 col-md-7">
                            <p class="join_now">
                              {" "}
                              Appointment{" "}
                              <span>
                                {moment(
                                  appointmentdetails.appointment_datetime
                                ).fromNow()}{" "}
                              </span>{" "}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            );
          }
          this.setState({
            telemedAppointmentUpcomingList: telemedAppointmentUpcomingList,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //past appointments for doctor
  getTelemedicinePastAppointmentList = () => {
    let telemedAppointmentPastList = "";
    var today = moment(new Date());
    var patientImage = "";
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: "",
      status: 0,
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let pastAppointmentCount = Object.keys(response.data.data).length;
          if (pastAppointmentCount > 0) {
            telemedAppointmentPastList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage = appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }
                var mindiff = moment().diff(
                  appointmentdetails.appointment_datetime,
                  "minutes"
                );
                let patientAge = "";
                if (appointmentdetails.patient_tbl.dob != null) {
                  let dob = appointmentdetails.patient_tbl.dob.split("T");
                  let age = dob[0];
                  let ageYears = moment().diff(age, "years");
                  let ageMonths = moment().diff(age, "months");
                  let ageDays = moment().diff(age, "days");
                  if (ageYears > 0) {
                    patientAge = ageYears + " Years";
                  } else if (ageMonths > 0) {
                    patientAge = ageMonths + " Months";
                  } else if (ageDays > 0) {
                    patientAge = ageDays + " Days";
                  }
                }
                if (
                  appointmentdetails.status == 4 ||
                  appointmentdetails.status == 3
                ) {
                  return (
                    <div class="clinic_box">
                      <div class="app_date">
                        <h3>
                          {moment(
                            appointmentdetails.appointment_datetime
                          ).format("ddd DD MMM, YYYY")}
                        </h3>
                      </div>
                      <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                          <div class="doc_details">
                            <img src={patientImage} />
                            <div class="doc_name">
                              <h4>
                                {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                "male"
                                  ? "Mr."
                                  : "Ms."}{" "}
                                {appointmentdetails.patient_tbl.name}
                              </h4>
                              <p>
                                {appointmentdetails.patient_tbl.gender
                                  .charAt(0)
                                  .toUpperCase() +
                                  appointmentdetails.patient_tbl.gender.slice(
                                    1
                                  )}
                                , <span>{patientAge}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <p class="doc_time">
                            {moment(
                              appointmentdetails.appointment_datetime
                            ).format("LT")}
                          </p>
                        </div>
                      </div>
                      <div class="doc_app_time">
                        <div class="row">
                          <div
                            class="col-lg-6 col-md-6"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.modalShow}
                          >
                            <p class="med_det">
                              <img src="../images/doctor-img/medical-detail.svg" />
                              Medical Details
                            </p>
                          </div>
                          <div
                            class="col-lg-6 col-md-6"
                            id={appointmentdetails.appointment_confirm_id}
                          >
                            {appointmentdetails.status == 3 &&
                            appointmentdetails.prescription_id != null ? (
                              <a
                                href={appointmentdetails.prescription_id}
                                target="_blank"
                              >
                                <p class="write_pre"> View Prescription </p>
                              </a>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 3 &&
                            appointmentdetails.prescription_id == null ? (
                              <p class="write_pre"> Appointment Completed</p>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 2 ? (
                              <a
                                id={
                                  appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                                }
                                onClick={this.joinAppointment.bind(
                                  this,
                                  appointmentdetails.appointment_confirm_id +
                                    "-" +
                                    appointmentdetails.patient_id
                                )}
                              >
                                <p class="write_pre"> Write Prescription</p>
                              </a>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 1 ? (
                              <a
                                id={
                                  appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                                }
                                onClick={this.joinAppointment.bind(
                                  this,
                                  appointmentdetails.appointment_confirm_id +
                                    "-" +
                                    appointmentdetails.patient_id
                                )}
                              >
                                <p class="write_pre"> Write Prescription</p>
                              </a>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 4 ? (
                              <a>
                                <p class="app_cancel">
                                  {" "}
                                  Appointment Cancelled{" "}
                                </p>{" "}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            );
          }
          this.setState({
            telemedAppointmentPastList: telemedAppointmentPastList,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  close = () => {
    this.setState({
      modal: false,
      modalvisible: "modal collapse-hide",
    });
  };
  modalShow = (e) => {
    let appointmentId = "";
    let patientId = "";
    let patientData = e.currentTarget.id;
    if (patientData != "") {
      let patientDetails = patientData.split("-");
      appointmentId = patientDetails[0];
      patientId = patientDetails[1];
    }

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Patients/getMedicalDetails/",
      {
        appointment_id: parseInt(appointmentId),
        patient_id: parseInt(patientId),
      }
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        let patientCurrentMedication = "";
        let patientDrugAllergies = "";
        let symptoms_tbls = "";
        let patientMedicalDocument = [];
        let imagedata = [];
        let patientAge = "";
        let chornicDiseases_list = "";
        let relatedMedication = "";
        let drugAllergies = "";
        let symptoms = "";
        let bloodPressureSystolic = "";
        let bloodPressureDiastolic = "";
        let height = "";
        let weight = "";
        let bmi = "";
        let pulse_rate = "";
        let respiratory_rate = "";
        let skip_blood_pressure = "";
        let temperature = "";
        let patientName =
          response.data.data[0].name.charAt(0).toUpperCase() +
          response.data.data[0].name.slice(1);
        let gender = response.data.data[0].gender;
        let diagnostics = "";
        let chornicDiseasesList = "";
        let consultationPurpose = "";

        if (
          Object.keys(response.data.data[0].patient_past_histories).length > 0
        ) {
          let pastLength =
            response.data.data[0].patient_past_histories[0][
              "patient_current_medication"
            ].length;
          if (pastLength > 0) {
            for (var past = 0; past < pastLength; past++) {
              patientCurrentMedication =
                patientCurrentMedication +
                "," +
                response.data.data[0].patient_past_histories[0][
                  "patient_current_medication"
                ][past]["medicinename"];
            }
          }
        }

        if (Object.keys(response.data.data[0].problem_tbls).length > 0) {
          consultationPurpose =
            response.data.data[0].problem_tbls[0]["problem"];
        }
        if (
          Object.keys(response.data.data[0].patient_past_histories).length > 0
        ) {
          chornicDiseasesList =
            chornicDiseases_list +
            response.data.data[0].patient_past_histories[0].past_history;
          let drugAllergiesLength =
            response.data.data[0].patient_past_histories[0][
              "patient_drug_allergies"
            ].length;
          if (drugAllergiesLength > 0) {
            for (var past = 0; past < drugAllergiesLength; past++) {
              patientDrugAllergies =
                patientDrugAllergies +
                "," +
                response.data.data[0].patient_past_histories[0][
                  "patient_drug_allergies"
                ][past]["medicinename"];
            }
          }
        }

        if (Object.keys(response.data.data[0].symptoms_tbls).length > 0) {
          diagnostics = response.data.data[0].symptoms_tbls[0].doctor_diagnosis;
          if (
            response.data.data[0].symptoms_tbls[0].add_more_symptoms != null
          ) {
            symptoms_tbls =
              response.data.data[0].symptoms_tbls[0].add_more_symptoms;
          }
          let symptomsLength =
            response.data.data[0].symptoms_tbls[0]["symptoms"].length;

          if (symptomsLength > 0) {
            for (var past = 0; past < symptomsLength; past++) {
              symptoms_tbls =
                symptoms_tbls +
                "," +
                response.data.data[0].symptoms_tbls[0]["symptoms"][past][
                  "description"
                ];
            }
          }
        }

        let documentsLength =
          response.data.data[0].patient_past_histories.length;
        if (documentsLength > 0) {
          for (var pastdoc = 0; pastdoc < documentsLength; pastdoc++) {
            for (
              let doc = 0;
              doc <
              response.data.data[0].patient_past_histories[pastdoc][
                "patient_medical_document"
              ].length;
              doc++
            ) {
              patientMedicalDocument[doc] =
                response.data.data[0].patient_past_histories[pastdoc][
                  "patient_medical_document"
                ][doc].replace("jpeg", "jpg");
              imagedata[doc] =
                '<div class="med_upload_box"><img src=' +
                patientMedicalDocument[doc] +
                "><p>Health record " +
                (doc + 1) +
                "</p></div>";
            }
          }
        }
        var patient_dob = "";

        if (response.data.data[0].dob != null) {
          let dob = response.data.data[0].dob.split("T");
          let age = dob[0];
          let ageYears = moment().diff(age, "years");
          let ageMonths = moment().diff(age, "months");
          let ageDays = moment().diff(age, "days");
          if (ageYears > 0) {
            patient_dob = ageYears + " Years";
          } else if (ageMonths > 0) {
            patient_dob = ageMonths + " Months";
          } else if (ageDays > 0) {
            patient_dob = ageDays + " Days";
          }
        }
        if (
          symptoms_tbls.indexOf(",") > -1 &&
          response.data.data[0].symptoms_tbls[0].add_more_symptoms == null
        ) {
          symptoms_tbls = symptoms_tbls.substr(1);
        } else {
          symptoms_tbls = symptoms_tbls;
        }
        if (response.data.data[0].vital_informations) {
          if (
            Object.keys(response.data.data[0].vital_informations).length > 0
          ) {
            bloodPressureSystolic =
              response.data.data[0].vital_informations[0]
                .blood_pressure_systolic;
            bloodPressureDiastolic =
              response.data.data[0].vital_informations[0]
                .blood_pressure_diastolic;
            if (response.data.data[0].vital_informations[0].height) {
              var realFeet =
                (parseFloat(
                  response.data.data[0].vital_informations[0].height
                ) *
                  0.3937) /
                12;
              var feet = Math.floor(realFeet);
              var inches = Math.round((realFeet - feet) * 12);
              response.data.data[0].vital_informations[0].height =
                feet + "'," + inches + '"';
            }
            height = response.data.data[0].vital_informations[0].height;
            weight = response.data.data[0].vital_informations[0].weight;
            bmi = response.data.data[0].vital_informations[0].bmi;
            pulse_rate = response.data.data[0].vital_informations[0].pulse_rate;
            respiratory_rate =
              response.data.data[0].vital_informations[0].respiratory_rate;
            skip_blood_pressure =
              response.data.data[0].vital_informations[0].blood_sugar;
            temperature =
              response.data.data[0].vital_informations[0].temperature;
          }
        }
        this.setState({
          patientName:
            patientName.charAt(0).toUpperCase() + patientName.slice(1),
          gender: gender,
          dob: patient_dob,
          profilePic: response.data.data[0].profile_pic,
          relation: response.data.data[0].master_relationship.relation_name,
          chornicDiseases_list: chornicDiseasesList,
          relatedMedication: relatedMedication + patientCurrentMedication,
          drugAllergies: patientDrugAllergies,
          symptoms: symptoms_tbls,
          relationPatientId: response.data.data[0].relation_patient_id,
          appointmentId: appointmentId, //problem_tbls
          bloodPressureSystolic: bloodPressureSystolic,
          bloodPressureDiastolic: bloodPressureDiastolic,
          height: height,
          weight: weight,
          bmi: bmi,
          pulse_rate: pulse_rate,
          respiratory_rate: respiratory_rate,
          skip_blood_pressure: skip_blood_pressure,
          temperature: temperature,
          labTests: "",
          patientMedicalDocument: patientMedicalDocument,
          imagedata: imagedata,
          problem: response.data.data[0].problem_tbls[0].problem,
          doctorDiagnosis: diagnostics,
          consultationPurpose: consultationPurpose,
        });
      }

      this.setState({
        modal: true,
        modalvisible: "modal collapse-show",
      });
    });
  };
  joinAppointment = (id) => {
    let data = id.split("-");
    let appointmentId = data[0];
    let patientId = data[1];
    ls.set("DoctorSelectedConsultation", appointmentId);
    ls.set("DoctorSelectedPatientId", patientId);

    window.location.href = "./Doctorvideoconsultation";
  };
  goback = () => {
    setTimeout(() => this.props.history.push("./Doctordashboard"), 2000);
  };
  render() {
    return (
      <main id="main">
        <DoctorHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <div
            id="upcominggModal"
            class={this.state.modalvisible}
            role="dialog"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    onClick={this.close}
                  >
                    &times;
                  </button>
                  <div>
                    <h4>Medical Details :</h4>
                  </div>
                  <h4 class="modal-title">{this.state.patientName}</h4>
                  <p>
                    {this.state.gender
                      ? translate(
                          this.state.gender.charAt(0).toUpperCase() +
                            this.state.gender.slice(1)
                        )
                      : ""}
                    ,{this.state.dob}
                  </p>
                </div>
                <div class="modal-body">
                  <div class="med_details">
                    <p>
                      Patient UHID: <span>{this.state.relationPatientId}</span>
                    </p>
                  </div>
                  <div class="med_details">
                    <h2>Purpose of Visit</h2>
                    <p>{this.state.consultationPurpose}</p>
                  </div>
                  <div class="med_details">
                    <h2>Vital Information</h2>
                    <p>
                      Pulse rate:{" "}
                      <span>
                        {this.state.pulse_rate ? this.state.pulse_rate : "--"} ,
                        Breaths per Minute
                      </span>
                    </p>
                    <p>
                      Blood Pressure :{" "}
                      {this.state.bloodPressureDiastolic
                        ? this.state.bloodPressureDiastolic + " mmHG"
                        : "--"}
                      -
                      {this.state.bloodPressureSystolic
                        ? this.state.bloodPressureSystolic + " mmHG"
                        : "--"}
                    </p>
                    <p>
                      Blood Sugar:{" "}
                      <span>
                        {this.state.skip_blood_pressure
                          ? this.state.skip_blood_pressure
                          : "--"}
                      </span>
                    </p>
                    <p>
                      Temperature:{" "}
                      <span>
                        {this.state.temperature ? this.state.temperature : "--"}{" "}
                        °F
                      </span>
                    </p>
                    <p>
                      Respiratory :{" "}
                      <span>
                        {this.state.respiratory_rate
                          ? this.state.respiratory_rate
                          : "--"}{" "}
                        , Breaths per Minute
                      </span>
                    </p>
                    <p>
                      Height:{" "}
                      <span>
                        {this.state.height
                          ? this.state.height + " Feet/Inches"
                          : "--"}
                      </span>
                    </p>
                    <p>
                      BMI :{" "}
                      <span>
                        {this.state.bmi ? this.state.bmi : "--"} Kg/m2
                      </span>
                    </p>
                    <p>
                      Weight :{" "}
                      <span>
                        {this.state.weight ? this.state.weight : "--"} Kg
                      </span>
                    </p>
                  </div>
                  <div class="med_details">
                    <h2>Past Medical History</h2>
                    <p>
                      Chronic Diseases:
                      {this.state.chornicDiseases_list
                        ? this.state.chornicDiseases_list
                        : "--"}
                    </p>
                    <p>
                      Related medicines:
                      {this.state.relatedMedication
                        ? this.state.relatedMedication
                        : "--"}
                    </p>
                    <p>
                      Drug Allergies:
                      {this.state.drugAllergies
                        ? this.state.drugAllergies
                        : "--"}
                    </p>
                  </div>
                  <div class="med_details">
                    <h2>Symptoms</h2>
                    <p>{this.state.symptoms ? this.state.symptoms : "--"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section id="telemed_dashboard">
            <div class="container-fluid back_section">
              <div class="row">
                <div class="col-md-12">
                  <div class="section_main_head" onClick={this.goback}>
                    <h2>
                      <img src="../images/patient//img/Doctors_List/View Profile/Back.svg" />{" "}
                      Back
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="telemed_content_section">
                    <div class="telemed_row">
                      <h3>Telemedicine Patients</h3>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        {/* {this.state.telemedAppointmentInprogressList ? (
                          <React.Fragment>
                            <div class="telemed_head">
                              <h2>Ongoing Appointments</h2>
                            </div>
                            {this.state.telemedAppointmentInprogressList}
                          </React.Fragment>
                        ) : (
                          ""
                        )} */}

                        <div class="telemed_up">
                          <div class="telemed_head">
                            <h2> Upcoming Appointments</h2>
                          </div>
                          <div class="telemed_date">
                            <h3>{moment().format(" dddd  Do MMMM, YYYY")}</h3>
                          </div>

                          <div class="telemed_box">
                            {this.state.telemedAppointmentUpcomingList != "" ? (
                              this.state.telemedAppointmentUpcomingList
                            ) : (
                              <div class="no_appoint">No Appointments</div>
                            )}
                          </div>
                        </div>
                        <div class="pending_main">
                          <div class="pending_head">
                            <h2>
                              Pending Appointments
                              {/* <span>View all</span> */}
                            </h2>
                          </div>
                          {this.state.telemedFutureAppointmentList ? (
                            <div class="pending_box">
                              {this.state.telemedFutureAppointmentList}
                            </div>
                          ) : (
                            <div class="no_appoint">No Appointments</div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="clinic_main">
                          <div class="clinic_head">
                            <h2>Past Appointments</h2>
                          </div>
                          {this.state.telemedAppointmentPastList ? (
                            this.state.telemedAppointmentPastList
                          ) : (
                            <div class="no_appoint">No Appointments</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </I18nPropvider>
        <PatientFooter />
      </main>
    );
  }
}
