import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import { reactLocalStorage } from "reactjs-localstorage";
import Constant from "../../constants";
import {
  usePaystackPayment,
  PaystackButton,
  PaystackConsumer,
} from "react-paystack";

import { FormattedMessage } from "react-intl";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Sanarheader";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientFooter from "../patient/Patientfooter";
import PatientGetLocations from "../patient/PatientGetLocaitons";
import paymentGateway from "../patient/paymentGateway";
import FileBase64 from "react-file-base64";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import * as SecureLS from "secure-ls";
import { fi } from "date-fns/locale";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const moment = require("moment");

export default class PatientSelectRetailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { country_name: "India" },
      indianStates: [],
      indianCities: [],
      files: [],
      errors: {},
      total1: "0.00",
      total: "0.00",
      deliveryCharges: "0.00",
      toBePaid: "0.00",
      payableAmount: "0.00",
      relativeId: "",
      seletedRetaielId: "",
      confirm: "",
      addressId: "",
      prescriptionImage: "",
      AddressList: "",
      deliveryAddress: "",
      selectedAddressId: "",
      addressSelectionSection: "collapse-show",
      retailerSelectionSection: "collapse-hide",
      add_address_form: "add_address_form collapse-hide",
      showcoupon: false,
      coupon_id: "",
      coupon: "",
      couponDiscount: "",
      totDiscountAmount: "",
      cartDiscount: "",
      totalDiscount: "",
      couponId: "",
      totalmrp: "",
      lat_long: "",
      totalsavings: "",
      deduct_points: "",
      wallet_amount_perpoint: "",
      wallet_points: "",
      totwalletDeductuAmount: "",
      paymentMethod: "",
      deliveryType: "",
      slotBookingDate: "",
      slotBookingTime: "",
    };

    this.addfamily = this.addfamily.bind(this);
    this.caliculateTotals = this.caliculateTotals.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    console.log("select reatiler");
    const handle = this.props.match.params.id;
    var txnstatus = this.props.match.params.txnstatus;

    if (txnstatus != undefined) {
      this.state.txnstatus = txnstatus;
    } else {
      txnstatus = "";
    }

    let userData = ls.get("userObjEnc");
    let cartData = "";
    cartData = ls.get("cartIdEnc");
    if (userData) {
      if (userData != "") {
        this.state.patientId = userData.patient_id;
        this.forceUpdate();
        this.setState({
          name: userData.name,
          user_mobile: userData.mobile_number,
          email_id: userData.email,
          token: userData.accessToken,
          patientId: userData.patient_id,
          profile_image: "",
        });
        this.forceUpdate();
        this.getPatientRelations(userData.patient_id);
        this.getAllAddresses(userData.patient_id);
        this.getaddressInfo(userData.patient_id);
        this.getCompleteStates();
      }
    } else {
      window.location.href = "/login";
    }
    if (cartData != "") {
      this.state.cartId = cartData;
      this.forceUpdate();
    }
    if (txnstatus == "") {
      if (handle) {
        this.validateTransaction(handle);
      }
    } else {
      var deliveryAddress = ls.get("deliveryAddress");
      if (deliveryAddress != "") {
        this.state.selectedAddressId = deliveryAddress;
        this.forceUpdate();
        this.ValidateSelectedAddress();
      }
    }
  };

  // Get relatives
  getPatientRelations = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtives/" + patientId
    ).then((response) => {
      let profile_pic = "";
      if (Object.keys(response.data.data).length > 0) {
        const stateRelationsOptions = response.data.data.map((data, index) => {
          return (
            <div class="radio">
              <label>
                <input
                  type="radio"
                  name="optradio"
                  defaultValue={
                    data.master_relationship
                      ? data.name.charAt(0).toUpperCase() +
                        data.name.slice(1) +
                        " (" +
                        data.master_relationship.relation_name +
                        ")"
                      : data.name.charAt(0).toUpperCase() + data.name.slice(1)
                  }
                  id={data.id}
                  onClick={this.updateSelectedRelatives}
                />
                {data.master_relationship
                  ? data.name.charAt(0).toUpperCase() +
                    data.name.slice(1) +
                    " (" +
                    data.master_relationship.relation_name +
                    ")"
                  : data.name.charAt(0).toUpperCase() + data.name.slice(1)}
              </label>
            </div>
          );
        });

        this.setState({
          stateRelationsOptions: stateRelationsOptions,
          defaultValue: patientId,
        });
      }
    });
  };
  updatedeliveTypeId = (event) => {
    let pickupAmount = "";
    let delivery = event.currentTarget.value;
    if (delivery == 1) {
      $("#map_direction").show();
      $("#retailer_info").show();
    } else {
      $("#map_direction").hide();
      $("#retailer_info").hide();
    }

    this.state.deliveryId = delivery;

    // based on store pickup delivery charges hide
    if (this.state.deliveryId != 1) {
      pickupAmount = this.state.payableAmount;
    } else {
      pickupAmount =
        this.state.payableAmount - parseFloat(this.state.deliveryCharges);
    }
    this.state.pickupAmount = pickupAmount;
    this.forceUpdate();
  };
  //Add family member
  addfamily = () => {
    ls.set("ordermedicineredirectAddfamily", 1);
    this.props.history.push("./Patientadd");
  };
  // select relatives for the order
  updateSelectedRelatives = (event) => {
    let relativeId = event.currentTarget.id;
    this.state.relativeId = relativeId;
    this.state.selectedRelative = event.target.defaultValue;
    this.forceUpdate();
  };

  // Get all the Delivery Addresses

  getAllAddresses = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/OM/deliveryAddress/findAllDeliveryAddress/" +
        patientId
    )
      .then((response) => {
        let profile_pic = "";
        if (Object.keys(response.data.data).length > 0) {
          const deliveryAddresses = response.data.data.map((data, index) => {
            return (
              <div
                class="save_add_box"
                id={data.id}
                onClick={this.selectedAddress}
              >
                <div class="row">
                  <div class="col-md-8">
                    <div class="save_name">
                      <input
                        type="radio"
                        name="address"
                        id={data.id}
                        onChange={this.handleAddressSelected}
                        checked={
                          this.state.selectedAddressId === data.id
                            ? "checked"
                            : ""
                        }
                      />{" "}
                      <h2>
                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}{" "}
                        <span>({data.address_type})</span>
                      </h2>
                      <p>{data.mobile_no}</p>
                      <h6 id={"address-" + data.id}>{data.address}</h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="set_edit">
                      <a href="#" id={data.id} onClick={this.addAddress}>
                        {" "}
                        <p>
                          <img
                            class="search_img"
                            src="https://www.pngfind.com/pngs/m/275-2755033_edit-png-file-on-phone-svg-edit-icon.png"
                          />
                          Edit
                        </p>
                      </a>
                      <a href="#" id={data.id} onClick={this.setDefaultAddress}>
                        {" "}
                        <p>
                          <img
                            class="search_img"
                            src="https://www.pikpng.com/pngl/m/418-4180575_file-symbol-rain-wikimedia-commons-open-3-dots.png"
                          />
                          Set as default
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          });

          this.setState({
            deliveryAddresses: deliveryAddresses,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Selected Address
  selectedAddress = (event) => {
    let selectedAddressId = event.currentTarget.id;
    $("#navbardrop").html($("#address-" + selectedAddressId).html());
    $(".showdropdown").hide();
    this.state.addressId = selectedAddressId;
    this.forceUpdate();
  };
  //show address dropdown
  showAddress = () => {
    $(".showdropdown").show();
  };

  getRetailerList = () => {
    let cartId = this.state.cartId;
    var lat_long = "";
    if (this.state.lat_long == "") {
      lat_long = ls.get("selectedAddress");
      this.state.lat_long = lat_long;
      this.forceUpdate();
    }
    Httpconfig.httptokenpost(Constant.siteurl + "api/ProductSearch", {
      cart_id: this.state.cartId,
      lat_lang: this.state.lat_long,
    })
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          if (Object.keys(response.data.retailerInfo).length > 0) {
            var retailerList = response.data.retailerInfo;
            let patientEmail =
              response.data.patientData != null
                ? response.data.patientData.tbl_user.email
                : "";
            let storeName =
              response.data.retailerInfo[0].retailerInfo.storename;
            let mobile_number =
              response.data.retailerInfo[0].retailerInfo.mobile_number;
            let storeAddress =
              response.data.retailerInfo[0].retailerInfo.address;

            let deliveryDays =
              response.data.retailerInfo[0].retailerInfo.set_delivery_days;

            this.setState({
              patientEmail: patientEmail,
              storeName: storeName,
              mobile_number: mobile_number,
              storeAddress: storeAddress,
              deliveryDays: deliveryDays,
            });

            var totalCartItems = Object.keys(response.data.cartData).length;
            if (Object.keys(response.data.retailerInfo).length == 1) {
              let retatilerId = response.data.retailerInfo[0].retailerInfo.id;
              this.state.retailerList = "";
              this.forceUpdate();
              this.setState({
                retailerList: retailerList,
                totalCartItems: totalCartItems,
              });
              this.forceUpdate();
              this.selectedRetailer(retatilerId);
            } else {
              let retatilerId = response.data.retailerInfo[0].retailerInfo.id;
              this.state.retailerList = "";
              this.forceUpdate();
              this.setState({
                retailerList: "",
                retailerList: retailerList,
                totalCartItems: totalCartItems,
              });
              this.forceUpdate();
              this.selectedRetailer(retatilerId);
            }
          } else {
            if (Object.keys(response.data.retailerInfo).length == 0) {
              this.hideRetailerSelectionSection();
              $("#noret_modal").show();
            }
          }
          this.state.retailerList = "";
          this.forceUpdate();
          this.setState({
            retailerList: "",
            retailerList: retailerList,
            totalCartItems: totalCartItems,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  selectedRetailer = (id) => {
    var retailerData = "";
    var nostock = "";
    let total1 = 0;
    let total = 0;
    let tot_dis = 0;
    let oneProductDiscount = 0;
    let PriceDisc = "";
    let num = 0;
    let paidtotal = 0;
    let showcoupon = "";
    let totalmrp = 0;
    this.state.seletedRetaielId = id;
    this.state.totalmrp = "";
    this.forceUpdate();

    Httpconfig.httptokenpost(Constant.siteurl + "api/ProductSearch", {
      cart_id: this.state.cartId,
      lat_lang: this.state.lat_long,
    }).then((response) => {
      if (Object.keys(response.data).length > 0) {
        var retailerList = response.data.retailerInfo;
        this.state.retailerList = "";
        this.forceUpdate();
        this.setState({
          retailerList: "",
          retailerList: retailerList,
        });
        this.forceUpdate();

        if (
          Object.keys(retailerList).length > 0 &&
          this.state.seletedRetaielId != ""
        ) {
          this.showRetailerSelectionSection();
          retailerList
            .filter(
              (item) => item.retailerInfo.id == this.state.seletedRetaielId
            )
            .map((data, index) => {
              if (data.retailerInfo.set_delivery_slab != "") {
                this.state.deliveryCharges =
                  data.retailerInfo.set_delivery_slab;
                console.log("--440--", this.state.deliveryCharges);
              } else {
                this.state.deliveryCharges = 0;
              }
              this.state.cartDiscount = data.retailerInfo.discount_slab;
              this.state.currency = data.retailerInfo.currency;
              this.state.retailerName = data.retailerInfo.storename;
              this.state.retailerAddress = data.retailerInfo.address;
              this.state.available =
                Object.keys(data.stockAvailable).length +
                "/" +
                this.state.totalCartItems;
              this.state.distance = data.distance;
              this.state.wallet_points = data.retailerInfo.wallet_points;
              this.state.deduct_points = data.retailerInfo.deduct_points;
              this.state.wallet_amount_perpoint =
                data.retailerInfo.wallet_amount_perpoint;
              this.state.totwalletDeductuAmount =
                data.retailerInfo.deduct_points *
                data.retailerInfo.wallet_amount_perpoint;

              retailerData = data.stockAvailable.map((prodcutsList, index) => {
                paidtotal = 0;

                paidtotal = parseFloat(prodcutsList.mrp * prodcutsList.status);
                oneProductDiscount =
                  parseFloat(paidtotal * prodcutsList.discount) / 100;
                paidtotal = paidtotal - oneProductDiscount;
                total = parseFloat(total) + parseFloat(paidtotal);

                totalmrp += parseFloat(prodcutsList.mrp * prodcutsList.status);

                tot_dis += oneProductDiscount;

                var toBePaid = 0.0;
                toBePaid =
                  parseFloat(total) + parseFloat(this.state.deliveryCharges);

                if (toBePaid > 0) {
                  showcoupon = true;
                }
                this.setState({
                  totalDiscount: tot_dis,
                  totalsavings: tot_dis,
                  totalAmtDiscount: PriceDisc,
                  toBePaid: toBePaid,
                  payableAmount: toBePaid,
                  showcoupon: showcoupon,
                });
                return (
                  <div class="med_name">
                    <h2>
                      {(num = num + 1)}.{" "}
                      {prodcutsList.products_master_tbl.medicinename}
                      <span>
                        {" "}
                        <div class="currency_left">{this.state.currency} </div>
                        <span
                          class="mrp"
                          id={"mrp-" + prodcutsList.medicine_id}
                        >
                          {parseFloat(paidtotal)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </span>
                      </span>
                      <h4 class="sm_mrp">
                        MRP {""}
                        {this.state.currency}{" "}
                        <p
                          class="sm-mrp"
                          id={"sm-mrp-" + prodcutsList.medicine_id}
                        >
                          {parseFloat(prodcutsList.mrp)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                        <p style={{ float: "right" }}>
                          {" "}
                          <p
                            class="sm-discount"
                            id={"sm-discount-" + prodcutsList.discount}
                          >
                            {" "}
                            {prodcutsList.discount + "%"} off
                          </p>
                        </p>
                      </h4>
                    </h2>
                    <div class="med_inner_deails">
                      <div>
                        <h6>{prodcutsList.products_master_tbl.manufacturer}</h6>
                        <p>{prodcutsList.products_master_tbl.size}</p>
                        <p>{prodcutsList.products_master_tbl.strength}</p>
                      </div>
                      <div
                        class="input-group number-spinner"
                        style={{ position: "absolute", right: "15" }}
                      >
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default minus_btn"
                            data-dir="dwn"
                            id={"qty-" + prodcutsList.medicine_id}
                            onClick={this.qtyDecrementUpdateQty.bind(
                              this,
                              prodcutsList.medicine_id,
                              prodcutsList.id,
                              prodcutsList.mrp,
                              prodcutsList.quantity,
                              prodcutsList.discount,
                              prodcutsList.CGST,
                              prodcutsList.SGST,
                              prodcutsList.IGST
                            )}
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                        </div>
                        <input
                          type="text"
                          class="form-control text-center"
                          readOnly="readOnly"
                          value={prodcutsList.status}
                          id={
                            "qty-" +
                            prodcutsList.medicine_id +
                            "-" +
                            prodcutsList.CGST.replace(".", "") +
                            "-" +
                            prodcutsList.SGST.replace(".", "") +
                            "-" +
                            prodcutsList.IGST.replace(".", "")
                          }
                        />
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default plus_btn"
                            data-dir="up"
                            id={"qty-" + prodcutsList.medicine_id}
                            onClick={this.qtyIncrementUpdateQty.bind(
                              this,
                              prodcutsList.medicine_id,
                              prodcutsList.id,
                              prodcutsList.mrp,
                              prodcutsList.quantity,
                              prodcutsList.discount,
                              prodcutsList.CGST,
                              prodcutsList.SGST,
                              prodcutsList.IGST
                            )}
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <h5>
                      <span
                        className="available-qty"
                        style={{ float: "right", marginTop: "0px" }}
                      >
                        {prodcutsList.quantity} items available
                      </span>{" "}
                    </h5>
                  </div>
                );
              });
              nostock = data.stockNotAvailable.map((prodcutsList, index) => {
                return (
                  <div class="med_names">
                    <h2>
                      {(num = num + 1)}.{" "}
                      {prodcutsList.products_master_tbl.medicinename}{" "}
                      <span>
                        {this.state.currency + "\t"}{" "}
                        <span
                          class="mrp"
                          id={"mrp-" + prodcutsList.medicine_id}
                        >
                          {" "}
                          {prodcutsList.mrp
                            ? parseFloat(prodcutsList.mrp)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : "0.00"}
                        </span>
                      </span>
                    </h2>
                    <h6>{prodcutsList.products_master_tbl.manufacturer}</h6>
                    <p>{prodcutsList.products_master_tbl.size}</p>
                    <p>{prodcutsList.products_master_tbl.strength}</p>
                    <div class="input-group number-spinner">
                      <div class="input-group-btn">
                        <button
                          class="btn btn-default minus_btn"
                          data-dir="dwn"
                          id={"minus-" + prodcutsList.medicine_id}
                        >
                          <i class="fa fa-minus"></i>
                        </button>
                      </div>
                      <input
                        type="text"
                        class="form-control text-center"
                        readOnly="readOnly"
                        defaultValue={prodcutsList.quantity}
                        id={
                          "qty-" +
                          prodcutsList.medicine_id +
                          "-" +
                          prodcutsList.CGST +
                          "-" +
                          prodcutsList.IGST +
                          "-" +
                          prodcutsList.SGST
                        }
                      />
                      <div class="input-group-btn">
                        <button
                          class="btn btn-default plus_btn"
                          data-dir="up"
                          id={"plus-" + prodcutsList.medicine_id}
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <h5>Out of stock</h5>
                  </div>
                );
              });
            });
        }

        this.state.total = total;
        this.state.retailerSelectedProcuctInfo = retailerData;
        this.state.retailerSelectedNostockProcuctInfo = nostock;
        this.state.totalmrp = totalmrp;
        this.forceUpdate();
      }
    });
  };

  //  quanatity Increment

  qtyIncrementUpdate = (prodcutId, rowId, mrp) => {
    let qty = $("#qty-" + prodcutId).val();
    if (qty >= 0 && qty < 50) {
      qty = parseInt(qty) + 1;
      $("#qty-" + prodcutId).val("");
      $("#qty-" + prodcutId).val(qty);
      this.updateQuantity(prodcutId, rowId);
      this.caliculateTotals();
    } else {
      if (parseInt(qty) >= 50) {
        toast.error("Qunatity should not be more that 50");
        return false;
      }
    }
  };

  // quanatity decrement

  qtyDecrementUpdate = (prodcutId, rowId, mrp) => {
    let qty = $("#qty-" + prodcutId).val();
    if (qty >= 1) {
      qty = parseInt(qty) - 1;
      $("#qty-" + prodcutId).val("");
      $("#qty-" + prodcutId).val(qty);
      this.updateQuantity(prodcutId, rowId);
      this.caliculateTotals();
    }
  };

  //  quanatity Increment

  qtyIncrementUpdateQty = (
    prodcutId,
    rowId,
    mrp,
    aquantity,
    discount,
    CGST,
    SGST,
    IGST
  ) => {
    CGST = CGST.replace(".", "");
    SGST = SGST.replace(".", "");
    IGST = IGST.replace(".", "");

    let qty = $(
      "#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST
    ).val();

    let total = 0;
    let discountCaliculation = 0;
    if (parseInt(qty) >= parseInt(aquantity)) {
      toast.error("Quantity should not be greater than availble Quantity");
      return false;
    } else {
      if (qty >= 50) {
        toast.error("Qunatity should not be more that 50");
        return false;
      }
    }
    if (qty >= 0) {
      qty = parseInt(qty) + 1;
      $("#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST).val("");
      $("#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST).val(qty);
      $("#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST).val(qty);
      $("#sm-mrp-" + prodcutId).val();
      total = parseFloat(total) + parseFloat(qty * mrp);
      total = parseFloat(total) - parseFloat((total * discount) / 100);
      $("#mrp-" + prodcutId).html(
        parseFloat(total)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      );

      this.updateQuantity(prodcutId, rowId);
      this.caliculateTotals();
    }
  };

  // quanatity decrement

  qtyDecrementUpdateQty = (
    prodcutId,
    rowId,
    mrp,
    quantity,
    discount,
    CGST,
    SGST,
    IGST
  ) => {
    CGST = CGST.replace(".", "");
    SGST = SGST.replace(".", "");
    IGST = IGST.replace(".", "");
    let qty = $(
      "#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST
    ).val();
    let total = 0;
    let discountCaliculation = 0;

    if (qty >= 2) {
      qty = parseInt(qty) - 1;
      $("#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST).val("");
      $("#qty-" + prodcutId + "-" + CGST + "-" + SGST + "-" + IGST).val(qty);
      total = parseFloat(total) + parseFloat(qty * mrp);
      total = parseFloat(total) - parseFloat((total * discount) / 100);
      $("#mrp-" + prodcutId).html(
        parseFloat(total)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      );

      this.updateQuantity(prodcutId, rowId);
      this.caliculateTotals();
    }
  };

  // Update quantity

  updateQuantity = (productId, rowId) => {
    let cartId = this.state.cartId;
    let quanatity = $("#qty-" + productId).val();
    Httpconfig.httptokenpost(Constant.siteurl + "api/OM/Cart", {
      cart_id: cartId,
      patient_id: this.state.patientId,
      medicine_id: productId,
      quantity: quanatity,
      shop_id: "",
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.miniCart();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //Caliculate Totals

  caliculateTotals = () => {
    console.log("edferfrfr");
    let total1 = 0;
    let total = 0;
    let deliveryCharges = "";
    let quantity = "";
    let retailerId = this.state.seletedRetaielId;
    let totaldiscount = 0;
    let totalsavings = 0;
    let totalmrp = 0;

    $(".medicine_sec .med_list .med_name").each(function () {
      let mrp = $(this).find(".sm-mrp").html();
      let discount = $(this).find(".sm-discount").html();

      $(this)
        .find(".form-control")
        .each(function () {
          let className = $(this).attr("class");
          if (className == "form-control text-center") {
            quantity = $(this).val();
            discount = parseFloat(discount.replace(/%/g, ""));
            let discountPercent = parseFloat(discount) / 100;
            if (quantity != "") {
              mrp = parseFloat(mrp.replace(/,/g, ""));
              total = parseFloat(quantity * mrp);
              total = parseFloat(total) - parseFloat(total * discountPercent);
              let discountAmount =
                parseFloat(quantity * mrp) -
                (parseFloat(quantity * mrp) * discount) / 100;
              totaldiscount =
                totaldiscount +
                (parseFloat(quantity * mrp) - parseFloat(discountAmount));
              totalmrp += mrp * quantity;
              if (quantity != "") {
                total1 = parseFloat(total1) + parseFloat(total);
              }
            }
          }
        });
    });
    this.state.totalDiscount = totaldiscount;
    this.state.totalsavings = totaldiscount;
    this.forceUpdate();
    this.state.total = total1;
    this.state.toBePaid =
      parseFloat(total1) + parseFloat(this.state.deliveryCharges);
    $("#total-price-" + retailerId).html(
      this.state.toBePaid.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
    this.state.payableAmount = this.state.toBePaid;
    this.state.totalmrp = totalmrp;
    this.forceUpdate();
  };

  validate = () => {
    console.log("patient select retailer");
    let relativeId = this.state.relativeId;
    let addressId = this.state.selectedAddressId;
    let retailerId = this.state.seletedRetaielId;
    let toBePaid = this.state.toBePaid;
    let total = this.state.total;
    let prescriptionCount = 0;
    let medicaldoc_pic = [];
    if (this.state.files) {
      prescriptionCount = Object.keys(this.state.files).length;
    }
    let uploaded = prescriptionCount;
    let count = 0;
    if (relativeId == "") {
      toast.error("Please select the patient");
      count = count + 1;
    } else if (addressId == "") {
      toast.error("Please select the delivery address");
      count = count + 1;
    } else if (retailerId == "") {
      toast.error("Please select the retailer");
      count = count + 1;
    } else if (!this.state.deliveryType) {
      toast.error("Please select the delivery type");
      count = count + 1;
    } else if (!this.state.paymentMethod) {
      toast.error("Please select the paymentMethod");
      count = count + 1;
    } else if (!this.state.slotBookingDate) {
      toast.error("Please select the slot date");
      count = count + 1;
    } else if (!this.state.slotBookingTime) {
      toast.error("Please select the slot time");
      count = count + 1;
    } else if (total == "0.00") {
      toast.error("Paid amount should not be zero");
      count = count + 1;
    } else if ($("#terms").prop("checked") == false) {
      toast.error("Please accept Terms & Conditions");
      count = count + 1;
    }

    $(".medicine_sec .med_list .med_name").each(function () {
      let mrp = $(this).find(".sm-mrp").html();
      let availableqty = $(this).find(".available-qty").html();
      let quantity = 0;

      $(this)
        .find(".form-control")
        .each(function () {
          let className = $(this).attr("class");
          if (className == "form-control text-center") {
            quantity = $(this).val();

            if (parseInt(quantity) > parseInt(availableqty)) {
              toast.error("Available quantity is less than order quantity ");
              count = count + 1;
            }
          }
        });
    });

    if (count > 0) {
    } else {
      let total = 0;
      let quantity = "";
      let id = "";
      let order_details = [];
      let productDetails = [];
      let data = "";
      let medicineId = "";
      let retailerId = this.state.seletedRetaielId;
      let patientId = this.state.patientId;
      let addressId = this.state.selectedAddressId;
      let relativeId = this.state.relativeId;
      let patientEmail = this.state.patientEmail;
      let deliveryCharges = this.state.deliveryCharges;
      let totals = this.state.total;
      let toBePaid = this.state.toBePaid;
      let prescriptionImage = this.state.prescriptionImage;
      let cartId = this.state.cartId;
      let uploadedFiles = this.state.files;
      // $("#loading_processOrder").show();

      $(".medicine_sec .med_list .med_name").each(function () {
        let mrp = $(this).find(".sm-mrp").html();
        $(this)
          .find(".form-control")
          .each(function () {
            let className = $(this).attr("class");
            if (className == "form-control text-center") {
              quantity = $(this).val();
              data = $(this).attr("id");
              data = data.split("-");
              medicineId = data[1];
              if (quantity != "") {
                total =
                  parseFloat(total) +
                  parseFloat(quantity * parseFloat(mrp.replace(/,/g, "")));
                order_details = {
                  retailer_id: retailerId,
                  patient_id: patientId,
                  medicine_id: medicineId,
                  quantity: quantity,
                  amount: mrp,
                  expiry_date: "",
                  batch: "",
                  vat: "0",
                  CGST: data[2] ? data[2] : "",
                  SGST: data[3] ? data[3] : "",
                  IGST: data[4] ? data[4] : "",
                };
              }
            }
            productDetails.push(order_details);
          });
      });
      if (this.state.wallet_points < this.state.deduct_points) {
        var deduct_pointsVal = this.state.wallet_points;
        var totwallet_amountVal =
          this.state.wallet_points * this.state.wallet_amount_perpoint;
      } else {
        var deduct_pointsVal = this.state.deduct_points;
        var totwallet_amountVal =
          this.state.deduct_points * this.state.wallet_amount_perpoint;
      }
      data = {
        retailer_id: retailerId,
        patient_id: patientId,
        patient_address_id: addressId,
        relative_id: relativeId,
        cart_level_discount: "",
        delivery_charges: deliveryCharges,
        net_amount: total,
        payable_amount: this.state.payableAmount,
        coupan_id: this.state.couponId,
        coupan_name: this.state.coupon,
        coupan_value: this.state.couponDiscount,
        coupon_amount: this.state.totDiscountAmount,
        prescription: prescriptionImage,
        order_details: productDetails,
        discount: this.state.totalDiscount,
        delivery_agent: "",
        cart_id: cartId,
        patientEmail: patientEmail,
        timeZone: moment.tz.guess(),
        transaction_id: "",
        deliveryType: this.state.deliveryType,
        paymentMethod: this.state.paymentMethod,
        slotBookingDate: this.state.slotBookingDate,
        slotBookingTime: this.state.slotBookingTime,
        wallet_points: this.state.wallet_points,
        deduct_points: deduct_pointsVal,
        wallet_amount_perpoint: this.state.wallet_amount_perpoint,
        totwallet_amount: totwallet_amountVal,
      };
      console.log("--985--", data);
      this.state.processedData = data;
      this.state.confirm = 1;
      if (uploaded > 0) {
        for (
          let uploadscount = 0;
          uploadscount < uploadedFiles.length;
          uploadscount++
        ) {
          medicaldoc_pic.push({
            name: uploadedFiles[uploadscount].name,
            base64: uploadedFiles[uploadscount].base64,
            type: uploadedFiles[uploadscount].type,
            size: uploadedFiles[uploadscount].size,
          });
        }

        data = {
          patient_id: patientId,
          cart_id: cartId,
          medicaldoc_pic: medicaldoc_pic,
        };

        Httpconfig.httptokenpost(
          Constant.siteurl + "api/OM/Cart/prescriptionImage",
          data
        )
          .then((response) => {
            if (response.data.status == "200" && response.data.error == false) {
              // if (
              //   this.state.txnstatus == "" ||
              //   this.state.txnstatus == undefined
              // ) {
              //   this.InitPayment();
              // } else {
              //   this.ReInitPayment();
              // }
              this.skipPaymentSuccess(data);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        // if (this.state.txnstatus == "" || this.state.txnstatus == undefined) {
        //   this.InitPayment();
        // } else {
        //   this.ReInitPayment();
        // }
        this.skipPaymentSuccess(data);
      }
    }
    ls.set("orderdetails", this.state.processedData);
  };

  // Paytm payment gateway init
  InitPayment = () => {
    let patientId = this.state.patientId;
    let doctorFees = this.state.payableAmount;
    var doctorId = this.state.seletedRetaielId;
    let consultationDateTime = this.state.consultationDateTime;
    let appointmentId = this.state.cartId;
    let consultationType = "ordermedicine";
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/OrderMedicineInitPaytmPayment",
      {
        patient_id: patientId,
        fees: doctorFees,
        doctorId: doctorId,
        consultationDateTime: "",
        appointmentId: appointmentId,
        clinicId: "",
        cDateTime: "",
        consultationType: consultationType,
      }
    ).then((response) => {
      if (response.data.error == false) {
        if (
          response.data.data[0]["txnId"] != "" &&
          response.data.data[0]["txnId"] != undefined
        ) {
          $("#txnToken").val(response.data.data[0]["txnId"]);
          $("#paytmform").attr(
            "action",
            "https://" +
              Constant.Paytm_hostname +
              "/theia/api/v1/showPaymentPage?mid=" +
              Constant.paytm_mid +
              "&orderId=" +
              appointmentId
          );
          $("#appointmentId").val(appointmentId);
          const interval = setInterval(() => {
            $("#paytmform").submit();
          }, 1500);
        }
      } else {
        if (response.data.error == true) {
          this.ReInitPayment();
        }
      }
    });
  };

  // duplicate order id generate and re submit transaction
  ReInitPayment = () => {
    // alert(this.state.payableAmount);
    let patientId = this.state.patientId;
    let doctorFees = this.state.payableAmount;
    var doctorId = this.state.seletedRetaielId;
    let consultationDateTime = "";
    let appointmentId = this.state.cartId;
    let consultationType = "ordermedicine";
    var orderResubmitCount = 1;
    var ordersavedResubmitValue = "";
    ordersavedResubmitValue = ls.get("orderResubmitCount");
    if (ordersavedResubmitValue != undefined && ordersavedResubmitValue != "") {
      orderResubmitCount = parseInt(ordersavedResubmitValue) + 1;
    }
    appointmentId = appointmentId + "-" + orderResubmitCount;
    ls.set("orderResubmitCount", orderResubmitCount);
    ls.set("order_unique_id", appointmentId);

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/OrderMedicineInitPaytmPayment",
      {
        patient_id: patientId,
        fees: doctorFees,
        doctorId: doctorId,
        consultationDateTime: "",
        appointmentId: appointmentId,
        clinicId: "",
        cDateTime: "",
        consultationType: consultationType,
      }
    ).then((response) => {
      if (response.data.error == false) {
        if (
          response.data.data[0]["txnId"] != "" &&
          response.data.data[0]["txnId"] != undefined
        ) {
          $("#txnToken").val(response.data.data[0]["txnId"]);
          $("#paytmform").attr(
            "action",
            "https://" +
              Constant.Paytm_hostname +
              "/theia/api/v1/showPaymentPage?mid=" +
              Constant.paytm_mid +
              "&orderId=" +
              appointmentId
          );
          $("#appointmentId").val(appointmentId);
          const interval = setInterval(() => {
            $("#paytmform").submit();
          }, 1500);
        }
      }
    });
  };

  // Validate Transaction ID
  validateTransaction = (transactionId) => {
    var appid = "";
    var appointmentId = "";
    let appointmenUniqueId = "";
    appid = ls.get("order_unique_id");
    if (appid != "") {
      appointmenUniqueId = appid;
      appointmentId = appid;
    } else {
      appointmentId = this.state.cartId;
    }

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/ValidateTransaction",
      {
        appointmentId: appointmentId,
        transactionId: transactionId,
      }
    ).then((response) => {
      if (response.data.error == true) {
        var processedData = ls.get("orderdetails");
        if (transactionId) {
          processedData.transaction_id = transactionId;
          processedData.order_unique_id = appointmenUniqueId;
        }
        if (processedData != null && transactionId) {
          this.skipPaymentSuccess(processedData);
        }
      } else {
        toast.error(response.data.message);
      }
    });
  };

  // get file data
  getFiles(files) {
    var objLen = 0;
    if (this.state.files) {
      objLen = Object.keys(this.state.files).length;
    }
    var fileFormate = false;
    files.map((item) => {
      if (item.type == "image/png" || item.type == "image/jpeg") {
        fileFormate = true;
      } else {
        fileFormate = false;
      }
    });
    if (fileFormate) {
      let docLength = files.length + this.state.files.length;

      if (objLen > 0) {
        if (docLength > 5) {
          toast.error("More than 5 Files not allowed!");
        } else {
          this.state.files = [...this.state.files, ...files];
        }
      } else {
        if (docLength > 5) {
          toast.error("More than 5 Files not allowed!");
        } else {
          this.setState({ files: files });
        }
      }
    } else {
      toast.error("Upload files Jpeg / Png formate only!");
    }
    this.forceUpdate();
  }
  addAddress = (event) => {
    let id = event.currentTarget.id;
    window.location.href = "./manageAddress/" + id;
  };
  setDefaultAddress = (event) => {
    let id = event.currentTarget.id;
    let patientId = this.state.patientId;
    Httpconfig.httptokenput(
      Constant.siteurl + "api/OM/deliveryAddress/" + patientId + "/" + id
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success("Address updated as default address");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //get current location
  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var url =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&key=" +
          Constant.googleAPiKey;
        $.getJSON(url, function (data, textStatus) {
          var streetaddress = data.results[0].formatted_address;

          ls.set("detected_addressEnc", streetaddress);
        });
      });

      let detected_address = ls.get("detected_addressEnc");
      if (detected_address) {
        this.setState({ detected_address: detected_address });
        this.handleAddressSelect(this.state.detected_address);
        setTimeout(function () {
          ls.remove("detected_addressEnc");
        }, 3000);
      }
    }
  };

  removeAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let clickValue = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    var isConfirm = window.confirm("Are you sure to delete " + clickName + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/OM/deliveryAddress/" + clickValue
      )
        .then((response) => {
          toast.success("Successfully Deleted Manage Address");
          // setTimeout(() => window.location.reload(), 2000);
          this.getaddressInfo(this.state.patientId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // this.state.patientId
  defaultAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let rowId = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    let patientId = this.state.patientId;
    var isConfirm = window.confirm(
      "Are you sure to set Default address " + clickName + "?"
    );
    if (isConfirm) {
      Httpconfig.httptokenput(
        Constant.siteurl + "api/OM/deliveryAddress/" + patientId + "/" + rowId
      )
        .then((response) => {
          toast.success("Successfully updated the Default Address ");
          this.getaddressInfo(this.state.patientId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  editAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let clickValue = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/deliveryAddress/" + clickValue
    )
      .then((response) => {
        console.clear();
        this.addNewAddress();
        this.getCitiesbasedonStateId(response.data.data[0].state);
        this.setState({
          fields: {
            patient_id: response.data.data[0].patient_id,
            username: response.data.data[0].name,
            mobile_no: response.data.data[0].mobile_no,
            location: response.data.data[0].location,
            address: response.data.data[0].address,
            landmark: response.data.data[0].landmark,
            latitude: response.data.data[0].latitude,
            longitude: response.data.data[0].longitude,
            address_type: response.data.data[0].address_type,
            city: response.data.data[0].city,
            stateName: response.data.data[0].state,
            country_name: response.data.data[0].country,
            zipcode: response.data.data[0].zipcode,

            isEdit: true,
            rowId: response.data.data[0].id,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddress = (event) => {
    let addressId = event.currentTarget.id;
    this.state.AddressList.filter((item) => item.id == addressId).map(
      (data, index) => {
        this.state.selectedAddressId = data.id;
        let patientId = this.state.patientId;
        this.state.deliveryAddress = data.address;
        this.state.lat_long = data.latitude + "," + data.longitude;
        ls.set("selectedAddress", this.state.lat_long);
        $(".showdropdown").css("display", "none");

        this.getAllAddresses(this.state.patientId);
        this.forceUpdate();
      }
    );
  };

  handleAddressSelected = (event) => {
    let addressId = event.currentTarget.id;
    this.state.AddressList.filter((item) => item.id == addressId).map(
      (data, index) => {
        this.state.selectedAddressId = data.id;
        let patientId = this.state.patientId;
        this.state.deliveryAddress = data.address;
        this.state.lat_long = data.latitude + "," + data.longitude;
        ls.set("selectedAddress", this.state.lat_long);
        $(".showdropdown").css("display", "none");

        this.getRetailerList();
        this.getAllAddresses(this.state.patientId);
        this.forceUpdate();
      }
    );
  };

  ValidateSelectedAddress = () => {
    let selectedAddress = "";
    selectedAddress = this.state.selectedAddressId;
    if (selectedAddress == "") {
      toast.error("Select Delivery Address");
      return;
    } else {
      ls.set("deliveryAddress", this.state.selectedAddressId);
      this.getRetailerList();
    }
  };

  //show Retailer Selection Section
  showRetailerSelectionSection = () => {
    this.state.retailerSelectionSection = "collapse-show";
    this.state.addressSelectionSection = "collapse-hide";
    this.forceUpdate();
  };
  //show Retailer Selection Section
  hideRetailerSelectionSection = () => {
    this.state.retailerSelectionSection = "collapse-hide";
    this.state.addressSelectionSection = "collapse-show";
    this.forceUpdate();
  };

  // To get all the Patient Addresses
  getaddressInfo(patientId) {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/OM/deliveryAddress/findAllDeliveryAddress/" +
        patientId
    )
      .then((response) => {
        this.state.AddressList = response.data.data;
        if (response.data.status == "200" && response.data.error == false) {
          const addressDetailsView = response.data.data.map(
            (addressDetails, num) => {
              return (
                <div className="add_address_box">
                  <div className="add_address_det">
                    <h5>
                      {addressDetails.default_status == 1 ? "Default" : ""}
                    </h5>

                    <h2>
                      <input
                        type="radio"
                        name="address1"
                        id={addressDetails.id}
                        onChange={this.handleAddress}
                      />{" "}
                      {addressDetails.name}{" "}
                      <p>({addressDetails.address_type})</p>
                      <span>+{addressDetails.mobile_no}</span>
                    </h2>
                    <h6 style={{ overflow: "auto" }}>
                      {addressDetails.address},{addressDetails.state},
                      {addressDetails.city},{addressDetails.zipcode},
                      {addressDetails.landmark
                        ? addressDetails.landmark + ","
                        : ""}{" "}
                      {addressDetails.country}
                    </h6>
                  </div>

                  <div className="add_address_bar">
                    <li className="nav-item dropdown">
                      <a href="#" id="navbardrop" data-toggle="dropdown">
                        <img src="../images/patient/img/Ordermedicine/orangedotmenu.svg" />
                      </a>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          name={addressDetails.name}
                          value={addressDetails.id}
                          href="javascript:void(0);"
                          onClick={this.editAddressBox.bind(this)}
                        >
                          Edit
                        </a>
                        {addressDetails.default_status != 1 ? (
                          <a
                            className="dropdown-item"
                            name={addressDetails.name}
                            value={addressDetails.id}
                            href="javascript:void(0);"
                            onClick={this.defaultAddressBox.bind(this)}
                          >
                            Set as Default
                          </a>
                        ) : (
                          ""
                        )}
                        <a
                          className="dropdown-item"
                          name={addressDetails.name}
                          value={addressDetails.id}
                          href="javascript:void(0);"
                          onClick={this.removeAddressBox.bind(this)}
                        >
                          Delete
                        </a>
                      </div>
                    </li>
                  </div>
                </div>
              );
            }
          );

          this.setState({
            addressDetailsView: addressDetailsView,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkSubmit(event) {
    event.preventDefault();
    let isEditEnabled = this.state.fields.isEdit;
    this.forceUpdate();
    if (this.handleValidation()) {
      if (isEditEnabled) {
        this.updateAddressModule(event);
      } else {
        this.createAddressModule(event);
      }
    }
  }

  // creates address Module
  createAddressModule = async (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
    let enteredAddress = $('textarea[name="address"]').val();
    await this.getLatLangfromAddress(address);

    let address_latitude = ls.get("address_latitudeEnc");
    let address_longitude = ls.get("address_longitudeEnc");

    Httpconfig.httptokenpost(Constant.siteurl + "api/OM/deliveryAddress", {
      patient_id: this.state.patientId,
      name: fields["username"],
      mobile_no: fields["mobile_no"],
      location: fields["location"] ? fields["location"] : "location",
      address: fields["address"],
      landmark: fields["landmark"],
      city: fields["city"],
      state: fields["stateName"],
      country: fields["country_name"],
      zipcode: fields["zipcode"],
      latitude: address_latitude ? address_latitude : "17.438976",
      longitude: address_longitude ? address_longitude : "78.38905559999999",
      address_type: fields["address_type"],
    })
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Address Saved Successfully");
          this.getaddressInfo(this.state.patientId);
          this.setState({
            add_address_form: "add_address_form collapse-hide",
          });
          $("#address-form").reset();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // updates controller
  updateAddressModule = async (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    let rowId = this.state.fields.rowId;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];

    await this.getLatLangfromAddress(address);
    let address_latitude = ls.get("address_latitudeEnc");
    let address_longitude = ls.get("address_longitudeEnc");
    Httpconfig.httptokenput(
      Constant.siteurl + "api/OM/deliveryAddress/" + rowId,
      {
        patient_id: this.state.patientId,
        name: fields["username"],
        mobile_no: fields["mobile_no"],
        location: fields["location"] ? fields["location"] : "location",
        address: fields["address"],
        landmark: fields["landmark"],
        city: fields["city"],
        state: fields["stateName"],
        country: fields["country_name"],
        zipcode: fields["zipcode"],
        latitude: address_latitude ? address_latitude : "1",
        longitude: address_longitude ? address_longitude : "2",
        address_type: fields["address_type"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Successfully Updated Manage Address");

          this.setState({
            add_address_form: "add_address_form collapse-hide",
          });
          window.location.reload();

          this.getaddressInfo(this.state.patientId);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  getLatLangfromAddress = (enteredAddress) => {
    return new Promise((resolve, reject) => {
      var latitude = "";
      var longitude = "";
      var url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        enteredAddress +
        "&key=" +
        Constant.googleAPiKey;
      $.getJSON(url, function (data, textStatus) {
        var responseStatus = data.status;
        if (responseStatus === "OK") {
          latitude = data.results[0].geometry.location.lat;
          longitude = data.results[0].geometry.location.lng;
          resolve();
          ls.set("address_latitudeEnc", latitude);
          ls.set("address_longitudeEnc", longitude);
        }
      });

      let address_latitude = ls.get("address_latitudeEnc");
      let address_longitude = ls.get("address_longitudeEnc");
      if (address_latitude && address_longitude) {
        this.state.fields.latitude = address_latitude;
        this.state.fields.longitude = address_longitude;

        setTimeout(function () {
          ls.remove("address_latitudeEnc");
          ls.remove("address_longitudeEnc");
        }, 2000);
      }
    });
  };
  getCompleteStates() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Country/states/" + Constant.countryID
    )
      .then((response) => {
        this.setState({
          indianStates: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;

    if (field == "stateName") {
      this.getCitiesbasedonStateId(event.target.value);
    }
    this.setState({ fields });
  };
  getCitiesbasedonStateId(stateId) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country/cities/" + stateId)
      .then((response) => {
        // console.log("rresponsee", response);
        this.setState({
          indianCities: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Name cannot be empty";
    }
    if (!fields["mobile_no"]) {
      formIsValid = false;
      errors["mobile_no"] = "Mobile No cannot be empty";
    } else if (
      fields["mobile_no"].length > 10 ||
      fields["mobile_no"].length < 10
    ) {
      formIsValid = false;
      errors["mobile_no"] = "Mobile No should be 10 digits";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["address_type"]) {
      formIsValid = false;
      errors["address_type"] = "Address type cannot be empty";
    }
    if (!fields["zipcode"]) {
      formIsValid = false;
      errors["zipcode"] = "Pincode cannot be empty";
    }
    if (!fields["stateName"]) {
      formIsValid = false;
      errors["statename"] = "State name cannot be empty";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  addNewAddress = (event) => {
    //console.log("newAddress", event.currentTarget.name);
    if (event) {
      this.state.fields.isEdit = false;
      this.forceUpdate();
    }
    //fetch CountryCurrency Details
    Httpconfig.httptokenget(Constant.siteurl + "api/Country")
      .then((response) => {
        this.setState({
          countryData: response.data.data,
          add_address_form: "add_address_form collapse-show",
        });

        //this.forceUpdate();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ImageClick = (event) => {
    var array = this.state.files;
    var foundValue = array.filter((obj) => obj.name != event.target.id);
    this.setState({ files: foundValue });
  };
  ImageZoomClick = (event) => {
    this.setState({
      zoomimage: event.currentTarget.src,
    });
  };
  removeImageOnClick = (event) => {
    let imageName = event.currentTarget.id.replace("img_", "");
    let img = imageName.replace(/[{()}]/g, "");
    $("#" + img).remove();
    $("." + img).remove();
    //// // // console.log("State values");
    //// // // console.log(this.state.files);
    var array = [...this.state.files]; // make a separate copy of the array
    // // // console.log("array values");
    //var index = array.indexOf(imageName);
    var index = array.findIndex((item) => item.name === imageName);
    //alert(index);
    //// // // console.log(array);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
    this.forceUpdate();
    // this.setState({
    //   zoomimage:'',
    // })
  };

  skipPaymentSuccess = (data) => {
    // Implementation for whatever you want to do with reference and after success call.
    //data.transaction_id = '1001001';//reference.reference;
    // data.timeZone = moment.tz.guess();
    // data.patientEmail = data.patientEmail;
    // data.patient_address_id = data.patient_address_id
    //console.log("---order data-----", data);
    //return;

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess",
      data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success(response.data.message);
          ls.set("cartIdEnc", "");
          ls.remove("orderdetails");
          ls.remove("orderResubmitCount");
          ls.remove("order_unique_id");
          ls.remove("selectedAddress");
          setInterval(() => {
            window.location.href = Constant.imgurl + "/myOrders";
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Coupon validate handler
  applyCoupon = () => {
    if (!this.state.coupon) {
      this.setState({ code: "Enter the Coupon Code" });
    } else {
      var coupanName = this.state.coupon;
      var couponDiscount = ""; //"5";//this.state.couponDiscount;
      var totDiscountAmount = "";
      var feeAmount = this.state.total;
      var payableAmount = "";
      var couponId = "";
      var totalsavings = "";
      //var payableAmount= parseInt(feeAmount)-parseInt(couponDiscount);
      //this.state.payableAmount=payableAmount;

      // this.forceUpdate();
      // this.setState({coupon_id:couponId,payableAmount:payableAmount,couponDiscount:couponDiscount})
      let postData = {
        consultationFees: feeAmount,
        coupanName: coupanName,
        amount: feeAmount,
        patientId: this.state.patientId,
        module: "ordermedician",
      };

      Httpconfig.httptokenpost(
        Constant.siteurl + "api/Coupon/Coupondiscountamount/",
        postData
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            couponDiscount = response.data.data.discount;
            totDiscountAmount = response.data.data.totdiscountamount;
            couponId = response.data.data.id;
            payableAmount = response.data.data.totamount;
            totalsavings = this.state.totalDiscount;
            totalsavings =
              parseFloat(totDiscountAmount) + parseFloat(totalsavings);
            payableAmount =
              parseFloat(payableAmount) +
              parseFloat(this.state.deliveryCharges);
            this.setState({
              code: response.data.message,
              couponId: couponId,
              payableAmount: payableAmount,
              couponDiscount: couponDiscount,
              totDiscountAmount: totDiscountAmount,
              totalsavings: totalsavings,
            });
          } else {
            this.setState({
              code: response.data.message,
              payableAmount: this.state.toBePaid,
              couponDiscount: "",
              totDiscountAmount: "",
            });
          }
        })
        .catch((error) => {
          toast.error(error);
          this.setState({
            code: "Invalid Coupon",
            payableAmount: this.state.toBePaid,
            couponDiscount: "",
          });
        });
      // alert(this.state.toBePaid);
    }
  };

  // remove coupon Applied

  removeCoupon = () => {
    var totalsavings =
      parseFloat(this.state.totalsavings) -
      parseFloat(this.state.totDiscountAmount);
    this.setState({
      coupon: "",
      couponDiscount: "",
      coupon_id: "",
      totDiscountAmount: "",
      payableAmount: this.state.toBePaid,
      totalsavings: totalsavings,
      code: "Coupon removed successfully",
    });
  };

  // Verify Coupon Code
  handleCode = (event) => {
    this.setState({ coupon: event.target.value.toUpperCase() });
    if (event.target.value != "") {
      this.setState({ code: "" });
    }
  };
  getTimeSlots() {
    var startTime = moment("09:00", "HH:mm");
    var endTime = moment("23:59", "HH:mm");
    var timeSlots = [];
    while (startTime <= endTime) {
      timeSlots.push(new moment(startTime).format("HH:mm a"));
      startTime.add(60, "minutes");
    }
    return timeSlots;
  }
  // close not retailer found popup
  close = () => {
    $("#noret_modal").hide();
  };

  render() {
    const { languages_data } = this.state;
    const { fields, retailerList } = this.state;
    //alert(Object.keys(this.state.retailerList).length);
    let documentsRelated = [];
    if (this.state.files) {
      let imageArray = this.state.files;

      if (imageArray.length > 0) {
        for (var i = 0; i < imageArray.length; i++) {
          let img = imageArray[i].name.split(".");
          let imgName = img[0];
          let imageName = imageArray[i].name;
          let imagebase64 = imageArray[i].base64;
          let imageType = imageArray[i].type;
          let imageSize = imageArray[i].size;
          let imageId = imageArray[i];

          documentsRelated.push(
            <div class={"upload_presc_img " + imgName}>
              <a
                href="#"
                class="thumbnail"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <img
                  src={imagebase64}
                  id={imgName}
                  value={imageName}
                  alt={imageName}
                  name={imageName}
                  onClick={this.ImageZoomClick.bind(this)}
                />
              </a>
              <div class="upload_top_img">
                <a
                  href="#"
                  class="thumbnail"
                  data-toggle="modal"
                  data-target="#lightbox"
                ></a>
                <img
                  class="delete_img"
                  id={"img_" + imageArray[i].name}
                  onClick={this.removeImageOnClick}
                  src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                />
              </div>
            </div>
          );
        }
      }
    }
    // if(this.state.retailerList)
    // {

    //   if(this.state.retailerList[0].retailerInfo.wallet_points > 0)
    //   {
    //    // console.log("-------this.state.totalsavings-----", this.state.retailerList)
    //   this.state.deduct_points =this.state.retailerList[0].retailerInfo.deduct_points;
    //   this.state.wallet_amount_perpoint=this.state.retailerList[0].retailerInfo.wallet_amount_perpoint;
    //   this.state.totwalletDeductuAmount = this.state.retailerList[0].retailerInfo.deduct_points *  this.state.retailerList[0].retailerInfo.wallet_amount_perpoint
    //   //this.state.totalsavings = this.state.totalsavings + this.state.totwalletDeductuAmount;
    //   //this.state.payableAmount = this.state.payableAmount - this.state.totwalletDeductuAmount;
    //    //console.log("-------this.state.totalsavings-----", this.state.totalsavings)
    //   }

    // }

    return (
      <main id="main_ord_nav">
        <PatientHeader />
        <I18nPropvider locale={this.state.Language}>
          <section
            id="almost_section"
            class={this.state.addressSelectionSection}
          >
            <div class="almost_main">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <div class="almost_head">
                      <h2>You almost there!</h2>
                      <p>Please follow the process to place your order</p>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="almost_step">
                      <div class="stepwizard">
                        <div class="stepwizard-row setup-panel">
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-primary btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/signIn_selected.svg" />
                            </a>
                            <p class="p_dark">Sign In</p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-primary btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/location_selected.svg" />
                            </a>
                            <p class="p_dark">Delivery address</p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-default btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/retailer.svg" />
                            </a>
                            <p class="p_light">Select Pharmacy </p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-default btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/payment.svg" />
                            </a>
                            <p class="p_light">Make Payment</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section id="almost_section">
<div class="almost_main">
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="almost_head">
<h2>You almost there!</h2>
<p>Please follow the process to place your order</p>
</div>
</div>
<div class="col-md-8">
<div class="almost_step">
<div class="stepwizard">
<div class="stepwizard-row setup-panel">
<div class="stepwizard-step">
<a href="#" type="button" class="btn btn-primary btn-circle">
<img src="../images/patient/img/Ordermedicine/signIn_selected.svg" /></a>
<p class="p_dark">Sign In</p>
</div>
<div class="stepwizard-step">
<a href="#" type="button" class="btn btn-default btn-circle">
<img src="../images/patient/img/Ordermedicine/location.svg" />
</a>
<p class="p_light">Delivery address</p>
</div>
<div class="stepwizard-step">
<a href="#" type="button" class="btn btn-default btn-circle">
<img src="../images/patient/img/Ordermedicine/retailer.svg" /></a>
<p class="p_light">Select Retailer </p>
</div>
<div class="stepwizard-step">
<a href="#" type="button" class="btn btn-default btn-circle">
<img src="../images/patient/img/Ordermedicine/payment.svg" />
</a>
<p class="p_light">Make Payment</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section> */}
          <section
            id="order_profile"
            class={this.state.addressSelectionSection}
          >
            <div className="appoint_section">
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                   
                  </div> */}
                  {/* Side Menu Ends here */}

                  <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <section id="order_add_address">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="add_address_sec">
                              <div className="add_address_head">
                                <h2
                                  data-toggle="modal"
                                  data-target="#noret_modal"
                                >
                                  Delivery Address
                                </h2>
                              </div>
                              <a
                                href="#"
                                name="add newaddress"
                                onClick={this.addNewAddress}
                              >
                                <div className="add_address_btn">
                                  <p>Add New Address</p>
                                </div>
                              </a>

                              <div className={this.state.add_address_form}>
                                <form
                                  onSubmit={this.checkSubmit.bind(this)}
                                  id="address-form"
                                >
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-7">
                                        <label>New User</label>
                                        <input
                                          type="text"
                                          name="username"
                                          value={this.state.fields.username}
                                          className="form-control"
                                          placeholder="Name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "username"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["username"]}
                                        </span>
                                      </div>

                                      <div className="col-md-5">
                                        <span>
                                          <label
                                            data-toggle="modal"
                                            data-target="#loading_modal"
                                          >
                                            +91
                                          </label>
                                          <input
                                            type="text"
                                            name="mobile_no"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            value={
                                              this.state.fields["mobile_no"] ||
                                              ""
                                            }
                                            className="form-control"
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Mobile number"
                                            onChange={this.handleChange.bind(
                                              this,
                                              "mobile_no"
                                            )}
                                          />
                                        </span>
                                        <span className="cRed">
                                          {this.state.errors["mobile_no"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label>Complete Address</label>
                                    <textarea
                                      className="form-control"
                                      rows="3"
                                      name="address"
                                      value={this.state.fields["address"] || ""}
                                      placeholder="Enter Full address"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "address"
                                      )}
                                      onBlur={() =>
                                        this.getLatLangfromAddress.bind(this)
                                      }
                                    ></textarea>
                                    <span className="cRed">
                                      {this.state.errors["address"]}
                                    </span>
                                  </div>
                                  <div className="form-group">
                                    <label>
                                      Land Mark<span>(Optional)</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="landmark"
                                      value={
                                        this.state.fields["landmark"] || ""
                                      }
                                      placeholder="Enter Landmark"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "landmark"
                                      )}
                                    />
                                    <span className="cRed">
                                      {this.state.errors["landmark"]}
                                    </span>
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>State</label>
                                        {/* <input
                                          type="text"
                                          name="statename"
                                          value={this.state.fields.statename}
                                          className="form-control"
                                          placeholder="Enetr state name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "statename"
                                          )}
                                        /> */}
                                        <select
                                          name="statename"
                                          id="state-dropdown"
                                          className="form-control"
                                          value={this.state.fields.stateName}
                                          onChange={this.handleChange.bind(
                                            this,
                                            "stateName"
                                          )}
                                        >
                                          <option value="">Select State</option>
                                          {this.state.indianStates.map(
                                            (stateList, i) => {
                                              return (
                                                <option value={stateList.id}>
                                                  {stateList.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        <span className="cRed">
                                          {this.state.errors["statename"]}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <label>City</label>
                                        {/* <input
                                          type="text"
                                          name="city"
                                          value={this.state.fields.city}
                                          className="form-control"
                                          placeholder="Enter city name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "city"
                                          )}
                                        /> */}
                                        <select
                                          name="city"
                                          className="form-control"
                                          value={this.state.fields.city}
                                          onChange={this.handleChange.bind(
                                            this,
                                            "city"
                                          )}
                                        >
                                          <option>Select City</option>
                                          {this.state.indianCities.map(
                                            (cityData, i) => {
                                              return (
                                                <option value={cityData.name}>
                                                  {cityData.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        <span className="cRed">
                                          {this.state.errors["city"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Pincode</label>
                                        <input
                                          type="text"
                                          name="zipcode"
                                          value={this.state.fields.zipcode}
                                          className="form-control"
                                          placeholder="Enter Pincode"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "zipcode"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["zipcode"]}
                                        </span>
                                      </div>
                                      <div className="col-md-6">
                                        <label>Country</label>
                                        <select
                                          name="country_name"
                                          className="form-control"
                                          value={this.state.fields.country_name}
                                          onChange={this.handleChange.bind(
                                            this,
                                            "country_name"
                                          )}
                                        >
                                          <option>
                                            {this.state.fields.country_name}
                                          </option>

                                          {/* {this.state.countryData &&
                                            this.state.countryData.map(
                                              (countryData, i) => {
                                                return (
                                                  <option
                                                    value={countryData.name}
                                                  >
                                                    {countryData.name}
                                                  </option>
                                                );
                                              }
                                            )} */}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="form-group"
                                    onChange={this.handleChange.bind(
                                      this,
                                      "address_type"
                                    )}
                                  >
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="address_type"
                                        value="Home"
                                        checked={
                                          this.state.fields["address_type"] ===
                                          "Home"
                                        }
                                      />
                                      Home
                                    </label>
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="address_type"
                                        value="Work"
                                        checked={
                                          this.state.fields["address_type"] ===
                                          "Work"
                                        }
                                      />
                                      Work
                                    </label>
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="address_type"
                                        value="Other"
                                        checked={
                                          this.state.fields["address_type"] ===
                                          "Other"
                                        }
                                      />
                                      Other
                                    </label>
                                    <span className="cRed">
                                      {this.state.errors["address_type"]}
                                    </span>
                                  </div>

                                  <div className="">
                                    <button
                                      type="submit"
                                      className="btn  btn-primary padTopCategorySave "
                                    >
                                      Save Address
                                    </button>{" "}
                                  </div>
                                </form>
                              </div>

                              {/* addressbox Code will come here */}
                              {this.state.addressDetailsView}
                              <a onClick={this.ValidateSelectedAddress}>
                                <div className="add_address_btn">
                                  <p>Continue</p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="almost_section"
            class={this.state.retailerSelectionSection}
          >
            <div class="almost_main">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <div class="almost_head">
                      <h2>You almost there!</h2>
                      <p>Please follow the process to place your order</p>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="almost_step">
                      <div class="stepwizard">
                        <div class="stepwizard-row setup-panel">
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-primary btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/signIn_selected.svg" />
                            </a>
                            <p class="p_dark">Sign In</p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-primary btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/location_selected.svg" />
                            </a>
                            <p class="p_dark">Delivery address</p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-primary btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/retailer.svg" />
                            </a>
                            <p class="p_dark">Select Retailer </p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#"
                              type=""
                              class="btn btn-default btn-circle"
                            >
                              <img src="../images/patient/img/Ordermedicine/payment.svg" />
                            </a>
                            <p class="p_light">Make Payment</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            id="select_retailer"
            class={this.state.retailerSelectionSection}
          >
            <div class="select_main">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="sel_ret_head">
                      <h2>
                        Select Preferred Pharmacy <span>(optional)</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 select_ful">
                    <div class="presc_img">
                      <img src="../images/patient/img/Ordermedicine/select_user.svg" />
                    </div>
                    <div class="sel_patient">
                      <h3>Select the patient</h3>
                      <a href="#" onClick={this.addfamily}>
                        <p>+ Add your family member</p>
                      </a>
                    </div>
                    <div class="ret_form">
                      <form>
                        {/* get the relatives details */}
                        {this.state.stateRelationsOptions}
                      </form>
                    </div>
                    <div class="sel_ret_presc">
                      <div class="presc_head">
                        <div class="presc_img">
                          <img src="../images/patient/img/Ordermedicine/upload_icon.svg" />
                        </div>
                        <div class="presc_det">
                          <h2>UPLOAD &amp; SEND PRESCRIPTION</h2>
                          <h6>You have one Rx medicine in the item</h6>
                        </div>
                      </div>
                      <div class="ret_upload_btn">
                        <div class="custom-file-upload">
                          <label>
                            Upload
                            <FileBase64
                              multiple={true}
                              onDone={this.getFiles.bind(this)}
                            />
                          </label>
                          {/* <label for="file-upload" class="custom-file-upload1">
                    Upload
                    </label> */}
                          {/* <p>or Drag &amp; Drop here</p> */}

                          {/* <input id="file-upload" type="file" /> */}
                        </div>
                      </div>
                      <div class="ret_upload_img">
                        {
                          this.state.prescriptionImage ? (
                            <img src={this.state.prescriptionImage} />
                          ) : (
                            ""
                          )
                          // <img src="https://icon-library.com/images/prescription-icon/prescription-icon-18.jpg" />
                        }
                        {documentsRelated}
                      </div>
                    </div>
                    <div class="sel_ret_presc">
                      <div class="presc_head">
                        <div class="presc_img">
                          <img src="../images/patient/img/Ordermedicine/upload_icon.svg" />
                        </div>
                        <div class="presc_det">
                          <h2>Booking Type</h2>
                          {/* <h6>You have one Rx medicine in the item</h6> */}
                          <fieldset>
                            <div class="form-control">
                              <input
                                type="radio"
                                class="radio"
                                name="delivery_type"
                                value="center_visit"
                                onClick={(e) => {
                                  this.state.deliveryType = e.target.value;
                                }}
                              />
                              <label>Diagnostics Center Visit</label>
                              <br />
                              <input
                                type="radio"
                                class="radio"
                                name="delivery_type"
                                value="home_visit"
                                onClick={(e) => {
                                  this.state.deliveryType = e.target.value;
                                }}
                              />
                              <label>Home Visit (Sample Collection)</label>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div class="sel_ret_presc">
                      <div class="presc_head">
                        <div class="presc_img">
                          <img src="../images/patient/img/Ordermedicine/upload_icon.svg" />
                        </div>
                        <div class="presc_det">
                          <h2>Payment Method</h2>
                          <fieldset>
                            <div class="form-control">
                              <input
                                type="radio"
                                class="radio"
                                name="paymentType"
                                value="COD"
                                onClick={(e) => {
                                  this.state.paymentMethod = e.target.value;
                                }}
                              />
                              <label>COD (CASH ON DELIVERY)</label>
                              <br />
                              <input
                                type="radio"
                                class="radio"
                                name="paymentType"
                                value="online"
                                onClick={(e) => {
                                  this.state.paymentMethod = e.target.value;
                                }}
                              />
                              <label>Online </label>
                            </div>
                          </fieldset>
                        </div>
                        <div
                          class="row"
                          id="retailer_info"
                          style={{ display: "none", padding: "15px" }}
                        ></div>
                      </div>
                    </div>

                    <div class="sel_ret_presc">
                      <div class="presc_head">
                        <div class="presc_img">
                          <img src="../images/patient/img/Ordermedicine/upload_icon.svg" />
                        </div>
                        <div class="presc_det">
                          <h2 class="mr-5">Choose Date</h2>
                          <input
                            type="date"
                            id="psdate"
                            name="slot_date"
                            className="form-control"
                            data-ng-model="date"
                            value={this.state.fields["dob"]}
                            min={moment().format("YYYY-MM-DD")}
                            onChange={(e) => {
                              this.state.slotBookingDate =
                                e.currentTarget.value;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="sel_ret_presc">
                      <div class="presc_head">
                        <div class="presc_img">
                          <img src="../images/patient/img/Ordermedicine/upload_icon.svg" />
                        </div>
                        <div class="presc_det">
                          <h2 class="mr-5">Time Slot</h2>
                          <select
                            onChange={(e) => {
                              this.state.slotBookingTime = e.target.value;
                            }}
                            name="slot_time"
                            defaultValue=""
                            className="form-control"
                          >
                            <option value="" disabled={true}>
                              Select Time
                            </option>
                            {this.getTimeSlots().map((times) => {
                              return <option value={times}>{times}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 ">
                    <div class="select_ret_dropdown" onClick={this.showAddress}>
                      <ul>
                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle user_name"
                            href="#"
                            id="navbardrop"
                            data-toggle="dropdown"
                          >
                            <p
                              className="delivery_address"
                              style={{ overflow: "auto" }}
                            >
                              {this.state.deliveryAddress != ""
                                ? this.state.deliveryAddress
                                : "Select Delivery Address"}
                            </p>
                          </a>
                          <div class="dropdown-menu showdropdown">
                            {/* <a class="dropdown-item cur_loc" onClick={this.getCurrentLocation}><img class="cur_loc_img" src="https://listimg.pinclipart.com/picdir/s/485-4851736_free-png-search-icon-search-icon-free-download.png" />Use Current Location</a> */}
                            <a
                              class="dropdown-item add_new"
                              id=""
                              onClick={this.hideRetailerSelectionSection}
                            >
                              + Add New Address
                            </a>
                            <div class="saved_address">
                              <div class="save_head">
                                <h2>Saved Address</h2>
                              </div>
                              {/* Get Addresses */}
                              {this.state.deliveryAddresses}
                            </div>
                          </div>
                          <span>
                            <img
                              class="search_img"
                              src="https://listimg.pinclipart.com/picdir/s/485-4851736_free-png-search-icon-search-icon-free-download.png"
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div class="select_carousel">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="select_caro_sec">
                              {}

                              {this.state.retailerList ? (
                                <div class="owl-carousel owl-theme">
                                  {/* Get Retilers carousel */}
                                  <OwlCarousel
                                    ref=""
                                    items={1.5}
                                    className="owl-theme"
                                    margin={8}
                                    loop={false}
                                  >
                                    {this.state.retailerList.map(
                                      (data, index) => (
                                        <div
                                          class="item"
                                          id={data.retailerInfo.id}
                                          onClick={this.selectedRetailer.bind(
                                            this,
                                            data.retailerInfo.id
                                          )}
                                        >
                                          <div class="select_content">
                                            <p class="sl_no">{index + 1}</p>

                                            <div class="ret_name">
                                              {/* {Object.keys(data.retailerInfo.stockAvailable).length} */}

                                              <h2>
                                                {data.retailerInfo.storename}{" "}
                                                <span class="ret_avail">
                                                  {
                                                    Object.keys(
                                                      data.stockAvailable
                                                    ).length
                                                  }
                                                  /{this.state.totalCartItems}{" "}
                                                  <span>
                                                    {data.retailerInfo
                                                      .is_active == 1
                                                      ? "  available"
                                                      : ""}
                                                  </span>
                                                </span>
                                              </h2>
                                              <p class="tot_amnt">
                                                {index == 0 ? (
                                                  <h5 class="recomend_txt">
                                                    Recommended
                                                  </h5>
                                                ) : (
                                                  ""
                                                )}
                                                <h5>
                                                  {data.retailerInfo.currency}{" "}
                                                  <span
                                                    id={
                                                      "total-price-" +
                                                      data.retailerInfo.id
                                                    }
                                                  >
                                                    {parseFloat(data.cartTotal)
                                                      .toFixed(2)
                                                      .replace(
                                                        /\d(?=(\d{3})+\.)/g,
                                                        "$&,"
                                                      )}
                                                  </span>
                                                </h5>
                                              </p>
                                              <h3>
                                                {" "}
                                                {data.retailerInfo.address}
                                              </h3>
                                              <h5 class="ret_loc">
                                                {" "}
                                                <span class="ret_km">
                                                  {data.distance} km
                                                </span>
                                              </h5>
                                              <h3 class="ret_expect">
                                                {" "}
                                                Expected delivery by{" "}
                                                <span>
                                                  {moment()
                                                    .add(
                                                      data.retailerInfo
                                                        .set_delivery_days,
                                                      "days"
                                                    )
                                                    .format(
                                                      "dddd, MMMM Do YYYY"
                                                    )}
                                                </span>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </OwlCarousel>
                                </div>
                              ) : (
                                " Retailers not found to delivery in your location "
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.retailerName ? (
                      <div class="ret_det_box">
                        <div class="ret_name">
                          <h2>
                            {this.state.retailerName
                              ? this.state.retailerName
                              : ""}{" "}
                            <span class="ret_avail">
                              {this.state.available ? this.state.available : ""}
                              <span> available</span>{" "}
                            </span>
                          </h2>
                          <h5 class="ret_loc">
                            {this.state.retailerAddress}{" "}
                            <span class="ret_km">
                              {" "}
                              {this.state.distance} km
                            </span>
                          </h5>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.relativeId ? (
                      <p class="med_for">
                        Medicines for <span>{this.state.selectedRelative}</span>
                      </p>
                    ) : (
                      ""
                    )}
                    <div class="medicine_sec">
                      <div class="med_list">
                        {this.state.retailerSelectedProcuctInfo
                          ? this.state.retailerSelectedProcuctInfo
                          : ""}
                        {this.state.retailerSelectedNostockProcuctInfo
                          ? this.state.retailerSelectedNostockProcuctInfo
                          : ""}
                      </div>
                    </div>
                    <div class="med_upload_sec">
                      {/* <div class="presc_head">
    <div class="presc_img">
    <img src="img/Date.svg" />
    </div>
    <div class="presc_det">
    <h2>
    UPLOAD &amp; SEND PRESCRIPTION</h2>
    <h6>You have one Rx medicine in the item</h6>
    </div>
    </div> */}
                      <div class="total_amnt_box">
                        <div class="price_head">
                          <h2>Price Details</h2>
                        </div>
                        <div class="price_details">
                          <p>
                            Total M.R.P{" "}
                            <span>
                              {this.state.currency}{" "}
                              {this.state.totalmrp
                                ? parseFloat(this.state.totalmrp).toFixed(2)
                                : 0}
                            </span>
                          </p>
                          <p>
                            Price Discount on M.R.P{" "}
                            <span>
                              {this.state.currency}{" "}
                              {this.state.totalDiscount
                                ? parseFloat(this.state.totalDiscount).toFixed(
                                    2
                                  )
                                : 0}
                            </span>
                          </p>
                          <p>
                            Total Amount{" "}
                            <span>
                              <div class="currency_left">
                                {this.state.currency}
                              </div>
                              {parseFloat(this.state.total)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </span>
                          </p>
                          {/*                           
                          {this.state.couponDiscount ? (
                            <p>
                              Coupon Discount{" "}
                     
                              <span>
                             
                                {this.state.couponDiscount
                                  ? this.state.couponDiscount
                                  : 0}
                              </span>
                            </p>

                          ) : (
                            ""
                          )} */}
                          {/* {this.state.totwalletDeductuAmount ? (
                            <p>
                              Wallet Discount Amount{" "}
                              <span>
                                {this.state.currency}{" "}
                                {this.state.totwalletDeductuAmount
                                  ? parseFloat(
                                      this.state.totwalletDeductuAmount
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                          ) : (
                            ""
                          )} */}

                          {this.state.totDiscountAmount ? (
                            <p>
                              Coupon Discount Amount{" "}
                              <span>
                                {this.state.currency}{" "}
                                {this.state.totDiscountAmount
                                  ? parseFloat(
                                      this.state.totDiscountAmount
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}

                          <p>
                            Collecting Charges{" "}
                            <span>
                              <div class="currency_left">
                                {this.state.currency}
                              </div>
                              {parseFloat(this.state.deliveryCharges)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </span>
                          </p>
                        </div>
                        <div class="to_paid">
                          <p>
                            To Be Paid{" "}
                            <span>
                              {this.state.currency}{" "}
                              {parseFloat(this.state.payableAmount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </span>
                          </p>
                          <h6>
                            Total savings{" "}
                            {parseFloat(this.state.totalsavings)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            {/* <br />
                            Rounded Amount{" "}
                            {Math.round(this.state.payableAmount)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} */}
                          </h6>
                        </div>
                      </div>
                      {this.state.showcoupon == true ? (
                        <reactFragment>
                          <p>{translate("Have a Coupon Code")}?</p>
                          <div class="form-group">
                            <div class="input-group">
                              <FormattedMessage id="Enter Your Code">
                                {(placeholder) => (
                                  <input
                                    type="text"
                                    class="form-control code_input"
                                    value={this.state.coupon}
                                    placeholder={placeholder}
                                    onChange={this.handleCode}
                                  />
                                )}
                              </FormattedMessage>
                              {!this.state.couponDiscount != "" ? (
                                <span
                                  class="input-group-addon sel_apply_btn"
                                  onClick={this.applyCoupon}
                                >
                                  <span>{translate("Apply")}</span>
                                </span>
                              ) : (
                                <span
                                  class="input-group-addon app_btn"
                                  onClick={this.removeCoupon}
                                >
                                  <span>{translate("Remove")}</span>
                                </span>
                              )}
                            </div>
                            <span className="cRed">{this.state.code}</span>
                          </div>
                        </reactFragment>
                      ) : (
                        ""
                      )}
                      <p class="terms">
                        <input type="checkbox" name="terms" id="terms" /> Accept{" "}
                        <a href="./patient/TermsConditions" target="_blank">
                          <span>Terms and Conditions</span>
                        </a>
                      </p>
                      <div class="sel_ret_btn">
                        <a href="#" onClick={this.validate}>
                          <p>Proceed to Pay</p>
                        </a>
                        {/* { this.state.confirm == "" ? (
                          <a href="#" onClick={this.validate}>
                            <p>Proceed to Pay</p>
                          </a>
                        ) 
                        
                        } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form
              method="post"
              id="paytmform"
              action={this.state.actionurl}
              name="paytm"
            >
              <table border="1">
                <tbody>
                  <input type="hidden" name="mid" value={Constant.paytm_mid} />
                  <input
                    type="hidden"
                    id="appointmentId"
                    name="orderId"
                    value=""
                  />
                  <input
                    type="hidden"
                    id="txnToken"
                    name="txnToken"
                    value={this.state.txnToken}
                  />
                </tbody>
              </table>
            </form>
          </section>

          {/* no retailer rmodal */}

          <div
            class="modal  fade in"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            id="noret_modal"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="ret_content">
                    <img src="../images/patient/img/Ordermedicine/triangle.svg" />
                    <h2>
                      Sorry! No Pharmacy found near your location. Please choose
                      a different delivery location and try again.
                    </h2>
                    <button
                      type="button"
                      class="ok_btn"
                      data-dismiss="modal"
                      onClick={this.close}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* loading modal */}
          <div
            class="modal  fade in"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            id="loading_processOrder"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="load_content">
                    <img src="../images/patient/img/Ordermedicine/loader.svg" />
                    <h2>Please wait, we are processing your request</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="lightbox"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <button
                type="button"
                class="close hidden"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <div class="modal-content">
                <div class="modal-body">
                  <img src={this.state.zoomimage} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* <paymentGateway /> */}
          <ToastContainer />
          {/* <PatientFooter/> */}
        </I18nPropvider>
      </main>
    );
  }
}
