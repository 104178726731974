import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import PatientHeader from "../patient/Patientheader";
import PatientSideMenu from "../patient/Patientsidemenu";
import $ from "jquery";
import ReactExport from "react-data-export";
import { RawHTML } from 'react-dom';


import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Razorpay = require("razorpay");

export default class ViewsubscriptionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponState: "",
      selectedSubscriptionData: [],
      patient_id: "",
      user_id: "",
      subscribedUserPlan: [],
      remainingPlanDays: 0
    }
  }

  // To get detais after first render
  componentDidMount = () => {
    var retrievedObject = ls.get("userObjEnc");
    this.state.patient_id = retrievedObject.patient_id
    this.state.user_id = retrievedObject.userID
    var patient_id = ls.get("SelectedPatientIdEnc");
    this.getUserSubscriptions();

  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // For getting the data
  getSubscriptionPlans() {
    Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/getsubscription")
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
      })
      .catch((error) => { });
  }

  //To get all subscrption that user subscribed
  getUserSubscriptions = () => {
    Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/getusersubscription", { "patient_id": this.state.patient_id, status: 1 })
      .then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          this.state.subscribedUserPlan = response.data.data
          this.getSubscriptionPlans();
          if (this.state.subscribedUserPlan.length > 0) {
            var currentDate = new Date();
            this.state.remainingPlanDays = this.state.subscribedUserPlan[0].subscription_todate ? moment(this.state.subscribedUserPlan[0].subscription_todate).format("YYYY-MM-DD") : 0;
          } else {
            toast.error("You have no subscriptions, please subscribe", {
              position: "top-center"
            })
          }
        } else {
          this.state.subscribedUserPlan = []
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  //bySubscription
  bySubscription = (e) => {
    let selectedSubscriptionId = e.currentTarget.id;
    console.log("bySubscription...")
    let userSubscriptionData = {}
    this.state.selectedSubscriptionData = this.state.page_data.filter((subscription) => {
      if (subscription.id == selectedSubscriptionId) {
        subscription.patient_id = this.state.patient_id
        subscription.subscription_id = subscription.id
        subscription.user_id = this.state.user_id
        return true
      }
    })
    Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/isSubscribed", this.state.selectedSubscriptionData[0])
      .then((response) => {
        if (response.data.isSubscribed) {
          toast.error(response.data.message)
        } else {
          this.openCheckout()
        }
      })
  }

  //Razorpay payment gateway
  openCheckout = () => {
    let that = this;
    let options = {
      key: Constant.razorpay_key_id,
      amount: that.state.selectedSubscriptionData[0].subscription_amount * 100, //this.state.amount, // 2000 paise = INR 20, amount in paisa
      name: "Padma Clinic",
      description: "Diagnosis",
      image: Constant.siteurl + "/images/patient/img/main-logo.png",
      handler: async function (response) {
        Httpconfig.httptokenpost(
          Constant.siteurl + "api/DIAGNOSIS/orderProcess/rzp/paymentCapture",
          {
            payment_id: response.razorpay_payment_id,
            amount: that.state.selectedSubscriptionData[0].subscription_amount * 100,
            currency: "INR",
          }
        ).then((captureResponse) => {
          that.conformSubscription(captureResponse.data.data);
        });
      },
      modal: {
        ondismiss: function () { },
      },
      prefill: {
        name: "Rakesh",
        email: "rakesh.n@medleymed.com",
        phone: "9652726606",
      },
      notes: {
        address: this.state.address,
      },
      theme: {
        color: "#555AB9",
      },
    };
    let rzp = new window.Razorpay(options);
    //  let rzp = new Razorpay(options);
    rzp.open();
  };

  conformSubscription = (transactionResponse) => {
    delete this.state.selectedSubscriptionData[0]["id"]
    Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/createusersubscription", this.state.selectedSubscriptionData[0])
      .then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          toast.success(response.data.message)
          this.getSubscriptionPlans()
          this.getUserSubscriptions()
        } else {
          toast.success(response.data.message)
        }
      })
  }

  subscriptionBackNavigation = () => {
    var url = "";
    url = ls.get("backredirecturl");

    if (url == "") {
      window.location.href = Constant.imgurl + "/Patientprofile";

    } else {
      window.location.href = Constant.imgurl + "/" + url;

    }

  }


  render() {
    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <section id="main_dashboard">
          <div className="container medicine_main" id="main_front">
            <div className="row">
              <div class="col-lg-3 col-md-12">
                <PatientSideMenu />
              </div>
              <div class="col-lg-9 col-md-12">
                <section id="subscription">
                  <div class="container">

                    <div class="row" id="notification">
                      <div class="col-md-12 no_padding">
                        <section id="medicine_reminder">
                          <div class="med_vitsub">

                            <div class="sub_plan">
                              {this.state.remainingPlanDays ? <h2>Your current subscription plan <b>{this.state.subscribedUserPlan[0].master_subscription_tbl.subscription_name}</b> expires on <b>{this.state.remainingPlanDays}</b>
                              </h2> : <h2>You have no subscriptions please subscribe</h2>}
                              <div class="close_bar" onClick={() => { $('#notification').hide("slow") }}>
                                <span><i class="fa fa-times" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div class="sub_head">
                          <h2>
                            {/* <Link
                                to="/Viewremindersetting"> */}
                            <span onClick={this.subscriptionBackNavigation} ><img src="../images/patient/img/arrow-back.svg" /></span>
                            {/* </Link> */}
                            {"       "}
                            Subscriptions
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        {/* <div class="sub_main">
                       <h2>Your current subscription plan expires on 12/07/2022
                        </h2>
                       <p>12 days left</p>
                     </div> */}
                        <div class="subscribe_main">
                          <div class="row">
                            {this.state.page_data ?
                              this.state.page_data.map(
                                (item, num) => (
                                  <div class="col-md-4">
                                    <div class="subscribe_box">

                                      <div class={this.state.subscribedUserPlan.length > 0 && this.state.subscribedUserPlan[0].subscription_id == item.id ? "heading_sub" : "heading"} >
                                        <h2>{item.subscription_name}</h2>
                                        <p>{Constant.currency + " " + item.subscription_amount}</p>
                                      </div>

                                      <div class="list">

                                        {/* <p><i class="fa fa-caret-right" aria-hidden="true"></i>{item.subscription_type}</p>
                    <p><i class="fa fa-caret-right" aria-hidden="true"></i>{ item.subscription_days} days validity</p> */}
                                        <p dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}></p>
                                        {/* <p dangerouslySetInnerHTML={__html: {item.description}></p> */}
                                        {/* <RawHTML>{dangerouslySetInnerHTML= { __html: item.description } }</RawHTML> */}
                                        {/* <React.Fragment dangerouslySetInnerHTML={ { __html: item.description } }></React.Fragment>                     {/* <p><i class="fa fa-caret-right" aria-hidden="true"></i>Medicine reminders in web & mobile</p> */}
                                        {/* <p><i class="fa fa-caret-right" aria-hidden="true"></i>Customize reminders</p>
                    <p><i class="fa fa-caret-right" aria-hidden="true"></i>Snooze option</p> */}
                                      </div>
                                      <div class="sub_footer">
                                        {this.state.subscribedUserPlan.length > 0 && this.state.subscribedUserPlan[0].subscription_id == item.id ?
                                          <button type="button" id={item.id} class="sub_btn_dis">Subscribed</button> :
                                          <button type="button" id={item.id} class="sub_btn" onClick={this.bySubscription}>Subscribe</button>}
                                      </div>
                                    </div>
                                  </div>
                                ))

                              : "NO Data"}
                            {/* <div class="col-md-4">
                          <div class="subscribe_box">
                            <div class="heading">
                            <h2>Basic Plan</h2>
                              <p>₹ 500</p>
                            </div>
                            <div class="list">
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>1 month plan</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>30 days validity</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Medicine reminders in web & mobile</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Customize reminders</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Snooze option</p>
                            </div>
                            <div class="sub_footer">
                                <button type="button" class="sub_btn">Subscribe</button>
                            </div>
                          </div>
                        </div> */}


                            {/* <div class="col-md-4">
                          <div class="subscribe_box">
                            <div class="heading">
                            <h2>Prime Plan</h2>
                              <p>₹ 1000</p>
                            </div>

                            <div class="list">
                              
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>2 months plan</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>120 days validity</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Medicine reminders in web & mobile</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Customize reminders</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Snooze option</p>
                            </div>
                            <div class="sub_footer">
                                <button type="button" class="sub_btn">Subscribe</button>
                            </div>
                          </div>
                        </div> */}
                            {/* <div class="col-md-4">
                          <div class="subscribe_box">
                            <div class="heading">
                              <h2>Signature Plan</h2>
                              <p>₹ 2000</p>
                            </div>
                            <div class="list">
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>4 months plan</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>120 days validity</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Medicine reminders in web & mobile</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Customize reminders</p>
                              <p><i class="fa fa-caret-right" aria-hidden="true"></i>Snooze option</p>
                            </div>
                            <div class="sub_footer">
                                <button type="button" class="sub_btn">Subscribe</button>
                            </div>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </section>
              </div>
            </div>
          </div>
        </section>

      </main>
    );
  }
}
