import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";

export default class ViewProductrequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      datatable: {
        columns: [
          {
            label: "Test Id",
            field: "medicineid",
            sort: "asc",
            width: 150,
          },
          {
            label: "Test Name ",
            field: "medicine_name ",
            sort: "asc",
            width: 150,
          },

          {
            label: "MRP",
            field: "mrp",
            sort: "asc",
            width: 150,
          },
          {
            label: "Discount",
            field: "discount",
            sort: "asc",
            width: 150,
          },
          {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 150,
          },
          {
            label: "Test Type",
            field: "test_type",
            sort: "asc",
            width: 150,
          },

          {
            label: "Diagnosis center",
            field: "retailer",
            sort: "asc",
            width: 150,
          },

          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
      },
    };
    this.deleteproductRequest = this.deleteproductRequest.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.fetchpagesdata();
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // For getting the data
  fetchpagesdata() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/request/productRequest/"
    )
      .then((response) => {
        let store_data = "";
        this.setState({
          page_data: response.data.data,
        });

        let assignvalues = [];

        this.items = response.data.data.map((item, key) =>
          item.status != 4
            ? assignvalues.push({
                medicineid: key + 1,
                "medicine_name ": item.medicine_name,

                mrp: item.mrp,
                discount: item.discount,
                description: item.description,
                test_type: item.test_type,
                retailer: item.diagnosis_registration_tbl.labname,
                actions: (
                  <div>
                    <span>
                      <i
                        onClick={() =>
                          this.requestaccpted(item.id, item.medicine_name)
                        }
                        className="fa fa-check-square point-cursor"
                        title={"Accepted " + item.medicine_name}
                      ></i>
                    </span>{" "}
                    &nbsp;
                    <span>
                      <i
                        onClick={() =>
                          this.requestrejected(item.id, item.medicine_name)
                        }
                        className="fa fa-window-close point-cursor"
                        title={"Rejected " + item.medicine_name}
                      ></i>
                    </span>{" "}
                    &nbsp;
                    <span>
                      <i
                        onClick={() =>
                          this.deleteproductRequest(item.id, item.medicine_name)
                        }
                        className="fa fa-trash point-cursor"
                        title={"Delete " + item.id}
                      ></i>
                    </span>{" "}
                    &nbsp;
                  </div>
                ),
              })
            : ""
        );
        if (assignvalues.length == 0) {
          assignvalues.push({
            medicineid: "",
            medicine_name: "",
            mrp: "",
            discount: "",
            description: "No Data",
            test_type: "",
            retailer: "",
            actions: "",
          });
        }

        let retailerState = Object.assign({}, this.state);

        retailerState.datatable.rows = assignvalues;
        this.setState(retailerState);
      })
      .catch((error) => {});
  }

  //Accepted controller
  requestaccpted(Id, name) {
    var isConfirm = window.confirm(
      "Are you sure to Add Test Name " + name + "?"
    );
    if (isConfirm) {
      Httpconfig.httptokenput(
        Constant.siteurl +
          "api/DIAGNOSIS/request/productRequest/productReqStatusUpdate/" +
          Id,
        {
          status: 1,
        }
      )
        .then((response) => {
          toast.success("Successfully Active Product Request");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {});
    }
  }

  //Reject controller
  requestrejected(Id, medicine_name) {
    var isConfirm = window.confirm(
      "Are you sure to Rejected " + medicine_name + "?"
    );
    if (isConfirm) {
      Httpconfig.httptokenput(
        Constant.siteurl +
          "api/DIAGNOSIS/request/productRequest/productReqStatusUpdate/" +
          Id,
        {
          status: 0,
        }
      )
        .then((response) => {
          toast.success("Successfully Rejected Product Request");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {});
    }
  }
  //delete controller
  deleteproductRequest(Id, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/DIAGNOSIS/request/productRequest/" + Id
      )

        .then((response) => {
          toast.success("Successfully Deleted Product Request");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {});
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link>&gt;Diagnostics &gt;
                      Product Request
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div id="reg_form">
                        <form>
                          <div className="row"></div>
                        </form>
                        {datatable.rows.length === 0 ? (
                          <p>No Data</p>
                        ) : (
                          <MDBDataTable
                            striped
                            responsive
                            bordered
                            small
                            data={datatable}
                          />
                        )}

                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="update_btn"
                              style={{ textAlign: "right" }}
                            ></div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
