import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigAgent";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Patientheader";
import Patcss from "../../public/css/patient/order-medicine.css";
import AgentHeader from "../agent/AgentHeader";
import AgentMenu from "./AgentMenu";
import Footer from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import FileBase64 from "react-file-base64";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const moment = require("moment");

const orderDetailsView = [];
const orderDetails = [];
const documentsRelated = [];

export default class Vieworders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_view_profile: true,
      selectedPatient: "",
      slotBookingTime: "",
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    let orderId = localStorage.getItem("AgentOrderID");
    let retailerId = localStorage.getItem("AgentRetailerId");
    this.getCompleteOrderDetails(retailerId, orderId);
  };

  getTimeSlots() {
    var startTime = moment("09:00", "HH:mm");
    var endTime = moment("23:59", "HH:mm");
    var timeSlots = [];
    while (startTime <= endTime) {
      let start = new moment(startTime).format("HH:mm A");
      startTime.add(60, "minutes");
      let end = new moment(startTime).format("HH:mm A");
      timeSlots.push(`${start}-${end}`);
    }
    return timeSlots;
  }

  showDirections = (latitude, event) => {
    let lat = latitude;
    let long = event.target.id;
    // window.open("https://maps.google.com?q=17.3529,78.5357", "_blank");
    window.open(
      "https://maps.google.com?q= " + lat + "," + long + ",",
      "_blank"
    );
  };

  // Redirect to orders view
  redirect = () => {
    window.location.href = Constant.imgurl + "/agent/Agentdashboard";
  };
  // For complete the order details
  getCompleteOrderDetails = (retailerId, orderId) => {
    let orderedQuantity = "";

    this.setState({
      is_view_profile: false,
      selectedOrderId: orderId,
      retailerId: retailerId,
    });

    Httpconfig.httptokenpost(
      Constant.siteurl +
      "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
      retailerId +
      "/" +
      orderId
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        const orderDetails = response.data.data.map((orderDetails, num) => {
          let orderDate = "";
          let userOrderDate = "";
          let total = "";
          orderDate = orderDetails.order_date;
          let isPrescriptionImages = "0";
          let isPrescriptionImagesList = "0";
          this.setState({ cartId: response.data.data[0].cart_id });
          this.getPatientRelations(
            orderDetails.patient_id,
            orderDetails.relative_id
          );

          if (orderDetails.cart_prescriptions_tbl) {
            isPrescriptionImages = Object.keys(
              orderDetails.cart_prescriptions_tbl
            ).length;
            if (isPrescriptionImages > 0) {
              isPrescriptionImagesList = Object.keys(
                orderDetails.cart_prescriptions_tbl.medical_document
              ).length;
              this.setState({
                files: orderDetails.cart_prescriptions_tbl.medical_document,
              });
            }
          }

          let slotDateTime = "";
          let isRetailerData = Object.keys(
            orderDetails.diagnosis_registration_tbl
          ).length;
          // if (isRetailerData > 0) {
          //   let slabDate =
          //     orderDetails.diagnosis_registration_tbl.set_delivery_days;
          //   if (slabDate) {
          //     var nowPlusOneDay = moment(orderDate).add("days", slabDate);
          //     slotDateTime = nowPlusOneDay.format("YYYY-MM-DD");
          //   }
          // }

          // let newdate = orderDate.split("-").reverse().join("-");
          userOrderDate = moment(orderDate).format("dddd, MMMM Do YYYY");
          // slotDateTime = moment(slotDateTime).format("dddd, MMMM Do YYYY");
          slotDateTime =
            moment(orderDetails.slot_date).format("dddd, MMMM Do YYYY") +
            "" +
            " , " +
            orderDetails.slot_time;
          var retailerCurrency = "";
          if (orderDetails.diagnosis_registration_tbl.currency) {
            retailerCurrency = orderDetails.diagnosis_registration_tbl.currency;
          }
          let orderMedicinesLength = orderDetails.order_processing_tbls.length;

          // let deliveryAddressLength = Object.keys(
          //   orderDetails.delivery_address_tbl
          // ).length;

          // let relation_name = "Self";
          // let deliveryAddress = "";
          if (orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]) {
            if (
              orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]
                .discount == ""
            ) {
              orderDetails.order_processing_tbls[0].retailer_stock_tbl[0].discount = 0;
            }

            let price_discount =
              parseFloat(orderDetails.net_amount).toFixed(2).replace(/,/g, "") *
              parseFloat(
                orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]
                  .discount
              )
                .toFixed(2)
                .replace(/,/g, "");
          }
          let Tot_discount = parseFloat(orderDetails.discount)
            .toFixed(2)
            .replace(/,/g, "");
          let coupanAmt =
            orderDetails.net_amount.replace(/,/g, "") - Tot_discount;
          if (orderDetails.coupan_value != "") {
            orderDetails.coupan_value = orderDetails.coupan_value.replace(
              /%/g,
              ""
            );
          }

          let coupanTotal = orderDetails.coupon_amount
            ? orderDetails.coupon_amount
            : "0.00";
          let totalDiscount = orderDetails.cart_level_discount
            ? orderDetails.cart_level_discount
            : "0.00";
          if (orderMedicinesLength > 0) {
            const retailerOrderMedicineDetailsView =
              orderDetails.order_processing_tbls.map((finalLoadedData, num) => {
                // total =
                //   parseFloat(finalLoadedData.amount.replace(/,/g, "")) -
                //   (parseFloat(finalLoadedData.amount.replace(/,/g, "")) *
                //     parseFloat(
                //       finalLoadedData.retailer_stock_tbl[0].discount
                //     )) /
                //     100;
                let discount = 0;
                if(finalLoadedData.discount){
                  discount = finalLoadedData.discount
                }
                total =
                  parseFloat(finalLoadedData.amount.replace(/,/g, "")) -
                  (parseFloat(finalLoadedData.amount.replace(/,/g, "")) *
                    parseFloat(
                      discount
                    )) / 100;
                orderedQuantity = finalLoadedData.quantity;
                return (
                  <div class="med_list">
                    <div class="med_name">
                      <div className="med_amount">
                        <span> {num + 1}. </span>
                        <h2 class="agent_test_title">
                          {
                            finalLoadedData.diagnosis_products_master_tbl
                              .testname
                          }{" "}
                        </h2>
                        <h4 class="mrp">
                          MRP:
                          <del>
                            {retailerCurrency} {finalLoadedData.amount}
                          </del>
                          <h6 class="dis_data">
                            {finalLoadedData.discount
                              ? "" +
                              finalLoadedData.discount +
                              "%"
                              : ""}{" "}
                            Off
                          </h6>
                        </h4>
                        <h5>
                          {retailerCurrency}{" "}
                          {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </h5>
                      </div>
                      <div className="order_descp">
                        <h6>
                          {finalLoadedData.diagnosis_products_master_tbl
                            .manufacturer
                            ? finalLoadedData.diagnosis_products_master_tbl
                              .manufacturer
                            : ""}
                        </h6>
                        <p>
                          {finalLoadedData.diagnosis_products_master_tbl.form
                            ? finalLoadedData.diagnosis_products_master_tbl.form
                            : ""}
                        </p>
                        <p>
                          {finalLoadedData.diagnosis_products_master_tbl.size
                            ? finalLoadedData.diagnosis_products_master_tbl.size
                            : ""}
                        </p>
                        <h6>
                          {orderedQuantity
                            ? "Ordered Quantity: " + orderedQuantity
                            : ""}
                        </h6>
                      </div>
                      {/* <h6>
                        {finalLoadedData.retailer_stock_tbl
                          ? "Discount: " +
                            finalLoadedData.retailer_stock_tbl[0].discount +
                            "%"
                          : ""}
                      </h6> */}
                      { }
                    </div>
                  </div>
                );
              });
            this.setState({
              retailerOrderMedicineDetailsView:
                retailerOrderMedicineDetailsView,
            });
          }

          let progressBar = "";

          if (orderDetails.order_status === 1) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">2</a> <p class="p_light">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">5</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 2) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Verified </p></div>';
            if (
              orderDetails.delivery_type == "home_visit" &&
              orderDetails.test_status == "picked-up"
            ) {
              progressBar +=
                '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg"></a><p class="p_dark">Samples Collected </p></div>';
            } else {
              progressBar +=
                '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div>';
            }
            progressBar +=
              '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 3) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 4) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 5) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Completed</p></div></div>';
          } else if (orderDetails.order_status === 6) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="../images/patient/img/Patient Intake Process/tickmark.svg" /></a><p class="p_light">Cancelled </p></div></div>';
          }
          let orderCreatedAt = moment(orderDetails.order_date);
          let mindiff = moment().diff(orderCreatedAt, "minutes");
          let constTime = Constant.orderCancelationTime.split(":");
          let totalCancelationMinutes =
            Number(constTime[0]) * 60 + Number(constTime[1]);
          return (
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="vitalsheet_head">
                    <h2 onClick={this.redirect}>
                      <img src="../images/patient/img/diagnostics/arrowback.svg"></img>{" "}
                      Back
                    </h2>
                  </div>
                  <div class="order_det_list">
                    <div class="row">
                      <div class="col-md-12">
                        {/* <div class="vitalsheet_head">
                          <h4 onClick={this.redirect}>
                            <img src="../images/patient/img/diagnostics/arrowback.svg"></img>{" "}
                            Back
                          </h4>
                        </div> */}
                        <div class="order_det_head">
                          <div class="presc_img">
                            <img src="../images/patient/img/Ordermedicine/pdf.svg" />
                            {orderDetails.invoice ? (
                              <a
                                href={
                                  orderDetails.invoice
                                    ? Constant.imgurl + orderDetails.invoice
                                    : ""
                                }
                                target="_blank"
                              ></a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="presc_det">
                            <h2>
                              Order ID: <span>{orderDetails.id}</span>
                            </h2>
                            <h2>
                              Order Date: <span>{userOrderDate}</span>
                            </h2>
                          </div>
                          {orderDetails.order_status == 1 ? (
                            <p class="pending_btns verify_btn">
                              Order verification pending..
                            </p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 2 &&
                            orderDetails.test_status == "picked-up" &&
                            orderDetails.payment_method == "COD" ? (
                            <p class="verify_btn">Samples Collected</p>
                          ) : orderDetails.order_status == 2 ? (
                            <p class="verify_btn">Order Verified</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 3 ? (
                            <p class="verify_btn">Samples Collected</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 4 ? (
                            <p class="verify_btn">Processed</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 5 ? (
                            <p class="verify_btn">Completed</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 6 &&
                            orderDetails.refund_status == 6 ? (
                            <p class="pending_btns verify_btn">
                              Cancelled : <br />
                              Refund Queued For Processing..
                            </p>
                          ) : orderDetails.order_status == 6 &&
                            orderDetails.refund_status == 7 ? (
                            <p class="pending_btns verify_btn ">
                              Cancelled : <br /> Amount Refunded
                            </p>
                          ) : orderDetails.order_status == 6 ? (
                            <p class="pending_btns verify_btn">Cancelled </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="delivery_det">
                          <div class="expect_head">
                            <h2>Slot Date & Time {slotDateTime}</h2>
                            {/* <h6>
                              You will received an invoice with order details
                              after verified the prescription by our pharmacy
                            </h6> */}
                          </div>
                          <div class="expect_wizard">
                            <div class="stepwizard">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: progressBar,
                                }}
                              />
                            </div>
                          </div>
                          {/* <div class="retailer_address">
                            <React.Fragment>
                              <h4>
                                {
                                  orderDetails.diagnosis_registration_tbl
                                    .storename
                                }
                              </h4>
                              <p>
                                {
                                  orderDetails.diagnosis_registration_tbl
                                    .address
                                }
                              </p>
                            </React.Fragment>
                          </div> */}
                          <div class="delivery_address">
                            <h2>Address</h2>
                            {orderDetails.delivery_address_tbl ? (
                              <React.Fragment>
                                <h4>
                                  {orderDetails.delivery_address_tbl.name
                                    ? orderDetails.delivery_address_tbl.name
                                    : "--"}
                                </h4>
                                <p>
                                  {orderDetails.delivery_address_tbl.address
                                    ? orderDetails.delivery_address_tbl.address
                                    : ""}

                                  <br />
                                  {orderDetails.delivery_address_tbl.landmark
                                    ? orderDetails.delivery_address_tbl.landmark
                                    : ""}
                                  <br />
                                  {orderDetails.delivery_address_tbl.zipcode
                                    ? orderDetails.delivery_address_tbl.zipcode
                                    : ""}
                                  <br />
                                  <span>Mobile :</span>
                                  {orderDetails.delivery_address_tbl.mobile_no
                                    ? orderDetails.delivery_address_tbl
                                      .mobile_no
                                    : ""}
                                </p>
                                {/* <a
                                  onClick={this.showDirections.bind(
                                    this,
                                    orderDetails.delivery_address_tbl.latitude
                                  )}
                                  id={
                                    orderDetails.delivery_address_tbl.longitude
                                  }
                                  // style={{ display: "none" }}
                                >
                                  <img
                                    class="loc_img"
                                    src="../images/patient/img/Doctors_List/location.svg"
                                  />{" "}
                                  {translate("Directions")}
                                </a> */}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* {orderDetails.order_status != 6 &&
                          orderDetails.test_status != "picked-up" ? (
                            <b>Change Time Slot</b>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status != 6 &&
                          orderDetails.test_status != "picked-up" ? (
                            <select
                              onChange={this.changeTimeSlots.bind(this)}
                              name="slot_time"
                              defaultValue={orderDetails.slot_time}
                              id={
                                orderDetails.order_processing_tbls[0].order_id
                              }
                              className="form-control"
                            >
                              <option value="" disabled={true}>
                                Select Time
                              </option>
                              {this.getTimeSlots().map((times) => {
                                return (
                                  <option
                                    value={times}
                                    selected={orderDetails.slot_time}
                                  >
                                    {times}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="payment_det">
                          <div class="pay_head">
                            <h2>Products & Payment details</h2>
                            <p>
                              Test(s) for{" "}
                              <span id="patientRelations">
                                {this.state.selectedPatient
                                  ? this.state.selectedPatient
                                  : ""}
                              </span>
                            </p>
                          </div>
                          <div class="medicine_sec">
                            {this.state.retailerOrderMedicineDetailsView}
                          </div>
                          <div class="price_det_head">
                            <h2>Price details</h2>
                          </div>
                          <div class="pay_list">
                            <p>
                              Total M.R.P.{" "}
                              <span class="tot_amnt">
                                {retailerCurrency}{" "}
                                {parseFloat(orderDetails.net_amount)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </span>
                            </p>
                            <p>
                              Coupon Discount (-) :
                              <span>
                                {retailerCurrency}{" "}
                                {coupanTotal
                                  ? parseFloat(coupanTotal)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : "0"}
                              </span>
                            </p>
                            <p>
                              Sample Collection Charges
                              <span>
                                + {retailerCurrency}{" "}
                                {orderDetails.delivery_charges
                                  ? parseFloat(orderDetails.delivery_charges)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : "0.00"}
                              </span>
                            </p>
                          </div>
                          <h4 class="total_payable">
                            Discount{" "}
                            <span>
                              {retailerCurrency}{" "}
                              {parseFloat(totalDiscount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </span>
                          </h4>
                          <h4 class="total_payable">
                            Total Paid Amount{" "}
                            <span>
                              {orderDetails.payable_amount
                                ? retailerCurrency +
                                " " +
                                parseFloat(orderDetails.payable_amount)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : "0.00"}
                            </span>
                          </h4>

                          <div class="pay_mode">
                            <h2>Payment Mode</h2>
                            <h3>
                              Paid Through :
                              <span>
                                {orderDetails.payment_method
                                  ? orderDetails.payment_method
                                  : "Online"}
                              </span>
                            </h3>
                          </div>
                          {/* <div>
                            <a
                              orderId={orderDetails.id}
                              retailerId={orderDetails.retailer_id}
                              href="javascript:void(0);"
                            >
                              {" "}
                              {orderDetails.order_status != 5 &&
                              orderDetails.order_status != 6 &&
                              mindiff <= totalCancelationMinutes &&
                              orderDetails.order_status != 4 ? (
                                <p class="cancel_txt">Cancel Order</p>
                              ) : (
                                ""
                              )}
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        this.setState({
          orderDetails: orderDetails,
        });
      }
    });
  };

  // For getting the user relationships
  getPatientRelations = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtivesAgent/" + patientId,
      {}
    )
      .then((response) => {
        /* Assiging consulation purpose to the state */
        let pName = "";
        let relationName = "";
        let selectedPatient = "";
        let id = "";
        let dataCount = Object.keys(response.data.data).length;
        for (let rel = 0; rel < dataCount; rel++) {
          if (response.data.data[rel].id != "") {
            id = response.data.data[rel].id;
          }
          if (response.data.data[rel].name != null) {
            pName = response.data.data[rel].name;
          }
          if (response.data.data[rel].master_relationship != null) {
            relationName =
              "(" +
              response.data.data[rel].master_relationship.relation_name +
              ")";
          }

          if (patientId == id) {
            selectedPatient = pName + relationName;
          }
        }

        this.state.selectedPatient = selectedPatient;
        $("#patientRelations").html(selectedPatient);

        this.forceUpdate();
      })

      .catch((error) => {
        toast.error(error);
      });
  };

  changeTimeSlots = (event) => {
    let slot_time = event.target.value;

    let orderId = event.target.id;
    this.updateTimeSlots(slot_time, orderId);
  };

  //update patient time slots

  updateTimeSlots = (slot_time, orderId) => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/updateTimeslot",
      {
        slot_time: slot_time,
        orderId: orderId,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.success("Slots Updated Successfully");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For rendering the upload images

  render() {
    return (
      <main id="main_ord_nav">
        <AgentHeader />
        <AgentMenu />
        <I18nPropvider locale={this.state.Language}>
          {this.state.is_view_profile == true ? (
            <section id="order_profile">
              <div class="pro_section">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <React.Fragment>
              {" "}
              <div class="col-lg-10 col-md-10 agent_main">
                <section id="order_view_det">
                  <div class="pro_section">{this.state.orderDetails}</div>
                </section>
              </div>
            </React.Fragment>
          )}
          {/* <Footer /> */}
        </I18nPropvider>
      </main>
    );
  }
}
