import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const moment = require("moment");

export default class editProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      update: 0,
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.getProductInfo(handle);
    if (handle) {
      this.setState({
        update: 1,
      });
    }
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };

  // To get all the ResponderInfo
  getProductInfo(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/productsMaster/" + handle)
      .then((response) => {
        this.setState({
          fields: {
            id: response.data.data[0].medicineid,
            medicineid: response.data.data[0].medicineid,
            medicinename: response.data.data[0].medicinename,
            manufacturer: response.data.data[0].manufacturer,
            strength: response.data.data[0].strength,
            size: response.data.data[0].size,
            mrp: response.data.data[0].mrp,
            form: response.data.data[0].form,
            price_to_retail: response.data.data[0].price_to_retail,
            hsn_code: response.data.data[0].hsn_code,
            is_validated: response.data.data[0].is_validated,
            is_deleted: response.data.data[0].is_deleted,
            composition: response.data.data[0].composition,
          },
        });
      })
      .catch((error) => {
      });
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateProduct(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createProduct(event);
    } else {
      toast.warn("Form has errors.");
    }
  }
  // update Product
  updateProduct = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    Httpconfig.httptokenput(Constant.siteurl + "api/productsMaster/" + handle, {
      medicineid: fields["medicineid"],
      medicinename: fields["medicinename"],
      manufacturer: fields["manufacturer"],
      strength: fields["strength"],
      size: fields["size"],
      mrp: fields["mrp"],
      price_to_retail: fields["price_to_retail"],
      form: fields["form"],
      image: fields["image"],
      hsn_code: fields["hsn_code"],
      composition: fields["composition"],
      is_validated: fields["is_validated"],
      is_deleted: fields["is_deleted"],
    })
      .then((response) => {
        toast.success("Successfully Updated Retailer");
        setTimeout(() => this.props.history.push("/admin/ViewProducts"), 2000);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Create Product
  createProduct = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    Httpconfig.httptokenpost(Constant.siteurl + "api/productsMaster", {
      medicineid: fields["medicineid"],
      medicinename: fields["medicinename"],
      manufacturer: fields["manufacturer"],
      strength: fields["strength"],
      size: fields["size"],
      mrp: fields["mrp"],
      price_to_retail: fields["price_to_retail"],
      form: fields["form"],
      image: fields["image"],
      hsn_code: fields["hsn_code"],
      composition: fields["composition"],
      is_validated: fields["is_validated"],
      is_deleted: fields["is_deleted"],
    })
      .then((response) => {
        toast.success("Product Successfully Created");
        setTimeout(() => this.props.history.push("/admin/ViewProducts"), 2000);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
//  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["medicinename"]) {
      formIsValid = false;
      errors["medicinename"] = "medicine name cannot be empty";
    }
    if (!fields["manufacturer"]) {
      formIsValid = false;
      errors["manufacturer"] = "manufacturer cannot be empty";
    }
    if (!fields["mrp"]) {
      formIsValid = false;
      errors["mrp"] = "mrp cannot be empty";
    }
    if (!fields["form"]) {
      formIsValid = false;
      errors["form"] = "form cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      {this.state.update == 1 ? (
                        <a> Update Product </a>
                      ) : (
                        <a> Create Product </a>
                      )}
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        {this.state.update == 1 ? (
                          <h3>Update Product </h3>
                        ) : (
                          <h3>Create Product </h3>
                        )}
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>Medicine Name</strong>
                                </label>
                                <input
                                  type="text"
                                  name="medicinename"
                                  value={
                                    this.state.fields["medicinename"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "medicinename"
                                  )}
                                  className="form-control"
                                  placeholder="Medicine Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["medicinename"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>Manufacturer</strong>
                                </label>
                                <input
                                  type="text"
                                  name="manufacturer"
                                  value={
                                    this.state.fields["manufacturer"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "manufacturer"
                                  )}
                                  className="form-control"
                                  placeholder="Manufacturer"
                                />
                                <span className="cRed">
                                  {this.state.errors["manufacturer"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>Strength</strong>
                                </label>
                                <input
                                  type="text"
                                  name="strength"
                                  value={this.state.fields["strength"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "strength"
                                  )}
                                  className="form-control"
                                  placeholder="Strength"
                                />
                                <span className="cRed">
                                  {this.state.errors["strength"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>UOM</strong>
                                </label>
                                <input
                                  type="text"
                                  name="size"
                                  value={this.state.fields["size"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "size"
                                  )}
                                  className="form-control"
                                  placeholder="UOM"
                                />
                                <span className="cRed">
                                  {this.state.errors["size"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>MRP (Maximum Retail Price)</strong>
                                </label>
                                <input
                                  type="text"
                                  name="mrp"
                                  value={this.state.fields["mrp"] || ""}
                                  onChange={this.handleChange.bind(this, "mrp")}
                                  className="form-control"
                                  placeholder="MRP"
                                />
                                <span className="cRed">
                                  {this.state.errors["mrp"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>PTR (Price to Pharmacy)</strong>
                                </label>
                                <input
                                  type={this.state.type}
                                  name="price_to_retail"
                                  value={
                                    this.state.fields["price_to_retail"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "price_to_retail"
                                  )}
                                  className="form-control"
                                  placeholder="PTR"
                                />
                                <span className="cRed">
                                  {this.state.errors["price_to_retail"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>Form</strong>
                                </label>
                                <input
                                  type="text"
                                  name="form"
                                  value={this.state.fields["form"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "form"
                                  )}
                                  className="form-control"
                                  placeholder="Form"
                                />
                                <span className="cRed">
                                  {this.state.errors["form"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>HSN Code</strong>
                                </label>
                                <input
                                  type="text"
                                  name="hsn_code"
                                  value={this.state.fields["hsn_code"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "hsn_code"
                                  )}
                                  className="form-control"
                                  placeholder="hsn code"
                                />
                                <span className="cRed">
                                  {this.state.errors["hsn_code"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>
                                  <strong>Composition</strong>
                                </label>
                                <input
                                  type="text"
                                  name="composition"
                                  value={this.state.fields["composition"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "composition"
                                  )}
                                  className="form-control"
                                  placeholder="Composition"
                                />
                                <span className="cRed">
                                  {this.state.errors["composition"]}
                                </span>
                              </div>
                            </div>
                          </div>



                          <div className="row">
                            <div className="form-group col-md-12">
                              {this.state.update == 1 ? (
                                <button
                                  type="submit"
                                  className="btn  btn-primary save_btn"
                                >
                                  Update Product
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn  btn-primary save_btn"
                                >
                                  Create Product
                                </button>
                              )}{" "}
                              &nbsp;
                              <Link
                                to="/admin/ViewProducts"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
