import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Patcss from "../../public/css/patient/style_pat.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Patientheader";
import PatientMenu from "../patient/Patientmenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import loginbgi from "../../public/images/loginbgi.png";
import * as SecureLS from "secure-ls";

import Facebook from "../Facebook";
import GoogleBtn from "../GoogleBtn";
const moment = require("moment");
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class home extends Component {
  constructor(props) {
    super(props);
    this.showDiv = this.showDiv.bind(this);
    this.showemail = this.showemail.bind(this);
    this.showmobile = this.showmobile.bind(this);
    this.otpvalidate = this.otpvalidate.bind(this);

    this.state = {
      showlogin: true,
      showsignup: false,
      Language: "",
      otp: "",
      id: "",
      otp_expiry: "",

      googleName: "",
      googleEmail: "",
      isSocialObject: false,

      controller_count: 0,
      controller_total: 0,
      module_count: 0,
      module_total: 0,
      fields: { "name": "" },
      errors: {},
      languages_data: "",
      clinicDetailsView: "",
    };
  }
  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
  };

  handleLangChange = (field, event) => {
    let fields = this.state.fields;

    fields[field] = event.target.value;
    this.setState({ fields });
  };

  // To get detais after first render
  componentDidMount = () => {
    console.log("login.js")
    var userObjEnc = localStorage.getItem("userObjEnc");
    if (userObjEnc != null) {
      setTimeout(() => this.props.history.push("./"), 2000);
    }

    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
    let clinic_id = window.location.pathname.split("/");

    this.getClinicList();
    this.fetchlanguagesdata();
    document.getElementById("login_email").style.display = "none";
    document.getElementById("confirm_otp").style.display = "none";
    document.getElementById("phone_confirm_otp").style.display = "none";

    let isSocialObject = reactLocalStorage.getObject("isSocialObject");
    if (Object.keys(isSocialObject).length != 0) {
      this.state.googleName = isSocialObject.name;
      this.state.googleEmail = isSocialObject.email;
      this.state.isSocialObject = true;
    }
  };
  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  fetchlanguagesdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Languages/")
      .then((response) => {
        this.setState({
          languages_data: response.data,
        });
      })
      .catch((error) => { });
  }

  // For validating the otp
  otpvalidate = (e) => {
    var otp = document.getElementById("otp").value;
    var randPass = ls.get("randpass");
    let buff = Buffer.from(randPass, "base64");
    let text = buff.toString("utf-8");

    if (otp == text) {
      this.createPatient(e);
    } else {
      toast.error("Invalid OTP");
    }
    // Httpconfig.httptokenpost(
    //   Constant.siteurl + "api/Users/checkOTP", // + this.state.id
    //   {
    //     email: email,
    //     mobilenum: number,
    //     otp,
    //     timeZone: moment.tz.guess(),
    //   }
    // ).then((response) => {
    //   if (response.data.status == 200 && !response.data.error) {
    //     toast.success("Registration Completed Successfully .Please Login");
    //     document.getElementById("otp").value = "";
    //     window.location.reload();
    //   } else {
    //     toast.warn(response.data.message);
    //   }
    // });
  };
  // For showing the mail option
  showemail = (e) => {
    document.getElementById("login_email").style.display = "block";
    document.getElementById("login_phone").style.display = "none";
  };
  // For showing the mobile option
  showmobile = (e) => {
    document.getElementById("login_email").style.display = "none";
    document.getElementById("login_phone").style.display = "block";
  };

  // For showing user div

  showDiv = (e) => {
    const buttonId = e.target.id;
    if (buttonId == "nav-home-tab") {
      this.setState({
        showlogin: true,
        showsignup: false,
      });

      var element = document.getElementById("login_home");
      element.classList.add("show");
      var element = document.getElementById("login_home");
      element.classList.add("active");
      var element = document.getElementById("signup_home");
      element.classList.remove("show");
      var element = document.getElementById("signup_home");
      element.classList.remove("active");
      var element = document.getElementById(buttonId);
      element.classList.add("show");
      var element = document.getElementById(buttonId);
      element.classList.add("active");
      var element = document.getElementById("nav-profile-tab");
      element.classList.remove("show");
      var element = document.getElementById("nav-profile-tab");
      element.classList.remove("active");
    }
    if (buttonId == "nav-profile-tab") {
      this.setState({
        showlogin: false,
        showsignup: true,
      });

      var element = document.getElementById("signup_home");
      element.classList.add("active");
      var element = document.getElementById("signup_home");
      element.classList.add("show");
      var element = document.getElementById("login_home");
      element.classList.remove("show");
      var element = document.getElementById("login_home");
      element.classList.remove("active");
      var element = document.getElementById(buttonId);
      element.classList.add("show");
      var element = document.getElementById(buttonId);
      element.classList.add("active");
      var element = document.getElementById("nav-home-tab");
      element.classList.remove("show");
      var element = document.getElementById("nav-home-tab");
      element.classList.remove("active");
    }
  };

  // When value changes of the login Mogile Fields
  loginHandleMobileChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };

  // For Validating the otp
  validateOTP = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
    return;
  };

  // For redirecting after validate otp

  validateOTPRedirect(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    console.log("continue....")
    if (this.handlePhoneOtpValidation() && handle == undefined) {
      const { fields, errors } = this.state;

      let generatedSessionOtp = this.state.loginOTP;
      let generatedToken = this.state.sessionAccessToken;
      let generatedUserobj = this.state.userObj;
      let enteredOtp = fields["validate_login_otp"];

      let email = fields["email_id"];
      let number = fields["user_login_mobile_no"];

      Httpconfig.httptokenpost(
        Constant.siteurl + "api/Users/checkOTP", // + this.state.id
        {
          otp: enteredOtp,
          email: email,
          mobilenum: number,
          timeZone: moment.tz.guess(),
        }
      ).then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          let generatedOTP = response.data.data.userobj.otp;
          let generatedToken = response.data.data.userobj.accessToken;
          this.state.id = response.data.data.userobj.userID;
          this.state.selectedUserId = response.data.data.userobj.userID;
          this.state.loginOTP = generatedOTP;
          this.state.sessionAccessToken = generatedToken;
          this.state.userObj = response.data.data.userobj;
          console.log("------ this.state.userObj------", this.state.userObj);

          this.forceUpdate();
          localStorage.setItem(
            "patientToken",
            response.data.data.userobj.accessToken
          );

          let redirectStatus = "";
          redirectStatus = localStorage.getItem("ordermedicineredirect");
          //reactLocalStorage.setObject("userObj", generatedUserobj);
          ls.set("userObjEnc", this.state.userObj);
          let userObj = localStorage.getItem("userObj");

          if (redirectStatus == 1) {
            setTimeout(
              () =>
                this.props.history.push("/ordermedicinehome", {
                  userObj: this.state.userObj,
                }),
              1000
            );
            localStorage.removeItem("ordermedicineredirect");
            return;
          }

          Httpconfig.httptokenget(
            Constant.siteurl +
            "api/problem/checkIsProfileUpdated/" +
            JSON.parse(this.state.userObj.patient_id)
          )
            .then((responseData) => {
              if (responseData.data.status == "200") {
                let healthProfileStatus = responseData.data.healthProfileStatus;
                if (healthProfileStatus == 1) {
                  setTimeout(
                    () =>
                      this.props.history.push("/", {
                        userObj: this.state.userObj,
                      }),
                    1000
                  );
                }
                if (healthProfileStatus == 0) {
                  setTimeout(
                    () =>
                      this.props.history.push("/Patientprofile", {
                        userObj: this.state.userObj,
                      }),
                    1000
                  );
                }
              }
            })
            .catch((error) => {
              toast.error(error);
            });
        } else {
          toast.error(response.data.message);
        }
      });
    } else {
      toast.warn("Validate OTP has errors.");
    }
  }

  //  For validating the otp by mobile

  handlePhoneOtpValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["validate_login_otp"]) {
      errors["validate_login_otp"] = "";
      formIsValid = false;
      errors["validate_login_otp"] = "OTP cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // Login Form Validation Action
  loginMobileCheckSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleMobileLoginValidation() && handle == undefined) {
      this.createPatientLoginSession(event);
    }
  }

  // Login Form Validation Action

  loginEmailCheckSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleEmailLoginValidation() && handle == undefined) {
      this.createEmailPatientLoginSession(event);
    }
  }

  //  For creating user login
  createEmailPatientLoginSession = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    Httpconfig.httptokenpost(Constant.siteurl + "api/Users/login", {
      email: fields["email_id"],
      role: "patient",
      deviceId: "",
      device_type: "web",
      timezone: moment.tz.guess(),
    })
      .then((response) => {
        if (response.data.status == "204" && response.data.error == true) {
          toast.error("Not registered with this email id");
        }
        if (response.data.status == 200 && response.data.error) {
          toast.error(response.data.message);
          return;
        }
        if (
          response.data.loginOutput.notification.code == 200 &&
          response.data.loginOutput.notification.type == "Success"
        ) {
          toast.success(response.data.loginOutput.notification.message);
          document.getElementById("login_email").style.display = "none";
          document.getElementById("login_phone").style.display = "none";
          document.getElementById("phone_confirm_otp").style.display = "block";
          let generatedOTP = response.data.loginOutput.data.userobj.otp;
          let generatedToken =
            response.data.loginOutput.data.userobj.accessToken;
          this.state.id = response.data.loginOutput.data.userobj.userID;
          this.state.selectedUserId =
            response.data.loginOutput.data.userobj.userID;
          this.state.loginOTP = generatedOTP;
          this.state.sessionAccessToken = generatedToken;
          this.state.userObj = response.data.loginOutput.data.userobj;
          this.forceUpdate();
          localStorage.setItem(
            "patientToken",
            response.data.loginOutput.data.userobj.accessToken
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // Login Form Submit Action
  createPatientLoginSession = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    Httpconfig.httptokenpost(Constant.siteurl + "api/Users/login", {
      phone: fields["user_login_mobile_no"],
      role: "patient",
      timezone: moment.tz.guess(),
    })
      .then((response) => {
        if (response.data.status == "204" && response.data.error == true) {
          toast.error("Mobile Number not registered");
        }
        if (response.data.status == 200 && response.data.error) {
          toast.error(response.data.message);
          return;
        }
        if (
          response.data.loginOutput.notification.code == 200 &&
          response.data.loginOutput.notification.error == false
        ) {
          document.getElementById("login_phone").style.display = "none";
          document.getElementById("phone_confirm_otp").style.display = "block";
          let generatedOTP = response.data.loginOutput.data.userobj.otp;
          let generatedToken =
            response.data.loginOutput.data.userobj.accessToken;
          this.state.id = response.data.loginOutput.data.userobj.userID;
          this.state.selectedUserId =
            response.data.loginOutput.data.userobj.userID;

          this.state.loginOTP = generatedOTP;
          this.state.sessionAccessToken = generatedToken;
          this.state.userObj = response.data.loginOutput.data.userobj;
          this.forceUpdate();

          localStorage.setItem(
            "patientToken",
            response.data.loginOutput.data.userobj.accessToken
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    return;
  };

  // Form Validation Section
  handleMobileLoginValidation() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;
    if (!fields["user_login_mobile_no"]) {
      errors["user_login_mobile_no"] = "";
      formIsValid = false;
      errors["user_login_mobile_no"] = "Phone number cannot be empty";
    } else if (fields["user_login_mobile_no"].length < 10) {
      formIsValid = false;
      errors["user_login_mobile_no"] = "Phone number invalid";
    } else if (fields["user_login_mobile_no"].length > 10) {
      formIsValid = false;
      errors["user_login_mobile_no"] = "Phone number invalid";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  // Ends here

  handleEmailLoginValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (typeof fields["email_id"] !== "undefined") {
      let lastAtPos = fields["email_id"].lastIndexOf("@");
      let lastDotPos = fields["email_id"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email_id"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email_id"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user_login_email_id"] = "Email is invalid";
      }
    } else {
      errors["user_login_email_id"] = "Email cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // for submit the form
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
    } else if (this.handleValidation() && handle == undefined) {
      //this.createPatient(event);
      this.generateTempOTP();
    }
  }

  //Generate OTP for Prescription
  generateTempOTP = () => {
    const { fields, errors } = this.state;
    Httpconfig.httptokenpost(
      // Constant.siteurl + "api/Users/generateOtp", {patientId:id}
      Constant.siteurl + "api/Users/generatedynamicotp",
      {
        phonenumber: fields["phone"],
        name: fields["name"],
        email: fields["email_id"],
      }
    )
      .then((response) => {
        if (response.data.error == false) {
          ls.set("randpass", response.data.data);
          toast.success(response.data.message);
          let buff = Buffer.from(response.data.data, "base64");
          let text = buff.toString("utf-8");
          //console.log(text);
          document.getElementById("confirm_otp").style.display = "block";
          document.getElementById("signup_div").style.display = "none";
          //$("#modalInvoice").show("modal");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log("Generate otp err", err);
      });
  };

  //  For validating the form
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    var pattern = /^[a-zA-Z0-9]{3,20}$/g;
    var regex = /\d/g;
    var mobileRegx = new RegExp("^[0-9]*$");

    if (!fields["name"].trim()) {
      formIsValid = false;
      errors["name"] = "Enter your Name";
    }

    if (typeof fields["email_id"] !== "undefined" && fields["email_id"] != "") {
      let lastAtPos = fields["email_id"].lastIndexOf("@");
      let lastDotPos = fields["email_id"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email_id"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email_id"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email_id"] = "Email is invalid";
      }
    } else {
      //errors["email_id"] = "";
      formIsValid = false;
      errors["email_id"] = "Email cannot be empty";
    }
    if (!fields["phone"]) {
      errors["phone"] = "";
      formIsValid = false;
      errors["phone"] = "Phone number cannot be empty";
    } else if (
      fields["phone"].length < 10 ||
      fields["phone"].length > 10 ||
      !mobileRegx.test(fields["phone"])
    ) {
      formIsValid = false;
      errors["phone"] = "Phone number invalid";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  // Signup new patient
  createPatient = (event) => {
    event.preventDefault();
    var handle = this.props.match.params.handle;
    const { fields, errors } = this.state;
    let clinicId = this.state.fields.clinicId;
    Httpconfig.httptokenpost(Constant.siteurl + "api/Users/", {
      clinic_id: 1,
      email: fields["email_id"],
      mobile_number: fields["phone"],
      user_type: "patient",
      name: fields["name"],
      selected_language: "en",
      is_fingerprint_required: false,
      device_type: "web",
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success("Successfully Created Patient");
          window.location.reload();
          // document.getElementById("confirm_otp").style.display = "block";
          // document.getElementById("signup_div").style.display = "none";

          // this.state.id = response.data.data.id;
          // this.state.selectedUserId = response.data.data.id;
          // this.state.otp = response.data.data.otp;
          // this.state.otp_expiry = response.data.data.otp_expiry;
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For language change

  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  // for checking the length
  checklength = (event) => {
    var reg = new RegExp("^[0-9]*$");
    if (!reg.test(event.target.value) || event.target.value.length >= 10) {
      event.preventDefault();
    }
  };

  // for checking the mobile no length
  mobileLength = (event) => {
    let number = event.target.value;
    if (number.length > 10) {
      number = number.substring(0, 10);
      $("#user_login_mobile_no").val(number);
    }
  };

  // for sending the otp again
  resesndOTp = () => {
    const { fields, errors } = this.state;
    let selectedUser = this.state.selectedUserId;
    let email = fields["email_id"];
    let number = fields["user_login_mobile_no"];
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Users/resendOTP", //+ selectedUser,
      {
        email: email,
        mobilenum: number,
        timeZone: moment.tz.guess(),
      }
    )
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => { });
  };

  // get the list of clinics
  getClinicList = () => {
    Httpconfig.httptokenget(Constant.siteurl + "api/Clinic/clinicList")
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          const clinicDetailsView = response.data.data.map(
            (clinicDetails, num) => {
              return (
                <option value={clinicDetails.id}>
                  {clinicDetails.clinic_name}
                </option>
              );
            }
          );
          this.setState({
            clinicDetailsView: clinicDetailsView,
          });
        }
      })
      .catch((error) => { });
  };

  render() {
    const { languages_data, googleEmail, googleName, isSocialObject } =
      this.state;
    const BLOCK = { diplay: "block" };
    const NONE = { diplay: "none" };
    const MNumber = translate("Mobile Number");

    return (
      <main id="main">
        <I18nPropvider locale={this.state.Language}>
          <PatientHeader onSelectLanguage={this.handleLanguage} />
          <section
            id="login-page"
            style={{ backgroundImage: `url(${loginbgi})` }}
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 login_section
               no_padding"
                >
                  <nav>
                    <div
                      className="nav nav-tabs nav-fill"
                      id="nav-tab"
                      role="tablist"
                    >
                      <a
                        className="nav-item nav-link active"
                        id="nav-home-tab"
                        data-toggle="tab"
                        href="#login_home"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        onClick={this.showDiv}
                      >
                        {translate("Login")}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#signup_home"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                        onClick={this.showDiv}
                      >
                        {translate("Signup")}
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="login_home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      style={this.state.showlogin ? BLOCK : NONE}
                    >
                      <div className="login_content" id="login_phone">
                        <div className="login_box">
                          <div className="login_head">
                            <h5>
                              {translate(
                                "This number will be used for Health Care Provider consultation related information, new offers. We will send a code for verification to this number"
                              )}
                            </h5>
                          </div>
                          <form
                            onSubmit={this.loginMobileCheckSubmit.bind(this)}
                          >
                            <div className="form-group">
                              <FormattedMessage id="Enter Mobile number">
                                {(placeholder) => (
                                  <input
                                    type="number"
                                    id="user_login_mobile_no"
                                    name="user_login_mobile_no"
                                    class="form-control log-input"
                                    placeholder={placeholder}
                                    value={
                                      this.state.fields[
                                      "user_login_mobile_no"
                                      ] || ""
                                    }
                                    onChange={this.loginHandleMobileChange.bind(
                                      this,
                                      "user_login_mobile_no"
                                    )}
                                    onKeyUp={this.mobileLength}
                                  />
                                )}
                              </FormattedMessage>
                              <span className="cRed">
                                {this.state.errors["user_login_mobile_no"]}
                              </span>
                            </div>

                            <button
                              type="submit"
                              className="btn btn-default Next_btn"
                            >
                              {translate("Next")}
                            </button>

                            <div className="use_btn" onClick={this.showemail}>
                              <a href="#">
                                <p className="use_btn">
                                  <img src="../images/patient/img/Login/blue_mail.svg" />
                                  {translate("Use Email ID")}
                                </p>
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="login_content" id="phone_confirm_otp">
                        <div class="login_box">
                          <div class="login_head">
                            <h2>Enter Code</h2>
                            <h5>
                              {translate("Please enter your confirmation code")}
                            </h5>
                          </div>
                          <form onSubmit={this.validateOTPRedirect.bind(this)}>
                            <div class="form-group">
                              <FormattedMessage id="Enter code">
                                {(placeholder) => (
                                  <input
                                    type="text"
                                    name="validate_login_otp"
                                    class="form-control log-input"
                                    id="login_otp"
                                    placeholder={placeholder}
                                    onChange={this.validateOTP.bind(
                                      this,
                                      "validate_login_otp"
                                    )}
                                  />
                                )}
                              </FormattedMessage>

                              <span className="cRed">
                                {this.state.errors["validate_login_otp"]}
                              </span>
                            </div>
                            <button
                              type="submit"
                              class="btn btn-default Next_btn"
                            >
                              {translate("Continue")}
                            </button>
                            <a>
                              <p class="log_account" onClick={this.resesndOTp}>
                                Resend Code
                              </p>
                            </a>
                          </form>
                        </div>
                      </div>

                      <div class="login_content" id="login_email">
                        <div class="login_box">
                          <div class="login_head">
                            <h5>
                              This email will be used for Health Care Provider consultation
                              related information, new offers. We will send a
                              code for verification to this email
                            </h5>
                          </div>
                          <form
                            onSubmit={this.loginEmailCheckSubmit.bind(this)}
                          >
                            <div class="form-group">
                              <FormattedMessage id="Enter Email id">
                                {(placeholder) => (
                                  <input
                                    type="email"
                                    class="form-control log-input"
                                    placeholder={placeholder}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "email_id"
                                    )}
                                  />
                                )}
                              </FormattedMessage>
                            </div>
                            <span className="cRed">
                              {this.state.errors["user_login_email_id"]}
                            </span>
                            <a
                              href=""
                              onClick={this.loginEmailCheckSubmit.bind(this)}
                            >
                              {" "}
                              <button
                                type="button"
                                class="btn btn-default Next_btn"
                              >
                                Next
                              </button>
                            </a>
                            <div class="use_btn">
                              <a href="#">
                                <p class="mbl_btn" onClick={this.showmobile}>
                                  <img src="../images/patient/img/Login/Phone_number.svg" />
                                  Use Phone Number
                                </p>
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="signup_home"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                      style={this.state.showlogin ? BLOCK : NONE}
                    >
                      <div className="login_content" id="signup_div">
                        <div className="login_box">
                          <div className="login_head">
                            <h5>
                              {translate(
                                "We will send you an SMS with a confirmation code to this email"
                              )}
                            </h5>
                          </div>
                          { }

                          <form onSubmit={this.checkSubmit.bind(this)}>
                            <div className="form-group">
                              <FormattedMessage id="Name">
                                {(placeholder) => (
                                  <input
                                    type="text"
                                    className="form-control log-input"
                                    id="name"
                                    name="name"
                                    placeholder={placeholder}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "name"
                                    )}
                                  />
                                )}
                              </FormattedMessage>
                              <span className="cRed">
                                {this.state.errors["name"]}
                              </span>
                            </div>
                            <div className="form-group">
                              <FormattedMessage id="Email">
                                {(placeholder) => (
                                  <input
                                    type="email"
                                    className="form-control log-input"
                                    name="email_id"
                                    id="email_id"
                                    placeholder={
                                      placeholder
                                    }
                                    onChange={this.handleChange.bind(
                                      this,
                                      "email_id"
                                    )}
                                  />
                                )}
                              </FormattedMessage>
                              <span className="cRed">
                                {this.state.errors["email_id"]}
                              </span>
                            </div>
                            <div className="form-group">
                              <FormattedMessage id="Mobile Number">
                                {(placeholder) => (
                                  <input
                                    type="number"
                                    maxLength={10}
                                    className="form-control log-input"
                                    id="phone"
                                    name="phone"
                                    value={this.state.fields["phone"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "phone"
                                    )}
                                    onKeyPress={this.checklength.bind(this)}
                                    placeholder={placeholder}
                                  />
                                )}
                              </FormattedMessage>
                              <span className="cRed">
                                {this.state.errors["phone"]}
                              </span>
                            </div>

                            <a href="#">
                              <button
                                type="submit"
                                className="btn btn-default Next_btn"
                              >
                                {translate("Next")}
                              </button>
                            </a>
                          </form>
                        </div>
                      </div>

                      <div class="login_content" id="confirm_otp">
                        <div class="login_box">
                          <div class="login_head">
                            <h5>Please enter your confirmation code</h5>
                          </div>
                          <form>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control log-input"
                                id="otp"
                                placeholder="Enter code"
                              />
                            </div>

                            <a href="#">
                              <button
                                type="button"
                                class="btn btn-default Next_btn"
                                onClick={this.otpvalidate}
                              >
                                Continue
                              </button>
                            </a>
                            <a href="#" onClick={this.generateTempOTP}>
                              <p class="log_account">Resend OTP</p>
                            </a>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
