import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable, MDBBadge, MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";

export default class ViewRetailersWalletList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      datatable: {
        columns: [
          {
            label: "S.no",
            field: "Sno",
            sort: "asc",
            width: 50,
          },
          {
            label: "Pharmacy Name",
            field: "retailer_id",
            sort: "asc",
            width: 250,
          },

          {
            label: "Wallet Points",
            field: "wallet_points",
            sort: "asc",
            width: 150,
          },
          {
            label: "Wallet point value",
            field: "wallet_points_amount",
            sort: "asc",
            width: 150,
          },
          {
            label: "Deduction points",
            field: "deduct_points",
            sort: "asc",
            width: 250,
          },
        ],
        rows: [],
      },
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    this.fetchpagesdata();
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //update status for patient

  updateStatus(id, status) {
    console.log("id", id);
    console.log("Status", status);
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Coupon/ActiveInactive/" + status + "/" + id
    )

      .then((response) => {
        toast.success("Successfully updates Status");

        setTimeout(() => window.location.reload(false), 2000);
      })

      .catch((error) => {
        console.log(error);
      });
  }

  fetchpagesdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/OM/RetailerWalletlist/")
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) => {
          assignvalues.push({
            Sno: key + 1,
            id: item.id,
            retailer_id: item.retailer_registration_tbl
              ? item.retailer_registration_tbl.storename
              : "",
            wallet_points: item.wallet_points,
            wallet_points_amount: item.wallet_points_amount,
            deduct_points: item.deduct_points,
            status: item.status,

            actions: <div>&nbsp;</div>,
          });
        });

        let couponState = Object.assign({}, this.state);
        couponState.datatable.rows = assignvalues;
        this.setState(couponState);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //retailer to pharmacy
  render() {
    const { datatable } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt; Order Medicine
                      &gt; Pharmacy Wallet List
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3> Pharmacy Wallet List</h3>
                      </div>
                      <div id="reg_form" class="viewcoupons">
                        <form>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <Link
                                to="/admin/CreateRetailersWalletList"
                                className="btn  btn-primary fright"
                              >
                                Create Pharmacy Wallet
                              </Link>
                            </div>
                          </div>
                        </form>
                        {datatable.rows.length === 0 ? (
                          <p>Loading............</p>
                        ) : (
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            small
                            data={datatable}
                          />
                        )}

                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="update_btn"
                              style={{ textAlign: "right" }}
                            ></div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
