import React, { Component, useState } from "react";
import { Slider } from "react-semantic-ui-range";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { Dropdown } from "semantic-ui-react";
import { Multiselect } from "multiselect-react-dropdown";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class PatientVitalInformation extends Component {
  constructor(props) {
    super(props);
    const appointmentId = ls.get("appointmentIdEnc");
    this.state = {
      isHeightChecked: false,
      isWeightChecked: false,
      isBmiChecked: false,
      isTemparatureChecked: false,
      isPulseChecked: false,
      isBloodSugarChecked: false,

      isParentChecked: true,
      isDisabled: false,
      disabledRequired: false,
      fields: {},
      errors: {},
      status: true,

      bmiColor: "green_hr",
      bmiDisplayText: "Normal",

      tempColor: "green_hr",
      tempDisplayText: "Normal",

      pulseColor: "green_hr",
      pulseDisplayText: "Normal",

      bloodsugarColor: "green_hr",
      bloodsugarDisplayText: "Normal",

      respiratoryColor: "green_hr",
      respiratoryDisplayText: "Normal",

      respiratoryColor: "green_hr",
      respiratoryDisplayText: "Normal",

      systolicColor: "green_hr",
      systolicDisplayText: "Normal",

      diastolicColor: "green_hr",
      diastolicDisplayText: "Normal",

      isHeightDisabled: true,
      isWeightDisabled: true,
      isBMIDisabled: true,
      isTempDisabled: true,
      isPulseDisabled: true,
      isBloodSugarDisabled: true,
      isRespiratoryDisabled: true,
      isBPDisabled: true,
      appointmentId: appointmentId,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  //  For submitting the form
  onFormSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    let checkboxLength = $(".blue_check").length;
    let checkboxCheckedLength = $(".blue_check:checked").length;
    if (checkboxCheckedLength == 0) {
      window.location.href = "./" + this.state.nextPage;
    } else {
      if (this.handleValidation() && handle) {
      } else if (this.handleValidation() && handle == undefined) {
        this.createPatientVitals(event);
      } else {
        toast.warn("Form has errors.");
      }
    }
  }

  //  For creating the patient vitals
  createPatientVitals = (event) => {
    event.preventDefault();
    var handle = this.props.match.params.handle;
    const { fields, errors } = this.state;

    let url_path = "api/VitalInformation/";
    Httpconfig.httptokenpost(Constant.siteurl + url_path, {
      patient_id: JSON.stringify(this.state.patient_id),
      pulse_rate: this.state.fields.pulse,
      skip_pulse: this.state.fields.skip_pulse,
      temperature: this.state.fields.temparature,
      skip_temperature: this.state.fields.skip_temparature,
      blood_pressure_systolic: this.state.fields.systolic,
      blood_pressure_diastolic: this.state.fields.diastolic,
      skip_blood_pressure: this.state.fields.skip_bloodsugar,
      blood_sugar: this.state.fields.bloodsugar,
      respiratory_rate: this.state.fields.respiratoryrate,
      skip_respiratory_rate: this.state.fields.skip_respiratoryrate,
      height: this.state.fields.height,
      skip_height: this.state.fields.skip_height,
      weight: this.state.fields.weight,
      skip_weight: this.state.fields.skip_weight,
      bmi: this.state.fields.bmi,
      appointment_id: parseInt(this.state.appointmentId),
      patient_type: "adult",
      clinic_id: this.state.clinicId,
    })
      .then((response) => {
        if (response.status == 200) {
          let next_page = response.data.nextpage.web_reference_page_name;
          toast.dismiss();
          toast.success("👌 Vitals Saved succesfully", {
            position: "bottom-center",
            autoClose: 1000,
          });
          setTimeout(() => this.props.history.push("/" + next_page), 2000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["inHeight"]) {
      fields["inHeight"] = 0;
    }
    if (!fields["ftHeight"]) {
      fields["ftHeight"] = 0;
    }

    if (!fields["weight"] && this.state.isWeightDisabled == false) {
      formIsValid = false;
      errors["weight"] = "Weight cannot be empty";
    } else if (fields["weight"] < 10 && this.state.isWeightDisabled == false) {
      formIsValid = false;
      errors["weight"] = "Please enter valid Weight";
    }
    if (!fields["temparature"] && this.state.isTempDisabled == false) {
      formIsValid = false;
      errors["temparature"] = "Temparature cannot be empty";
    } else if (
      fields["temparature"] < 90 &&
      this.state.isTempDisabled == false
    ) {
      formIsValid = false;
      errors["temparature"] = "Please enter valid Temperature ";
    }
    if (!fields["pulse"] && this.state.isPulseDisabled == false) {
      formIsValid = false;
      errors["pulse"] = "Pulse cannot be empty";
    } else if (fields["pulse"] < 40 && this.state.isPulseDisabled == false) {
      formIsValid = false;
      errors["pulse"] = "Please enter valid Pulse";
    }
    if (!fields["bloodsugar"] && this.state.isBloodSugarDisabled == false) {
      formIsValid = false;
      errors["bloodsugar"] = "Bloodsugar cannot be empty";
    } else if (
      fields["bloodsugar"] < 40 &&
      this.state.isBloodSugarDisabled == false
    ) {
      formIsValid = false;
      errors["bloodsugar"] = "Please enter valid Bloodsugar ";
    }
    if (
      !fields["respiratoryrate"] &&
      this.state.isRespiratoryDisabled == false
    ) {
      formIsValid = false;
      errors["respiratoryrate"] = "Respiratoryrate cannot be empty";
    } else if (
      fields["respiratoryrate"] < 10 &&
      this.state.isRespiratoryDisabled == false
    ) {
      formIsValid = false;
      errors["respiratoryrate"] = "Please enter valid respiratory rate";
    }
    if (!fields["systolic"] && this.state.isBPDisabled == false) {
      formIsValid = false;
      errors["systolic"] = "Systolic cannot be empty";
    } else if (fields["systolic"] < 60 && this.state.isBPDisabled == false) {
      formIsValid = false;
      errors["systolic"] = "Please enter valid Systolic";
    }
    if (!fields["diastolic"] && this.state.isBPDisabled == false) {
      formIsValid = false;
      errors["diastolic"] = "Diastolic cannot be empty";
    } else if (fields["diastolic"] < 40 && this.state.isBPDisabled == false) {
      formIsValid = false;
      errors["diastolic"] = "Please enter valid Diastolic";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // called after the React component props or state has changed.

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");

    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
  };

  // To get detais after first render
  componentDidMount = () => {
    this.setState({
      isDisabled: false,
      disabledRequired: false,
    });
    var retrievedObject = ls.get("userObjEnc");

    var appointmentId = ls.get("appointmentIdEnc");
    var patient_id = ls.get("SelectedPatientIdEnc");
    this.state.appointmentId = appointmentId;
    let userData = retrievedObject;
    if (patient_id != null || patient_id != "") {
      userData.patient_id = patient_id;
    }
    if (retrievedObject != null || retrievedObject != null) {
      this.setState({
        name: userData.name,
        user_mobile: userData.mobile_number,
        email_id: userData.email,
        token: userData.accessToken,
        patient_id: userData.patient_id,
        profile_image: "",
        bmiDefault: "",
      });

      let appointmentId = this.state.appointmentId;
      let patientId = userData.patient_id;
      this.state.clinicId = userData.clinic_id;

      this.getVitalsInfo(patientId, appointmentId);
      this.getPatientRelations(userData.patient_id);
      this.handleSelectAll();
    } else {
      window.location.href = "/";
    }
  };

  /* get the relations */
  getPatientRelations = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtives/" + patientId,
      {}
    )
      .then((response) => {
        this.state.patientName = "Select Patient";
        let id = "0";
        let pName = "";
        let relationName = " (Self)";
        let relationShipId = "0";
        let image = "../images/patient/img/Profile/Male_patient.svg";
        let my_relatives = "";
        let stateRelationsOptions = [];
        let selectedImage = "";
        let selectedPatient = "";
        let dataCount = Object.keys(response.data.data).length;
        for (let rel = 0; rel < dataCount; rel++) {
          if (response.data.data[rel].id != "") {
            id = response.data.data[rel].id;
          }
          if (response.data.data[rel].relation_patient_id != null) {
            relationShipId = response.data.data[rel].relation_patient_id;
          }
          if (response.data.data[rel].name != null) {
            pName = response.data.data[rel].name;
          }
          if (response.data.data[rel].master_relationship != null) {
            relationName =
              "(" +
              response.data.data[rel].master_relationship.relation_name +
              ")";
          }
          if (response.data.data[rel].profile_pic != null) {
            image = Constant.imgurl + response.data.data[rel].profile_pic;
          } else {
            if (response.data.data[rel].gender.toLowerCase() == "male") {
              image = "../images/patient/img/Profile/Male_patient.svg";
            } else {
              image = "../images/patient/img/Profile/Female_patient.svg";
            }
          }
          if (patientId == id) {
            selectedImage = image;
            selectedPatient = pName + relationName;
          }

          my_relatives = {
            key: relationShipId,
            text: pName + relationName,
            value: relationShipId,
            image: { avatar: true, src: image },
          };
          stateRelationsOptions.push(my_relatives);
        }
        this.state.stateRelationsOptions = stateRelationsOptions;
        var patient_id = ls.get("SelectedPatientIdEnc");
        if (patient_id != null) {
          this.setState({
            defaultValue: patient_id,
          });
        } else {
          this.state.selectedRelativeValue = stateRelationsOptions[0].value;
          this.setState({
            defaultValue: stateRelationsOptions[0].value,
          });
        }
        $(".purpose_select .divider").html("");
        $(".purpose_select .divider").html(
          '<img src="' +
            selectedImage +
            '" class="ui avatar image"><span class="text">' +
            selectedPatient +
            "</span>"
        );
        this.forceUpdate();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For handling the togle for vitals
  handleToggle = () => {
    this.setState({ checked: !this.state.checked });
  };

  // Update the text for values
  updateRations = () => {
    const {
      isDisabled,
      disabledRequired,
      isParentChecked,
      fields,
      tempDisplayText,
    } = this.state;
    let patient_id = "";
    let systolic = this.state.fields.systolic;
    if (systolic >= 90 && systolic <= 199) {
      this.state.systolicColor = "green_hr";
      this.state.systolicDisplayText = "Normal";
    } else if (systolic >= 120 && systolic <= 139) {
      this.state.systolicColor = "yellow_hr";
      this.state.systolicDisplayText = "high";
    } else if (systolic >= 140) {
      this.state.systolicColor = "red_hr";
      this.state.systolicDisplayText = "Very high";
    }
    let systolicdisplayStatus = this.state.systolicDisplayText;
    // Ends

    // Diastolic Starts here
    let diastolic = this.state.fields.diastolic;
    if (diastolic >= 0 && diastolic <= 59) {
      this.state.diastolicColor = "green_hr";
      this.state.diastolicDisplayText = "Normal";
    } else if (diastolic >= 60 && diastolic <= 80) {
      this.state.diastolicColor = "yellow_hr";
      this.state.diastolicDisplayText = "high";
    } else if (diastolic >= 81 && diastolic <= 89) {
      this.state.diastolicColor = "red_hr";
      this.state.diastolicDisplayText = "Very high";
    } else if (diastolic >= 90) {
      this.state.diastolicColor = "red_hr";
      this.state.diastolicDisplayText = "Very high";
    }
    let diastolicdisplayStatus = this.state.diastolicDisplayText;
    // Ends

    // Bloodsugar Starts here
    let bloodsugar = this.state.fields.bloodsugar;
    if (bloodsugar >= 0 && bloodsugar < 140) {
      this.state.bloodsugarColor = "green_hr";
      this.state.bloodsugarDisplayText = "Normal";
    } else if (bloodsugar >= 140 && bloodsugar < 199) {
      this.state.bloodsugarColor = "yellow_hr";
      this.state.bloodsugarDisplayText = "high";
    } else if (bloodsugar >= 199 && bloodsugar < 800) {
      this.state.bloodsugarColor = "red_hr";
      this.state.bloodsugarDisplayText = "Very high";
    }

    let bloodsugardisplayStatus = this.state.bloodsugarDisplayText;
    // Ends

    // Respiratory Starts here
    let respiratory = this.state.fields.respiratoryrate;
    if (respiratory >= 0 && respiratory <= 14) {
      this.state.respiratoryColor = "red_hr";
      this.state.respiratoryDisplayText = "Low";
    } else if (respiratory > 15 && respiratory <= 20) {
      this.state.respiratoryColor = "green_hr";
      this.state.respiratoryDisplayText = "Normal";
    } else if (respiratory > 21 && respiratory <= 25) {
      this.state.respiratoryColor = "yellow_hr";
      this.state.respiratoryDisplayText = "Borderline";
    } else if (respiratory > 26) {
      this.state.respiratoryColor = "red_hr";
      this.state.respiratoryDisplayText = "High";
    }
    let respiratorydisplayStatus = this.state.respiratoryDisplayText;
    // Ends

    // Pulse Starts here
    let pulse = this.state.fields.pulse;
    if (pulse > 0 && pulse <= 59) {
      this.state.pulseColor = "red_hr";
      this.state.pulseDisplayText = "Low";
    } else if (pulse > 60 && pulse <= 80) {
      this.state.pulseColor = "green_hr";
      this.state.pulseDisplayText = "Normal";
    } else if (pulse >= 81 && pulse <= 90) {
      this.state.pulseColor = "yellow_hr";
      this.state.pulseDisplayText = "Borderline";
    } else if (pulse >= 91 && pulse >= 150) {
      this.state.pulseColor = "red_hr";
      this.state.pulseDisplayText = "High";
    }

    let pulsedisplayStatus = this.state.pulseDisplayText;
    // Ends

    // BMI Starts
    const ftHeight = this.state.fields.ftHeight;
    const inHeight = this.state.fields.inHeight;
    const weight = this.state.fields.weight;

    if (ftHeight != "" && inHeight != "" && weight != "") {
      let hightFeetConvert = ftHeight * 12;
      let hightIncehsConvert =
        parseFloat(hightFeetConvert) + parseFloat(inHeight);
      let heightCms = hightIncehsConvert * 2.54;
      let bmi = (weight / (((heightCms / 100) * heightCms) / 100)).toFixed(2);

      this.state.fields.bmi = bmi;
    } else {
      this.state.fields.bmi = "0";
    }

    let bmi = this.state.fields.bmi;
    if (bmi < 18.49) {
      this.state.bmiColor = "red_hr";
      this.state.bmiDisplayText = "Low";
    } else if (18.5 <= bmi && bmi <= 24.99) {
      this.state.bmiColor = "green_hr";
      this.state.bmiDisplayText = "Normal";
    } else if (25 <= bmi && bmi <= 29.99) {
      this.state.bmiColor = "yellow_hr";
      this.state.bmiDisplayText = "Borderline";
    } else {
      this.state.bmiColor = "red_hr";
      this.state.bmiDisplayText = "High";
    }
    let bmidisplayStatus = this.state.bmiDisplayText;
    // BMI Ends

    // Temparature Starts here
    let temparature = this.state.fields.temparature;

    if (temparature > 0 && temparature <= 96.99) {
      this.state.tempColor = "red_hr";
      this.state.tempDisplayText = "Low";
    } else if (temparature >= 97 && temparature <= 99) {
      this.state.tempColor = "green_hr";
      this.state.tempDisplayText = "Normal";
    } else if (temparature >= 99.1 && temparature <= 100.99) {
      this.state.tempColor = "yellow_hr";
      this.state.tempDisplayText = "Borderline";
    } else if (temparature > 101) {
      this.state.tempColor = "red_hr";
      this.state.tempDisplayText = "High";
    }
    let tempdisplayStatus = this.state.tempDisplayText;
    // Temparature Ends
    this.forceUpdate();
  };

  // To get all the ResponderInfo
  getVitalsInfo(patientId, appointmentId) {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/VitalInformation/getVitals/" +
        patientId +
        "/" +
        appointmentId +
        "/" +
        this.state.clinicId
    )
      .then((response) => {
        if (response.status == "200") {
          let previousPage = response.data.previouspage.web_reference_page_name;
          let nextPage = response.data.nextpage.web_reference_page_name;
          let responseLog = response;
          this.state.previousPage = previousPage;
          this.state.nextPage = nextPage;

          let height = response.data.data[0].height;

          var realFeet = (parseFloat(height) * 0.3937) / 12;
          var feet = Math.floor(realFeet);
          var inches = Math.round((realFeet - feet) * 12);

          if (height != "0" && !height.isNaN == true) {
            this.state.isHeightDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_height]").prop("checked", true);
          }
          if (responseLog.data.data[0].weight != 0) {
            this.state.isWeightDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_weight]").prop("checked", true);
          }
          if (responseLog.data.data[0].temperature != 0) {
            this.state.isTempDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_temparature]").prop("checked", true);
          }
          if (responseLog.data.data[0].pulse_rate != 0) {
            this.state.isPulseDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_pulse]").prop("checked", true);
          }
          if (responseLog.data.data[0].blood_sugar != 0) {
            this.state.isBloodSugarDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_bloodsugar]").prop("checked", true);
          }
          if (responseLog.data.data[0].respiratory_rate != 0) {
            this.state.isRespiratoryDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_respiratoryrate]").prop("checked", true);
          }
          if (responseLog.data.data[0].blood_pressure_systolic != 0) {
            this.state.isBPDisabled = false;
            this.state.checked = "checked";
            $("input[name=skip_systolic]").prop("checked", true);
          }
          if (responseLog.data.data[0].blood_pressure_diastolic != 0) {
            this.state.isBPDisabled = false;
            this.state.checked = "checked";
            $(".green_check").prop("checked", true);
            $("input[name=skip_systolic]").prop("checked", true);
          }

          this.setState({
            fields: {
              height: responseLog.data.data[0].height,
              ftHeight: feet,
              inHeight: inches,
              skip_height: responseLog.data.data[0].skip_height,
              skip_weight: responseLog.data.data[0].skip_weight,
              skip_temparature: responseLog.data.data[0].skip_temparature,
              skip_pulse: responseLog.data.data[0].skip_pulse,
              skip_respiratoryrate:
                responseLog.data.data[0].skip_respiratoryrate,
              skip_systolic: responseLog.data.data[0].skip_blood_pressure,
              skip_bloodsugar: responseLog.data.data[0].skip_bloodsugar,
              weight: responseLog.data.data[0].weight,
              bmi: responseLog.data.data[0].bmi,
              temparature: responseLog.data.data[0].temperature,
              pulse: responseLog.data.data[0].pulse_rate,
              bloodsugar: responseLog.data.data[0].blood_sugar,
              respiratoryrate: responseLog.data.data[0].respiratory_rate,
              systolic: responseLog.data.data[0].blood_pressure_systolic,
              diastolic: responseLog.data.data[0].blood_pressure_diastolic,
            },
          });
          this.forceUpdate();
        }
      })
      .catch((error) => {});
    // Ends here

    const ftHeight = this.state.fields.ftHeight;
    const inHeight = this.state.fields.inHeight;
    let feetInchesMerge = ftHeight + "." + inHeight;
    let height = feetInchesMerge / 0.032808;
    this.state.fields.height = height.toFixed(2);
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    let fieldName = event.target.name;

    if (
      fieldName == "skip_height" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      this.state.fields.skip_height = 0;
      this.state.isHeightDisabled = true;
    } else if (
      fieldName == "skip_height" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_height = 0;
      this.state.isHeightDisabled = false;
    }

    if (fieldName == "ftHeight") {
      if (!Number(fields[field])) {
        if (fields[field] != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.ftHeight = "0";
        } else {
          if (fields[field] == "") {
          } else {
            if (fields[field] > 7) {
              toast.error("Please enter valid Height");
              $("input[name=" + fieldName + "]").val("");
              this.state.fields.ftHeight = "0";
            } else {
              this.state.fields.ftHeight = fields[field];
            }
          }
        }
      } else {
        if (fields[field] == "") {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.ftHeight = "0";
        } else {
          if (fields[field] > 7) {
            toast.error("Please enter valid Height");
            $("input[name=" + fieldName + "]").val("");
            this.state.fields.ftHeight = "0";
          } else {
            this.state.fields.ftHeight = fields[field];
          }
        }
      }
    }
    if (fieldName == "inHeight") {
      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.inHeight = "0";
        } else {
          if (fields[field] == "") {
            $("input[name=" + fieldName + "]").val("");
          } else {
            this.state.fields.inHeight = fields[field];
          }
        }
      } else {
        if (event.target.value <= 12) {
          this.state.fields.inHeight = event.target.value;
        } else {
          toast.error("Please enter valid Intches");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.inHeight = "0";
        }
      }
    }

    if (
      fieldName == "skip_weight" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      this.state.fields.skip_weight = 0;
      this.state.isWeightDisabled = true;
    } else if (
      fieldName == "skip_weight" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_weight = 1;
      this.state.isWeightDisabled = false;
    }
    if (fieldName == "weight") {
      if (Number(event.target.value) >= 0 && Number(event.target.value) < 90) {
        $("#weightTextColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
      } else if (
        Number(event.target.value) <= 90 &&
        Number(event.target.value) <= 110
      ) {
        $("#weightTextColor.green_num_txt").attr(
          "style",
          "color: green !important"
        );
      } else if (
        Number(event.target.value) > 110 &&
        Number(event.target.value) < 140
      ) {
        $("#weightTextColor.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );
      } else if (Number(event.target.value) >= 140) {
        $("#weightTextColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
      }

      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.weight = "0";
        } else {
          this.state.fields.weight = event.target.value;
        }
      } else {
        if (event.target.value > 150) {
          toast.error("Please enter valid Weight");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.weight = "0";
          this.updateRations();
          return;
        } else {
          this.state.fields.weight = event.target.value;
        }
      }
    }

    if (
      fieldName == "skip_temparature" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      this.state.fields.skip_temparature = 0;
      this.state.isTempDisabled = true;
    } else if (
      fieldName == "skip_temparature" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_temparature = 1;
      this.state.isTempDisabled = false;
    }
    if (fieldName == "temparature") {
      if (
        Number(event.target.value) >= 0 &&
        Number(event.target.value) <= 96.99
      ) {
        $("#tempTextColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.tempColor = "red_hr";
        this.state.tempDisplayText = "Normal";
      } else if (
        Number(event.target.value) >= 97 &&
        Number(event.target.value) <= 99
      ) {
        $("#tempTextColor.green_num_txt").attr(
          "style",
          "color: green !important"
        );

        this.state.tempColor = "green_hr";
        this.state.tempDisplayText = "high";
      } else if (
        Number(event.target.value) >= 99.1 &&
        Number(event.target.value) <= 100.99
      ) {
        $("#tempTextColor.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );

        this.state.tempColor = "yellow_hr";
        this.state.tempDisplayText = "Borderline";
      } else if (Number(event.target.value) >= 101) {
        $("#tempTextColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.tempColor = "red_hr";
        this.state.tempDisplayText = "High";
      }
      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.temparature = "0";
        } else {
          this.state.fields.temperature = event.target.value;
        }
      } else {
        if (event.target.value > 110) {
          toast.error("Please enter valid Temperature");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.temparature = "0";
          this.updateRations();
          return;
        } else {
          this.state.fields.temperature = event.target.value;
        }
      }
    }
    if (
      fieldName == "skip_pulse" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      this.state.fields.skip_pulse = 0;
      this.state.isPulseDisabled = true;
    } else if (
      fieldName == "skip_pulse" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_pulse = 1;
      this.state.isPulseDisabled = false;
    }

    if (fieldName == "pulse") {
      if (
        Number(event.target.value) >= 40 &&
        Number(event.target.value) <= 59
      ) {
        $("#pulseTextColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.pulseColor = "red_hr";
        this.state.pulseDisplayText = "Low";
      } else if (
        Number(event.target.value) >= 60 &&
        Number(event.target.value) <= 80
      ) {
        $("#pulseTextColor.green_num_txt").attr(
          "style",
          "color: green !important"
        );
        this.state.pulseColor = "green_hr";
        this.state.pulseDisplayText = "Normal";
      } else if (
        Number(event.target.value) > 80 &&
        Number(event.target.value) <= 90
      ) {
        $("#pulseTextColor.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );
        this.state.pulseColor = "yellow_hr";
        this.state.pulseDisplayText = "high";
      } else if (Number(event.target.value) >= 91) {
        $("#pulseTextColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.pulseColor = "red_hr";
        this.state.pulseDisplayText = "Very high";
      }
      if (event.target.value > 150) {
        toast.error("Pulse should be between 40-150");
        $("input[name=" + fieldName + "]").val("");
        this.state.fields.pulse = 0;
        this.updateRations();
        return;
      } else {
        this.state.fields.pulse = event.target.value;
      }
    }

    if (
      fieldName == "skip_bloodsugar" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      //skip_bloodsugar

      this.state.fields.skip_bloodsugar = 0;
      this.state.fields.bloodsugar = 0;
      this.state.isBloodSugarDisabled = true;

      this.forceUpdate();
    } else if (
      fieldName == "skip_bloodsugar" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_bloodsugar = 1;
      this.state.isBloodSugarDisabled = false;
    }
    if (fieldName == "bloodsugar") {
      if (Number(event.target.value) <= 0 && Number(event.target.value) <= 89) {
        $("#bloodSugTxtCol.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.bloodsugarColor = "red_hr";
        this.state.bloodsugarDisplayText = "Low";
      } else if (
        Number(event.target.value) >= 90 &&
        Number(event.target.value) <= 140
      ) {
        $("#bloodSugTxtCol.green_num_txt").attr(
          "style",
          "color: green !important"
        );
        this.state.bloodsugarColor = "green_hr";
        this.state.bloodsugarDisplayText = "Normal";
      } else if (
        Number(event.target.value) >= 141 &&
        Number(event.target.value) <= 199
      ) {
        $("#bloodSugTxtCol.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );
        this.state.bloodsugarColor = "yellow_hr";
        this.state.bloodsugarDisplayText = "Borderline";
      } else if (Number(event.target.value) >= 200) {
        $("#bloodSugTxtCol.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.bloodsugarColor = "red_hr";
        this.state.bloodsugarDisplayText = "High";
      }
      this.state.bloodsugar = event.target.value;

      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.bloodsugar = "0";
        } else {
          this.state.fields.bloodsugar = event.target.value;
        }
      } else {
        if (event.target.value > 800) {
          toast.error("Please enter valid Blood Sugar");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.bloodsugar = 0;
          this.updateRations();
          return;
        } else {
          this.state.fields.bloodsugar = event.target.value;
        }
      }
    }

    if (
      fieldName == "skip_respiratoryrate" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      this.state.fields.skip_respiratoryrate = 0;
      this.state.isRespiratoryDisabled = true;
    } else if (
      fieldName == "skip_respiratoryrate" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_respiratoryrate = 1;
      this.state.isRespiratoryDisabled = false;
    }
    if (fieldName == "respiratoryrate") {
      if (Number(event.target.value) <= 0 && Number(event.target.value) <= 14) {
        $("#respiratoryrate.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.respiratoryColor = "red_hr";
        this.state.respiratoryDisplayText = "Low";
      } else if (
        Number(event.target.value) >= 15 &&
        Number(event.target.value) <= 20
      ) {
        $("#respiratoryrate.green_num_txt").attr(
          "style",
          "color: green !important"
        );
        this.state.respiratoryColor = "green_hr";
        this.state.respiratoryDisplayText = "Normal";
      } else if (
        Number(event.target.value) >= 21 &&
        Number(event.target.value) <= 25
      ) {
        $("#respiratoryrate.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );
        this.state.respiratoryColor = "yellow_hr";
        this.state.respiratoryDisplayText = "Borderline";
      } else if (Number(event.target.value) >= 26) {
        $("#respiratoryrate.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.respiratoryColor = "red_hr";
        this.state.respiratoryDisplayText = "High";
      }
      this.state.respiratoryrate = event.target.value;
      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.respiratoryrate = "0";
        } else {
          this.state.fields.respiratoryrate = event.target.value;
        }
      } else {
        if (event.target.value > 40) {
          toast.error("Please enter valid Resporatory");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.respiratoryrate = "0";
          this.updateRations();
          return;
        } else {
          this.state.fields.respiratoryrate = event.target.value;
        }
      }
    }

    if (
      fieldName == "skip_systolic" &&
      $("input[name='" + fieldName + "']").is(":checked") == false
    ) {
      this.state.fields.skip_systolic = 0;
      this.state.isBPDisabled = true;
    } else if (
      fieldName == "skip_systolic" &&
      $("input[name='" + fieldName + "']").is(":checked") == true
    ) {
      this.state.fields.skip_systolic = 1;
      this.state.isBPDisabled = false;
    }

    if (fieldName == "systolic") {
      if (Number(event.target.value) <= 0 && Number(event.target.value) <= 89) {
        $("#systolicTxtColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.systolicColor = "red_hr";
        this.state.systolicDisplayText = "Low";
      } else if (
        Number(event.target.value) >= 90 &&
        Number(event.target.value) <= 120
      ) {
        $("#systolicTxtColor.green_num_txt").attr(
          "style",
          "color: green !important"
        );
        this.state.systolicColor = "green_hr";
        this.state.systolicDisplayText = "Normal";
      } else if (
        Number(event.target.value) >= 121 &&
        Number(event.target.value) <= 139
      ) {
        $("#systolicTxtColor.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );
        this.state.systolicColor = "yellow_hr";
        this.state.systolicDisplayText = "Borderline";
      } else if (Number(event.target.value) >= 140) {
        $("#systolicTxtColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.systolicColor = "red_hr";
        this.state.systolicDisplayText = "High";
      }
      this.state.systolic = event.target.value;
      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.systolic = "0";
        } else {
          this.state.fields.systolic = event.target.value;
        }
      } else {
        if (event.target.value > 200) {
          toast.error("Please enter valid systolic");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.systolic = "0";
          this.updateRations();
          return;
        } else {
          this.state.fields.systolic = event.target.value;
        }
      }
    }
    if (fieldName == "diastolic") {
      if (Number(event.target.value) <= 0 && Number(event.target.value) <= 59) {
        $("#diastolicTxtColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.diastolicColor = "red_hr";
        this.state.diastolicDisplayText = "Low";
      } else if (
        Number(event.target.value) >= 60 &&
        Number(event.target.value) <= 80
      ) {
        $("#diastolicTxtColor.green_num_txt").attr(
          "style",
          "color: green !important"
        );
        this.state.diastolicColor = "green_hr";
        this.state.diastolicDisplayText = "Normal";
      } else if (
        Number(event.target.value) >= 81 &&
        Number(event.target.value) <= 89
      ) {
        $("#diastolicTxtColor.green_num_txt").attr(
          "style",
          "color: yellow !important"
        );
        this.state.diastolicColor = "yellow_hr";
        this.state.diastolicDisplayText = "Borderline";
      } else if (Number(event.target.value) >= 90) {
        $("#diastolicTxtColor.green_num_txt").attr(
          "style",
          "color: #FD5D5D !important"
        );
        this.state.diastolicColor = "red_hr";
        this.state.diastolicDisplayText = "High";
      }
      this.state.diastolic = event.target.value;
      if (!Number(event.target.value)) {
        if (event.target.value != 0) {
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.diastolic = "0";
        } else {
          this.state.fields.diastolic = event.target.value;
        }
      } else {
        if (event.target.value > 150) {
          toast.error("Please enter valid diastolic");
          $("input[name=" + fieldName + "]").val("");
          this.state.fields.diastolic = "0";
          this.updateRations();
          return;
        } else {
          this.state.fields.diastolic = event.target.value;
        }
      }
    }

    const ftHeight = this.state.fields.ftHeight;
    const inHeight = this.state.fields.inHeight;
    const weight = this.state.fields.weight;
    if (ftHeight != "" && inHeight != "" && weight != "") {
      let hightFeetConvert = ftHeight * 12;

      let hightIncehsConvert =
        parseFloat(hightFeetConvert) + parseFloat(inHeight);

      let heightCms = hightIncehsConvert * 2.54;

      let bmi = (weight / (((heightCms / 100) * heightCms) / 100)).toFixed(2);

      if (isNaN(bmi) == true) {
        this.state.fields.bmi = "0";
      } else {
        this.state.fields.bmi = bmi;
      }
      this.forceUpdate();
    } else {
      this.state.fields.bmi = "0";
    }
    let checked = 0;
    var checkedVals = $(".blue_check:checkbox:checked")
      .map(function () {
        if (this.value == 0) {
          checked = checked + 1;
        }
      })
      .get();
    if (checked == 0) {
      $(".green_check").prop("checked", false);
    }
    if (checked >= 6) {
      $(".green_check").prop("checked", true);
    }
    this.setState({ fields });
    this.forceUpdate();
  };

  //  For selecting all
  handleSelectAll = (e) => {
    let checkboxLength = $(".blue_check").length;
    let checkboxCheckedLength = $(".blue_check:checked").length;
    if ($(".green_check").is(":checked")) {
      if (checkboxLength === checkboxCheckedLength) {
        $(".blue_check").prop("checked", true);
        $(".green_check").prop("checked", true);
      } else {
        $(".blue_check").prop("checked", true);
        $(".green_check").prop("checked", true);

        this.state.isHeightDisabled = false;
        this.state.isWeightDisabled = false;
        this.state.isBMIDisabled = false;
        this.state.isTempDisabled = false;
        this.state.isPulseDisabled = false;
        this.state.isBloodSugarDisabled = false;
        this.state.isRespiratoryDisabled = false;
        this.state.isBPDisabled = false;
        this.state.isPulseDisabled = false;

        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: 0,
            skip_weight: 0,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: 0,
            pulse: this.state.fields.pulse,
            bloodsugar: this.state.fields.bloodsugar,
            skip_pulse: 0,
            skip_bloodsugar: 0,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.respiratory_rate,
            skip_systolic: 0,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
        this.forceUpdate();
      }
    } else {
      $(".blue_check").prop("checked", false);
      $(".green_check").prop("checked", false);

      this.state.isHeightDisabled = true;
      this.state.isWeightDisabled = true;
      this.state.isBMIDisabled = true;
      this.state.isTempDisabled = true;
      this.state.isPulseDisabled = true;
      this.state.isBloodSugarDisabled = true;
      this.state.isRespiratoryDisabled = true;
      this.state.isBPDisabled = true;
      this.state.isPulseDisabled = true;

      this.setState({
        fields: {
          height: this.state.fields.height,
          roundHeight: Math.round(this.state.fields.height),
          ftHeight: this.state.fields.ftHeight,
          inHeight: this.state.fields.inHeight,
          weight: this.state.fields.weight,
          skip_height: 0,
          skip_weight: 0,
          bmi: this.state.fields.bmi,
          temparature: this.state.fields.temparature,
          skip_temparature: 0,
          pulse: this.state.fields.pulse,
          bloodsugar: this.state.fields.bloodsugar,
          skip_pulse: 0,
          skip_bloodsugar: 0,
          respiratoryrate: 0,
          skip_respiratoryrate: 0,
          skip_systolic: 0,
          systolic: this.state.fields.systolic,
          diastolic: this.state.fields.diastolic,
        },
      });
      this.forceUpdate();
    }
  };

  //  For language change
  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };
  //  For back to previous page
  goback = (event) => {
    window.location.href = "./" + event.currentTarget.id;
  };

  render() {
    const {
      isDisabled,
      disabledRequired,
      isParentChecked,
      fields,
      tempDisplayText,
    } = this.state;
    let patient_id = "";

    let systolic = this.state.fields.systolic;
    if (systolic <= 89) {
      $("#systolicTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.systolicColor = "red_hr";
      this.state.systolicDisplayText = "Low";
    } else if (systolic >= 90 && systolic <= 119) {
      $("#systolicTxtColor.green_num_txt").attr(
        "style",
        "color: green !important"
      );
      this.state.systolicColor = "green_hr";
      this.state.systolicDisplayText = "Normal";
    } else if (systolic >= 120 && systolic <= 129) {
      $("#systolicTxtColor.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );
      this.state.systolicColor = "yellow_hr";
      this.state.systolicDisplayText = "Borderline";
    } else if (systolic >= 130 && systolic <= 139) {
      $("#systolicTxtColor.green_num_txt").attr(
        "style",
        "color: #ffa500  !important"
      );
      this.state.systolicColor = "orange_hr";
      this.state.systolicDisplayText = "High";
    } else if (systolic >= 140 && systolic <= 180) {
      //new requirement (as per sheet)
      $("#systolicTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.systolicColor = "red_hr";
      this.state.systolicDisplayText = "Hypertension";
    } else if (systolic >= 181) {
      //new requirement (as per sheet)
      $("#systolicTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.systolicColor = "red_hr";
      this.state.systolicDisplayText = "Hypertensive Crisis";
    }
    let systolicdisplayStatus = this.state.systolicDisplayText;
    // Ends

    let diastolic = this.state.fields.diastolic;
    if (diastolic <= 59) {
      $("#diastolicTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.diastolicColor = "red_hr";
      this.state.diastolicDisplayText = "Low";
    } else if (diastolic >= 60 && diastolic <= 79) {
      $("#diastolicTxtColor.green_num_txt").attr(
        "style",
        "color: green !important"
      );
      this.state.diastolicColor = "green_hr";
      this.state.diastolicDisplayText = "Normal";
    } else if (diastolic >= 60 && diastolic <= 79) {
      $("#diastolicTxtColor.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );
      this.state.diastolicColor = "yellow_hr";
      this.state.diastolicDisplayText = "Borderline";
    } else if (diastolic >= 80 && diastolic <= 89) {
      $("#diastolicTxtColor.green_num_txt").attr(
        "style",
        "color: #ffa500  !important"
      );
      this.state.diastolicColor = "orange_hr";
      this.state.diastolicDisplayText = "High";
    } else if (diastolic >= 90 && diastolic <= 120) {
      // new requirement(as per sheet)
      $("#diastolicTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.diastolicColor = "red_hr";
      this.state.diastolicDisplayText = "Hypertension";
    } else if (diastolic >= 121) {
      $("#diastolicTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.diastolicColor = "red_hr";
      this.state.diastolicDisplayText = "Hypertensive Crisis";
    }
    let diastolicdisplayStatus = this.state.diastolicDisplayText;
    // Ends

    let bloodsugar = this.state.fields.bloodsugar;
    if (bloodsugar <= 0 && bloodsugar <= 89) {
      $("#bloodSugTxtCol.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.bloodsugarColor = "red_hr";
      this.state.bloodsugarDisplayText = "Low";
    } else if (bloodsugar >= 90 && bloodsugar <= 140) {
      $("#bloodSugTxtCol.green_num_txt").attr(
        "style",
        "color: green !important"
      );
      this.state.bloodsugarColor = "green_hr";
      this.state.bloodsugarDisplayText = "Normal";
    } else if (bloodsugar >= 141 && bloodsugar <= 199) {
      $("#bloodSugTxtCol.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );
      this.state.bloodsugarColor = "yellow_hr";
      this.state.bloodsugarDisplayText = "Borderline";
    } else if (bloodsugar >= 200) {
      $("#bloodSugTxtCol.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.bloodsugarColor = "red_hr";
      this.state.bloodsugarDisplayText = "High";
    }
    let bloodsugardisplayStatus = this.state.bloodsugarDisplayText;
    // Ends

    let respiratory = this.state.fields.respiratoryrate;
    if (respiratory >= 0 && respiratory <= 14) {
      $("#respiratoryrate.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.respiratoryColor = "red_hr";
      this.state.respiratoryDisplayText = "Low";
    } else if (respiratory >= 15 && respiratory <= 20) {
      $("#respiratoryrate.green_num_txt").attr(
        "style",
        "color: green !important"
      );
      this.state.respiratoryColor = "green_hr";
      this.state.respiratoryDisplayText = "Normal";
    } else if (respiratory >= 21 && respiratory <= 25) {
      $("#respiratoryrate.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );
      this.state.respiratoryColor = "yellow_hr";
      this.state.respiratoryDisplayText = "Borderline";
    } else if (respiratory >= 26) {
      $("#respiratoryrate.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.respiratoryColor = "red_hr";
      this.state.respiratoryDisplayText = "High";
    }
    let respiratorydisplayStatus = this.state.respiratoryDisplayText;
    // Ends

    let pulse = this.state.fields.pulse;

    if (pulse >= 0 && pulse <= 59) {
      $("#pulseTextColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.pulseColor = "red_hr";
      this.state.pulseDisplayText = "Low";
    } else if (pulse >= 60 && pulse <= 80) {
      $("#pulseTextColor.green_num_txt").attr(
        "style",
        "color: green !important"
      );
      this.state.pulseColor = "green_hr";
      this.state.pulseDisplayText = "Normal";
    } else if (pulse >= 81 && pulse <= 90) {
      $("#pulseTextColor.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );
      this.state.pulseColor = "yellow_hr";
      this.state.pulseDisplayText = "Borderline";
    } else if (pulse >= 91) {
      $("#pulseTextColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.pulseColor = "red_hr";
      this.state.pulseDisplayText = "high";
    }

    let pulsedisplayStatus = this.state.pulseDisplayText;
    // Ends

    // BMI Starts
    const ftHeight = this.state.fields.ftHeight;
    const inHeight = this.state.fields.inHeight;
    const weight = this.state.fields.weight;

    if (ftHeight != "" && inHeight != "" && weight != "") {
      let hightFeetConvert = ftHeight * 12;
      let hightIncehsConvert =
        parseFloat(hightFeetConvert) + parseFloat(inHeight);
      let heightCms = hightIncehsConvert * 2.54;
      let bmi = (weight / (((heightCms / 100) * heightCms) / 100)).toFixed(2);

      if (isNaN(bmi) == true) {
        this.state.fields.bmi = "0";
      } else {
        this.state.fields.bmi = bmi;
      }
    } else {
      this.state.fields.bmi = "0";
    }

    let bmi = this.state.fields.bmi;
    if (bmi < 18.5) {
      $("#bmiTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.bmiColor = "red_hr";
      this.state.bmiDisplayText = "Low";
    } else if (18.5 <= bmi && bmi <= 24.99) {
      $("#bmiTxtColor.green_num_txt").attr("style", "color: green !important");
      this.state.bmiColor = "green_hr";
      this.state.bmiDisplayText = "Normal";
    } else if (25 <= bmi && bmi <= 29.99) {
      $("#bmiTxtColor.green_num_txt").attr("style", "color: yellow !important");
      this.state.bmiColor = "yellow_hr";
      this.state.bmiDisplayText = "Borderline";
    } else if (30 <= bmi && bmi <= 40) {
      $("#bmiTxtColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.bmiColor = "red_hr";
      this.state.bmiDisplayText = "High";
    }
    let bmidisplayStatus = this.state.bmiDisplayText;
    // BMI Ends

    //height start
    let height = this.state.fields.ftHeight;

    let inheight = this.state.fields.inHeight;

    //Weight start here
    let Weight = this.state.fields.weight;

    if (Weight >= 0 && Weight < 90) {
      $("#weightTextColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
    } else if (Weight <= 90 && Weight <= 110) {
      $("#weightTextColor.green_num_txt").attr(
        "style",
        "color: green !important"
      );
    } else if (Weight > 110 && Weight < 140) {
      $("#weightTextColor.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );
    } else if (Weight >= 140) {
      $("#weightTextColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
    }

    // Temparature Starts here
    let temparature = this.state.fields.temparature;

    if (temparature >= 0 && temparature <= 96.99) {
      $("#tempTextColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.tempColor = "red_hr";
      this.state.tempDisplayText = "Low";
    } else if (temparature >= 97 && temparature <= 99) {
      $("#tempTextColor.green_num_txt").attr(
        "style",
        "color: green !important"
      );

      this.state.tempColor = "green_hr";
      this.state.tempDisplayText = "Normal";
    } else if (temparature >= 99.1 && temparature <= 100.99) {
      $("#tempTextColor.green_num_txt").attr(
        "style",
        "color: yellow !important"
      );

      this.state.tempColor = "yellow_hr";
      this.state.tempDisplayText = "Borderline";
    } else if (temparature >= 101) {
      $("#tempTextColor.green_num_txt").attr(
        "style",
        "color: #FD5D5D !important"
      );
      this.state.tempColor = "red_hr";
      this.state.tempDisplayText = "High";
    }
    let tempdisplayStatus = this.state.tempDisplayText;
    // Temparature Ends

    const heightFeetSettings = {
      start: 0,
      min: 0,
      max: 7,
      step: 1,
      onChange: (feetvalue) => {
        const ftHeight = feetvalue;
        const inHeight = this.state.fields.inHeight;
        let feetInchesMerge = ftHeight + "." + inHeight;
        let height = feetInchesMerge / 0.032808;
        this.state.fields.height = height.toFixed(2);
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: feetvalue,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            bloodsugar: this.state.fields.bloodsugar,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    const heightInchSettings = {
      start: 0,
      min: 0,
      max: 12,
      step: 1,
      onChange: (inchvalue) => {
        const ftHeight = this.state.fields.ftHeight;
        const inHeight = inchvalue;
        let feetInchesMerge = ftHeight + "." + inHeight;
        let height = Number(ftHeight) * 30.48 + Number(inHeight) * 2.54;
        this.state.fields.height = height.toFixed(2);
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: inchvalue,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            bloodsugar: this.state.fields.bloodsugar,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
        this.forceUpdate();
      },
    };

    const weightSettings = {
      start: 0,
      min: 0,
      max: 150,
      step: 1,
      onChange: (weightvalue) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: weightvalue,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            bloodsugar: this.state.fields.bloodsugar,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    const systolicSettings = {
      start: 60,
      min: 0,
      max: 200,
      step: 1,
      onChange: (value) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            bloodsugar: this.state.fields.bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: value,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    const diastolicSettings = {
      start: 40,
      min: 0,
      max: 150,
      step: 1,
      onChange: (value) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            bloodsugar: this.state.fields.bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: value,
          },
        });
      },
    };

    const temparatureSettings = {
      start: 90,
      min: 0,
      max: 110,
      step: 1,
      onChange: (value) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: value,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            bloodsugar: this.state.fields.bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    const pulseSettings = {
      start: 40,
      min: 0,
      max: 150,
      step: 1,
      onChange: (value) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: value,
            bloodsugar: this.state.fields.bloodsugar,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    const bloodsugarSettings = {
      start: 40,
      min: 0,
      max: 800,
      step: 1,
      onChange: (value) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            bloodsugar: value,
            respiratoryrate: this.state.fields.respiratoryrate,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            skip_systolic: this.state.fields.skip_systolic,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    const respiratorySettings = {
      start: 10,
      min: 0,
      max: 40,
      step: 1,
      onChange: (value) => {
        this.setState({
          fields: {
            height: this.state.fields.height,
            roundHeight: Math.round(this.state.fields.height),
            ftHeight: this.state.fields.ftHeight,
            inHeight: this.state.fields.inHeight,
            weight: this.state.fields.weight,
            skip_height: this.state.fields.skip_height,
            skip_weight: this.state.fields.skip_weight,
            bmi: this.state.fields.bmi,
            temparature: this.state.fields.temparature,
            skip_temparature: this.state.fields.skip_temparature,
            pulse: this.state.fields.pulse,
            skip_pulse: this.state.fields.skip_pulse,
            skip_bloodsugar: this.state.fields.skip_bloodsugar,
            bloodsugar: this.state.fields.bloodsugar,
            respiratoryrate: value,
            skip_systolic: this.state.fields.skip_systolic,
            skip_respiratoryrate: this.state.fields.skip_respiratoryrate,
            systolic: this.state.fields.systolic,
            diastolic: this.state.fields.diastolic,
          },
        });
      },
    };

    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject != null) {
      let userData = retrievedObject;
      const patient_id = userData.patient_id;
    } else {
      window.location.href = "/";
    }

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="purpose">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="purpose_heading">
                    <h2>{translate("Intake Process")}</h2>
                  </div>
                </div>
                {/* <div className="col-md-6 text-right">
                  <div className="purpose_select">
                    <h4>{translate("Enter the details for")}...</h4>
                    <Dropdown
                      placeholder={this.state.patientName}
                      openOnFocus={false}
                      selection
                      options={this.state.stateRelationsOptions}
                      defaultValue={this.state.defaultValue}
                      disabled={true}
                    />
                  </div>
                </div> */}
              </div>
              <div className="purpose_consult">
                <div className="row">
                  <div className="col-md-4">
                    <div className="stepwizard">
                      <div className="stepwizard-row setup-panel">
                        <div className="stepwizard-step">
                          <a
                            href="#step-1"
                            type=""
                            className="btn  btn-green btn-circle"
                          >
                            <img src="../images/patient/img/Patient Intake Process/tickmark.svg" />
                          </a>
                          <p className="p_dark p_green">
                            {translate("Purpose of consultation")}
                          </p>
                        </div>
                        <div className="stepwizard-step">
                          <a
                            href="#step-2"
                            type=""
                            className="btn  btn-primary btn-circle"
                            disabled="disabled"
                          >
                            <img src="../images/patient/img/Patient Intake Process/tickmark.svg" />
                          </a>
                          <p className="p_dark p_green">
                            {translate("Specify Symptoms")}
                          </p>
                        </div>
                        <div className="stepwizard-step">
                          <a
                            href="#step-3"
                            type=""
                            className="btn btn-default btn-circle"
                          >
                            3
                          </a>
                          <p className="p_dark">
                            {translate("Provide Vital Informations")}
                          </p>
                        </div>
                        <div className="stepwizard-step">
                          <a
                            href="#step-4"
                            type=""
                            className="btn btn-default btn-circle light_circle"
                            disabled="disabled"
                          >
                            4
                          </a>
                          <p className="p_light">
                            {translate("History of Past Illness")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="purpose_box">
                      <div className="row pupose_l">
                        <div className="col-md-8 col-sm-8 col-8">
                          <h2 className="specify_head">
                            {translate("Enter your vital information")}{" "}
                            <span>({translate("Optional")})</span>
                          </h2>
                        </div>
                        <div className="col-md-4 col-sm-4 col-4 text-right toggle_all">
                          <p>{translate("All")}</p>
                          <label className="switch">
                            <input
                              id="main_check"
                              className="green_check"
                              type="checkbox"
                              onChange={this.handleSelectAll}
                              defaultChecked={this.state.checked}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <form onSubmit={this.onFormSubmit.bind(this)}>
                        <div className="row vital_info">
                          <div className="col-lg-12 col-md-12 col-sm-12 no_padding">
                            <div className="row">
                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Height")}</h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_height"
                                        className="blue_check"
                                        type="checkbox"
                                        value={
                                          this.state.fields["skip_height"] ||
                                          "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_height"
                                        )}
                                      />

                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      maxLength="2"
                                      name="ftHeight"
                                      id="heightTextColor"
                                      className="green_num_txt"
                                      value={
                                        this.state.fields["ftHeight"] || ""
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "ftHeight"
                                      )}
                                      onKeyPress={this.handleChange.bind(
                                        this,
                                        "ftHeight"
                                      )}
                                      disabled={this.state.isHeightDisabled}
                                    />

                                    {this.state.isHeightDisabled}

                                    <h6 className="info_txt">
                                      {translate("Feet")}{" "}
                                    </h6>
                                    <br />
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["ftHeight"]}
                                    </span>
                                  </div>
                                  <Slider
                                    value={this.state.fields["ftHeight"]}
                                    color="blue"
                                    settings={heightFeetSettings}
                                    disabled={this.state.isHeightDisabled}
                                  />
                                  <div className="vital_value">
                                    <input
                                      maxLength="2"
                                      name="inHeight"
                                      id="incTextColor"
                                      className="green_num_txt"
                                      value={
                                        this.state.fields["inHeight"] || ""
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "inHeight"
                                      )}
                                      disabled={this.state.isHeightDisabled}
                                    />

                                    <h6 className="info_txt">
                                      {translate("Inch")}{" "}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["inHeight"]}
                                    </span>
                                  </div>
                                  <Slider
                                    value={this.state.fields["inHeight"]}
                                    color="blue"
                                    settings={heightInchSettings}
                                    disabled={this.state.isHeightDisabled}
                                  />
                                  ({this.state.fields.height} cm)
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Weight")} </h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_weight"
                                        className="blue_check"
                                        type="checkbox"
                                        value={
                                          this.state.fields["skip_weight"] ||
                                          "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_weight"
                                        )}
                                      />
                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      maxLength="5"
                                      name="weight"
                                      className="green_num_txt"
                                      id="weightTextColor"
                                      value={this.state.fields["weight"] || ""}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "weight"
                                      )}
                                      disabled={this.state.isWeightDisabled}
                                    />

                                    <h6 className="info_txt">
                                      {translate("kg")}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["weight"]}
                                    </span>
                                  </div>
                                  <Slider
                                    value={this.state.fields["weight"]}
                                    color="blue"
                                    settings={weightSettings}
                                    disabled={this.state.isWeightDisabled}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("BMI")}</h5>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      className="green_num_txt"
                                      id="bmiTxtColor"
                                      value={
                                        isNaN(this.state.fields["bmi"] == true)
                                          ? ""
                                          : this.state.fields["bmi"] || " "
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "bmi"
                                      )}
                                      disabled={this.state.isBMIDisabled}
                                    />

                                    <h6 className="info_txt">
                                      {translate("Kg/m2")}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["bmi"]}
                                    </span>
                                  </div>
                                  <div className="vital_degree">
                                    <p className={this.state.bmiColor}></p>
                                    <h6 className="info_txt">
                                      {translate(bmidisplayStatus)}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Temperature")}</h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_temparature"
                                        className="blue_check"
                                        type="checkbox"
                                        value={
                                          this.state.fields[
                                            "skip_temparature"
                                          ] || "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_temparature"
                                        )}
                                        onClick={() => {
                                          this.setState({
                                            checked: !this.state.checked,
                                          });
                                        }}
                                      />
                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      maxLength={6}
                                      name="temparature"
                                      id="tempTextColor"
                                      className="green_num_txt"
                                      value={
                                        this.state.fields["temparature"] || ""
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "temparature"
                                      )}
                                      onKeyUp={this.handleChange.bind(
                                        this,
                                        "temparature"
                                      )}
                                      disabled={this.state.isTempDisabled}
                                    />

                                    <h6 className="info_txt">
                                      {translate("°F")}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["temparature"]}
                                    </span>
                                  </div>
                                  <div className="vital_degree">
                                    <p className={this.state.tempColor}></p>
                                    <h6 className="info_txt">
                                      {translate(tempDisplayText)}
                                    </h6>
                                  </div>
                                  <Slider
                                    value={this.state.fields["temparature"]}
                                    color="blue"
                                    settings={temparatureSettings}
                                    disabled={this.state.isTempDisabled}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Pulse")}</h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_pulse"
                                        className="blue_check"
                                        type="checkbox"
                                        value={
                                          this.state.fields["skip_pulse"] || "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_pulse"
                                        )}
                                        onClick={() => {
                                          this.setState({
                                            checked: !this.state.checked,
                                          });
                                        }}
                                      />
                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      maxLength={3}
                                      name="pulse"
                                      id="pulseTextColor"
                                      className="green_num_txt"
                                      value={this.state.fields["pulse"] || ""}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "pulse"
                                      )}
                                      disabled={this.state.isPulseDisabled}
                                    />

                                    <h6 className="info_txt">
                                      {translate("bpm")}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["pulse"]}
                                    </span>
                                  </div>
                                  <div className="vital_degree">
                                    <p className={this.state.pulseColor}></p>
                                    <h6 className="info_txt">
                                      {translate(pulsedisplayStatus)}
                                    </h6>
                                  </div>
                                  <Slider
                                    value={this.state.fields["pulse"]}
                                    color="blue"
                                    settings={pulseSettings}
                                    disabled={this.state.isPulseDisabled}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Blood Sugar")}</h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_bloodsugar"
                                        className="blue_check"
                                        type="checkbox"
                                        defaultValue={
                                          this.state.fields[
                                            "skip_bloodsugar"
                                          ] || "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_bloodsugar"
                                        )}
                                        onClick={() => {
                                          this.setState({
                                            checked: !this.state.checked,
                                          });
                                        }}
                                      />
                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      maxLength={3}
                                      name="bloodsugar"
                                      id="bloodSugTxtCol"
                                      className="green_num_txt"
                                      value={
                                        this.state.fields["bloodsugar"] || ""
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "bloodsugar"
                                      )}
                                      disabled={this.state.isBloodSugarDisabled}
                                    />

                                    <h6 className="info_txt">
                                      {translate("mg/dL")}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["bloodsugar"]}
                                    </span>
                                  </div>
                                  <div className="vital_degree">
                                    <p
                                      className={this.state.bloodsugarColor}
                                    ></p>
                                    <h6 className="info_txt">
                                      {translate(bloodsugardisplayStatus)}
                                    </h6>
                                  </div>

                                  <Slider
                                    value={this.state.fields["bloodsugar"]}
                                    color="blue"
                                    settings={bloodsugarSettings}
                                    disabled={this.state.isBloodSugarDisabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Respiratory Rate")}</h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_respiratoryrate"
                                        className="blue_check"
                                        type="checkbox"
                                        id="skip_respiratoryrate"
                                        value={
                                          this.state.fields[
                                            "skip_respiratoryrate"
                                          ] || "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_respiratoryrate"
                                        )}
                                        onClick={() => {
                                          this.setState({
                                            checked: !this.state.checked,
                                          });
                                        }}
                                      />
                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="vital_value">
                                    <input
                                      maxLength={3}
                                      name="respiratoryrate"
                                      className="green_num_txt"
                                      id="respiratoryrate"
                                      value={
                                        this.state.fields["respiratoryrate"] ||
                                        ""
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "respiratoryrate"
                                      )}
                                      disabled={
                                        this.state.isRespiratoryDisabled
                                      }
                                    />

                                    <h6 className="info_txt">
                                      {translate("Breath Per Minute")}
                                    </h6>
                                  </div>
                                  <div>
                                    <span className="cRed">
                                      {this.state.errors["respiratoryrate"]}
                                    </span>
                                  </div>
                                  <div className="vital_degree">
                                    <p
                                      className={this.state.respiratoryColor}
                                    ></p>
                                    <h6 className="info_txt">
                                      {translate(respiratorydisplayStatus)}
                                    </h6>
                                  </div>
                                  <Slider
                                    value={this.state.fields["respiratoryrate"]}
                                    color="blue"
                                    settings={respiratorySettings}
                                    disabled={this.state.isRespiratoryDisabled}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-12 col-sm-12 vital_space">
                                <div className="vital_info_box">
                                  <div>
                                    <h5>{translate("Blood Pressure")}</h5>
                                    <label className="switch1">
                                      <input
                                        name="skip_systolic"
                                        className="blue_check"
                                        type="checkbox"
                                        value={
                                          this.state.fields["skip_systolic"] ||
                                          "0"
                                        }
                                        onChange={this.handleChange.bind(
                                          this,
                                          "skip_systolic"
                                        )}
                                        onClick={() => {
                                          this.setState({
                                            checked: !this.state.checked,
                                          });
                                        }}
                                      />
                                      <span className="slider round_1"></span>
                                    </label>
                                  </div>
                                  <div className="row col-md-12">
                                    <div className="col-md-6 col-sm-6 no_padding">
                                      <div class="vital_bar">
                                        <div className="vital_value">
                                          <input
                                            maxLength={3}
                                            name="systolic"
                                            id="systolicTxtColor"
                                            className="green_num_txt"
                                            value={
                                              this.state.fields["systolic"] ||
                                              ""
                                            }
                                            onChange={this.handleChange.bind(
                                              this,
                                              "systolic"
                                            )}
                                            disabled={this.state.isBPDisabled}
                                          />

                                          <h6 className="info_txt">
                                            {translate("mmHg")}
                                          </h6>
                                        </div>
                                        <div>
                                          <span className="cRed">
                                            {this.state.errors["systolic"]}
                                          </span>
                                        </div>
                                        <h4 className="temp_txt">Systolic</h4>
                                        <div className="vital_degree">
                                          <p
                                            className={this.state.systolicColor}
                                          ></p>
                                          <h6 className="info_txt">
                                            {translate(systolicdisplayStatus)}
                                          </h6>
                                        </div>
                                        <Slider
                                          value={this.state.fields["systolic"]}
                                          color="blue"
                                          settings={systolicSettings}
                                          disabled={this.state.isBPDisabled}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 no_padding">
                                      <div class="vital_bar">
                                        <div className="vital_value">
                                          <input
                                            maxLength={3}
                                            name="diastolic"
                                            id="diastolicTxtColor"
                                            className="green_num_txt"
                                            value={
                                              this.state.fields["diastolic"] ||
                                              ""
                                            }
                                            onChange={this.handleChange.bind(
                                              this,
                                              "diastolic"
                                            )}
                                            onKeyUp={this.handleChange.bind(
                                              this,
                                              "diastolic"
                                            )}
                                            disabled={this.state.isBPDisabled}
                                          />

                                          <h6 className="info_txt">
                                            {translate("mmHg")}
                                          </h6>
                                        </div>
                                        <div>
                                          <span className="cRed">
                                            {this.state.errors["diastolic"]}
                                          </span>
                                        </div>
                                        <h4 className="temp_txt">Diastolic</h4>
                                        <div className="vital_degree">
                                          <p
                                            className={
                                              this.state.diastolicColor
                                            }
                                          ></p>
                                          <h6 className="info_txt">
                                            {translate(diastolicdisplayStatus)}
                                          </h6>
                                        </div>
                                        <Slider
                                          value={this.state.fields["diastolic"]}
                                          color="blue"
                                          settings={diastolicSettings}
                                          disabled={this.state.isBPDisabled}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ToastContainer />
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-xs-6 text-left">
                            <div
                              class="back_btn"
                              id={this.state.previousPage}
                              onClick={this.goback}
                            >
                              <p id={this.state.previousPage}>
                                <img
                                  src="../images/patient/img/Patient Intake Process/arrow_black.svg"
                                  id={this.state.previousPage}
                                />
                                {translate("Back")}
                              </p>
                            </div>
                          </div>

                          <div
                            class="col-md-6 col-sm-6 col-xs-6 text-right"
                            onCLick={this.onFormSubmit.bind(this)}
                          >
                            <div class="next_btn">
                              <button
                                type="submit"
                                className="btn btn-default Next_btn_profile"
                              >
                                {translate("Save")} & {translate("Next")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
