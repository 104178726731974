import React, { Component, useState } from "react";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import RetailerCss from "../../public/css/retailer/retailer_style.css";

export default class AgentMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Retailerprofile: "",
      RetailerDashboard: "",
      Retailerstock: "",
      RetailertransactionHistory: "",
      RetailerSettings: "",
      RetailerViewwallertReport: "",
    };
  }
  //Redirect to retailer profile page
  viewRetailerprofile = () => {
    window.location.href = "./Retailerprofile";
  };

  //Redirect to retailer stock page
  viewAgentDashboard = () => {
    window.location.href = Constant.imgurl + "/agent/Agentdashboard";
  };

  //Redirect to retailer settings
  viewsettings = () => {
    window.location.href = Constant.imgurl + "/Agentsettings";
  };

  componentDidMount = () => {
    let url_path = window.location.pathname;
    let pages = url_path.split("/");
    var page = pages[2];

    var obj = {};
    obj[page] = "active";
    this.setState({
      page: page,
    });
    //checking page rendered
    if (page == "Retailerprofile") {
      this.state.Retailerprofile = "active";
    }
    if (page == "Retailerdashboard" || page == "retailerpendingorders") {
      this.state.RetailerDashboard = "active";
    }
    if (page == "Retailerstock") {
      this.state.Retailerstock = "active";
    }
    if (page == "RetailertransactionHistory") {
      this.state.RetailertransactionHistory = "active";
    }
    if (page == "Retailersettings") {
      this.state.RetailerSettings = "active";
    }
    if (page == "RetailerViewwallertReport") {
      this.state.RetailerViewwallertReport = "active";
    }
    if (page == "Viewagents") {
      this.state.Viewagents = "active";
    }

    this.forceUpdate();
  };
  render() {
    return (
      <div class="container-fluid">
        <div class="row">
          <nav id="side_Nav" class="col-lg-2 col-md-2">
            <ul>
              <li
                class={
                  this.state.RetailerDashboard != ""
                    ? this.state.RetailerDashboard
                    : ""
                }
                onClick={this.viewAgentDashboard}
              >
                <img src="../images/retailer/manageOrder.svg" />
                <p>Manage Tests</p>
              </li>

              {/* <li
                class={
                  this.state.Retailersettings != ""
                    ? this.state.Retailersettings
                    : ""
                }
                onClick={this.viewsettings}
              >
                <img src="../images/retailer/settings.svg" />
                <p>Settings</p>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
