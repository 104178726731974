import React, { Component } from "react";

import "../../public/css/patient/paymentsuccess.css";
import { reactLocalStorage } from "reactjs-localstorage";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { ToastContainer, toast } from "react-toastify";
const moment = require("moment");

export default class paymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDone: false,
    };
  }
  // To get detais after first render

  componentDidMount = () => {};
  //To get payment details
  getPaymentData = (paymentId) => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/payment/scucess",
      {
        paymentId: paymentId,
      }
    ).then((responseData) => {
      this.bookingAppointment(responseData);
    });
  };


  // skip payment gateway
  bookingAppointment = (data) => {
    var doctorId = JSON.parse(localStorage.getItem("DoctorSelected"));
    var appointmentId = JSON.parse(localStorage.getItem("appointmentId"));
    var patientId = localStorage.getItem("SelectedPatientId");
    var doctorFees = JSON.parse(localStorage.getItem("selectedDoctorFee"));
    var transactionId = data.data.data.id;

    var consultationDateTime = moment()
      .add(5, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");

    // condition for checking consult later
    if (localStorage.getItem("seletedSlot_time") != null) {
      consultationDateTime = JSON.parse(
        localStorage.getItem("seletedSlot_time")
      );
    }

    let postData = {
      patient_id: patientId,
      doctor_id: doctorId,
      appointment_datetime: consultationDateTime,
      appointment_id: appointmentId,
      transaction_id: transactionId,
      status: "1",
      consultation_fee: doctorFees,
    };
    if (data.data.data.status == "paid") {
      Httpconfig.httptokenpost(
        Constant.siteurl + "api/PatientAppointment/",
        postData
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            localStorage.removeItem("DoctorSelected");
            localStorage.removeItem("appointmentId");
            localStorage.removeItem("SelectedPatientId");
            localStorage.removeItem("selectedDoctorFee");
            localStorage.removeItem("seletedSlot_time");
            reactLocalStorage.remove("purchasesId");
            this.state.paymentDone = true;
            this.forceUpdate();
            toast.success(
              "Thank you for booking appointment, you can view your appointment details",
              {
                position: "bottom-center",
              }
            );

            setTimeout(
              () => (window.location.href = "/Patientdashboard"),
              2000
            );
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  render() {
    return (
      <div class="container-fluid mt-5">
        <div class="row">
          <div class="col-md-6 mx-auto mt-5 ">
            {this.state.paymentDone ? (
              <div class="payments">
                <div class="payment_headers">
                  <div class="check mt-2">
                    <i class="fa fa-check mt-1" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="contents text-success">
                  <h1>Payment Success !</h1>
                </div>
              </div>
            ) : (
              <div class="d-flex justify-content-center mt-5">
                <h2>Loading.....</h2>
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
