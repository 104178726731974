import React from 'react';
import $ from "jquery";

var searchLength = 0
const clear = (e) => {
  searchLength = 0
  $("#medicineList").val('')
  $(".search-li").hide()
  $(".remove_icon").hide()
  console.log($("#medicineList").val)
}

const handleChange = (e) => {
  searchLength = e.target.value.length
}

const Search = ({ searchQuery, setSearchQuery }) => {
  // const searchDataLength = $("#medicineList").val.length
  return (

    <div class="order_input">

      <input

        type="text"

        class="form-control"

        id="medicineList"

        onChange={handleChange}

        placeholder="Search Diagnostics Tests"

        onInput={e => searchQuery(e)}

        autocomplete="off"

      />

      <img

        class="search_img"

        src="../images/patient/img/Ordermedicine/search.svg"

      />

      <ul id="searchResult">{setSearchQuery}</ul>
      {searchLength > 2 ? <img
        class="remove_icon"
        src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
        onClick={clear}
      /> : ""}

    </div>

  );

};



export default Search;