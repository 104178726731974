import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctorAdmin";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";

export default class DoctorSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Doctorprofile: "",
      Doctordashboard: "",
      Doctorviewslots: "",
      DoctorConsultationHistory: "",
      DoctoreEditSlots: "",
      Doctorid: "",
    };
    this.viewDoctorDashboard = this.viewDoctorDashboard.bind(this);
    this.viewDoctorSlots = this.viewDoctorSlots.bind(this);
    this.viewDoctorConsultationHistory =
      this.viewDoctorConsultationHistory.bind(this);
    this.DoctoreEditSlots.bind(this);
  }
  viewDoctorprofile = () => {
    window.location.href = "/doctoradmin/doctoradminDoctorprofile";
  };
  viewDoctorDashboard = () => {
    window.location.href = "/doctoradmin/doctoradmindashboard";
  };
  viewDoctorSlots = () => {
    window.location.href = "/doctoradmin/doctoradminviewslots";
  };
  viewDoctorConsultationHistory = () => {
    window.location.href = "/doctoradmin/DoctorAdminConsultationHistory";
  };

  DoctoreEditSlots = () => {
    window.location.href = "/doctoradmin/doctoradmincreate";
  };

  componentDidMount = () => {
    let url_path = window.location.pathname;
    let pages = url_path.split("/");
    var page = pages[2];
    var obj = {};
    obj[page] = "active";
    this.setState(obj);
    var retrievedObject = localStorage.getItem("doctorAdmin");
    let userData = JSON.parse(retrievedObject);
    this.setState({ Doctorid: userData.doctor_id });
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Doctor/DoctorProfiler/" + userData.doctor_id
    )
      .then((response) => {
        if (response.status == "200" && response.data.error === false) {
          this.setState({
            doctorProfileImage: response.data.data[0].profile_pic,
            doctorGender: response.data.data[0].gender.toLowerCase(),
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  render() {
    return (
      <div class="col-lg-2 col-md-3 no_padding">
        <div class="doctor_sidemenu">
          <ul>
            <li
              className={
                this.state.Doctorprofile != ""
                  ? "doc_side_box active"
                  : "doc_side_box"
              }
              onClick={this.viewDoctorprofile}
            >
              <p>
                <img src="../../images/doctor-img/profile-side.svg" />
                My Profile
              </p>
            </li>

            {this.state.Doctordashboard != "" ? (
              <li
                className={
                  this.state.Doctordashboard != ""
                    ? "doc_side_box active"
                    : "doc_side_box"
                }
                onClick={this.viewDoctorDashboard}
              >
                <p>
                  <img src="../../images/doctor-img/dashboard-side.svg" />
                  Dashboard
                </p>
              </li>
            ) : (
              <li
                className={
                  this.state.Doctordashboard != ""
                    ? "doc_side_box"
                    : "doc_side_box"
                }
                onClick={this.viewDoctorDashboard}
              >
                <p>
                  <img src="../../images/doctor-img/dashboard-side.svg" />
                  Dashboard
                </p>
              </li>
            )}
            {this.state.Doctorviewslots != "" ? (
              <li
                className={
                  this.state.Doctorviewslots != ""
                    ? "doc_side_box active"
                    : "doc_side_box"
                }
                onClick={this.viewDoctorSlots}
              >
                <p>
                  <img src="../../images/doctor-img/avail-slot-side.svg" />
                  Available Slot
                </p>
              </li>
            ) : (
              <li
                className={
                  this.state.Doctorviewslots != ""
                    ? "doc_side_box"
                    : "doc_side_box"
                }
                onClick={this.viewDoctorSlots}
              >
                <p>
                  <img src="../../images/doctor-img/avail-slot-side.svg" />
                  Available Slot
                </p>
              </li>
            )}

            {this.state.DoctorConsultationHistory != "" ? (
              <Link to={"/admin/Createdoctorslots/"} className="point-cursor">
                <li
                  className={
                    this.state.DoctorConsultationHistory != ""
                      ? "doc_side_box active"
                      : "doc_side_box"
                  }
                  onClick={this.viewDoctorConsultationHistory}
                >
                  <p>
                    <img src="../../images/doctor-img/revenue-side.svg" />
                    Consultation History
                  </p>
                </li>
              </Link>
            ) : (
              <li
                className={
                  this.state.DoctorConsultationHistory != ""
                    ? "doc_side_box"
                    : "doc_side_box"
                }
                onClick={this.viewDoctorConsultationHistory}
              >
                <p>
                  <img src="../../images/doctor-img/revenue-side.svg" />
                  Consultation History
                </p>
              </li>
            )}

            {this.state.DoctoreEditSlots != "" ? (
              <li
                className={
                  this.state.DoctoreEditSlots != ""
                    ? "doc_side_box active"
                    : "doc_side_box"
                }
                onClick={this.DoctoreEditSlots}
              >
                <p>
                  <img src="../../images/doctor-img/avail-slot-side.svg" />
                  Manage Slots
                </p>
              </li>
            ) : (
              <li
                className={
                  this.state.DoctoreEditSlots != ""
                    ? "doc_side_box"
                    : "doc_side_box"
                }
                onClick={this.DoctoreEditSlots}
              >
                <p>
                  <img src="../../images/doctor-img/avail-slot-side.svg" />
                  Manage Slots
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
