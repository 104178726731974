import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigAdmin";
//var Constant = require("../../constants");
import Constant from "../../constants";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controller_count: 0,
      controller_total: 0,
      module_count: 0,
      module_total: 0,
      clinic_count: 0,
      doctor_count: 0,
      patient_count: 0,
      retailer_count: 0,
    };
  }

  // 1.To get detais after first render
  componentDidMount = () => {
    this.getControllerCount();
    this.getModuleCount();
    this.getClinicsCount();
    this.getDoctorCount();
    this.getPatientCount();
    this.getRetailerCount();
  };

  // 2.To get all Controllers Count
  getControllerCount() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Mastercontrollers/getControllerCount"
    )
      .then((response) => {
        var count = response.data.activeData;
        var total = response.data.totalData;
        this.setState({
          controller_count: count,
          controller_total: total,
        });
      })
      .catch((error) => {});
  }

  //3. To get all Controllers Count
  getModuleCount() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Mastermodule/getModuleCount"
    )
      .then((response) => {
        var count = response.data.activeData;
        var total = response.data.totalData;
        this.setState({
          module_count: count,
          module_total: total,
        });
      })
      .catch((error) => {});
  }

  // 4.To get all count of Clinics
  getClinicsCount() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Mastercontrollers/count/clinics"
    )
      .then((response) => {
        var count = response.data.data;

        this.setState({
          clinic_count: count,
        });
      })
      .catch((error) => {});
  }

  //5.To get all count of Doctors
  getDoctorCount() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Mastercontrollers/count/doctors"
    )
      .then((response) => {
        var count = response.data.data;
        this.setState({
          doctor_count: count,
        });
      })
      .catch((error) => {});
  }

  // 6. To get all count of Users
  getPatientCount() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Mastercontrollers/count/patients"
    )
      .then((response) => {
        var count = response.data.data;

        this.setState({
          patient_count: count,
        });
      })
      .catch((error) => {});
  }

  // 6. To get all count of Retailers
  getRetailerCount() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Mastercontrollers/count/diagnosis"
    )
      .then((response) => {
        var count = response.data.data;

        this.setState({
          retailer_count: count,
        });
      })
      .catch((error) => {});
  }

  render() {
    const {
      controller_count,
      controller_total,
      module_count,
      module_total,
      pages,
    } = this.state;

    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <h3>Dashboard</h3>
                </div>
              </div>

              <div className="dash_content">
                <div className="row">
                  <div className="col-md-3">
                    <div className="doc_info_box">
                      <div className="info-box-icon bg-red">
                        <i className="fa fa-hospital-o"></i>
                      </div>
                      <div className="info_box_content">
                        <Link
                          to="/admin/Viewclinics"
                          className="dash_content_item"
                        >
                          <span className="info-box-text" title="No Of Clinics">
                            No Of Clinics -{" "}
                          </span>
                          <span className="info-box-number">
                            {this.state.clinic_count}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="doc_info_box">
                      <span className="info-box-icon bg-aqua">
                        <i className="fa fa-user-md"></i>
                      </span>
                      <div className="info_box_content">
                        <Link
                          to="/admin/Viewdoctors"
                          className="dash_content_item"
                        >
                          <span className="info-box-text" title="No Of Health Care Providers">
                            No Of Health Care Providers -{" "}
                          </span>
                          <span className="info-box-number">
                            {this.state.doctor_count}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  

                  <div className="col-md-3">
                    <div className="doc_info_box">
                      <span className="info-box-icon bg-blue">
                        <i className="fa fa-user"></i>
                      </span>
                      <div className="info_box_content">
                        <Link
                          to="/admin/Viewpatients"
                          className="dash_content_item"
                        >
                          <span className="info-box-text" title="No Of Users">
                            No Of Users -{" "}
                          </span>
                          <span className="info-box-number">
                            {this.state.patient_count}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="doc_info_box">
                      <span className="info-box-icon bg-green">
                        <i className="fa fa-list"></i>
                      </span>
                      <div className="info_box_content">
                        <Link
                          to="/admin/ViewDiagnostics"
                          className="dash_content_item"
                        >
                          <span className="info-box-text" title="Remotel Diagnostics">
                            Remotel Diagnostics -{" "}
                          </span>
                          <span className="info-box-number">
                            {this.state.retailer_count}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
