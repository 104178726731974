import React, { PropTypes, Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify"; // for toast messages
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig"; //for apis
import Constant from "../../constants"; // tp get the const values
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider"; // for language conversion
import translate from "../../i18nProvider/translate"; //for lanuage conversion
import Patnewcss from "../../public/css/patient/style-new.css"; //styles for the patient module
import PatientHeader from "../patient/Patientheader"; //patient header
import PatientFooter from "../patient/Patientfooter"; //[atoemt footer
import { Dropdown } from "semantic-ui-react"; // for relatives drop down
import { reactLocalStorage } from "reactjs-localstorage";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class Patientconsultationpurpose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showlogin: true,
      showsignup: false,
      Language: "",
      date: new Date(),
      name: "",
      user_mobile: "",
      email_id: "",
      token: "",
      userId: "",
      profile_image: "",
      fields: { consultation_opinion: "" },
      errors: {},
      selectedRelativeValue: "",
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.addfamily = this.addfamily.bind(this);
  }
  // called after the React component props or state has changed.

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
  };
  /* on form submit validation */
  onFormSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle == undefined) {
      this.createPatient(event);
    }
  }
  /* update the state for the consultation purpose */
  HandleConsulationPurposeChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  /* get the relations */
  getPatientRelations = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtives/" + patientId,
      {}
    )
      .then((response) => {
        let id = "0";
        let pName = "";
        let relationName = " (Self)";
        let relationShipId = "0";
        let image = "../images/patient/img/Profile/Male_patient.svg";
        let my_relatives = "";
        let stateRelationsOptions = [];
        let selectedImage = "";
        let selectedPatient = "";
        let dataCount = Object.keys(response.data.data).length;
        selectedPatient = ls.get("SelectedPatientIdEnc");
        for (let rel = 0; rel < dataCount; rel++) {
          if (response.data.data[rel].id != "") {
            id = response.data.data[rel].id;
          }
          if (response.data.data[rel].relation_patient_id != null) {
            relationShipId = response.data.data[rel].relation_patient_id;
          }
          if (response.data.data[rel].name != null) {
            pName = response.data.data[rel].name;
          }
          if (response.data.data[rel].master_relationship != null) {
            relationName =
              "(" +
              response.data.data[rel].master_relationship.relation_name +
              ")";
          }
          if (response.data.data[rel].profile_pic != null) {
            image = response.data.data[rel].profile_pic;
          } else {
            if (response.data.data[rel].gender.toLowerCase() == "male") {
              image = "../images/patient/img/Profile/Male_patient.svg";
            } else {
              image = "../images/patient/img/Profile/Female_patient.svg";
            }
          }
          if (selectedPatient == id) {
            selectedImage = image;
            selectedPatient = pName + relationName;
          }

          my_relatives = {
            key: relationShipId,
            text: pName + relationName,
            value: id,
            image: { avatar: true, src: image },
          };
          stateRelationsOptions.push(my_relatives);
        }
        this.state.stateRelationsOptions = stateRelationsOptions;
        var patient_id = ls.get("SelectedPatientIdEnc");

        if (patient_id != "") {
          $(".purpose_select .divider").html("");
          $(".purpose_select .divider").html(
            '<img src="' +
              selectedImage +
              '" class="ui avatar image"><span class="text">' +
              selectedPatient +
              "</span>"
          );
          this.setState({
            defaultValue: patient_id,
          });
        } else {
          this.state.selectedRelativeValue = stateRelationsOptions[0].value;
          this.setState({
            defaultValue: stateRelationsOptions[0].value,
          });
        }
        if (patient_id != "") {
          $(".purpose_select .divider").html("");
          $(".purpose_select .divider").html(
            '<img src="' +
              selectedImage +
              '" class="ui avatar image"><span class="text">' +
              selectedPatient +
              "</span>"
          );
        }
        this.forceUpdate();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // To get detais after first render
  componentDidMount = () => {
    var retrievedObject = ls.get("userObjEnc");

    var patient_id = ls.get("SelectedPatientIdEnc");

    var appointmentId = "";
    appointmentId = ls.get("appointmentIdEnc");
    if (appointmentId == "") {
      appointmentId = null;
    }
    this.state.appointmentId = appointmentId;
    let userData = retrievedObject;
    if (userData == "") {
      window.location.href = "/Patientlogin";
    }

    if (retrievedObject != "" || retrievedObject != null) {
      this.setState({
        name: userData.name,
        user_mobile: userData.mobile_number,
        email_id: userData.email,
        token: userData.accessToken,
        patient_id: userData.patient_id,
        profile_image: "",
      });
      this.state.clinicId = userData.clinic_id;

      this.forceUpdate();
      if (userData.patient_id != "") {
        this.getPatientRelations(userData.patient_id);
        this.getPatientConsultaionPurposese(userData.patient_id, appointmentId);
      }
      this.state.userId = userData.patient_id;
    } else {
      window.location.href = "/";
    }
  };

  /* check valiation */
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    var pattern = /^[a-zA-Z0-9]{3,20}$/g;

    if (!fields["consultation_purpose"]) {
      errors["consultation_purpose"] = "";
      formIsValid = false;
      errors["consultation_purpose"] = "Enter the purpose of Consultation";
    } else if (!fields["consultation_opinion"]) {
      errors["consultation_opinion"] = "";
      formIsValid = false;
      toast.error("Select Consultation Opinion");
      errors["consultation_opinion"] = "Select Consultation Opinion";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  /* get the consulation purpose by sending the patient id */
  getPatientConsultaionPurposese = (patient_id, appointmentId) => {
    let url_path = "";
    var patient_ids = ls.get("SelectedPatientIdEnc");
    if (patient_ids != "") {
      patient_id = patient_ids;
      this.state.selectedRelativeValue = patient_id;
    }
    let clinicId = this.state.clinicId;
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/problem/getPatientProblem/" +
        patient_id +
        "/" +
        appointmentId +
        "/" +
        clinicId,
      {}
    )
      .then((response) => {
        /* Assiging consulation purpose to the state */
        let appointmentId = ls.get("appointmentIdEnc");
        if (appointmentId != null) {
          this.setState({
            fields: {
              consultation_purpose: response.data.data[0].problem,
              consultation_opinion: response.data.data[0].consultation_opinion,
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  /* Save patient consulation purpose */
  createPatient = (event) => {
    event.preventDefault();
    var handle = this.props.match.params.handle;
    const { fields, errors } = this.state;
    let url_path = "api/problem/";
    var patient_id = ls.get("SelectedPatientIdEnc");
    if (patient_id == "") {
      toast.error("please select family Member");
      return;
    }

    if (this.state.appointmentId != null) {
      var post_data = {
        patient_id: this.state.selectedRelativeValue,
        problem: this.state.fields["consultation_purpose"],
        appointmentId: JSON.parse(this.state.appointmentId),
      };
    } else {
      var post_data = {
        patient_id: this.state.selectedRelativeValue,
        problem: this.state.fields["consultation_purpose"],
      };
    }

    Httpconfig.httptokenpost(Constant.siteurl + url_path, {
      patient_id: JSON.parse(this.state.selectedRelativeValue),
      problem: this.state.fields["consultation_purpose"],
      consultation_opinion: this.state.fields["consultation_opinion"],
      appointmentId: JSON.parse(this.state.appointmentId),
      clinic_id: JSON.parse(this.state.clinicId),
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let next_page = response.data.nextpage.web_reference_page_name;
          let patientAppointmentId = response.data.data.appointment_id;
          var retrievedObject = ls.set(
            "appointmentIdEnc",
            patientAppointmentId
          );
          toast.dismiss();
          toast.success("👌 Purpose of Consultaion Saved successfully", {
            position: "bottom-center",
            autoClose: 1000,
          });
          setTimeout(() => this.props.history.push("/" + next_page), 2000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  /* Adddin family members */
  addfamily = () => {
    this.props.history.push("/Patientadd");
  };
  /* set the selected lanuage to the state */
  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  // for onRelativeChange
  onRelativeChange = (event, data) => {
    let selectedRelativeValue = data.value;

    var retrievedObject = ls.set("SelectedPatientIdEnc", selectedRelativeValue);
    this.state.selectedRelativeValue = data.value;
    ls.remove("appointmentIdEnc");
    this.state.appointmentId = null;
  };

  render() {
    var retrievedObject = ls.get("userObjEnc");
    let userData = retrievedObject;
    if (userData == null) {
      window.location.href = "/Patientlogin";
    }
    const patient_id = userData.patient_id;
    const ueserId = this.state.userId;
    const name = this.state.name;
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
      this.props;
    const { languages_data } = this.state;

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <ToastContainer />
          <form onSubmit={this.onFormSubmit.bind(this)}>
            <section id="purpose">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <div class="purpose_heading">
                      <h2>{translate("Intake Process")}</h2>
                    </div>
                  </div>
                  
                  {this.state.stateRelationsOptions ? (
                    <div class="col-md-8 text-right" >
                      <div class="purpose_select">
                        <h4>{translate("Enter the details for")}…</h4>

                        <Dropdown
                          placeholder="Select Family Member"
                          options={this.state.stateRelationsOptions}
                          defaultValue={this.state.value}
                          onChange={this.onRelativeChange}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div class="purpose_consult">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="stepwizard">
                        <div class="stepwizard-row setup-panel">
                          <div class="stepwizard-step">
                            <a
                              href="#step-1"
                              type=""
                              class="btn btn-default btn-circle"
                            >
                              1
                            </a>
                            <p class="p_dark">
                              {translate("Purpose of Consultation")}
                            </p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#step-2"
                              type=""
                              class="btn btn-default btn-circle light_circle"
                              disabled="disabled"
                            >
                              2
                            </a>
                            <p class="p_light">
                              {translate("Specify Symptoms")}
                            </p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#step-3"
                              type=""
                              class="btn btn-default btn-circle light_circle"
                              disabled="disabled"
                            >
                              3
                            </a>
                            <p class="p_light">
                              {translate("Provide Vital Informations")}
                            </p>
                          </div>
                          <div class="stepwizard-step">
                            <a
                              href="#step-4"
                              type=""
                              class="btn btn-default btn-circle light_circle"
                              disabled="disabled"
                            >
                              4
                            </a>
                            <p class="p_light">
                              {translate("History of Past Illness")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="consult_fields">
                        <h2>Type Of Appointment</h2>
                        <div class="consult_radio">
                          <div class="radio-inline">
                            <label>
                              <input
                                type="radio"
                                className="gender"
                                name="optradio"
                                value="First Consultation"
                                checked={
                                  this.state.fields["consultation_opinion"] ==
                                  "First Consultation"
                                }
                                id="first_consultation"
                                onClick={this.HandleConsulationPurposeChange.bind(
                                  this,
                                  "consultation_opinion"
                                )}
                              />
                               <span class="label-text">
                              {translate("First Consultation")}
                              </span>
                            </label>
                          </div>
                          <div class="radio-inline">
                            <label>
                              <input
                                type="radio"
                                name="optradio"
                                value="Second Opinion"
                                checked={
                                  this.state.fields["consultation_opinion"] ==
                                  "Second Opinion"
                                }
                                id="second_opinion"
                                onClick={this.HandleConsulationPurposeChange.bind(
                                  this,
                                  "consultation_opinion"
                                )}
                              />
                                <span class="label-text">
                              {translate("Follow up")}
                              </span>
                            </label>
                          </div>
                        </div>
                        <span className="cRed">
                          {this.state.errors["consultation_opinion"]}
                        </span>
                      </div>
                      <div class="purpose_box">
                        <h2>{translate("Purpose of Consultation")}</h2>
                        <form>
                          <div class="form-group col-md-12 no_padding">
                            <FormattedMessage id="Type here eg. Fever">
                              {(placeholder) => (
                                <textarea
                                  name="consultation_purpose"
                                  rows="4"
                                  class="form-control  log-textarea"
                                  placeholder={placeholder}
                                  value={
                                    this.state.fields["consultation_purpose"] ||
                                    ""
                                  }
                                  onBlur={this.HandleConsulationPurposeChange.bind(
                                    this,
                                    "consultation_purpose"
                                  )}
                                  onFormSubmit={this.HandleConsulationPurposeChange.bind(
                                    this,
                                    "consultation_purpose"
                                  )}
                                  onChange={this.HandleConsulationPurposeChange.bind(
                                    this,
                                    "consultation_purpose"
                                  )}
                                />
                              )}
                            </FormattedMessage>
                          </div>
                          <span className="cRed">
                            {this.state.errors["consultation_purpose"]}
                          </span>

                          <p>{transcript}</p>
                        </form>

                        <div class="mic_icon">
                          <img src="../images/patient/img/Voicerecord.svg" />
                        </div>

                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-xs-6 text-left"></div>
                          <div
                            class="col-md-6 col-sm-6 col-xs-6 text-right"
                            onCLick={this.onFormSubmit.bind(this)}
                          >
                            <div class="next_btn">
                              <button
                                type="submit"
                                className="btn btn-default Next_btn_profile"
                              >
                                {translate("Save")} & {translate("Next")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>

          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
