import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { Dropdown } from "semantic-ui-react";
import { Multiselect } from "multiselect-react-dropdown";
import { reactLocalStorage } from "reactjs-localstorage";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

export default class AvailableDoctors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      langOptions: [],
      genderOptions: [],
      experienceOptions: [],
      specalities: [],
      modeOptions: [],
      ratingOptions: [],
      viewProfileOpen: false,
      viewAppointmentOpen: false,
      doc_data: [],
      selectedtime: "",
      sorting: "",
      doctorSlotsfromdate: new Date(),
      problem: "Choose Specialist",
      processlater: "col-md-12 collapse-hide",
      specalitesClass: "select_d collapse-hide",
      sendNotification: "",
      clinicId: "",
    };
    this.viewDocProfile = this.viewDocProfile.bind(this);
    this.bookDocAppointment = this.bookDocAppointment.bind(this);
    this.consultLater = this.consultLater.bind(this);

    this.consultNow = this.consultNow.bind(this);
    this.openMap = this.openMap.bind(this);
    this.onSortingChange = this.onSortingChange.bind(this);
  }

  // called after the React component props or state has changed.

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
    var sort = document.getElementsByClassName("specality");
    let selectedProblem = "";
    for (let i = 0; i < sort.length; i++) {
      sort[i].addEventListener(
        "click",
        function (event) {
          if (event.target.id != "") {
            selectedProblem = document.getElementById(
              event.currentTarget.id
            ).innerText;
          } else {
            selectedProblem = "Choose Specialist";
          }

          this.setState({
            specalities: [event.currentTarget.id],
            problem: selectedProblem,
          });
          this.forceUpdate();
          this.onFilterChange();
        }.bind(this),
        true
      );
    }
  };

  // To get detais after first render
  componentDidMount = () => {
    window.addEventListener("popstate", function (e) {
      window.location.reload();
    });
    var specal = document.getElementsByClassName("spec");
    for (let i = 0; i < specal.length; i++) {
      specal[i].addEventListener(
        "click",
        function (event) {
          this.onLoadSpecalities();
        }.bind(this)
      );
    }
    var retrievedObject = ls.get("userObjEnc");
    var patient_id = ls.get("SelectedPatientIdEnc");
    if (retrievedObject != "" || retrievedObject != null) {
      let userData = retrievedObject;
      var seletectePatient = ls.get("SelectedPatientIdEnc");
      this.state.clinicId = userData.clinic_id;
      this.forceUpdate();
      if (patient_id != null || patient_id != "") {
        userData.patient_id = patient_id;
      }
      if (seletectePatient) {
        this.getPatientRelations(seletectePatient);
      }
      this.onLoadSpecalities();
      if (retrievedObject != null || retrievedObject != null) {
        this.setState({
          name: userData.name,
          user_mobile: userData.mobile_number,
          email_id: userData.email,
          token: userData.accessToken,
          patient_id: userData.patient_id,
          profile_image: "",
        });
        this.forceUpdate();

        /*get the doctor details */

        Httpconfig.httptokenpost(
          Constant.siteurl + "api/Doctor/DoctorIsavailablefilters",
          {
            gender: this.state.genderOptions,
            sortting: this.state.sorting,
            experience: this.state.experienceOptions,
            languages: this.state.langOptions,
            specalities: this.state.specalities,
            clinic_id: this.state.clinicId,
            search_name: this.state.doctorNameSearch,
          }
        ).then((response) => {
          this.setState({
            doc_data: response.data.data,
          });
        });
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
    this.onSortingChange();
    this.getLanuages();
    const interval = setInterval(() => {
      let patientId = "";
      var retrievedObject = ls.get("userObjEnc");
      if (retrievedObject != null) {
        let userData = retrievedObject;
        patientId = userData.patient_id;
        this.setState({
          patientId: userData.patient_id,
        });

        if (patientId != null) {
          this.getNotifications(patientId);
        }
      }
    }, 8000);
  };

  // For getting the location in map based on lattitude and longitude

  openMap = (lat, lang) => {
    window.open("https://maps.google.com?q=" + lat + "," + lang);
  };

  //To get PatientRelations
  getPatientRelations = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtives/" + patientId,
      {}
    )
      .then((response) => {
        /* Assiging consulation purpose to the state */
        let PatientName = "";
        response.data.data.map((data, index) =>
          data.id == patientId
            ? data.gender.toLowerCase() == "male"
              ? (PatientName = "Mr." + data.name)
              : (PatientName = "Ms." + data.name)
            : ""
        );

        this.setState({
          PatientName: PatientName,
        });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For getting the date which is selected by user
  getUserSelectedDate = (value) => {
    let date = new Date(value);
    let selectedDate =
      date.getFullYear() +
      "-" +
      Number(date.getMonth() + 1) +
      "-" +
      date.getDate();

    this.fetchUserSelectedSlots(selectedDate);
  };

  // For getting the languages
  getLanuages = () => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Clinic/getClinicLanguages/" + this.state.clinicId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          const languages = response.data.data.map((lang, num) => {
            return (
              <label>
                <input
                  id={lang.id}
                  type="checkbox"
                  name={lang.name}
                  value={lang.id}
                  onChange={this.onLanguageChange.bind(this)}
                />{" "}
                <span className="label-text">{translate(lang.name)}</span>
              </label>
            );
          });

          this.state.lanuagesList = languages;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For changing the language
  onLanguageChange = (event) => {
    var langArr = [...this.state.langOptions];
    const value = event.target.value;
    const index = langArr.findIndex((day) => day === value);
    if (index > -1) {
      langArr = [...langArr.slice(0, index), ...langArr.slice(index + 1)];
    } else {
      langArr.push(value);
    }
    this.state.langOptions = langArr;
    this.onFilterChange();
  };

  //  For changing the gender

  onGenderChange = (event) => {
    var genderArr = [...this.state.genderOptions];
    const value = event.target.value;
    const index = genderArr.findIndex((day) => day === value);
    if (index > -1) {
      genderArr = [...genderArr.slice(0, index), ...genderArr.slice(index + 1)];
    } else {
      genderArr.push(value);
    }
    this.state.genderOptions = genderArr;
    this.onFilterChange();
  };

  // For changin the experience

  onExperienceChange = (event) => {
    var expArr = [...this.state.experienceOptions];
    const value = event.target.value;
    const index = expArr.findIndex((day) => day === value);
    if (index > -1) {
      expArr = [...expArr.slice(0, index), ...expArr.slice(index + 1)];
    } else {
      expArr.push(value);
    }
    this.state.experienceOptions = expArr;
    this.onFilterChange();
  };

  //  For payment
  handlePay = (e) => {
    ls.set("DoctorSelectedEnc", e.target.id);
    ls.set("paymentTime", moment().format("YYYY MM DD hh:mm:ss a"));
    setTimeout(
      () => this.props.history.push("/Patientconfirmappointment"),
      2000
    );
  };

  //  For consult later pay
  consultLaterPay = (event) => {
    let clickedId = event.currentTarget.id;
    let selectedTime = ls.get("seletedSlot_timeEnc");
    if (clickedId != "" && selectedTime) {
      ls.set("DoctorSelectedEnc", clickedId);
      ls.set("paymentTime", moment().format("YYYY MM DD hh:mm:ss a"));
      setTimeout(
        () => this.props.history.push("/Patientconfirmappointment"),
        2000
      );
    } else {
      toast.dismiss();
      toast.error("Please select slot time");
    }
  };

  // Doctor Search
  searchDoctor = (event) => {
    //let searchString = event.target.value;
    //this.state.doctorNameSearch = searchString;
    // this.forceUpdate();
    // this.onFilterChange();
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Doctor/DoctorIsavailablefilters/",
      {
        gender: this.state.genderOptions,
        sortting: this.state.sorting,
        experience: this.state.experienceOptions,
        languages: this.state.langOptions,
        specalities: this.state.specalities,
        rating: this.state.ratingOptions,
        search_name: event.target.value //this.state.doctorNameSearch,
      }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.doc_data = response.data.data;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For getting the doctor based on the filter
  onFilterChange = () => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Doctor/DoctorIsavailablefilters/",
      {
        gender: this.state.genderOptions,
        sortting: this.state.sorting,
        experience: this.state.experienceOptions,
        languages: this.state.langOptions,
        specalities: this.state.specalities,
        rating: this.state.ratingOptions,
        search_name: this.state.doctorNameSearch,
      }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.doc_data = response.data.data;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For rating change
  onRatingChange = (event) => {
    var ratingArr = [...this.state.ratingOptions];
    const value = parseInt(event.target.value);
    const index = ratingArr.findIndex((day) => day === value);
    if (index > -1) {
      ratingArr = [...ratingArr.slice(0, index), ...ratingArr.slice(index + 1)];
    } else {
      ratingArr.push(value);
    }
    this.state.ratingOptions = ratingArr;
    this.onFilterChange();
  };

  //  For getting the specialities
  onLoadSpecalities = () => {
    // choose speciality drop down
    Httpconfig.httptokenget(Constant.siteurl + "api/Category/1")
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let specalitiesCount = Object.keys(response.data.data).length;

          if (specalitiesCount > 0) {
            const specalities = response.data.data.map(
              (specalitiesData, num) => {
                let imgPath = "";
                if (specalitiesData.category_image != null) {
                  imgPath = Constant.imgurl + specalitiesData.category_image;
                }
                return (
                  <React.Fragment>
                    {num == 0 ? (
                      <div class="select_doctor">
                        <div class="common_health">
                          <h5>Common Health problems</h5>
                          <p> Select your specialist</p>
                        </div>

                        <h3 class="clr_txt" onClick={this.clear}>
                          Clear
                        </h3>
                      </div>

                    ) : (
                      ""
                    )}
                    <div class="dropbar_content">
                      <li
                        onClick={this.handleSpecalityChange.bind(
                          this,
                          specalitiesData.id,
                          specalitiesData.category
                        )}
                      >
                        <a id={specalitiesData.id}>
                          {imgPath ? <img src={imgPath} /> : ""}
                          <p>{specalitiesData.category}</p>
                        </a>
                      </li>
                    </div>
                  </React.Fragment>
                );
              }
            );
            this.state.specalitiesList = specalities;
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For changing the specialities
  handleSpecalityChange = (id, name) => {
    this.state.specalities = [id];
    this.state.specalitesClass = "select_d collapse-hide";
    this.state.problem = name;
    this.onFilterChange();
    this.forceUpdate();
  };

  // For mode change
  onModeChange = (event) => {
    var modeArr = [...this.state.modeOptions];
    const value = event.target.value;
    const index = modeArr.findIndex((day) => day === value);
    if (index > -1) {
      modeArr = [...modeArr.slice(0, index), ...modeArr.slice(index + 1)];
    } else {
      modeArr.push(value);
    }
    this.state.modeOptions = modeArr;
  };

  //  For sorting

  onSortingChange = (event) => {
    let i = 0;
    let selected = "";
    var sort = document.getElementsByClassName("sort");
    for (i = 0; i < sort.length; i++) {
      sort[i].addEventListener(
        "click",
        function (event) {
          this.setState({
            sorting: event.currentTarget.id,
          });
          this.onFilterChange();
        }.bind(this)
      );
    }
  };

  //  For selecting clear all

  onUserClearall = (event) => {
    var clearAll = document.getElementsByTagName("input");
    for (var i = 0; i < clearAll.length; i++) {
      if (clearAll[i].type == "checkbox") {
        clearAll[i].checked = false;
      }
    }
    this.state.langOptions = [];
    this.state.genderOptions = [];
    this.state.experienceOptions = [];
    this.state.modeOptions = [];
    this.state.ratingOptions = [];
    this.state.sorting = [];
    this.state.specalities = [];
    this.state.problem = "Choose Specialist";

    this.onFilterChange();
  };

  //  For language handling
  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  //  For view Doctor profile
  viewDocProfile = (e) => {
    if (this.state.viewProfileOpen != e.target.id) {
      this.setState({
        viewProfileOpen: e.target.id,
      });
    } else {
      this.setState({
        viewProfileOpen: "",
      });
    }
  };

  //  For booking the appointment
  bookDocAppointment = (e) => {
    if (this.state.viewAppointmentOpen != e.target.id) {
      ls.remove("seletedSlot_timeEnc");
      $(".timings").removeClass("highlet_button");
      $("#doctorid" + e.target.id).show();
      this.setState({
        viewAppointmentOpen: e.target.id,
      });
    } else {
      this.setState({
        viewAppointmentOpen: "",
      });
    }
  };

  //  For getting the notification for consultnow appointments
  getNotifications = (patientId) => {
    let timeZone = moment.tz.guess();
    Httpconfig.httptokenpost(
      Constant.siteurl +
      "api/PatientAppointment/getPatientConsultNowAppointments/",
      {
        timeZone: timeZone,
        patient_id: patientId,
      }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (response.data.data.length > 0) {
            let appointmentId = ls.get("appointmentIdEnc");
            for (let count = 0; count < response.data.data.length; count++) {
              if (
                (response.data.data[count].status == 2 ||
                  response.data.data[count].status == 3) &&
                appointmentId == response.data.data[count].appointment_id
              ) {
                var dateTime =
                  response.data.data[count].appointment_date +
                  " " +
                  response.data.data[count].appointment_time;
                var mindiff = moment().diff(dateTime, "minutes");
                if (mindiff < 7) {
                  if (
                    this.state.sendNotification !=
                    response.data.data[count]["doctor_id"]
                  ) {
                    this.setState({
                      sendNotification: response.data.data[count]["doctor_id"],
                      showSlots: "",
                      docConfirmed: response.data.data[count]["doctor_id"],
                      sendNotification: "",
                    });
                    this.forceUpdate();
                  } else {
                    this.setState({
                      sendNotification: "",
                      docConfirmed: "",
                    });
                    this.forceUpdate();
                  }
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For checking the appointment request status
  checkRequestStatus = (id) => {
    alert("in")
    ls.remove("seletedSlot_timeEnc");
    $(".timings").removeClass("highlet_button");
    let doctorName = "";
    let doctorId = id;
    var retrievedObject = ls.get("userObjEnc");
    let appointmentId = ls.get("appointmentIdEnc");
    var patient_id = ls.get("SelectedPatientIdEnc");
    let userData = retrievedObject;
    let masterPatientId = userData.patient_id;

    if (patient_id != null || patient_id != "") {
      userData.patient_id = patient_id;
    }
    let PatientName = this.state.PatientName;
    let PatientId = userData.patient_id;
    let consultationTime = moment().format("HH:mm");
    let consultationDateTime = moment().format("YYYY-MM-DD");

    if (this.state.sendNotification != doctorId) {
      this.setState({
        sendNotification: doctorId,
        showSlots: "",
      });
      Httpconfig.httptokenpost(
        Constant.siteurl +
        "api/PatientAppointment/ConsultNowDoctorConfirmation/",
        {
          doctor_id: doctorId,
          doctor_name: doctorName,
          patient_id: PatientId,
          master_patient_id: masterPatientId,
          patient_age: "",
          patient_name: PatientName,
          appointment_id: parseInt(appointmentId),
          appointment_time: consultationTime,
          appointment_date: consultationDateTime,
        }
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            if (
              response.data.requestStatus == 2 ||
              response.data.requestStatus == 3
            ) {
              this.setState({
                docConfirmed: doctorId,
                sendNotification: "",
              });
              this.forceUpdate();
            }
            toast.success(response.data.message, {
              position: "top-center",
            });
          } else {
            toast.error(response.data.message, {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      this.setState({
        sendNotification: "",
        docConfirmed: "",
      });
    }
  };

  //  Consult now  appointment booking
  consultNow = (e) => {
    let id = e.target.id;
    ls.remove("seletedSlot_timeEnc");
    ls.set("consultation_type", "consult_now");
    $(".timings").removeClass("highlet_button");
    let doctordata = id.split("-");
    let doctorName = doctordata[1];
    let doctorId = doctordata[0];
    $("#doctorid" + doctorId).hide();

    var retrievedObject = ls.get("userObjEnc");

    var appointmentId = ls.get("appointmentIdEnc");

    var patient_id = ls.get("SelectedPatientIdEnc");

    let userData = retrievedObject;
    let masterPatientId = userData.patient_id;

    if (patient_id != null || patient_id != "") {
      userData.patient_id = patient_id;
    }
    let PatientName = this.state.PatientName;
    let PatientId = userData.patient_id;
    let consultationTime = moment().format("HH:mm");
    let consultationDateTime = moment().format("YYYY-MM-DD");

    if (this.state.sendNotification != doctorId) {
      this.setState({
        sendNotification: doctorId,
        showSlots: "",
      });

      Httpconfig.httptokenpost(
        Constant.siteurl +
        "api/PatientAppointment/ConsultNowDoctorConfirmation/",
        {
          doctor_id: doctorId,
          doctor_name: doctorName,
          patient_id: PatientId,
          master_patient_id: masterPatientId,
          patient_age: "",
          patient_name: PatientName,
          appointment_id: parseInt(appointmentId),
          appointment_time: consultationTime,
          appointment_date: consultationDateTime,
        }
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            if (
              response.data.requestStatus == 2 ||
              response.data.requestStatus == 3
            ) {
              this.setState({
                docConfirmed: doctorId,
                sendNotification: "",
              });
            }
          } else {
            toast.error(response.data.message, {
              position: "top-center",
            });
          }
        })
        .catch((error) => { });
    } else {
      this.setState({
        sendNotification: "",
        docConfirmed: "",
      });
    }
  };

  //Fetch the Doctor Appointment Slots Starts
  fetchDoctorSlots = (id) => {
    Httpconfig.httptokenpost(Constant.siteurl + "api/telemedicine/getSlots", {
      doctor_id: id,
    }).then((response) => {
      if (response.data.result) {
        if (response.data.result) {
          var doctorSlotsfromdate = new Date(response.data.result.fromDate);
          if (new Date(doctorSlotsfromdate) < new Date()) {
            doctorSlotsfromdate = new Date();
          }

          var doctorSlotstodate = new Date(response.data.result.toDate);

          var numberOfDays = 0;
          if (
            moment(doctorSlotstodate).diff(
              moment(doctorSlotsfromdate).format("YYYY-MM-DD"),
              "days"
            ) >= 0
          ) {
            var numberOfDays = moment(doctorSlotstodate)
              .add(1, "days")
              .diff(moment(doctorSlotsfromdate).format("YYYY-MM-DD"), "days");
          }

          this.setState({
            doctorSlotsfromdate: doctorSlotsfromdate,
            doctorSlotstodate: doctorSlotstodate,
            numberOfDays: numberOfDays,
          });
          this.forceUpdate();
        }
      }
    });
  };

  //  consultlater appointment booking
  consultLater = (e) => {
    this.fetchDoctorSlots(e.target.id);
    ls.remove("seletedSlot_timeEnc");
    $(".timings").removeClass("highlet_button");
    $("#doctorid" + e.target.id).hide();
    if (this.state.showSlots != e.target.id) {
      this.setState({
        showSlots: e.target.id,
        sendNotification: "",
        docConfirmed: "",
      });
    } else {
      this.setState({
        showSlots: "",
      });
    }
  };

  //  For Going back to previous page
  goBack = () => {
    window.location.href = "/Patientmedicalhistory";
  };

  //  For getting the slots

  fetchUserSelectedSlots(selectedDate) {
    let doctorId = this.state.showSlots;
    let timeZone = moment.tz.guess();
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/telemedicine/patientAppointmentSlots",
      {
        doctor_id: doctorId,
        consultation_date: selectedDate,
        module_type: "telemedicine-app",
        timeZone: timeZone,
      }
    ).then((response) => {
      if (response.status == 200) {
        let resultCount = Object.keys(response.data.result).length;
        let slotsCount = Object.keys(response.data.result).length;
        if (resultCount > 0) {
          const morningSlots = response.data.result[0];
          const afternoonSlots = response.data.result[1];
          const eveningSlots = response.data.result[2];
          const nightSlots = response.data.result[3];
          $("#" + doctorId).addClass("collapse-show");

          this.setState({
            morningSlotsLabel: morningSlots.Label,
            morningSlots: morningSlots.time_slots,
            afternoonLabel: afternoonSlots.Label,
            afternoonSlots: afternoonSlots.time_slots,
            eveningSlotsLabel: eveningSlots.Label,
            eveningSlots: eveningSlots.time_slots,
            nightSlotsLabel: nightSlots.Label,
            nightSlots: nightSlots.time_slots,
            slotsCount: slotsCount,
          });
          var i = 0;
          var time = document.getElementsByClassName("timings");
          for (i = 0; i < time.length; i++) {
            time[i].addEventListener("click", function (event) {
              var selelcedT = this.innerHTML;

              let sTime = moment(selelcedT, ["h:mm A"]).format("HH:mm:ss");
              let seldate = selectedDate + " " + sTime;

              let seletedSlot_time = moment(seldate, [
                "YYYY-MM-DD HH:mm A",
              ]).format("YYYY-MM-DD HH:mm:ss");

              ls.set("seletedSlot_timeEnc", seletedSlot_time);
              ls.set("consultation_type", "consult_later");
              var lang = localStorage.getItem("seletedSlot_time");
              $(".timings").removeClass("highlet_button");
              $(this).addClass("highlet_button");
            });
          }
        } else {
          toast.error("Slots not Available");
          this.setState({
            morningSlotsLabel: "",
            morningSlots: "",
            afternoonLabel: "",
            afternoonSlots: "",
            eveningSlotsLabel: "",
            eveningSlots: "",
            nightSlotsLabel: "",
            nightSlots: "",
            slotsCount: "",
          });
          this.forceUpdate();
        }
      }
    });
  }
  // show the dropdown list of specalistes
  showList = () => {
    this.state.specalitesClass = "select_d collapse-show";
  };

  // For clearing the specialities
  clear = () => {
    this.state.specalitesClass = "select_d collapse-hide";
    this.state.specalities = "";
    this.state.problem = "Choose Specialist";
    this.onFilterChange();
  };

  render() {
    const {
      fields,
      errors,
      files,
      langOptions,
      genderOptions,
      experienceOptions,
      modeOptions,
      ratingOptions,
      viewProfileOpen,
      viewAppointmentOpen,
    } = this.state;

    var currentDate = new Date();
    var numberOfDaysToAdd = 45;
    const daysHighlighted = new Array(numberOfDaysToAdd).fill(currentDate);

    var slotsMorningArray = [];
    var slotsNoonArray = [];
    var slotsEveningArray = [];
    var slotsNightArray = [];
    var morningArr = this.state.morningSlots;
    var afternoonArr = this.state.afternoonSlots;
    var eveningArr = this.state.eveningSlots;
    var nightArr = this.state.nightSlots;

    if (morningArr) {
      var nightLength = morningArr.length;
      var mrng = "";
      mrng += '<div class="sch_time">';
      for (var i = 0; i < nightLength; i++) {
        if (i == 0) {
          mrng += "<h3>Morning</h3>";
        }
        mrng += "<p class='timings'>" + morningArr[i] + "</p>";
      }
      mrng += "</div>";
      slotsMorningArray = mrng;
      this.state.processlater = "col-md-12 collapse-show";
    }

    if (afternoonArr) {
      var noonLength = afternoonArr.length;
      var noon = "";
      noon += '<div class="sch_time">';
      for (var i = 0; i < noonLength; i++) {
        if (i == 0) {
          noon += "<h3>After noon</h3>";
        }
        noon += "<p class='timings'>" + afternoonArr[i] + "</p>";
      }
      noon += "</div>";
      slotsNoonArray = noon;
      this.state.processlater = "col-md-12 collapse-show";
    }

    if (eveningArr) {
      var evngLength = eveningArr.length;
      var evng = "";
      evng += '<div class="sch_time">';
      for (var i = 0; i < evngLength; i++) {
        if (i == 0) {
          evng += "<h3>Evening</h3>";
        }
        evng += "<p class='timings'>" + eveningArr[i] + "</p>";
      }
      evng += "</div>";
      slotsEveningArray = evng;
      this.state.processlater = "col-md-12 collapse-show";
    }

    if (nightArr) {
      var nightLength = nightArr.length;
      var night = "";
      night += '<div class="sch_time">';
      for (var i = 0; i < nightLength; i++) {
        if (i == 0) {
          night += "<h3>Night</h3>";
        }
        night += "<p class='timings'>" + nightArr[i] + "</p>";
      }
      night += "</div>";
      slotsNightArray = night;
      this.state.processlater = "col-md-12 collapse-show";
    }

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="availavle_doc">
            <div className="container">
              <div className="row av_box">
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <div className="available_heading">
                    <h4>
                      <img
                        src="../images/patient/img/Doctors_List/View Profile/Back.svg"
                        onClick={this.goBack}
                      />
                      {translate("User Details")}
                    </h4>
                    <h2>{translate("Available Doctors")}</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12">
                  <div className="row available_sort">
                    <div className="col-md-4 ">
                      <div className="search_doc">
                        <input
                          type="search"
                          class="form-control"
                          placeholder="Search Doctors"
                          onKeyUp={this.searchDoctor}
                        ></input>
                        <span>
                          <img
                            class="search_icon"
                            src=".././images/patient/img/searchicon.svg"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wrap-select">
                        <div id="dd" className="wrapper-dropdown-3">
                          <span class="choose_txt">{translate("Sorting")}</span>
                          <div class="drop_img">
                            <img src="../images/patient/img/Homepage/Dropdown.svg" />
                          </div>
                          <ul className="dropdown">
                            <li>
                              <a href="#" class="sort" id="low to high">
                                <input
                                  type="radio"
                                  value="low to high"
                                  onChange={(event) => {
                                    this.onSortingChange(event);
                                  }}
                                />
                                {translate("Price - Low to high")}
                              </a>
                            </li>
                            <li>
                              <a href="#" class="sort" id="high to low">
                                <input
                                  type="radio"
                                  value="high to low"
                                  onClick={this.onSortingChange.bind(this)}
                                />
                                {translate("Price - High to low")}
                              </a>
                            </li>
                            <li>
                              <a href="#" class="sort" id="experience">
                                <input
                                  type="radio"
                                  value="experience"
                                  onClick={this.onSortingChange.bind(this)}
                                />
                                {translate("Experience")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="doc_spc">
                        <div id="dd2" className="wrapper-dropdown-3 spec">
                          <div class="choose_txt" onClick={this.showList}>
                            {translate(this.state.problem)}
                            <div class="drop_img">
                              <img src="../images/patient/img/Homepage/Dropdown.svg" />
                            </div>
                          </div>

                          <div class={this.state.specalitesClass}>
                            {this.state.specalitiesList
                              ? this.state.specalitiesList
                              : ""}
                          </div>
                        </div>

                        <div className="wrap-select"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="available_section">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="sort_box">
                      <h2>{translate("Filters")}</h2>
                      <a href="#" onClick={this.onUserClearall.bind(this)}>
                        <p>{translate("Clear all")}</p>
                      </a>

                      <div></div>
                      <div>
                        <h5>{translate("Gender")}</h5>
                        <div className="form-check">
                          <label>
                            <input
                              type="checkbox"
                              name="gender"
                              value="Male"
                              onChange={this.onGenderChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              {translate("Male")}
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="gender"
                              value="Female"
                              onChange={this.onGenderChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              {translate("Female")}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div>
                        <h5>{translate("Experience")}</h5>
                        <div className="form-check">
                          <label>
                            <input
                              type="checkbox"
                              name="experience"
                              value="1-4"
                              onChange={this.onExperienceChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              1 to 4 {translate("years")}
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="experience"
                              value="4-8"
                              onChange={this.onExperienceChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              4 to 8 {translate("years")}
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="experience"
                              value="8-12"
                              onChange={this.onExperienceChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              8 to 12 {translate("years")}
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="experience"
                              value="12-16"
                              onChange={this.onExperienceChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              12 to 16 {translate("years")}
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="experience"
                              value="16-18"
                              onChange={this.onExperienceChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              16 to 18 {translate("years")}
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="experience"
                              value="18 >"
                              onChange={this.onExperienceChange.bind(this)}
                            />{" "}
                            <span className="label-text">
                              {translate("Above")} 18 {translate("years")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-12">
                    {
                      this.state.doc_data.length > 0 ?
                        this.state.doc_data.map((doctor) => (
                          <div className="doc_box">
                            <div className="row">
                              <div className="col-md-2 col-sm-12 col-4">
                                <div className="doc_img">
                                  {doctor.gender == "Male" ? (
                                    <img
                                      src={
                                        doctor.profile_pic != ""
                                          ? doctor.profile_pic
                                          : "../images/patient/img/Profile/Male_doctor.svg"
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={
                                        doctor.profile_pic != ""
                                          ? doctor.profile_pic
                                          : "../images/patient/img/Profile/Female_doctor.svg"
                                      }
                                    />
                                  )}
                                  {doctor.is_available == 1 ? (
                                    <p className="online">
                                      <img src="../images/patient/img/Profile/available.svg" />
                                    </p>
                                  ) : (
                                    <p className="online">
                                      <img src="../images/patient/img/Profile/busy.svg" />
                                    </p>
                                  )}
                                  {doctor.is_available == 1 ? (
                                    <h5 class="doc_available">
                                      {translate("Doctor is Available now")}
                                    </h5>
                                  ) : (
                                    <h5 class="doc_busy">
                                      {translate("Doctor is busy now")}
                                    </h5>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-5 col-sm-12 col-8">
                                <div className="doc_info">
                                  <h2>
                                    Dr.{" "}
                                    {doctor.doctor_name.charAt(0).toUpperCase() +
                                      doctor.doctor_name.slice(1)}
                                  </h2>
                                  <h5>
                                    {doctor.course_name ? doctor.course_name : "--"}
                                    ,{doctor.speciality_name}
                                  </h5>
                                  <h5>
                                    {doctor.experience}{" "}
                                    {translate("years experience")}
                                  </h5>
                                  <p>
                                    <span>
                                      {doctor.currency_symbol}
                                      {doctor.fees}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-5 col-sm-12">
                                <div className="doc_directions">
                                  {doctor.clinic_tbl ? (
                                    doctor.clinic_tbl.clinic_logo == null ? (
                                      <img src="./images/patient/img/Profile/hospital.svg" />
                                    ) : (
                                      <img
                                        src={
                                          Constant.imgurl +
                                          doctor.clinic_tbl.clinic_logo
                                        }
                                      />
                                    )
                                  ) : (
                                    <img src="./images/patient/img/Profile/hospital.svg" />
                                  )}
                                  {doctor.clinic_tbl ? (
                                    <h5>{doctor.clinic_tbl.clinic_name}</h5>
                                  ) : (
                                    ""
                                  )}
                                  <h5>{doctor.clinic_tbl.address}</h5>
                                  {doctor.clinic_tbl ? (
                                    <p
                                      id={doctor.lat_long}
                                      onClick={this.openMap.bind(
                                        this,
                                        doctor.clinic_tbl.latitude != null
                                          ? doctor.clinic_tbl.latitude
                                          : "",
                                        doctor.clinic_tbl.longitude != null
                                          ? doctor.clinic_tbl.longitude
                                          : ""
                                      )}
                                    >
                                      <img
                                        className="loc_img"
                                        id={doctor.lat_long}
                                        src="../images/patient/img/Doctors_List/location.svg"
                                      />{" "}
                                      {translate("Directions")}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="acc_view">
                                  <a
                                    className="btn profile_btn"
                                    id={doctor.id}
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="viewprofilecollapse"
                                    onClick={this.viewDocProfile}
                                  >
                                    {translate("View Profile")}
                                  </a>
                                  <a
                                    className="btn appoint_btn"
                                    role="button"
                                    id={doctor.id}
                                    onClick={this.bookDocAppointment}
                                  >
                                    {translate("Book an Appointment")}
                                  </a>
                                </div>
                                <div className="row">
                                  <div className="col acc_box">
                                    <div
                                      className={
                                        this.state.viewProfileOpen == doctor.id
                                          ? "multi-collapse in"
                                          : "collapse multi-collapse"
                                      }
                                      id={"viewprofilecollapse_" + doctor.id}
                                    >
                                      <div className="card card-body">
                                        <div className="panel">
                                          <div className="doctor_details">
                                            <h4>{translate("View Profile")}</h4>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 doc_set">
                                                <div className="doc_details">
                                                  <img src="../images/patient/img/Doctors_List/View Profile/registractionnew.svg" />
                                                </div>
                                                <div className="doc_nums">
                                                  <p>
                                                    {translate("Registration No")}
                                                  </p>
                                                  <h5>{doctor.registraion_no}</h5>
                                                </div>
                                              </div>
                                              <div className="col-md-6 col-sm-6 doc_set">
                                                <div className="doc_details">
                                                  <img src="../images/patient/img/Doctors_List/View Profile/feesnew.svg" />
                                                </div>
                                                <div className="doc_nums">
                                                  <p>{translate("Fees")}</p>
                                                  <h5>
                                                    {doctor.currency_symbol}{" "}
                                                    {doctor.fees}
                                                  </h5>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 doc_set">
                                                <div className="doc_details">
                                                  <img src="../images/patient/img/Doctors_List/View Profile/educationnew.svg" />
                                                </div>
                                                <div className="doc_nums">
                                                  <p>{translate("Education")}</p>
                                                  <h5>{doctor.course_name}</h5>
                                                </div>
                                              </div>
                                              <div className="col-md-6 col-sm-6 doc_set">
                                                <div className="doc_details">
                                                  <img src="../images/patient/img/Doctors_List/View Profile/translationnew.svg" />
                                                </div>
                                                <div className="doc_nums">
                                                  <p>{translate("I can speak")}</p>
                                                  <h5>{doctor.languages}</h5>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 doc_set">
                                                <div className="doc_details">
                                                  <img src="../images/patient/img/Doctors_List/Practise.svg" />
                                                </div>
                                                <div className="doc_nums">
                                                  <p>{translate("Practice")}</p>
                                                  <h5>{doctor.practice}</h5>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 doc_set">
                                                <div className="doc_details">
                                                  <img src="../images/patient/img/Doctors_List/View Profile/expnew.svg" />
                                                </div>
                                                <div className="doc_nums">
                                                  <p>
                                                    {translate("Doctor Speciality")}
                                                  </p>
                                                  <h5>{doctor.speciality_name}</h5>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        this.state.viewAppointmentOpen == doctor.id
                                          ? "multi-collapse in"
                                          : "collapse multi-collapse"
                                      }
                                      id={"viewAppointmentOpen" + doctor.id}
                                    >
                                      <div className="card card-body">
                                        <div className="panel">
                                          <div className="doc_book">
                                            <div
                                              className="row doc_row"
                                              id={"doctorid" + doctor.id}
                                            >
                                              <div className="col-md-3">
                                                <h3>
                                                  {translate(
                                                    "When do you want to consult a doctor"
                                                  )}
                                                  ?
                                                </h3>
                                              </div>
                                              <div className="col-md-9">
                                                {doctor.is_available == 1 &&
                                                  this.state.docConfirmed !=
                                                  doctor.id ? (
                                                  <a
                                                    id={
                                                      doctor.id +
                                                      "-" +
                                                      doctor.doctor_name
                                                    }
                                                    onClick={this.consultNow}
                                                  >
                                                    <p
                                                      className="consult_btn consult_btn_now"
                                                      id={
                                                        doctor.id +
                                                        "-" +
                                                        doctor.doctor_name
                                                      }
                                                    >
                                                      {translate("Consult now")}
                                                    </p>
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                                {doctor.is_available ? (
                                                  <h5 className="or_btn">Or</h5>
                                                ) : (
                                                  ""
                                                )}
                                                <a onClick={this.consultLater}>
                                                  <p
                                                    className="consult_btn"
                                                    id={doctor.id}
                                                  >
                                                    {translate("Consult later")}
                                                  </p>
                                                </a>
                                              </div>
                                            </div>

                                            <div
                                              className={
                                                this.state.sendNotification ==
                                                  doctor.id
                                                  ? "collapse-show"
                                                  : "collapse-hide"
                                              }
                                            >
                                              <div class="doc_row">
                                                <div class="col-md-12">
                                                  <div className="doc_lt ">
                                                    <img
                                                      className="doc_confirm_img"
                                                      src="../images/patient/img/Doctors_List/Doctor_confirmation.svg"
                                                    />
                                                  </div>
                                                  <div className="doc_txt ">
                                                    <h2 className="doc_confirm">
                                                      {translate(
                                                        "Please wait for doctor confirmation"
                                                      )}
                                                    </h2>
                                                    <p className="short_txt">
                                                      {translate(
                                                        "Doctor will confirm shortly"
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className={
                                                this.state.docConfirmed == doctor.id
                                                  ? "collapse-show"
                                                  : "collapse-hide"
                                              }
                                            >
                                              <div className="row doc_row">
                                                <div className="col-md-6 col-sm-6">
                                                  <h2 className="doc_confirm">
                                                    {translate("Doctor confirmed")}
                                                  </h2>
                                                  <p className="short_txt">
                                                    {translate(
                                                      "Please proceed to pay for the consultation"
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                  <a>
                                                    {" "}
                                                    <button
                                                      className="pay_now"
                                                      id={doctor.id}
                                                      onClick={this.handlePay}
                                                    >
                                                      {translate("Pay Now")}
                                                    </button>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            id={doctor.id}
                                            className={
                                              this.state.showSlots == doctor.id
                                                ? "doc_calendar collapse-show"
                                                : "doc_calendar collapse-hide"
                                            }
                                          >
                                            <h2 className="sch_appoint">
                                              <img src="../images/patient/img/Doctors_List/Schedule_appointment.svg" />{" "}
                                              {translate(
                                                "Schedule your appointment"
                                              )}
                                            </h2>
                                            <div class="row">
                                              <div class="col-lg-6 col-md-6">
                                                <div className="wrapper">
                                                  <DatePicker
                                                    name="start_date"
                                                    autoComplete="off"
                                                    id={doctor.id}
                                                    className="dateInput"
                                                    placeholderText="Start Date"
                                                    onChange={
                                                      this.getUserSelectedDate
                                                    }
                                                    dateFormat="d-MM-yyyy"
                                                    highlightDates={[
                                                      {
                                                        "react-datepicker__day--highlighted":
                                                          new Array(
                                                            this.state.numberOfDays
                                                          )
                                                            .fill()
                                                            .map((_, i) => {
                                                              let fromdate = moment(
                                                                this.state
                                                                  .doctorSlotsfromdate
                                                              ).format(
                                                                "YYYY-MM-DD"
                                                              );
                                                              const d = new Date(
                                                                fromdate
                                                              );
                                                              d.setDate(
                                                                d.getDate() + i
                                                              );
                                                              return d;
                                                            }),
                                                      },
                                                    ]}
                                                    inline
                                                    calendarIcon
                                                    minDate={
                                                      this.state.doctorSlotsfromdate
                                                    }
                                                    maxDate={
                                                      this.state.doctorSlotstodate
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: slotsMorningArray,
                                                  }}
                                                />

                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: slotsNoonArray,
                                                  }}
                                                />

                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: slotsEveningArray,
                                                  }}
                                                />

                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: slotsNightArray,
                                                  }}
                                                />
                                              </div>

                                              <div
                                                className={this.state.processlater}
                                              >
                                                <div className="next_btn text-right ">
                                                  <button
                                                    type="submit"
                                                    onClick={this.consultLaterPay}
                                                    id={doctor.id}
                                                    className="btn btn-default Next_btn_profile prof_btn"
                                                  >
                                                    {translate("Proceed")}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )) : <div> <span style={{ color: "red" }}>Doctor is not available. </span> </div>}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ToastContainer />
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
