import React, { Component, useState } from "react";
import { Slider } from "react-semantic-ui-range";

import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

//import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { Dropdown } from "semantic-ui-react";
import { Multiselect } from "multiselect-react-dropdown";
import Search from "./search";
import * as SecureLS from "secure-ls";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import constants from "../../constants";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class Diagnostictestdetail extends Component {
  constructor(props) {
    super(props);
    //const appointmentId = localStorage.getItem("appointment_id");
    const appointmentId = ls.get("appointmentIdEnc");
    this.state = {
      patientId: "",
      medicineOptions: "",
      medicineProductsView: "",
      home: "collapse-show",
      searchproductslist: "collapse-show",
      remove_icon: "remove_icon collapse-hide clear_icon",
      search_address: "",
      detected_address: "",
      cartId: "",
      cartItemsCount: "0",
      cartDetailsPopup: "",
      cartDetailsPage: "",
      cartDetailsPageSection: "collapse-hide",
      mostPopularTests: "",
      suggestedProducts: "",
      ordersView: "",
      manufacturers: "",
      brandOptions: [],
      formOptions: [],
      downloadSection: "collapse-show",
      selectedProductName: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.fetchmedicinedata = this.fetchmedicinedata.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;

    let userData = "";
    let prodcutDesId = "";
    userData = ls.get("userObjEnc");
    let cartData = "";

    cartData = ls.get("cartIdEnc");
    this.setState({
      isDisabled: false,
      disabledRequired: false,
    });
    var retrievedObject = ls.get("userObjEnc");
    if (cartData != "") {
      this.state.cartId = cartData;
      this.forceUpdate();
    }
    var savedAddress = "";
    savedAddress = ls.get("detected_address");

    if (savedAddress !== "") {
      this.setState({
        detected_address: savedAddress,
      });
    } else {
      this.getCurrentLocation();
    }

    if (userData) {
      if (Object.keys(userData).length > 0) {
        this.state.patientId = userData.patient_id;
        this.forceUpdate();
        //this.getLasterOrderDetails(userData.patient_id);
      }
    }
    prodcutDesId = localStorage.getItem("OrderMedicineId");
    this.state.prodcutDesId = prodcutDesId;

    if (prodcutDesId != null) {
      prodcutDesId = prodcutDesId;
    } else {
      prodcutDesId = this.props.match.params.id;
    }

    this.getProductDescription(prodcutDesId);
    this.getCartDetails();
    //this.getCurrentLocation();

    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=" + Constant.googleAPiKey;
    document.head.appendChild(script);
  };

  onFormSubmit(event) {
    event.preventDefault();
    // alert(" Vitals Form Submit Called");
    const { handle } = this.props.match.params;
    // alert(handle + " handle " + this.handleValidation());
    // alert($("#main_check").is(":checked"));

    let checkboxLength = $(".blue_check").length;
    let checkboxCheckedLength = $(".blue_check:checked").length;
    //alert(checkboxCheckedLength);
    //if($("#main_check").is(":checked")==false){

    if (checkboxCheckedLength == 0) {
      window.location.href = "./" + this.state.nextPage;
    } else {
      // alert("in");

      if (this.handleValidation() && handle) {
        this.createPatientVitals(event);
      } else if (this.handleValidation() && handle == undefined) {
        this.createPatientVitals(event);
      } else {
        toast.warn("Form has errors.");
      }
    }
  }
  fetchmedicinedata(event) {
    let pincode = "";
    pincode = ls.get("pinCodeEnc");
    if (pincode == "") {
      this.showDialog("search");
      return;
    }
    let search = event.target.value;
    let medicinesList = [];
    if (search.length > 0) {
      this.state.remove_icon = "remove_icon collapse-show";
      this.forceUpdate();
    } else {
      this.state.remove_icon = "remove_icon collapse-remove_icon";
      this.forceUpdate();
    }

    if (search.length > 2) {
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/MedicineDetails/MedicineSearchDetails",
        { medicine_name: search, patient_id: this.state.patientId }
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.result).length;
        this.state.search = search;
        if (Object.keys(response.data.result).length > 0) {
          const medicineDetailsView = response.data.result.map(
            (finalLoadedData, num) => {
              return (
                <li
                  class="search-li"
                  id={finalLoadedData.id}
                  onClick={this.handleChange}
                >
                  {" "}
                  {finalLoadedData.testname}
                </li>
              );
            }
          );

          this.state.medicineOptions = "";
          this.state.medicineOptions = medicineDetailsView;
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          // this.state.medicineProductsView = "";
        }
      });
    } else {
      $(".clear_icon").hide();
      this.state.medicineOptions = "";
      // this.state.medicineProductsView = "";
      this.state.search = search;
      this.state.searchCount = 0;
      this.forceUpdate();
    }
  }

  qtyIncrement = (event) => {
    let data = event.currentTarget.id.split("-");
    let id = data[0];
    let orderId = data[1];
    let qty = $("#qty-" + orderId).val();

    if (qty >= 0 && qty < 50) {
      qty = parseInt(qty) + 1;
      $("#qty-" + orderId).val(qty);
    } else {
      if (qty >= 50) {
        toast.error("Qunatity should not be more that 50");
        return false;
      }
    }
  };

  // quanatity Increment
  qtyDecrement = (event) => {
    let data = event.currentTarget.id.split("-");
    let id = data[0];
    let orderId = data[1];
    let qty = $("#qty-" + orderId).val();
    if (qty > 0) {
      qty = parseInt(qty) - 1;
      $("#qty-" + orderId).val("");
      $("#qty-" + orderId).val(qty);
    }
  };

  addToCart = (event) => {
    let pincode = "";
    pincode = ls.get("pinCodeEnc");
    if (pincode == "") {
      this.showDialog("search");
      return;
    }
    let testId = event.currentTarget.id;

    let quantity = $("#qty-" + testId).val();
    $("#add_to_cart-" + testId).show();
    $(".tests_btn" + testId).hide();
    let cartId = "";
    let cartItemsCount = this.state.cartItemsCount;
    if (quantity == 0) {
      toast.error("Quantity should not be Zero");
      return false;
    }
    if (this.state.cartId) {
      cartId = this.state.cartId;
    }
    if (quantity > 50) {
      toast.error("Quantity should not be more than 50");
      return false;
    }
    if (cartItemsCount >= 30) {
      toast.error("You can add medicines upto 30 only for an order");
      return false;
    }

    Httpconfig.httptokenpost(Constant.siteurl + "api/DIAGNOSIS/Cart", {
      cart_id: cartId,
      shop_id: 0,
      patient_id: this.state.patientId,
      medicine_id: testId,
      quantity: quantity,
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (cartId == "") {
            this.state.cartId = response.data.cart_id;
            this.state.cartItemsCount = response.data.cart_items;

            this.forceUpdate();

            ls.set("cartIdEnc", response.data.cart_id);
          } else {
            if (this.state.cartId != response.data.cart_id) {
              this.state.cartId = response.data.cart_id;
              this.state.cartItemsCount = response.data.cart_items;
              ls.set("cartIdEnc", response.data.cart_id);
              this.forceUpdate();
            }
          }
          this.getCartDetails();
          toast.success(response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.dismiss();
          toast.error(response.data.message, {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    $("#order_pro_toast").show();
    setTimeout(function () {
      $("#order_pro_toast").hide();
    }, 3000);
  };
  getProductDetailsById = (productId) => {
    if (productId != "") {
      Httpconfig.httptokenget(
        Constant.siteurl + "api/DIAGNOSIS/productMaster/fetchdata/" + productId
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.data).length;

        this.setState({
          selectedProductName: response.data.data[0].testname,
        });
        if (Object.keys(response.data.data).length > 0) {
          const medicineProductsView = response.data.data.map(
            (product, num) => {
              var testID = product.testid;

              //this.state.selectedProductName = product.medicinename
              // console.log("--318--", medicineID);
              this.getAlternateMedicine(testID);
              return (
                <div className="col-md-8">
                  <div className="testdetail_block">
                    <div className="testdetail_decription">
                      <div className="testtitle_block">
                        <div className="test_title">
                          <h5>{product.testname}</h5>
                          <p> {product.description}</p>
                        </div>
                        <div className="testdetail_price">
                          <p className="main_price">
                            {" "}
                            {Constant.currency}{" "}
                            {product.discount
                              ? parseFloat(product.mrp) -
                                (product.mrp * product.discount) / 100
                              : product.mrp}
                          </p>
                          <span className="discount_price">
                            <del>
                              {product.discount
                                ? Constant.currency + "" + product.mrp
                                : ""}
                            </del>{" "}
                            {product.discount
                              ? product.discount + " % off"
                              : ""}
                          </span>
                        </div>
                      </div>
                      {/* <div className="testtitle_alt">
                        <span>Also known as:</span>
                        <p>
                          Complete Blood Picture, Complete Blood Profile, CBP
                        </p>
                      </div> */}
                      <div className="last_cart">
                        <div className="testdetail_cat">
                          <span className="test_cat">Category:</span>
                          <span className="testcat_name"> Lab Test</span>
                        </div>
                        <div className="testdetail_cart">
                          <button
                            // className="addedtocart"
                            class={"addedtocart tests_btn" + product.id}
                            id={product.id}
                            onClick={this.addToCart}
                          >
                            Add to Cart
                          </button>
                          <button
                            className="removecart"
                            id={"add_to_cart-" + product.id}
                            style={{ display: "none" }}
                          >
                            <img src="/images/patient/img/diagnostics/checkmark.svg" />
                            Added
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="testdetail_singletest">
                      <div className="singletest_type">
                        <span className="type">Sample Type:</span>
                        <span className="type_title">{product.form}</span>
                      </div>
                      <div className="singletest_preparations">
                        <span className="preparations">Preparations:</span>
                        <span className="preparations_title">
                          {" "}
                          Fasting from early morning
                        </span>
                      </div>
                      <div className="singletest_about">
                        <span className="about">About:</span>
                        <p className="about_description" id="description_about">
                          N/A
                        </p>
                        {/* <div className="read_option">
                          <span id="readmore_about">Read More</span>
                        </div> */}
                      </div>
                    </div>

                    <div className="testdetail_packagetest">
                      <div className="package_total">
                        <h5>Total Tests Included</h5>
                      </div>
                      <div className="package_list" id="package_description">
                        <ol>
                          <li>N/A</li>
                        </ol>
                      </div>
                      {/* <div className="read_option">
                        <span id="readmore_packagelist">Read More</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            }
          );
          this.setState({
            medicineProductsView: medicineProductsView,
          });
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          this.state.medicineProductsView = "";
          this.forceUpdate();
        }
      });
    } else {
      this.state.medicineOptions = "";
      this.state.medicineProductsView = "";
      this.forceUpdate();
    }
  };
  getCartDetails = () => {
    let cartId = "";
    cartId = this.state.cartId;

    let total = 0;
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/Cart/findAllCartDetails/" + cartId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.cartItemsCount = response.data.cart_count;
          this.forceUpdate();
          const cartDetailsView = response.data.data.map((LoadedData, num) => {
            total =
              parseInt(total) +
              LoadedData.diagnosis_products_master_tbl.mrp *
                LoadedData.quantity;

            return (
              <React.Fragment>
                <p>
                  {LoadedData.diagnosis_products_master_tbl.testname}{" "}
                  <span>Qty: {LoadedData.quantity}</span>
                </p>
              </React.Fragment>
            );
          });
          this.state.cartTotal = total;
          this.state.cartDetailsPopup = cartDetailsView;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  getProductDiscription = (product_id) => {
    // window.location.href = "/productdescription" + "?id=" + product_id;
    if (product_id != "") {
      window.open("/Diagnostictestdetail" + "?id=" + product_id, "_blank");
      localStorage.setItem("OrderMedicineId", product_id);
    }
  };

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");

    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        // console.log("notnull " + this.state.Language);
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
      //console.log(this.state.Language);
    }
  };

  // For handling address
  handleAddressChange = (search_address) => {
    //this.setState({ search_address });
    this.setState({ detected_address: search_address });
  };

  // For set the latlong for address
  setSearchAddressLatLong = (lat, Lng) => {
    let search_address_latitude = lat;
    let search_address_longitude = Lng;
    this.setState({
      search_latitude: search_address_latitude,
      search_longitude: search_address_longitude,
    });
    ls.set("search_latitudeEnc", search_address_latitude);
    ls.set("search_longitudeEnc", search_address_longitude);
  };

  handleAddressSelect = (search_address, placeId) => {
    geocodeByPlaceId(placeId).then((place) => {
      geocodeByAddress(search_address)
        .then((results) => {
          var res = getLatLng(results[0]);
          var lat = results[0].geometry.location.lat();
          var lang = results[0].geometry.location.lng();
          this.setSearchAddressLatLong(lat, lang);
          this.setState({ detected_address: search_address });
          ls.set("detected_address", search_address);
          this.displayPostcode(place[0].address_components);
          //  console.log(results);
        })

        // .then((results) => this.setSearchAddressLatLong(results))
        .catch((error) => console.error("Error", error));
    });
  };

  displayPostcode = (address) => {
    for (let p = address.length - 1; p >= 0; p--) {
      if (address[p].types.indexOf("postal_code") != -1) {
        Httpconfig.httptokenget(
          Constant.siteurl +
            "api/DIAGNOSIS/deliveryAddress/getpincode/" +
            address[p].long_name
        )
          .then((response) => {
            if (
              response.data.status == "200" &&
              response.data.isPinCodeAvailable == false
            ) {
              this.showDialog("cart");
              // this.setState({ detected_address: "", search_address: "" });
              // ls.remove("detected_address");
              // ls.remove("detected_addressEnc");
              // ls.remove("pinCodeEnc");
            } else {
              ls.set("pinCodeEnc", address[p].long_name);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
  };

  //get current location
  getCurrentLocation = () => {
    var placeIds = "";
    var self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var streetaddress = "";

        var url =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&key=" +
          Constant.googleAPiKey;
        $.getJSON(url, function (data, textStatus) {
          placeIds = data.results[0].place_id;

          if (data.results.length > 0) {
            streetaddress = data.results[0].formatted_address;
            ls.set("detected_addressEnc", streetaddress);
            ls.set("detected_address", streetaddress);
            self.handleAddressSelect(streetaddress, placeIds);
          }
        });
      });
      let detected_address = ls.get("detected_addressEnc");
      if (detected_address) {
        this.setState({ detected_address: detected_address });
        this.handleAddressSelect(detected_address, placeIds);
      }
    }
  };
  // For address select
  // handleAddressSelect = (search_address) => {
  //   geocodeByAddress(search_address)
  //     .then((results) => {
  //       var res = getLatLng(results[0]);
  //       var lat = results[0].geometry.location.lat();
  //       var lang = results[0].geometry.location.lng();
  //       this.setSearchAddressLatLong(lat, lang);
  //       this.setState({ detected_address: search_address });
  //       ls.set("detected_address", search_address);
  //     })
  //     .catch((error) => console.error("Error", error));
  // };

  // getCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       var streetaddress = "";
  //       var url =
  //         "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
  //         position.coords.latitude +
  //         "," +
  //         position.coords.longitude +
  //         "&key=" +
  //         Constant.googleAPiKey;
  //       $.getJSON(url, function (data, textStatus) {
  //         if (data.results.length > 0) {
  //           streetaddress = data.results[0].formatted_address;
  //           ls.set("detected_addressEnc", streetaddress);
  //           ls.set("detected_address", streetaddress);
  //         }
  //       });
  //     });

  //     let detected_address = ls.get("detected_addressEnc");
  //     if (detected_address) {
  //       this.setState({ detected_address: detected_address });
  //       this.handleAddressSelect(detected_address);
  //     }
  //   }
  // };
  // select retailer
  selectRetailer = () => {
    let patientId = this.state.patientId;
    if (patientId) {
      window.location.href = Constant.imgurl + "/Diagnosticcart";
    }
  };

  getProductDescription = (productId) => {
    if (productId != "") {
      Httpconfig.httptokenget(
        Constant.siteurl + "api/DIAGNOSIS/productMaster/fetchdata/" + productId
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.data).length;
        if (Object.keys(response.data.data).length > 0) {
          const medicineProductsView = response.data.data.map(
            (product, num) => {
              var testID = product.testid;
              this.getAlternateMedicine(testID);
              return (
                <React.Fragment>
                  <div className="col-md-8">
                    <div className="testdetail_block">
                      <div className="testdetail_decription">
                        <div className="testtitle_block">
                          <div className="test_title">
                            <h5>{product.testname}</h5>
                            {/* <p> {product.description}</p> */}
                          </div>
                          <div className="testdetail_price">
                            <p className="main_price">
                              {" "}
                              {Constant.currency}{" "}
                              {product.discount
                                ? parseFloat(product.mrp) -
                                  (product.mrp * product.discount) / 100
                                : product.mrp}
                            </p>
                            <span className="discount_price">
                              <del>
                                {product.discount
                                  ? Constant.currency + "" + product.mrp
                                  : ""}
                              </del>{" "}
                              {product.discount
                                ? product.discount + " % off"
                                : ""}
                            </span>
                          </div>
                        </div>
                        {/* <div className="testtitle_alt">
                          <span>Also known as:</span>
                          <p>
                            Complete Blood Picture, Complete Blood Profile, CBP
                          </p>
                        </div> */}
                        <div className="last_cart">
                          <div className="testdetail_cat">
                            <span className="test_cat">Category:</span>
                            <span className="testcat_name"> Lab Test</span>
                          </div>
                          <div className="testdetail_cart">
                            <button
                              // className="addedtocart"
                              class={"addedtocart tests_btn" + product.id}
                              id={product.id}
                              onClick={this.addToCart}
                            >
                              Add to Cart
                            </button>
                            <button
                              className="removecart"
                              id={"add_to_cart-" + product.id}
                              style={{ display: "none" }}
                            >
                              <img src="/images/patient/img/diagnostics/checkmark.svg" />
                              Added
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="testdetail_singletest">
                        <div className="singletest_type">
                          <span className="type">Sample Type:</span>
                          <span className="type_title">{product.form}</span>
                        </div>
                        <div className="singletest_preparations">
                          <span className="preparations">Preparations:</span>
                          <span className="preparations_title">
                            {" "}
                            Fasting from early morning
                          </span>
                        </div>
                        <div className="singletest_about">
                          <span className="about">About:</span>
                          <p
                            className="about_description"
                            id="description_about"
                          >
                            N/A
                          </p>
                          {/* <div className="read_option">
                            <span id="readmore_about">Read More</span>
                          </div> */}
                        </div>
                      </div>

                      <div className="testdetail_packagetest">
                        <div className="package_total">
                          <h5>Total Tests Included</h5>
                        </div>
                        <div className="package_list" id="package_description">
                          <ol>
                            <li>{product.description}</li>
                          </ol>
                        </div>
                        {/* <div className="read_option">
                          <span id="readmore_packagelist">Read More</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          );
          this.setState({
            medicineProductsView: medicineProductsView,
          });
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          this.state.medicineProductsView = "";
          this.forceUpdate();
        }
      });
    } else {
      this.state.medicineOptions = "";
      this.state.medicineProductsView = "";
      this.forceUpdate();
    }
  };

  getAlternateMedicine = (testID) => {
    var postData = {
      medicineId: testID,
    };
    if (postData != "") {
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/MedicineDetails/alternativeMedicines/",
        postData
      ).then((response) => {
        console.log("--1953--", response.data.result);

        const alerternateMedicine = response.data.result.map(
          (LoadedData, num) => {
            var testID = LoadedData.testid;
            return (
              <div className="relatedtests_block">
                <div className="relatedtests_build">
                  <div className="relatedtests_img">
                    {/* <img src="./images/patient/img/diagnostics/testimage.svg"></img> */}
                    <img src={Constant.imgurl + LoadedData.image} />
                  </div>
                  <div className="relatedtests_heading">
                    <h5
                      onClick={this.getProductDiscription.bind(
                        this,
                        LoadedData.id
                      )}
                    >
                      {LoadedData.testname}
                    </h5>
                  </div>
                  <div className="relatedtests_des">
                    <p>{LoadedData.description}</p>
                  </div>
                  <div className="relatedtests_include">
                    <span>Includes 65 tests</span>
                  </div>
                  <div className="relatedtests_footer">
                    <div className="relatedtests_price">
                      <p className="main_price">
                        {Constant.currency}{" "}
                        {LoadedData.discount
                          ? parseFloat(LoadedData.mrp) -
                            (LoadedData.mrp * LoadedData.discount) / 100
                          : LoadedData.mrp}
                      </p>
                      <span className="discount_price">
                        <del>
                          {LoadedData.discount
                            ? Constant.currency + "" + LoadedData.mrp
                            : ""}
                        </del>{" "}
                        {LoadedData.discount
                          ? LoadedData.discount + " % off"
                          : ""}
                      </span>
                    </div>
                    <div className="relatedtests_cart">
                      <button
                        class={"addedtocart tests_btn" + LoadedData.id}
                        id={LoadedData.id}
                        onClick={this.addToCart}
                      >
                        Add to Cart
                      </button>
                      <button
                        className="removecart"
                        id={"add_to_cart-" + LoadedData.id}
                        style={{ display: "none" }}
                      >
                        <img src="/images/patient/img/diagnostics/checkmark.svg" />
                        Added
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        );
        this.setState({
          alerternateMedicine: alerternateMedicine,
        });
        this.forceUpdate();
      });
    } else {
      this.state.alerternateMedicine = "";
      this.forceUpdate();
    }
  };

  miniCart = () => {
    let cartId = this.state.cartId;
    let total = 0;
    let productTotal = 0;

    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/Cart/findAllCartDetails/" + cartId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          console.log("--544--", response);
          this.state.cartItemsCount = response.data.cart_count;
          this.forceUpdate();

          const cartDetailsPage = response.data.data.map((LoadedData, num) => {
            productTotal = 0;
            total =
              parseFloat(total) +
              LoadedData.diagnosis_products_master_tbl.mrp *
                LoadedData.quantity;
            productTotal =
              parseFloat(productTotal) +
              LoadedData.diagnosis_products_master_tbl.mrp *
                LoadedData.quantity;

            return (
              <div class="cart_list_box">
                <div class="row">
                  <div class="col-md-2">
                    <div class="cart_img">
                      <img
                        src={
                          LoadedData.diagnosis_products_master_tbl.image != null
                            ? Constant.imgurl +
                              LoadedData.diagnosis_products_master_tbl.image
                            : this.getProductImage(
                                LoadedData.diagnosis_products_master_tbl.form
                              )
                        }
                      />
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="cart_item_det">
                      <h2>
                        {LoadedData.diagnosis_products_master_tbl.testname +
                          " " +
                          LoadedData.diagnosis_products_master_tbl.strength}
                      </h2>
                      <p>
                        {" "}
                        <span class="mrp">
                          MRP:{Constant.currency}{" "}
                          {LoadedData.diagnosis_products_master_tbl.mrp}
                        </span>
                        <span>
                          {Constant.currency}{" "}
                          {productTotal
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </span>
                      </p>
                      <h4>{LoadedData.diagnosis_products_master_tbl.size}</h4>
                      <h6>
                        {LoadedData.diagnosis_products_master_tbl.manufacturer}
                      </h6>
                      {/* 
                      <div class="input-group number-spinner">
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default minus_btn"
                            data-dir="dwn"
                            id={"minus-" + LoadedData.medicine_id}
                            onClick={this.qtyDecrementUpdate.bind(
                              this,
                              LoadedData.medicine_id,
                              LoadedData.id
                            )}
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                        </div>
                        <input
                          type="text"
                          class="form-control text-center"
                          readOnly="readOnly"
                          value={LoadedData.quantity}
                          id={"qty-" + LoadedData.medicine_id}
                        />
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default plus_btn"
                            data-dir="up"
                            id={"plus-" + LoadedData.medicine_id}
                            onClick={this.qtyIncrementUpdate.bind(
                              this,
                              LoadedData.medicine_id,
                              LoadedData.id
                            )}
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="cart_item_btm">
                  <a
                    href="#"
                    id={LoadedData.id}
                    onClick={this.removeItem.bind(this, LoadedData.id)}
                  >
                    <p>Remove</p>
                  </a>
                  <a
                    href="#"
                    id={LoadedData.medicine_id}
                    onClick={this.saveForLater}
                  >
                    <h3>
                      {" "}
                      <img src="../images/patient/img/Ordermedicine/saveforLater.svg" />{" "}
                      Save For Later
                    </h3>
                  </a>
                </div>
              </div>
            );
          });
          this.state.cartDetailsPageSection = "collapse-show";
          this.state.home = "collapse-hide";
          this.state.downloadSection = "collapse-hide";
          this.state.searchproductslist = "collapse-hide";
          this.state.cartTotal = total;
          this.state.cartDetailsPage = cartDetailsPage;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  handleChange = (event) => {
    let searched = event.currentTarget.id;
    this.getProductDetailsById(searched);
    let testName = event.currentTarget.innerText;
    this.state.selelcteMedicineName = testName;
    this.state.home = "collapse-hide";
    this.state.searchproductslist = "collapse-show";
    this.state.cartDetailsPageSection = "collapse-hide";
    this.state.downloadSection = "collapse-hide";

    $("#medicineList").val(testName);
    this.state.search = testName;
    this.state.medicineOptions = "";
    this.forceUpdate();
  };

  // show popup dialog
  showDialog = (type) => {
    if (type == "search") {
      this.setState({
        dialogText: "Please enter area / pincode",
      });
    } else {
      this.setState({
        dialogText: "Our Services are not available in given area / pincode",
      });
    }
    $("#noloc_Modal").show();
  };

  //close postal code popup
  closePopup = () => {
    this.setState({ detected_address: "", search_address: "" });
    ls.remove("detected_address");
    ls.remove("detected_addressEnc");
    ls.remove("pinCodeEnc");
    $("#noloc_Modal").hide();
  };

  render() {
    const {
      isDisabled,
      disabledRequired,
      isParentChecked,
      fields,
      tempDisplayText,
    } = this.state;
    let patient_id = "";

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />

        <I18nPropvider locale={this.state.Language}>
          <section id="order_medicine_section">
            <div class="order_search">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <PlacesAutocomplete
                      value={
                        this.state.detected_address
                          ? this.state.detected_address
                          : this.state.search_address
                      }
                      onChange={this.handleAddressChange}
                      onSelect={this.handleAddressSelect}
                      searchOptions={{
                        componentRestrictions: { country: "IN" },
                      }}
                      shouldFetchSuggestions={
                        this.state.detected_address.length > 3
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="ord_search">
                          <input
                            {...getInputProps({
                              placeholder: "Enter your Area name / pincode",
                              className: "location-search-input order_select",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>

                    {/* <div class="input-group-btn detect_btn">
                      <button
                        class="btn btn-default"
                        type=""
                        onClick={this.getCurrentLocation}
                      >
                        <img
                          class="det_img"
                          src="../images/patient/img/Ordermedicine/detectLocation.svg"
                        />
                        Detect
                      </button>
                    </div> */}
                    <span class="loc_img" onClick={this.getCurrentLocation}>
                      <img src="../images/patient/img/Ordermedicine/locationIcon.svg" />
                    </span>
                  </div>

                  <div class="col-md-7">
                    <Search
                      searchQuery={this.fetchmedicinedata}
                      setSearchQuery={this.state.medicineOptions}
                    />
                  </div>

                  {/* <div class="col-md-7">
                    <div class="order_input">
                      <input
                        type="text"
                        class="form-control"
                        id="medicineList"
                        placeholder="Search Diagnostics Tests"
                        onKeyUp={this.fetchmedicinedata}
                        autocomplete="off"
                      />
                      <img
                        class="search_img"
                        src="../images/patient/img/Ordermedicine/search.svg"
                      /> */}
                  {/* <img
                        class="remove_icon"
                        src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                        onClick={this.handleClear}
                      /> */}
                  {/* <span>
                        <img
                          class="search_img"
                          src="../images/patient/img/Ordermedicine/search.svg"
                        />
                      </span> */}
                  {/* <ul id="searchResult">{this.state.medicineOptions}</ul>
                    </div>
                  </div> */}
                  {/* <div class="col-md-2 order_bar">
                    <button type="button">Search</button>
                 <a href="#" onClick={this.miniCart}>
                      <img
                        class="cart_ico"
                        src="../images/patient/img/Ordermedicine/shopping-cart.svg"
                      />
                      <div class="cart_bar">{this.state.cartItemsCount}</div>
                      <span>cart</span>
                    </a> 
                  </div> */}
                  <div class="col-md-2 order_cart" id="all_cart_item">
                    <a href="#" onClick={this.selectRetailer}>
                      <img
                        class="cart_img"
                        src={constants.imgurl + "/images/patient/img/cart.svg"}
                      />
                      <div class="cart_bar">{this.state.cartItemsCount}</div>
                    </a>
                  </div>
                  <div
                    class="col-md-2 order_cart"
                    id="cart_items"
                    style={{ display: "none" }}
                  >
                    <a href="#">
                      <img
                        class="cart_ico"
                        src="../images/patient/img/Ordermedicine/shopping-cart.svg"
                      />
                      <div class="cart_bar">0</div>
                      <span>cart</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="test_detail" class={this.state.searchproductslist}>
            <div className="container">
              <div className="row">
                {this.state.medicineProductsView}
                <div className="col-md-4">
                  <div className="relatedtests_block">
                    <div className="related_header">
                      {/* {this.state.alerternateMedicine != "" ? (
                        <h5>Related Packages</h5>
                      ) : (
                        
                        <h5>No related packages</h5>
                      )} */}
                    </div>
                    {this.state.alerternateMedicine}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="order_pro_toast" style={{ display: "none" }}>
            <div class="order_toast_head">
              <h2>Order Summary</h2>
              {/* <div class="toast_amnt">
                <h6>cart price</h6>
                <h3>
                  {Constant.currency}{" "}
                  {this.state.cartTotal
                    ? this.state.cartTotal
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    : ""}
                </h3>
              </div> */}
            </div>
            <div class="order_toast_content">
              {this.state.cartDetailsPopup}
              <h2>
                +<span>{this.state.cartItemsCount}</span>Items
              </h2>
              <div class="toast_cart_btn">
                <a href="#" onClick={this.selectRetailer}>
                  <p>Go to Cart</p>
                </a>
              </div>
            </div>
          </div>
          <section id="order_cart" class={this.state.cartDetailsPageSection}>
            <div class="order_cart_sec">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="order_cart_head">
                      <h2>Review Your Cart</h2>
                    </div>
                  </div>
                </div>
                <div class="order_cart_content">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="price_head">
                        <h2>Items</h2>
                      </div>

                      {Object.keys(this.state.cartDetailsPage).length > 0
                        ? this.state.cartDetailsPage
                        : "No medicines found"}

                      {Object.keys(this.state.cartDetailsPage).length > 0 ? (
                        this.state.patientId ? (
                          <div class="cart_ret_btn">
                            <a href="#" onClick={this.selectRetailer}>
                              <p>Continue</p>
                            </a>
                          </div>
                        ) : (
                          <div class="cart_ret_btn">
                            <a href="#" onClick={this.loginRedirect}>
                              <p>Login to Continue shopping</p>
                            </a>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* modal for no delivery */}
          <div class="modal fade" id="noloc_Modal">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body">
                  <img
                    class="loc-img"
                    src="../images/patient/img/Ordermedicine/address-book.svg"
                  ></img>
                  <p>{this.state.dialogText}</p>
                  <button
                    type="button"
                    className="close_btn"
                    data-dismiss="modal"
                    onClick={this.closePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* end modal for no delivery */}
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
