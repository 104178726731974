import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";

export default class ViewDoctorAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      datatable: {
        columns: [
          {
            label: "S.No",
            field: "medicineid",
            sort: "asc",
            width: 150,
          },
          {
            label: "Admin Name ",
            field: "name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Admin Mobile Number ",
            field: "mobile_number",
            sort: "asc",
            width: 150,
          },
          {
            label: "Admin Email",
            field: "emaild",
            sort: "asc",
            width: 150,
          },
          {
            label: "Doctor Name",
            field: "doctor_name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Doctor Email",
            field: "email",
            sort: "asc",
            width: 150,
          },
          {
            label: "Doctor Mobile Number",
            field: "mobile_no",
            sort: "asc",
            width: 150,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
      },
    };
    this.deleteDoctorAdmin = this.deleteDoctorAdmin.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.fetchDoctorAdmindata();
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  //  For getting the data
  fetchDoctorAdmindata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/AdminDoctors/")
      .then((response) => {
        let assignvalues = [];
        let dataLength = Object.keys(response.data.data).length;
        if (dataLength > 0) {
          this.setState({
            page_data: response.data.data,
          });

          this.items = response.data.data.map((item, key) =>
            item.status != 4 && item.tbl_user
              ? assignvalues.push({
                medicineid: key + 1,
                name: item.tbl_user.name,
                mobile_number: item.tbl_user.mobile_number,
                emaild: item.tbl_user.email,
                doctor_name: item.doctor_tbl.doctor_name,
                mobile_no: item.doctor_tbl.mobile_no,
                email: item.doctor_tbl.email,
                actions: (
                  <div>
                    <span>
                      <i
                        onClick={() => this.editAdmin(item.id)}
                        className="fa fa-edit point-cursor"
                        title={"Accepted " + item.medicine_name}
                      ></i>
                    </span>{" "}
                    &nbsp;
                    <span>
                      <i
                        onClick={() =>
                          this.deleteDoctorAdmin(item.id, item.tbl_user.name)
                        }
                        className="fa fa-trash point-cursor"
                        title={"Delete " + item.id}
                      ></i>
                    </span>{" "}
                    &nbsp;
                  </div>
                ),
              })
              : ""
          );
        }
        if (assignvalues.length == 0) {
        }

        let retailerState = Object.assign({}, this.state);
        retailerState.datatable.rows = assignvalues;
        this.setState(retailerState);
      })
      .catch((error) => {
        console.log("---135----", error)
      });
  }
  // Redirect to image upload page
  uploadImage(id) {
    this.props.history.push("/admin/UploadImages/" + id);
  }

  // editProducts
  editAdmin(id) {
    this.props.history.push("/admin/CreateDoctorAdmin/" + id);
  }

  //delete controller
  deleteDoctorAdmin(Id, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/AdminDoctors/AdminDoctordelete/" + Id
      )
        .then((response) => {
          toast.success("Successfully Deleted Doctor Admin");

          this.fetchDoctorAdmindata();
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => { });
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link>&gt; Clinic Setup &gt;
                      View Doctor Admin
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3> View Doctor Admin</h3>
                      </div>
                      <div id="reg_form">
                        <form>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <Link
                                to="/admin/CreateDoctorAdmin"
                                className="btn  btn-primary fright"
                              >
                                Create Doctor Admin
                              </Link>
                            </div>
                          </div>
                        </form>
                        {datatable.rows.length === 0 ? (
                          <p>No Data</p>
                        ) : (
                          <MDBDataTable
                            striped
                            responsive
                            bordered
                            small
                            data={datatable}
                          />
                        )}

                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="update_btn"
                              style={{ textAlign: "right" }}
                            ></div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
