import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";

export default class ViewProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      datatable: {
        columns: [
          {
            label: "S.No",
            field: "medicineid",
            sort: "asc",
            width: 150,
          },
          {
            label: "Name ",
            field: "medicine_name ",
            sort: "asc",
            width: 150,
          },
          {
            label: "Manufacturer ",
            field: "medicine_main ",
            sort: "asc",
            width: 150,
          },
          {
            label: "Type",
            field: "medicine_type",
            sort: "asc",
            width: 150,
          },
          {
            label: "Strength",
            field: "medicine_strength",
            sort: "asc",
            width: 150,
          },
          {
            label: "UOM",
            field: "medicine_size",
            sort: "asc",
            width: 150,
          },
          {
            label: "MRP",
            field: "mrp",
            sort: "asc",
            width: 150,
          },
          {
            label: "Image",
            field: "image",
            sort: "asc",
            width: 150,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
      },
    };
    this.deleteproductRequest = this.deleteproductRequest.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.fetchpagesdata();
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //  For getting the data
  fetchpagesdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/productsMaster/")
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) =>
          item.status != 4
            ? assignvalues.push({
                medicineid: key + 1,
                "medicine_name ": item.medicinename,
                "medicine_main ": item.manufacturer,
                medicine_type: item.form,
                medicine_strength: item.strength,
                medicine_size: item.size,
                mrp: item.mrp,
                image:
                  item.image != null ? (
                    <img src={item.image} />
                  ) : (
                    <img src="../images/retailer/no-product.png" />
                  ),
                actions: (
                  <div>
                    <span>
                      <i
                        onClick={() => this.editProducts(item.id)}
                        className="fa fa-edit point-cursor"
                        title={"Edit"}
                      ></i>
                    </span>{" "}
                    &nbsp;
                    <span>
                      <i
                        onClick={() => this.uploadImage(item.id)}
                        className="fa fa-upload point-cursor"
                        title={"Upload Image"}
                      ></i>
                    </span>{" "}
                    &nbsp;
                    <span>
                      <i
                        onClick={() =>
                          this.deleteproductRequest(item.id, item.medicinename)
                        }
                        className="fa fa-trash point-cursor"
                        title={"Delete"}
                      ></i>
                    </span>{" "}
                    &nbsp;
                  </div>
                ),
              })
            : ""
        );
        if (assignvalues.length == 0) {
          assignvalues.push({
            medicineid: "",
            medicine_name: "",
            medicine_main: "",
            medicine_type: "No Data",
            uom: "",
            description: "",
            compositions: "",
            retailer: "",

            actions: "",
          });
        }

        let retailerState = Object.assign({}, this.state);
        retailerState.datatable.rows = assignvalues;
        this.setState(retailerState);
      })
      .catch((error) => {

      });
  }
  // Redirect to image upload page
  uploadImage(id) {
    this.props.history.push("/admin/UploadImages/" + id);
  }

  // editProducts
  editProducts(id) {
    this.props.history.push("/admin/editProducts/" + id);
  }

  //delete controller
  deleteproductRequest(Id, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");

    if (isConfirm) {
      Httpconfig.httptokendelete(Constant.siteurl + "api/productsMaster/" + Id)

        .then((response) => {
          if (response.data.status == 200 && !response.data.error)
            toast.success(response.data.message);
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {

        });
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link>&gt;Order Medicine &gt; View Products
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3> Create Product</h3>
                      </div>
                      <div id="reg_form">
                        <form>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <Link
                                to="/admin/editProducts"
                                className="btn  btn-primary fright"
                              >
                                Create Product
                              </Link>
                            </div>
                          </div>
                        </form>
                        {datatable.rows.length === 0 ? (
                          <p>No Data</p>
                        ) : (
                          <MDBDataTable
                            striped
                            responsive
                            bordered
                            small
                            data={datatable}
                          />
                        )}

                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="update_btn"
                              style={{ textAlign: "right" }}
                            ></div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
