import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctorAdmin";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import DoctorAdminHeader from "../doctorAdmin/DoctorAdminHeader";

import DoctorSideMenu from "./DoctorSideMenu";
import PatientFooter from "../patient/Patientfooter";
const moment = require("moment");

export default class DoctorAdminProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      doctorData: [],
    };
    this.viewAllTelemedicineAppointment =
      this.viewAllTelemedicineAppointment.bind(this);
  }
  viewAllTelemedicineAppointment = () => {
    window.location.href = "./Doctortelemedicineappointments";
  };
  componentDidMount = () => {
    var retrievedObject = localStorage.getItem("doctorAdmin");
    let userData = JSON.parse(retrievedObject);
    let doctorName = userData.name;

    this.setState({
      doctor_id: userData.doctor_id,
      doctorName: doctorName.charAt(0).toUpperCase() + doctorName.slice(1),
      doctorMobile: userData.mobile_number,
    });
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Doctor/DoctorProfiler/" + userData.doctor_id
    )
      .then((response) => {
        if (response.status == "200" && response.data.error === false) {
          let doctorDOBData = "";
          if (response.data.data[0].dob != null) {
            doctorDOBData = response.data.data[0].dob.split("T");
          }

          this.setState({
            doctorData: response.data.data,
            doctorDob: doctorDOBData[0],
          });
        } else {
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  render() {
    return (
      <main id="main">
        <DoctorAdminHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="doctor_dashboard">
            <div class="container-fluid">
              <div class="row">
                <DoctorSideMenu onSelectLanguage={this.handleLanguage} />

                {this.state.doctorData.map((doctorDetails) => (
                  <div class="col-lg-10 col-md-9 no_padding">
                    <div class="overview_left">
                      {console.log(doctorDetails)}
                      <section id="doc_profile_section" class="doc_pro">
                        <div class="profile_head">
                          <h1>Profile</h1>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="profile_info1">
                              <h2>Personal Information</h2>
                              <div class="profile_details">
                                <div class="row">

                                  <div class="col-md-3">
                                    <p>
                                      Name:{" "}
                                      <span>
                                        {doctorDetails.doctor_name
                                          ? doctorDetails.doctor_name
                                            .charAt(0)
                                            .toUpperCase() +
                                          doctorDetails.doctor_name.slice(1)
                                          : ""}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      Phone:{" "}
                                      <span>{doctorDetails.mobile_no}</span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      DOB:{" "}
                                      <span>
                                        {" "}
                                        {moment(this.state.doctorDob).format(
                                          "Do MMMM , YYYY"
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      Gender:{" "}
                                      <span>{doctorDetails.gender}</span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      Country: <span>India</span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      Language:{" "}
                                      <span>
                                        {doctorDetails.selected_language_name}
                                      </span>
                                    </p>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="profile_info1">
                              <h2>Medical Qualification</h2>
                              <div class="profile_details">
                                <div class="row">

                                  <div class="col-md-3">
                                    <p>
                                      Education:{" "}
                                      <span>
                                        {doctorDetails.course_name
                                          ? doctorDetails.course_name
                                          : "--"}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      Registration Details:{" "}
                                      <span>
                                        {doctorDetails.registraion_no
                                          ? doctorDetails.registraion_no
                                          : "--"}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      Specialities{" "}
                                      <span>
                                        {doctorDetails.speciality_name
                                          ? doctorDetails.speciality_name
                                          : "--"}
                                      </span>
                                    </p>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="profile_info1">
                              <h2>Contact Information</h2>
                              <div class="profile_details">
                                <div class="row">

                                  <div class="col-md-3">
                                    <p>
                                      Pincode:{" "}
                                      <span>
                                        {doctorDetails.zip_code
                                          ? doctorDetails.zip_code
                                          : "--"}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      City:{" "}
                                      <span>
                                        {doctorDetails.city
                                          ? doctorDetails.city
                                          : "--"}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <p>
                                      State:{" "}
                                      <span>
                                        {doctorDetails.state
                                          ? doctorDetails.state
                                          : "--"}
                                      </span>
                                    </p>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="upload_info1">
                              <div class="upload_details">
                                <h2>Your Picture</h2>
                                <div class="user_img">
                                  <img
                                    src={
                                      doctorDetails.profile_pic != ""
                                        ? Constant.imgurl +
                                        doctorDetails.profile_pic
                                        : doctorDetails.gender.toLowerCase() ==
                                          "male"
                                          ? "../images/patient/img/Profile/Male_patient.svg"
                                          : "../images/patient/img/Profile/Female_patient.svg"
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div class="upload_details">
                                <h2>Your Signature</h2>
                                <div class="sign_img">
                                  <img
                                    src={
                                      doctorDetails.signature_pic
                                        ? Constant.imgurl +
                                        doctorDetails.signature_pic
                                        : ""
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </I18nPropvider>
        <PatientFooter />
      </main>
    );
  }
}
