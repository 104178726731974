import React, { PropTypes, Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";

import { wrap } from "module";
import * as SecureLS from "secure-ls";
import constants from "../../constants";
import Iframe from "react-iframe";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

const https = require("https");

export default class PatientConfirmAppointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showlogin: true,
      showsignup: false,
      Language: "",
      date: new Date(),
      name: "",
      user_mobile: "",
      email_id: "",
      token: "",
      userId: "",
      profile_image: "",
      fields: {},
      errors: {},
      checked: "",
      termsconditions: "",
      coupon: "",
      code: "",
      amount: "",
      response: "",
      patientMedicalDocument: "",
      confirm: "",
      consultationType: "",
      couponDiscount: "",
      totDiscountAmount: "",
      clinicId: "",
      txnToken: "",
      consultationDateTime: "",
      transactionId: "",
      payableAmount: "",
      txnstatus: "",
      seconds: 150,
      time: {},
      timerInterval: "",
    };

    this.openCheckout = this.openCheckout.bind(this);
    this.checkTermsConditions = this.checkTermsConditions.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);

    this.handleCode = this.handleCode.bind(this);
    this.showDirections = this.showDirections.bind(this);
    this.InitPayment = this.InitPayment.bind(this);
    this.validateTransaction = this.validateTransaction.bind(this);
    this.skipPayment = this.skipPayment.bind(this);
    this.ReInitPayment = this.ReInitPayment.bind(this);
  }

  //On Load of the Component
  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
    var sort = document.getElementsByClassName("upload_img");
    for (let i = 0; i < sort.length; i++) {
      sort[i].addEventListener(
        "click",
        function (event) {
          this.setState({
            zoomimage: event.currentTarget.src,
          });
        }.bind(this)
      );
    }
  };
  // To get detais after first render
  componentDidMount = () => {
    const handle = this.props.match.params.id;
    var txnstatus = this.props.match.params.txnstatus;

    if (txnstatus != undefined) {
      this.state.txnstatus = txnstatus;
    } else {
      txnstatus = "";
    }

    let appointmentId = "";
    var patient_id = null;
    let clinicId = "";
    var retrievedObject = ls.get("userObjEnc");
    let doctorId = ls.get("DoctorSelectedEnc");

    //appointmentId = localStorage.getItem("appointmentId");
    appointmentId = ls.get("appointmentIdEnc");
    let consultationMonth = moment().format("MMMM");
    let consultationDate = moment().format("DD");
    let consultationTime = moment()
      .add(Constant.appointmentWaitingTime, "minutes")
      .format("hh:mm A");

    let consultationDateTime = moment()
      .add(Constant.appointmentWaitingTime, "minutes")
      .format("YYYY-MM-DD hh:mm A");
    this.state.consultationDateTime = consultationDateTime;
    this.forceUpdate();

    if (!appointmentId) {
      toast.warn("Please enter purpose of consultation", {
        position: "bottom-center",
        autoClose: 1000,
      });

      setTimeout(
        () => this.props.history.push("/Patientconsultationpurpose"),
        2000
      );
    }

    if (ls.get("seletedSlot_timeEnc") != "") {
      consultationDateTime = ls.get("seletedSlot_timeEnc");
      consultationTime = moment(consultationDateTime).format(
        "Do MMMM YYYY h:mm A"
      );
    }

    if (retrievedObject == null) {
      window.location.href = "/";
    }
    let userData = retrievedObject;
    patient_id = ls.get("SelectedPatientIdEnc");
    if (patient_id != null || patient_id != "") {
      userData.patient_id = patient_id;
      clinicId = userData.clinic_id;
    }
    let consultationType = ls.get("consultation_type");
    this.state.consultationType = consultationType;
    this.state.consultationType = consultationType;
    this.forceUpdate();
    this.setState({
      name: userData.name,
      user_mobile: userData.mobile_number,
      email_id: userData.email,
      token: userData.accessToken,
      patient_id: userData.patient_id,
      profile_image: "",
      appointmentId: appointmentId,
      doctorId: doctorId,
      consultationType: consultationType,
      clinicId: userData.clinic_id,
    });

    this.forceUpdate();
    this.startTimer();
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Doctor/DoctorProfiler/" + doctorId
    )
      .then((responseData) => {
        if (
          responseData.data.status == "200" &&
          responseData.data.error == false
        ) {
          let profile_pic = "";
          if (responseData.data.data[0].profile_pic != "") {
            profile_pic = responseData.data.data[0].profile_pic;
          } else {
            if (responseData.data.data[0].gender.toLowerCase() == "male") {
              profile_pic = "../images/patient/img/Profile/Male_patient.svg";
            } else {
              profile_pic = "../images/patient/img/Profile/Female_patient.svg";
            }
          }
          this.state.payableAmount = responseData.data.data[0].fees;
          this.forceUpdate();
          this.setState({
            doctorName:
              responseData.data.data[0].tbl_user.name.charAt(0).toUpperCase() +
              responseData.data.data[0].tbl_user.name.slice(1),
            patient_id: userData.patient_id,
            speciality: responseData.data.data[0].speciality_name,
            address: responseData.data.data[0].address,
            city: responseData.data.data[0].city,
            fees: responseData.data.data[0].fees,
            experience: responseData.data.data[0].experience,
            profile_pic: profile_pic,
            is_available: responseData.data.data[0].is_available,
            languages: responseData.data.data[0].languages,
            lat_long: responseData.data.data[0].lat_long,
            consultationMonth: consultationMonth,
            consultationDate: consultationDate,
            consultationTime: consultationTime,
            consultationDateTime: consultationDateTime,
            currencySymbol: responseData.data.data[0].currency_symbol,
            education: responseData.data.data[0].education,
            courseName: responseData.data.data[0].course_name,
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    let postData = [
      { appointment_id: appointmentId, patient_id: userData.patient_id },
    ];
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Patients/getMedicalDetails/",
      { appointment_id: appointmentId, patient_id: userData.patient_id }
    )
      .then((response) => {
        let patientCurrentMedication = "";
        let patientDrugAllergies = "";
        let symptoms_tbls = "";
        let patientMedicalDocument = [];
        let imagedata = [];
        let patientAge = "";
        let chornicDiseases_list = "";
        let relatedMedication = "";
        let bloodPressureSystolic = "";
        let bloodPressureDiastolic = "";
        let height = "";
        let weight = "";
        let bmi = "";
        let pulse_rate = "";
        let respiratory_rate = "";
        let skip_blood_pressure = "";
        let temperature = "";
        let patientName =
          response.data.data[0].name.charAt(0).toUpperCase() +
          response.data.data[0].name.slice(1);
        let gender = response.data.data[0].gender;
        let profile_pic = "";
        let blood_sugar = "";
        let pastillness = "";
        let cli_address = response.data.data[0].tbl_user.clinic_tbl.address;
        let cli_lat = response.data.data[0].tbl_user.clinic_tbl.latitude;
        let cli_lang = response.data.data[0].tbl_user.clinic_tbl.longitude;
        let cli_city = response.data.data[0].tbl_user.clinic_tbl.city_name;
        let clinic_logo = response.data.data[0].tbl_user.clinic_tbl.clinic_logo;
        this.setState({
          cli_address: cli_address,
          cli_lat: cli_lat,
          cli_lang: cli_lang,
          cli_city: cli_city,
          clinic_logo: clinic_logo,
        });

        if (response.data.data[0].dob !== null) {
          let dob = response.data.data[0].dob.split("T");
          let age = dob[0];
          let ageYears = moment().diff(age, "years");
          let ageMonths = moment().diff(age, "months");
          let ageDays = moment().diff(age, "days");
          if (ageYears > 0) {
            if (ageYears === 1) {
              patientAge = ageYears + " Year";
            } else {
              patientAge = ageYears + " Years";
            }
          } else if (ageMonths > 0) {
            if (ageMonths == 1) {
              patientAge = ageMonths + " Month";
            } else {
              patientAge = ageMonths + " Months";
            }
          } else if (ageDays) {
            patientAge = ageDays + " Days";
          }
        }
        if (response.data.data[0].profile_pic !== null) {
          profile_pic = response.data.data[0].profile_pic;
        } else {
          if (gender.toLowerCase() === "male") {
            profile_pic = "../images/patient/img/Profile/Male_patient.svg";
          } else {
            profile_pic = "../images/patient/img/Profile/Female_patient.svg";
          }
        }
        this.setState({
          patientName: patientName,
          gender: gender,
        });
        var surName = "";
        if (this.state.gender.toLowerCase() === "male") {
          surName = "Mr.";
        } else {
          surName = "Ms.";
        }
        this.setState({
          surName: surName,
          dob: patientAge,
          profilePics: profile_pic,
        });
        // this.state.surName = surName;
        // this.state.dob = patientAge;
        // this.state.profilePics = profile_pic;
        this.state.relation =
          response.data.data[0].master_relationship.relation_name;
        if (
          Object.keys(response.data.data[0].patient_past_histories).length > 0
        ) {
          let pastLength =
            response.data.data[0].patient_past_histories[0][
              "patient_current_medication"
            ].length;
          if (pastLength > 0) {
            for (var past = 0; past < pastLength; past++) {
              patientCurrentMedication =
                patientCurrentMedication +
                "," +
                response.data.data[0].patient_past_histories[0][
                  "patient_current_medication"
                ][past]["medicinename"];
            }
          }
        }

        if (
          Object.keys(response.data.data[0].patient_past_histories).length > 0
        ) {
          if (
            response.data.data[0].patient_past_histories[0]["past_history"] !=
            null
          ) {
            pastillness =
              pastillness +
              "," +
              response.data.data[0].patient_past_histories[0]["past_history"];
          }
          let drugAllergiesLength =
            response.data.data[0].patient_past_histories[0][
              "patient_drug_allergies"
            ].length;
          if (drugAllergiesLength > 0) {
            for (var past = 0; past < drugAllergiesLength; past++) {
              if (
                response.data.data[0].patient_past_histories[0][
                  "patient_drug_allergies"
                ][past]["medicinename"] != null
              ) {
                patientDrugAllergies =
                  patientDrugAllergies +
                  "," +
                  response.data.data[0].patient_past_histories[0][
                    "patient_drug_allergies"
                  ][past]["medicinename"];
              }
            }
          }
        }

        if (response.data.data[0].symptoms_tbls) {
          if (Object.keys(response.data.data[0].symptoms_tbls).length > 0) {
            if (
              response.data.data[0].symptoms_tbls[0].add_more_symptoms !== null
            ) {
              symptoms_tbls =
                response.data.data[0].symptoms_tbls[0].add_more_symptoms;
            }
            let symptomsLength =
              response.data.data[0].symptoms_tbls[0]["symptoms"].length;
            if (symptomsLength > 0) {
              for (var pastsym = 0; pastsym < symptomsLength; pastsym++) {
                
                symptoms_tbls =
                  symptoms_tbls +
                  "," +
                  response.data.data[0].symptoms_tbls[0]["symptoms"][pastsym][
                    "description"
                  ];
              }
            }
          }
        }

        let documentsLength =
          response.data.data[0].patient_past_histories.length;
        if (documentsLength > 0) {
          for (var pastdoc = 0; pastdoc < documentsLength; pastdoc++) {
            for (
              let doc = 0;
              doc <
              response.data.data[0].patient_past_histories[pastdoc][
                "patient_medical_document"
              ].length;
              doc++
            ) {
              patientMedicalDocument[doc] =
                response.data.data[0].patient_past_histories[pastdoc][
                  "patient_medical_document"
                ][doc];
              imagedata[doc] =
                '<div class="med_upload_box"><a href="#" class="thumbnail" data-toggle="modal" data-target="#lightbox"> <img class="upload_img" src=' +
                patientMedicalDocument[doc] +
                "><p>Health record " +
                (doc + 1) +
                "</a></p></div>";
            }
          }
        }

        if (response.data.data[0].chornic_diseases_list !== null) {
          if (
            Object.keys(response.data.data[0].chornic_diseases_list).length > 0
          ) {
            chornicDiseases_list = response.data.data[0].chornic_diseases_list;
          }
        }
        if (response.data.data[0].related_medication !== null) {
          if (
            Object.keys(response.data.data[0].related_medication).length > 0
          ) {
            relatedMedication = response.data.data[0].related_medication;
          }
        }
        if (response.data.data[0].vital_informations) {
          if (
            Object.keys(response.data.data[0].vital_informations).length > 0
          ) {
            bloodPressureSystolic =
              response.data.data[0].vital_informations[0]
                .blood_pressure_systolic;
            bloodPressureDiastolic =
              response.data.data[0].vital_informations[0]
                .blood_pressure_diastolic;
            if (response.data.data[0].vital_informations[0].height !== "") {
              var realFeet =
                (response.data.data[0].vital_informations[0].height * 0.3937) /
                12;
              var feet = Math.floor(realFeet);
              var inches = Math.round((realFeet - feet) * 12);

              response.data.data[0].vital_informations[0].height =
                feet + "'," + inches + '"';
            }

            height = response.data.data[0].vital_informations[0].height;
            weight = response.data.data[0].vital_informations[0].weight;
            bmi = response.data.data[0].vital_informations[0].bmi;
            pulse_rate = response.data.data[0].vital_informations[0].pulse_rate;
            respiratory_rate =
              response.data.data[0].vital_informations[0].respiratory_rate;
            skip_blood_pressure =
              response.data.data[0].vital_informations[0].skip_blood_pressure;
            temperature =
              response.data.data[0].vital_informations[0].temperature;
            blood_sugar =
              response.data.data[0].vital_informations[0].blood_sugar;
            if (response.data.data[0].chornic_diseases_list !== null) {
              pastillness =
                pastillness + "," + response.data.data[0].chornic_diseases_list;
            }
          }
        }
        if (response.data.data[0].vital_information) {
          if (Object.keys(response.data.data[0].vital_information).length > 0) {
            bloodPressureSystolic =
              response.data.data[0].vital_information[0]
                .blood_pressure_systolic;
            bloodPressureDiastolic =
              response.data.data[0].vital_information[0]
                .blood_pressure_diastolic;
            if (response.data.data[0].vital_information[0].height !== "") {
              var realFeetInch =
                (response.data.data[0].vital_information[0].height * 0.3937) /
                12;
              var feetinch = Math.floor(realFeetInch);
              var inch = Math.round((realFeetInch - feetinch) * 12);

              response.data.data[0].vital_information[0].height =
                feetinch + "'," + inch + '"';
            }

            height = response.data.data[0].vital_information[0].height;
            weight = response.data.data[0].vital_information[0].weight;
            bmi = response.data.data[0].vital_information[0].bmi;
            pulse_rate = response.data.data[0].vital_information[0].pulse_rate;
            respiratory_rate =
              response.data.data[0].vital_information[0].respiratory_rate;
            skip_blood_pressure =
              response.data.data[0].vital_information[0].skip_blood_pressure;
            temperature =
              response.data.data[0].vital_information[0].temperature;
            blood_sugar =
              response.data.data[0].vital_information[0].blood_sugar;
            if (response.data.data[0].chornic_diseases_list != null) {
              pastillness =
                pastillness + "," + response.data.data[0].chornic_diseases_list;
            }
          }
        }
        if (
          symptoms_tbls.indexOf(",") > -1 &&
          response.data.data[0].symptoms_tbls[0].add_more_symptoms === null
        ) {
          symptoms_tbls = symptoms_tbls.substr(1);
        }
        if (patientDrugAllergies.indexOf(",") > -1) {
          patientDrugAllergies = patientDrugAllergies.substr(1);
        }
        if (pastillness.indexOf(",") > -1) {
          pastillness = pastillness.substr(1);
        }

        this.setState({
          chornicDiseases_list: chornicDiseases_list,
          relatedMedication: relatedMedication,
          drugAllergies: patientDrugAllergies,
          patientCurrentMedication: patientCurrentMedication,
          symptoms: symptoms_tbls,
          bloodPressureSystolic: bloodPressureSystolic,
          bloodPressureDiastolic: bloodPressureDiastolic,
          height: height,
          weight: weight,
          bmi: bmi,
          pulse_rate: pulse_rate,
          respiratory_rate: respiratory_rate,
          skip_blood_pressure: skip_blood_pressure,
          temperature: temperature,
          patientMedicalDocument: patientMedicalDocument,
          imagedata: imagedata,
          problem: response.data.data[0].problem_tbls[0].problem,
          blood_sugar: blood_sugar,
          pastillness: pastillness,
        });
        this.forceUpdate();
        if (handle) {
          if (handle !== "") {
            this.setState({
              transactionId: handle,
              doctorId: doctorId,
              patient_id: userData.patient_id,
              appointmentId: appointmentId,
              consultationType: consultationType,
              clinicId: userData.clinic_id,
              coupon: ls.get("coupanName"),
              couponId: ls.get("coupanId"),
              payableAmount: ls.get("consultation_fee"),
              couponDiscount: ls.get("coupanDiscount"),
            });
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //check/Update for the  Terms and conditions
  handleUpdate = () => {
    this.setState({ checked: !this.state.checked });
    if (this.state.checked) {
      this.setState({ termsconditions: "" });
    } else {
      this.setState({ termsconditions: "" });
    }
  };

  // skip payment gateway
  skipPayment = () => {
    let patientId = this.state.patient_id;
    let clinicId = this.state.clinic_id;
    let transactionId = this.state.transactionId;
    let doctorFees = this.state.payableAmount;
    var doctorId = this.state.doctorId;
    let appointment_unique_id = "";
    let consultationDateTime = this.state.consultationDateTime;
    let appointmentId = this.state.appointmentId;
    let time = moment(consultationDateTime, "HH:mm:ss").format("HH:mm:ss");
    let consultationDate = consultationDateTime.split(" ");
    let cDateTime =
      consultationDate[0] + " " + moment(time).format("YYYY-MM-DD HH:mm");

    cDateTime = moment()
      .add(constants.appointmentWaitingTime, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");

    if (ls.get("seletedSlot_timeEnc")) {
      if (ls.get("seletedSlot_timeEnc") != null) {
        cDateTime = ls.get("seletedSlot_timeEnc");
      }
    }
    if (doctorFees == "") {
      this.state.fees = 100;
    }

    if (transactionId == "") {
      transactionId = Math.floor(100000 + Math.random() * 900000);
    }
    appointment_unique_id = ls.get("appointment_unique_id");
    if (appointment_unique_id != "") {
      appointment_unique_id = appointment_unique_id;
    }

    let postData = {
      patient_id: JSON.stringify(patientId),
      doctor_id: JSON.parse(doctorId),
      appointment_datetime: cDateTime,
      appointment_id: parseInt(appointmentId),
      transaction_id: transactionId,
      status: "1",
      consultation_fee: doctorFees,
      module_type: this.state.consultationType,
      coupon_id: this.state.couponId,
      coupon_name: this.state.coupon,
      coupon_value: this.state.couponDiscount,
      clinic_id: this.state.clinicId,
      appointment_unique_id: appointment_unique_id,
    };
    return;
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/",
      postData
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          ls.remove("appointmentIdEnc");
          ls.remove("SelectedPatientIdEnc");
          ls.remove("appointment_unique_id");
          ls.remove("paymentTime");
          ls.remove("coupanName");
          ls.remove("coupanId");
          ls.remove("coupanDiscount");
          ls.remove("consultation_fee");
          toast.success(
            "Thank you for booking appointment, you can view your appointment details",
            {
              position: "bottom-center",
            }
          );

          setTimeout(() => (window.location.href = "/Patientdashboard"), 2000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //check for the Terms and conditions checked/Unchecked

  checkTermsConditions = () => {
    if (this.state.checked) {
      this.setState({ termsconditions: "" });
      this.state.amount = 100 / 100;
      this.openCheckout();
      return;
      this.state.confirm = 1;
    } else {
      this.setState({ termsconditions: "Please check the Terms & Conditions" });
    }
  };
  // Language Change handler
  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };
  // Coupon validate handler
  applyCoupon = () => {
    if (!this.state.coupon) {
      this.setState({ code: "Enter the Coupon Code" });
    } else {
      var coupanName = this.state.coupon;
      var couponDiscount = "";
      var totDiscountAmount = "";
      var feeAmount = this.state.fees;
      var payableAmount = "";
      var couponId = "";
      this.forceUpdate();

      let postData = {
        amount: feeAmount,
        coupanName: coupanName,
        patientId: this.state.patient_id,
        //module: "telemedician",
        module: "telemedicine",
      };

      Httpconfig.httptokenpost(
        Constant.siteurl + "api/Coupon/Coupondiscountamount/",
        postData
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            this.setState({
              code: response.data.message,
            });
            couponDiscount = response.data.data.discount;
            totDiscountAmount = response.data.data.totdiscountamount;
            couponId = response.data.data.id;
            payableAmount = response.data.data.totamount;
            this.setState({
              couponId: couponId,
              payableAmount: payableAmount,
              couponDiscount: couponDiscount,
              totDiscountAmount: totDiscountAmount,
            });
          } else {
            this.setState({
              code: response.data.message,
              payableAmount: this.state.fees,
              couponDiscount: "",
              totDiscountAmount: "",
              couponId: "",
            });
          }
        })
        .catch((error) => {
          toast.error(error);
          this.setState({
            code: "Invalid Coupon",
            payableAmount: this.state.fees,
            couponDiscount: "",
            couponDiscount: "",
          });
        });
    }
  };

  // remove coupon Applied

  removeCoupon = () => {
    this.setState({
      coupon: "",
      couponDiscount: "",
      coupon_id: "",
      totDiscountAmount: "",
      payableAmount: this.state.fees,
      code: "Coupon Removed",
    });
    this.setState({ code: "Coupon Removed Successfully" });
  };

  // Verify Coupon Code
  handleCode = (event) => {
    this.setState({ coupon: event.target.value.toUpperCase() });
    if (event.target.value != "") {
      this.setState({ code: "" });
    } else {
      this.removeCoupon();
    }
  };
  // Checkout for the payment
  openCheckout = (e) => {
    let patientId = this.state.patient_id;
    let clinicid = this.state.clinicId;
    let doctorFees = Number(this.state.payableAmount);
    var doctorId = this.state.doctorId;
    let coupanName = this.state.coupon;
    let coupanId = this.state.couponId;
    let coupanDiscount = this.state.couponDiscount;
    let that = this;

    doctorId = doctorId.replace(/"/g, "");
    let appointmentId = this.state.appointmentId;
    let cDateTime = "";
    cDateTime = moment()
      .add(Constant.appointmentWaitingTime, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");

    if (ls.get("seletedSlot_timeEnc")) {
      cDateTime = ls.get("seletedSlot_timeEnc");
    }
    if (doctorFees == null) {
      doctorFees = 100;
    }

    let options = {
      key: Constant.razorpay_key_id,
      amount: doctorFees * 100,
      name: "Remotel ",
      description: "Consultation booking",
      image: Constant.siteurl + "/images/patient/img/main-logo.png",
      handler: async function (response) {
        let transactionId = response.razorpay_payment_id;
        let postData = {
          patient_id: JSON.stringify(patientId),
          doctor_id: JSON.parse(doctorId),
          appointment_datetime: cDateTime,
          appointment_id: parseInt(appointmentId),
          clinic_id: clinicid,
          transaction_id: transactionId,
          coupon_id: coupanId,
          coupon_name: coupanName,
          coupon_value: coupanDiscount,
          status: 1,
          consultation_fee: doctorFees,
          module_type: ls.get("consultation_type"),
        };
        Httpconfig.httptokenpost(
          Constant.siteurl + "api/PatientAppointment/",
          postData
        )
          .then((response) => {
            if (response.data.status == "200" && response.data.error == false) {
              ls.remove("appointmentIdEnc");
              ls.remove("SelectedPatientIdEnc");
              ls.remove("consultation_type");
              ls.remove("appointment_unique_id");
              ls.remove("paymentTime");
              toast.success(
                "Thank you for booking an appointment, you can view your appointment details",
                {
                  position: "bottom-center",
                }
              );
              setTimeout(
                () => (window.location.href = "/Patientdashboard"),
                2000
              );
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      },
      modal: {
        ondismiss: function () {},
      },
      prefill: {
        name: "Rakesh",
        email: "rakesh.n@medleymed.com",
        phone: "9652726606",
      },
      notes: {
        address: this.state.address,
      },
      theme: {
        color: "#555AB9",
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  // Previous page Handler
  goBack = () => {
    window.location.href = "/availabledoctors";
  };
  // Show the Map Directions
  showDirections = () => {
    var lat = this.state.cli_lat;
    var lang = this.state.cli_lang;
    window.open("https://maps.google.com?q=" + lat + "," + lang);
  };

  // Checkout for the payment payslack
  paySlackCheckout = (e) => {
    let patientId = this.state.patient_id;
    let doctorFees = this.state.payableAmount;
    var doctorId = this.state.doctorId;
    doctorId = doctorId.replace(/"/g, "");
    let consultationDateTime = this.state.consultationDateTime;
    let appointmentId = this.state.appointmentId;
    let time = moment(consultationDateTime, "HH:mm:ss").format("HH:mm:ss");
    let consultationDate = consultationDateTime.split(" ");
    let cDateTime =
      consultationDate[0] + " " + moment(time).format("YYYY-MM-DD HH:mm");
    cDateTime = moment().add(5, "minutes").format("YYYY-MM-DD HH:mm:ss");

    if (ls.get("seletedSlot_timeEnc") != "") {
      cDateTime = ls.get("seletedSlot_timeEnc");
    }
    if (doctorFees == null) {
      this.state.fees = 100;
    }
    let postData = {
      patient_id: patientId,
      doctor_id: JSON.parse(doctorId),
      appointment_datetime: cDateTime,
      appointment_id: parseInt(appointmentId),
      transaction_id: "",
      status: "1",
      email: this.state.email_id,
      consultation_fee: doctorFees,
      module_type: this.state.consultationType,
    };
    this.state.appointmentDetails = postData;
    this.forceUpdate();
  };

  // Paytm payment gateway init
  InitPayment = () => {
    let patientId = this.state.patient_id;
    let doctorFees = this.state.payableAmount;
    var doctorId = this.state.doctorId;
    doctorId = doctorId.replace(/"/g, "");
    let consultationDateTime = this.state.consultationDateTime;
    let appointmentId = this.state.appointmentId;
    let time = moment(consultationDateTime, "HH:mm:ss").format("HH:mm:ss");
    let consultationDate = consultationDateTime.split(" ");
    let cDateTime =
      consultationDate[0] + " " + moment(time).format("YYYY-MM-DD HH:mm");
    cDateTime = moment()
      .add(Constant.appointmentWaitingTime, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    let consultationType = this.state.consultationType;
    if (
      ls.get("seletedSlot_timeEnc") != null &&
      ls.get("seletedSlot_timeEnc") != ""
    ) {
      cDateTime = ls.get("seletedSlot_timeEnc");
    }

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/TelemediceInitPaytmPayment",
      {
        patient_id: patientId,
        fees: this.state.payableAmount,
        doctorId: doctorId,
        consultationDateTime: consultationDateTime,
        appointmentId: appointmentId,
        clinicId: this.state.clinicId,
        cDateTime: cDateTime,
        consultationType: consultationType,
      }
    ).then((response) => {
      if (response.data.error == false) {
        if (
          response.data.data[0]["txnId"] != "" &&
          response.data.data[0]["txnId"] != undefined
        ) {
          $("#txnToken").val(response.data.data[0]["txnId"]);
          $("#appointmentId").val(appointmentId);
          this.state.selectedappointmentId = appointmentId;
          this.state.txnTokenId = response.data.data[0]["txnId"];
          this.forceUpdate();
          ls.set("coupanName", this.state.coupon);
          ls.set("coupanId", this.state.couponId);
          ls.set("coupanDiscount", this.state.couponDiscount);
          ls.set("consultation_fee", this.state.payableAmount);
          $("#paytmform").attr(
            "action",
            "https://" +
              constants.Paytm_hostname +
              "/theia/api/v1/showPaymentPage?mid=" +
              constants.paytm_mid +
              "&orderId=" +
              appointmentId
          );

          const interval = setInterval(() => {
            $("#paytmform").submit();
          }, 1500);
        }
      } else {
        if (response.data.error == true) {
          if (response.data.transactionStatus == 325) {
            this.ReInitPayment();
          } else {
            toast.error(response.data.data.message, {
              position: "bottom-center",
              autoClose: 1000,
            });
          }
        }
      }
    });
  };

  // duplicate appointment id generate and re submit transaction
  ReInitPayment = () => {
    let patientId = this.state.patient_id;
    let doctorFees = this.state.payableAmount;
    var doctorId = this.state.doctorId;
    var ResubmitCount = 1;
    var savedResubmitValue = "";
    savedResubmitValue = ls.get("ResubmitCount");
    if (savedResubmitValue != undefined && savedResubmitValue != "") {
      ResubmitCount = parseInt(savedResubmitValue) + 1;
    }
    doctorId = doctorId.replace(/"/g, "");
    let consultationDateTime = this.state.consultationDateTime;
    let appointmentId = this.state.appointmentId + "-" + ResubmitCount;
    ls.set("ResubmitCount", ResubmitCount);
    let time = moment(consultationDateTime, "HH:mm:ss").format("HH:mm:ss");
    let consultationDate = consultationDateTime.split(" ");
    let cDateTime =
      consultationDate[0] + " " + moment(time).format("YYYY-MM-DD HH:mm");
    cDateTime = moment()
      .add(Constant.appointmentWaitingTime, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    let consultationType = this.state.consultationType;
    if (
      ls.get("seletedSlot_timeEnc") != null &&
      ls.get("seletedSlot_timeEnc") != ""
    ) {
      cDateTime = ls.get("seletedSlot_timeEnc");
    }
    ls.set("appointment_unique_id", appointmentId);

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/TelemediceInitPaytmPayment",
      {
        patient_id: patientId,
        fees: this.state.payableAmount,
        doctorId: doctorId,
        consultationDateTime: consultationDateTime,
        appointmentId: appointmentId,
        clinicId: this.state.clinicId,
        cDateTime: cDateTime,
        consultationType: consultationType,
      }
    ).then((response) => {
      if (response.data.error == false) {
        if (
          response.data.data[0]["txnId"] != "" &&
          response.data.data[0]["txnId"] != undefined
        ) {
          $("#txnToken").val(response.data.data[0]["txnId"]);
          this.state.selectedappointmentId = appointmentId;
          this.state.txnTokenId = response.data.data[0]["txnId"];
          this.forceUpdate();
          ls.set("coupanName", this.state.coupon);
          ls.set("coupanId", this.state.couponId);
          ls.set("coupanDiscount", this.state.couponDiscount);
          $("#paytmform").attr(
            "action",
            "https://" +
              constants.Paytm_hostname +
              "/theia/api/v1/showPaymentPage?mid=" +
              constants.paytm_mid +
              "&orderId=" +
              appointmentId
          );
          $("#appointmentId").val(appointmentId);
          const interval = setInterval(() => {
            $("#paytmform").submit();
          }, 1500);
        }
      }
    });
  };

  // Validate Transaction ID
  validateTransaction = (transactionId) => {
    var appid = "";
    var appointmentId = "";
    appid = ls.get("appointment_unique_id");
    if (appid != "") {
      appointmentId = appid;
    } else {
      appointmentId = this.state.appointmentId;
    }

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/ValidateTransaction",
      {
        appointmentId: appointmentId,
        transactionId: transactionId,
      }
    ).then((response) => {
      if (response.data.error == true) {
        this.skipPayment();
      } else {
        toast.error(response.data.message);
      }
    });
  };

  //starting timer
  startTimer = () => {
    let proccedTime = ls.get("paymentTime");
    let paymentTime = moment(proccedTime)
      .add(150, "seconds")
      .format("YYYY MM DD hh:mm:ss a");
    let minDiff = moment(paymentTime).diff(
      moment().format("YYYY MM DD hh:mm:ss a"),
      "minutes"
    );
    let secdiff = moment(paymentTime).diff(
      moment().format("YYYY MM DD hh:mm:ss a"),
      "seconds"
    );
    if (secdiff > 0 && minDiff <= 2) {
      console.log("----1101", setInterval(this.countDown, 1000));
      this.setState({
        seconds: secdiff,
        timerInterval: setInterval(this.countDown, 1000),
      });
    } else {
      window.location.href = "/availabledoctors";
    }
  };
  //  for converting seconds to time
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  // For countDown

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (seconds == 0) {
      clearInterval(this.state.timerInterval);
      window.location.href = "/availabledoctors";
    }
  };

  render() {
    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />

        <I18nPropvider locale={this.state.Language}>
          <section id="availavle_doc">
            <div class="container">
              <div class="row av_box">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="available_heading">
                    <h4>
                      <img
                        src="../images/patient/img/Doctors_List/View Profile/Back.svg"
                        onClick={this.goBack}
                      />
                      {translate("Select Doctor")}
                    </h4>
                    <h2>{translate("Appointment Preview")}</h2>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </section>

          <section id="pay_now">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12">
                  <div class="pay_appoint_box">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-12 col-3">
                        <div class="pay_avtar">
                          <img
                            src={
                              this.state.profile_pic
                                ? this.state.profile_pic
                                : "../images/patient/img/Profile/Male_doctor.svg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-7 col-sm-12 col-9">
                        <div class="doc_info">
                          <h2>
                            {translate("Dr")}. {this.state.doctorName}
                          </h2>
                          <h5>
                            {this.state.courseName
                              ? this.state.courseName
                              : "--"}
                            ,
                            {this.state.speciality
                              ? this.state.speciality
                              : "--"}
                          </h5>
                          <h5>
                            {this.state.experience
                              ? this.state.experience
                              : "1"}{" "}
                            {translate("years experience")}
                          </h5>
                          <p>
                            <span>
                              {this.state.currencySymbol}{" "}
                              {parseFloat(this.state.fees)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-3 col-sm-12">
                        <div
                          class="doc_directions"
                          style={{ overflowWrap: "break-word" }}
                        >
                          <span>
                            {this.state.clinic_logo == null ? (
                              <img src="./images/patient/img/Profile/hospital.svg" />
                            ) : (
                              <img
                                src={Constant.imgurl + this.state.clinic_logo}
                              />
                            )}
                          </span>
                          <h5>
                            {this.state.cli_address + "," + this.state.cli_city}
                          </h5>
                          <p onClick={this.showDirections}>
                            <img
                              class="loc_img"
                              src="../images/patient/img/Doctors_List/location.svg"
                            />{" "}
                            {translate("Directions")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p class="subtitle">
                          <span>{translate("appointment with")}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-12 col-3">
                        <div class="pay_avtar">
                          <img
                            src={
                              this.state.profilePics != null
                                ? this.state.profilePics
                                : "../images/patient/img/Profile/Male_patient.svg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-lg-10 col-md-10 col-sm-12 col-9">
                        <div class="doc_info">
                          <h2>
                            {this.state.surName} {this.state.patientName}{" "}
                            <span>({this.state.relation})</span>
                          </h2>
                          <h5>
                            {this.state.gender
                              ? translate(
                                  this.state.gender.charAt(0).toUpperCase() +
                                    this.state.gender.slice(1)
                                )
                              : ""}
                            ,{" "}
                            <span>
                              {this.state.dob ? this.state.dob : "--"}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="consult_box">
                    <div class="row c_row">
                      <div class="col-lg-2 col-md-3 col-sm-3">
                        <div class="date_time">
                          <p>{this.state.consultationMonth}</p>
                          <h3>{this.state.consultationDate}</h3>
                        </div>
                      </div>
                      <div class="col-lg-10 col-md-9 col-sm-9 no_padding">
                        <div class="consult_text">
                          <h1>{translate("Consultation Time")}</h1>
                          <p>
                            {translate("Your appointment will be start at")}{" "}
                            <span>{this.state.consultationTime}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="medical_box">
                    <div class="row c_row">
                      <div class="col-lg-1 col-md-3 col-sm-3">
                        <div class="medical_img">
                          <img src="../images/patient/img/In_person_visit/medical_detail.svg" />
                        </div>
                      </div>
                      <div class="col-lg-11 col-md-9 col-sm-9">
                        <div class="medical_text">
                          <h1>{translate("Medical Details")} </h1>
                          <p>
                            {translate(
                              "See the medical details submitted by you"
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <div class="medical_head">
                          <h1>{translate("Purpose of visit")}</h1>
                          <p>{this.state.problem}</p>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <div class="medical_head">
                          <h1>{translate("Symptoms")}</h1>
                          <p>
                            {this.state.symptoms ? this.state.symptoms : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="medical_hr"></div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <div class="medical_head">
                          <h1>{translate("Vital Informations")}</h1>
                          <div>
                            <p>{translate("Blood pressure")} </p>
                            <span class="vit_span">
                              {this.state.bloodPressureSystolic
                                ? this.state.bloodPressureSystolic
                                : "--"}{" "}
                              mmHg ,{" "}
                              {this.state.bloodPressureDiastolic
                                ? this.state.bloodPressureDiastolic
                                : "--"}{" "}
                              mmHg
                            </span>
                          </div>
                          <div>
                            <p>{translate("Pulse Rate")} </p>
                            <span class="vit_span">
                              {this.state.pulse_rate
                                ? this.state.pulse_rate
                                : "--"}{" "}
                              bpm
                            </span>
                          </div>
                          <div>
                            <p>{translate("Temperature")} </p>
                            <span class="vit_span">
                              {this.state.temperature
                                ? this.state.temperature
                                : "--"}{" "}
                              °F
                            </span>
                          </div>
                          <div>
                            <p>{translate("Respiratory Rate")} </p>
                            <span class="vit_span">
                              {this.state.respiratory_rate
                                ? this.state.respiratory_rate
                                : "--"}{" "}
                              Breaths per Minute
                            </span>
                          </div>
                          <div>
                            <p>{translate("Blood Sugar")} </p>
                            <span class="vit_span">
                              {this.state.blood_sugar
                                ? this.state.blood_sugar
                                : " --"}{" "}
                              mg/dL
                            </span>
                          </div>
                          <div>
                            <p>{translate("Height")} </p>
                            <span class="vit_span">
                              {this.state.height ? this.state.height : "--"}{" "}
                              feet/Inch
                            </span>
                          </div>
                          <div>
                            <p>{translate("Weight")} </p>
                            <span class="vit_span">
                              {this.state.weight ? this.state.weight : "--"} Kg
                            </span>
                          </div>
                          <div>
                            <p>{translate("Body Mass Index")} </p>
                            <span class="vit_span">
                              {this.state.bmi ? this.state.bmi : "--"} kg/M2{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="medical_hr"></div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <div class="medical_head">
                          <h1>{translate("Chronic Conditions")}</h1>
                          <p>
                            {this.state.pastillness
                              ? this.state.pastillness
                              : "--"}
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <div class="medical_head">
                          <h1>{translate("Patient Drug Allergies")}</h1>
                          <p>
                            {this.state.drugAllergies
                              ? this.state.drugAllergies
                              : "--"}
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <div class="medical_head">
                          <h1>{translate("Current Medication")}</h1>
                          <p>
                            {this.state.patientCurrentMedication
                              ? this.state.patientCurrentMedication.substr(1)
                              : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="medical_hr"></div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <div class="medical_head">
                          <h1>{translate("Uploaded Document")}</h1>

                          {this.state.imagedata != "" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.imagedata,
                              }}
                            />
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="fee_box">
                    <h2>{translate("Fee Details")}</h2>
                    <div>
                      <p>{translate("Consultation Fees")}</p>
                      <h5>
                        {this.state.currencySymbol}{" "}
                        {parseFloat(this.state.fees)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </h5>
                    </div>
                    <p>{translate("Have a Coupon Code")}?</p>
                    <div class="form-group">
                      <div class="input-group">
                        <FormattedMessage id="Enter Your Code">
                          {(placeholder) => (
                            <input
                              type="text"
                              class="form-control code_input"
                              value={this.state.coupon}
                              placeholder={placeholder}
                              onChange={this.handleCode}
                            />
                          )}
                        </FormattedMessage>
                        {!this.state.couponDiscount != "" ? (
                          <span
                            class="input-group-addon app_btn"
                            onClick={this.applyCoupon}
                          >
                            <span>{translate("Apply")}</span>
                          </span>
                        ) : (
                          <span
                            class="input-group-addon app_btn"
                            onClick={this.removeCoupon}
                          >
                            <span>{translate("Remove")}</span>
                          </span>
                        )}
                      </div>
                      <span className="cRed coupon_text">
                        {this.state.code}
                      </span>
                    </div>
                    <div class="fees_hr"></div>
                    {this.state.couponDiscount != "" ? (
                      <div>
                        <h2>{translate("Coupon Discount (-)")}</h2>
                        <h5>
                          {this.state.couponDiscount
                            ? this.state.couponDiscount
                            : 0}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.totDiscountAmount != "" ? (
                      <div>
                        <h2>{translate("Coupon Discount Amount (-)")}</h2>
                        <h5>
                          {this.state.currencySymbol}{" "}
                          {this.state.totDiscountAmount
                            ? parseFloat(this.state.totDiscountAmount).toFixed(
                                2
                              )
                            : 0}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}

                    <div>
                      <h2>{translate("Net Payable Amount")}</h2>
                      <h5>
                        {this.state.currencySymbol}{" "}
                        {parseFloat(this.state.payableAmount)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </h5>
                    </div>
                    <h2>
                      Book Appointment within: {this.state.time.m} m :{" "}
                      {this.state.time.s} s
                    </h2>
                    <div class="fee_terms">
                    <p>
                          <input
                            type="checkbox"
                            class="fee_check"
                            defaultChecked={this.state.checked}
                            onClick={this.handleUpdate}
                          />{" "}
                          {translate("Accept Terms and conditions")}
                        </p>
                      <a href="./patient/TermsConditions" target="_blank">
                     
                      </a>
                      <span className="cRed">{this.state.termsconditions}</span>

                      {this.state.confirm == "" ? (
                        <button
                          type="button"
                          class="fee_book"
                          onClick={this.checkTermsConditions}
                        >
                          {translate("Book Now")}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form
              method="post"
              id="paytmform"
              action={this.state.actionurl}
              name="paytm"
            >
              <table border="1">
                <tbody>
                  <input type="hidden" name="mid" value={constants.paytm_mid} />
                  <input
                    type="hidden"
                    id="appointmentId"
                    name="orderId"
                    value={this.state.selectedappointmentId}
                  />
                  <input
                    type="hidden"
                    id="txnToken"
                    name="txnToken"
                    value={this.state.txnTokenId}
                  />
                </tbody>
              </table>
            </form>
          </section>
          <div
            id="lightbox"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <button
                type="button"
                class="close hidden"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <div class="modal-content">
                <div class="modal-body">
                  <img src={this.state.zoomimage} alt="" />
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
