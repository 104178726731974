import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "./Patientheader";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientMenu from "./Patientmenu";
import PatientSideMenu from "./Patientsidemenu";
import PatientFooter from "./Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import FileBase64 from "react-file-base64";
import DatePicker from "react-datepicker";
import * as SecureLS from "secure-ls";
import { Dropdown } from "semantic-ui-react";
import { Multiselect } from "multiselect-react-dropdown";
import deDE from "../../i18nProvider/messages/de-DE";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

const addressDetailsView = [];

export default class ManageAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {

        Time: "",
      },
      indianStates: [],
      indianCities: [],
      medicineOptions: "",
      selelcteMedicineName: "",
      selectedStartDate: "",
      selectedEndDate: "",
      selectedTimeList: "",
      seletedTimeListDisplay: "",
      start_date: "",
      end_date: "",
      formName: "",
      daySelectionView: false,

      selectedDayList: "",
      errors: {},
      add_address_form: "add_address_form collapse-hide",

    };
    this.fetchmedicinedata = this.fetchmedicinedata.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.getRemainderTimes = this.getRemainderTimes.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;

    this.setState({
      formName: "Add"
    })
    if (handle) {
      this.setState({
        formName: "Update"
      })

      this.getReminderInfo(handle)
    };
    this.getRemainderTimes();


    let Day_selectionValues = [{ id: 1, name: "Monday" }, { id: 2, name: "Tuesday" }, { id: 3, name: "Wednesday" }, { id: 4, name: "Thursday" }, { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" }, { id: 7, name: "Sunday" }]
    this.setState({ Day_selections: Day_selectionValues })

    var retrievedObject = ls.get("userObjEnc");
    let userObj = retrievedObject;
    if (userObj == "") {
      window.location.href = "./patientLogin";
    }
    if (userObj != "") {
      this.setState({
        name: userObj.name,
        user_mobile: userObj.mobile_number,
        email_id: userObj.email,
        token: userObj.accessToken,
        patientId: userObj.patient_id,
        profile_image: "",
      });
      this.forceUpdate();
    }


  };

  // Get remanider dates
  getRemainderTimes = () => {
    Httpconfig.httptokenget(Constant.siteurl + "api/Users/getremaindertimelist")
      .then((response) => {
        this.setState({
          TimeOptions: response.data.data,
        })
      })
      .catch((error) => { });


  }
  // Get remainder Inforamtion
  getReminderInfo(handle) {

    Httpconfig.httptokenget(Constant.siteurl + "api/Remindersetting/findOne/" + handle)
      .then((response) => {
        if (response.data[0].daywise_frequency === "Once") {
          this.setState({
            daywiseFrequency: 1,
            customTimeSelection: false,
            disableStatus: false,
          })

        }
        if (response.data[0].daywise_frequency === "Twice") {
          this.setState({
            daywiseFrequency: 2,
            customTimeSelection: false,
            disableStatus: false,
          })
        }
        if (response.data[0].daywise_frequency === "Thrice") {
          this.setState({
            daywiseFrequency: 3,
            customTimeSelection: false,
            disableStatus: false,
          })
        }
        if (response.data[0].daywise_frequency === "Four times") {
          this.setState({
            daywiseFrequency: 4,
            customTimeSelection: false,
            disableStatus: false,
          })
        }
        if (response.data[0].daywise_frequency === "Custom") {
          var hours = [];
          var minits = [];
          for (var i = 1; i <= 12; i++) {
            var hour = moment(JSON.stringify(i), ["HH"]).format("HH");
            hours.push(hour)
          }
          for (var j = 0; j < 60; j++) {
            var minit = moment(JSON.stringify(j), ["mm"]).format("mm");
            minits.push(minit)
          }

          this.setState({
            daywiseFrequency: 30,
            customTimeSelection: true,
            disableStatus: true,
            hours: hours,
            minits: minits
          })

        }

        this.setState({
          fields: {

            medicinename: response.data[0].medicine_name,
            start_date: response.data[0].fromdate,
            end_date: response.data[0].todate,
            time: response.data[0].time,
            status: response.data[0].status,
            dose: response.data[0].dose,
            week: response.data[0].week,
            daywise_frequency: response.data[0].daywise_frequency,
            day_selection: response.data[0].day_selection,
            intake_instraction: response.data[0].intake_instraction,
            description: response.data[0].description,
            selectedTimeList: response.data[0].time,
            selectedDayList: response.data[0].day_selection,
            select_ringtone: response.data[0].select_ringtone,


          },


        });

        let timeArray = [];
        let timeArray1 = [];
        let times = JSON.parse(response.data[0].time);
        times.map((time, num) => {
          let timeData = {
            id: time,
            name: time,
          };
          timeArray.push(timeData);
          timeArray1.push(time);
        });
        let daySelectionArray = [];
        let dayselect = JSON.parse(response.data[0].day_selection);
        dayselect.map((day, num) => {
          let dayData = {
            name: day,
          };
          daySelectionArray.push(dayData);
        });
        // console.log("-----212-----", timeArray1);
        this.setState({
          start_date: new Date(response.data[0].fromdate),
          end_date: new Date(response.data[0].todate),
          selelcteMedicineName: response.data[0].medicine_name,
          selectedTimeValue: timeArray,
          selectedDayValue: daySelectionArray,
          selectedTimeList: timeArray,
          seletedTimeListDisplay: timeArray1
        });

        response.data[0].week == 'Every day' ? this.setState({
          daySelectionView: false,
        }) : this.setState({
          daySelectionView: true,
        })

        this.forceUpdate();
      })
      .catch((error) => { });
  }

  //  For submitting the form 
  checkSubmit(event) {

    event.preventDefault();

    const { handle } = this.props.match.params;
    if (this.handleValidation()) {

      if (handle) {
        this.updateReminderModule(handle);
      } else {
        this.createReminderModule(event);
      }
    }
  }

  // creates address Module
  createReminderModule = async (event) => {

    event.preventDefault();
    const { fields, errors } = this.state;
    var time = [];
    var shows = this.state.selectedTimeList;

    for (var key in shows) {

      if (shows[key].id == "Invalid date") {
        this.setState({
          errors: {
            Time: "This Time cannot be proper way please check the times",
          }
        })
        return;
      }
      else {
        if (shows.hasOwnProperty(key)) {
          time.push(shows[key].id);
        }
      }

    }

    var timeCount = time.length;
    if (timeCount > this.state.daywiseFrequency) {
      this.setState({
        errors: {
          Time: "Time cannot be more than Frequency",
        }
      })
      return;
    }
    if (moment(this.state.start_date).format("YYYY-MM-DD") > moment(this.state.end_date).format("YYYY-MM-DD")) {
      toast.error("From date  do not  greater than todate ");
      return;
    }

    var showSelectDay = this.state.selectedDayList;
    var DaySelectionArr = [];
    for (var key in showSelectDay) {
      if (showSelectDay.hasOwnProperty(key)) {
        DaySelectionArr.push(showSelectDay[key].name);
      }

    }


    Httpconfig.httptokenpost(Constant.siteurl + "api/Remindersetting", {
      "patient_id": this.state.patientId,
      "medicine_name": this.state.selelcteMedicineName,          //fields["medicinename"],
      "numberoftimes": 3,         //fields["numberoftimes"],
      "fromdate": moment(this.state.start_date).format("YYYY-MM-DD"),
      "todate": moment(this.state.end_date).format("YYYY-MM-DD"),
      "time": time,
      "dose": fields["dose"],
      "week": fields["week"],
      "daywise_frequency": fields["daywise_frequency"],
      "day_selection": DaySelectionArr,  //fields["day_selection"],
      "intake_instraction": fields["intake_instraction"],
      "description": fields["description"],
      "select_ringtone": fields["select_ringtone"],
      "status": 1

    })
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Successfully Created Patient Reminder");

          setTimeout(() => this.props.history.push("/ViewReminderSetting"));
          // window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
      });
  };

  // updates controller
  updateReminderModule = async (id) => {
    // event.preventDefault();    
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    var time = [];
    var shows = this.state.selectedTimeList;
    for (var key in shows) {
      if (shows.hasOwnProperty(key)) {
        time.push(shows[key].id);
      }

    }
    var timeCount = time.length;
    if (timeCount > this.state.daywiseFrequency) {
      this.setState({
        errors: {
          Time: "Time cannot be more than Frequency",
        }
      })
      return;
    }
    var finalTime = time.length > 0 ? time : JSON.parse(fields["time"]);

    var showSelectDay = this.state.selectedDayList;
    var DaySelectionArr = [];
    for (var key in showSelectDay) {
      if (showSelectDay.hasOwnProperty(key)) {
        DaySelectionArr.push(showSelectDay[key].name);
      }

    }
    var finalDay_selection = DaySelectionArr.length > 0 ? DaySelectionArr : JSON.parse(fields["day_selection"]);
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Remindersetting/" + id,
      {
        // fromdate: moment(this.state.start_date).format("YYYY-MM-DD"),
        // todate: moment(this.state.end_date).format("YYYY-MM-DD"),
        // time : fields["time"],
        "patient_id": this.state.patientId,
        "medicine_name": this.state.selelcteMedicineName,    //fields["medicinename"],
        "numberoftimes": 3, //fields["numberoftimes"],  
        "fromdate": moment(this.state.start_date).format("YYYY-MM-DD"),
        "todate": moment(this.state.end_date).format("YYYY-MM-DD"),
        "time": finalTime,     //time,
        "dose": fields["dose"],
        "week": fields["week"],
        "daywise_frequency": fields["daywise_frequency"],
        "day_selection": finalDay_selection,                     //fields["day_selection"],
        "intake_instraction": fields["intake_instraction"],
        "description": fields["description"],
        "select_ringtone": fields["select_ringtone"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Successfully Updated Manage Address");
          setTimeout(() => this.props.history.push("/ViewReminderSetting"));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };



  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;

    fields[field] = event.target.value;
    if (field == 'intake_instraction') {
      if (event.target.value == "Select") return
    }

    if (field == 'daywise_frequency') {

      var selelcteValue = event.target.value;
      // selectedTimeList: "",
      // selectedTimeValue: "",
      this.setState({

        disableStatus: false,
      })

      if (selelcteValue === "Once") {

        this.setState({
          daywiseFrequency: 1,
          customTimeSelection: false,
        })

      }

      if (selelcteValue === "Twice") {
        this.setState({
          daywiseFrequency: 2,
          customTimeSelection: false,
        })
      }
      if (selelcteValue === "Thrice") {
        this.setState({
          daywiseFrequency: 3,
          customTimeSelection: false,
        })
      }
      if (selelcteValue === "Four times") {
        this.setState({
          daywiseFrequency: 4,
          customTimeSelection: false,
        })
      }

      if (selelcteValue === "Custom") {
        var hours = [];
        var minits = [];
        for (var i = 1; i <= 12; i++) {
          var hour = moment(JSON.stringify(i), ["HH"]).format("HH");
          hours.push(hour)
        }
        for (var j = 0; j < 60; j++) {
          var minit = moment(JSON.stringify(j), ["mm"]).format("mm");
          minits.push(minit)
        }

        this.setState({
          daywiseFrequency: 30,
          customTimeSelection: true,
          disableStatus: true,
          hours: hours,
          minits: minits
        })

      }

    }

    if (field == 'week') {
      if (event.target.value === 'Every day') {
        this.state.daySelectionView = false;
      } else {
        this.state.daySelectionView = true;
      }
      this.forceUpdate();
    }

    //   if(this.state.customTimeSelection === true && field == "time")
    //   {

    // var valuesdata = event.target.value.toString();     
    // var dd = [];
    // var array =  valuesdata.split(",");
    //  array.map((item)=>{
    //  var changeFomaton12 =  moment(JSON.stringify(item), ["HH:mm"]).format("hh:mm a")
    //  var obj={
    //     id:changeFomaton12,  name:changeFomaton12
    //   }
    //   dd.push(obj);
    //  })
    //     this.setState({
    //       selectedTimeList: dd,
    //       selectedLeftTimeList: dd,
    //     });
    //   }



    this.setState({ fields });
  };

  addTimers = () => {
    const { fields, errors } = this.state;
    if (this.state.customTimeSelection === true) {
      if (fields["hour"] === undefined || fields["minit"] == undefined || fields["hoursformat"] == undefined) {
        alert("Please select proper time format");
      }
      else {
        var time = [];
        var distimeArr = [];
        var shows = this.state.selectedTimeList;
        // console.log("------2525----", fields["hour"]+"-----"+ fields["minit"] +"-----"+ fields["hoursformat"]);

        if (shows) {
          const isFound = shows.some(element => {
            if (element.id === fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"]) {
              return true;
            }
            return false;
          });
          if (isFound === false) {
            var obj = {
              id: fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"], name: fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"]
            }
            time.push(obj)
            distimeArr.push(fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"])
          }
          else {
            alert("Already same time is added please check the time")
          }


        }
        else {
          var obj = {
            id: fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"], name: fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"]
          }
          time.push(obj)
          distimeArr.push(fields["hour"] + ':' + fields["minit"] + ' ' + fields["hoursformat"])
        }
        if (shows) {
          shows.map((item) => {
            time.push(item);
          })
        }
        var shows1 = this.state.seletedTimeListDisplay;
        if (shows1) {
          shows1.map((item) => {
            distimeArr.push(item);
          })

        }
        this.setState({
          selectedTimeList: time,
          selectedLeftTimeList: time,
          seletedTimeListDisplay: distimeArr
        });
      }

    }

  };




  handleChangeInput = (event) => {
    let searched = event.currentTarget.id;
    let medicineName = event.currentTarget.innerText;
    this.state.selelcteMedicineId = searched;
    this.state.selelcteMedicineName = medicineName;
    this.state.medicineOptions = "";
    $("#medicineList").val("");
    $("#medicineList").val(medicineName);
    this.forceUpdate();
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.selelcteMedicineName) {
      formIsValid = false;
      errors["medicinename"] = "Medicine cannot be empty";
    }
    if (!fields["dose"]) {
      formIsValid = false;
      errors["dose"] = "Dose cannot be empty";
    }
    if (!fields["week"]) {
      formIsValid = false;
      errors["week"] = "Day/Week cannot be empty";
    }
    if (!fields["intake_instraction"]) {
      formIsValid = false;
      errors["intake_instraction"] = "AF/BF cannot be empty";   //AC/PC
    }
    // if (this.state.daySelectionView === true) {
    //   if (!fields["day_selection"]) {
    //     formIsValid = false;
    //     errors["day_selection"] = "Day Selection cannot be empty";
    //   }
    // }
    if (this.state.daySelectionView === true) {
      if ((this.state.selectedDayValue === undefined || this.state.selectedDayValue.length === 0) && this.state.selectedDayList.length == 0) {
        formIsValid = false;
        errors["day_selection"] = "Day Selection cannot be empty";
      }
    }

    if (!fields["daywise_frequency"]) {
      formIsValid = false;
      errors["daywise_frequency"] = "Frequency cannot be empty";
    }
    // if (!fields["numberoftimes"]) {
    //   formIsValid = false;
    //   errors["numberoftimes"] = "Mobile No cannot be empty";
    // }
    if (this.state.start_date === "") {
      formIsValid = false;
      errors["start_date"] = "Start date cannot be empty";
    }
    if (this.state.end_date === "") {
      formIsValid = false;
      errors["end_date"] = "End date type cannot be empty";
    }
    if ((this.state.selectedTimeValue === undefined || this.state.selectedTimeValue.length === 0) && this.state.selectedTimeList.length == 0) {
      formIsValid = false;
      errors["Time"] = "Please check the Frequency and time slots should be same.";
    }
    else if (this.state.daywiseFrequency > this.state.selectedTimeList.length && this.state.daywiseFrequency != 30) {
      formIsValid = false;
      errors["Time"] = "Please check the Frequency and time should be same.";
    }

    // if (fields["Time"]==="") {
    //   formIsValid = false;
    //   errors["Time"] = "Time cannot be empty";
    // }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "Instructions cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  //   setStartDateOnChange = (value) => {

  setStartDateOnChange = (value) => {

    this.setState({ start_date: value });
  };
  setEndDateOnChange = (value) => {
    this.setState({ end_date: value });
  };

  //  fetch master medicine data on search
  fetchmedicinedata(event) {
    let search = event.target.value;
    this.state.search = search;
    if (search.length > 2) {
      if (search != "") {
        Httpconfig.httptokenpost(
          Constant.siteurl + "api/productsMaster/findbyname", {
          name: search
        }
          //Constant.siteurl + "api/DIAGNOSIS/diagnosisProduct/medicine/" + search
        ).then((response) => {
          if (Object.keys(response.data.data).length > 0) {
            const medicineDetailsView = response.data.data.map(
              (finalLoadedData, num) => {
                return (
                  <li id={finalLoadedData.id} onClick={this.handleChangeInput}>
                    {finalLoadedData.medicinename}
                  </li>
                );
              }
            );
            this.state.medicineOptions = medicineDetailsView;
            this.forceUpdate();
          } else {
            this.state.medicineOptions = "";
            this.state.selelcteMedicineName = search;
            this.forceUpdate();
          }
        });
      } else {
        this.state.medicineOptions = "";
        this.forceUpdate();
      }
    }
    else {
      this.state.medicineOptions = "";
      this.forceUpdate();
    }

  }

  // // handle time validation on change
  // handleValidation() {
  //   let fields = this.state.fields;
  //   let errors = {};
  //   let formIsValid = true;

  //   this.setState({ errors: errors });
  //   return formIsValid;
  // }

  //  for selecting the Time
  onTimeSelect = (selectedTimeListdata, selectedItem) => {

    let timeLenght = Object.keys(selectedTimeListdata).length;
    let selectedFrequency = this.state.daywiseFrequency;
    if (selectedFrequency === timeLenght) {
      this.setState({
        selectedTimeList: selectedTimeListdata,
        selectedLeftTimeList: selectedTimeListdata,
      });



      // }else if (selectedFrequency === 2 && timeLenght === 2) {
      //   this.setState({
      //     selectedTimeList: selectedTimeListdata,
      //     selectedLeftTimeList: selectedTimeListdata,
      //   });
      // }else if (selectedFrequency === 3 && timeLenght === 3) {
      //   this.setState({
      //     selectedTimeList: selectedTimeListdata,
      //     selectedLeftTimeList: selectedTimeListdata,
      //   });
      // }else if (selectedFrequency === 4 && timeLenght === 4) {
      //   this.setState({
      //     selectedTimeList: selectedTimeListdata,
      //     selectedLeftTimeList: selectedTimeListdata,
      //   });
    }
    // else{
    //   this.state.errors["Time"] = "Please check the Frequency and time slots should be same.";
    //   this.forceUpdate();
    // }
    // else{
    //   
    // }
    this.forceUpdate();

  };

  //  For removing the Time
  onTimeRemove = (deselectedTimeList, removedItem) => {
    this.setState({
      selectedTimeValue: deselectedTimeList,
      selectedTimeList: deselectedTimeList,
      selectedLeftTimeList: deselectedTimeList,
    });
  };

  onDaySelection = (selectedDayList, selectedItem) => {

    let timeLenght = Object.keys(selectedDayList).length;
    let selectedFrequency = this.state.daywiseFrequency;
    if (selectedFrequency === timeLenght) {
      this.setState({
        selectedDayList: selectedDayList,
        selectedLeftTimeList: selectedDayList,
      });

    }
    this.setState({
      selectedDayList: selectedDayList,
    });

    this.forceUpdate();

  };
  //  For removing the Time
  onDayselectionRemove = (deselectedDayList, removedItem) => {
    this.setState({
      selectedDayValue: deselectedDayList,
      selectedDayList: deselectedDayList,
      selectedLeftTimeList: deselectedDayList,
    });
  };
  removeImageOnClick = (event) => {

    var array = this.state.selectedTimeList;
    var array2 = this.state.seletedTimeListDisplay;
    var index = array.findIndex((item) => item.id === event.currentTarget.id);
    var index2 = array2.findIndex((item) => item === event.currentTarget.id);

    if (index !== -1) {
      array.splice(index, 1);
    }
    if (index2 !== -1) {
      array2.splice(index2, 1);
    }

    this.setState({
      selectedTimeList: array,
      selectedLeftTimeList: array,
      seletedTimeListDisplay: array2
    });
  };

  // Play notification sounds
  playNotificationSound = () => {

    var sound = this.state.fields.select_ringtone;
    var playSound = "";

    if (sound == 'Pill_time') {
      playSound = "pill_time.mp3";
    }
    if (sound == 'Pills') {
      playSound = "pills.mp3";
    }
    if (sound == 'Notify Tingle') {
      playSound = "notify_tingle.mp3";
    }
    if (sound == 'Take your Pills') {
      playSound = "take_your_pills.mp3";
    }
    if (sound == 'Medical') {
      playSound = "medical.mp3";
    }
    if (sound == 'Remainder Audio') {
      playSound = "remaindernotification.mp3";
    }

    var url = Constant.imgurl + "/sounds/" + playSound;
    const audio = new Audio(url);
    audio.play();

  }

  render() {

    return (
      <main>
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="main_dashboard">
            <div class="container medicine_main">
              <div class="row">
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                  <PatientSideMenu />
                </div>
                <div class="col-md-9">
                  <section id="add_reminder">
                    <form onSubmit={this.checkSubmit.bind(this)}>
                      <div class="container">
                        <div class="row">
                          <div class="col-md-10 no_padding">
                            <div class="heading">
                              <h2> <Link
                                to="/Viewremindersetting">
                                <span ><img src="../images/patient/img/arrow-back.svg" /></span>
                              </Link>
                                {"       "}
                                {this.state.formName} Medicine Reminder </h2>
                            </div>
                            <div class="rem_form">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <div class="search">
                                      <img class="searchicon" src="../images/patient/img/searchbar.svg" />
                                      {/* <input type="text"  id="medicineList"   onKeyUp={this.fetchmedicinedata} value={this.state.fields["medicinename"] || ""}  class="form-control" placeholder="Add/Search Medicine" /> */}
                                      {/* <button type="button" class="searchbtn">Add</button> onChange={this.handleChange.bind(
                                                                        this,
                                                                        "medicinename"
                                                                    )}    */}
                                      <input
                                        type="text"
                                        class="form-control "
                                        autocomplete="off"
                                        defaultValue={this.state.fields["medicinename"] || ""}
                                        name="product_name"
                                        id="medicineList"
                                        placeholder="Search Medicine"
                                        onKeyUp={this.fetchmedicinedata}
                                      />
                                      <ul id="searchResult">{this.state.medicineOptions}</ul>
                                      <span className="cRed">
                                        {this.state.errors["medicinename"]}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">

                                  <div class="form-group">
                                    <label>Schedule</label>
                                    <div class="rem_schedule">
                                      <div class="col-md-6 col-xs-6 pad_no">


                                        <div class="from-date">


                                          <DatePicker
                                            name="start_date"
                                            autoComplete="off"
                                            className="dateInput"
                                            placeholderText="Start Date"
                                            popperPlacement="bottom"
                                            minDate={new Date()}
                                            popperModifiers={{
                                              flip: {
                                                behavior: ["bottom"],
                                              },
                                            }}
                                            selected={this.state.start_date}
                                            onChange={this.setStartDateOnChange}
                                            dateFormat="yyyy-MM-d"
                                            calendarIcon
                                            showMonthDropdown
                                            adjustDateOnChange
                                          />
                                          <img src="../images/patient/img/date-icon.svg" />
                                        </div>
                                        <span className="cRed">
                                          {this.state.errors["start_date"]}
                                        </span>
                                      </div>
                                      <div class="col-md-6 col-xs-6 pad_no">


                                        <div class="to-date">
                                          <DatePicker
                                            name="end_date"
                                            autoComplete="off"
                                            className="dateInput"
                                            placeholderText="End Date"
                                            popperPlacement="bottom"
                                            minDate={new Date()}
                                            popperModifiers={{
                                              flip: {
                                                behavior: ["bottom"],
                                              },
                                            }}
                                            selected={this.state.end_date}
                                            onChange={this.setEndDateOnChange}
                                            dateFormat="yyyy-MM-dd"
                                            calendarIcon
                                            showMonthDropdown
                                            adjustDateOnChange
                                          />
                                          <img src="../images/patient/img/date-icon.svg" />
                                        </div>
                                        <span className="cRed">
                                          {this.state.errors["end_date"]}
                                        </span>
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Dose</label>
                                    <input type="text" name="dose" value={this.state.fields["dose"] || ""} onChange={this.handleChange.bind(
                                      this,
                                      "dose"
                                    )} class="form-control" placeholder="1 pill or 1 capsule" />
                                    <span className="cRed">
                                      {this.state.errors["dose"]}
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Daily/Weekly</label>
                                    <select class="form-control" name="week" value={this.state.fields["week"] || ""} onChange={this.handleChange.bind(
                                      this,
                                      "week"
                                    )}>
                                      <option>Select</option>
                                      <option>Every day</option>
                                      <option>Every week</option>
                                      {/* <option>Every Month</option> */}
                                    </select>
                                    <span className="cRed">
                                      {this.state.errors["week"]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label>Frequency</label>
                                    <select class="form-control" name="daywise_frequency" value={this.state.fields["daywise_frequency"] || ""} onChange={this.handleChange.bind(
                                      this,
                                      "daywise_frequency"
                                    )}>
                                      <option value="">Select</option>
                                      <option id="1">Once</option>
                                      <option id="2">Twice</option>
                                      <option id="3">Thrice</option>
                                      <option id="4">Four times</option>
                                      <option id="5">Custom</option>
                                    </select>
                                    <span className="cRed">
                                      {this.state.errors["daywise_frequency"]}
                                    </span>
                                  </div>
                                </div>
                                {this.state.daySelectionView}
                                {this.state.daySelectionView == true ?
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Day Selection</label>

                                      {/* <select class="wth_check form-control" id="day_selection" name="day_selection" value={this.state.fields["day_selection"] || ""} onChange={this.handleChange.bind(
                                        this,
                                        "day_selection"
                                      )}>
                                        <option value="">Select</option>
                                        <option>Monday</option>
                                        <option>Tuesday</option>
                                        <option>Wednesday</option>
                                        <option>Thursday</option>
                                        <option>Friday</option>
                                        <option>Saturday</option>
                                        <option>Sunday</option>
                                      </select> */}

                                      <Multiselect
                                        onChange={this.handleChange.bind(
                                          this,
                                          "day_selection"
                                        )}
                                        name="day_selection"
                                        id="day_selection"
                                        options={
                                          this.state.Day_selections
                                        }
                                        value={this.state.selectedDayList || ""}
                                        selectedValues={this.state.selectedDayValue}
                                        onSelect={this.onDaySelection}
                                        placeholder="Select Day Selection"
                                        onRemove={this.onDayselectionRemove}
                                        displayValue="name"
                                      />
                                      <span className="cRed">
                                        {this.state.errors["day_selection"]}
                                      </span>
                                    </div>
                                  </div>
                                  : ""}

                                {this.state.disableStatus === false ?

                                  <div class="col-md-4">

                                    <div class="form-group">
                                      <label>Reminder Time</label>
                                      <div class="rem-time">
                                        {/* <select class="form-control" name ="time" multiple value={this.state.fields["time"] || ""} onChange={this.handleChange.bind(
                                                                        this,
                                                                        "time"
                                                                    )}>
                                  <option>08:00</option>
                                  <option>09:00</option>
                                  <option>10:00</option>
                                  <option>11:00</option>
                                  <option>13:00</option>
                                  <option>16:00</option>
                                </select> */}

                                        <Multiselect
                                          onChange={this.handleChange.bind(
                                            this,
                                            "Time"
                                          )}
                                          name="Time"
                                          id="Time"
                                          options={
                                            this.state.TimeOptions
                                          }
                                          selectionLimit={this.state.daywiseFrequency ? this.state.daywiseFrequency : 4}
                                          value={
                                            this.state
                                              .selectedTimeList || ""
                                          }
                                          selectedValues={
                                            this.state.selectedTimeValue
                                          }
                                          onSelect={this.onTimeSelect}
                                          placeholder="Select Time"
                                          onRemove={this.onTimeRemove}
                                          displayValue="name"


                                        />
                                        <span className="cRed">
                                          {this.state.errors["Time"]}
                                        </span>


                                      </div>
                                    </div>
                                  </div>
                                  : ""}
                                {this.state.customTimeSelection == true ?
                                  <div class="col-md-8">

                                    <div class="form-group">
                                      <label>Reminder Time</label>
                                      <div class="rem-time">
                                        <div class="custom_time">
                                          <select name="hour" className="form-control" value={this.state.fields["hour"] || ""} onChange={this.handleChange.bind(
                                            this,
                                            "hour"
                                          )}>
                                            <option>Select HH</option>
                                            {
                                              this.state.hours && this.state.hours.map((hour, i) => {

                                                return (
                                                  <option value={hour}>{hour}</option>
                                                )
                                              })
                                            }
                                          </select>
                                          <select name="minit" className="form-control" value={this.state.fields["minit"] || ""} onChange={this.handleChange.bind(
                                            this,
                                            "minit"
                                          )} >
                                            <option>Select MM</option>
                                            {
                                              this.state.minits && this.state.minits.map((minit, i) => {

                                                return (
                                                  <option value={minit}>{minit}</option>
                                                )
                                              })
                                            }
                                          </select>
                                          <select class="form-control" name="hoursformat" value={this.state.fields["hoursformat"] || ""} onChange={this.handleChange.bind(
                                            this,
                                            "hoursformat"
                                          )}>
                                            <option>Select AM/PM</option>
                                            <option value="am">AM</option>
                                            <option value="pm">PM</option>
                                            {/* <option>Every Month</option> */}
                                          </select>

                                          <button class="Add_btn" type="button" onClick={this.addTimers}>Add</button>
                                        </div>
                                        {Object.keys(this.state.seletedTimeListDisplay).length > 0 ?
                                          this.state.seletedTimeListDisplay.map((item, i) => {
                                            return (
                                              <div class="selected_time"> <p >{item}<img class="delete_img" id={item} onClick={this.removeImageOnClick} src="../images/patient/img/cross.svg" /> </p></div>
                                            )
                                          }) : ""
                                        }
                                      </div>
                                      <span className="cRed">
                                        {this.state.errors["Time"]}
                                      </span>
                                    </div> </div> : " "}


                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label>AF/BF</label>
                                    <select class="form-control" name="intake_instraction" value={this.state.fields["intake_instraction"] || ""} onChange={this.handleChange.bind(
                                      this,
                                      "intake_instraction"
                                    )}>
                                      <option value="">Select</option>
                                      <option>Before Food</option>
                                      <option>After Food</option>
                                      <option>Any</option>

                                    </select>
                                    <span className="cRed">
                                      {this.state.errors["intake_instraction"]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-10">
                                  <div class="form-group">
                                    <label>Select Ring tone</label>
                                    <div className="play_section">
                                    <select class="form-control" name="select_ringtone" value={this.state.fields["select_ringtone"] || ""} onChange={this.handleChange.bind(
                                      this,
                                      "select_ringtone"
                                    )}>
                                      <option value="">Select</option>
                                      {/* <option>Violin</option> */}
                                      <option>Pill_time</option>
                                      <option>Pills</option>
                                      <option>Notify Tingle</option>
                                      <option>Take your Pills</option>
                                      <option>Medical</option>
                                      <option>Remainder Audio</option>
                                    </select>
                                    <span className="play_btn" onClick={this.playNotificationSound}>Play</span>
                                    </div>
                                    <span className="cRed">
                                      {this.state.errors["select_ringtone"]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label>Other Instructions</label>
                                    <textarea name="description" value={
                                      this.state.fields["description"] ||
                                      ""
                                    }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "description"
                                      )} class="form-control" rows="4" />
                                    <span className="cRed">
                                      {this.state.errors["description"]}
                                    </span>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div class="rem_btns">
                              <button type="submit" class="save_btn">Save</button>
                              {/* <button type="button" class="cancel_btn">Cancel</button> */}
                              <Link to="/Viewremindersetting" className="cancel_btn">Cancel</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* <section id="order_profile">
            <div className="appoint_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <PatientSideMenu />
                  </div>

                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <section id="order_add_address">
                            <div className="add_address_sec">
                              <div className="add_address_head">
                                <h2>Create Reminder Form</h2>
                              </div>
                             

                              <div className="add_address_form collapse-show">
                                <form onSubmit={this.checkSubmit.bind(this)}>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Medicine Name</label>
                                        <input
                                          type="text"
                                          name="medicinename"
                                          value={this.state.fields.medicinename}
                                          className="form-control"
                                          placeholder="Medicine Name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "medicinename"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["medicinename"]}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <label>Number Of Times</label>
                                        <input
                                          type="text"
                                          name="numberoftimes"
                                         
                                          value={
                                            this.state.fields["numberoftimes"] || ""
                                          }
                                          className="form-control"
                                          placeholder="Number Of Times"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "numberoftimes"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["numberoftimes"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                
                                  <div className="form-group">
                                    <div className="row">
                                    <div className="col-md-6">
                                        <label>Start Date</label>
                                       
                                         <DatePicker
                                  name="start_date"
                                  autoComplete="off"
                                  className="dateInput"
                                  placeholderText="Start Date"
                                  popperPlacement="bottom"
                                  minDate={new Date()}
                                  popperModifiers={{
                                    flip: {
                                      behavior: ["bottom"],
                                    },
                                  }}
                                  
                                  selected={this.state.start_date}
                                  onChange={this.setStartDateOnChange}
                                  dateFormat="yyyy-MM-d"
                                  calendarIcon
                                  showMonthDropdown
                                  adjustDateOnChange
                                />
                              
                                        <span className="cRed">
                                          {this.state.errors["start_date"]}
                                        </span>
                                      </div> 

                                      <div className="col-md-6">
                                        <label>To Date</label>
                                        <DatePicker
                                  name="end_date"
                                  autoComplete="off"
                                  className="dateInput"
                                  placeholderText="End Date"
                                  popperPlacement="bottom"
                                  minDate={new Date()}
                                  popperModifiers={{
                                    flip: {
                                      behavior: ["bottom"],
                                    },
                                  }}
                                  selected={this.state.end_date}
                                  onChange={this.setEndDateOnChange}
                                  dateFormat="yyyy-MM-dd"
                                  calendarIcon
                                  showMonthDropdown
                                  adjustDateOnChange
                                />
                                      
                                        <span className="cRed">
                                          {this.state.errors["end_date"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Time</label>
                                        <input
                                          type="time"
                                          name="time"
                                          value={this.state.fields.time}
                                          className="form-control"
                                          placeholder="Enter Time"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "time"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["time"]}
                                        </span>
                                      </div>
                                    
                                    </div>
                                  </div>

                                

                                  <div className="rem_btns">
                                    <button
                                      type="submit"
                                      className="save-btn"
                                    >
                                      Save
                                    </button>
                                    <Link
                                        to="/ViewReminderSetting"
                                    
                                        className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              
                                  </div>
                                </form>
                              </div>

                           
                            </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
