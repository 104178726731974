import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import moment from "moment-timezone";
import Admincss from "../../public/css/admin/css/admin.css";
import Httpconfig from "../helpers/HttpconfigAdmin";
import { ToastContainer } from "react-toastify";
import Constant from "../../constants";
import toast from "../../helpers/toast";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
    };
  }

  today = moment();
  //  For opennav
  openNav = () => {
    if (document.getElementById("mySidenav")) {
      document.getElementById("mySidenav").style.width = "250px";
    }
  };

  //  For logout
  logouts = (e) => {
    var retrievedObject = localStorage.getItem("AdminUserObj");
    if (retrievedObject == null) {
      window.location.href = "/admin/login";
    }
    let userData = JSON.parse(retrievedObject);

    let userID = userData.userID;
    Httpconfig.httptokenget(Constant.siteurl + "api/Users/logout/" + userID)
      .then((response) => {
        if (response.data.status == "200" && response.data.error === false) {
          localStorage.removeItem("AdminUserObj");
          localStorage.removeItem("AdminToken");
          localStorage.setItem("validated", false);
          toast.success(response.data.message);
          setTimeout(() => window.location.reload(), 1000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  Get the value for first render
  componentDidMount = () => {
    var retrievedObject = localStorage.getItem("AdminUserObj");
    if (retrievedObject == null) {
      window.location.href = "/admin/login";
    }
    let userData = JSON.parse(retrievedObject);
    this.validateToken();
  };
  //  For logout
  logout = () => {
    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  };

  //  For validating the token
  validateToken = () => {
    var retrievedObject = localStorage.getItem("AdminUserObj");

    if (retrievedObject == null) {
      window.location.href = "/admin/login";
    }

    let userData = JSON.parse(retrievedObject);
    let userId = userData.userID;
    if (retrievedObject != "") {
      let token = localStorage.getItem("AdminToken");
      if (token != "") {
        Httpconfig.httptokenpost(Constant.siteurl + "api/Users/checkToken", {
          userid: userId,
          token: token,
        })
          .then((response) => {
            if (response.data.status == "200" && response.data.error === true) {
              localStorage.removeItem("AdminUserObj");
              localStorage.removeItem("AdminToken");
              setTimeout(() => window.location.reload(), 1000);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        toast.error("Your Session has timed out.Please Relogin");
        localStorage.removeItem("AdminUserObj");
        localStorage.removeItem("AdminToken");
        setTimeout(() => window.location.reload(), 1000);
      }
    } else {
      toast.error("Your Session has timed out.Please Relogin");
      localStorage.removeItem("AdminUserObj");
      localStorage.removeItem("AdminToken");
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  render() {
    return (
      <nav className="navbar navbar-default navbar-fixed-top nav-head">
        <div className="container-fluid">
          <div className="navbar-header">
            <a href="/admin" class="scrollto">
              <img
                src={
                  window.location.origin + "/images/patient/img/main-logo.png"
                }
                alt=""
                class="img-fluid admin-logo"
              />
            </a>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <a className="navbar-brand" href="#">
              <i className="fa fa-bars admin_menu" onClick={this.openNav}></i>
            </a>
          </div>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a className="dropdown-btn side_txt" onClick={this.logout}>
                <img
                  src="/img/profile.png"
                  className="user-image"
                  alt="User Image"
                />
                Admin <span className="caret"></span>
              </a>
              <div className="dropdown-container drp_content">
                <a href="#" onClick={this.logouts}>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
        <ToastContainer />
      </nav>
    );
  }
}
