import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class SideBar extends Component {
  closeNav = () => {
    if (document.getElementById("mySidenav")) {
      document.getElementById("mySidenav").style.width = "0";
    }
  };

  render() {
    return (
      <div id="mySidenav" className="sidenav">
        <a href="#" className="closebtn" onClick={this.closeNav}>
          &times;
        </a>
        <div id="accordion">
          <div className="side_box">
            <Link className="side_txt" to="/admin" onClick={this.closeNav}>
              Dashboard
            </Link>

            <div
              className="dropdown-container show"
              id="collapseOne"
              class="collapse"
              data-parent="#accordion"
            >
              <Link
                className="side_txt active"
                to="../admin/Viewmastermodules"
                onClick={this.closeNav}
              >
                Sub Modules
              </Link>
            </div>

            <a
              className="dropdown-btn side_txt"
              onClick={this.showmenu}
              data-toggle="collapse"
              href="#collapseTwo"
            >
              Configurations
              <i className="fa fa-caret-down"></i>
            </a>
            <div
              className="dropdown-container"
              id="collapseTwo"
              class="collapse"
              data-parent="#accordion"
            >
              <Link
                className="side_txt active"
                to="/admin/Viewcategory"
                onClick={this.closeNav}
              >
                Category
              </Link>
              <Link
                className="side_txt active"
                to="/admin/Viewlanguages"
                onClick={this.closeNav}
              >
                Languages
              </Link>
              <Link
                className="side_txt active"
                to="/admin/Createviewrelationships"
                onClick={this.closeNav}
              >
                Relationships
              </Link>
              <Link
                className="side_txt active"
                to="/admin/Viewspecializations"
                onClick={this.closeNav}
              >
                Specializations
              </Link>
              <Link
                className="side_txt active"
                to="../admin/Viewcancellationreasons"
                onClick={this.closeNav}
              >
                Order Cancellation Reasons
              </Link>
            </div>
            <a
              className="dropdown-btn side_txt"
              data-toggle="collapse"
              href="#collapseThree"
            >
              Clinic Setup <i className="fa fa-caret-down"></i>
            </a>
            <div
              className="dropdown-container"
              id="collapseThree"
              class="collapse"
              data-parent="#accordion"
            >
              {/* <Link
                className="side_txt active"
                to="/admin/Viewmastersubclinicpagemodules"
                onClick={this.closeNav}
              >
                Clinic Page Modules
              </Link> */}
              <Link
                className="side_txt active"
                to="/admin/ViewClinics"
                onClick={this.closeNav}
              >
                View Clinics
              </Link>
              {/* <Link
                className="side_txt active"
                to="/admin/ViewDoctorAdmin"
                onClick={this.closeNav}
              >
                View Doctor Admin
              </Link> */}
              <Link
                className="side_txt active"
                to="/admin/ViewDoctors"
                onClick={this.closeNav}
              >
                View Health Care Provider
              </Link>

              <Link
                className="side_txt active"
                to="/admin/ViewDoctorSlots"
                onClick={this.closeNav}
              >
                View Slots
              </Link>

              <Link
                className="side_txt active"
                to="/admin/Viewcoupons"
                onClick={this.closeNav}
              >
                View Coupons
              </Link>
              <Link
                className="side_txt active"
                to="/admin/Viewsubscriptions"
                onClick={this.closeNav}
              >
                View Subscriptions
              </Link>
              <Link
                className="side_txt active"
                to="/admin/ViewSubscriptionUsers"
                onClick={this.closeNav}
              >
                View Users Subscription
              </Link>
            </div>

            <a
              className="dropdown-btn side_txt"
              data-toggle="collapse"
              href="#collapseDiagnosis"
            >
              Diagnostics <i className="fa fa-caret-down"></i>
            </a>
            <div
              className="dropdown-container"
              id="collapseDiagnosis"
              class="collapse"
              data-parent="#accordion"
            >
              <Link
                className="side_txt active"
                to="/admin/ViewDiagnostics"
                onClick={this.closeNav}
              >
                View Diagnostics
              </Link>
              <Link
                className="side_txt active"
                to="../admin/ViewProductrequest"
                onClick={this.closeNav}
              >
                {/* <i className="fa fa-dashboard"></i> */}
                Product Request
              </Link>
              <Link
               className="side_txt active"
               to="../admin/ViewMasterProducts"
               onClick={this.closeNav}
              >
                View Products
              </Link>
              <Link
                className="side_txt active"
                to="../admin/Vieworders"
                onClick={this.closeNav}
              >
                {/* <i className="fa fa-dashboard"></i> */}
                Lab Reports
              </Link>
            </div>

            <a
              className="dropdown-btn side_txt"
              data-toggle="collapse"
              href="#collapseSix"
            >
              Reports
              <i className="fa fa-caret-down"></i>
            </a>
            <div
              className="dropdown-container"
              id="collapseSix"
              class="collapse"
              data-parent="#accordion"
            >
              <Link
                className="side_txt active"
                to="../admin/viewConsultations"
                onClick={this.closeNav}
              >
                Consultations
              </Link>

              <Link
                className="side_txt active"
                to="../admin/Coupons"
                onClick={this.closeNav}
              >
                Coupons
              </Link>

              <Link
                className="side_txt active"
                to="../admin/ClinicPatients"
                onClick={this.closeNav}
              >
                Clinic Patients
              </Link>
              <Link
                className="side_txt active"
                to="../admin/SearchAppointments"
                onClick={this.closeNav}
              >
                Search Appointments
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  F;
}

export default withRouter(SideBar);
