import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { TextArea } from "semantic-ui-react";
const moment = require("moment");

export default class CreatediagnosticsMasterProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      retailer_data: "",
      type: "password",
      options: [],
      img: "/images/doctor-img/Login screens/hide_icon.svg",

    };
    this.showPassword = this.showPassword.bind(this);
  }
  //  for setStartTimeOnChange
  setStartTimeOnChange = (field, event) => {
    let expDate = event.target.value;
    this.setState({ registration_expirity: expDate });
    this.state.fields["registration_expirity"] =
      moment(expDate).format("YYYY-MM-DD");
    let count = expDate.search("-");
    if (count > 0) {
      let dateString = expDate.split("-");
      if (dateString[0].length != 4) {
        toast.error("Invalid expired date");
        return;
      }
    }

    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
    this.state.errors[field] = "";
  };
  // for showPassword
  showPassword() {
    let type = this.state.type;
    if (this.state.type) {
      if (this.state.type == "input") {
        this.state.type = "password";
        this.state.img = "/images/doctor-img/Login screens/hide_icon.svg";
      } else {
        this.state.type = "input";
        this.state.img = "/images/doctor-img/Login screens/unhide_icon.svg";
      }
    }
    this.forceUpdate();
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.getDiagnosisInfo(handle);
    this.fetchpagesdata();
    this.fetchCountryCurrencyDetails();
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  //  for emailValidation
  emailValidation = (field, event) => {
    var email = document.getElementById("txtEmailId").value;
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
      toast.error("Please provide a valid email address");
      return false;
    }
  };
  // To get all the ResponderInfo
  getDiagnosisInfo(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/diagnosis/findOneDiagnosisProduct/" + handle)
      .then((response) => {

        console.log("--87--", response.data.data.testname);
        this.state.labname = response.data.data.testname;

        this.setState({
          fields: {
            user_id: response.data.data.testid,
            testname: response.data.data.testname,
            manufacturer: response.data.data.manufacturer,
            composition: response.data.data.composition,
            form: response.data.data.form,
            attributes: response.data.data.attributes,
            tat: response.data.data.tat,
            test_type: response.data.data.test_type,
            description: response.data.data.description,
            mrp: response.data.data.mrp,
          },
        });
        this.forceUpdate();
      })
      .catch((error) => { });
  }
  fetchpagesdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/diagnosis/")
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) =>
          assignvalues.push({
            labname: item.labname,
            "mobile_number ": item.mobile_number,
            "email ": item.email,
            registration_number: item.registration_number,
            registration_expirity: moment(item.registration_expirity).format(
              "YYYY-MM-DD"
            ),

            status: item.is_active == "1" ? "Active" : "In Active",
            actions: (
              <div>
                <span>
                  <Link
                    to={"/admin/Creatediagnostics/" + item.id}
                    className="fa fa-edit point-cursor"
                    title={"Update " + item.labname}
                  ></Link>
                </span>{" "}
                &nbsp;
                <span>
                  <i
                    onClick={() => this.deleteRetailer(item.id, item.labname)}
                    className="fa fa-trash point-cursor"
                    title={"Delete " + item.labname}
                  ></i>
                </span>{" "}
                &nbsp;
              </div>
            ),
          })
        );

        this.state.totalDiagnosis = assignvalues;
      })
      .catch((error) => { });
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateRetailers(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createRetailer(event);
    } else {
      //toast.warn("Form has errors.");
    }
  }
  //  for getting country currency details
  fetchCountryCurrencyDetails() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country")
      .then((response) => {
        this.setState({
          countryData: response.data.data,
        });
      })
      .catch((error) => { });
  }

  // creates new controller
  createRetailer = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    let expDate = moment(this.state.registration_expirity).format("YYYY-MM-DD");
    Httpconfig.httptokenpost(Constant.siteurl + "api/diagnosis/createDiagnosisProduct", {
      testname: fields["testname"],
      manufacturer: fields["manufacturer"],
      composition: fields["composition"],
      form: fields["form"],
      attributes: fields["attributes"],
      tat: fields["tat"],
      test_type: fields["test_type"],
      description: fields["description"],
      mrp: fields["mrp"],
    })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success("Successfully Created Diagnostic Lab");
          setTimeout(
            () => this.props.history.push("/admin/ViewMasterProducts"),
            1000
          );
        } else if (response.status == 204) {
          toast.error("Email or Mobile Number already assosiated with us...");
        }
      })
      .catch((error) => { });
  };

  // updates controller
  updateRetailers = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    Httpconfig.httptokenput(Constant.siteurl + "api/diagnosis/updateMasterProduct/" + handle, {
      testname: fields["testname"],
      manufacturer: fields["manufacturer"],
      composition: fields["composition"],
      form: fields["form"],
      attributes: fields["attributes"],
      tat: fields["tat"],
      test_type: fields["test_type"],
      description: fields["description"],
      mrp: fields["mrp"],
    })
      .then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          toast.success(response.data.message);
          setTimeout(
            () => this.props.history.push("/admin/ViewMasterProducts"),
            2000
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["testname"]) {
      formIsValid = false;
      errors["testname"] = "Investigation Name cannot be empty";
    }
    if (!fields["manufacturer"]) {
      formIsValid = false;
      errors["manufacturer"] = "Department cannot be empty";
    }
    if (!fields["composition"]) {
      formIsValid = false;
      errors["composition"] = "Method cannot be empty";
    }
    if (!fields["form"]) {
      formIsValid = false;
      errors["form"] = "Sample cannot be empty";
    }
    if (!fields["attributes"]) {
      formIsValid = false;
      errors["attributes"] = "Sample container cannot be empty";
    }
    if (!fields["tat"]) {
      formIsValid = false;
      errors["tat"] = "TAT cannot be empty";
    }
    if (!fields["test_type"]) {
      formIsValid = false;
      errors["test_type"] = "Test Tpe cannot be empty";
    }
    if (!fields["mrp"]) {
      formIsValid = false;
      errors["mrp"] = "MRP cannot be empty";
    }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "Description cannot be empty";
    }

    // if (!fields["address"]) {
    //   formIsValid = false;
    //   errors["address"] = "Address cannot be empty";
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors, coupon_data, countryData } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      <a> Diagnostics </a>
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3>
                          {" "}
                          {this.state.fields["user_id"]
                            ? "Update Diagnostic Master Products"
                            : "Create Diagnostic Master Products"}{" "}
                        </h3>
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Investigation Name</label>
                                <input
                                  type="text"
                                  name="testname"
                                  value={this.state.fields["testname"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "testname"
                                  )}
                                  className="form-control"
                                  placeholder="Investigation Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["testname"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Department</label>
                                <input
                                  type="text"
                                  name="manufacturer"
                                  value={
                                    this.state.fields["manufacturer"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "manufacturer"
                                  )}
                                  // onKeyPress={(event) => {
                                  //   if (!/[0-9]/.test(event.key)) {
                                  //     event.preventDefault();
                                  //   }
                                  // }}
                                  // minLength="10"
                                  // maxlength="10"
                                  className="form-control"
                                  placeholder="Department"
                                />
                                <span className="cRed">
                                  {this.state.errors["manufacturer"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Method</label>
                                <input
                                  type="text"
                                  name="composition"
                                  id="composition"
                                  autoComplete="new-password"
                                  value={this.state.fields["composition"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "composition"
                                  )}
                                  className="form-control"
                                  placeholder="Method"
                                />
                                <span className="cRed">
                                  {this.state.errors["composition"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Sample</label>
                                <input
                                  type="text"
                                  name="form"
                                  autoComplete="new-form"
                                  value={this.state.fields["form"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "form"
                                  )}
                                  className="form-control"
                                  placeholder="Sample"
                                />

                                <span className="cRed">
                                  {this.state.errors["form"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Sample container</label>
                                <input
                                  type="text"
                                  name="attributes"
                                  value={
                                    this.state.fields["attributes"] ||
                                    ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "attributes"
                                  )}
                                  className="form-control"
                                  placeholder="Sample container"
                                />
                                <span className="cRed">
                                  {this.state.errors["attributes"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <label>TAT</label>
                              <div className="form-group">
                                {/* <DatePicker
                                  name="registration_expirity"
                                  minDate={new Date()}
                                  autoComplete="off"
                                  className="dateInput"
                                  
                                  placeholderText="Registration Expiry"
                                  selected={this.state.registration_expirity}
                                  onChange={this.setStartTimeOnChange}
                                  value={
                                    this.state.fields["registration_expirity"]
                                  }
                                  dateFormat="dd-MM-yyyy"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  showYearDropdown
                                  showMonthDropdow
                                /> */}
                                <input
                                  type="text"
                                  id="tat"
                                  name="tat"
                                  className="form-control"
                                  value={
                                    this.state.fields["tat"]
                                  }
                                  min={moment().format("YYYY-MM-DD")}
                                  onChange={this.setStartTimeOnChange.bind(
                                    this,
                                    "tat"
                                  )}
                                  placeholder="TAT"
                                />
                                <span className="cRed">
                                  {this.state.errors["tat"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Test Type</label>
                                <select class="form-control" name="test_type" value={this.state.fields["test_type"] || ""} onChange={this.handleChange.bind(
                                  this,
                                  "test_type"
                                )}>
                                  <option>Select</option>
                                  <option value={"test"}>Test</option>
                                  <option value={"package"}>Package</option>
                                  {/* <option>Every Month</option> */}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["test_type"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Test Description</label>
                                <TextArea
                                  name="description"
                                  value={this.state.fields["description"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "description"
                                  )}
                                  className="form-control"
                                  placeholder="Test Description"
                                />
                                <span className="cRed">
                                  {this.state.errors["description"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>MRP</label>
                                <input
                                  type="number"
                                  name="mrp"
                                  value={
                                    this.state.fields["mrp"] ||
                                    ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "mrp"
                                  )}
                                  className="form-control"
                                  placeholder="MRP"
                                />
                                <span className="cRed">
                                  {this.state.errors["mrp"]}
                                </span>
                              </div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <button
                                type="submit"
                                className="btn  btn-primary save_btn"
                              //onClick={this.emailValidation.bind(this, "")}
                              >
                                {this.state.fields["user_id"]
                                  ? "Update Product"
                                  : "Save Product"}
                              </button>{" "}
                              &nbsp;
                              <Link
                                to="/admin/viewMasterProducts"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
