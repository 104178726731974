import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctorAdmin";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import DoctorAdminHeader from "../doctorAdmin/DoctorAdminHeader";
import DoctormainSideMenu from "./DoctorSideMenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
const moment = require("moment");

export default class DoctorAdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      doctor_id: "",
      doc_data: [],
      telemedInprogressAppointmentList: "",
      telemedAppointmentUpcomingList: "",
      response: [],
      modalvisible: "modal collapse-hide",
      user_type: "",
    };

    this.viewAllTelemedicineAppointment =
      this.viewAllTelemedicineAppointment.bind(this);
    this.joinAppointment = this.joinAppointment.bind(this);
    this.close = this.close.bind(this);
    this.modalShow = this.modalShow.bind(this);
  }
  viewAllTelemedicineAppointment = () => {
    window.location.href = "./DoctorAdmintelemedicineappointments";
  };

  viewDoctorSlots = () => {
    window.location.href = "./doctoradminviewslots";
  };
  viewAppointments = () => {
    window.location.href = "./DoctorAdmintelemedicineappointments";
  };
  viewProfile = () => {
    window.location.href = "./doctoradminDoctorprofile";
  };
  viewRevenue = () => {
    window.location.href = "./DoctorAdminConsultationHistory";
  };
  componentDidMount = () => {
    var retrievedObject = localStorage.getItem("doctorAdmin");
    let userData = JSON.parse(retrievedObject);
    this.state.doctor_id = userData.doctor_id;
    this.state.user_type = userData.user_type;
    this.getTelemedicineOverview();
    this.getTelemedicineInprogessAppointmentList();
    this.getTelemedicineUpcomingAppointmentList();
    this.getTelemedicinePastAppointmentList();
    this.getTelemedicineFutureAppointmentList();
    const interval = setInterval(() => {
      this.getTelemedicineOverview();
      this.getTelemedicineInprogessAppointmentList();
      this.getTelemedicineUpcomingAppointmentList();
      this.getTelemedicinePastAppointmentList();
      this.getTelemedicineFutureAppointmentList();
    }, 10000);
  };
  getTelemedicineOverview = () => {
    var retrievedObject = localStorage.getItem("doctorAdmin");

    if (retrievedObject == null) {
      window.location.href = "doctoradmin/login";
    }
    let userData = JSON.parse(retrievedObject);
    this.setState({
      doctor_id: userData.doctor_id,
    });
    var post_data = {
      doctorId: userData.doctor_id,
      consultationDate: moment().format("YYYY-MM-DD"),
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentCount",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.setState({
            doctor_id: userData.doctor_id,
            todayDate: moment().format("YYYY-MM-DD"),
            todayTelemedicinePatientCount:
              response.data.data.telemedicine[0].todayPatientCount,
            todayTelemedicineUpcomingCount:
              response.data.data.telemedicine[0].todayUpcomingCount,
            todayTelemedicineInprogressCount:
              response.data.data.telemedicine[0].todayInprogressCount,
            todayTelemedicineCompletedCount:
              response.data.data.telemedicine[0].todayCompletedCount,
            todayTelemedicineCancelledCount:
              response.data.data.telemedicine[0].todayCancelledCount,

            todayClinicPatientCount:
              response.data.data.clinic[0].todayPatientCount,
            todayClinicUpcomingCount:
              response.data.data.clinic[0].todayUpcomingCount,
            todayClinicInprogressCount:
              response.data.data.clinic[0].todayInprogressCount,
            todayClinicCompletedCount:
              response.data.data.clinic[0].todayCompletedCount,
            todayClinicCancelledCount:
              response.data.data.clinic[0].todayCancelledCount,
            todayTotalRevenue: response.data.data.revenue[0].todayTotalRevenue,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // To get future appointments of a doctor
  getTelemedicineFutureAppointmentList = () => {
    let telemedAppointmentFutureList = "";
    var patientImage = "";
    var today = moment(new Date());
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: "",
      status: 1,
      orderBy: "ASC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          console.log("appoint data", response);
          let futureAppointmentCount = Object.keys(response.data.data).length;
          if (futureAppointmentCount > 0) {
            telemedAppointmentFutureList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage =
                    Constant.imgurl +
                    appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }

                return (
                  <React.Fragment>
                    <div class="app_date">
                      <h3>
                        {moment(appointmentdetails.appointment_datetime).format(
                          "dddd  Do MMMM, YYYY"
                        )}
                      </h3>
                    </div>
                    <div class="pending_sec">
                      <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                          <div class="doc_details">
                            <img src="../images/patient/img/Profile/Male_patient.svg" />
                            <div class="doc_name">
                              <h4>
                                {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                  "male"
                                  ? "Mr."
                                  : "Ms."}
                                {appointmentdetails.patient_tbl.name}
                              </h4>
                              <p>
                                {appointmentdetails.patient_tbl.gender},{" "}
                                <span>
                                  {
                                    moment(
                                      appointmentdetails.patient_tbl.dob,
                                      "YYYY//MM/DD"
                                    )
                                      .fromNow()
                                      .split(" ")[0]
                                  }{" "}
                                  {
                                    moment(
                                      appointmentdetails.patient_tbl.dob,
                                      "YYYY//MM/DD"
                                    )
                                      .fromNow()
                                      .split(" ")[1]
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <p class="doc_time">
                            {moment(
                              appointmentdetails.appointment_datetime
                            ).format("LT")}
                          </p>
                        </div>
                      </div>
                      <div class="doc_app_time">
                        <div class="row">
                          <div
                            class="col-lg-5 col-md-5 "
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.modalShow}
                          >
                            <p class="med_det">
                              <img src="../images/doctor-img/medical-detail.svg" />
                              Medical Details
                            </p>
                          </div>
                          <div class="col-lg-7 col-md-7">
                            <p class="join_now">
                              {" "}
                              Appointment{" "}
                              <span>
                                {" "}
                                {moment(
                                  appointmentdetails.appointment_datetime
                                ).fromNow()}{" "}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            );
          } else {
            telemedAppointmentFutureList = "";
          }
          this.setState({
            telemedFutureAppointmentList: telemedAppointmentFutureList,
          });
          this.forceUpdate();
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  getTelemedicineInprogessAppointmentList = () => {
    let telemedAppointmentInprogressList = "";
    var patientImage = "";
    var today = moment(new Date());
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: todayDate,
      status: 2,
      orderBy: "ASC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let inprogressAppointmentCount = Object.keys(
            response.data.data
          ).length;
          if (inprogressAppointmentCount > 0) {
            telemedAppointmentInprogressList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage =
                    Constant.imgurl +
                    appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }
                var mindiff = moment().diff(
                  appointmentdetails.appointment_datetime,
                  "minutes"
                );
                if (mindiff < 5) {
                  return (
                    <div class="doc_available">
                      <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                          <div class="doc_details">
                            <img src={patientImage} />
                            <div class="doc_name">
                              <h4>
                                {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                  "male"
                                  ? "Mr."
                                  : "Ms."}
                                {appointmentdetails.patient_tbl.name}
                              </h4>
                              <p>
                                {appointmentdetails.patient_tbl.gender},{" "}
                                <span>
                                  {
                                    moment(
                                      appointmentdetails.patient_tbl.dob,
                                      "YYYY//MM/DD"
                                    )
                                      .fromNow()
                                      .split(" ")[0]
                                  }{" "}
                                  Years
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <p class="doc_time">
                            {moment(
                              appointmentdetails.appointment_datetime
                            ).format("LT")}
                          </p>
                        </div>
                      </div>

                      <div class="doc_app_time">
                        <div class="row">
                          <div
                            class="col-lg-5 col-md-5 no_padding"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.modalShow}
                          >
                            <p
                              class="med_det"
                              id={
                                appointmentdetails.appointment_confirm_id +
                                "-" +
                                appointmentdetails.patient_id
                              }
                            >
                              {" "}
                              <img src="../images/doctor-img/medical-detail.svg" />{" "}
                              Medical Details
                            </p>
                          </div>
                          <div
                            class="col-lg-7 col-md-7 no_padding"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                          >
                            <p class="join_now">
                              {" "}
                              <img src="../images/doctor-img/joinNow-icon.svg" />{" "}
                              Call Started
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            );
          } else {
            telemedAppointmentInprogressList = "";
          }
          this.setState({
            telemedInprogressAppointmentList: telemedAppointmentInprogressList,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  getTelemedicineUpcomingAppointmentList = () => {
    let telemedAppointmentUpcomingList = "";
    var today = moment(new Date());
    var patientImage = "";
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();

    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: todayDate,
      status: 1,
      orderBy: "ASC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let upComingAppointmentCount = Object.keys(response.data.data).length;
          if (upComingAppointmentCount > 0) {
            telemedAppointmentUpcomingList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage =
                    Constant.imgurl +
                    appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }

                let DocName =
                  appointmentdetails.patient_tbl.name.charAt(0).toUpperCase() +
                  appointmentdetails.patient_tbl.name.slice(1);
                var mindiff = moment().diff(
                  appointmentdetails.appointment_datetime,
                  "minutes"
                );
                if (mindiff < 10) {
                  return (
                    <div class="upcoming_box">
                      <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                          <div class="doc_details">
                            <img src={patientImage} />
                            <div class="doc_name">
                              <h4>
                                {" "}
                                {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                  "male"
                                  ? "Mr."
                                  : "Ms."}
                                {DocName}
                              </h4>
                              <p>
                                {appointmentdetails.patient_tbl.gender},{" "}
                                <span>
                                  {
                                    moment(
                                      appointmentdetails.patient_tbl.dob,
                                      "YYYY//MM/DD"
                                    )
                                      .fromNow()
                                      .split(" ")[0]
                                  }{" "}
                                  Years
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <p class="doc_time">
                            {moment(
                              appointmentdetails.appointment_datetime
                            ).format("LT")}
                          </p>
                        </div>
                      </div>
                      <div class="doc_app_time">
                        <div class="row">
                          <div
                            class="col-lg-5 col-md-5"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.modalShow}
                          >
                            <p
                              class="med_det"
                              id={
                                appointmentdetails.appointment_confirm_id +
                                "-" +
                                appointmentdetails.patient_id
                              }
                            >
                              <img
                                src="../images/doctor-img/medical-detail.svg"
                                style={{ marginRight: "12px" }}
                              />{" "}
                              Medical Details
                            </p>
                          </div>
                          {mindiff < 0 ? (
                            <div class="col-lg-7 col-md-7">
                              <p class="join_now">
                                {" "}
                                Appointment in{" "}
                                <span>
                                  {moment(
                                    appointmentdetails.appointment_datetime
                                  ).fromNow()}{" "}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            <div
                              class="col-lg-7 col-md-7 no_padding"
                              id={
                                appointmentdetails.appointment_confirm_id +
                                "-" +
                                appointmentdetails.patient_id
                              }
                            >
                              <p class="join_now">
                                {" "}
                                <img src="../images/doctor-img/joinNow-icon.svg" />{" "}
                                Call Started
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            );
          }
          this.setState({
            telemedAppointmentUpcomingList: telemedAppointmentUpcomingList,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // get telemed past appointments
  getTelemedicinePastAppointmentList = () => {
    let telemedAppointmentPastList = "";
    var today = moment(new Date());
    var patientImage = "";
    var todayDate = moment(today).format("YYYY-MM-DD");
    let timeZone = moment.tz.guess();
    var post_data = {
      doctorId: this.state.doctor_id,
      appointmentDate: todayDate,
      status: 0,
      orderBy: "DESC",
      timeZone: timeZone,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getTodayAppointmentDetails",
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let pastAppointmentCount = Object.keys(response.data.data).length;
          if (pastAppointmentCount > 0) {
            telemedAppointmentPastList = response.data.data.map(
              (appointmentdetails) => {
                if (appointmentdetails.patient_tbl.profile_pic != null) {
                  patientImage =
                    Constant.imgurl +
                    appointmentdetails.patient_tbl.profile_pic;
                } else {
                  if (
                    appointmentdetails.patient_tbl.gender.toLowerCase() ==
                    "male"
                  ) {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }
                var mindiff = moment().diff(
                  appointmentdetails.appointment_datetime,
                  "minutes"
                );
                if (
                  appointmentdetails.status == 4 ||
                  appointmentdetails.status == 3
                ) {
                  return (
                    <div class="clinic_box">
                      <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                          <div class="clinic_sec">
                            <h3>
                              {moment(
                                appointmentdetails.appointment_datetime
                              ).format(" dddd  Do MMMM, YYYY")}
                            </h3>
                          </div>
                          <div class="doc_details">
                            <img src={patientImage} />
                            <div class="doc_name">
                              <h4>
                                {appointmentdetails.patient_tbl.gender.toLowerCase() ==
                                  "male"
                                  ? "Mr."
                                  : "Ms."}
                                {appointmentdetails.patient_tbl.name}
                              </h4>
                              <p>
                                {appointmentdetails.patient_tbl.gender},{" "}
                                <span>
                                  {
                                    moment(
                                      appointmentdetails.patient_tbl.dob,
                                      "YYYY//MM/DD"
                                    )
                                      .fromNow()
                                      .split(" ")[0]
                                  }{" "}
                                  Years
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <p class="doc_time">
                            {moment(
                              appointmentdetails.appointment_datetime
                            ).format("LT")}
                          </p>
                        </div>
                      </div>
                      <div class="doc_app_time">
                        <div class="row">
                          <div
                            class="col-lg-6 col-md-6"
                            id={
                              appointmentdetails.appointment_confirm_id +
                              "-" +
                              appointmentdetails.patient_id
                            }
                            onClick={this.modalShow}
                          >
                            <p class="med_det">
                              <img
                                src="../images/doctor-img/medical-detail.svg"
                                style={{ marginRight: "12px" }}
                              />
                              Medical Details
                            </p>
                          </div>
                          <div
                            class="col-lg-6 col-md-6"
                            id={appointmentdetails.appointment_confirm_id}
                          >
                            {appointmentdetails.status == 3 &&
                              appointmentdetails.prescription_id != null ? (
                              <a
                                href={
                                  Constant.imgurl +
                                  appointmentdetails.prescription_id
                                }
                                target="_blank"
                              >
                                <p class="write_pre"> View Prescription </p>
                              </a>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 3 &&
                              appointmentdetails.prescription_id == null ? (
                              <p class="write_pre"> Appointment Completed</p>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 2 ? (
                              <a
                                id={
                                  appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                                }
                                onClick={this.joinAppointment.bind(
                                  this,
                                  appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                                )}
                              >
                                <p class="write_pre"> Write Prescription</p>
                              </a>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 1 ? (
                              <a
                                id={
                                  appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                                }
                                onClick={this.joinAppointment.bind(
                                  this,
                                  appointmentdetails.appointment_confirm_id +
                                  "-" +
                                  appointmentdetails.patient_id
                                )}
                              >
                                <p class="write_pre"> Write Prescription</p>
                              </a>
                            ) : (
                              ""
                            )}
                            {appointmentdetails.status == 4 ? (
                              <a>
                                <p class="app_cancel">
                                  {" "}
                                  Appointment Cancelled{" "}
                                </p>{" "}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            );
          }
          this.setState({
            telemedAppointmentPastList: telemedAppointmentPastList,
          });
        } else {
          toast.warn(
            "Sorry we cannot update the Availibility status at this moment",
            {
              position: "bottom-center",
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  joinAppointment = (id) => {
    let data = id.split("-");
    let appointmentId = data[0];
    let patientId = data[1];
    reactLocalStorage.setObject(
      "DoctorSelectedConsultation",
      JSON.parse(appointmentId)
    );
    reactLocalStorage.setObject(
      "DoctorSelectedPatientId",
      JSON.parse(patientId)
    );
    window.location.href = "./Doctorvideoconsultation";
  };
  close = () => {
    this.setState({
      modal: false,
      modalvisible: "modal collapse-hide",
    });
  };
  modalShow = (e) => {
    let appointmentId = "";
    let patientId = "";
    let patientData = e.currentTarget.id;
    if (patientData != "") {
      let patientDetails = patientData.split("-");
      appointmentId = patientDetails[0];
      patientId = patientDetails[1];
    }

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Patients/getMedicalDetails/",
      {
        appointment_id: parseInt(appointmentId),
        patient_id: parseInt(patientId),
      }
    ).then((response) => {
      let patientCurrentMedication = "";
      let patientDrugAllergies = "";
      let symptoms_tbls = "";
      let patientMedicalDocument = [];
      let imagedata = [];
      let patientAge = "";
      let chornicDiseases_list = "";
      let relatedMedication = "";
      let drugAllergies = "";
      let symptoms = "";
      let bloodPressureSystolic = "";
      let bloodPressureDiastolic = "";
      let height = "";
      let weight = "";
      let bmi = "";
      let pulse_rate = "";
      let respiratory_rate = "";
      let skip_blood_pressure = "";
      let temperature = "";
      let patientName =
        response.data.data[0].name.charAt(0).toUpperCase() +
        response.data.data[0].name.slice(1);
      let gender = response.data.data[0].gender;
      let diagnostics = "";
      let consultationPurpose = "";
      let pasthistory = "";

      if (
        Object.keys(response.data.data[0].patient_past_histories).length > 0
      ) {
        let pastLength =
          response.data.data[0].patient_past_histories[0][
            "patient_current_medication"
          ].length;
        if (pastLength > 0) {
          for (var past = 0; past < pastLength; past++) {
            patientCurrentMedication =
              patientCurrentMedication +
              "," +
              response.data.data[0].patient_past_histories[0][
              "patient_current_medication"
              ][past]["medicinename"];
          }
        }
      }
      if (
        Object.keys(response.data.data[0].patient_past_histories).length > 0
      ) {
        let drugAllergiesLength =
          response.data.data[0].patient_past_histories[0][
            "patient_drug_allergies"
          ].length;
        if (drugAllergiesLength > 0) {
          for (var past = 0; past < drugAllergiesLength; past++) {
            patientDrugAllergies =
              patientDrugAllergies +
              "," +
              response.data.data[0].patient_past_histories[0][
              "patient_drug_allergies"
              ][past]["medicinename"];
          }
        }
      }
      if (response.data.data[0].drug_allergies) {
        if (Object.keys(response.data.data[0].drug_allergies).length > 0) {
          let drugAllergiesLength = response.data.data[0].drug_allergies.length;
          if (drugAllergiesLength > 0) {
            for (var past = 0; past < drugAllergiesLength; past++) {
              patientDrugAllergies =
                patientDrugAllergies +
                "," +
                response.data.data[0].drug_allergies[past]["medicinename"];
            }
          }
        }
      }

      if (Object.keys(response.data.data[0].problem_tbls).length > 0) {
        consultationPurpose = response.data.data[0].problem_tbls[0]["problem"];
      }

      if (Object.keys(response.data.data[0].symptoms_tbls).length > 0) {
        diagnostics = response.data.data[0].symptoms_tbls[0].doctor_diagnosis;
        let symptomsLength =
          response.data.data[0].symptoms_tbls[0]["symptoms"].length;
        if (response.data.data[0].symptoms_tbls[0].add_more_symptoms != null) {
          symptoms_tbls =
            response.data.data[0].symptoms_tbls[0].add_more_symptoms;
        }
        if (symptomsLength > 0) {
          for (var past = 0; past < symptomsLength; past++) {
            symptoms_tbls =
              symptoms_tbls +
              "," +
              response.data.data[0].symptoms_tbls[0]["symptoms"][past][
              "description"
              ];
          }
        }
      }

      let documentsLength = response.data.data[0].patient_past_histories.length;
      if (documentsLength > 0) {
        for (var pastdoc = 0; pastdoc < documentsLength; pastdoc++) {
          for (
            let doc = 0;
            doc <
            response.data.data[0].patient_past_histories[pastdoc][
              "patient_medical_document"
            ].length;
            doc++
          ) {
            patientMedicalDocument[doc] =
              response.data.data[0].patient_past_histories[pastdoc][
                "patient_medical_document"
              ][doc].replace("jpeg", "jpg");
            imagedata[doc] =
              '<div class="med_upload_box"><img src=' +
              Constant.imgurl +
              patientMedicalDocument[doc] +
              "><p>Health record " +
              (doc + 1) +
              "</p></div>";
          }
        }
      }

      if (response.data.data[0].dob != null) {
        let dob = response.data.data[0].dob.split("T");
        let age = dob[0];
        let ageYears = moment().diff(age, "years");
        let ageMonths = moment().diff(age, "months");
        let ageDays = moment().diff(age, "days");
        if (ageYears > 0) {
          patientAge = ageYears + " Years";
        } else if (ageMonths != "") {
          patientAge = ageMonths + " Months";
        } else if (ageDays != "") {
          patientAge = ageDays + " Days";
        }
        patientAge =
          moment(response.data.data[0].dob, "YYYY//MM/DD")
            .fromNow()
            .split(" ")[0] + " Years";
      }

      if (response.data.data[0].chornic_diseases_list != null) {
        chornicDiseases_list = response.data.data[0].chornic_diseases_list;
      }
      if (response.data.data[0].related_medication != null) {
        if (Object.keys(response.data.data[0].related_medication).length > 0) {
          for (
            let medCount = 0;
            medCount <
            Object.keys(response.data.data[0].related_medication).length;
            medCount++
          ) {
            relatedMedication =
              relatedMedication +
              "," +
              response.data.data[0].related_medication[medCount][
              "medicinename"
              ];
          }
        }
      }

      if (Object.keys(response.data.data[0].vital_informations).length > 0) {
        bloodPressureSystolic =
          response.data.data[0].vital_informations[0].blood_pressure_systolic;
        bloodPressureDiastolic =
          response.data.data[0].vital_informations[0].blood_pressure_diastolic;

        if (response.data.data[0].vital_informations[0].height != "") {
          var realFeet =
            (response.data.data[0].vital_informations[0].height * 0.3937) / 12;
          var feet = Math.floor(realFeet);
          var inches = Math.round((realFeet - feet) * 12);
          feet = feet + "'," + inches + '"';
          let calheight = (
            response.data.data[0].vital_informations[0].height * 0.0328
          ).toFixed(2);
          if (calheight > 0) {
            let calData = calheight.split(".");
            let Inch = calData[1];
            let feets = calData[0];

            if (Inch > 12) {
              Inch = Inch.charAt(0);
            }

            response.data.data[0].vital_informations[0].height =
              feets + "'," + Inch + '"';
          }
        }

        height = response.data.data[0].vital_informations[0].height;
        weight = response.data.data[0].vital_informations[0].weight;
        bmi = response.data.data[0].vital_informations[0].bmi;
        pulse_rate = response.data.data[0].vital_informations[0].pulse_rate;
        respiratory_rate =
          response.data.data[0].vital_informations[0].respiratory_rate;
        skip_blood_pressure =
          response.data.data[0].vital_informations[0].blood_sugar;
        temperature = response.data.data[0].vital_informations[0].temperature;
      }

      if (
        symptoms_tbls.indexOf(",") > -1 &&
        response.data.data[0].symptoms_tbls[0].add_more_symptoms == null
      ) {
        symptoms_tbls = symptoms_tbls.substr(1);
      }

      if (patientDrugAllergies.indexOf(",") > -1) {
        patientDrugAllergies = patientDrugAllergies.substr(1);
      }

      if (patientCurrentMedication.indexOf(",") > -1) {
        patientCurrentMedication = patientCurrentMedication.substr(1);
      }
      if (relatedMedication.indexOf(",") > -1) {
        relatedMedication = relatedMedication.substr(1);
      }
      if (
        response.data.data[0].patient_past_histories[0].past_history != null
      ) {
        chornicDiseases_list =
          chornicDiseases_list +
          "," +
          response.data.data[0].patient_past_histories[0].past_history;
      }
      if (chornicDiseases_list.charAt(0) == ",") {
        if (chornicDiseases_list.indexOf(",") > -1) {
          chornicDiseases_list = chornicDiseases_list.substr(1);
        }
      }
      this.setState({
        patientName: patientName.charAt(0).toUpperCase() + patientName.slice(1),
        consultationPurpose: consultationPurpose,
        gender: gender,
        dob: patientAge,
        profilePic: response.data.data[0].profile_pic,
        relation: response.data.data[0].master_relationship.relation_name,
        chornicDiseases_list: chornicDiseases_list,
        relatedMedication: relatedMedication + patientCurrentMedication,
        drugAllergies: patientDrugAllergies,
        symptoms: symptoms_tbls,
        relationPatientId: response.data.data[0].relation_patient_id,
        appointmentId: appointmentId,
        bloodPressureSystolic: bloodPressureSystolic,
        bloodPressureDiastolic: bloodPressureDiastolic,
        height: height,
        weight: weight,
        bmi: bmi,
        pulse_rate: pulse_rate,
        respiratory_rate: respiratory_rate,
        skip_blood_pressure: skip_blood_pressure,
        temperature: temperature,
        labTests: "",
        patientMedicalDocument: patientMedicalDocument,
        imagedata: imagedata,
        problem: response.data.data[0].problem_tbls[0].problem,
        doctorDiagnosis: diagnostics,
      });
    });

    this.setState({
      modal: true,
      modalvisible: "modal collapse-show",
    });

    this.forceUpdate();
  };

  render() {
    return (
      <main id="main">
        <DoctorAdminHeader onSelectLanguage={this.handleLanguage} />

        <I18nPropvider locale={this.state.Language}>
          <applist />
          <section id="doctor_dashboard">
            <div class="container-fluid">
              <div class="row">
                <DoctormainSideMenu onSelectLanguage={this.handleLanguage} />
                <div
                  id="upcominggModal"
                  class={this.state.modalvisible}
                  role="dialog"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          onClick={this.close}
                        >
                          &times;
                        </button>
                        <h4 class="modal-title">
                          {this.state.gender == "male" ? "Mr." : "Ms."}{" "}
                          {this.state.patientName}
                          <span>Medical Details</span>
                        </h4>
                        <p>
                          {this.state.gender
                            ? translate(
                              this.state.gender.charAt(0).toUpperCase() +
                              this.state.gender.slice(1)
                            )
                            : ""}
                          ,{this.state.dob}
                        </p>
                      </div>

                      <div class="modal-body">
                        <div class="med_details">
                          <p>
                            Patient UHID:{" "}
                            <span>{this.state.relationPatientId}</span>
                          </p>
                        </div>
                        <div class="med_details">
                          <h2>Purpose of Visit</h2>

                          <p>{this.state.consultationPurpose}</p>
                        </div>
                        <div class="med_details">
                          <h2>Vital Information</h2>
                          <p>
                            Pulse rate:{" "}
                            <span>
                              {this.state.pulse_rate
                                ? this.state.pulse_rate
                                : "--"}
                            </span>
                          </p>
                          <p>
                            Blood Pressure :{" "}
                            {this.state.bloodPressureDiastolic
                              ? this.state.bloodPressureDiastolic + " mmHG"
                              : "--"}
                            -
                            {this.state.bloodPressureSystolic
                              ? this.state.bloodPressureSystolic + " mmHG"
                              : "--"}
                          </p>
                          <p>
                            Blood Sugar :{" "}
                            <span>
                              {this.state.skip_blood_pressure
                                ? this.state.skip_blood_pressure + " mg/dl"
                                : "--"}
                            </span>
                          </p>
                          <p>
                            Temperature:{" "}
                            <span>
                              {this.state.temperature
                                ? this.state.temperature + " °F"
                                : "--"}
                            </span>
                          </p>
                          <p>
                            Respiratory :{" "}
                            <span>
                              {this.state.respiratory_rate
                                ? this.state.respiratory_rate +
                                " Breaths / minute"
                                : "--"}
                            </span>
                          </p>
                          <p>
                            Height:{" "}
                            <span>
                              {this.state.height
                                ? this.state.height + " Feet/Inches"
                                : "--"}
                            </span>
                          </p>
                          <p>
                            Weight :{" "}
                            <span>
                              {this.state.weight
                                ? this.state.weight + " Kg"
                                : "--"}
                            </span>
                          </p>
                          <p>
                            BMI :{" "}
                            <span>
                              {this.state.bmi
                                ? this.state.bmi + " Kg/M2"
                                : "--"}
                            </span>
                          </p>
                        </div>
                        <div class="med_details">
                          <h2>Past Medical History</h2>
                          <p>
                            Chronic Diseases:
                            {this.state.chornicDiseases_list
                              ? this.state.chornicDiseases_list
                              : "--"}
                          </p>
                          <p>
                            Current Medication:
                            {this.state.relatedMedication
                              ? this.state.relatedMedication
                              : "--"}
                          </p>
                          <p>
                            Drug Allergies:
                            {this.state.drugAllergies
                              ? this.state.drugAllergies
                              : "--"}
                          </p>
                        </div>
                        <div class="med_details">
                          <h2>Symptoms</h2>
                          <p>
                            {this.state.symptoms ? this.state.symptoms : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-10 col-md-9 no_padding">
                  <div class="overview_left">
                    <section id="doc_dasboard_section">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-3 col-md-6 col-sm-6 no_padding">
                            <a onClick={this.viewAppointments}>
                              <div class="dashboard_box">
                                <img src="../images/doctor-img/appoint.svg" />
                                <p>Appointments</p>
                              </div>
                            </a>
                          </div>
                          <div class="col-lg-3 col-md-6 col-sm-6 no_padding">
                            <a onClick={this.viewDoctorSlots}>
                              <div class="dashboard_box">
                                <img src="../images/doctor-img/avail-slot.svg" />
                                <p>Available Slot</p>
                              </div>
                            </a>
                          </div>
                          <div class="col-lg-3 col-md-6 col-sm-6 no_padding">
                            <a onClick={this.viewProfile}>
                              <div class="dashboard_box">
                                <img src="../images/doctor-img/pro.svg" />
                                <p>Profile</p>
                              </div>
                            </a>
                          </div>
                          <div class="col-lg-3 col-md-6 col-sm-6 no_padding">
                            <a onClick={this.viewRevenue}>
                              <div class="dashboard_box">
                                <img src="../images/doctor-img/revenue.svg" />
                                <p>Revenue</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div class="overview_section">
                      <div class="row">
                        <div class="col-lg-8 col-md-12">
                          <h2 className="dash_head">Telemedicine Overview</h2>
                          <div class="telemed_overview">
                            <div class="container">
                              <div class="row">
                                <div class="col-lg-3 col-md-3 no_padding text-mid">
                                  <div class="today_pat">
                                    <h3>Today's patient</h3>
                                    <p>
                                      {this.state.todayTelemedicinePatientCount
                                        ? this.state
                                          .todayTelemedicinePatientCount
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 no_padding text-mid">
                                  <div class="ongoing_consult">
                                    <h3>Ongoing Consultation</h3>
                                    <p>
                                      {this.state
                                        .todayTelemedicineInprogressCount
                                        ? this.state
                                          .todayTelemedicineInprogressCount
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 no_padding text-mid">
                                  <div class="complete_consult">
                                    <h3>Completed Consultation</h3>
                                    <p>
                                      {this.state
                                        .todayTelemedicineCompletedCount
                                        ? this.state
                                          .todayTelemedicineCompletedCount
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 no_padding text-mid">
                                  <div class="pending_consult">
                                    <h3>Pending Consultation</h3>
                                    <p>
                                      {this.state.todayTelemedicineUpcomingCount
                                        ? this.state
                                          .todayTelemedicineUpcomingCount
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <h2 className="dash_head">My Revenues</h2>
                          <div class="revenue_overview">
                            <div class="container">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 no_padding text-mid">
                                  <div class="revenue_telemed">
                                    <h3>Telemedicine Appointments</h3>
                                    <p>
                                      ₹{" "}
                                      {this.state.todayTotalRevenue
                                        ? this.state.todayTotalRevenue
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="doctor_content_section">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="telemed_row">
                            <h1>
                              Telemedicine Appointments
                              <span
                                onClick={this.viewAllTelemedicineAppointment}
                              >
                                View all
                              </span>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <div class="telemed_head">
                            <h2>
                              In Progress Appointments{" "}
                            </h2>
                          </div>

                          <div class="telemed_box">
                            {this.state.telemedInprogressAppointmentList ? (
                              this.state.telemedInprogressAppointmentList
                            ) : (
                              <div class="telemed_sec">
                                <div class="no_appoint">No Appointments </div>
                              </div>
                            )}
                            <div class="up_box">
                              <div class="up_head">
                                <h2
                                  data-toggle="modal"
                                  data-target="#upcominggModal"
                                >
                                  Upcoming Appointments{" "}
                                </h2>
                              </div>
                              <div class="upcoming_section">
                                <h3>
                                  {moment().format(" dddd  Do MMMM, YYYY")}
                                </h3>
                                {this.state.telemedAppointmentUpcomingList ? (
                                  this.state.telemedAppointmentUpcomingList
                                ) : (
                                  <div class="no_appoint">No Appointments </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="pending_main">
                            <div class="pending_head">
                              <h2>
                                Pending Appointments{" "}
                              </h2>
                            </div>
                            {this.state.telemedFutureAppointmentList ? (
                              <div class="pending_box">
                                {this.state.telemedFutureAppointmentList}
                              </div>
                            ) : (
                              <div class="pending_box">
                                <div class="no_appoint">No Appointments</div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="clinic_head">
                            <h2>
                              Past Appointments{" "}
                            </h2>
                          </div>

                          <div class="clinic_main">
                            {this.state.telemedAppointmentPastList ? (
                              this.state.telemedAppointmentPastList
                            ) : (
                              <div class="no_appoint">No Appointments</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* modal for telemedecine medical details   */}
          <div class="modal fade" id="telemedModal" role="dialog">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ask_patient_section">
                    <img src="../images/doctor-img/Medical information locked/Medical_information.svg" />
                    <h5>Medical Information Locked</h5>
                    <a href="#">
                      <p>Ask Patient's Consent</p>
                    </a>
                    <h6>
                      Seek Permission for the detailed Information of the
                      Patient
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* modal for upcoming medical details  */}
          <div id="upcominggModal" class="modal collapse-hide" role="dialog">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                  <h4 class="modal-title">
                    Nandini Singh<span>Medical Details</span>
                  </h4>
                  <p>F, 26years</p>
                </div>
                <div class="modal-body">
                  <p class="sch_box">16 june 2020 , 02:30 PM</p>
                  <div class="med_details">
                    <p>
                      Patient UHID: <span>NR005</span>
                    </p>
                  </div>
                  <div class="med_details">
                    <h2>Apollo clinic</h2>
                    <p>Madhapur, Hyderabad, Telangana</p>
                  </div>
                  <div class="med_details">
                    <h2>Vital Information</h2>
                    <p>
                      Pulse rate: <span>75BPM</span>
                    </p>
                    <p>Blood Pressure : 120mmHG- 58mmHG</p>
                    <p>
                      Temperature: <span>103F</span>
                    </p>
                    <p>
                      Respiratory : <span>22 Breaths per minute</span>
                    </p>
                    <p>
                      Height: <span>6 feet</span>
                    </p>
                    <p>
                      BMI : <span>32kg/ M2</span>
                    </p>
                    <p>
                      Weight : <span>75K</span>
                    </p>
                  </div>
                  <div class="med_details">
                    <h2>Past Medical History</h2>
                    <p>Patient suffered fever 2months back</p>
                  </div>
                  <div class="med_details">
                    <h2>Diagnosis</h2>
                    <p>Lorem Dolor sit amet fo tequa</p>
                  </div>
                  <div class="med_details">
                    <h2>Lab Test</h2>
                    <p>USG in Lower Abdomen</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </I18nPropvider>
        <PatientFooter />
      </main>
    );
  }
}
