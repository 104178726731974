import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctor";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";


export default class doctorAdminforgotpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      type: "password",


    };

  }

  handleChange = (field, event) => {

    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  }
  checkSubmit(event) {

    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle == undefined) {
      this.checkLogin(event);
    } else {
      toast.warn("Form has errors.");
    }
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    var pattern = /^[a-zA-Z0-9]{3,20}$/g;
    var numbers = /^[0-9]+$/;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "User name cannot be empty";
    } else if (typeof fields["username"] !== "undefined" && fields["username"].lastIndexOf("@") > 0) {
      let lastAtPos = fields["username"].lastIndexOf("@");
      let lastDotPos = fields["username"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["username"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["username"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["username"] = "Email is invalid";
      }

    } else if (!/^[0-9]+$/g.exec(fields["username"])) {
      formIsValid = false;
      errors["username"] = "Invalid Username / Special characters not allowed";

    } else if (fields["username"].length < 10) {
      formIsValid = false;
      errors["username"] = "Phone number invalid";
    } else {
      errors["username"] = "";
    }


    this.setState({ errors: errors });
    return formIsValid;
  }


  checkLogin = (event) => {
    event.preventDefault();
    var handle = this.props.match.params.handle;
    const { fields, errors } = this.state;
    if (fields["username"].lastIndexOf("@") > 0) {
      var post_data = {
        email: fields["username"],
        role: "doctor",
      }
    } else {
      var post_data = {
        phone: fields["username"],
        role: "doctor",
      }
    }
    Httpconfig.httptokenpost(Constant.siteurl + "api/Users/forgetPassword", post_data)
      .then((response) => {

        if (response.data.notification.is_auth === false) {
          toast.error(response.data.notification.hint);
          return
        }
        if (response.data.notification.is_auth === true) {
          toast.success("Password has been send to your registered email");
          setTimeout(() => this.props.history.push("./login"), 2000);
          return
        }
        if (response.data.loginOutput.data != "") {
          console.log(response.data.loginOutput.data.userobj);
          reactLocalStorage.setObject("DocuserObj", response.data.loginOutput.data.userobj);
          localStorage.removeItem("doctorToken");
          reactLocalStorage.setObject("doctorToken", response.data.loginOutput.data.userobj.accessToken);
          toast.success(response.data.loginOutput.notification.message);
          setTimeout(() => this.props.history.push("./Doctordashboard"), 2000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  componentDidMount = () => {
    var DocObject = localStorage.getItem("DocuserObj");

    if (DocObject != null) {
      setTimeout(() => this.props.history.push("./Doctordashboard"), 2000);
    }
  }

  redirect = () => {
    this.props.history.push("./login");
  }


  render() {
    return (

      <main id="main">

        <I18nPropvider locale={this.state.Language}>

          <section id="doctor_login">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 doc_img_mid">
                  <div class="doc_login_img">
                    <img src="../../images/patient/img/main-logo.png" />
                  </div>

                </div>
                <div class="col-md-6 doc_form_mid">
                  <div class="doc_login_form">
                    <h2>Forgot Password</h2>
                    <form onSubmit={this.checkSubmit.bind(this)}>
                      <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control log_input" name="username" id="username" placeholder="Email" onChange={this.handleChange.bind(this, "username")} />

                      </div>
                      <span className="cRed">
                        {this.state.errors["username"]}
                      </span>
                      <button type="submit" class="btn btn-default doc_log_btn">Submit</button>
                      <p class="fgt_pwd"><a onClick={this.redirect}>Login</a></p>
                    </form>

                  </div>

                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </I18nPropvider>
        <PatientFooter />

      </main>


    );
  }
}