import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";

import DatePicker from "react-datepicker";
const moment = require("moment");

class RetailerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      retailer_data: "",
      type: "password",
      options: [],
      img: "/images/doctor-img/Login screens/hide_icon.svg",
    };
    this.showPassword = this.showPassword.bind(this);
  }
  //  For start time chznge
  setStartTimeOnChange = (field, event) => {
    let expDate = event.target.value;
    this.setState({ registration_expirity: expDate });
    this.state.fields["registration_expirity"] =
      moment(expDate).format("YYYY-MM-DD");
    let count = expDate.search("-");
    if (count > 0) {
      let dateString = expDate.split("-");
      if (dateString[0].length != 4) {
        toast.error("Invalid expired date");
        return;
      }
    }

    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
    this.state.errors[field] = "";
  };

  //  For sho password
  showPassword() {
    let type = this.state.type;
    if (this.state.type) {
      if (this.state.type == "input") {
        this.state.type = "password";
        this.state.img = "/images/doctor-img/Login screens/hide_icon.svg";
      } else {
        this.state.type = "input";
        this.state.img = "/images/doctor-img/Login screens/unhide_icon.svg";
      }
    }
    this.forceUpdate();
  }
  // To get detais after first render

  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.fetchCountryCurrencyDetails();
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  //  For email validation
  emailValidation = (field, event) => {
    var email = document.getElementById("txtEmailId").value;
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
      toast.error("Please provide a valid email address");
      return false;
    }
  };

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateRetailers(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createRetailer(event);
    } else {
    }
  }

  //  For getting currency details
  fetchCountryCurrencyDetails() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country")
      .then((response) => {
        this.setState({
          countryData: response.data.data,
        });
      })
      .catch((error) => {});
  }

  // creates new controller
  createRetailer = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;

    let expDate = moment(this.state.registration_expirity).format("YYYY-MM-DD");

    Httpconfig.httptokenpost(Constant.siteurl + "api/retailer/", {
      storename: fields["storename"],
      email: fields["email"],
      mobile_number: fields["mobile_number"],
      password: fields["password"],
      registration_expirity: expDate,
      registration_number: fields["registration_number"],
      address: fields["address"],
      latitude: fields["latitude"],
      longitude: fields["longitude"],

      bank_name: fields["bank_name"],
      account_number: fields["account_number"],
      account_holder_name: fields["account_holder_name"],
      ifsc_code: fields["ifsc_code"],
      upi_number: fields["upi_number"],
      gst_number: fields["gst_number"],
      pan_number: fields["pan_number"],
      commission: fields["commission"],
    })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success("Successfully Created Retailer");
          setTimeout(() => this.props.history.push("/retailer/login"), 1000);
        } else if (response.status == 204) {
          toast.error("Email or Mobile Number already assosiated with us...");
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateRetailers = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    Httpconfig.httptokenput(Constant.siteurl + "api/retailer/" + handle, {
      storename: fields["storename"],
      email: fields["email"],
      mobile_number: fields["mobile_number"],
      registration_expirity: this.state.fields["registration_expirity"],
      registration_number: fields["registration_number"],
      address: fields["address"],
      latitude: fields["latitude"],
      longitude: fields["longitude"],

      bank_name: fields["bank_name"],
      account_number: fields["account_number"],
      account_holder_name: fields["account_holder_name"],
      ifsc_code: fields["ifsc_code"],
      upi_number: fields["upi_number"],
      gst_number: fields["gst_number"],
      pan_number: fields["pan_number"],
      commission: fields["commission"],
      password: fields["password"],
    })
      .then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          toast.success(response.data.message);
          setTimeout(
            () => this.props.history.push("/admin/Viewretailers"),
            2000
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["storename"]) {
      formIsValid = false;
      errors["storename"] = "Store name cannot be empty";
    }
    if (!fields["mobile_number"]) {
      formIsValid = false;
      errors["mobile_number"] = "Mobile Number cannot be empty";
    }
    if (!fields["registration_number"]) {
      formIsValid = false;
      errors["registration_number"] = "Registration Number cannot be empty";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }
    if (!fields["registration_expirity"]) {
      formIsValid = false;
      errors["registration_expirity"] = "Expiry Date cannot be empty";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["latitude"]) {
      formIsValid = false;
      errors["latitude"] = "Latitude cannot be empty";
    }
    if (!fields["longitude"]) {
      formIsValid = false;
      errors["longitude"] = "Longitude cannot be empty";
    }

    if (!fields["gst_number"]) {
      formIsValid = false;
      errors["gst_number"] = "GST Number cannot be empty";
    }
    if (!fields["pan_number"]) {
      formIsValid = false;
      errors["pan_number"] = "PAN Number cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors, coupon_data, countryData } = this.state;
    return (
      <main id="main">
        <I18nPropvider locale={this.state.Language}>
          <section id="doctor_login">
            <div class="container-fluid">
              <div class="row signup_wrapper">
                <div class="col-md-5 doc_img_mid">
                  <div class="doc_login_img">
                    <img src="../../images/patient/img/main-logo.png" />
                  </div>
                </div>
                <div class="col-md-7 doc_form_mid retailer_signup">
                  <form onSubmit={this.checkSubmit.bind(this)}>
                    <div className="row">
                      <div class="col-md-12 doc_login_form">
                        <h2 class="text-left ml-5">Signup to Pharmacy</h2>
                      </div>
                      <div class="row signup_fields mx-5">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Store Name</label>
                            <input
                              type="text"
                              name="storename"
                              value={this.state.fields["storename"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "storename"
                              )}
                              className="form-control"
                              placeholder="Store Name"
                            />
                            <span className="cRed">
                              {this.state.errors["storename"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Mobile Number</label>
                            <input
                              type="text"
                              name="mobile_number"
                              value={this.state.fields["mobile_number"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "mobile_number"
                              )}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              minLength="10"
                              maxlength="10"
                              className="form-control"
                              placeholder="Mobile Number"
                            />
                            <span className="cRed">
                              {this.state.errors["mobile_number"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Email address</label>
                            <input
                              type="text"
                              name="email"
                              id="txtEmailId"
                              autoComplete="new-password"
                              value={this.state.fields["email"] || ""}
                              onChange={this.handleChange.bind(this, "email")}
                              className="form-control"
                              placeholder="Email address"
                            />
                            <span className="cRed">
                              {this.state.errors["email"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Enter Password</label>
                            <input
                              type={this.state.type}
                              name="password"
                              autoComplete="new-password"
                              value={this.state.fields["password"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "password"
                              )}
                              className="form-control"
                              placeholder="Enter Password"
                            />
                            <img
                              style={{ marginTop: "33px" }}
                              class="password_view_retailer"
                              onClick={this.showPassword}
                              src={this.state.img}
                            />
                            <span className="cRed">
                              {this.state.errors["password"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Registration Number</label>
                            <input
                              type="text"
                              name="registration_number"
                              value={
                                this.state.fields["registration_number"] || ""
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "registration_number"
                              )}
                              className="form-control"
                              placeholder="Registration Number"
                            />
                            <span className="cRed">
                              {this.state.errors["registration_number"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Registration Expiry</label>
                            <input
                              type="date"
                              id="registration_expirity"
                              name="registration_expirity"
                              className="form-control"
                              value={this.state.fields["registration_expirity"]}
                              min={moment().format("YYYY-MM-DD")}
                              onChange={this.setStartTimeOnChange.bind(
                                this,
                                "registration_expirity"
                              )}
                            />
                            <span className="cRed">
                              {this.state.errors["registration_expirity"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Address</label>
                            <input
                              type="text"
                              name="address"
                              value={this.state.fields["address"] || ""}
                              onChange={this.handleChange.bind(this, "address")}
                              className="form-control"
                              placeholder="Address"
                            />
                            <span className="cRed">
                              {this.state.errors["address"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Latitude</label>
                            <input
                              type="text"
                              name="latitude"
                              value={this.state.fields["latitude"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "latitude"
                              )}
                              className="form-control"
                              placeholder="Latitude"
                            />
                            <span className="cRed">
                              {this.state.errors["latitude"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Longitude</label>
                            <input
                              type="text"
                              name="longitude"
                              value={this.state.fields["longitude"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "longitude"
                              )}
                              className="form-control"
                              placeholder="Longitude"
                            />
                            <span className="cRed">
                              {this.state.errors["longitude"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>GST Number</label>
                            <input
                              type="text"
                              name="gst_number"
                              value={this.state.fields["gst_number"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "gst_number"
                              )}
                              className="form-control"
                              placeholder="GST Number"
                            />
                            <span className="cRed">
                              {this.state.errors["gst_number"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>PAN Number</label>
                            <input
                              type="text"
                              name="pan_number"
                              value={this.state.fields["pan_number"] || ""}
                              onChange={this.handleChange.bind(
                                this,
                                "pan_number"
                              )}
                              className="form-control"
                              placeholder="PAN Number"
                            />
                            <span className="cRed">
                              {this.state.errors["pan_number"]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row"></div>

                    <div className="row"></div>

                    <div className="row"></div>

                    <div className="row mx-5 my-3">
                      <div
                        id="reg_signup_form"
                        className="form-group col-md-12 p-0"
                      >
                        <button
                          type="submit"
                          className="btn  btn-primary save_btn"
                          onClick={this.emailValidation.bind(this, "")}
                        >
                          Create Pharmacy
                        </button>{" "}
                        &nbsp;
                        <Link className="cancel_btn">Cancel</Link> &nbsp; &nbsp;
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </I18nPropvider>
        <PatientFooter />
      </main>
    );
  }
}

export default RetailerSignup;
