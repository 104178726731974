import { LOCALES } from '../constants';

export default {
  [LOCALES.GERMAN] :  {
    'hello' :  'Hallo',
    'Login' :  'Anmeldung',
    'Language' :  'Sprache',
    'Consult a Doctor' :  'Einen Arzt konsultieren',
    'Video consultation' :  'Video-Beratung',
    'Video consultation over app' :  "Video-Beratung über die App",
    'In person visit' :  "Persönlicher Besuch",
    'Meet The Doctor In Clinic' :  'Treffen Sie den Arzt in der Klinik',
    'Order Medicine' :  'Medizin bestellen',
    'Book For Diagnostic' :  "Buch zur Diagnose",
    'Care at Home' :  'Pflege zu Hause',
    'Consult Top Specialists now' : "Wenden Sie sich jetzt an Top-Spezialisten",
    'Cough and cold' : "Husten und Erkältung",
    'Consult now' : "Konsultieren Sie jetzt",
    'Best Doctors from your nearest clinic' : "Beste Ärzte aus Ihrer nächsten Klinik",
    "Book Full Body Checkups":"Buchen Sie Ganzkörper-Checkups",
    "Book Diagnostic Tests":"Buchdiagnosetests",
    "Home Care":"Heimpflege",
    "Trending Products":"Trendprodukte",
    "Doctors":"Ärztinnen",
    'Jaya Clinic' : "Jaya Klinik",
    'Madhapur' : "Madhapur",
    'Directions' : "Richtungen",
    'ENT' : "HNO",
    'Cardiologist' : "Kardiologin",
    'MRP' : "MRP",
    'Add' : "Hinzufügen",
    'off' : "aus",
    'Download the MedleyMed app' : "Laden Sie die MedleyMed-App herunter",
    'Book appointments and health checkups' : "Buchen Sie Termine und Gesundheitsuntersuchungen",
    'Order medicines and consult doctors online' : "Medikamente bestellen und Ärzte online konsultieren",
    'Get the link to download the app' : "Holen Sie sich den Link zum Herunterladen der App",
    'Get App link' : "App-Link abrufen",
    'For patients' : "Für Patienten",
    'Search for doctors' : "Suche nach Ärzten",
    'Search for Clinics' : "Suche nach Kliniken",
    'Search for hospitals' : "Suche nach Krankenhäusern",
    'Our Services' : "Unsere Dienstleistungen",
    'Order Medicines' : "Medikamente bestellen",
    'Book Lab Tests' : "Labortests buchen",
    'Consult a Doctor' : "Einen Arzt konsultieren",
    'Our Policies' : "Unsere Bestimmungen",
    'Privacy Policy' : "Datenschutzrichtlinie",
    'Terms and Conditions' : "Geschäftsbedingungen",
    'Return Policy' : "Rücknahmegarantie",
    'Social' : "Sozial",
    'Need Help' : "Brauchen Sie Hilfe",
    'Our App' : "Unsere App",
    'App Store' : "Appstore",
    'Play Store' : "Spielladen",
    "Copyright":"Urheberrechte ©",
    "MedleyMed":"MedleyMed",
    "All rights reserved":"Alle Rechte vorbehalten",
    "This number will be used for doctor consultation related information, new offers. We will send a code for verification to this number":
    "Diese Nummer wird für ärztliche Konsultationsinformationen und neue Angebote verwendet. Wir senden einen Code zur Bestätigung an diese Nummer",
    "Login":"Anmeldung",
    "Signup":"Anmelden",
    "Next":"Nächster",
    "Use Email ID":"Verwenden Sie die E-Mail-ID",
    "Mobile Number":"Handynummer",
    "We will send you an SMS with a confirmation code to this number":"Wir senden Ihnen eine SMS mit einem Bestätigungscode an diese Nummer",
    "Enter Mobile number":"Geben Sie die Handynummer ein",
    "Name":"Name",
    "Email":"Email",
    "Enter email id":"Geben Sie die E-Mail-ID ein",
    "Mobile number":"Handynummer",
    "Please keep your health profile updated for better consultation experience":"Bitte halten Sie Ihr Gesundheitsprofil für eine bessere Beratungserfahrung auf dem neuesten Stand",
    "Profile Completed":"Profil abgeschlossen",
    "Who is the Patient":"Wer ist der Patient?",
    "Specify any long-term illness or chronic conditions present":"Geben Sie alle langfristigen Krankheiten oder chronischen Zustände an",
    "if any":"Wenn überhaupt",
    "Optional":"Optional",
    "Related medication":"Verwandte Medikamente",
    "Drug allergies present":"Arzneimittelallergien vorhanden",
    "Back":"Zurück",
    "Do it later":"Mach es später",
    "Save":"sparen",
    "Next":"Nächster",
    "Diabetes":"Diabetes",
    "Blood Pressure":"Blutdruck",
    "Headache":"Kopfschmerzen",
    "Type here eg. Diabetes":"Type hier zB. Diabetestes",
    "Add family Member":"Familienmitglied hinzufügen",
    "Help us with your information":"Helfen Sie uns mit Ihren Informationen",
    "Enter family member name":"Geben Sie den Namen des Familienmitglieds ein",
    "Enter mobile number":"Geben Sie die Handynummer ein",
    "Date of birth":"Geburtsdatum",
    "Gender":"Geschlecht",
    "Male":"Männlich",
    "Female":"Weiblich",
    "Other":"Andere",
    "Save & Next":"Speichern weiter",
    "Who is the Patient?":"Wer ist Patient?",
    "Hello!":"Hallo!",
    "My Appointment":"Meine Verabredung",
    "My Profile":"Mein Profil",
    "Privacy Policy":"Datenschutz-Bestimmungen",
    "Terms & Conditions":"Terms & amp; Bedingungen",
    "Upcoming":"Bevorstehende",
    "Tele medicine":"Telemedizin",
    "In Person Visit":"Persönlicher Besuch",
    "Your appointment scheduled on":"Ihr Termin ist am geplant",
    "Join Now":"Jetzt beitreten",
    "Past":"Vergangenheit",
    "appointment with":"Termin mit",
    "Directions":"Richtungen",
    "Doctor is Available now":"Doktor ist jetzt verfügbar",
    "View Prescription":"Rezept anzeigen",
    "Please wait for the call from clinic front desk":"Bitte warten Sie auf den Anruf an der Rezeption der Klinik",
    "You will receive call from a clinics front desk and confirm your time slot":"Sie erhalten einen Anruf von einer Klinikrezeption und bestätigen Ihr Zeitfenster",
   
    "Enter the details for":"Geben Sie die Details für ein",
    "Patient Intake Process":"Prozess der Patientenaufnahme",
    "Purpose of consultation":"Zweck der Beratung",
    "Specify Symptoms":"Geben Sie die Symptome an",
    "Provide Vital Informations":"Stellen Sie wichtige Informationen bereit",
    "History of Past Illness":"Geschichte vergangener Krankheiten",
    "Enter your vital information":"Geben Sie Ihre wichtigen Informationen ein",
    "Height":"Höhe",
    "Weight":"Gewicht",
    "BMI":"BMI",
    "Breath Per Minute":"Atem pro Minute",

    "Normal":"Normal",
    "Medium":"Mittel",
    "High":"Hoch",
    "Very high":"Sehr hoch",

    "Obese":"Fettleibig",
    "Over weight":"Übergewicht",
    "Underweight":"Untergewicht",

    "cm":"cm",
    "kg":"kg",

    "Temperature":"Temperatur",
    "Pulse":"Impuls",
    "Blood Sugar":"Blutzucker",
    "Respiratory Rate":"Atemfrequenz",
    "Systolic":"Systolisch",

    "Specify Symptoms":"Geben Sie die Symptome an",
    "Symptoms related to":"Symptome im Zusammenhang mit",
    "or Choose common symptoms":"oder Wählen Sie häufige Symptome",
    "Search Symptom":"Suchsymptom",
    "Select Symptom from body":"Wählen Sie Symptom aus Körper",
    "Add New Symptoms":"Neue Symptome hinzufügen",
    "Rotate Model":"Modell drehen",
    "Click on the body model":"Klicken Sie auf das Körpermodell",
    "Abdominal pain":"Bauchschmerzen",
    "select body part":"Körperteil auswählen",
    "Search your symptoms by search or":"Suchen Sie Ihre Symptome durch Suche oder",

    "History of Past Illness":"Geschichte vergangener Krankheiten",
    "Past Medical History":"Frühere Krankengeschichte",
    "Social History":"Sozialgeschichte",
    "Chronic conditions":"Chronische Erkrankungen",
    "Chronic medication":"Chronische Medikamente",
    "Add Current Medication":"Aktuelle Medikamente hinzufügen",
    "Drug Allergies":"Arzneimittelallergien",
    "Add Medical Document":"Medizinisches Dokument hinzufügen",
    "Upload Document":"Dokument hochladen",
    "Logout":"Ausloggen",

    "Type here eg. Fever" : "Geben Sie hier z. Fieber",

    "Enter code":"Code eingeben",
    "Continue":"Fortsetzen",
    "Please enter your confirmation code":"Bitte geben Sie Ihren Bestätigungscode ein",

    "mg/dL":"mg/dL",
    "bpm":"bpm",
    "F":"F",
    "Kg/m2":"Kg/m2",
    "Feet.Inch":"Feet.Inch",

    "Patient Details":"Patientendetails",
    "Patient Intake Process":"Prozess der Patientenaufnahme",
    "Filters":"Filter",
    "Clear all":"Alles löschen",
    "Languages":"Sprachen",
    "Sorting":"Sortierung",
    "Choose Specialist":"Wählen Sie Spezialist",
    "Gender":"Geschlecht",
    "Geschlecht":"Geschlecht",
    "Mode":"Modus",
    "Ratings":"Bewertungen",
    "View Profile":"Profil anzeigen",
    "Book an Appointment":"Vereinbare einen Termin",
    "reviews":"Bewertungen",
    "Registration No":"Registrierungsnr",
    "Fees":"Gebühren",
    "Education":"Bildung",
    "I can speak":"Ich kann sprechen",
    "Practice":"Trainieren",
    "Feedback":"Feedback",
    "My Area of expertise":"Mein Fachgebiet",
    "When do you want to consult a doctor":"Wann möchten Sie einen Arzt aufsuchen",
    "Please wait for doctor confirmation":"Bitte warten Sie auf die Bestätigung des Arztes",
    "Doctor will confirm shortly":"Der Arzt wird es in Kürze bestätigen",
    "Doctor confirmed":"Arzt bestätigt",
    "Please proceed to pay for the consultation":"Bitte zahlen Sie für die Beratung",
    "Pay Now":"Zahlen Sie jetzt",
    "Schedule your appointment":"Planen Sie Ihren Termin",
    "Or":"Oder",
    "Consult later":"Konsultieren Sie später",
    "Afternoon":"Nachmittag",
    "Evening":"Abend",
    "Morning":"Morgen",
    "Night":"Nacht",
    "am":"bin",
    "pm":"Uhr",

    
    "Select Doctor":"Wählen Sie Doktor",
    "Available Doctors for Telemedicine":"Verfügbare Ärzte für Telemedizin",
    "Dr":"DR",
    "appointment with":"Termin mit",
    "Consultation Time":"Konsultationszeit",
    "Purpose of visit":"Zweck der Reise",
    "Symptoms":"Symptome",
    "Vital Informations":"Wichtige Informationen",
    "Social History":"Sozialgeschichte",
    "Patient Drug Allergies":"Arzneimittelallergien bei Patienten",
    "Uploaded Document":"Hochgeladenes Dokument",
    "Fee Details":"Gebührendetails",
    "Doctor consultation Fees":"Arztberatungsgebühren",
    "Have a Coupon Code":"Haben sie einen Gutscheincode",
    "Enter Your Code":"Gib deinen Code ein",
    "Apply":"Anwenden",
    "Net Payable Amount":"Nettobetrag",
    "Accept Terms and conditions":"Nutzungsbedingungen akzeptieren",
    "Book Now":"buchen Sie jetzt",
    "Your appointment will be start at":"Ihr Termin beginnt um",
    "All" : "Alles" ,
    "Choose Specialist":"Wählen Sie Spezialist",
    "Find the doctor near you":"Finden Sie den Arzt in Ihrer Nähe",
    "Diabetes":"Diabetes",
    "eg. I had jaundice 2 months back":"z.B. Ich hatte vor 2 Monaten Gelbsucht",

  },
};
// GERMAN
