module.exports = {
  siteurl: "https://www.remotelhealth.com:8080/",
//siteurl: "http://localhost:8080/",
timezone: "Asia/Kolkata",
timeOffset: 330,
imgurl: "https://www.remotelhealth.com",
//imgurl: "http://localhost:3000",
// imgurl: (function () {
//   if (typeof window !== "undefined") {
//     let url = window.location.host;
//     if (url == "localhost:3000") {
//       return "http://localhost:3000";
//     } else if (url == "sunshinestaging.medleymed.com") {
//       return "https://sunshinestaging.medleymed.com";
//     } else {
//       return "https://sunshinestaging.medleymed.com";
//     }
//   }
// })(),
//paymentkey: "pk_test_b503e67b2f26e761101121c9704e9e06bc4c5b79",
paymentkey:"pk_test_50c4105c8a980c18302dc1abddf4278c31af1fe8",
gokadaApiKey:
"JtkvzUjr2Dt9osSRPotebKqrhvPZNTqHQc0Ric4djNB0r8DHWGZ7NIIqoXRM_test",
//sendername: "Padma Clinic",
sendername: "Remotel ",
senderEmail:"team.sanar@gmail.com",
currency: "₹",
countryID: "101",
stateId: 35,
// Paytm keys
// paytm_mid: "KwRQVt65865982291013",
// paytm_merchantKey:"1usZCfZTfQWQiH#z",
Paytm_callBackURL:"api/PatientAppointment/TelemedicePaytmPaymentSuccess",
Paytm_orderMedicineCallBackURL:"api/OM/orderProcess/OrderMedicinePaytmPaymentSuccess",
/* for Staging */
Paytm_hostname:'securegw-stage.paytm.in',
// Paytm keys
paytm_mid: "KwRQVt65865982291013",
paytm_merchantKey:"1usZCfZTfQWQiH#z",
/* for Production */
// Paytm_hostname: 'securegw.paytm.in'
//Paytm_hostname: 'securegw.paytm.in',
// Paytm keys
//paytm_mid: "UvCRif08464633111500",
//paytm_merchantKey:"@9HGTx7G%OwHHCO_",

// Appointment waiting time for consult now
appointmentWaitingTime:0,

// PDf image base url
imgHostUrl:"file:///var/Remotel/public",

// Video call domain and Jwt token
//  jitsiDomain:"videovisit.medleymed.com",
//  jitsiJwt:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnt9fSwiYXVkIjoidmlkZW92aXNpdC5tZWRsZXltZWQuY29tIiwiaXNzIjoiTWVkRG9jTWFzdGVyQXBwSUQiLCJzdWIiOiJ2aWRlb3Zpc2l0Lm1lZGxleW1lZC5jb20iLCJyb29tIjoiKiJ9.tmoZKAHyrB94Ir2j0cXz1Jop52b1OA91vimQ5fBpejo',
jitsiDomain:"jitsimeet.medleymed.in",
//jitsiJwt:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnt9fSwiYXVkIjoiaml0c2ltZWV0Lm1lZGxleW1lZC5pbiIsImlzcyI6Ijg2MEY5QzVCNjdEQjhDODQwNUFFOTExNEQ4QTdBNDY3Iiwic3ViIjoiaml0c2ltZWV0Lm1lZGxleW1lZC5pbiIsImlhdCI6MTk1MzQ5ODgxOSwiZXhwIjoxNzI2MDUyMjk0LCJyb29tIjoiKiJ9.6dGwjMH14kZhjaIWNCZCtup3QIzVuytF2QgQTUeHY2E',

jitsiJwt:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnt9fSwiYXVkIjoiaml0c2ltZWV0Lm1lZGxleW1lZC5pbiIsImlzcyI6Ijg2MEY5QzVCNjdEQjhDODQwNUFFOTExNEQ4QTdBNDY3Iiwic3ViIjoiaml0c2ltZWV0Lm1lZGxleW1lZC5pbiIsImlhdCI6MTk1MzQ5ODgxOSwiZXhwIjoxNzMxODM5NTQ2LCJyb29tIjoiKiJ9.lO5UAvoec5sqoxGLj1RzYzKvgcdv7F43b7eW8aui5dw',
//Verify signature use witout encode: 8C8405AE9114D8A7A467
//payload for JWTToken:
// {
//   "context": {
//     "user": {}
//   },
//   "aud": "jitsimeet.medleymed.in",
//   "iss": "860F9C5B67DB8C8405AE9114D8A7A467",
//   "sub": "jitsimeet.medleymed.in",
//   "iat": 1953498819,
//   "exp": 1726052294,
//   "room": "*"
// }


// APi key for Lat,langs
//googleAPiKey:"AIzaSyAhM9f13JS3erSaJ0gAeQm8w0KcMfcIopU",
//googleAPiKey:"AIzaSyCJvEQmWCo1HL1eOePHbjUbK_Wd7LlJkMs",
googleAPiKey: "AIzaSyA8DX2hscBm1X1ZJc07d4cMb5dQ9u3HdII",
 //consult now appointment duriation
 doctorSlotDuration: "00:15", //HH:mm
 //cancellation duration for PatientAppointment
 orderCancelationTime: "00:05",  //HH:mm
 retailerWallertMiniumpoints:50,

 // Payment Gateway
 // razorpay_key_id: 'rzp_test_D86RkCbANxJSQS', // Test YOUR RAZORPAY KEY
 // razorpay_key_secret:'c0FxxootuhD5bCWTGhqiA75N', // Test YOUR RAZORPAY SECRET
 //razorpay_key_id: 'rzp_test_bhpoqh7a39AwWV', // Test YOUR RAZORPAY KEY
 //razorpay_key_secret:'oukA5x0WyngHVUSOPSUSYpoi', // Test YOUR RAZORPAY SECRET

 // razorpay_key_id: 'rzp_test_DZlU5YdCANiIib', // Test YOUR RAZORPAY KEY
 // razorpay_key_secret:'aJAJhL5JCWVlpJVHZ3Bk2W7d', // Test YOUR RAZORPAY SECRET
 //Test mode keys
 //razorpay_key_id: 'rzp_test_gIIAc42lIE8qma', // Test YOUR RAZORPAY KEY 
 //razorpay_key_secret:'olET4WyhaaB0gvRtvcTu2HIF', // Test YOUR RAZORPAY SECRET
//  razorpay_key_id:'rzp_test_hGECBnTSatvq9K',
//  razorpay_key_secret:'og5L7aQSsJyK45AYiqwkDrNP',

 // Production keys Razorpay
 razorpay_key_id:'rzp_live_5qPNYxDaYDF1bC',
 razorpay_key_secret:'tvlWSjMAU5wv7RLkltfhlw4t',

 //One signal keys
 // doctorRestKey: 'ZDM1MThjYjEtZWE4NC00ZjU0LTgxYmQtMGJhMjUxNjFkMmNk',
 // doctorAppID: 'fd9343de-879e-4860-b9fa-78b9fdcc7ca3',

 // doctorRestKey: 'MmRiNjIxNzktMDMyMi00YzNlLTg3OGEtODk2NTA5NmNiODBm',
 // doctorAppID: 'ecded6f9-1f9f-47b6-a011-cf0e07e21053',
 // patientRestKey: "OTU2NzU4ZTMtNjA0MS00MmEzLThjZjMtYWE3OWY4Mjg3ZDY5",
 // patientAppID: "095a7d91-4277-447b-9be7-6c4c1c2cb94f",
 //doctorRestKey: 'MjgyMDRkMGItNjA2Ni00Mzg5LWFhNzktYzdlYmRiMGU3ZGU2',
 // doctorRestKey: 'ODI2YjljMjMtZmY4OS00M2IxLWEyZmEtMmZhMzVlZDFiNTA0',
 // doctorAppID: '26254c69-6fa6-49db-b418-98c2ee12e448',
 doctorRestKey: 'MmRiNjIxNzktMDMyMi00YzNlLTg3OGEtODk2NTA5NmNiODBm',
 doctorAppID: 'ecded6f9-1f9f-47b6-a011-cf0e07e21053',

 patientRestKey: "YjdkOTY2Y2ItOGRmYi00Y2QzLTgyNmEtMmZmMWMxYTkwZTJk",
 patientAppID: "a20180d7-d908-4c6d-b126-68ce2d712430",

 doctorAppointmentchannelID:"bc578075-202d-4d49-a29a-f87e0d1959ea",
 patientAppointmentchannelID:"79e2cc27-6846-476d-becc-838c7d6b24fa",
 patientRemainderchannelID:"e1165801-68a0-4976-9b89-ceb4a613a16b",
 RemainderchannelID:"e1165801-68a0-4976-9b89-ceb4a613a16b",
 //patientRestKey: "YjdkOTY2Y2ItOGRmYi00Y2QzLTgyNmEtMmZmMWMxYTkwZTJk",
 // patientRestKey: "NmM3YzlhYWEtMjQ0MC00MzUyLWExY2UtYWRhZDg5MTMxOTYy",
 // patientAppID: "d44e007e-ff81-4b69-8568-8396912dd4ca",
 //channelID:"39a96e37-cc5d-4edb-8dcf-1abe9242673e",
 // doctorAppointmentchannelID:"d09099d2-9fd0-4f2a-a5e2-3cd2b9f0734a",
 // patientAppointmentchannelID:"968ec926-9199-4aec-a32f-a843d5305d02",
 // patientRemainderchannelID:"39a96e37-cc5d-4edb-8dcf-1abe9242673e",
 // RemainderchannelID:"c9e67933-f44a-4ba1-b926-1ed762cebe40",
 //RemainderchannelID:"6a6d35b3-c5dc-429b-8a08-c6a945dfd764",
 defaultSubscriptionId: "1",
 EveryReminderTimeset : 5,
 UptoReminderTimeset : 30,
 RemainderTimeSlotDifference:30,
 ReminderChannelsIds : {  
  remainderaudio : 'e1165801-68a0-4976-9b89-ceb4a613a16b',
  medical : '173a23a2-e9c0-4b39-b25d-e5cdf0b0bebe',
  pills : 'eecbedcf-e7c0-4999-808a-e46184dd9b34',
  take_your_pills : '03249b37-0958-4711-aff8-cd8bdff7bb8e',
  pill_time : 'e17a242f-fe6c-465d-91cc-c5386a446b6c',
  notify_tingle : '26a12855-0b2b-44d0-8992-5031e6982fff'
 }


};