import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Link } from "react-router-dom";
import FileBase64 from "react-file-base64";
import Header from "./Header";
import Sidebar from "./sidebar";
import PatientFooter from "../patient/Patientfooter";
const fs = require("fs");

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      productImageUploaded: "",
      product_pic_show: "",
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  //  For onchange
  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  //  For getting product image files
  getProductImageFile(files) {
    this.setState({ ProductImageFile: files });
    this.state.productImageUploaded = 1;
    this.state.fields.product_pic = files;
    this.state.fields.product_pic_show = files;
    this.forceUpdate();
  }
  //  For submitting the form
  onFormSubmit(e) {
    e.preventDefault();

    const { handle } = this.props.match.params;
    Httpconfig.httptokenput(
      Constant.siteurl +
        "api/productsMaster/ProductMasterImageUpload/" +
        handle,
      {
        myImage: this.state.fields.product_pic,
      }
    )
      .then((response) => {
        toast.success("Product Image uploaded Successfully");
        setTimeout(() => this.props.history.push("/admin/ViewProducts"), 2000);
      })
      .catch((error) => {});
  }
  render() {
    let signatureImage = "";
    let signatureName = "";
    if (this.state.fields.product_pic_show) {
      signatureName = this.state.fields.product_pic_show[0]["base64"];
      signatureImage = signatureName;
    }

    return (
      <React.Fragment>
        <Header />
        <Sidebar />
        <section id="main_dashboard">
          <div className="container" id="main_front">
            <div className="row">
              <div className="col-md-12">
                <div className="dash-section">
                  <div className="section-header">
                    <ol className="breadcrumb">
                      <li className="active">
                        <Link to="/admin"> Dashboard</Link> &gt;
                        <a> Upload Images </a>
                      </li>
                    </ol>
                  </div>
                </div>

                <section id="CMS_tab">
                  <div className="CMS_content">
                    <div className="container">
                      <div className="row">
                        <div className="tab-header">
                          <h3>Upload Product Image</h3>
                        </div>
                        <div id="reg_form">
                          <form onSubmit={this.onFormSubmit}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group col-md-12">
                                  <FileBase64
                                    multiple={true}
                                    onDone={this.getProductImageFile.bind(this)}
                                  />

                                  <div class="upload_imgs">
                                    {this.state.productImageUploaded == 1 ? (
                                      <div class="upload_view_img">
                                        <a
                                          href="#"
                                          class="thumbnail"
                                          data-toggle="modal"
                                          data-target="#lightbox"
                                        >
                                          <img src={signatureImage} />
                                        </a>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-md-4">
                                <button
                                  type="submit"
                                  className="btn  btn-primary padTopCategorySave fright"
                                >
                                  Save Image
                                </button>{" "}
                                &nbsp;
                                <Link
                                  to="/admin/ViewProducts"
                                  className="btn  btn-primary fright"
                                  style={{ marginTop: 22 }}
                                >
                                  Cancel
                                </Link>{" "}
                                &nbsp; &nbsp;
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <PatientFooter />
      </React.Fragment>
    );
  }
}
