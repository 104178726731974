import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Patnewcss from "../../public/css/patient/style-new.css";
import Constant from "../../constants";
import FileBase64 from "react-file-base64";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class Patientmenu extends Component {
  constructor(props) {
    super(props);

    const lang = localStorage.getItem("Language_selected");
    this.state = {
      Language: "",
    };
  }

  // called after the React component props or state has changed.

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
  };

  // To get detais after first render

  componentDidMount = () => {
    let url_path = window.location.pathname;

    let pages = url_path.split("/");
    var page = pages[1];
    var obj = {};

    obj[page] = "active";
    this.setState(obj);

    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      this.state.Language = lang;
    } else {
      this.state.Language = lang;
    }

    var retrievedObject = ls.get("userObjEnc");
    let userData = retrievedObject;
    if (retrievedObject != null || retrievedObject != null) {
      this.setState({
        token: userData.accessToken,
        patient_id: userData.patient_id,
        patientId: userData.patient_id,
      });
      this.getProfileDetails(userData.patient_id);
    }
  };
  //  For getting the profile details
  getProfileDetails = (patientId) => {
    if (patientId != "") {
      let url_path = "api/Patients/" + patientId;
      let patientDob = "";
      Httpconfig.httptokenget(Constant.siteurl + url_path, {})
        .then((response) => {
          this.state.selectedPatientId = patientId;
          if (response.data.status == 200 && response.data.error == false) {
            response.data.data.map((relationsList, num) => {
              if (relationsList.profile_pic != null) {
                this.state.patientProfileImage = relationsList.profile_pic;
              } else {
                this.state.patientProfileImage = null;
              }

              this.state.name = relationsList.name;
              this.state.gender = relationsList.gender;
            });
            this.forceUpdate();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  // For getting the files
  getFiles(files) {
    if (files[0].type == "image/png" || files[0].type == "image/jpeg") {
      this.setState({ files: files });
      let patientId = this.state.patientId;
      let patientProfileImage = this.state.files[0].base64;
      this.state.patientProfileImage = patientProfileImage;
      this.forceUpdate();

      Httpconfig.httptokenput(
        Constant.siteurl + "api/Users/uploadimages/" + patientId,
        {
          profile_pic: this.state.files,
        }
      )
        .then((response) => {
          toast.success("👌 Profile Image Updated Successfully", {
            position: "bottom-center",
          });
          window.location.reload();
        })
        .catch((error) => {
          this.props.history.push("/patienthealthprofile");
          toast.error(error);
        });
    } else {
      this.forceUpdate();
      toast.error("Upload files Jpeg / Png formate only!");
    }
  }

  render() {
    return (
      <div>
        <I18nPropvider locale={this.state.Language}>
          <div class="pro_section">
            <div class="profile_menu_sec" id="pro_menu">
              <div class="profile_box">
                {this.state.patientProfileImage != null ? (
                  <img src={this.state.patientProfileImage} />
                ) : this.state.gender == "female" ? (
                  <img src="../images/patient/img/Profile/Female_patient.svg" />
                ) : this.state.gender == "male" ? (
                  <img src="../images/patient/img/Profile/Male_patient.svg" />
                ) : (
                  <img src="../images/patient/img/Profile/user_profile.svg" />
                )}
                <a href="#">
                  {" "}
                  <h2 class="edit_avtar">
                    <div class="input-group">
                      <span class="input-group-btn"></span>
                    </div>
                    <span className="btn-file">
                      <img src="../images/patient/img/Profile/edit.svg" />
                      <FileBase64
                        multiple={true}
                        onDone={this.getFiles.bind(this)}
                      />
                    </span>
                  </h2>
                </a>
                <div class="profile_name_sec">
                  <p class="hello_word">{translate("Hello!")}</p>
                  <p class="doc_name">
                    {this.state.gender == "female"
                      ? "Ms. "
                      : this.state.gender == "male"
                      ? "Mr. "
                      : ""}{" "}
                    {this.state.name ? this.state.name : ""}
                  </p>
                </div>
              </div>

              <ul class="profile-menu-ul-1">
                <li class={this.state.Patientdashboard ? "profile_li" : ""}>
                  <a href="/Patientdashboard">
                    <img src="../images/patient/img/Ordermedicine/myAppointment.svg" />
                    {translate("My Appointments")}{" "}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>

                <li
                  class={this.state.Patientprofile ? "profile_li" : ""}
                  onClick={this.addPatient}
                >
                  <a href="/Patientprofile">
                    <img src="../images/patient/img/Ordermedicine/MyProfile.svg" />
                    {translate("My Profile")}{" "}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>
                <li class={this.state.myOrders ? "profile_li" : ""}>
                  <a href="/myOrders">
                    <img src="../images/patient/img/Ordermedicine/My_Orders.svg" />
                    {translate("Diagnostic Orders")}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>
                <li class={this.state.manageAddress ? "profile_li" : ""}>
                  <a href="/manageAddress">
                    <img src="../images/patient/img/Ordermedicine/address-book.svg" />
                    {translate("Address Book")}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>
                <li class={this.state.ViewVitals ? "profile_li" : ""}>
                  <a href="/ViewVitals">
                    <img src="../images/patient/img/Ordermedicine/vital-icon.svg" />
                    {translate("Vital Sheet History")}{" "}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>
                <li class={this.state.ViewReminder ? "profile_li" : ""}>
                  <a href="/ViewReminderSetting">
                    <img src="../images/patient/img/Ordermedicine/med-reminder.svg" />
                    {translate("Medicine Reminder ")}{" "}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>

                <li class={this.state.ViewReminder ? "profile_li" : "   "}>
                  <a href="/ViewReminderConfirmation">
                    <img src="../images/patient/img/Ordermedicine/med-reminder.svg" />
                    {translate("Medicine Reminder History")}{" "}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>
                <li class={this.state.ViewReminder ? "profile_li" : ""}>
                  <a href="/ViewsubscriptionPlan">
                    <img src="../images/patient/img/Ordermedicine/subscription.svg" />
                    {translate("Subscription")}{" "}
                    <span class="right_arrow">
                      <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                    </span>
                  </a>
                </li>

                <h2>Others</h2>
                <li class="">
                  <img src="../images/patient/img/Ordermedicine/PrivacyPolicy.svg" />
                  <a
                    href={Constant.imgurl + "/patient/PrivacyPolicy"}
                    target="_blank"
                  >
                    {translate("Privacy Policy")}
                  </a>
                  <span class="right_arrow">
                    <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                  </span>
                </li>
                <li class="">
                  <img src="../images/patient/img/Ordermedicine/T&C.svg" />
                  <a
                    href={Constant.imgurl + "/patient/TermsConditions"}
                    target="_blank"
                  >
                    {translate("Terms & Conditions")}
                  </a>
                  <span class="right_arrow">
                    <img src="../images/patient/img/Ordermedicine/rightIcon.svg" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </I18nPropvider>
      </div>
    );
  }
}
