import Patnewcss from "../../public/css/patient/termsconditions.css";
import React, { Component, useState } from "react";
import PatientFooter from "../patient/Patientfooter";
import Constant from "../../constants";
export default class PatientPastIllness extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }
   handleLanguage = (langValue) => {
      this.setState({ Language: langValue });
   };
   render() {
      return (
         <main id="main">
            <section id="terms_agreement">
               <div class="page_title">
                  <div class="container">
                     <div class="row">
                        <div class="col-md-12 no_padding">
                           <div class="heading">
                              <h1>Terms and Conditions</h1>
                              {/* <div class="breadcrumbs">
                                 <ul>
                                    <li><a href={Constant.imgurl}>Home</a></li>
                                    <span>
                                       <i class="fa fa-chevron-right"></i>
                                    </span>
                                    <li class="active">Terms and Conditions</li>
                                 </ul>
                              </div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="container">

                  <div class="row">
                     <div class="col-md-12 no_padding">
                        <div class="terms_box">
                           <div class="terms_content">
                              <div class="terms_list">
                                 <p><strong>Remotel Healthcare Private Limited (“we“, “our“, or “us“)</strong> operates and manages www.remotelhealthcare.com. Our aim is to facilitate an affordable and high quality health care service to all users (“user“, “you“, or “your“) by connecting each of you through our Website, to doctors of your choice, and in this regard, if prescribed, assist you to navigate through entire consultation, surgical process, diagnostic process, and/or in recovery procedure, as prescribed by the doctor of your choice, or part thereof (“Services”).</p>
                                 <p><strong>These terms govern your use of the Website and Services (“Terms of Use”) and are published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy and Terms of Use for access or usage of the Website.</strong></p>
                              </div>
                              <div class="terms_list">
                                 <h1>1. Agreement</h1>
                                 <h2><span>1.1</span>Please read these Terms of Use carefully and contact us if you have any questions. By visiting our Website and/or availing any of our Services, it is deemed that you have read and understood the Terms of Use and you are agreeing with the Terms of Use, as well as the Privacy Policy , which is hereby incorporated into these Terms of Use.<strong> IF YOU DO NOT AGREE WITH ANY OR ALL OF THE FOLLOWING TERMS, PLEASE DO NOT ACCESS AND/OR USE OUR WEBSITE OR OUR SERVICES.</strong></h2>
                                 {/* <h2><span>1.2</span>By visiting our Website and/or by availing our Services on behalf of an individual such as your child or any person not capable of providing consent,or an employer,it is deemed that you are the lawful guardian of the child or are authorised by such individual or entity, as the case may be, to accept the Privacy Policy on behalf of such individual or entity, and consent to our collection, storage, usage, disclosure and/or transfer of such individual’s or entity’s information as described in this Privacy Policy.</h2>
                          <h2><span>1.3</span>The User’s consent shall be taken for the purpose of sharing data collected by ‘Instacare” with the hospitals and/or insurance agency and/or EMI Service providers.</h2> */}
                              </div>
                              <div class="terms_list">
                                 <h1>2. Eligibility</h1>
                                 <h2><span>2.1</span>This Website is intended for users above 18 (eighteen) years of age .The lawful guardian of individuals below the age of 18 (eighteen) years and/or of individuals with unsound mind may use the Website or avail Services on behalf of such individuals. By visiting our Website and/or by availing our Services on behalf of an individual such as your child below the age 18 (eighteen) years or any person not capable of providing consent, or an employer, it is deemed that you are the lawful guardian of the child or are authorised by such individual or entity, as the case may be, to accept the Terms of Use on behalf of such individual or entity.</h2>
                                 {/* <h2><span>2.2</span>Personal Information means any information about you from which you can be identified, including but not limited to:</h2>
                           <ul>
                              <li><span>(a)</span>Identity information: your full name, age, date of birth and gender;</li>
                              <li><span>(b)</span>Contact information:your contact number, e-mail address, residential address (present and permanent);</li>
                              <li><span>(c)</span>Technical information: internet protocol address, cookie data, browser type, browser language, referring URL, errors generated at the time of usage of Website, date and time zone.</li>
                              <li><span>(d)</span>Usage information: includes information about how you use the Website including but not limited to content viewed, content shared, pages visited on the Website, appointments taken and/or consultations booked by you, by using our Services;</li>
                              <li><span>(e)</span>Your insurance related information; and</li>
                              <li><span>(f)</span>Any other personal information that is willingly and freely shared by you on the Website or in the course of availing our Services.</li>
                           </ul>

                           <h2><span>2.3</span>Sensitive Personal Information means personal information revealing, related to, or constituting, as may be applicable-</h2>
                           <ul>
                              <li><span>(a)</span>Username and password keys to enable access to our Website ;</li>
                              <li><span>(b)</span>Financial information such as bank account details, credit and debit card details or any other payment instrument details;</li>
                              <li><span>(c)</span>Health information including but not limited to medical records, symptoms, and appointment or consultations history, medications, physical, psychological and mental health condition and surgical procedures;</li>
                              <li><span>(d)</span>Official identifier;</li>
                              <li><span>(e)</span>Biometric information;</li>
                              <li><span>(f)</span>Genetic information;</li>
                              <li><span>(g)</span>Transgender status; and</li>
                              <li><span>(h)</span>Intersex status;</li>
                           </ul>
                           <h2><span>2.4</span>We may collect your information included in your personal profile which is created on the Website after you register, any Personal Information and/or Sensitive Personal Information contained in any enquiry made by you regarding our Services, and any Personal Information and/or Sensitive Personal Information contained in or in relation to any communication that you share with us.</h2> */}
                                 {/* <h2><span>2.5</span>However, we may collect any information where your identity has been anonymised and is not in any manner personally identifiable to you. We will also be free to collect any such information that is freely available in the public domain without your consent.</h2> */}
                              </div>
                              <div class="terms_list">
                                 <h1>3. Scope of Service</h1>
                                 <h2><span>3.1</span>We facilitate connection between you and the doctor/surgeon of your choice through our Website. After you choose a doctor/surgeon to provide you health care services, we will facilitate a consultation by you with the doctor/surgeon. Please note that “REMOTEL HEALTHCARE PRIVATE LIMITED” is not a provider of any health care services, and has no responsibility for delivering any health care services and does not take responsibility whatsoever for facilitation. You agree to assume all responsibility in relation with choosing any doctor/surgeon for your desired procedure. We assume no responsibility or liability for any treatment or other services, health care services rendered by any doctor/surgeon, or for any malpractice claims, failure to follow adequate standard of care and/or other claims that may arise directly or indirectly from any such advice, treatment or other services including but not limited to health care services.</h2>
                                 {/* <ul>
                              <li><span>(a)</span>Information provided by you during direct interactions with us: You may give us information during the course of receiving any Services or otherwise, when you access our Website, use any of the features of our Website, when you register on our Website, by filling in forms (electronically or physically),via e-mail, phone, social media platforms (including but not limited to Facebook or YouTube), messages (including but not limited to short message service, WhatsApp), or by contacting any of our representatives/employees, or when you give feedback to us.</li>
                              <li><span>(b)</span>Automatic data collection tools: as you interact with our Website, we may automatically collect technical information including but not limited to usage data about your browsing actions and patterns, and such other information as mentioned in clause 2.2 (c) of this Privacy Policy. We collect this personal data by using cookies, web beacons, server logs and other similar technologies.</li>
                              <li><span>(c)</span>Information from third parties: we may collect information about you from our authorised third parties including but not limited to physicians, doctors, hospitals, clinical labs, diagnostic labs, pharmacies, payment gateways and advertisers, that may include your Personal Information and/or Sensitive Personal Information .</li>
                           </ul> */}
                                 <h2><span>3.2</span>We facilitate for you your treatment(s) at various stages, the Services (including but not limited to) which we provide through our Website are as follows:</h2>
                                 <ul>
                                    <li><span>(a)</span><strong>Booking Consultation:</strong> We facilitate you to book preliminary consultations with the doctors/surgeons of your choice and book subsequent consultations only on your request. We provide you the [Book a Consultation] Service on the profile page of the doctors/surgeons on the Website. To avail this Service, you are required to select consulting hospital of the doctors/surgeons of your choice and pick a time slot of your choice from the available time slots. To confirm the consultation, you are required to provide your email address and mobile phone number. Upon receiving a request, we will confirm the consultation subject to availability of the doctor/surgeon during the selected time slot.</li>
                                    <li><span>(b)</span><strong>“Ask your surgeon” facility:</strong> We facilitate you through our Website to connect to doctors/surgeons and seek queries of your choice. To avail this Service, you are required to provide your contact information including your email address and mobile phone number along with the query you want to raise to a doctor/surgeon. Upon successful generation of a query, we facilitate you in receiving a response from the doctor/surgeon via messaging application (including but not limited to WhatsApp). Please note that “REMOTEL HEALTHCARE PRIVATE LIMITED ” is not providing any medical advice and/or endorsing any specific doctor/surgeon or medical procedure and we shall not be responsible for any medical advice given by any doctor/surgeon.</li>
                                    <li><span>(c)</span><strong>Get Quotations:</strong> We facilitate you through our [Get a Quote] service on our Website, with an estimated quotation of required surgery or medical procedure at various consulting hospitals of the surgeons/doctors specialising in that medical procedure in the selected city (provided the Services are available in the selected city). Please note that the final price may vary from the quotation depending upon the complexities in your treatment, your needs and/or your treatment plan.</li>

                                    <li><span>(d)</span><strong>Diagnostic Facility:</strong> We facilitate you with your diagnostic tests, as required during the medical procedure/treatments and as advised by the doctor/surgeons you choose. Please note that we do not conduct any diagnostic tests and are not responsible for any sample collected, tests conducted and the accuracy, authenticity and/or correctness of diagnostic test reports.</li>
                                    <li><span>(e)</span><strong>Financing Facility:</strong> We facilitate you with adequate financial support, by providing you with the facility of Easy Monthly Instalment (EMI) Scheme. However, it may depend upon including but not limited to the complexity of the surgical treatment/operation, your annual income, your credit rating or any other conditions applicable at the time of availing the EMI facility.</li>

                                    <li><span>(f)</span><strong>Post Operative Care:</strong> We facilitate you to avail post operative care facilities of your choice as per your request and/or as per the advice of your doctor/surgeon. Please note that we do not refer, recommend, or endorse any post operative care providers or organisations or services</li>

                                 </ul>
                                 <h2><span>3.3</span>We facilitate contact with the doctors/surgeons, and provide information about them and their credentials in order for you to make informed decisions. We do not, however, recommend any preferred or specific doctors/surgeons for your desired treatment.</h2>
                                 <h2><span>3.4</span>You agree to use the Website, the materials provided therein and avail Services only for the purposes that are permitted by the Terms of Use, and any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.</h2>
                                 <h2><span>3.5</span>We reserve the right to either accept or refuse to provide our users all/any of the Services mentioned in Para 3.2 of this Terms of Use, as per Clause 4 of this Terms of Use. We may cancel any Services for any reason whatsoever by informing you of such cancellation any time prior to, or during rendering such Services. Further, we may cancel your scheduled consultation in case of non-availability of concerned doctor/surgeon and/or non-payment of consultation fees, by informing you of such cancellation any time prior to the scheduled consultation. It is solely our discretion to provide you with Services without any liability to you.</h2>
                                 <h2><span>3.6</span>We reserve the right to add, delete, modify, change, suspend, discontinue and/or revise the method of providing Services through our Website or otherwise, or any features of our Services without any prior notice. However, we shall be not liable for any addition, deletion, modification, change, suspension, discontinuance and/or revision of services made by surgeons/ doctors, medical establishments, clinical labs, diagnostic labs, hospitals, post-operative care providers, or pharmacies.</h2>

                              </div>
                              <div class="terms_list">
                                 <h1>4. Refusal to provide access to Website and/or Services</h1>
                                 <h2><span>4.1</span> We reserve the right to refuse access to our Website and/or refuse to provide you with our Services, if:</h2>
                                 {/* <h2>Where it is necessary for the performance of a contract (already entered into or prior to entering into), and/or where it is necessary for fulfilling legitimate interests pursued by us or by an authorised third party:</h2> */}
                                 <ul>
                                    <li><span>(a)</span>You misbehave with any or all of the employees/staff/authorised personal of “ REMOTEL HEALTHCARE PRIVATE LIMITED ” and/or misbehave with any or all of the employees/staff/authorised personal of any of the authorised healthcare providers including but not limited contracted hospitals or clinical labs or diagnostic labs or post operative care operators and/or misbehave with any doctors/surgeons;</li>
                                    <li><span>(b)</span>You misrepresent that you are the lawful guardian of a child below the age of 18 years and or you are authorised on behalf of any person not capable of providing consent or an employer to avail our Services and/or access our Website;</li>
                                    <li><span>(c)</span>You fail and/or refuse to make payments with respect to any of your Services that you have availed and/or to any of the authorised healthcare providers including but not limited contracted hospitals or clinical labs or diagnostic labs or post operative care operators;</li>
                                    <li><span>(d)</span>You interfere with the operation and management of “ REMOTEL HEALTHCARE PRIVATE LIMITED ” or any of the authorised healthcare providers including but not limited contracted hospitals or clinical labs or diagnostic labs or post operative care operators; or</li>
                                    <li><span>(e)</span>You indulge in any activity prescribed under Clause 5.2 of the Terms of Use</li>
                                 </ul>
                                 {/* <h2><span>4.2</span> In order to protect your vital interests as also of any other natural person, we may collect, use and share your information, including but not limited to medical records, symptoms, consultations, medical history and medications to prevent a serious threat to your health and safety or that of others. Your information will only be shared by us with persons, authorities or organization that may help you in preventing such a threat</h2> */}
                              </div>
                              <div class="terms_list">
                                 <h1>5. Use of Website:</h1>
                                 <h2><span>5.1</span>You hereby agree and acknowledge that you will use the Website for lawful and genuine purposes. You will not use the Website to harass other users or take any actions that will affect the availability or functionality of our Website or Services. You shall also not attempt to modify the features of the Website or any of its content. Any unauthorized attempts to modify or actual modification of any information stored on the Website shall result in appropriate legal action against you.</h2>
                                 <h2><span>5.2</span><strong>Data analytics: </strong>we may share information about you with authorised third parties hired to track the Website usage and analyse your information. Data analytics is performed in order to better understand usage of our Services and enhance the Website experience by helping us make better business decisions. However, all such third parties are subject to confidentiality obligations to reasonably protect your information.
                                    In terms of Rule 3(2) of the Information Technology (Intermediaries Guidelines) Rules, 2011, you shall not host, display, upload, modify, publish, transmit, update or share any information which:
                                 </h2>
                                 {/* <h2><span>5.3</span>Performance of a contract: we may share your information including but not limited to medical records, symptoms, consultations, medical history and medications, with doctors, hospitals, and medical establishments including but not limited to clinical labs and diagnostic labs, in order to enforce any contract between us.</h2>
                           <h2><span>5.4</span>Business development: we may share anonymised data which does not personally identify you with prospective businesses in order to expand our field of services.</h2>
                           <h2><span>5.5</span> Compliance of legal obligation or dispute resolution: we may share information about you in compliance with any law and/or order of any court or tribunal. We may also disclose your information in order to resolve any dispute.</h2>
                         <h2>Please note that except as stated above, we will not share your identifiable information with any other person without your consent. However, we reserve the right to share anonymised data for the purposes we deem fit.</h2> */}
                                 <ul>
                                    <li><span>(a)</span>belongs to another person and to which the user does not have any right to;</li>
                                    <li><span>(b)</span>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                                    <li><span>(c)</span>harm minors in any way;</li>
                                    <li><span>(d)</span>infringes any patent, trademark, copyright or other proprietary rights;</li>
                                    <li><span>(e)</span>violates any law for the time being in force;</li>
                                    <li><span>(f)</span>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                                    <li><span>(g)</span>impersonate another person;</li>
                                    <li><span>(h)</span>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                                    <li><span>(i)</span>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation</li>
                                 </ul>
                              </div>
                              <div class="terms_list">
                                 <h1>6. Privacy Policy</h1>
                                 <h2><span>6.1</span>We shall not collect, store, use, disclose and/or transfer any information including Personal Information and Sensitive Personal Information (as defined in the Privacy Policy) identifiable to you without your consent. To read and understand how we collect, store, use, disclose and/or transfer, please refer to our Privacy Policy.</h2>
                                 <h2><span>6.2</span>Please note that except as stated above, we will not share your identifiable information with any other person without your consent. However, we reserve the right to share anonymised data for the purposes we deem fit.</h2>
                                 <h2><span>6.3</span>It is paramount and imperative that you provide accurate, truthful, correct, and authentic information during access to the Website and provision of Services. By submitting any information on our Website and/or by availing our Services, you represent and warrant to us that all the information provided to us, both during the registration process or anytime thereafter, is true, correct, accurate and authentic</h2>

                                 <h2><span>6.4</span>We shall not be responsible in any manner for the authenticity of information (including Personal Information or Sensitive Personal Information) shared by you to us or to any of our authorised personnel</h2>

                                 <h2><span>6.5</span> It is your responsibility to keep your email address and mobile phone number up-to-date with us so that we can communicate with you to provide Services. By providing your information and registering with, you agree to receive communications from us via e-mail, telephone, mobile short message service (SMS) and messaging apps (including but not limited to Whatsapp). If you, at any time, wish to discontinue receiving communications from us, you agree to notify us by e-mail.</h2>

                                 {/* <h2><span>6.6</span>The right to object to processing: You have the right to object to our collection, storage, usage, disclosure and/or transfer of information: (i) where we are relying on a legitimate interest and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts your fundamental rights and freedoms; and (ii) where collection, storage, usage, disclosure and/or transfer of your information is used for marketing purposes.</h2>
                           <h2><span>6.7</span>The right to information portability: You have the right to transmit your information from us to any other service provider.</h2>
                           <h2><span>6.8</span>Rights in relation to automated decision making and profiling: You have the right not to be subject to a decision based solely on automated processing, including profiling. We do not take any decision about you based on automated decision making and profiling, unless we take your explicit consent for the same .</h2>
                           <h2><span>6.9</span>If you wish to make a request for invoking any of your above-mentioned rights, you may contact us at support@instcare24x7.com, we may take all reasonable efforts to incorporate the changes within a reasonable time in accordance with applicable law.</h2> */}
                              </div>
                              <div class="terms_list">
                                 <h1>7. Payment for usage of Services</h1>
                                 <h2><span>7.1</span>You understand that access and usage of certain features of our Website is free of cost. However, in the event, you wish to avail all or any of our services such as, if you choose a doctor/surgeon expert in the desired practice and book an appointment, you shall be required to pay for such consultation with such doctor/surgeon.</h2>
                                 <h2><span>7.2</span>While opting any of the payment method made available on our Website, we will not be responsible or assume any liability whatsoever in respect of any loss or damage arising directly or indirectly to you due to:</h2>
                                 <ul>
                                    <li><span>(a)</span>Lack of authorization for any transaction; or</li>
                                    <li><span>(b)</span>Exceeding the pre-set limit mutually agreed by you and between your bank; or</li>
                                    <li><span>(c)</span>Any payment issues arising out of the transaction; or</li>
                                    <li><span>(d)</span>Decline of transaction for any other reasons.</li>

                                 </ul>
                                 <h2><span>7.3</span>We provide various payment methods through which you can make the payment for the Services availed. You have options to choose any one of the following methods of payment: credit card, debit card, net banking, UPI (Unified Payment Interface), or online mobile wallets via our online payment service providers.</h2>
                                 <h2><span>7.4</span>Please note that every patient or user requirements are unique and different and we offer you a customized package that help you in the entire surgical process and make it as simple and affordable as possible. However, the pricing of Services and customized package will depend upon the treatment or procedure you choose to undergo, type of post-operative care provider you choose, the category of hospital room you opt, the choice of food/beverages you chose, your pharmaceutical needs based on doctors/surgeons advice and any other special facility you request us to provide at any stage of your treatment.</h2>
                                 <h2><span>7.5</span>Please note that the customized package shall not include the following costs:</h2>
                                 <ul>
                                    <li><span>(a)</span>Any additional charges occurred due to any complications during any surgery/treatment;</li>
                                    <li><span>(b)</span>Any additional test or diagnosis required due to special nature of treatment or procedure;</li>
                                    <li><span>(c)</span>Any transportation cost; and</li>
                                    <li><span>(d)</span>Pharmacy costs for medications not prescribed by the doctor or in cases where medication is required beyond the prescribed period by the doctor.</li>

                                 </ul>

                              </div>
                              <div class="terms_list">
                                 <h1>8. Refund and Cancellation </h1>
                                 <h2><span>8.1</span>You may cancel any consultations booked by you through our Website with doctor/surgeon at any point of time, prior to the scheduled date and time of consultation. You are entitled for refund of any payment made by you, towards consultation, only if you cancel the consultation prior to the date and time of consultation.</h2>
                                 <h2><span>8.2</span>You are not entitled to claim any refund in case you have neither cancelled the scheduled consultation with doctor/surgeon not attended the consultation.</h2>
                                 <h2><span>8.3</span>Refund will be initiated to the same account/card used by you to make the payment towards consultation and will take 5 (five) to 15 (fifteen) working days to settle</h2>
                                 {/* <h2><span>8.4</span>We have implemented managerial and operational policies as well as adopted business practices to prevent any misuse, unauthorised access, modification, disclosure or destruction of your information.</h2> */}

                              </div>
                              <div class="terms_list">
                                 <h1>9. Additional Services and Terms</h1>
                                 <h2><span>9.1</span>Some of the Services we offer to you may be subject to additional terms and conditions, such additional terms and conditions will be provided to you before such Services are provided.</h2>
                                 {/* <h2><span>9.2</span> If you continue the use of our Website or our Services after the effective date of a modified Privacy Policy, it will indicate your consent to any amended or modified terms. It is hereby clarified that the Privacy Policy shall also include any amendments or modifications thereof.</h2> */}
                              </div>
                              <div class="terms_list">
                                 <h1>10. Disclaimer of Warranties and Limitation of Liability</h1>
                                 <h2><span>10.1</span>All information and Services included on or otherwise made available to you through this Website are provided by us on an “as is” basis, either expressed or implied, we specifically disclaim warranties of any kind to the extent allowed by the applicable law. You expressly agree that your use of this Website and/or our Services is at your sole risk.</h2>
                                 <h2><span>10.2</span>We assume no responsibility for any damages or viruses that may infect your computer equipment or other property on account of your access to, use of, or browsing this Website.</h2>
                                 <h2><span>10.3</span>. We have exerted reasonable efforts to ensure that all information published on the Website is accurate at the time of posting; however, there may be errors in such information for which we shall have no liability. We reserve the right to remove, modify, amend or alter any of the information contained on the Website at our sole discretion.</h2>
                                 <h2><span>10.4</span>We cannot guarantee the adequacy or completeness of the Website content. We do not warrant or endorse the effectiveness, quality or safety of the Services available on our Website.</h2>
                                 <h2><span>10.5</span>We may let you view our information and communicate with us through social media services including but not limited to Facebook and YouTube. We explicitly disclaim any responsibility for the terms of use and privacy policies that govern these third-party websites, which are in no way associated with us.</h2>
                                 <h2><span>10.6</span>We reserve the right to modify or withdraw any part of the Website, any of its content and/or any Services at any time without any notice.</h2>
                                 <h2><span>10.7</span>We disclaim responsibility for any harm to persons resulting from any instructions or Services rendered through the Website or otherwise.</h2>
                                 <h2><span>10.8</span> The lawful guardian of an individual below the age of 18 (eighteen) years shall be responsible for any actions of such individual not authorised to access and/or use the Website and/or Services.</h2>
                                 <h2><span>10.9</span>We shall not be responsible or liable in any manner to you for any losses, damages, injuries or expenses incurred by you as a result of any disclosures made by us, where you have consented to the making of such disclosures by us. You shall not hold us responsible or liable in any way for any disclosures by us inasmuch as it is in compliance with applicable laws.</h2>
                                 <h2><span>10.10</span>We respect your right to choose a healthcare provider to meet your needs. Information contained in the individual profiles of doctors, surgeons, physicians, dentists and other healthcare professionals on the Website was provided by those individuals. We have taken reasonable care and steps to ensure that any information regarding each doctor/surgeon and other healthcare providers is correct, which includes the verification of such healthcare provider’s education, qualifications and experience. However, we do not make any representation or warranty as to the information about the health professional. We encourage you to verify your healthcare professional’s qualifications, experience and accomplishments. We assume no responsibility or liability for any treatment or other services rendered by any doctor/surgeon or other healthcare providers, or for any malpractice claims and other claims that may arise directly or indirectly from any such advice, treatment or other services.</h2>
                                 <h2><span>10.11</span>We respect your right to choose the hospital that meets your needs. Information contained about the consulting hospitals of the doctors/surgeons and other healthcare professionals on the Website, including but not limited to the overview about the hospital, the facilities it provides and its location, was provided by those hospitals. We have taken reasonable care and steps to ensure that any information regarding each doctor/surgeon and other healthcare providers is correct, however, we do not make any representation or warranty as to the information about the hospitals. We encourage you to verify your hospital’s specifications, facilities and location.</h2>
                                 <h2><span>10.12</span>We do not refer, recommend, or endorse any medical practice, doctor/surgeon, other healthcare professional, hospital, clinical lab, diagnostic lab, post-operative care provider or pharmacy available on our Website. Any statements, programs, opinions, or other information that may be provided to you by a doctor/surgeon or other healthcare professional, hospital, clinical lab, diagnostic lab, post-operative care provider or pharmacy are solely attributable to that doctor/surgeon or other healthcare professional, hospital, clinical lab, diagnostic lab, post-operative care provider or pharmacy and not to us. Reliance on any information provided through Services and/or on our Website is solely at your own risk. We make no representations or warranties as to the conduct, ability or the efficacy, accuracy, completeness, timeliness or relevance of the information provided by any doctor/surgeon or other healthcare professional, hospital, clinical lab, diagnostic lab, post-operative care provider or pharmacy and/or the services provided by the said doctor/surgeon or other healthcare professional, hospital, clinical lab, diagnostic lab, post-operative care provider or pharmacy featured through the Services and/or on our Website.</h2>
                                 <h2><span>10.13</span>Doctors/surgeons or other healthcare professionals, hospitals, diagnostic labs, clinical labs, post-operative care providers and pharmacies are responsible for complying with applicable laws and with regulatory and applicable requirements for malpractice and liability. All interactions between you and doctors/surgeons and other healthcare professionals are at your own risk. By using the Services or Website, you agree not to hold us liable in any way for any malpractice or substandard treatment the doctor/surgeon or other health care professional, hospital, or post-operative care provider may render to you or any child or any person for whom you are responsible. In no event shall we be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you, any minor or any person for whom you are responsible, or anyone else in connection with your use of the Services and/or our Website, including without limitation, bodily injury, emotional distress, and/or any other damages resulting from your use of any information, program or suggestion provided to you by a doctor or surgeon or medical practice or health care professional, or communications or meetings between or among you, any minor or any person for whom you are responsible, and any doctors, surgeons, physicians, members or any other persons you meet through the Services or our Website. You agree to take reasonable precautions in all interactions with doctors/surgeons or other healthcare professionals.</h2>
                                 <h2><span>10.14</span>We do not practice medicine or any other licensed profession, and do not interfere with the practice of medicine or any other licensed profession by doctors/surgeons or other healthcare professionals, each of whom is responsible for his or her services and compliance with the requirements applicable to his or her profession and license. Neither we nor any third parties who promote the Services or provide you with a link to the Service shall be liable for any professional advice you obtain from any doctor/surgeon or other healthcare professional via the Services.</h2>
                                 <h2><span>10.15</span>Further, individual contracts exists between the doctor/surgeon or other healthcare providers and you, between the hospital and you, between the post-operative care provider and you, between the diagnostic lab or clinical lab and you, and pharmacy and you, and as such any breach of any of these contracts and thus, any claim arising from such breach is the subject matter of the doctor/surgeon or other healthcare provider or hospital or post-operative care provider or diagnostic/clinical lab or pharmacy and you alone, and we are in no way a party to such breach or involved in any suit including but not limited to any claim or negligence or damages arising from the same breach.</h2>
                                 <h2><span>10.16</span>We provide Services through the Website, and otherwise, that facilitates you to avail diagnostic test facilities during the medical procedure/treatment offered by third party labs including clinical lab and diagnostic lab. We are not and shall not be responsible for any sample collected, tests conducted, and reports generated by the third party labs and we only provide facilitation services to you through the Website and/or Services. We shall not be responsible for any mishap or missed services caused due to third party labs including clinical lab and diagnostic lab. We are not responsible for, any error in the sample collection and/or reports generated by the third party labs including clinical labs or diagnostic labs. In no event shall we be liable for failure on the part of the third party labs to provide agreed services or to make provisions for the carrying out the Services.</h2>
                                 <h2><span>10.17</span>All Services made available by the third party pharmacies are third party products. We have no control over such third party pharmaceutical goods and services. The authenticity and genuineness of the pharmaceutical goods and services made available by the third party pharmacies through the Website or Services shall be the sole responsibility of the third party pharmacies. You understand and agree that we shall have no liability with respect to the authenticity of the pharmaceutical goods and services being facilitated through the Website or Services.</h2>
                                 <h2><span>10.18</span>You agree and acknowledge that we will not be liable for any claims in relation to the sale of medicines by the third party pharmacies to you, including but not limited to any delay in the delivery of pharmaceutical goods and services, delivery of the wrong pharmaceutical goods and services or incorrect quantities of the same, any actions of the pharmacist (including but not limited to its personnel), and fitness or viability of the pharmaceutical goods and services. We are not associated with any manufacturer of pharmaceutical goods or other products and services provided to you through the use of Services. We do not warrant that the Website, or its content, or the Services will meet your pharmacy requirements.</h2>

                              </div>
                              <div class="terms_list">
                                 <h1>11. Use of information on the Website/ social media platforms</h1>
                                 <h2><span>11.1</span>Any information uploaded on the Website, transmitted via e-mail, phone, social media platforms (including but not limited to Facebook or YouTube), mobile messages (including but not limited to short message service and WhatsApp), should not be construed as medical advice or treatment for specific medical conditions and we encourage you to consult and act upon all your medical issues as per the advice of a medical practitioner of your choice</h2>
                                 <h2><span>11.2</span>We do not endorse any views, opinions and/or suggestions that may be uploaded on the Website transmitted via e-mail, phone, social media platforms (including but not limited to Facebook or YouTube), mobile messages (including but not limited to short message service and WhatsApp), or other information provided through our Services. Your submission of questions, reviews or testimonials about our Services does not in any manner constitute a professional relationship between us.</h2>


                              </div>
                              <div class="terms_list">
                                 <h1>12. Amendment or modification of Terms of Use</h1>
                                 <h2><span>12.1</span>We reserve the right to amend or modify any of the Terms of Use at any time as we deem fit. All changes to this Terms of Use will be effective immediately upon being posted on our Website. However, in order to prevent any confusion an effective date will be provided. In case of any material changes to this Terms of Use, we will notify you by e-mail and clearly post the changes on our Website. Unless we expressly notify otherwise, these Terms of Use shall incorporate and supersede any other Terms of Use associated with the Services mentioned under Para 3.</h2>
                                 <h2><span>12.2</span>It is suggested that users must on a regular basis review these Terms of Use, our Privacy Policy, and any other polices that may be posted on this Website, each of which are and will be accessible through our Website.</h2>
                              </div>
                              <div class="terms_list">
                                 <h1>13. Locations of Service</h1>
                                 <h2><span>13.1</span>Currently, the Services are available in 40(forty) locations namely specific cities. We shall update the Website and/or Terms of Use in case of any addition or deletion of our operating locations.</h2>

                              </div>
                              <div class="terms_list">
                                 <h1>14. Termination</h1>
                                 <h2><span>14.1</span>This agreement shall come into effect when you visit our Website and/or avail any of our Services, and shall remain valid until your usage of the Website and/or receipt of any of our Services, or until termination of (a) Services, (b) access to the Website, or (c) Terms of Use in accordance with clause 14.2 hereunder, by us</h2>
                                 <h2><span>14.2</span>We reserve the right to terminate this agreement without any reason, if:</h2>
                                 <ul>
                                    <li><span>(a)</span>The user breaches any provisions of this Terms of Use</li>
                                    <li><span>(b)</span>If we discover that the user is under the age of 18 years; and/or</li>
                                    <li><span>(c)</span>If the user of our Service has failed to provide us accurate information.</li>

                                 </ul>
                                 <h2><span>14.3</span>Pursuant to the termination, user’s right to access the Website and/or avail Services would immediately cease to exist.</h2>

                              </div>
                              <div class="terms_list">
                                 <h1>15. Intellectual property rights</h1>
                                 <h2><span>15.1</span>All the intellectual property used on the Website shall remain our exclusive property. The materials on the Website or otherwise may not be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording or other means.</h2>

                              </div>
                              <div class="terms_list">
                                 <h1>16. Severability</h1>
                                 <h2><span>16.1</span>If whole or any part of any provision of this Terms of Use may prove to be illegal or unenforceable in any jurisdiction and such part or any such part of any provision is ineffective as to that jurisdiction to the extent of the prohibition or unenforceability, it shall not affect the validity or enforceability of the remainder of this agreement or enforceability of that provision in any other jurisdiction.</h2>


                              </div>
                              <div class="terms_list">
                                 <h1>17. Governing Law and Dispute Resolution</h1>
                                 <h2><span>17.1</span>This Terms of Use and any matter relating to this Terms of Use shall be exclusively governed by and construed in accordance with the laws of India and the courts of Bengaluru shall have exclusive jurisdiction.</h2>
                                 <h2><span>17.2</span>In case of any dispute involving but not limited to rights conferred, compensation, refunds, and other claims, then the parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved in the court of Indore (MP).</h2>


                              </div>
                              <div class="terms_list">
                                 <h1>18. Indemnity</h1>
                                 <h2><span>18.1</span>User agrees to indemnify and hold harmless We Care Health Research and Consultancy Private Limited, its, officers, directors, employees, consultants, licensors, agents, and representatives from any and all claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from his/her/its access to or use of Website and/or Services and in violation of this Terms of Use.</h2>


                              </div>
                              <div class="terms_list">
                                 <h1>19. Survival</h1>
                                 <h2><span>19.1</span>Even after termination, certain obligations mentioned under Disclaimer of Warranties and Limitation of Liability, Indemnity, Intellectual Property and Dispute Resolution will continue and survive termination.</h2>


                              </div>
                              <div class="terms_list">
                                 <h1>20. Entire Agreement</h1>
                                 <h2><span>20.1</span>This Terms of Use, together with the Privacy Policy, payment and refund policy appearing in this Terms of Use, and any other policy posted on the Website, represent the complete agreement between you and We Care Health Research and Consulting Private Limited concerning the subject matter hereof, and it replaces all prior oral or written communications (if any) concerning such subject matter.</h2>


                              </div>
                              <div class="terms_list">
                                 <h1>21. Details of Grievance Officer</h1>
                                 <h2><span>21.1</span>If you have any questions or concerns about this Privacy Policy, you may contact us at the following:</h2>
                                 <h2><strong>Grievance Officer: Prasanth Gireesh</strong></h2>
                                 <h2><strong>Address – Road no 432, Poonamale High Road, Killpauk, Chennai, Tamilnadu ( 600010 )</strong> </h2>
                                 <h2><span>21.2</span>Any grievances, queries, claims, complaints, with regard to this Terms of Use shall be answered or resolved within a period of 30 (thirty) days from the date of such receipt.</h2>

                              </div>
                              <div class="terms_list">
                                 <h1>Cancellation & Refund Policy </h1>
                                 <h2>Cancellation of consult later appointment - should be allowed till 2hours prior to appointment</h2>
                                 <ul>
                                    <li><span>(1a)</span> before 2hrs = full amount refund</li>
                                    <li><span>(1b)</span> within 2hrs of appointment, no option to cancel</li>
                                 </ul>
                                 <h2>There will not be any cancellation option for consult now appointments</h2>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            {/* <PatientFooter /> */}
         </main>
      );
   }
}
