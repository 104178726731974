import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import RetailerCss from "../../public/css/retailer/retailer_style.css";
import dateformatter from "../helpers/dateformatter";

import Dialog from "react-bootstrap-dialog";

const moment = require("moment");

export default class DiagnosisHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      activeNotificationsCount: 0,
      notifyid: "",
    };
  }
  //show logout button
  showLogout = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  };
  //logout from the retailer
  logout = () => {
    var retrievedObject = localStorage.removeItem("retuserObj");
    var retrievedObjectToken = localStorage.removeItem("token");
    window.location.href = "./login";
  };
  // To get detais after first render

  componentDidMount = () => {
    var retrievedObject = localStorage.getItem("retuserObj");
    if (retrievedObject == null) {
      window.location.href = "./login";
    }
    let userData = JSON.parse(retrievedObject);

    this.setState({
      retailerId: userData.retailer_id,
      storeName: userData.name,
      date: moment().format("dddd, Do MMMM, YYYY hh:mm A"),
    });
    if (userData.retailer_id != "") {
      this.validateToken();
    }
  };
  //  for logout
  logout = () => {
    var retrievedObject = localStorage.getItem("retuserObj");
    if (retrievedObject == null) {
      window.location.href = "./login";
    }
    let userData = JSON.parse(retrievedObject);

    let retailerId = userData.userID;
    Httpconfig.httptokenget(Constant.siteurl + "api/Users/logout/" + retailerId)
      .then((response) => {
        localStorage.removeItem("retuserObj");
        localStorage.removeItem("retailerToken");

        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  for validated token
  validateToken = () => {
    var retrievedObject = localStorage.getItem("retuserObj");

    if (retrievedObject == null) {
      window.location.href = "./login";
    }
    let userData = JSON.parse(retrievedObject);
    let userId = userData.userID;
    if (localStorage.getItem("retailerToken")) {
      let token = JSON.parse(localStorage.getItem("retailerToken"));

      Httpconfig.httptokenpost(Constant.siteurl + "api/Users/checkToken", {
        userid: userId,
        token: token,
      })
        .then((response) => {
          if (response.data.status == "200" && response.data.error === true) {
            localStorage.removeItem("retuserObj");
            localStorage.removeItem("retailerToken");
            setTimeout(() => window.location.reload(), 1000);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Your Session has timed out.Please Relogin");
      localStorage.removeItem("retuserObj");
      localStorage.removeItem("retailerToken");
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  render() {
    return (
      <main id="main">
        <header id="header" class="ret_header">
          <div class="container-fluid">
            <div class="logo float-left logo_sec">
              <a href="/diagnostics/Diagnosisdashboard" class="scrollto">
                <img
                  src={Constant.imgurl + "/images/patient/img/main-logo.png"}
                  alt=""
                  class="img-fluid"
                />
              </a>
              <p class="logo_txt">{this.state.storeName}</p>
            </div>
            <nav class="main-nav float-right d-lg-block">
              <ul class="main_ul">
                <li>{this.state.date} </li>
                <li>
                  <div class="pro_dropdown">
                    <div class="dropdown">
                      <button onClick={this.showLogout} class="dropbtn">
                        <img
                          class="user_img"
                          src={
                            Constant.imgurl +
                            "/images/retailer/profile_avaatar.svg"
                          }
                        />{" "}
                        {this.state.storeName}{" "}
                        <img
                          class="drop_img"
                          src={
                            Constant.imgurl +
                            "/images/retailer/arrow-dropdown.svg"
                          }
                        />
                      </button>
                      <div id="myDropdown" class="dropdown-content">
                        <a href="#" onClick={this.logout}>
                          Logout
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <ToastContainer />
      </main>
    );
  }
}
