import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Patientheader";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientMenu from "../patient/Patientmenu";
import PatientSideMenu from "../patient/Patientsidemenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import FileBase64 from "react-file-base64";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

const addressDetailsView = [];

export default class Immunizationchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { country_name: "India", stateName: "" },
      indianStates: [],
      indianCities: [],
      errors: {},
      add_address_form: "add_address_form collapse-hide",
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }

    var retrievedObject = ls.get("userObjEnc");
    let userObj = retrievedObject;
    if (userObj == "") {
      window.location.href = "./patientLogin";
    }
    if (userObj != "") {
      if (this.props.match.params.id) {
        this.editAddressBox("", this.props.match.params.id);
      }

      this.setState({
        name: userObj.name,
        user_mobile: userObj.mobile_number,
        email_id: userObj.email,
        token: userObj.accessToken,
        patientId: userObj.patient_id,
        profile_image: "",
      });
      this.forceUpdate();
    }

    this.getaddressInfo(userObj.patient_id);
    this.getCompleteStates();
    this.getProfileDetails(userObj.patient_id);
    const interval = setInterval(() => {
      this.getaddressInfo(userObj.patient_id);
    }, 10000);
  };

  // For getting the profile details
  getProfileDetails = (patientId) => {
    if (patientId != "") {
      let url_path = "api/Patients/" + patientId;
      let patientDob = "";
      Httpconfig.httptokenget(Constant.siteurl + url_path, {}).then(
        (response) => {
          let patientDob = "";
          if (response.data.status == 200 && response.data.error == false) {
            let PatientGender = response.data.data[0].gender;
            let patientProfileImage = "";
            if (response.data.data[0].profile_pic != null) {
              patientProfileImage =
                Constant.imgurl + response.data.data[0].profile_pic;
            }
            this.setState({
              patientProfileImage: patientProfileImage,
              PatientGender: PatientGender,
            });
          }
        }
      );
    }
  };

  // For removing the address
  removeAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let clickValue = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    var isConfirm = window.confirm("Are you sure to delete " + clickName + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/OM/deliveryAddress/" + clickValue
      )
        .then((response) => {
          toast.success("Successfully Deleted Manage Address");
          this.getaddressInfo(this.state.patientId);
        })
        .catch((error) => { });
    }
  };

  // For getting default address
  defaultAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let rowId = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    let patientId = this.state.patientId;
    var isConfirm = window.confirm(
      "Are you sure to set Default address " + clickName + "?"
    );
    if (isConfirm) {
      Httpconfig.httptokenput(
        Constant.siteurl + "api/OM/deliveryAddress/" + patientId + "/" + rowId
      )
        .then((response) => {
          toast.success("Successfully updated the Default Address ");
          this.getaddressInfo(this.state.patientId);
        })
        .catch((error) => { });
    }
  };

  // For updating the address
  editAddressBox = (e, id) => {
    let clickValue = "";
    if (!e) {
      clickValue = id;
    } else {
      const anchor = e.target.closest("a");
      clickValue = anchor.getAttribute("value");
      let clickName = anchor.getAttribute("name");
    }

    this.state.add_address_form = "add_address_form collapse-show";
    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/deliveryAddress/" + clickValue
    )
      .then((response) => {
        this.getCitiesbasedonStateId(response.data.data[0].state);
        this.setState({
          fields: {
            patient_id: response.data.data[0].patient_id,
            username: response.data.data[0].name,
            mobile_no: response.data.data[0].mobile_no,
            location: response.data.data[0].location,
            address: response.data.data[0].address,
            landmark: response.data.data[0].landmark,
            latitude: response.data.data[0].latitude,
            longitude: response.data.data[0].longitude,
            city: response.data.data[0].city,
            stateName: response.data.data[0].state,
            country_name: response.data.data[0].country,
            zipcode: response.data.data[0].zipcode,
            address_type: response.data.data[0].address_type,
            isEdit: true,
            rowId: response.data.data[0].id,
          },
        });
      })
      .catch((error) => { });
  };

  // To get all the Patient Addresses
  getaddressInfo(patientId) {
    Httpconfig.httptokenget(
      Constant.siteurl +
      "api/OM/deliveryAddress/findAllDeliveryAddress/" +
      patientId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          const addressDetailsView = response.data.data.map(
            (addressDetails, num) => {
              return (
                <div className="add_address_box">
                  <div className="add_address_det">
                    <h2>
                      {addressDetails.name}{" "}
                      <p>({addressDetails.address_type})</p>
                      <span>+91{addressDetails.mobile_no}</span>
                      {addressDetails.default_status == "1" ? (
                        <p class="btn_default">Default</p>
                      ) : (
                        ""
                      )}
                    </h2>

                    <h6>
                      {addressDetails.address}
                      {", "}
                      {addressDetails.landmark}
                      {", "}
                      {addressDetails.city}
                      {", "}
                      {addressDetails.country}
                      {", "}
                      {addressDetails.zipcode}
                    </h6>
                  </div>
                  <div className="add_address_bar">
                    <li className="nav-item dropdown">
                      <a href="#" id="navbardrop" data-toggle="dropdown">
                        <img src="../images/patient/img/Ordermedicine/orangedotmenu.svg" />
                      </a>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          name={addressDetails.name}
                          value={addressDetails.id}
                          href="javascript:void(0);"
                          onClick={this.editAddressBox.bind(this)}
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          name={addressDetails.name}
                          value={addressDetails.id}
                          href="javascript:void(0);"
                          onClick={this.defaultAddressBox.bind(this)}
                        >
                          Set as Default
                        </a>
                        <a
                          className="dropdown-item"
                          name={addressDetails.name}
                          value={addressDetails.id}
                          href="javascript:void(0);"
                          onClick={this.removeAddressBox.bind(this)}
                        >
                          Delete
                        </a>
                      </div>
                    </li>
                  </div>
                </div>
              );
            }
          );
          this.setState({
            addressDetailsView: addressDetailsView,
          });
        } else if (
          response.data.status == "401" &&
          response.data.token == false
        ) {
          Httpconfig.httptokenget(
            Constant.siteurl +
            "api/Users/logout/" +
            localStorage.getItem("userID")
          );
          window.location.href = "./patientLogin";
        }
      })
      .catch((error) => { });
  }

  // For getting the files
  getFiles(files) {
    this.setState({ files: files });
    let patientId = this.state.patientId;
    let patientProfileImage = this.state.files[0].base64;
    this.state.patientProfileImage = patientProfileImage;
    this.forceUpdate();
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Users/uploadimages/" + patientId,
      {
        profile_pic: this.state.files[0].base64,
      }
    )
      .then((response) => {
        toast.success("👌 Profile Image Updated Successfully", {
          position: "bottom-center",
        });
      })
      .catch((error) => {
        this.props.history.push("/patienthealthprofile");
        toast.error(error);
      });
  }

  //  For submitting the form
  checkSubmit(event) {
    event.preventDefault();
    let isEditEnabled = this.state.fields.isEdit;
    if (this.handleValidation()) {
      if (isEditEnabled) {
        this.updateAddressModule(event);
      } else {
        this.createAddressModule(event);
      }
    }
  }

  // creates address Module
  createAddressModule = async (event) => {
    event.preventDefault();
    console.log("countryName==321", this.state.fields['country_name']);
    const { fields, errors } = this.state;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
    let enteredAddress = $('textarea[name="address"]').val();
    await this.getLatLangfromAddress(address);

    let address_latitude = localStorage.getItem("address_latitude");
    let address_longitude = localStorage.getItem("address_longitude");

    Httpconfig.httptokenpost(Constant.siteurl + "api/DIAGNOSIS/deliveryAddress", {
      patient_id: this.state.patientId,
      name: fields["username"],
      mobile_no: fields["mobile_no"],
      location: fields["location"] ? fields["location"] : "asdf",
      address: fields["address"],
      city: fields["city"],
      state: fields["stateName"],
      country: fields["country_name"],
      zipcode: fields["zipcode"],
      landmark: fields["landmark"],
      latitude: address_latitude ? address_latitude : "17.438976",
      longitude: address_longitude ? address_longitude : "78.38905559999999",
      address_type: fields["address_type"],
    })
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Address Saved Successfully");
          this.getaddressInfo(this.state.patientId);
          this.setState({
            add_address_form: "add_address_form collapse-hide",
          });
          window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => { });
  };

  // updates controller
  updateAddressModule = async (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    let rowId = this.state.fields.rowId;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
    await this.getLatLangfromAddress(address);
    Httpconfig.httptokenput(
      Constant.siteurl + "api/DIAGNOSIS/deliveryAddress/" + rowId,
      {
        patient_id: this.state.patientId,
        name: fields["username"],
        mobile_no: fields["mobile_no"],
        location: fields["location"] ? fields["location"] : "asdf",
        address: fields["address"],
        landmark: fields["landmark"],
        city: fields["city"],
        state: fields["stateName"],
        country: fields["country_name"],
        zipcode: fields["zipcode"],
        latitude: fields["latitude"] ? fields["latitude"] : "1",
        longitude: fields["longitude"] ? fields["longitude"] : "2",
        address_type: fields["address_type"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Successfully Updated Manage Address");
          this.setState({
            add_address_form: "add_address_form collapse-hide",
          });
          window.location.reload();

          this.getaddressInfo(this.state.patientId);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For getting the latlong from address
  getLatLangfromAddress = (enteredAddress) => {
    return new Promise((resolve, reject) => {
      var latitude = "";
      var longitude = "";
      var url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        enteredAddress +
        "&key=" +
        Constant.googleAPiKey;
      $.getJSON(url, function (data, textStatus) {
        var responseStatus = data.status;
        if (responseStatus === "OK") {
          latitude = data.results[0].geometry.location.lat;
          longitude = data.results[0].geometry.location.lng;
          localStorage.setItem("address_latitude", latitude);
          localStorage.setItem("address_longitude", longitude);
          resolve();
        }
      });

      let address_latitude = localStorage.getItem("address_latitude");
      let address_longitude = localStorage.getItem("address_longitude");
      if (address_latitude && address_longitude) {
        this.setState({
          fields: {
            latitude: address_latitude,
            longitude: address_longitude,
          },
        });
        setTimeout(function () {
          localStorage.removeItem("address_latitude");
          localStorage.removeItem("address_longitude");
        }, 2000);
      }
    });
  };

  // For getting the state information

  getCompleteStates() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Country/states/" + Constant.countryID
    )
      .then((response) => {
        this.setState({
          indianStates: response.data.data,
        });
      })
      .catch((error) => { });
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;

    if (field == "stateName") {
      this.getCitiesbasedonStateId(event.target.value);
    }
    this.setState({ fields });
  };

  // For getting the state id based on citi
  getCitiesbasedonStateId(stateId) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country/cities/" + stateId)
      .then((response) => {
        this.setState({
          indianCities: response.data.data,
        });
      })
      .catch((error) => { });
  }
  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Name cannot be empty";
    }
    if (!fields["mobile_no"]) {
      formIsValid = false;
      errors["mobile_no"] = "Mobile No cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["address_type"]) {
      formIsValid = false;
      errors["address_type"] = "Address type cannot be empty";
    }
    if (!fields["zipcode"]) {
      formIsValid = false;
      errors["zipcode"] = "Pincode cannot be empty";
    }
    if (!fields["stateName"]) {
      formIsValid = false;
      errors["statename"] = "State name cannot be empty";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  // For adding the new address
  addNewAddress = () => {
    this.state.add_address_form = "add_address_form collapse-show";
    this.setState({
      fields: {
        username: "",
        mobile_no: "",
        address: "",
        city: "",
        stateName: "",
        zipcode: "",
        address_type: "",
        isEdit: false,
        country_name: "India",
      },
    });

  };

  render() {
    return (
      <main id="main_ord_nav">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="order_profile">
            <div className="appoint_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <PatientSideMenu />
                  </div>

                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <section id="immunization_chart">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="immunization_sec">
                              <div className="immunization_head">
                                <h2>Immunization Chart</h2>
                              </div>      
                            </div>
                            <div className="immuni_chart_select">
                            <form>
                                <div class="form-group">
                                <input type="radio"  name="fav_language" value="women" checked/>
                                <label for="women">Women</label>
                                </div>
                                <div class="form-group">
                                <input type="radio"  name="fav_language" value="infants"/>
                                <label for="Infants">Infants</label>
                                </div>
                                <div class="form-group">
                                <input type="radio"  name="fav_language" value="children"/>
                                <label for="children">Children</label>
                                </div>
                            </form>
                            </div>
                          </div>
                        </div>

               <div className="row">
                <div className="col-md-12">

                    {/********For Pregancy******/}
                    <div className="immuni_pregancy">
                        <h2>FOR PREGNENT WOMEN</h2>
                    <table class="table table-bordered immuni_table">
               <thead class="thead-light">
               <tr>
               <th scope="col">Vaccine</th>
                <th scope="col">When to Give</th>
                <th scope="col">Dose</th>
                <th scope="col">Diluent</th>
                <th scope="col">Route</th>
                <th scope="col">Site</th>
                <th scope="col">Status</th>
              </tr>
             </thead>
             <tbody>
             <tr>
             <th scope="row" className="td_title">TT-1</th>
             <td>Early in Pregancy</td>
             <td>0.5 ml</td>
             <td>NO</td>
             <td>Intramuscular</td>
             <td>Upper Arm</td>
             <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
            </tr>
             <tr>
            <th scope="row" className="td_title">TT-2 #</th>
            <td>4 Weeks after TT-1</td>
             <td>0.5 ml</td>
            <td>NO</td>
            <td>Intramuscular</td>
            <td>Upper Arm</td>
            <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
            <tr>
           <th scope="row" className="td_title">TT-Booster #</th>
           <td>If received TT doses in a pregancy with in last 3 years</td>
           <td>0.5 ml</td>
           <td>NO</td>
           <td>Intramuscular</td>
           <td>Upper Arm</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
       </tbody>
</table>
   
                    </div>

           {/********For Infants******/}

                    <div className="immuni_infants">
                    <h2>FOR INFANTS</h2>
                    <table class="table table-bordered immuni_table">
               <thead class="thead-light">
               <tr>
               <th scope="col">Vaccine</th>
                <th scope="col">When to Give</th>
                <th scope="col">Max. Age</th>
                <th scope="col">Dose</th>
                <th scope="col">Diluent</th>
                <th scope="col">Route</th>
                <th scope="col">Site</th>
                <th scope="col">Status</th>
              </tr>
             </thead>
             <tbody>
             <tr>
             <th scope="row" className="td_title">BCG ##</th>
             <td>At birth as early as possible</td>
             <td>Till one year of age</td>
             <td>0.1 ml(0.05 ml until 1 month age)</td>
             <td>Sodium Chloride</td>
             <td>Intra-dermal</td>
             <td>Left Upper Arm</td>
             <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
            </tr>
             <tr>
            <th scope="row" className="td_title">Hepatitis B Birth Dose ###</th>
            <td>At birth as early as possible</td>
             <td>within 24 Hours</td>
            <td>0.5 ml</td>
            <td>No</td>
            <td>Intramuscular</td>
            <td>Anterolateral side of mid-thigh LEFT</td>
            <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
            <tr>
           <th scope="row" className="td_title">OPV-0<sup>*</sup>#</th>
           <td>At birth as early as possible</td>
           <td>Within the first 15 days</td>
           <td>2 drops</td>
           <td>No</td>
           <td>Oral</td>
           <td>-</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">OPV 1,2 & 3</th>
           <td>At 6, 10, & 14 weeks</td>
           <td>Till 5 years of age</td>
           <td>2 drops</td>
           <td>No</td>
           <td>Oral</td>
           <td>-</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Rota Virus Vaccine<sup>*</sup></th>
           <td>At 6, 10, & 14 weeks</td>
           <td>Till 1 year of age</td>
           <td>5 drops</td>
           <td>No</td>
           <td>Oral</td>
           <td>-</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">IPV(Inactivated Polio Vaccine)</th>
           <td>At 6 & 14 weeks</td>
           <td>Up to 1 yr of age</td>
           <td>0.1 ml</td>
           <td>No</td>
           <td>Intradermal</td>
           <td>Right Upper Arm</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Pentavalent<sup>**</sup>1,2 & 3</th>
           <td>At 6, 10, & 14 weeks</td>
           <td>Till one year of age</td>
           <td>0.5 ml</td>
           <td>No</td>
           <td>Intramuscular</td>
           <td>Anterolateral side of mid-thigh LEFT</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Measies-1 Dose</th>
           <td>9-12 completed months</td>
           <td>Given till 5 years of age</td>
           <td>0.5 ml</td>
           <td>Sterile Water</td>
           <td>Subcutaneous</td>
           <td>Right Upper Arm</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Japanese Encephalities 1st dose</th>
           <td>9-12 completed months</td>
           <td>Till 15 yrs</td>
           <td>0.5 ml</td>
           <td>Phosphate Buffer</td>
           <td>Subcutaneous</td>
           <td>Left Upper Arm</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Vitamin A (1st dose)</th>
           <td>At 9 completed months with measles</td>
           <td>Till 5 years of age</td>
           <td>1 ml(1 lakh IU)</td>
           <td>NO</td>
           <td>Oral</td>
           <td>-</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
       </tbody>
</table>
    
                    </div>

               {/********For Children******/}
                    <div className="immuni_children">
                    <h2>FOR CHILDREN</h2>
                    <table class="table table-bordered immuni_table">
               <thead class="thead-light">
               <tr>
               <th scope="col">Vaccine</th>
                <th scope="col">When to Give</th>
                <th scope="col">Max. Age</th>
                <th scope="col">Dose</th>
                <th scope="col">Diluent</th>
                <th scope="col">Route</th>
                <th scope="col">Site</th>
                <th scope="col">Status</th>
              </tr>
             </thead>
             <tbody>
             <tr>
             <th scope="row" className="td_title">DPT Booster-1</th>
             <td>16-24 months</td>
             <td>7 years</td>
             <td>0.5 ml</td>
             <td>No</td>
             <td>Intramuscular</td>
             <td>Anterolateral side of mid-thigh LEFT</td>
             <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
            </tr>
             <tr>
            <th scope="row" className="td_title">Measies 2nd dose</th>
            <td>16-24 months</td>
             <td>Till 5 years of age</td>
            <td>0.5 ml</td>
            <td>Sterile Water</td>
            <td>Subcutaneous</td>
            <td>Right upper Arm</td>
            <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
            <tr>
           <th scope="row" className="td_title">OPV Booster</th>
           <td>16-24 months</td>
           <td>Till 5 years of age</td>
           <td>2 drops</td>
           <td>No</td>
           <td>Oral</td>
           <td>-</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Japanese Encephalities 2nd dose</th>
           <td>16-24 months</td>
           <td>-</td>
           <td>0.5 ml</td>
           <td>Phosphate Buffer</td>
           <td>Subcutaneous</td>
           <td>Left Upper Arm</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">Vitamin A(2nd to 9th dose)</th>
           <td>16 months. Then 1 dose every 6 months</td>
           <td>Till 5 years of age</td>
           <td>2 ml(2 lakh IU)</td>
           <td>No</td>
           <td>Oral</td>
           <td>-</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">DPT Booster-2</th>
           <td>5-6 years</td>
           <td>7 years</td>
           <td>0.5 ml</td>
           <td>No</td>
           <td>Intramuscular</td>
           <td>Upper Arm(Left)</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           <tr>
           <th scope="row" className="td_title">TT</th>
           <td>10 years & 16 years</td>
           <td>-</td>
           <td>0.5 ml</td>
           <td>No</td>
           <td>Intramuscular</td>
           <td>Upper Arm</td>
           <td>
                <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
  <label class="form-check-label" for="flexCheckChecked">
   Taken
  </label>
    </div>
    </td>
           </tr>
           
       </tbody>
</table>
     
                    </div>
                </div>
               </div>


                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
