import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import { reactLocalStorage } from "reactjs-localstorage";
import Constant from "../../constants";

import { FormattedMessage } from "react-intl";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Sanarheader";
import Patcss from "../../public/css/patient/order-medicine.css";

import PatientFooter from "../patient/Patientfooter";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default class Homee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Language: "ENGLISH",
      specalitiesList: [],
      upComingDetailsView: [],
      allReminderForHtmlRendering: [],
      patientId: "",
      currentDateForVital: moment().format("YYYY-MM-DD"),
      vitalInfo: "",
      reminderSetting: "",
    };
    this.getAppointmentDetails = this.getAppointmentDetails.bind(this);
  }
  // called after the React component props or state has kk changed.

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
  };
  // make API calls once the component is initiated and configure    the values into the state.
  componentWillMount = () => {
    this.onLoadSpecalities();
  };
  // To get detais after first render
  componentDidMount = () => {
    let userData = "";
    console.log("userObjEnc...");
    userData = ls.get("userObjEnc");
    if (userData) {
      if (userData != "") {
        // this.setState({
        //   patientId: userData.patient_id,
        // });
        this.state.patientId = userData.patient_id;
        this.getPatientVitals();
        this.forceUpdate();
        this.getAppointmentDetails(userData.patient_id);
      }
    }

    var lang = localStorage.getItem("Language_selected");

    let clinic_id = window.location.pathname.split("/");
    if (clinic_id[2] != "") {
      localStorage.setItem("clinic_id", clinic_id[2]);
      this.setState.clinicId = clinic_id[2];
    }

    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
    this.getReminderDetails();
  };

  //To get Patient Vitals
  getPatientVitals = () => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/VitalInformation/getVitals",
      {
        patientId: this.state.patientId,
        date: this.state.currentDateForVital,
      }
    ).then((response) => {
      this.state.selectedPtientVitalArray = response.data.data.map((item) => {
        let realFeet = (item.height * 0.3937) / 12;
        let feet = Math.floor(realFeet);
        let inches = Math.round((realFeet - feet) * 12);
        let feetInches = feet + "'" + inches;
        item.feetInches = feetInches;
        return item;
      });
      this.state.vitalInfo = this.state.selectedPtientVitalArray[0];
      this.state.currentDateForVital = this.state.vitalInfo
        ? this.state.vitalInfo.createdAt
        : this.state.currentDateForVital;
    });
  };

  //To return colour codes based on vital values
  getClourCodes = (vitalName, value) => {
    console.log("vitalName, value", vitalName, value);
    if (vitalName === "bmi") {
      if (Number(value) > 18.5 && Number(value) <= 24.9) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 25 && Number(value) <= 29.9) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) > 30 && Number(value) <= 40) ||
        (Number(value) > 15 && Number(value) <= 18.49)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "bloodsugar") {
      if (Number(value) >= 90 && Number(value) <= 140) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 140 && Number(value) <= 199) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 200 && Number(value) <= 800) ||
        (Number(value) >= 40 && Number(value) <= 89)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "temperature") {
      if (Number(value) > 97 && Number(value) <= 99) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 99.1 && Number(value) <= 100.99) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 101 && Number(value) <= 110) ||
        (Number(value) >= 90 && Number(value) <= 96.99)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "pulse") {
      if (Number(value) > 60 && Number(value) <= 80) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 81 && Number(value) <= 90) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 91 && Number(value) <= 150) ||
        (Number(value) >= 40 && Number(value) <= 59)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "respiratory") {
      if (Number(value) >= 15 && Number(value) <= 20) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 21 && Number(value) <= 25) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) > 26 && Number(value) <= 40) ||
        (Number(value) >= 10 && Number(value) <= 14)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "fetal_heart_rate") {
      if (Number(value) >= 110 && Number(value) <= 169) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 170 && Number(value) <= 180) {
        return <hr class="red_line"></hr>;
      } else {
        return <hr class="brown_line"></hr>;
      }
    }
  };

  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };
  onLoadSpecalities = () => {
    Httpconfig.httptokenget(Constant.siteurl + "api/Category/1")
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          // if(response.data.data.length > 0)
          // {
          // this.state.specalitiesList = response.data.data;
          // this.forceUpdate();
          // }
          // else{
          // this.state.specalitiesList = "";
          // this.forceUpdate();
          // }
          // let specalitiesList = [];
          if (
            response.data.data.length == null ||
            response.data.data.length == 0
          ) {
            this.state.specalitiesList = "<div>No Specalities</div>";
          } else {
            //   specalitiesList = specalitiesList.filter((element) => { return element !== undefined })
            //   if (specalitiesList.length == 0) {
            //     specalitiesList = "<div>No Specalities</div>";
            //   } else {
            this.state.specalitiesList = response.data.data;
            this.forceUpdate();
            // }
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  getReminderDetails() {
    var currentDate = new Date();
    var retrievedObject = ls.get("userObjEnc");
    var patientId = retrievedObject.patient_id;
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Remindersetting/dateWiseReminderlist/" + patientId
    )
      .then((response) => {
        // if(response.data.data.length > 0)
        // {

        var allReminderForHtmlRendering = "";

        allReminderForHtmlRendering = response.data.data.map((item) => {
          return (
            <div class="row">
              <div class="col-md-12">
                <div class="med_rem_section">
                  <div class="med_box">
                    <div class="med_icon">
                      <img src="./images/patient/img/med-icon.svg" />
                    </div>
                    <div class="med_main">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="med_details">
                            <h2>
                              {item.medicine_name} <span>Upcoming</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="med_schedule">
                        <div class="row">
                          <div class="col-md-9 no_padding">
                            <div class="col-md-3">
                              <p class="list">
                                <img src="./images/patient/img/med-calender.svg" />
                                {item.fromdate} to {item.todate}
                              </p>
                            </div>
                            <div class="col-md-3">
                              <p class="list">
                                <img src="./images/patient/img/med-clock.svg" />
                                {item.time}
                              </p>
                            </div>
                            <div class="col-md-3">
                              <p class="list">
                                <img src="./images/patient/img/med-doc.svg" />
                                {item.intake_instraction}
                              </p>
                            </div>
                            <div class="col-md-3">
                              <p class="list">
                                <img src="./images/patient/img/med-pill.svg" />
                                {item.dose + " Pill"}
                              </p>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="med_session">
                              {/* <p class="green_box">M</p>
                      <p class="red_box">A</p>
                      <p class="blue_box">E</p> */}
                              <p className="">M</p>
                              <p className="">A</p>
                              <p className="">E</p>
                              <p>N</p>
                            </div>
                            <div class="med_option">
                              {/* <h6><img src="./images/patient/img/vit-download.svg" /></h6> */}
                              {/* <h6>
                        <img src="./images/patient/img/vit-edit.svg"/> 
                        <Link to={"/Createreminder/" + item.id} className="fa fa-edit point-cursor" title={"Update " + item.medicine_name}></Link>
                      </h6> */}

                              {/* <h6> <img onClick={() => this.deleteReminder(item.id, item.medicine_name)} src="./images/patient/img/vit-delete.svg" /></h6> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        if (
          allReminderForHtmlRendering == null ||
          allReminderForHtmlRendering.length == 0
        ) {
          allReminderForHtmlRendering = "<div>No Reminders</div>";
        } else {
          this.state.allReminderForHtmlRendering = allReminderForHtmlRendering;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getAppointmentDetails = (patientId) => {
    var timezone = moment.tz.guess();
    var postData = {
      patient_id: patientId,
      timezone: timezone,
      orderBy: "ASC",
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/displayupcomingappointments/",
      postData
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        let DocName = "";
        var upComingDetailsView = "";

        upComingDetailsView = response.data.data.map((upComingDetails, num) => {
          let upcomingStatus = [1, 2];
          if (upComingDetails.status == "1" || upComingDetails.status == "2") {
            if (upComingDetails.doctor_tbl != null) {
              if (
                upComingDetails.doctor_tbl.tbl_user &&
                upComingDetails.doctor_tbl.tbl_user != null
              ) {
                if (upComingDetails.doctor_tbl.tbl_user.name != null) {
                  DocName =
                    upComingDetails.doctor_tbl.tbl_user.name
                      .charAt(0)
                      .toUpperCase() +
                    upComingDetails.doctor_tbl.tbl_user.name.slice(1);
                }
              }
            }

            let patientAge = "";
            if (upComingDetails.patient_tbl.dob != null) {
              let dob = upComingDetails.patient_tbl.dob.split("T");
              let age = dob[0];
              let ageYears = moment().diff(age, "years");
              let ageMonths = moment().diff(age, "months");
              let ageDays = moment().diff(age, "days");
              if (ageYears > 0) {
                if (ageYears == 1) {
                  patientAge = ageYears + " Year";
                } else {
                  patientAge = ageYears + " Years";
                }
              } else if (ageMonths > 0) {
                if (ageMonths == 1) {
                  patientAge = ageMonths + " Month";
                } else {
                  patientAge = ageMonths + " Months";
                }
              } else if (ageDays) {
                patientAge = ageDays + " Days";
              }
            }
            var now = moment(new Date());
            var end = moment(upComingDetails.appointment_datetime);
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            var mindiff = moment().diff(end, "minutes");

            let doctorImage = "";
            let doctorAvailable = "";
            let patientImage = "";
            let docpic = "";
            if (upComingDetails.doctor_tbl != null) {
              docpic = upComingDetails.doctor_tbl.profile_pic;
              let docGender = upComingDetails.doctor_tbl.gender;

              if (upComingDetails.doctor_tbl.profile_pic != null) {
                let doctorImageData = upComingDetails.doctor_tbl.profile_pic;
                doctorImage = doctorImageData;
              } else {
                if (docGender.toLowerCase() == "male") {
                  doctorImage = "../images/patient/img/Profile/Male_doctor.svg";
                } else {
                  doctorImage =
                    "../images/patient/img/Profile/Female_doctor.svg";
                }
              }
              if (upComingDetails.doctor_tbl.is_available != null) {
                doctorAvailable = upComingDetails.doctor_tbl.is_available;
              }
            }

            if (upComingDetails.patient_tbl.profile_pic != null) {
              patientImage = upComingDetails.patient_tbl.profile_pic;
            } else {
              if (upComingDetails.patient_tbl.gender) {
                if (
                  upComingDetails.patient_tbl.gender.toLowerCase() == "male"
                ) {
                  patientImage =
                    "../images/patient/img/Profile/Male_patient.svg";
                } else {
                  patientImage =
                    "../images/patient/img/Profile/Female_patient.svg";
                }
              }
            }

            return (
              <div class="row">
                <div class="col-md-12">
                  <div class="upcoming_head">
                    
                     <h2>Upcoming Appointment</h2>
                    <h4>
                      Your appointment scheduled on{" "}
                      <span>
                        <img src="../images/patient/img/Ordermedicine//Date.svg" />
                        {moment(upComingDetails.appointment_datetime).format(
                          "dddd"
                        )}{" "}
                        {moment(upComingDetails.appointment_datetime).format(
                          "Do MMMM,YYYY"
                        )}{" "}
                        at{" "}
                        {moment(upComingDetails.appointment_datetime).format(
                          "LT"
                        )}
                      </span>
                    </h4>
                  </div>
                  <div class="schedule_app">
                    <div class="doctor_content">
                      <div class="doct_img">
                        <img src={doctorImage} alt="" />
                      </div>
                      <div class="doct_det">
                        <h3>Dr. {DocName}</h3>
                        <p>
                          {upComingDetails.doctor_tbl != null
                            ? upComingDetails.doctor_tbl.speciality_name
                            : ""}
                        </p>
                        <p>
                          {upComingDetails.doctor_tbl != null
                            ? upComingDetails.doctor_tbl.experience
                            : ""}{" "}
                          {translate("years experience")}
                        </p>
                      </div>
                    </div>
                    <div class="app_wth">
                      <img src="../images/patient/img/Ordermedicine/video.svg" />
                      <p>
                        You have <br />
                        appointment with
                      </p>
                    </div>
                    <div class="pat_content">
                      <div class="pat_img">
                        {patientImage != "" ? (
                          <img src={patientImage} alt="" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="pat_det">
                        <h3>
                          {upComingDetails.patient_tbl.name
                            .charAt(0)
                            .toUpperCase() +
                            upComingDetails.patient_tbl.name.slice(1)}
                        </h3>
                        <p>
                          {patientAge},{" "}
                          {upComingDetails.patient_tbl.gender
                            .charAt(0)
                            .toUpperCase() +
                            upComingDetails.patient_tbl.gender.slice(1)}
                        </p>
                      </div>
                    </div>
                    <div class="app_start">
                      <h5>
                        {upComingDetails.status == 2 ? (
                          <div
                            class="join_section"
                            id={upComingDetails.appointment_confirm_id}
                            onClick={this.navigate}
                          >
                            <a href="#">
                              {upComingDetails.status == 2 ? (
                                <p id={upComingDetails.appointment_confirm_id}>
                                  {translate("Join Now")}
                                </p>
                              ) : (
                                <p id={upComingDetails.appointment_confirm_id}>
                                  {translate("Please wait for Doctor to join")}
                                </p>
                              )}
                            </a>
                          </div>
                        ) : (
                          <div
                            class="join_section"
                            id={upComingDetails.appointment_confirm_id}
                          >
                            <a>
                              <p id={upComingDetails.appointment_confirm_id}>
                                {translate("Appointment Booked")}
                              </p>
                            </a>
                          </div>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        });
        if (upComingDetailsView == null || upComingDetailsView.length == 0) {
          upComingDetailsView = "<div>No Upcoming appointment</div>";
        } else {
          upComingDetailsView = upComingDetailsView.filter((element) => {
            return element !== undefined;
          });
          if (upComingDetailsView.length == 0) {
            upComingDetailsView = "<div>No Upcoming appointment</div>";
          } else {
            this.state.upComingDetailsView = upComingDetailsView;
            this.forceUpdate();
          }
        }
      }
    });
  };

  navigate = (event) => {
    ls.set("PatientselectedAppointmentEnc", event.target.id);
    this.props.history.push("/Patientchecksettings");
  };
  redirect = () => {
    if (this.state.patientId) {
      this.props.history.push("/Patientconsultationpurpose");
    } else {
      this.props.history.push("/patientLogin");
    }
  };

  render() {
    const specalitiesListItems = this.state.specalitiesList;
    console.log("------597----", this.state.allReminderForHtmlRendering.length);
    console.log("-------595--------", this.state.vitalInfo);
    return (
      <main id="main_ord_nav">
        <PatientHeader />
        <I18nPropvider locale={this.state.Language}>
          <section id="order_medicine_section">
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-consult"
                role="tabpanel"
                aria-labelledby="nav-consult-tab"
              >
                <section id="order_med_banner">
                  <div className="container">
                    <div
                      id="carouselExampleControls"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <OwlCarousel
                          ref="cars"
                          items={1}
                          className="owl-theme"
                          loop
                          nav
                          dots
                          margin={8}
                        >
                          <div>
                            <img
                              src="../images/patient/img/Homepage/consult_doctor_banner1.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <img
                              src="../images/patient/img/Homepage/consult_doctor_banner2.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <img
                              src="../images/patient/img/Homepage/consult_doctor_banner3.png"
                              alt=""
                            />
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </section>

                {this.state.patientId ? (
                  <section id="order_upcoming_app">
                   
                    <div class="container">
                      {this.state.upComingDetailsView}
                    </div>
                  </section>
                ) : (
                  ""
                )}

                {specalitiesListItems.length > 0 ? (
                  <section id="common_health">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="common_head">
                            <h2>Common Health Concerns</h2>
                            {/* <h5>Consult doctors for an instant appointment</h5> */}
                          </div>
                          <div class="common_carousel">
                            <div class="owl-carousel owl-theme">
                              <OwlCarousel
                                responsive={responsive}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                ref="cars"
                                items={6}
                                className="owl-theme"
                                loop
                              >
                                {specalitiesListItems.map((spe, num) => (
                                  <div class="item">
                                    <img
                                      src={Constant.imgurl + spe.category_image}
                                      alt="Image"
                                    />
                                    <div class="common_content">
                                      <h2>{spe.category}</h2>
                                      <a href="#" onClick={this.redirect}>
                                        <p>Consult now</p>
                                      </a>
                                    </div>
                                  </div>
                                ))}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
          {this.state.vitalInfo ? (
            <section id="home_vital_sheet_history">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="vital_head">
                      <h2>
                        Vital Sheet History{" "}
                        <span
                          onClick={() => {
                            window.location.href = "./ViewVitals";
                          }}
                        >
                          View All
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="vit_option">
                      <h2>
                        <img src="../images/patient/img/Profile/Male_patient.svg" />
                        {this.state.vitalInfo.patient_tbl.name}{" "}
                        {moment(this.state.currentDateForVital).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        |{" "}
                        {moment(this.state.currentDateForVital).format(
                          "HH:mm A"
                        )}
                      </h2>
                    </div>
                    {this.state.vitalInfo ? (
                      this.state.vitalInfo.patient_type === "adult" ? (
                        <div class="vit-list-section">
                          <div class="vital_list_box">
                            <div class="row">
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Height</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.feetInches
                                        : 0}
                                    </p>
                                    <span>feet/cm</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Weight</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.weight
                                        : 0}
                                    </p>
                                    <span>kg</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>BMI</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.bmi
                                        : 0}
                                    </p>
                                    <span>kg/m2</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Temperature</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.temperature
                                        : 0}
                                    </p>
                                    <span>Celsius</span>
                                  </div>
                                  <hr class="red_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Pulse</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.pulse_rate
                                        : 0}
                                    </p>
                                    <span>breaths per minute</span>
                                  </div>
                                  <hr class="red_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Blood Sugar</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.blood_sugar
                                        : 0}
                                    </p>
                                    <span>mg/dL</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Respiratory Rate</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.respiratory_rate
                                        : 0}
                                    </p>
                                    <span>
                                      breaths <br></br> per minute
                                    </span>
                                  </div>
                                  <hr class="yellow_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Blood Pressure</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo
                                            .blood_pressure_systolic
                                        : 0}
                                      /
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo
                                            .blood_pressure_diastolic
                                        : 0}
                                    </p>
                                    <span>mmHg</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Heart Rate</h2>
                                    <p>22</p>
                                    <span>/min</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>SpO2</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.spo2
                                        : 0}
                                    </p>
                                    <span>Celsius</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>ECG</h2>
                                    <button type="button" class="view_file_btn">
                                      View File
                                    </button>
                                  </div>
                                  <hr class="brown_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="./images/patient/img/vital-logo.svg" />
                                    <h2>Steps</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.steps
                                        : 0}
                                    </p>
                                    <span>/day</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="vital_main">
                          <div class="vital_list_box">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="../images/patient/img/vital-logo.svg" />
                                    <h2>Fetal heart rate</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo.fetal_heart_rate
                                        : 0}
                                    </p>
                                    <span>Beats/min</span>
                                  </div>
                                  {this.state.vitalInfo ? (
                                    this.getClourCodes(
                                      "fetal_heart_rate",
                                      this.state.vitalInfo.fetal_heart_rate
                                    )
                                  ) : (
                                    <hr class="brown_line"></hr>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    <img src="../images/patient/img/vital-logo.svg" />
                                    <h2>Head Circumference</h2>
                                    <p>
                                      {this.state.vitalInfo
                                        ? this.state.vitalInfo
                                            .head_circumference
                                        : 0}
                                    </p>
                                    <span>cm</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {this.state.allReminderForHtmlRendering.length > 0 ? (
            <section id="home_med_reminder">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="med_head">
                      <h2>
                        Medicine Reminder
                        <span
                          onClick={() => {
                            window.location.href = "./ViewReminderSetting";
                          }}
                        >
                          View All
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
                {this.state.allReminderForHtmlRendering
                  ? this.state.allReminderForHtmlRendering
                  : "No Data Avalable"}

                {/* <div class="row">
                <div class="col-md-12">
                  <div class="med_rem_section">
                    <div class="med_box">
                      <div class="med_icon">
                        <img src="./images/patient/img/med-icon.svg" />
                      </div>
                      <div class="med_main">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="med_details">
                              <h2>ECOSPRIN 75MG TAB <span>Upcoming</span></h2>
                            </div>
                          </div>

                        </div>
                        <div class="med_schedule">
                          <div class="row">
                            <div class="col-md-9 no_padding">
                              <div class="col-md-3">
                                <p class="list">
                                  <img src="./images/patient/img/med-calender.svg" />
                                  12/06/2022 to 22/06/2022
                                </p>
                              </div>
                              <div class="col-md-3">
                                <p class="list">
                                  <img src="./images/patient/img/med-clock.svg" />
                                  8.00 AM | 8.00 PM
                                </p>
                              </div>
                              <div class="col-md-3">
                                <p class="list">
                                  <img src="./images/patient/img/med-doc.svg" />
                                  Before Food
                                </p>
                              </div>
                              <div class="col-md-3">
                                <p class="list">
                                  <img src="./images/patient/img/med-pill.svg" />
                                  1 Pill
                                </p>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="med_session">
                                <p class="green_box">M</p>
                                <p class="red_box">A</p>
                                <p class="blue_box">E</p>
                                <p>N</p>
                              </div>
                              <div class="med_option">
                                <h6><img src="./images/patient/img/vit-download.svg" /></h6>
                                <h6> <img src="./images/patient/img/vit-edit.svg" /></h6>
                                <h6> <img src="./images/patient/img/vit-delete.svg" /></h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </section>
          ) : (
            ""
          )}

          <section id="order_download_app" class={this.state.downloadSection}>
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="download_content">
                    <h2>Download the Remotel HealthCare App</h2>
                    <h5>
                      Book appointments for video consultations with doctor from
                      the comfort of your home.
                    </h5>

                    <div class="play_store">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.healthcare.remotelpatient"
                        target="_blank"
                      >
                      <img src="../images/patient/img/Ordermedicine/googlepay_d.svg" />
                      </a>
                      <a
                        href="https://apps.apple.com/in/app/remotel-user/id6544799030"
                        target="_blank"
                      >
                      <img src="../images/patient/img/apple.svg" />
                      </a>
                    </div>
                    
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="app_img">
                    <img
                      class="mobile_img"
                      src="../images/patient/img/Download_app.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
