import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Link } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./sidebar";
import PatientFooter from "../patient/Patientfooter";
import FileBase64 from "react-file-base64";
const fs = require("fs");

export default class Createclinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      clinic_data: "",
      controller_data: "",
      pageHeading: "",
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    handle
      ? (this.state.pageHeading = "Update Category")
      : (this.state.pageHeading = "Create Category");
    if (handle != undefined) {
      this.getCategorytypedata();
      this.getClinicInfo(handle);
      this.getClinicdata();
    } else {
      this.getCategorytypedata();
      this.getClinicdata();
    }
  };

  // fetches all controllers
  getCategorytypedata() {
    const { handle } = this.props.match.params;
    Httpconfig.httptokenget(Constant.siteurl + "api/Specilization").then(
      (response) => {
        this.setState({
          controller_data: response.data,
          fields: {
            categorytype: response.data,
          },
        });
      }
    );
  }
  // fetches all Clinic data
  getClinicdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Clinic/clinicList").then(
      (response) => {
        this.setState({
          clinic_data: response.data.data,
        });
      }
    );
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };

  // Ends
  //  For getting clinic image files
  getClinicImageFile(files) {
    var fileFormate = false;

    files.map((item) => {
      if (item.type == "image/png" || item.type == "image/jpeg") {
        fileFormate = true;
      } else {
        fileFormate = false;
      }
    });

    if (fileFormate) {
      this.setState({ ClinicImageFiles: files });
      console.log(files);
      this.state.fields.category_image = files[0].base64;
    } else {
      toast.error("Upload files Jpeg / Png formate only!");
      return false;
    }
  }

  // To get all the ResponderInfo
  getClinicInfo(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Category/Findone/" + handle)
      .then((response) => {
        var cat_status = "";
        if (response.data.data.status == true) {
          cat_status = 1;
        } else {
          cat_status = 0;
        }
        // this.setState({
        //   fields: {
        //     category: response.data.data.category,
        //     category_type: response.data.data.category_type,
        //     clinic_name: response.data.data.clinic_id,
        //     category_image: response.data.data.category_image,
        //     status: cat_status,
        //   },
        // });
        this.state.fields.category =  response.data.data.category;
        this.state.fields.category_type = response.data.data.category_type
        this.state.fields.clinic_name = response.data.data.clinic_id
        this.state.fields.category_image = response.data.data.category_image
        this.state.fields.status = cat_status
        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateCategory(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createCategory(event);
    } else {
      toast.warn("Form has errors.");
    }
  }
  // creates new controller
  createCategory = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;

    let clinicLogo = "";
    if (this.state.ClinicImageFiles != undefined) {
      clinicLogo = this.state.ClinicImageFiles[0];
    }
    Httpconfig.httptokenpost(Constant.siteurl + "api/Category/myImage", {
      category: fields["category"],
      category_type: fields["category_type"],
      clinic_id: fields["clinic_name"],
      category_pic: clinicLogo,
      status: fields["status"],
    })
      .then((response) => {
        if (response.data.status == 204) {
          toast.error("Category already exists");
        }else{
          toast.success("Successfully Created Category");

          setTimeout(
            () => this.props.history.push("/admin/ViewCategory"),
            2000
          );
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateCategory = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    let clinicLogo = "";
    if (this.state.ClinicImageFiles != undefined) {
      clinicLogo = this.state.ClinicImageFiles[0];
    }
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Category/CategoryImage/UpdateCategory/" + handle,
      {
        category: fields["category"],
        category_type: fields["category_type"],
        clinic_id: fields["clinic_name"],
        category_pic: clinicLogo,
        status: fields["status"],
      }
    )
      .then((response) => {
        if (response.data.status == 204) {
          toast.error("Category already exists");
        }else{
          toast.success("Successfully Updated Category");
          setTimeout(() => this.props.history.push("/admin/ViewCategory"), 2000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["clinic_name"]) {
      formIsValid = false;
      errors["clinic_name"] = "Category type cannot be empty";
    }

    if (!fields["category"]) {
      formIsValid = false;
      errors["category"] = "Category name cannot be empty";
    }

    if (!fields["category_type"]) {
      formIsValid = false;
      errors["category_type"] = "Timezone cannot be empty";
    }
    if (!fields["category_image"]) {
      formIsValid = false;
      errors["category_image"] = "Please upload image formate";
    }
    this.setState({ errors: errors });

    return formIsValid;
  }

  render() {
    const { fields, errors, clinic_data, controller_data } = this.state;

    let clinicLogoImage = "";
    if (this.state.fields.category_image) {
      const { handle } = this.props.match.params;
      if (handle != undefined) {
      let imageName = this.state.fields.category_image;
      clinicLogoImage = Constant.imgurl + imageName;
      }else{
        let imageName = this.state.fields.category_image;
        clinicLogoImage =  imageName;
        
      }
    }

    return (
      <React.Fragment>
        <Header />
        <Sidebar />
        <section id="main_dashboard">
          <div className="container" id="main_front">
            <div className="row">
              <div className="col-md-12">
                <div className="dash-section">
                  <div className="section-header">
                    <ol className="breadcrumb">
                      <li className="active">
                        <Link to="/admin"> Dashboard</Link> &gt;
                        <a> Category </a>
                      </li>
                    </ol>
                  </div>
                </div>
                <section id="CMS_tab">
                  <div className="CMS_content">
                    <div className="container">
                      <div className="row">
                        <div className="tab-header">
                          <h3>{this.state.pageHeading} </h3>
                        </div>
                        <div id="reg_form">
                          <form onSubmit={this.checkSubmit.bind(this)}>
                            <div className="row">
                              <div className="form-group col-md-4">
                                <label>Select Clinic </label>
                                <select
                                  name="clinic_name"
                                  className="form-control"
                                  value={this.state.fields["clinic_name"]}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_name"
                                  )}
                                >
                                  <option value="">Select Clinic</option>
                                  {clinic_data &&
                                    clinic_data.map((clinic_data, i) => {
                                      return (
                                        <option value={clinic_data.id}>
                                          {clinic_data.clinic_name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["id"]}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label>Select Category Type</label>
                                <select
                                  name="category_type"
                                  className="form-control"
                                  value={this.state.fields["category_type"]}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "category_type"
                                  )}
                                >
                                  <option value="">Select Category Type</option>
                                  {controller_data &&
                                    controller_data.map(
                                      (controller_data, i) => {
                                        return (
                                          <option value={controller_data.name}>
                                            {controller_data.name}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["id"]}
                                </span>
                              </div>

                              <div className="col-md-4 cr_mid">
                                <label>Category Name</label>
                                <div className="form-group ">
                                  <input
                                    type="text"
                                    name="category"
                                    value={this.state.fields["category"] || ""}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "category"
                                    )}
                                    className="form-control"
                                    placeholder="Category Name"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["category"]}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div class="col-md-4">
                                <div className="form-group ">
                                  <FileBase64
                                    multiple={true}
                                    onDone={this.getClinicImageFile.bind(this)}
                                    id="uploadFile"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["category_image"]}
                                  </span>

                                  {clinicLogoImage != "" ? (
                                    <img
                                      style={{
                                        width: "190px",
                                        height: "125px",
                                      }}
                                      src={clinicLogoImage}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4 cr_mid">
                                <div className="form-group ">
                                  <label
                                    for="customRadio2"
                                    class="custom-control-label"
                                  >
                                    Status
                                  </label>
                                  <div class="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      value="1"
                                      checked={
                                        this.state.fields["status"] == "1"
                                      }
                                      name="status"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "status"
                                      )}
                                    />{" "}
                                    Active &nbsp;&nbsp;&nbsp;
                                    <input
                                      type="radio"
                                      value="0"
                                      checked={
                                        this.state.fields["status"] == "0"
                                      }
                                      name="status"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "status"
                                      )}
                                    />{" "}
                                    In Active
                                    <span className="cRed">
                                      {this.state.errors["status"]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-md-12">
                                <button
                                  type="submit"
                                  className="btn  btn-primary save_btn"
                                >
                                  Save Category
                                </button>{" "}
                                &nbsp;
                                <Link
                                  to="/admin/ViewCategory"
                                  className="cancel_btn"
                                >
                                  Cancel
                                </Link>{" "}
                                &nbsp; &nbsp;
                              </div>
                            </div>
                            
                          </form>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <PatientFooter />
      </React.Fragment>
    );
  }
}
