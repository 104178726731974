import React from "react";
const moment = require("moment");

export default {
  // Keep the signature of the original toast object
  // Doing so you can pass additionnal options

  asianDateFormat(date) {
    var formattedDate = "";
    if (date != "") {
      formattedDate = moment(date).format("DD/MM/YYYY hh:mm A ");
    }
    return formattedDate;
  },
  asianDateFormatnotime(date) {
    var formattedDate = "";
    if (date != "") {
      formattedDate = moment(date).format("DD/MM/YYYY");
    }
    return formattedDate;
  },
};
