import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Form, Button, Dropdown, Grid, Header } from "semantic-ui-react";
import ReactExport from "react-data-export";
import { Link } from "react-router-dom";
import $ from "jquery";

const moment = require("moment");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Revenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retailersListData: "",
      selectedStatus: "",
      selectedRetailerId: "",
      selectedStartDate: "",
      selectedEndDate: "",
      searchString: "",
      loadshow: "load_sec collapse-show",
      pageNumber: 1,
      ordersView: [],
      currentOrders: [],
      ordersCount: 0,
      totalRevenue: 0,
    };
    this.getAllOrders = this.getAllOrders.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.fetchRetailerInfo();
    this.getAllOrders();
  };

  // Get the retailers list
  fetchRetailerInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/retailer/")
      .then((response) => {
        let listData = response.data.data;
        let retailersListData = listData.map((item, index) => ({
          key: item.id,
          text: item.storename,
          value: item.id,
        }));

        retailersListData.unshift({ key: "All", text: "All", value: "All" });
        this.state.retailersListData = retailersListData;

        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // On retalier selection
  onRetailerListChange = (event, data) => {
    let selectedretailerValue = data.value;

    if (selectedretailerValue == "") {
      this.state.selectedRetailerId = "";
      this.forceUpdate();
      this.getAllOrders();
    } else {
      const keys = data.options.find(
        (data) => data.value === selectedretailerValue
      );
      const selectedretailer = {
        id: keys.key,
        medicineid: keys.text,
        medicinename: keys.value,
      };
      this.state.selectedRetailerName = keys.text;
      this.state.selectedRetailerId = selectedretailerValue;
      this.forceUpdate();
      this.getAllOrders();
    }
  };

  // get all the orders
  getAllOrders() {
    let retailerId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = 5;
    let searched = this.state.searchString;
    let payable_amount = "";
    let PaidAmount = "";
    let ordersCount = "";
    let totalOrdersAmount = "";
    let totalCommission = "";
    let finalordersarray = [];
    let totalRevenue = "0";

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDashboardDetails",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus,
        order_search_id: searched,
        page_number: "",
      }
    )
      .then((response) => {
        const ordersView = response.data.data.map((LoadedData, num) => {
          ordersCount = response.data.itemsCount;
          this.state.ordersCount = response.data.itemsCount;
          this.forceUpdate();

          totalOrdersAmount = response.data.total_orders;
          totalCommission = response.data.total_commission;
          PaidAmount = parseFloat(LoadedData.total_paid);

          if (isNaN(PaidAmount) == false) {
            totalRevenue = parseFloat(totalRevenue) + parseFloat(PaidAmount);
          }

          if (LoadedData.total_paid) {
            if (isNaN(PaidAmount) == false) {
              PaidAmount = PaidAmount.toFixed(2).replace(
                /\d(?=(\d{3})+\.)/g,
                "$&,"
              );
            } else {
              PaidAmount = LoadedData.payable_amount;
            }
          }

          let commission = parseFloat(
            LoadedData.retailer_registration_tbl.commission *
              (LoadedData.total_paid / 100)
          )
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");

          finalordersarray.push({
            Sno: num + 1,
            "Customer Name":
              LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
              LoadedData.patient_tbl.name.slice(1) +
              "," +
              LoadedData.patient_tbl.phone_number,
            Retailer:
              LoadedData.retailer_registration_tbl.storename +
              "," +
              LoadedData.retailer_registration_tbl.address +
              "," +
              LoadedData.retailer_registration_tbl.email +
              "," +
              LoadedData.retailer_registration_tbl.mobile_number,
            "Order Id": LoadedData.id,
            "Order Date": moment(LoadedData.order_date).format(
              "DD/MM/YYYY hh:mm:ss"
            ),
            Amount: PaidAmount,
          });

          return (
            <tr>
              <td>{num + 1}</td>
              <td>
                {LoadedData.patient_tbl.name
                  ? LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                    LoadedData.patient_tbl.name.slice(1)
                  : "--"}{" "}
                <h6 class="td_down">
                  {LoadedData.patient_tbl.phone_number
                    ? LoadedData.patient_tbl.phone_number
                    : "--"}
                </h6>
              </td>
              <td>
                <div class="dropdown">
                  <p class="dropbtn">
                    {LoadedData.retailer_registration_tbl.storename
                      ? LoadedData.retailer_registration_tbl.storename
                      : "--"}{" "}
                  </p>
                  <div class="dropdown-content">
                    <h3>
                      {LoadedData.retailer_registration_tbl.storename
                        ? LoadedData.retailer_registration_tbl.storename
                        : "--"}
                    </h3>
                    <p>
                      {}
                      {LoadedData.retailer_registration_tbl.address
                        ? LoadedData.retailer_registration_tbl.address
                        : ""}
                    </p>
                    <p>
                      {" "}
                      {LoadedData.retailer_registration_tbl.email
                        ? LoadedData.retailer_registration_tbl.email
                        : ""}
                    </p>
                    <p>
                      {" "}
                      {LoadedData.retailer_registration_tbl.mobile_number
                        ? LoadedData.retailer_registration_tbl.mobile_number
                        : ""}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p>{LoadedData.id}</p>
              </td>
              <td>
                {moment(LoadedData.order_date).format("DD/MM/YYYY hh:mm:ss")}
              </td>
              <td>
                {LoadedData.retailer_registration_tbl.currency}{" "}
                {LoadedData.total_paid
                  ? isNaN(PaidAmount) == false
                    ? PaidAmount
                    : "0.00"
                  : "--"}
              </td>
            </tr>
          );
        });
        this.state.ordersView = ordersView;
        this.state.totalOrdersAmount = totalOrdersAmount;
        this.state.totalCommission = totalCommission;
        this.state.finalordersarray = finalordersarray;
        this.state.totalRevenue = totalRevenue
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // get all the orders by page
  getAllOrdersbyPage(pageNumber) {
    let retailerId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = 5;
    let searched = this.state.searchString;
    let payable_amount = "";
    let PaidAmount = "";
    let ordersCount = "";
    let totalOrdersAmount = "";
    let totalCommission = "";
    let finalordersarray = [];
    pageNumber = pageNumber + 1;
    this.state.pageNumber = pageNumber;
    let orderViews = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDashboardDetails",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus,
        order_search_id: searched,
        page_number: pageNumber,
      }
    )
      .then((response) => {
        if (Object.keys(response.data.data).length > 0 && pageNumber > 1) {
          orderViews = this.state.currentOrders.concat(response.data.data);
          this.state.currentOrders = orderViews;
          this.forceUpdate();
          if (Object.keys(response.data.data).length > 0) {
            orderViews = response.data.data;
            this.state.currentOrders = orderViews;
            this.state.ordersCount = Object.keys(response.data.data).length;
            this.forceUpdate();
          }
        }
        if (Object.keys(orderViews).length > 0) {
          const ordersView = orderViews.map((LoadedData, num) => {
            ordersCount = response.data.itemsCount;
            this.state.ordersCount = response.data.itemsCount;
            this.forceUpdate();
            totalOrdersAmount = response.data.total_orders;
            totalCommission = response.data.total_commission;

            PaidAmount = parseInt(LoadedData.total_paid);

            if (LoadedData.total_paid) {
              if (isNaN(PaidAmount) == false) {
                PaidAmount = PaidAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                );
              } else {
                PaidAmount = LoadedData.payable_amount;
              }
            }
            let commission = parseFloat(
              LoadedData.retailer_registration_tbl.commission *
                (LoadedData.total_paid / 100)
            )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            finalordersarray.push({
              Sno: num + 1,
              "Customer Name":
                LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                LoadedData.patient_tbl.name.slice(1) +
                "," +
                LoadedData.patient_tbl.phone_number,
              Retailer:
                LoadedData.retailer_registration_tbl.storename +
                "," +
                LoadedData.retailer_registration_tbl.address +
                "," +
                LoadedData.retailer_registration_tbl.email +
                "," +
                LoadedData.retailer_registration_tbl.mobile_number,
              "Order Id": LoadedData.id,
              "Order Date": LoadedData.order_date,
              Amount: PaidAmount,
              "Commission Percent":
                LoadedData.retailer_registration_tbl.commission,
              "Commission Amount": commission,
            });

            return (
              <tr>
                <td>{num + 1}</td>
                <td>
                  {LoadedData.patient_tbl.name
                    ? LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                      LoadedData.patient_tbl.name.slice(1)
                    : "--"}{" "}
                  <h6 class="td_down">
                    {LoadedData.patient_tbl.phone_number
                      ? LoadedData.patient_tbl.phone_number
                      : "--"}
                  </h6>
                </td>
                <td>
                  <div class="dropdown">
                    <p class="dropbtn">
                      {LoadedData.retailer_registration_tbl.storename
                        ? LoadedData.retailer_registration_tbl.storename
                        : "--"}{" "}
                    </p>
                    <div class="dropdown-content">
                      <h3>
                        {LoadedData.retailer_registration_tbl.storename
                          ? LoadedData.retailer_registration_tbl.storename
                          : "--"}
                      </h3>
                      <p>
                        {}
                        {LoadedData.retailer_registration_tbl.address
                          ? LoadedData.retailer_registration_tbl.address
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.email
                          ? LoadedData.retailer_registration_tbl.email
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.mobile_number
                          ? LoadedData.retailer_registration_tbl.mobile_number
                          : ""}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p>{LoadedData.id}</p>
                </td>
                <td>{LoadedData.order_date}</td>
                <td>
                  {LoadedData.retailer_registration_tbl.currency}{" "}
                  {LoadedData.total_paid ? PaidAmount : "--"}
                </td>
                <td>
                  {LoadedData.retailer_registration_tbl.commission}
                  {" %"}
                </td>
                <td>
                  {LoadedData.retailer_registration_tbl.currency +
                    " " +
                    commission}
                </td>
              </tr>
            );
          });
          this.state.ordersView = ordersView;
          this.state.totalOrdersAmount = totalOrdersAmount;
          this.state.totalCommission = totalCommission;
          this.state.finalordersarray = finalordersarray;
          this.forceUpdate();
        } else {
          this.state.loadshow = "load_sec collapse-hide";
          this.forceUpdate();
        }
      })
      .catch((error) => {});
  }

  // Handle searched string
  handleSearchedString = (event) => {
    let searched = event.target.value;

    this.state.searchString = searched;
    this.forceUpdate();
  };
  //  for handleClear
  handleClear = (id) => {
    if (id == "start_date") {
      this.state.selectedStartDate = "";
      $("#" + id).val("");
    }
    if (id == "end_date") {
      this.state.selectedEndDate = "";
      $("#" + id).val("");
    }
    if (id == "input-search") {
      this.state.searchString = "";
      $("#" + id).val("");
    }
    this.getAllOrders();
  };
  // for startDate
  startDate = (event) => {
    let startDate = event.target.value;
    this.state.selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (this.state.selectedStartDate && this.state.selectedEndDate) {
      if (this.state.selectedStartDate <= this.state.selectedEndDate) {
        if (
          this.state.selectedEndDate != "" &&
          this.state.selectedStartDate != ""
        ) {
          this.getAllOrders();
        }
      } else {
        toast.error("from date cannot be greater than to date");
      }
    }
  };

  // Start date selection
  endDate = (event) => {
    let endDate = event.target.value;
    this.state.selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (this.state.selectedStartDate <= this.state.selectedEndDate) {
      if (
        this.state.selectedStartDate != "" &&
        this.state.selectedEndDate != ""
      ) {
        this.getAllOrders();
      }
    } else {
      toast.error("from date cannot be greater than to date");
    }
  };

  render() {
    return (
      <section id="main_dashboard">
        <div class="container">
          <div class="row">
            <div class="col-md-12 no_padding">
              <div id="order_section">
                <div class="container no_padding">
                  <div class="row">
                    <div class="dash-section col-md-12">
                      <div class="section-header">
                        <ol class="breadcrumb">
                          <li class="active">
                            <a href="/admin"> Dashboard</a> &gt; Reports &gt;
                            Pharmacy Revenue
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="row tab-header">
                    <div class="col-md-6 no_padding ">
                      <h3> Revenue </h3>
                    </div>
                    <div class="col-md-6 no_padding download-btn">
                      <div class="col-md-3"></div>
                      <div class="input-group">
                        <div class="total-consult">
                          <span>Total Orders: {this.state.ordersCount}</span>
                        </div>
                        <div class="total-revenue-btn">
                          <a class="btn">
                            Total Revenue: ₹ {this.state.totalRevenue}
                          </a>
                        </div>

                        {this.state.ordersCount > 0 ? (
                          <ExcelFile
                            element={
                              <a class="btn btn-primary fright" href="#">
                                {" "}
                                Download Report
                              </a>
                            }
                          >
                            <ExcelSheet
                              data={this.state.finalordersarray}
                              name="Order Request"
                            >
                              <ExcelColumn label="Sno" value="Sno" />
                              <ExcelColumn
                                label="Customer Name"
                                value="Customer Name"
                              />
                              <ExcelColumn label="Pharmacy" value="Retailer" />
                              <ExcelColumn label="Order Id" value="Order Id" />
                              <ExcelColumn
                                label="Order Date"
                                value="Order Date"
                              />
                              <ExcelColumn label="Amount" value="Amount" />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-5 date_sec">
                      <h2>Select Date</h2>
                      <form>
                        <div class="form-group">
                          <input
                            type="date"
                            id="start_date"
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            onChange={this.startDate}
                            max={moment().format("YYYY-MM-DD")}
                            value={this.state.selectedStartDate}
                          />
                        </div>
                        <p class="to_txt">To</p>
                        <div class="form-group">
                          <input
                            type="date"
                            id="end_date"
                            class="form-control"
                            onChange={this.endDate}
                            max={moment().format("YYYY-MM-DD")}
                            value={this.state.selectedEndDate}
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-lg-7 select_ret_sec">
                      <div class="form-group no_padding col-md-5">
                        {this.state.retailersListData ? (
                          <Dropdown
                            class="form-control"
                            id="medicines"
                            placeholder={"Select Pharmacy"}
                            fluid
                            clearable
                            search
                            selection
                            options={this.state.retailersListData}
                            onChange={this.onRetailerListChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="form-group no_padding col-md-3">
                        <input
                          type="text"
                          id="input-search"
                          class="form-control search_order"
                          placeholder="Search for Order Id"
                          onKeyUp={this.handleSearchedString}
                        />
                      </div>
                      <div class="form-group col-md-3 no_padding">
                        <a class="btn submit-btn" onClick={this.getAllOrders}>
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="CMS_tab">
                <div class="CMS_content">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div id="reg_form">
                          <div class="table-responsive dataTables_wrapper load_sec">
                            <table class="table table-bordered table-sm table-striped">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Customer</th>
                                  <th>Pharmacy Name</th>
                                  <th>Order ID</th>
                                  <th>Order Date</th>

                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.ordersView ? (
                                  this.state.ordersView
                                ) : (
                                  <React.Fragment>
                                    <td>No orders found</td>
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>

                            <div class="bottom-download-btns">
                              <div class="row">
                                <div class="col-md-9">
                                  <div class="report-btn">
                                    <ExcelFile
                                      element={
                                        <a class="btn" href="#">
                                          {" "}
                                          Download Report
                                        </a>
                                      }
                                    >
                                      <ExcelSheet
                                        data={this.state.finalordersarray}
                                        name="Order Request"
                                      >
                                        <ExcelColumn label="Sno" value="Sno" />
                                        <ExcelColumn
                                          label="Customer Name"
                                          value="Customer Name"
                                        />
                                        <ExcelColumn
                                          label="Retailer"
                                          value="Retailer"
                                        />
                                        <ExcelColumn
                                          label="Order Id"
                                          value="Order Id"
                                        />
                                        <ExcelColumn
                                          label="Order Date"
                                          value="Order Date"
                                        />
                                        <ExcelColumn
                                          label="Amount"
                                          value="Amount"
                                        />
                                      </ExcelSheet>
                                    </ExcelFile>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="total-consult">
                                    <span>
                                      Total Orders: {this.state.ordersCount}
                                    </span>
                                  </div>
                                  <div class="total-revenue-btn">
                                    <a class="btn">
                                      Total Revenue: ₹ {this.state.totalRevenue}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class={this.state.loadshow}
                              onClick={this.getAllOrdersbyPage.bind(
                                this,
                                this.state.pageNumber
                              )}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button onclick="topFunction()" id="myBtn" title="Go to top">
                  <img src="https://i.pinimg.com/originals/c4/f0/4b/c4f04b83f93836a05b4f188180708e0e.png" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
