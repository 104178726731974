import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctor";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import { Dropdown } from "semantic-ui-react";
import Dialog from "react-bootstrap-dialog";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

export default class DoctorHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      activeNotificationsCount: 0,
      notifyid: "",
    };
    this.doctorAvailableStatus = this.doctorAvailableStatus.bind(this);
    this.confirmAppointment = this.confirmAppointment.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.decline = this.decline.bind(this);
    this.autoDecline = this.autoDecline.bind(this);
  }
  doctorAvailableStatus = (event) => {
    if (event.target.value == 1) {
      this.state.doctorAvailable = 0;
      this.state.availableStatus = false;
    }
    if (event.target.value == 0) {
      this.state.doctorAvailable = 1;
      this.state.availableStatus = true;
    }
    var post_data = {
      is_available: this.state.doctorAvailable,
    };
    this.forceUpdate();
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Doctor/Doctoravailable/" + this.state.doctor_id,
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.dismiss();
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  decline = (event) => {
    let appointmentId = event.currentTarget.id;
    this.state.notifyid = appointmentId;
    var element = document.getElementById(appointmentId);
    element.classList.remove("collapse-show");
    element.classList.add("collapse-hide");
    let notifycount = this.state.activeNotificationsCount;
    if (notifycount > 0) {
      this.state.activeNotificationsCount = notifycount - 1;
    }
    Httpconfig.httptokenput(
      Constant.siteurl +
        "api/PatientAppointment/ConsultNowDoctorConfirmation/" +
        appointmentId,
      { status: 1, doctor_id: this.state.doctorId }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == "false") {
          this.state.notifyid = "";
          this.forceUpdate();
          toast.warn("Declined Successfully", {
            position: "bottom-center",
          });
        } else {
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  autoDecline = (appointmentId) => {
    if (appointmentId != null) {
      Httpconfig.httptokenput(
        Constant.siteurl +
          "api/PatientAppointment/ConsultNowDoctorConfirmation/" +
          appointmentId,
        { status: 1, doctor_id: this.state.doctorId }
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == "false") {
            // toast.warn("Declined Successfully", {
            //     position: "bottom-center",
            //   });
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  confirmAppointment = (event) => {
    let appointmentId = event.currentTarget.id;
    this.state.notifyid = appointmentId;
    var element = document.getElementById(appointmentId);
    element.classList.remove("collapse-show");
    element.classList.add("collapse-hide");
    let notifycount = this.state.activeNotificationsCount;
    if (notifycount > 0) {
      this.state.activeNotificationsCount = notifycount - 1;
    }
    Httpconfig.httptokenput(
      Constant.siteurl +
        "api/PatientAppointment/ConsultNowDoctorConfirmation/" +
        appointmentId,
      { status: 4, doctor_id: this.state.doctorId }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == "false") {
          this.state.notifyid = "";
          this.forceUpdate();
          toast.warn("Confirmed Successfully", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //play notification sound
  playSound = () => {
    var url = Constant.imgurl + "/images/sounds/long_ding.mp3";
    const audio = new Audio(url);
    audio.play();
    // alert(url);
  };
  pauseSound = () => {
    var url = Constant.imgurl + "/images/sounds/long_ding.mp3";
    const audio = new Audio(url);
    audio.pause();
    // alert(url);
  };
  getNotifications = (doctorId) => {
    var activeNotificationsCount = 0;
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/PatientAppointment/getConsultNowAppointments/" +
        doctorId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (response.data.data[0].is_available == 1) {
          }
          if (response.data.data[0].status == 0) {
            let notificationCount = response.data.data.length;

            if (notificationCount > 0) {
              this.state.notifcationRequest = "";
              this.forceUpdate();
              const requestNotification = response.data.data.map(
                (notifcation) => {
                  var time = notifcation.appointment_time;
                  var date = notifcation.appointment_date;
                  var dateTime = date + "T" + time;
                  var datediff = moment().diff(date, "days");
                  var mindiff = moment().diff(dateTime, "minutes");
                  activeNotificationsCount = activeNotificationsCount + 1;
                  toast.dismiss();
                  toast.warn(
                    "New Appointment request came from " +
                      notifcation.patient_name,
                    {
                      autoClose: 1000,
                      //  position: "bottom-center",
                    }
                  );
                  if (mindiff == 2) {
                    this.pauseSound();
                  } else {
                    this.playSound();
                  }
                  return (
                    <div
                      class={
                        this.state.notifyid == notifcation.appointment_id
                          ? "container collapse-hide"
                          : "container collapse-show"
                      }
                      id={notifcation.appointment_id}
                    >
                      <div class="row">
                        <div class="col-md-12 no_padding">
                          <div class="call_box">
                            <img src="../images/doctor-img/Homepage/consultnow.svg" />
                            <p class="consult_person">
                              {" "}
                              {notifcation.patient_name
                                .charAt(0)
                                .toUpperCase() +
                                notifcation.patient_name.slice(1)}{" "}
                              wants to consult now
                            </p>
                            <div class="call_btns">
                              <a
                                href="#"
                                id={notifcation.appointment_id}
                                onClick={this.decline}
                              >
                                <p class="decline_btn">Decline</p>
                              </a>
                              <a
                                href="#"
                                id={notifcation.appointment_id}
                                onClick={this.confirmAppointment}
                              >
                                {" "}
                                <p class="confirm_btn">Yes, Confirm</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              );
              this.setState({
                notifcationRequest: requestNotification,
                activeNotificationsCount: activeNotificationsCount,
                notifyid: "",
              });
            }
          }
        } else {
          if (
            response.data.status == 401 &&
            response.data.message == "Please Token is not valid! Login Again."
          ) {
            ls.remove("DocuserObj");
            window.location.href = "./login";
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  componentDidMount = () => {
    var retrievedObject = ls.get("DocuserObj");
    if (retrievedObject == null) {
      window.location.href = "./login";
    }
    let userData = retrievedObject;
    this.state.doctorId = userData.doctor_id;
    this.forceUpdate();
    this.setState({
      doctor_id: userData.doctor_id,
      date: moment().format("Do MMMM , YYYY"),
    });
    this.getNotifications(userData.doctor_id);
    const interval = setInterval(() => {
      this.getNotifications(userData.doctor_id);
    }, 10000);
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/Doctor/DoctorIsavailablecheck/" +
        userData.doctor_id
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (response.data.data[0].is_available == 1) {
            this.setState({
              doctorAvailable: response.data.data[0].is_available,
              availableStatus: true,
            });
          }
          if (response.data.data[0].is_available == 0) {
            this.setState({
              doctorAvailable: response.data.data[0].is_available,
              availableStatus: false,
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  removeLoggedSession = () => {
    var retrievedObject = ls.get("DocuserObj");
    let userData = retrievedObject;

    let doctorId = userData.userID;
    this.doctorAvailableStatusLogout();
    Httpconfig.httptokenget(Constant.siteurl + "api/Users/logout/" + doctorId)
      .then((response) => {
        if (response.data.status == "200" && response.data.error === false) {
          ls.remove("DocuserObj");
          ls.remove("DoctorSelectedPatientId");
          ls.remove("DoctorSelectedConsultation");
          ls.remove("doctorToken");
          toast.success(response.data.message);
          setTimeout(() => (window.location.href = "./login"), 1000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  validateToken = () => {
    var retrievedObject = ls.get("DocuserObj");
    if (retrievedObject == null) {
      ls.remove("doctorToken");
      window.location.href = "./login";
    }

    let userData = JSON.parse(retrievedObject);
    let userId = userData.userID;
    if (ls.get("doctorToken")) {
      let token = ls.get("doctorToken");

      Httpconfig.httptokenpost(Constant.siteurl + "api/Users/checkToken", {
        userid: userId,
        token: token,
      })
        .then((response) => {
          if (response.data.status == "200" && response.data.error === true) {
            ls.remove("DocuserObj");
            ls.remove("DoctorSelectedPatientId");
            ls.remove("DoctorSelectedConsultation");
            ls.remove("doctorToken");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Your Session has timed out.Please Relogin");
      ls.remove("DocuserObj");
      ls.remove("DoctorSelectedPatientId");
      ls.remove("DoctorSelectedConsultation");
      ls.remove("doctorToken");
    }
  };

  // when doctor logout update the staus
  doctorAvailableStatusLogout = () => {
    var post_data = {
      is_available: 0,
    };
    this.forceUpdate();

    Httpconfig.httptokenput(
      Constant.siteurl + "api/Doctor/Doctoravailable/" + this.state.doctorId,
      post_data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.dismiss();
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  render() {
    return (
      <main>
        <header id="header" class="fixed-top">
          <div class="container-fluid">
            <div class="logo float-left">
              <a href="/doctor/Doctorprofile">
                <img src="../../images/patient/img/main-logo.png" alt="" />
              </a>
            </div>
            <div class="float-left">
              <a href="#" class="doc_head_calendar">
                {" "}
                <span>
                  <img src="../../images/doctor-img/Homepage/Date.svg" />
                </span>{" "}
                {this.state.date}
              </a>
            </div>
            <nav class="main-nav float-right d-lg-block">
              <ul class="doc_second_menu">
                <li class="first_li">
                  <span>Your profile is now available to your patient </span>{" "}
                  <label class="switch">
                    <input
                      class="doc_blue_check"
                      type="checkbox"
                      value={this.state.doctorAvailable}
                      onChange={this.doctorAvailableStatus}
                      checked={this.state.availableStatus}
                    />
                    <span class="slider round"></span>
                  </label>
                </li>
                <li class="second_li">
                  <a
                    href="#"
                    class="log_out"
                    onClick={this.removeLoggedSession}
                  >
                    <img src="../../images/doctor-img/logout_icon.svg" />
                    Logout
                  </a>
                  <a href="#" class="notification">
                    <span>
                      <img
                        class="notify-img"
                        src="../../images/doctor-img/Homepage/notification.svg"
                      />
                    </span>
                    <span class="badge">
                      {this.state.activeNotificationsCount}
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <section id="doc_call_info">{this.state.notifcationRequest}</section>
        </header>

        <ToastContainer />
        <Dialog
          ref={(el) => {
            this.dialog = el;
          }}
        />
      </main>
    );
  }
}
