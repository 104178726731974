import React, { Component, useState } from "react";
import { Slider } from "react-semantic-ui-range";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl";
import translate from "../../i18nProvider/translate";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import * as SecureLS from "secure-ls";
import Search from "./search";
import { DateInput } from "semantic-ui-calendar-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { ThisMonthInstance } from "twilio/lib/rest/api/v2010/account/usage/record/thisMonth";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const moment = require("moment");
const Razorpay = require("razorpay");
const listdat = [];

export default class Diagnosticcart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItemsCount: "0",
      checkOutBtnisDisabled: false,
      isSampleChargesAdded: false,
      fields: {
        country_name: "India",
        stateName: "",
        username: "",
        mobile_no: "",
        address: "",
        landmark: "",
        search_address: "",
        city: "",
        zipcode: "",
        isEdit: false,
      },
      errors: {
        country_name: "India",
        stateName: "",
        username: "",
        mobile_no: "",
        address: "",
        landmark: "",
        city: "",
        zipcode: "",
      },
      indianStates: [],
      indianCities: [],
      cartId: ls.get("cartIdEnc"),
      cartItems: [],
      totalProductsDiscount: 0,
      totalPaidAmt: 0,
      totalValue: 0,
      totalNumberOfTests: 0,
      deliveryAddress: "",
      slotBookingTime: "",
      slotBookingDate: "",
      userData: ls.get("userObjEnc"),
      retailerStockData: [],
      totalNetAmt: 0,
      allAddressesForHtmlRendering: [],
      allAddressesArray: [],
      addAddressStatus: true,
      editableAddressId: "",
      allFamilyMembersArray: [],
      searchproductslist: "collapse-hide",
      familyMember: "",
      allFamilyMembersForHtmlRendering: [],
      addFamilyStatus: true,
      userRelations: "",
      familyfields: {
        username: "",
        mobile_no: "",
        gender_type: "",
        user_dob: "",
        user_relation: "",
        isFamilyEdit: false,
      },
      familyErrors: {
        username: "",
        mobile_no: "",
        gender_type: "",
        user_dob: "",
        user_relation: "",
      },
      couponsArray: [],
      allCouponsHtmlRendering: [],
      coupon: "",
      couponDiscount: 0,
      couponId: "",
      coupan_name: "",
      appliedCoupon: "Apply Coupon",
      familyMemberName: "Select Family Member",
      totalsavingsAmount: 0,
      search_address: "",
      detected_address: "",
    };
    this.removeCoupon = this.removeCoupon.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchmedicinedata = this.fetchmedicinedata.bind(this);
    if (this.state.cartId == "") {
      window.location.href = Constant.imgurl + "/myOrders";
    }
  }

  componentDidUpdate = () => {};

  componentDidMount = () => {
    // this.getCurrentLocation();
    this.getCartData();
    this.getDeliveryAddress();
    this.getCompleteStates();
    this.getPatientRelations();
    this.getRelativesList();
    this.getPatientCoupons();
    this.getCartDetails();
    this.getCitiesbasedonStateId();
    let cartData = "";
    let userData = "";
    let pincode = "";
    let pincodeMask = "";
    let savedAddress = "";

    userData = ls.get("userObjEnc");
    cartData = ls.get("cartIdEnc");
    pincode = ls.get("pinCodeEnc");
    savedAddress = ls.get("detected_address");

    geocodeByAddress(savedAddress).then((results) => {
      var res = getLatLng(results[0]);
      var lat = results[0].geometry.location.lat();
      var lang = results[0].geometry.location.lng();
      this.setState({
        latitude: lat,
        longitude: lang,
      });

      // this.setSearchAddressLatLong(lat, lang);
      // this.setState({ detected_address: search_address });
      // ls.set("detected_address", search_address);
      // this.displayPostcode(place[0].address_components, search_address);
      //  console.log(results);
    });
    // alert(savedAddress);
    this.setState({
      isDisabled: false,
      disabledRequired: false,
      pincode: pincode,
      pincodeMask: pincode,
    });

    if (cartData != "") {
      this.state.cartId = cartData;
      this.forceUpdate();
    }

    if (savedAddress !== "") {
      this.setState({
        detected_address: savedAddress,
        fields: {
          address: savedAddress,
          zipcode: pincode,
          country_name: "India",
        },
        errors: { country_name: "India" },
      });
    } else {
      this.getCurrentLocation();
    }

    if (userData) {
      if (Object.keys(userData).length > 0) {
        this.state.patientId = userData.patient_id;

        this.forceUpdate();
      }
    } else {
    }
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=" + Constant.googleAPiKey;
    document.head.appendChild(script);
  };

  handleLocationChange = (search_address) => {
    this.setState({ search_address });
  };

  // For set the latlong for address

  setSearchAddressLatLong = (lat, Lng) => {
    let search_address_latitude = lat;
    let search_address_longitude = Lng;
    this.setState({
      latitude: search_address_latitude,
      longitude: search_address_longitude,
    });

    ls.set("search_latitudeEnc", search_address_latitude);
    ls.set("search_longitudeEnc", search_address_longitude);
  };

  handleAddressSelect = (search_address, placeId) => {
    geocodeByPlaceId(placeId).then((place) => {
      geocodeByAddress(search_address)
        .then((results) => {
          console.log("--221--", results);
          var res = getLatLng(results[0]);
          var lat = results[0].geometry.location.lat();
          var lang = results[0].geometry.location.lng();
          this.setSearchAddressLatLong(lat, lang);
          this.setState({ detected_address: search_address });
          ls.set("detected_address", search_address);
          this.displayPostcode(place[0].address_components, search_address);
          //  console.log(results);
        })

        // .then((results) => this.setSearchAddressLatLong(results))
        .catch((error) => console.error("Error", error));
    });
  };

  displayPostcode = (address, searchedAddress) => {
    for (let p = address.length - 1; p >= 0; p--) {
      if (address[p].types.indexOf("postal_code") != -1) {
        Httpconfig.httptokenget(
          Constant.siteurl +
            "api/DIAGNOSIS/deliveryAddress/getpincode/" +
            address[p].long_name
        )
          .then((response) => {
            if (
              response.data.status == "200" &&
              response.data.isPinCodeAvailable == false
            ) {
              this.showDialog("cart");
              // this.setState({ detected_address: "", search_address: "" });
              // ls.remove("detected_address");
              // ls.remove("detected_addressEnc");
              // ls.remove("pinCodeEnc");
            } else {
              ls.set("pinCodeEnc", address[p].long_name);
              this.state.fields.zipcode = address[p].long_name;
              this.state.fields.address = searchedAddress;
              this.forceUpdate();
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
  };

  //get current location
  getCurrentLocation = () => {
    var placeIds = "";
    var self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var streetaddress = "";

        var url =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&key=" +
          Constant.googleAPiKey;
        $.getJSON(url, function (data, textStatus) {
          placeIds = data.results[0].place_id;

          if (data.results.length > 0) {
            streetaddress = data.results[0].formatted_address;
            ls.set("detected_addressEnc", streetaddress);
            ls.set("detected_address", streetaddress);
            self.handleAddressSelect(streetaddress, placeIds);
          }
        });
      });
      let detected_address = ls.get("detected_addressEnc");
      if (detected_address) {
        this.setState({ detected_address: detected_address });
        this.handleAddressSelect(detected_address, placeIds);
      }
    }
  };

  // For address select
  // handleAddressSelect = (search_address) => {
  //   geocodeByAddress(search_address)
  //     .then((results) => {
  //       var res = getLatLng(results[0]);
  //       var lat = results[0].geometry.location.lat();
  //       var lang = results[0].geometry.location.lng();
  //       this.setSearchAddressLatLong(lat, lang);
  //       this.setState({ detected_address: search_address });
  //       ls.set("detected_address", search_address);
  //     })
  //     .catch((error) => console.error("Error", error));
  // };

  // //get current location
  // getCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       var streetaddress = "";
  //       var url =
  //         "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
  //         position.coords.latitude +
  //         "," +
  //         position.coords.longitude +
  //         "&key=" +
  //         Constant.googleAPiKey;
  //       $.getJSON(url, function (data, textStatus) {
  //         if (data.results.length > 0) {
  //           streetaddress = data.results[0].formatted_address;
  //           ls.set("detected_addressEnc", streetaddress);
  //           ls.set("detected_address", streetaddress);
  //         }
  //       });
  //     });

  //     let detected_address = ls.get("detected_addressEnc");
  //     if (detected_address) {
  //       this.setState({ detected_address: detected_address });
  //       this.handleAddressSelect(detected_address);
  //     }
  //   }
  // };

  //sample collected charges condition
  updatedeliveTypeId = (event) => {
    let samplePaidAmt = "";
    let delivery = event.currentTarget.value;
    if (delivery == "home_visit") {
      this.state.isSampleChargesAdded = true;
      this.state.totalPaidAmt += parseFloat(this.state.sampleCollectedCharges);

      $("#sample_charge").show();
    } else if (delivery == "center_visit") {
      if (this.state.isSampleChargesAdded) {
        this.state.totalPaidAmt -= parseFloat(
          this.state.sampleCollectedCharges
        );
        $("#sample_charge").hide();
      }
    }
    this.state.deliveryType = delivery;
    this.forceUpdate();
  };

  fetchmedicinedata(event) {
    let pincode = "";
    pincode = ls.get("pinCodeEnc");
    if (pincode == "") {
      this.showDialog("search");
      return;
    }
    let search = event.target.value;
    let medicinesList = [];
    if (search.length > 0) {
      this.state.remove_icon = "remove_icon collapse-show";
      this.forceUpdate();
    } else {
      this.state.remove_icon = "remove_icon collapse-remove_icon";
      this.forceUpdate();
    }

    if (search.length > 2) {
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/MedicineDetails/MedicineSearchDetails",
        { medicine_name: search, patient_id: this.state.patientId }
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.result).length;
        this.state.search = search;
        if (Object.keys(response.data.result).length > 0) {
          const medicineDetailsView = response.data.result.map(
            (finalLoadedData, num) => {
              return (
                <li
                  class="search-li"
                  id={finalLoadedData.id}
                  onClick={this.handleSearch}
                >
                  {" "}
                  {finalLoadedData.testname}
                </li>
              );
            }
          );

          this.state.medicineOptions = "";
          this.state.medicineOptions = medicineDetailsView;
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          this.state.medicineProductsView = "";
        }
      });
    } else {
      $(".clear_icon").hide();
      this.state.medicineOptions = "";
      this.state.medicineProductsView = "";
      this.state.search = search;
      this.state.searchCount = 0;
      this.forceUpdate();
    }
  }

  getCartDetails = () => {
    let cartId = "";
    cartId = this.state.cartId;

    let total = 0;
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/Cart/findAllCartDetails/" + cartId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.cartItemsCount = response.data.cart_count;
          this.forceUpdate();
          const cartDetailsView = response.data.data.map((LoadedData, num) => {
            total =
              parseInt(total) +
              LoadedData.diagnosis_products_master_tbl.mrp *
                LoadedData.quantity;

            return (
              <React.Fragment>
                <p>
                  {LoadedData.diagnosis_products_master_tbl.testname}{" "}
                  <span>Qty: {LoadedData.quantity}</span>
                </p>
              </React.Fragment>
            );
          });
          this.state.cartTotal = total;
          this.state.cartDetailsPopup = cartDetailsView;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // show cart popup
  miniCart = () => {
    let cartId = this.state.cartId;
    let total = 0;
    let productTotal = 0;

    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/Cart/findAllCartDetails/" + cartId
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.state.cartItemsCount = response.data.cart_count;
          this.forceUpdate();

          const cartDetailsPage = response.data.data.map((LoadedData, num) => {
            productTotal = 0;
            total =
              parseFloat(total) +
              LoadedData.diagnosis_products_master_tbl.mrp *
                LoadedData.quantity;
            productTotal =
              parseFloat(productTotal) +
              LoadedData.diagnosis_products_master_tbl.mrp *
                LoadedData.quantity;

            return (
              <div class="cart_list_box">
                <div class="row">
                  <div class="col-md-2">
                    <div class="cart_img">
                      <img
                        src={
                          LoadedData.diagnosis_products_master_tbl.image != null
                            ? Constant.imgurl +
                              LoadedData.diagnosis_products_master_tbl.image
                            : this.getProductImage(
                                LoadedData.diagnosis_products_master_tbl.form
                              )
                        }
                      />
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="cart_item_det">
                      <h2>
                        {LoadedData.diagnosis_products_master_tbl.testname +
                          " " +
                          LoadedData.diagnosis_products_master_tbl.strength}
                      </h2>
                      <p>
                        {" "}
                        <span class="mrp">
                          MRP:{Constant.currency}{" "}
                          {LoadedData.diagnosis_products_master_tbl.mrp}
                        </span>
                        <span>
                          {Constant.currency}{" "}
                          {productTotal
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </span>
                      </p>
                      <h4>{LoadedData.diagnosis_products_master_tbl.size}</h4>
                      <h6>
                        {LoadedData.diagnosis_products_master_tbl.manufacturer}
                      </h6>

                      {/* <div class="input-group number-spinner">
                          <div class="input-group-btn">
                            <button
                              class="btn btn-default minus_btn"
                              data-dir="dwn"
                              id={"minus-" + LoadedData.medicine_id}
                              onClick={this.qtyDecrementUpdate.bind(
                                this,
                                LoadedData.medicine_id,
                                LoadedData.id
                              )}
                            >
                              <i class="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            class="form-control text-center"
                            readOnly="readOnly"
                            value={LoadedData.quantity}
                            id={"qty-" + LoadedData.medicine_id}
                          />
                          <div class="input-group-btn">
                            <button
                              class="btn btn-default plus_btn"
                              data-dir="up"
                              id={"plus-" + LoadedData.medicine_id}
                              onClick={this.qtyIncrementUpdate.bind(
                                this,
                                LoadedData.medicine_id,
                                LoadedData.id
                              )}
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
                <div class="cart_item_btm">
                  <a
                    href="#"
                    id={LoadedData.id}
                    onClick={this.removeItem.bind(this, LoadedData.id)}
                  >
                    <p>Remove</p>
                  </a>
                  <a
                    href="#"
                    id={LoadedData.medicine_id}
                    onClick={this.saveForLater}
                  >
                    <h3>
                      {" "}
                      <img src="../images/patient/img/Ordermedicine/saveforLater.svg" />{" "}
                      Save For Later
                    </h3>
                  </a>
                </div>
              </div>
            );
          });
          this.state.cartDetailsPageSection = "collapse-show";
          this.state.home = "collapse-hide";
          this.state.downloadSection = "collapse-hide";
          this.state.searchproductslist = "collapse-hide";
          this.state.cartTotal = total;
          this.state.cartDetailsPage = cartDetailsPage;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  handleSearch = (event) => {
    let searched = event.currentTarget.id;
    window.location.href =
      Constant.imgurl + "/Diagnostictestdetail/" + searched;
    // this.getProductDetailsById(searched);
    let testName = event.currentTarget.innerText;
    this.state.selelcteMedicineName = testName;
    this.state.home = "collapse-hide";
    this.state.searchproductslist = "collapse-show";
    this.state.cartDetailsPageSection = "collapse-hide";
    this.state.downloadSection = "collapse-hide";

    $("#medicineList").val(testName);
    this.state.search = testName;
    this.state.medicineOptions = "";
    this.forceUpdate();
  };

  getProductDetailsById = (productId) => {
    if (productId != "") {
      Httpconfig.httptokenget(
        Constant.siteurl + "api/DIAGNOSIS/productMaster/fetchdata/" + productId
      ).then((response) => {
        this.state.searchCount = Object.keys(response.data.data).length;
        if (Object.keys(response.data.data).length > 0) {
          const medicineProductsView = response.data.data.map(
            (product, num) => {
              var testID = product.testid;
              this.getAlternateMedicine(testID);
              return (
                <div className="col-md-8">
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="diagnostics_breadcrumbs">
                        <ul class="breadcrumbs_list">
                          <li>
                            <a href="#">Home</a>
                          </li>
                          <li>
                            <a href="#">Lab Tests</a>
                          </li>
                          <li>
                            <a href="#" className="active">
                              {product.testname}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div className="testdetail_block">
                    <div className="testdetail_decription">
                      <div className="testtitle_block">
                        <div className="test_title">
                          <h5>{product.testname}</h5>
                          <p> {product.description}</p>
                        </div>
                        <div className="testdetail_price">
                          <p className="main_price">
                            {" "}
                            {Constant.currency} {/* {product.mrp} */}
                            {product.discount
                              ? parseFloat(product.mrp) -
                                (product.mrp * product.discount) / 100
                              : product.mrp}
                          </p>
                          <span className="discount_price">
                            <del>
                              {product.discount
                                ? Constant.currency + "" + product.mrp
                                : ""}
                            </del>{" "}
                            {product.discount
                              ? product.discount + " % off"
                              : ""}
                          </span>
                        </div>
                      </div>
                      {/* <div className="testtitle_alt">
                        <span>Also known as:</span>
                        <p>
                          Complete Blood Picture, Complete Blood Profile, CBP
                        </p>
                      </div> */}
                      <div className="last_cart">
                        <div className="testdetail_cat">
                          <span className="test_cat">Category:</span>
                          <span className="testcat_name"> Lab Test</span>
                        </div>
                        <div className="testdetail_cart">
                          <button
                            class={"addedtocart tests_btn" + product.id}
                            id={product.id}
                            onClick={this.addToCart}
                          >
                            Add to Cart
                          </button>
                          <button
                            class="removecart"
                            id={"add_to_cart-" + product.id}
                            style={{ display: "none" }}
                          >
                            <img src="./images/patient/img/diagnostics/checkmark.svg" />
                            Added
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="testdetail_singletest">
                      <div className="singletest_type">
                        <span className="type">Sample Type:</span>
                        <span className="type_title">{product.form}</span>
                      </div>
                      <div className="singletest_preparations">
                        <span className="preparations">Preparations:</span>
                        <span className="preparations_title">
                          {" "}
                          Fasting from early morning
                        </span>
                      </div>
                      <div className="singletest_about">
                        <span className="about">About:</span>
                        <p className="about_description" id="description_about">
                          N/A
                        </p>
                        {/* <div className="read_option">
                          <span id="readmore_about">Read More</span>
                        </div> */}
                      </div>
                    </div>

                    <div className="testdetail_packagetest">
                      <div className="package_total">
                        <h5>Total Tests Included</h5>
                      </div>
                      <div className="package_list" id="package_description">
                        <ol>
                          <li>N/A</li>
                        </ol>
                      </div>
                      {/* <div className="read_option">
                        <span id="readmore_packagelist">Read More</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            }
          );
          this.setState({
            medicineProductsView: medicineProductsView,
          });
          this.forceUpdate();
        } else {
          this.state.medicineOptions = "";
          this.state.medicineProductsView = "";
          this.forceUpdate();
        }
      });
    } else {
      this.state.medicineOptions = "";
      this.state.medicineProductsView = "";
      this.forceUpdate();
    }
  };

  getAlternateMedicine = (testID) => {
    var postData = {
      medicineId: testID,
    };
    if (postData != "") {
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/MedicineDetails/alternativeMedicines/",
        postData
      ).then((response) => {
        const alerternateMedicine = response.data.result.map(
          (LoadedData, num) => {
            var medicineID = LoadedData.testid;
            //this.getAlternateMedicine(medicineID);
            return (
              <div className="relatedtests_block">
                <div className="relatedtests_build">
                  <div className="relatedtests_img">
                    {/* <img src="./images/patient/img/diagnostics/testimage.svg"></img> */}
                    <img src={Constant.imgurl + LoadedData.image} />
                  </div>
                  <div className="relatedtests_heading">
                    <h5
                    // onClick={this.getProductDiscription.bind(
                    //   this,
                    //   LoadedData.id
                    // )}
                    >
                      {LoadedData.testname}
                    </h5>
                  </div>
                  <div className="relatedtests_des">
                    <p>{LoadedData.description}</p>
                  </div>
                  <div className="relatedtests_include">
                    <span>Includes 65 tests</span>
                  </div>
                  <div className="relatedtests_footer">
                    <div className="relatedtests_price">
                      <p className="main_price">
                        {Constant.currency} {LoadedData.mrp}
                      </p>
                      <span className="discount_price">
                        <del>{LoadedData.discount ? LoadedData.mrp : ""}</del>{" "}
                        {LoadedData.discount ? LoadedData.discount : ""}
                      </span>
                    </div>
                    <div className="relatedtests_cart">
                      <button
                        class={"addedtocart tests_btn" + LoadedData.id}
                        id={LoadedData.id}
                        onClick={this.addToCart}
                      >
                        Add to Cart
                      </button>
                      <button
                        class="removecart"
                        id={"add_to_cart-" + LoadedData.id}
                        style={{ display: "none" }}
                      >
                        <img src="./images/patient/img/diagnostics/checkmark.svg" />
                        Added
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        );
        this.setState({
          alerternateMedicine: alerternateMedicine,
        });
        this.forceUpdate();
      });
    } else {
      this.state.alerternateMedicine = "";
      this.forceUpdate();
    }
  };
  addToCart = (event) => {
    let testId = event.currentTarget.id;

    let quantity = $("#qty-" + testId).val();
    $("#add_to_cart-" + testId).show();
    $(".tests_btn" + testId).hide();
    let cartId = "";
    let cartItemsCount = this.state.cartItemsCount;

    if (quantity == 0) {
      toast.error("Quantity should not be Zero");
      return false;
    }
    if (this.state.cartId) {
      cartId = this.state.cartId;
    }
    // if (quantity > 50) {
    //   toast.error("Quantity should not be more than 50");
    //   return false;
    // }
    if (cartItemsCount >= 30) {
      toast.error("You can add medicines upto 30 only for an order");
      return false;
    }
    this.setState({
      couponDiscount: 0,
      couponId: "",
      coupan_name: "",
      appliedCoupon: "Apply Coupon",
    });
    $("#remove_coupon").hide();
    Httpconfig.httptokenpost(Constant.siteurl + "api/DIAGNOSIS/Cart", {
      cart_id: cartId,
      shop_id: 0,
      patient_id: this.state.patientId,
      medicine_id: testId,
      quantity: quantity,
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (cartId == "") {
            this.state.cartId = response.data.cart_id;
            this.state.cartItemsCount = response.data.cart_items;
            this.forceUpdate();

            ls.set("cartIdEnc", response.data.cart_id);
          } else {
            if (this.state.cartId != response.data.cart_id) {
              this.state.cartId = response.data.cart_id;
              this.state.cartItemsCount = response.data.cart_items;
              ls.set("cartIdEnc", response.data.cart_id);
              this.forceUpdate();
            }
          }
          this.getCartDetails();
          if (response.data.message == "Test already added in the cart") {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 0,
            });
          } else {
            toast.success(response.data.message, {
              position: "bottom-center",
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    $("#order_pro_toast").show();
    setTimeout(function () {
      $("#order_pro_toast").hide();
    }, 3000);
  };

  //get product description
  getProductDiscription = (product_id) => {
    if (product_id != "") {
      window.location.href =
        Constant.imgurl + "/Diagnostictestdetail/" + product_id;
      localStorage.setItem("OrderMedicineId", product_id);
    }
  };

  //To get all card details
  getCartData = () => {
    Httpconfig.httptokenpost(Constant.siteurl + "api/DIAGNOSIS/ProductSearch", {
      cart_id: this.state.cartId,
    }).then((response) => {
      if (response.data.cartData.length) {
        this.state.totalValue = 0;
        this.state.retailerStockData = response.data.retailerInfo[0]
          ? response.data.retailerInfo[0].stockAvailable
          : "";
        this.state.totalNumberOfTests = response.data.cartData.length;
        this.state.cartItemsCount = this.state.totalNumberOfTests;
        this.state.totalPaidAmt = response.data.retailerInfo[0]
          ? response.data.retailerInfo[0].AmountDetailes.totalPaidAmt
          : "";

        if (this.state.sampleCollectedCharges) {
          this.state.totalPaidAmt += parseInt(
            this.state.sampleCollectedCharges
          );
        }

        this.state.totalNetAmt = response.data.retailerInfo[0]
          ? response.data.retailerInfo[0].AmountDetailes.totalNetAmt
          : "";
        this.state.totalProductsDiscount = response.data.retailerInfo[0]
          ? response.data.retailerInfo[0].AmountDetailes.totalProductsDiscount
          : "";
        this.state.totalsavingsAmount = response.data.retailerInfo[0]
          ? response.data.retailerInfo[0].AmountDetailes.totalProductsDiscount
          : "";
        this.state.sampleCollectedCharges = response.data.retailerInfo[0]
          ? response.data.retailerInfo[0].retailerInfo.set_delivery_slab
            ? response.data.retailerInfo[0].retailerInfo.set_delivery_slab
            : 0
          : 0;
        let cartData = "";
        if (response.data.retailerInfo[0]) {
          cartData = response.data.retailerInfo[0].stockAvailable.map(
            (stockAvailableData) => {
              this.state.totalValue += parseFloat(stockAvailableData.mrp);
              return (
                <div class="cart_box">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="cart_title">
                        <h2
                          onClick={this.getProductDiscription.bind(
                            this,
                            stockAvailableData.diagnosis_products_master_tbl.id
                          )}
                        >
                          {stockAvailableData.diagnosis_products_master_tbl
                            ? stockAvailableData.diagnosis_products_master_tbl
                                .testname
                            : ""}
                        </h2>
                        {/* <p>Includes 12 tests</p> */}
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="qty_text">
                        <p>
                          Qty:<span>1</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="amount_details">
                        <h2>
                          ₹{/* {stockAvailableData.afterDiscountMrp} */}
                          {parseFloat(
                            stockAvailableData.afterDiscountMrp
                          ).toFixed(2)}
                        </h2>
                        <p>
                          <del>₹{parseFloat(stockAvailableData.mrp)}</del>{" "}
                          {stockAvailableData.discount}% off
                        </p>
                        <div
                          class="remove_btn"
                          id={stockAvailableData.cart_tbl_id}
                          onClick={this.removeCartItems}
                        >
                          <img src="./images/patient/img/remove.svg" />
                          Remove
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          );
        }
        this.state.cartDetailsPageSection = "collapse-show";
        this.state.home = "collapse-show";
        this.state.downloadSection = "collapse-hide";
        this.state.searchproductslist = "collapse-hide";

        this.forceUpdate();
        this.setState({ cartItems: cartData });
      } else {
        this.setState({
          cartItems: [],
          totalNumberOfTests: 0,
          totalPaidAmt: 0,
          totalPaidAmt: 0,
          couponDiscount: 0,
          totalProductsDiscount: 0,
          totalValue: 0,
          totalsavingsAmount: 0,
          cartItemsCount: 0,
        });
        // window.location.href = Constant.imgurl + "/myOrders";
      }
    });
  };

  // To get user selected delivery address
  getDeliveryAddress = () => {
    let defaultAddressFilter = "";
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/DIAGNOSIS/deliveryAddress/findAllDeliveryAddress/" +
        this.state.userData.patient_id
    )
      .then((response) => {
        defaultAddressFilter = response.data.data.filter((address) => {
          return address.default_status === 1;
        });

        if (defaultAddressFilter == "") {
          defaultAddressFilter = response.data.data.filter((address) => {
            return address.default_status == 0;
          });
        }
        //console.log(defaultAddressFilter);
        this.state.allAddressesArray = response.data.data;
        // commented because of current select address to be added
        // this.state.deliveryAddress = defaultAddressFilter[0]
        //   ? defaultAddressFilter[0]
        //   : "";
        this.forceUpdate();
        // console.log("deliveryAddressData===826", this.state.deliveryAddress);
        this.deliveryAddressRendering();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Patient relations data
  getPatientRelations = () => {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/Patients/findpatientrealtives/" +
        this.state.userData.patient_id
    )
      .then((response) => {
        let defaultPatientFilter = response.data.data.filter((family) => {
          return family.id === this.state.userData.patient_id;
        });
        this.state.allFamilyMembersArray = response.data.data;
        this.state.familyMember = defaultPatientFilter[0];
        this.state.familyMemberName = defaultPatientFilter[0]["name"];
        this.forceUpdate();
        this.familyMembersRendering();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Get raltives list
  getRelativesList = () => {
    Httpconfig.httptokenget(Constant.siteurl + "api/masterrealtionships")
      .then((response) => {
        if (response.data.status == 200) {
          this.state.userRelations = response.data.data;
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //Get available Coupons
  getPatientCoupons = () => {
    Httpconfig.httptokenpost(Constant.siteurl + "api/Coupon/couponslist", {
      moduleName: "diagnostics",
    })
      .then((response) => {
        if (response.data.status == 200) {
          this.state.couponsArray = response.data.data;
          this.forceUpdate();
          this.couponListRendering();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Render coupons list in slider
  couponListRendering = () => {
    this.state.allCouponsHtmlRendering = this.state.couponsArray.map((item) => {
      return (
        <div class="sample_box mt-3" key={item.id}>
          <div class="family exit_coupons">
            <div className="coupon_desc">
              <h6>
                {"Code: "}
                {item.coupon_name}
              </h6>
              <p>
                {"Maximum discount"} {Constant.currency}{" "}
                {item.maximumdiscount_amount}
              </p>
            </div>
            <div className="new_coupon_apply">
              <h5>
                <button onClick={this.applyCoupon.bind(this, item.coupon_name)}>
                  Apply
                </button>
              </h5>
            </div>
          </div>
        </div>
      );
    });
    this.forceUpdate();
  };

  applyCoupon = (e, couponName) => {
    console.log("-----1155----", this.state.sampleCollectedCharges);

    console.log("-----1157 ------", this.state.isSampleChargesAdded);

    console.log("-------1159------", this.state.deliveryType);

    console.log("-----1155----", this.state.totalPaidAmt);

    if (this.state.deliveryType == "home_visit") {
      var CalAmount =
        this.state.totalPaidAmt - this.state.sampleCollectedCharges;
    } else if (this.state.deliveryType == "center_visit") {
      CalAmount = this.state.totalPaidAmt;
    } else {
      CalAmount = this.state.totalPaidAmt;
    }

    var coupanName = this.state.coupon;
    var couponDiscount = 0; //"5";//this.state.couponDiscount;
    var totDiscountAmount = "";
    var feeAmount = CalAmount;
    var payableAmount = "";
    var couponId = "";
    var totalsavings = "";
    var totalsaveAmount = "";
    var coupon_name = "";
    var totpayableAmount = "";

    let postData = {
      consultationFees: feeAmount,
      coupanName: e,
      amount: feeAmount,
      patientId: this.state.userData.patient_id,
      module: "diagnostics",
    };

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Coupon/Coupondiscountamount/",
      postData
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          coupon_name = response.data.data.coupon_name;
          couponDiscount = response.data.data.discount;
          totDiscountAmount = response.data.data.totdiscountamount;
          this.state.totDiscountAmount = totDiscountAmount;
          couponId = response.data.data.id;
          payableAmount = response.data.data.totamount;
          totalsavings = this.state.totalProductsDiscount;
          totalsavings =
            parseFloat(totDiscountAmount) + parseFloat(totalsavings);
          payableAmount =
            parseFloat(this.state.totalPaidAmt) - parseFloat(totalsaveAmount);
          totalsavings = this.state.totalDiscount;
          totalsavings =
            parseFloat(totDiscountAmount) + parseFloat(totalsavings);
          totalsaveAmount =
            parseFloat(this.state.totalProductsDiscount) +
            parseFloat(totDiscountAmount);
          totpayableAmount =
            parseFloat(this.state.totalPaidAmt) - parseFloat(totDiscountAmount);
          this.setState({
            //code: response.data.message,
            couponId: couponId,
            totalPaidAmt: totpayableAmount,
            couponDiscount: totDiscountAmount,
            totalsavingsAmount: totalsaveAmount,
            appliedCoupon: coupon_name,
            coupan_name: coupon_name,
          });
          toast.success(response.data.message);
          $("#coupon_modal").hide("slow");
          $("#remove_coupon").show();
        } else {
          this.setState({
            //code: response.data.message,
            totalPaidAmt: this.state.totalPaidAmt,
            couponDiscount: 0,
            couponId: "",
            coupan_name: "",
            totalProductsDiscount: this.state.totalProductsDiscount,
          });
          toast.error(response.data.message);
          $("#coupon_modal").hide("slow");
        }
      })
      .catch((error) => {
        toast.error(error);
        this.setState({
          code: "Invalid Coupon",
          totalPaidAmt: this.state.totalPaidAmt,
          couponDiscount: 0,
        });
      });
    // alert(this.state.toBePaid);
  };

  removeCoupon = () => {
    var totalsaveAmount = "";
    totalsaveAmount = parseFloat(this.state.totalProductsDiscount);
    this.setState({
      totalPaidAmt:
        this.state.totalPaidAmt +
        parseInt(Math.round(this.state.couponDiscount)),
      couponDiscount: 0,
      totalsavingsAmount: totalsaveAmount,

      couponId: "",
      coupan_name: "",
      appliedCoupon: "Apply Coupon",
    });
    $("#remove_coupon").hide();
    toast.success("Coupon removed successfully");
    this.forceUpdate();
  };

  //Render delivery address in slider
  familyMembersRendering = () => {
    this.state.allFamilyMembersForHtmlRendering =
      this.state.allFamilyMembersArray.map((item) => {
        return (
          <div class="sample_box mt-3" key={item.id}>
            <div class="family family_list">
              <input
                type="radio"
                name="family"
                id={item.id}
                onChange={this.handleFamiyChange}
                checked={this.state.familyMember.id === item.id}
              />
              <h3>
                {item.name}({item.master_relationship.relation_name})
              </h3>
            </div>
            <div className="gender_family">
              <h6>
                {""} | {item.gender}
              </h6>
            </div>
          </div>
        );
      });
    this.forceUpdate();
  };
  // For getting the latlong from address
  getLatLangfromAddress = (enteredAddress) => {
    return new Promise((resolve, reject) => {
      var latitude = "";
      var longitude = "";
      var url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        enteredAddress +
        "&key=" +
        Constant.googleAPiKey;
      $.getJSON(url, function (data, textStatus) {
        var responseStatus = data.status;
        if (responseStatus === "OK") {
          latitude = data.results[0].geometry.location.lat;
          longitude = data.results[0].geometry.location.lng;
          localStorage.setItem("address_latitude", latitude);
          localStorage.setItem("address_longitude", longitude);
          resolve();
        }
      });

      let address_latitude = localStorage.getItem("address_latitude");
      let address_longitude = localStorage.getItem("address_longitude");

      if (address_latitude && address_longitude) {
        this.state.fields.latitude = address_latitude;
        this.state.fields.longitude = address_longitude;
        this.state.fields.country_name = "India";
        this.forceUpdate();
        // this.setState({
        //   fields: {
        //     latitude: address_latitude,
        //     longitude: address_longitude,
        //   },
        // });

        setTimeout(function () {
          localStorage.removeItem("address_latitude");
          localStorage.removeItem("address_longitude");
        }, 2000);
      }
    });
  };
  // For getting the state information

  getCompleteStates() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Country/states/" + Constant.countryID
    )
      .then((response) => {
        this.setState({
          indianStates: response.data.data,
        });
      })
      .catch((error) => {});
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    console.log("state", fields);
    fields[field] = event.target.value;

    if (field == "stateName") {
      this.getCitiesbasedonStateId(event.target.value);
    }
    this.setState({ fields });
  };

  // Family adding form changes the field
  handleFamilyChange = (field, event) => {
    let familyfields = this.state.familyfields;
    familyfields[field] = event.target.value;

    this.setState({ familyfields });
  };

  // For getting the state id based on citi
  getCitiesbasedonStateId(stateId) {
    stateId = Constant.stateId;
    Httpconfig.httptokenget(Constant.siteurl + "api/Country/cities/" + stateId)
      .then((response) => {
        this.setState({
          indianCities: response.data.data,
        });
      })
      .catch((error) => {});
  }

  //  For submitting the form
  checkSubmit(event) {
    event.preventDefault();
    let isEditEnabled = this.state.fields.isEdit;
    if (this.handleValidation()) {
      if (isEditEnabled) {
        this.updateAddressModule(event);
      } else {
        this.createAddressModule(event);
      }
    }
  }

  // Family members form submission
  checkFamilySubmit(event) {
    event.preventDefault();
    let isEditEnabled = this.state.familyfields.isFamilyEdit;
    if (this.handleFamilyValidation()) {
      if (isEditEnabled) {
        this.updatePatient(event);
      } else {
        if (this.state.allFamilyMembersArray.length < 6) {
          this.createPatient(event);
        } else {
          toast.error("You can't add more than 6 family members");
        }
      }
    }
  }
  handleFamilyValidation() {
    let fields = this.state.familyfields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Name cannot be empty";
    }
    if (!fields["mobile_no"]) {
      formIsValid = false;
      errors["mobile_no"] = "Mobile No cannot be empty";
    }
    if (!fields["gender_type"]) {
      formIsValid = false;
      errors["gender_type"] = "gender cannot be empty";
    }
    if (!fields["user_dob"]) {
      formIsValid = false;
      errors["user_dob"] = "DOB cannot be empty";
    }
    if (!fields["user_relation"]) {
      formIsValid = false;
      errors["user_relation"] = "Relation cannot be empty";
    }
    this.setState({ familyErrors: errors });
    return formIsValid;
  }

  createPatient = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    var parts = this.state.familyfields["user_dob"].split("-");
    // var mydate = parts[2] + "-" + parts[1] + "-" + parts[0];
    let url_path =
      "api/Patients/updatepatient/" + this.state.userData.patient_id;
    Httpconfig.httptokenput(Constant.siteurl + url_path, {
      name: this.state.familyfields["username"],
      phone_number: this.state.familyfields["mobile_no"],
      // dob: mydate + "T07:03:03.000Z",
      dob: this.state.familyfields["user_dob"],
      gender: this.state.familyfields["gender_type"],
      page_type: "basicinfor",
      user_type: "patient",
      clinicId: this.state.userData.clinic_id,
      relation: this.state.familyfields["user_relation"],
      relation_patient_id: this.state.userData.patient_id,
    })
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("👌" + response.data.message, {
            position: "bottom-center",
          });
          this.setState({
            familyfields: {
              username: "",
              mobile_no: "",
              gender_type: "",
              user_dob: "",
              user_relation: "",
            },
            addFamilyStatus: true,
          });
          $("#family_modal").hide("slow");
          this.getPatientRelations();
        } else {
          toast.error("Mobile number already associate with us!", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  updatePatient = (event) => {
    // alert("Update");
    event.preventDefault();
    const { fields, errors } = this.state;
    var parts = this.state.fields["user_dob"].split("-");
    var mydate = parts[2] + "-" + parts[1] + "-" + parts[0];
    let url_path = "api/Patients/updatepatient/" + this.state.selectedPatientId;
    Httpconfig.httptokenput(Constant.siteurl + url_path, {
      name: this.state.fields["user_name"],
      phone_number: this.state.fields["user_mobilenumber"],
      dob: mydate + "T07:03:03.000Z",
      gender: this.state.fields["user_gender"],
      relation: this.state.fields["user_relation"],
      relation_patient_id: this.state.patient_id,
    })
      .then((response) => {
        toast.success("👌" + response.data.message, {
          position: "bottom-center",
        });
        //localStorage.setItem('currentMemberId',this.state.selectedPatientId);
        ls.set("currentMemberIdEnc", this.state.selectedPatientId);
        setTimeout(
          () => this.props.history.push("/patienthealthprofile"),
          2000
        );
      })
      .catch((error) => {
        this.props.history.push("/patienthealthprofile");
        console.log(error);
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Name cannot be empty";
    }
    if (!fields["mobile_no"]) {
      formIsValid = false;
      errors["mobile_no"] = "Mobile No cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["address_type"]) {
      formIsValid = false;
      errors["address_type"] = "Address type cannot be empty";
    }
    if (!fields["zipcode"]) {
      formIsValid = false;
      errors["zipcode"] = "Pincode cannot be empty";
    }
    // if (!fields["stateName"]) {
    //   formIsValid = false;
    //   errors["statename"] = "State name cannot be empty";
    // }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // creates address Module
  createAddressModule = async (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
    let enteredAddress = $('textarea[name="address"]').val();
    await this.getLatLangfromAddress(address);
    let address_latitude = localStorage.getItem("address_latitude");
    let address_longitude = localStorage.getItem("address_longitude");
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/deliveryAddress",
      {
        patient_id: this.state.userData.patient_id,
        name: fields["username"],
        mobile_no: fields["mobile_no"],
        // location: fields["location"] ? fields["location"] : "",
        location: fields["address"],
        address: fields["address"],
        city: fields["city"],
        state: fields["stateName"],
        country: fields["country_name"],
        zipcode: fields["zipcode"],
        landmark: fields["landmark"],
        // latitude: address_latitude ? address_latitude : "",
        // longitude: address_longitude ? address_longitude : "",
        latitude: this.state.fields.latitude ? this.state.fields.latitude : "",
        longitude: this.state.fields.longitude
          ? this.state.fields.latitude
          : "",
        address_type: fields["address_type"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success(response.data.message);
          this.state.deliveryAddress = true;
          this.state.addAddressStatus = true;
          this.getDeliveryAddress();
          if (!response.data.message) {
            this.state.fields = {
              username: "",
              mobile_no: "",
              gender_type: "",
              user_dob: "",
              user_relation: "",
              isFamilyEdit: false,
              location: "",
              address: "",
              city: "",
              stateName: "",
              country_name: "",
              zipcode: "",
              landmark: "",
              address_type: "",
              latitude: "",
              longitude: "",
            };
          }

          this.forceUpdate();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateAddressModule = async (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    console.log("--1650--", fields["latitude"]);
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
    await this.getLatLangfromAddress(address);
    Httpconfig.httptokenput(
      Constant.siteurl +
        "api/DIAGNOSIS/deliveryAddress/" +
        this.state.editableAddressId,
      {
        patient_id: this.state.userData.patient_id,
        name: fields["username"],
        mobile_no: fields["mobile_no"],
        location: fields["location"] ? fields["location"] : "",
        address: fields["address"],
        city: fields["city"],
        state: fields["stateName"],
        country: fields["country_name"],
        zipcode: fields["zipcode"],
        landmark: fields["landmark"],
        latitude: this.state.fields.latitude ? this.state.fields.latitude : "",
        longitude: this.state.fields.longitude
          ? this.state.fields.latitude
          : "",
        address_type: fields["address_type"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          //toast.success("Successfully Updated Manage Address");
          toast.success(response.data.message);
          this.state.addAddressStatus = true;
          this.state.familyfields = "";
          this.forceUpdate();
          this.getDeliveryAddress();
          if (!response.data.message) {
            this.state.fields = {
              username: "",
              mobile_no: "",
              gender_type: "",
              user_dob: "",
              user_relation: "",
              isFamilyEdit: false,
              location: "",
              address: "",
              city: "",
              stateName: "",
              country_name: "",
              zipcode: "",
              landmark: "",
              address_type: "",
            };
          }
          this.forceUpdate();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For updating the address
  editAddressBox = (addressId) => {
    let selectedEditAddress = this.state.allAddressesArray.filter((address) => {
      return address.id == addressId;
    });
    this.getCitiesbasedonStateId(selectedEditAddress[0].state);
    this.setState({
      fields: {
        username: selectedEditAddress[0].name,
        mobile_no: selectedEditAddress[0].mobile_no,
        location: selectedEditAddress[0].location,
        address: selectedEditAddress[0].address,
        landmark: selectedEditAddress[0].landmark,
        city: selectedEditAddress[0].city,
        stateName: selectedEditAddress[0].state,
        country_name: selectedEditAddress[0].country,
        zipcode: selectedEditAddress[0].zipcode,
        address_type: selectedEditAddress[0].address_type,
        isEdit: true,
      },
      addAddressStatus: false,
      editableAddressId: addressId,
    });
  };

  // For removing the address
  removeAddressBox = (addressId, name) => {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/DIAGNOSIS/deliveryAddress/" + addressId
      )
        .then((response) => {
          toast.dismiss();
          toast.success("Successfully Deleted Manage Address");
          this.getDeliveryAddress();
        })
        .catch((error) => {});
    }
  };

  //Render delivery address in slider
  deliveryAddressRendering = () => {
    this.state.allAddressesForHtmlRendering = this.state.allAddressesArray.map(
      (item) => {
        return (
          <div class="sample_box mt-3" key={item.id}>
            <div class="address address_default">
              <div className="address_select_radio">
                <input
                  type="radio"
                  name="address"
                  id={item.id}
                  onChange={this.handleAddressChange}
                  checked={this.state.deliveryAddress.id === item.id}
                />
              </div>
              <div className="address_detail">
                <h3>{item.name}</h3>
                <h6> {item.address}</h6>
                <h6>
                  {item.city} - {item.zipcode}
                </h6>
                <h6>{item.mobile_no ? item.mobile_no : ""}</h6>
              </div>
            </div>
            <div className="address_btn">
              <div className="address_edit">
                <button onClick={this.editAddressBox.bind(this, item.id)}>
                  <img src="./images/patient/img/diagnostics/edit.svg"></img>
                  Edit
                </button>
              </div>
              <div className="address_delete">
                <button
                  onClick={this.removeAddressBox.bind(this, item.id, item.name)}
                >
                  <img src="./images/patient/img/diagnostics/delete.svg"></img>
                  Delete
                </button>
              </div>
            </div>
          </div>
        );
      }
    );
    this.forceUpdate();
    // console.log(
    //   "allAddressesForHtmlRendering",
    //   this.state.allAddressesForHtmlRendering
    // );
  };

  //Generating time slots
  getTimeSlots(startTime, noOfiterations) {
    var startTime = moment(startTime, "HH:mm");
    var timeSlots = [];
    let i = 0;
    while (i < noOfiterations) {
      let start = new moment(startTime).format("hh:mm A");
      startTime.add(60, "minutes");
      let end = new moment(startTime).format("hh:mm A");
      timeSlots.push(`${start} - ${end}`);
      i++;
    }
    return timeSlots;
  }

  //order process
  orderProcess = () => {
    toast.dismiss();
    if (this.state.cartItems.length === 0) {
      toast.error("No Test(s) found in the cart");
      return;
    } else if (!this.state.deliveryType) {
      toast.error("please select Mode Of Service");
      return;
    } else if (!this.state.paymentMethod) {
      toast.error("please select Payment Mode ");
      return;
    }
    if (!this.state.deliveryAddress) {
      toast.error("Please select delivery address");
      return;
    }
    if (!this.state.slotBookingDate) {
      toast.error("Please select slot Date");
      return;
    }
    if (!this.state.slotBookingTime) {
      toast.error("please select slot time");
      return;
    } else {
      let selectedStartTime = this.state.slotBookingTime.split(":")[0];
      let splitTime = this.state.slotBookingTime.split(" ");
      let selectedAmPm = splitTime[splitTime.length - 1];
      // let hours = moment(selectedStartTime + " " + selectedAmPm, [
      //   "h:mm A",
      // ]).format("HH");
      let hours = moment(splitTime[3] + " " + selectedAmPm, ["h:mm A"]).format(
        "HH"
      );
      let addedHoursToSelectedDate = moment(this.state.slotBookingDate).add(
        hours,
        "hours"
      );
      let currentDateTime = new Date(moment().format("YYYY-MM-DD HH:mm:ss"));
      let selectedSlotDateTime = new Date(addedHoursToSelectedDate);
      if (currentDateTime >= selectedSlotDateTime) {
        toast.error("selected slot time and date are not available");
        return;
      }
    }
    if (this.state.paymentMethod === "COD") {
      this.setState({ checkOutBtnisDisabled: true });
      this.conformOrder();
    } else {
      this.openCheckout();
    }
  };

  // For handling address
  handleAddressChange = (event) => {
    let addressId = event.currentTarget.id;
    let selectedAddress = this.state.allAddressesArray.filter((address) => {
      return address.id == addressId;
    });
    this.state.deliveryAddress = selectedAddress[0];
    this.state.pincodeMask = "";
    $("#address_modal").hide("slow");
    this.deliveryAddressRendering();
    this.forceUpdate();
  };

  // To change Family member
  handleFamiyChange = (event) => {
    let familyId = event.currentTarget.id;
    let selectedMember = this.state.allFamilyMembersArray.filter((family) => {
      return family.id == familyId;
    });
    this.state.familyMember = selectedMember[0];
    this.state.familyMemberName = selectedMember[0]["name"];
    this.familyMembersRendering();
    this.forceUpdate();
  };

  // Verify Coupon Code
  handleCode = (event) => {
    this.setState({ coupon: event.target.value.toUpperCase() });
    if (event.target.value != "") {
      this.setState({ code: "" });
    }
  };

  //Razorpay payment gateway
  openCheckout = (e) => {
    let that = this;
    let totalPaid = Math.round(that.state.totalPaidAmt); //here round up total amount
    this.setState({ checkOutBtnisDisabled: true });
    let options = {
      key: Constant.razorpay_key_id,
      amount: totalPaid * 100, //this.state.amount, // 2000 paise = INR 20, amount in paisa
      name: "Remotel ",
      description: "Diagnosis",
      image: Constant.siteurl + "/images/patient/img/main-logo.png",
      handler: async function (response) {
        Httpconfig.httptokenpost(
          Constant.siteurl + "api/DIAGNOSIS/orderProcess/rzp/paymentCapture",
          {
            payment_id: response.razorpay_payment_id,
            amount: totalPaid * 100,
            currency: "INR",
          }
        ).then((captureResponse) => {
          that.conformOrder(captureResponse.data.data);
        });
      },
      modal: {
        ondismiss: function () {
          that.setState({ checkOutBtnisDisabled: false });
        },
      },
      prefill: {
        // name: "Rakesh",
        // email: "rakesh.n@medleymed.com",
        // phone: "9652726606",
      },
      notes: {
        address: this.state.address,
      },
      theme: {
        color: "#555AB9",
      },
    };
    let rzp = new window.Razorpay(options);
    //  let rzp = new Razorpay(options);
    rzp.open();
  };

  //saving order data
  conformOrder = (transactionResponse) => {
    let transactionId = transactionResponse ? transactionResponse.id : null;
    let delivery_amt = "";
    if (this.state.deliveryType == "home_visit") {
      delivery_amt = parseFloat(this.state.sampleCollectedCharges);
    } else {
      delivery_amt = 0;
    }
    let orderData = {
      retailer_id: this.state.retailerStockData[0].retailer_id,
      patient_id: this.state.deliveryAddress.patient_id,
      patient_address_id: this.state.deliveryAddress.id,
      relative_id: this.state.familyMember.id,
      cart_level_discount: this.state.totalProductsDiscount,
      delivery_charges: delivery_amt,
      net_amount: this.state.totalNetAmt,
      payable_amount: this.state.totalPaidAmt,
      coupan_id: this.state.couponId,
      coupan_name: this.state.coupan_name,
      coupan_value: "",
      coupon_amount: this.state.couponDiscount,
      prescription: "",
      order_details: this.state.retailerStockData,
      discount: this.state.totalProductsDiscount,
      delivery_agent: "",
      cart_id: this.state.cartId,
      patientEmail: this.state.deliveryAddress.patient_tbl.tbl_user.email,
      timeZone: moment.tz.guess(),
      transaction_id: transactionId,
      deliveryType: this.state.deliveryType,
      paymentMethod: this.state.paymentMethod,
      slotBookingDate: this.state.slotBookingDate,
      slotBookingTime: this.state.slotBookingTime,
      totwallet_amount: null,
    };

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess",
      orderData
    ).then((response) => {
      console.log(response);
      if (response.data.status == 200 && !response.data.error) {
        ls.set("cartIdEnc", "");
        ls.remove("orderdetails");
        ls.remove("orderResubmitCount");
        ls.remove("order_unique_id");
        ls.remove("selectedAddress");
        window.location.href = Constant.imgurl + "/Diagnosticorderplaced";
      } else {
        this.setState({ checkOutBtnisDisabled: false });
      }
    });
  };

  //Removing cart items
  removeCartItems = (e) => {
    let cartItemId = e.currentTarget.id;
    Httpconfig.httptokendelete(
      Constant.siteurl + "api/DIAGNOSIS/Cart/" + cartItemId
    )
      .then((response) => {
        if (response.data.status === 200 && !response.data.error) {
          toast.dismiss();
          toast.success(response.data.message);
          this.getCartData();
          this.setState({
            couponDiscount: 0,
            couponId: "",
            coupan_name: "",
            appliedCoupon: "Apply Coupon",
          });
          $("#remove_coupon").hide();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchAddress = (search_address) => {
    this.setState({ detected_address: search_address });
  };

  // show popup dialog
  showDialog = (type) => {
    if (type == "search") {
      this.setState({
        dialogText: "Please enter area / pincode",
      });
    } else {
      this.setState({
        dialogText: "Our Services are not available in given area / pincode",
      });
    }
    $("#noloc_Modal").show();
  };

  //close postal code popup
  closePopup = () => {
    this.setState({ detected_address: "", search_address: "" });
    ls.remove("detected_address");
    ls.remove("detected_addressEnc");
    ls.remove("pinCodeEnc");
    $("#noloc_Modal").hide();
  };
  // update Terms and conditions
  handleUpdate = () => {
    this.setState({ checked: !this.state.checked });
    if (this.state.checked) {
      this.setState({ termsconditions: "" });
    } else {
      this.setState({ termsconditions: "" });
    }
  };

  // check the terms and conditons accepted
  checkTermsConditions = () => {
    if (this.state.checked) {
      this.setState({ termsconditions: "" });
      this.state.amount = 100 / 100;
      this.orderProcess();
      return;
      this.state.confirm = 1;
    } else {
      this.setState({ termsconditions: "Please check the Terms & Conditions" });
    }
  };

  render() {
    return (
      <main id="main">
        <PatientHeader />
        <I18nPropvider locale={this.state.Language}>
          <section id="order_medicine_section">
            <div class="order_search">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <PlacesAutocomplete
                      value={
                        this.state.detected_address
                          ? this.state.detected_address
                          : this.state.search_address
                      }
                      onChange={this.searchAddress}
                      onSelect={this.handleAddressSelect}
                      searchOptions={{
                        componentRestrictions: { country: "IN" },
                      }}
                      shouldFetchSuggestions={
                        this.state.detected_address.length > 3
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="ord_search">
                          <input
                            {...getInputProps({
                              placeholder: "Enter your Area name / pincode",
                              className: "location-search-input order_select",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>

                    <span class="loc_img" onClick={this.getCurrentLocation}>
                      <img src="../images/patient/img/Ordermedicine/locationIcon.svg" />
                    </span>
                  </div>

                  <div class="col-md-7">
                    <Search
                      searchQuery={this.fetchmedicinedata}
                      setSearchQuery={this.state.medicineOptions}
                    />
                  </div>

                  <div class="col-md-2 order_cart" id="all_cart_item">
                    <a href="#" onClick={this.getCartData}>
                      <img
                        class="cart_img"
                        src="./images/patient/img/cart.svg"
                      />
                      <div class="cart_bar">{this.state.cartItemsCount}</div>
                    </a>
                  </div>
                  <div
                    class="col-md-2 order_cart"
                    id="cart_items"
                    style={{ display: "none" }}
                  >
                    <a href="#">
                      <img
                        class="cart_ico"
                        src="../images/patient/img/Ordermedicine/shopping-cart.svg"
                      />
                      <div class="cart_bar">0</div>
                      <span>cart</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="test_detail" class={this.state.searchproductslist}>
            <div className="container">
              <div className="row">
                {this.state.medicineProductsView}
                <div className="col-md-4">
                  <div className="relatedtests_block">
                    <div className="related_header">
                      {/* {this.state.alerternateMedicine != "" ? (
                        <h5>Related Packages</h5>
                      ) : (
                        ""
                      )} */}
                    </div>
                    {this.state.alerternateMedicine}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="order_pro_toast" style={{ display: "none" }}>
            <div class="order_toast_head">
              <h2>Order Summary</h2>
              {/* <div class="toast_amnt">
                <h6>cart price</h6>
                <h3>
                  {Constant.currency}{" "}
                  {this.state.cartTotal
                    ? this.state.cartTotal
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    : ""}
                </h3>
              </div> */}
            </div>
            <div class="order_toast_content">
              {this.state.cartDetailsPopup}
              <h2>
                +<span>{this.state.cartItemsCount}</span>Items
              </h2>
              <div class="toast_cart_btn">
                <a href="#" onClick={this.getCartData}>
                  <p>Go to Cart</p>
                </a>
              </div>
            </div>
          </div>
          <section id="diag_cart" class={this.state.home}>
            <div class="container">
              {/* <div class="row">
                <div class="col-md-12">
                  <div class="diagnostics_breadcrumbs">
                    <ul class="breadcrumbs_list">
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#" className="active">
                          Popular Tests
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div class="row">
                <div class="col-md-12">
                  <div class="main_heading">
                    <h2>
                      Cart Item(s): <span>{this.state.totalNumberOfTests}</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div class="cart_main">
                    {this.state.cartItems.length
                      ? this.state.cartItems
                      : "Your cart is empty!"}
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="choose_section">
                    <div class="pay_title">
                      <h2>Mode of Service</h2>
                    </div>
                    <div class=" pat_visit">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="radio"
                              name="delivery_type"
                              value="center_visit"
                              class="form-control"
                              onClick={this.updatedeliveTypeId}
                              // onClick={(e) => {
                              //   this.state.deliveryType = e.target.value;
                              // }}
                            />
                            <label className="visit_label">Center Visit</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="radio"
                              name="delivery_type"
                              class="form-control"
                              value="home_visit"
                              onClick={this.updatedeliveTypeId}
                              // onClick={(e) => {
                              //   this.state.deliveryType = e.target.value;
                              // }}
                            />
                            <label className="visit_label">Home Visit</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="coupon_main"
                    data-toggle="modal"
                    data-target="#family_modal"
                  >
                    <span class="add_new_family_btn">
                      <p class="before_coupon">
                        <img
                          class="coupon-icon"
                          src="./images/patient/img/diagnostics/useraddress.svg"
                        />
                        {this.state.familyMemberName}
                        <img
                          class="arrow-icon"
                          src="./images/patient/img/blue-arrowright.svg"
                        />
                      </p>
                    </span>
                  </div>

                  {this.state.allAddressesArray.length &&
                  this.state.pincodeMask == "" ? (
                    this.state.deliveryAddress.patient_tbl ? (
                      <div class="sample_box">
                        <div class="heading">
                          <h2>
                            Sample Collection Address
                            <span
                              data-toggle="modal"
                              data-target="#address_modal"
                              class="add_new_address_btn"
                            >
                              Change
                            </span>
                          </h2>
                        </div>
                        <div class="address">
                          <h3>{this.state.deliveryAddress.name}</h3>
                          <h6> {this.state.deliveryAddress.address}</h6>
                          <h6>
                            {this.state.deliveryAddress.city} -{" "}
                            {this.state.deliveryAddress.zipcode}
                          </h6>
                          <h6>
                            {this.state.deliveryAddress.mobile_no
                              ? this.state.deliveryAddress.mobile_no
                              : ""}
                          </h6>
                        </div>
                      </div>
                    ) : (
                      <div class="sample_box">
                        <div class="heading">
                          <h2>
                            No Address Found!
                            <span
                              data-toggle="modal"
                              data-target="#address_modal"
                              class="add_new_address_btn"
                            >
                              Add New
                            </span>
                          </h2>
                        </div>
                      </div>
                    )
                  ) : (
                    <div class="sample_box">
                      <div class="heading">
                        <h2>
                          Selected Address
                          <span
                            data-toggle="modal"
                            data-target="#address_modal"
                            class="add_new_address_btn"
                          >
                            Save
                          </span>
                        </h2>
                      </div>
                      <div class="address">
                        <h3>{this.state.detected_address}</h3>
                      </div>
                    </div>
                  )}
                  <div class="choose_section">
                    <div class="pay_title">
                      <h2>Payment Mode</h2>
                    </div>
                    <div class="pat_visit">
                      <div class="row ">
                        <div class="col-md-12">
                          <div class="form-group">
                            <input
                              type="radio"
                              name="paymentType"
                              value="COD"
                              class="form-control"
                              onClick={(e) => {
                                this.state.paymentMethod = e.target.value;
                              }}
                            />
                            <label className="booking_input">
                              Cash On Delivery (COD)
                            </label>
                          </div>
                        </div>
                        {/* <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="radio"
                              name="paymentType"
                              class="form-control"
                              value="online"
                              onClick={(e) => {
                                this.state.paymentMethod = e.target.value;
                              }}
                            />
                            <label className="booking_input">Online</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="choose_section">
                    <div class="form-group">
                      <label>Choose Date:</label>
                      <input
                        type="date"
                        class="form-control"
                        max={moment().add(30, "days").format("YYYY-MM-DD")}
                        min={moment().format("YYYY-MM-DD")}
                        onChange={(e) => {
                          this.state.slotBookingDate = e.currentTarget.value;
                        }}
                      />
                    </div>

                    <div class="form-group">
                      <label>Time Slot:</label>
                      <select
                        name="slot_time"
                        id="cars"
                        className="form-control"
                        onChange={(e) => {
                          this.state.slotBookingTime = e.target.value;
                        }}
                      >
                        <option value="volvo">Select Time</option>

                        <optgroup label="Early Morning">
                          {this.getTimeSlots("07:00", 3).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>

                        <optgroup label="Morning">
                          {this.getTimeSlots("10:00", 2).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>

                        <optgroup label="Afternoon">
                          {this.getTimeSlots("12:00", 3).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>

                        <optgroup label="Evening">
                          {this.getTimeSlots("15:00", 3).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>
                      </select>
                    </div>

                    {/* <div class="form-group">
                      <label>Time Slot:</label>

                      <select
                        onChange={(e) => {
                          this.state.slotBookingTime = e.target.value;
                        }}
                        name="slot_time"
                        defaultValue=""
                        className="form-control"
                      >
                        <option value="" disabled={true}>
                          Select Time
                        </option>
                        {this.getTimeSlots().map((times) => {
                          return <option value={times}>{times}</option>;
                        })}
                      </select>
                    </div> */}
                  </div>
                  <div class="coupon_main">
                    <span
                      data-toggle="modal"
                      data-target="#coupon_modal"
                      class="add_new_coupon_btn"
                    >
                      <p class="before_coupon">
                        <img
                          class="coupon-icon"
                          src="./images/patient/img/coupon.svg"
                        />
                        {this.state.appliedCoupon}

                        <img
                          style={{ display: "none" }}
                          onClick={this.removeCoupon}
                          id="remove_coupon"
                          class="close_icon"
                          src="./images/patient/img/close-circle.svg"
                        />
                        <img
                          class="arrow-icon"
                          src="./images/patient/img/blue-arrowright.svg"
                        />
                      </p>
                    </span>
                    {/* <p class="after_coupon"><img class="coupon-icon" src="./images/patient/img/coupon.svg" />
                      FIRSTTEST
                      <img class="close-icon" src="./images/patient/img/close-circle.svg" /></p>  */}
                  </div>
                  <div class="test_view">
                    <div class="test_list">
                      <h2>No. of Tests </h2>
                      <p>{this.state.totalNumberOfTests}</p>
                    </div>
                    <div class="test_list">
                      <h2>Value</h2>
                      <p>₹ {parseFloat(this.state.totalValue).toFixed(2)}</p>
                    </div>
                    <div class="test_list">
                      <h2>Discount</h2>
                      <p>
                        - ₹{" "}
                        {parseFloat(this.state.totalProductsDiscount).toFixed(
                          2
                        )}
                      </p>
                    </div>
                    <div class="test_list">
                      <h2>Coupon Applied</h2>
                      <p>
                        - ₹ {parseFloat(this.state.couponDiscount).toFixed(2)}
                      </p>
                    </div>
                    <div
                      class="test_list"
                      id="sample_charge"
                      style={{ display: "none" }}
                    >
                      <h2>Sample Collection Charges</h2>
                      <p>
                        {" "}
                        {this.state.sampleCollectedCharges
                          ? Constant.currency +
                            "" +
                            parseFloat(
                              this.state.sampleCollectedCharges.replaceAll(
                                ",",
                                ""
                              )
                            ).toFixed(2)
                          : 0}
                      </p>
                    </div>
                    <div class="total_amount">
                      <h2>Total Amount</h2>
                      <p>
                        ₹{" "}
                        {parseFloat(
                          // Math.round(this.state.totalPaidAmt)
                          this.state.totalPaidAmt
                        ).toFixed(2)}
                      </p>
                    </div>
                    {/* <p style={{ fontSize: "13px" }}>
                      Final Amount : ₹
                      {parseFloat(this.state.totalPaidAmt).toFixed(2)}
                    </p> */}
                    {/* <p style={{ fontSize: "13px" }}>
                      Rounded Amount : ₹ (
                      {this.state.totalPaidAmt
                        ? parseFloat(this.state.totalPaidAmt).toFixed(2) -
                          parseFloat(
                            Math.floor(this.state.totalPaidAmt)
                          ).toFixed(2)
                        : "0"}
                      )
                    </p> */}
                    <div class="total_save">
                      <h2>Total Savings</h2>
                      <p>
                        ₹ {parseFloat(this.state.totalsavingsAmount).toFixed(2)}
                      </p>
                    </div>
                  </div>

                  <div class="t-c">
                  {/* <p> */}
                        <input
                          type="checkbox"
                          class="fee_check"
                          defaultChecked={this.state.checked}
                          onClick={this.handleUpdate}
                        />{" "}
                       
                      {/* </p> */}
                    <a href="./patient/TermsConditions" target="_blank">
                    {translate("Accept Terms and conditions")}
                    </a>
                    <span className="cRed">{this.state.termsconditions}</span>
                  </div>
                  <div class="proceed_btn">
                    <button
                      type="button"
                      // onClick={this.orderProcess}
                      onClick={this.checkTermsConditions}
                      disabled={this.state.checkOutBtnisDisabled}
                    >
                      {this.state.checkOutBtnisDisabled
                        ? "Loading....."
                        : "Proceed to Check Out"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------address side menu---------- */}
            <div
              class="modal right fade in"
              id="address_modal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header header_address">
                    <h4 class="modal-title add_new_address">
                      Delivery Address
                    </h4>

                    <img
                      data-dismiss="modal"
                      src="./images/patient/img/close-circle.svg"
                    />
                  </div>
                  <div class="modal-body">
                    <div class="sample_box mt-3">
                      <div class="address">
                        <h4
                          onClick={(e) => {
                            this.state.addAddressStatus =
                              !this.state.addAddressStatus;
                            this.forceUpdate();
                          }}
                        >
                          {this.state.addAddressStatus
                            ? "Add New Address"
                            : "View Address List"}
                        </h4>
                      </div>
                    </div>

                    {this.state.addAddressStatus ? (
                      this.state.allAddressesForHtmlRendering ? (
                        this.state.allAddressesForHtmlRendering
                      ) : (
                        "No Data Avalable"
                      )
                    ) : (
                      <form
                        onSubmit={this.checkSubmit.bind(this)}
                        id="address-form"
                      >
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <label>New User</label>
                              <input
                                type="text"
                                name="username"
                                value={this.state.fields.username}
                                className="form-control new_address_input"
                                placeholder="Name"
                                onChange={this.handleChange.bind(
                                  this,
                                  "username"
                                )}
                              />
                              {/* <span className="cRed">
                                {this.state.errors["username"]}
                              </span> */}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-12">
                              <span>
                                <label
                                  data-toggle="modal"
                                  data-target="#loading_modal"
                                >
                                  +91
                                </label>
                                <input
                                  type="text"
                                  name="mobile_no"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={this.state.fields["mobile_no"]}
                                  className="form-control new_address_input"
                                  minLength="10"
                                  maxLength="10"
                                  placeholder="Mobile number"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "mobile_no"
                                  )}
                                />
                              </span>
                              <span className="cRed">
                                {this.state.errors["mobile_no"]}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Complete Address</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            name="address"
                            value={this.state.fields["address"]}
                            placeholder="Enter Full address"
                            onChange={this.handleChange.bind(this, "address")}
                            // onBlur={() => this.getLatLangfromAddress.bind(this)}
                          ></textarea>

                          <span className="cRed">
                            {this.state.errors["address"]}
                          </span>
                        </div>
                        <div className="form-group">
                          <label>
                            Land Mark<span>(Optional)</span>
                          </label>
                          <input
                            type="text"
                            className="form-control new_address_input"
                            name="landmark"
                            value={this.state.fields["landmark"]}
                            placeholder="Enter Landmark"
                            onChange={this.handleChange.bind(this, "landmark")}
                          />
                          <span className="cRed">
                            {this.state.errors["landmark"]}
                          </span>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>State</label>
                              {/* <input
                                          type="text"
                                          name="statename"
                                          value={this.state.fields.statename}
                                          className="form-control"
                                          placeholder="Enetr state name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "statename"
                                          )}
                                        /> */}
                              <select
                                name="statename"
                                id="state-dropdown"
                                className="form-control new_address_input"
                                value={this.state.fields.stateName}
                                onChange={this.handleChange.bind(
                                  this,
                                  "stateName"
                                )}
                              >
                                <option value="">Select State</option>
                                {this.state.indianStates.map((stateList, i) => {
                                  return (
                                    <option value={stateList.id}>
                                      {stateList.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <span className="cRed">
                                {this.state.errors["statename"]}
                              </span>
                            </div>

                            <div className="col-md-6">
                              <label>City</label>
                              {/* <input
                                          type="text"
                                          name="city"
                                          value={this.state.fields.city}
                                          className="form-control"
                                          placeholder="Enter city name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "city"
                                          )}
                                        /> */}
                              <select
                                name="city"
                                className="form-control new_address_input"
                                value={this.state.fields.city}
                                onChange={this.handleChange.bind(this, "city")}
                              >
                                <option>Select City</option>
                                {this.state.indianCities.map((cityData, i) => {
                                  return (
                                    <option value={cityData.name}>
                                      {cityData.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <span className="cRed">
                                {this.state.errors["city"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <label>Pincode</label>
                              <input
                                type="text"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={6}
                                name="zipcode"
                                value={this.state.fields.zipcode}
                                className="form-control new_address_input"
                                placeholder="Enter Pincode"
                                onChange={this.handleChange.bind(
                                  this,
                                  "zipcode"
                                )}
                              />
                              <span className="cRed">
                                {this.state.errors["zipcode"]}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <label>Country</label>
                              <select
                                name="country_name"
                                className="form-control"
                                value={this.state.fields.country_name}
                                onChange={this.handleChange.bind(
                                  this,
                                  "country_name"
                                )}
                              >
                                <option>
                                  {this.state.fields.country_name}
                                </option>

                                {/* {this.state.countryData &&
                                            this.state.countryData.map(
                                              (countryData, i) => {
                                                return (
                                                  <option
                                                    value={countryData.name}
                                                  >
                                                    {countryData.name}
                                                  </option>
                                                );
                                              }
                                            )} */}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group"
                          onChange={this.handleChange.bind(
                            this,
                            "address_type"
                          )}
                        >
                          <label className="radio-inline address_radio">
                            <input
                              type="radio"
                              name="address_type"
                              value="Home"
                              checked={
                                this.state.fields["address_type"] === "Home"
                              }
                            />
                            Home
                          </label>
                          <label className="radio-inline address_radio">
                            <input
                              type="radio"
                              name="address_type"
                              value="Work"
                              checked={
                                this.state.fields["address_type"] === "Work"
                              }
                            />
                            Work
                          </label>
                          <label className="radio-inline address_radio">
                            <input
                              type="radio"
                              name="address_type"
                              value="Other"
                              checked={
                                this.state.fields["address_type"] === "Other"
                              }
                            />
                            Other
                          </label>
                          <span className="cRed">
                            {this.state.errors["address_type"]}
                          </span>
                        </div>

                        <div className="new_address">
                          <button
                            type="submit"
                            className="btn  btn-primary padTopCategorySave "
                          >
                            Save Address
                          </button>{" "}
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* ---------address side menu ends------------- */}

            {/* Add Family Member menu */}
            <div
              class="modal right fade in close_link"
              id="family_modal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header header_address">
                    <h4 class="modal-title add_new_address">
                      Select Family Member
                    </h4>

                    <img
                      data-dismiss="modal"
                      src="./images/patient/img/close-circle.svg"
                    />
                  </div>
                  <div class="modal-body">
                    <div class="sample_box mt-3">
                      <div class="address">
                        <h4
                          onClick={(e) => {
                            this.state.addFamilyStatus =
                              !this.state.addFamilyStatus;
                            this.forceUpdate();
                          }}
                        >
                          Add Family Member
                        </h4>
                      </div>
                    </div>
                    {this.state.addFamilyStatus ? (
                      this.state.allFamilyMembersForHtmlRendering ? (
                        this.state.allFamilyMembersForHtmlRendering
                      ) : (
                        "No Data Avalable"
                      )
                    ) : (
                      <form
                        onSubmit={this.checkFamilySubmit.bind(this)}
                        id="address-form"
                      >
                        <div class="help_info_box">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <label>New User</label>
                                <input
                                  type="text"
                                  name="username"
                                  value={this.state.familyfields.username}
                                  className="form-control address_input"
                                  placeholder="Name"
                                  onChange={this.handleFamilyChange.bind(
                                    this,
                                    "username"
                                  )}
                                />
                                <span className="cRed">
                                  {this.state.familyErrors["username"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-12">
                                <span>
                                  <label
                                    data-toggle="modal"
                                    data-target="#loading_modal"
                                  >
                                    +91
                                  </label>
                                  <input
                                    type="text"
                                    name="mobile_no"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    value={this.state.familyfields["mobile_no"]}
                                    className="form-control address_input"
                                    minLength="10"
                                    maxLength="10"
                                    placeholder="Mobile number"
                                    onChange={this.handleFamilyChange.bind(
                                      this,
                                      "mobile_no"
                                    )}
                                  />
                                </span>
                                <span className="cRed">
                                  {this.state.familyErrors["mobile_no"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>DOB</label>
                            <input
                              type="date"
                              class="form-control address_input"
                              name="user_dob"
                              onChange={this.handleFamilyChange.bind(
                                this,
                                "user_dob"
                              )}
                              max={moment().format("YYYY-MM-DD")}
                            />
                            {/* <i
                              class="fa fa-calendar cal_icon"
                              aria-hidden="true"
                            ></i> */}
                            <span className="cRed">
                              {this.state.familyErrors["user_dob"]}
                            </span>
                          </div>

                          <div class="form-group">
                            <label>Relation</label>
                            <select
                              name="user_relation"
                              className="form-control address_input"
                              value={this.state.familyfields.user_relation}
                              onChange={this.handleFamilyChange.bind(
                                this,
                                "user_relation"
                              )}
                            >
                              <option>Select relation</option>
                              {this.state.userRelations.map(
                                (relationData, i) => {
                                  return (
                                    <option value={relationData.id}>
                                      {relationData.relation_name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                            <span className="cRed">
                              {this.state.familyErrors["user_relation"]}
                            </span>
                          </div>
                          <div
                            className="form-group"
                            onChange={this.handleFamilyChange.bind(
                              this,
                              "gender_type"
                            )}
                          >
                            <label className="radio-inline address_radio">
                              <input
                                type="radio"
                                name="gender_type"
                                value="Male"
                                checked={
                                  this.state.familyfields["gender_type"] ===
                                  "Male"
                                }
                              />
                              Male
                            </label>
                            <label className="radio-inline address_radio">
                              <input
                                type="radio"
                                name="gender_type"
                                value="Female"
                                checked={
                                  this.state.familyfields["gender_type"] ===
                                  "Female"
                                }
                              />
                              Female
                            </label>
                            <label className="radio-inline address_radio">
                              <input
                                type="radio"
                                name="gender_type"
                                value="Other"
                                checked={
                                  this.state.familyfields["gender_type"] ===
                                  "Other"
                                }
                              />
                              Other
                            </label>
                            <span className="cRed">
                              {this.state.familyErrors["gender_type"]}
                            </span>
                          </div>
                        </div>
                        <div className="address_save">
                          <button
                            type="submit"
                            className="btn  btn-primary padTopCategorySave "
                          >
                            Save
                          </button>{" "}
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Coupon apply modal */}
            <div
              class="modal right fade in"
              id="coupon_modal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header header_address">
                    <h4 class="modal-title add_new_address">Select Coupon</h4>

                    <img
                      data-dismiss="modal"
                      src="./images/patient/img/close-circle.svg"
                    />
                  </div>
                  <div class="modal-body">
                    {/* <div class="sample_box mt-3">
                      <div class="address">
                        <h4
                          onClick={(e) => {
                            this.state.addFamilyStatus =
                              !this.state.addFamilyStatus;
                            this.forceUpdate();
                          }}
                        >
                          Add Family Member
                        </h4>
                      </div>
                    </div> */}
                    <reactFragment>
                      <div class="form-group">
                        <div class="input-group">
                          <FormattedMessage id="Enter Your Code">
                            {(placeholder) => (
                              <input
                                type="text"
                                class="form-control code_input coupon_input"
                                value={this.state.coupon}
                                placeholder={placeholder}
                                onChange={this.handleCode}
                              />
                            )}
                          </FormattedMessage>
                          <button
                            className="coupon_apply"
                            onClick={this.applyCoupon.bind(
                              this,
                              this.state.coupon
                            )}
                          >
                            Apply
                          </button>
                          {/* {!this.state.couponDiscount != "" ? (
                            <span
                              class="input-group-addon sel_apply_btn"
                              onClick={this.applyCoupon}
                            >
                              <span>Apply</span>
                            </span>
                          ) : (
                            <span
                              class="input-group-addon app_btn"
                              onClick={this.removeCoupon}
                            >
                              <span>{translate("Remove")}</span>
                            </span>
                          )} */}
                        </div>
                        {/* <span className="cRed">{this.state.code}</span> */}
                      </div>
                    </reactFragment>

                    {this.state.allCouponsHtmlRendering}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* modal for no delivery */}
          <div class="modal fade" id="noloc_Modal">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body">
                  <img
                    class="loc-img"
                    src="../images/patient/img/Ordermedicine/address-book.svg"
                  ></img>
                  <p>{this.state.dialogText}</p>
                  <button
                    type="button"
                    className="close_btn"
                    data-dismiss="modal"
                    onClick={this.closePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* end modal for no delivery */}
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
