import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Form, Button, Dropdown, Grid, Header } from "semantic-ui-react";
import ReactExport from "react-data-export";
import { Link } from "react-router-dom";
import $ from "jquery";

const moment = require("moment");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retailersListData: "",
      selectedStatus: "",
      selectedRetailerId: "",
      searchString: "",
      loadshow: "load_sec collapse-show",
      pageNumber: 1,
      ordersView: [],
      currentOrders: [],
      xlsArray: [],
      Nooftimesused: "",
    };
    this.getAllOrders = this.getAllOrders.bind(this);
  }

  // To get detais after first render ----
  componentDidMount = () => {
    this.fetchRetailerInfo();
    this.getAllOrders();
  };

  // Get the retailers list
  fetchRetailerInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/retailer/")
      .then((response) => {
        let listData = response.data.data;
        let retailersListData = listData.map((item, index) => ({
          key: item.id,
          text: item.storename,
          value: item.id,
        }));
        this.state.retailersListData = retailersListData;

        this.forceUpdate();
      })
      .catch((error) => {
      });
  }

  // On retalier selection
  onRetailerListChange = (event, data) => {
    let selectedretailerValue = data.value;

    if (selectedretailerValue == "") {
      this.state.selectedRetailerId = "";
      this.forceUpdate();
      this.getAllOrders();
    } else {
      const keys = data.options.find(
        (data) => data.value === selectedretailerValue
      );
      const selectedretailer = {
        id: keys.key,
        medicineid: keys.text,
        medicinename: keys.value,
      };
      this.state.selectedRetailerName = keys.text;
      this.state.selectedRetailerId = selectedretailerValue;
      this.forceUpdate();
      this.getAllOrders();
    }
  };

  // get all the orders
  getAllOrders() {
    let retailerId = this.state.selectedRetailerId;

    let finalordersarray = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/RetailerWalletlist/FetchWallertFilters",
      {
        retailerId: retailerId,
      }
    )
      .then((response) => {
        const ordersView = response.data.data.map((LoadedData, num) => {
          if (LoadedData.order_tbls) {
            var numofcount = 0;
            for (var i = 0; i < LoadedData.order_tbls.length; i++) {
              numofcount =
                Number(numofcount) +
                Number(LoadedData.order_tbls[i].wallet_points_deducted);
            }
          }
          finalordersarray.push({
            Sno: num + 1,
            RetialerName: LoadedData.storename,
            wallet_points: LoadedData.wallet_points,
            Nooftimesused: LoadedData.order_tbls.length,
            deduct_points: LoadedData.deduct_points,
            wallet_amount_perpoint: LoadedData.wallet_amount_perpoint,

            status: LoadedData.is_active == 1 ? "Active" : "Inactive",
          });

          return (
            <tr>
              <td>{num + 1}</td>
              <td>
                {LoadedData.storename
                  ? LoadedData.storename.replace(
                      /(?![^\n]{1,32}$)([^\n]{1,32})\s/g,
                      "[$1]\n"
                    )
                  : "--"}{" "}
              </td>
              <td>
                <p>
                  {LoadedData.wallet_points != null
                    ? LoadedData.wallet_points
                    : "0"}
                </p>
              </td>
              <td>
                <p>{numofcount}</p>
              </td>
              <td>
                <p>{LoadedData.deduct_points}</p>
              </td>
              <td>{LoadedData.wallet_amount_perpoint}</td>

              <td>{LoadedData.is_active == 1 ? "Active" : "Inactive"}</td>
            </tr>
          );
        });
        this.state.ordersView = ordersView;
        this.state.xlsArray = finalordersarray;

        this.forceUpdate();
      })
      .catch((error) => {
      });
  }

  // Handle searched string
  handleSearchedString = (event) => {
    let searched = event.target.value;
    this.state.searchString = searched;
    this.forceUpdate();
  };

  handleClear = (id) => {
    if (id == "start_date") {
      this.state.selectedStartDate = "";
      $("#" + id).val("");
    }
    if (id == "end_date") {
      this.state.selectedEndDate = "";
      $("#" + id).val("");
    }
    if (id == "input-search") {
      this.state.searchString = "";
      $("#" + id).val("");
    }
    this.getAllOrders();
  };

  render() {
    return (
      <section id="main_dashboard">
        <div class="container">
          <div class="row">
            <div class="col-md-12 no_padding">
              <div id="order_section">
                <div class="container no_padding">
                  <div class="row">
                    <div class="dash-section">
                      <div class="section-header">
                        <ol class="breadcrumb">
                          <li class="active">
                            <a href="/admin"> Dashboard</a>&gt; Reports &gt;
                            Pharmacy Wallet Reports
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="row tab-header">
                    <div class="col-md-6 no_padding ">
                      <h3> Wallet Report </h3>
                    </div>
                    <div class="col-md-6 no_padding download-btn">
                      <ExcelFile
                        filename="Wallet Report"
                        element={
                          <a class="btn  btn-primary fright">Download Report</a>
                        }
                      >
                        <ExcelSheet
                          data={this.state.xlsArray}
                          name="Order Request"
                        >
                          <ExcelColumn label="Sno" value="Sno" />
                          <ExcelColumn
                            label="Pharmacy Name"
                            value="RetialerName"
                          />
                          <ExcelColumn
                            label="Wallet Points"
                            value="wallet_points"
                          />
                          <ExcelColumn
                            label="Used points"
                            value="Nooftimesused"
                          />
                          <ExcelColumn
                            label="Deduction points"
                            value="deduct_points"
                          />
                          <ExcelColumn
                            label="Deduction points value"
                            value="wallet_amount_perpoint"
                          />

                          <ExcelColumn label="status" value="status" />
                          {/*
                           */}
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7 select_ret_sec">
                      <div class="form-group no_padding col-md-5 px-3">
                        {this.state.retailersListData ? (
                          <Dropdown
                            class="form-control"
                            id="medicines"
                            placeholder={"Select Pharmacy"}
                            fluid
                            clearable
                            search
                            selection
                            options={this.state.retailersListData}
                            onChange={this.onRetailerListChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="form-group no_padding col-md-3"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="CMS_tab">
                <div class="CMS_content">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div id="reg_form">
                          <div class="table-responsive dataTables_wrapper load_sec">
                            <table class="table table-bordered table-sm table-striped">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Pharmacy Name</th>
                                  <th>Wallet Points</th>
                                  <th>Used points</th>
                                  <th>Deduction points</th>
                                  <th>Deduction points value</th>

                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.ordersView != "" ? (
                                  this.state.ordersView
                                ) : (
                                  <React.Fragment>
                                    <td>No records found</td>
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button onclick="topFunction()" id="myBtn" title="Go to top">
                  <img src="https://i.pinimg.com/originals/c4/f0/4b/c4f04b83f93836a05b4f188180708e0e.png" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
