import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
const moment = require("moment");
export default class ViewDoctors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_data: "",
            datatable: {
                columns: [
                    {
                        label: "Subscription Name",
                        field: "subscription_name",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Subscription Amount",
                        field: "subscription_amount",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Subscription Type",
                        field: "subscription_type",
                        sort: "asc",
                        width: 150,
                    },
                    // {
                    //     label: "Subscription Fromdate",
                    //     field: "subscription_fromdate",
                    //     sort: "asc",
                    //     width: 150,
                    // },
                    // {
                    //     label: "Subscription Todate",
                    //     field: "subscription_todate",
                    //     sort: "asc",
                    //     width: 150,
                    // },
                    {
                        label: "Subscription Days",
                        field: "subscription_days",
                        sort: "asc",
                        width: 150,
                    },
                    // {
                    //     label: "description",
                    //     field: "description",
                    //     sort: "asc",
                    //     width: 150,
                    // },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 150,
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        sort: "asc",
                        width: 10,
                    },
                ],
                rows: [],
            },
        };
        // this.deleteDoctor = this.deleteDoctor.bind(this);
        this.deleteSubscriptionpackages = this.deleteSubscriptionpackages.bind(this);
    }

    // To get detais after first render
    componentDidMount = () => {
        this.fetchpagesdata();
    };

    // When value changes of the fields
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    // For getting the data
    fetchpagesdata() {
        Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/getsubscription", { userType: "admin" })
            .then((response) => {
                this.setState({
                    page_data: response.data.data,
                });
                let assignvalues = [];
                this.items = response.data.data.map((item, key) => {
                    assignvalues.push({
                        subscription_name: item.subscription_name,
                        subscription_amount: Constant.currency + " " + item.subscription_amount,
                        subscription_type: item.subscription_type,
                        // subscription_fromdate: moment(item.subscription_fromdate).format("YYYY-MM-DD"),
                        // subscription_todate: moment(item.subscription_todate).format("YYYY-MM-DD"),
                        subscription_days: item.subscription_days,
                        // description: (<div> item.description </div>),
                        status: Number(item.status) ? "Active" : "Inactive",
                        actions: (
                            <div>
                                <span>
                                    <Link
                                        to={"/admin/Createsubscription/" + item.id}
                                        className="fa fa-edit point-cursor"
                                        title={"Update " + item.doctor_name}
                                    ></Link>
                                </span>{" "}
                                &nbsp;
                                {/* {item.id != 1 ? <span>
                                    <i

                                        onClick={() => this.deleteSubscriptionpackages(item.id, item.subscription_name)}
                                        className="fa fa-trash point-cursor"
                                        title={"Delete " + item.storename}
                                    ></i>
                                </span> : ""} */}
                                &nbsp;

                            </div>
                        ),
                    });
                });

                let couponState = Object.assign({}, this.state);
                couponState.datatable.rows = assignvalues;
                this.setState(couponState);
            })
            .catch((error) => { });
    }

    //delete controller
    // deleteDoctor(doctorId, name) {
    //     var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    //     if (isConfirm) {
    //         Httpconfig.httptokendelete(Constant.siteurl + "api/Doctor/" + doctorId)
    //             .then((response) => {
    //                 toast.success("Successfully Deleted Doctor");
    //                 setTimeout(() => window.location.reload(), 2000);
    //             })
    //             .catch((error) => { });
    //     }
    // }
    //delete controller
    deleteSubscriptionpackages(id, name) {
        var isConfirm = window.confirm("Are you sure to delete " + name + "?");
        if (isConfirm) {
            Httpconfig.httptokendelete(Constant.siteurl + "api/subscription/delete/" + id)
                .then((response) => {
                    toast.success("Successfully Deleted Subscription Package");
                    setTimeout(() => window.location.reload(), 2000);
                })
                .catch((error) => { });
        }
    }
    render() {
        const { datatable } = this.state;
        return (
            <section id="main_dashboard">
                <div className="container" id="main_front">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dash-section">
                                <div className="section-header">
                                    <ol className="breadcrumb">
                                        <li className="active">
                                            <Link to="/admin"> Dashboard</Link>&gt; Clinic Setup &gt;
                                            Subscriptions
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <section id="CMS_tab">
                                <div className="CMS_content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="tab-header">
                                                <h3>  Subscriptions List </h3>
                                            </div>
                                            <div id="reg_form">
                                                <form>
                                                    <div className="row">
                                                        <div className="form-group col-md-12">
                                                            <Link
                                                                to="/admin/Createsubscription"
                                                                className="btn  btn-primary fright"
                                                            >
                                                                Create Subscription
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </form>
                                                {datatable.rows.length === 0 ? (
                                                    <p>Loading............</p>
                                                ) : (
                                                    <MDBDataTable
                                                        striped
                                                        responsive46
                                                        bordered
                                                        small
                                                        data={datatable}
                                                    />
                                                )}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div
                                                            className="update_btn"
                                                            style={{ textAlign: "right" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <ToastContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
