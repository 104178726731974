import React, { Component, useState } from "react";
import { ToastContainer } from "react-toastify";
import $ from "jquery";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Form, Button, Dropdown, Grid, Header } from "semantic-ui-react";
import ReactExport from "react-data-export";
import { Link } from "react-router-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require("moment");

export default class Vieworders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retailersListData: "",
      selectedStatus: "",
      selectedRetailerId: "",
      selectedStartDate: "",
      selectedEndDate: "",
      searchString: "",
      orderDetailsView: "collapse-hide",
      orderListView: "container",
      loadshow: "load_sec collapse-hide",
      pageNumber: 1,
      ordersView: [],
      currentOrders: [],
    };
    this.getAllOrders = this.getAllOrders.bind(this);
  }

  // To get details after first render
  componentDidMount = () => {
    this.orderStatusCount();
    this.fetchRetailerInfo();
    this.getAllOrders();
    this.getRejectionResonslist();
  };

  // for orderStatusCount
  orderStatusCount = () => {
    let retailerId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = this.state.selectedStatus;
    let searched = this.state.searchString;

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDashboardCount",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus,
        order_search_id: searched,
      }
    )
      .then((response) => {
        this.state.all = response.data.data[0].all;
        this.state.pending = response.data.data[0].pending;
        this.state.reviewed = response.data.data[0].reviewed;
        this.state.processing = response.data.data[0].processing;
        this.state.shipping = response.data.data[0].shipping;
        this.state.delivered = response.data.data[0].delivered;
        this.state.cancelled = response.data.data[0].cancelled;
        this.state.wallet_points = response.data.data[0].wallet_points;

        this.forceUpdate();
      })
      .catch((error) => {});
  };

  // Get the retailers list
  fetchRetailerInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/retailer/")
      .then((response) => {
        let listData = response.data.data;
        let retailersListData = listData.map((item, index) => ({
          key: item.id,
          text: item.storename,
          value: item.id,
        }));
        this.state.retailersListData = retailersListData;

        this.forceUpdate();
      })
      .catch((error) => {});
  }
  // On retalier selection
  onRetailerListChange = (event, data) => {
    let selectedretailerValue = data.value;

    if (selectedretailerValue == "") {
      this.state.selectedRetailerId = "";
      this.forceUpdate();
      this.getAllOrders();
      this.orderStatusCount();
    } else {
      const keys = data.options.find(
        (data) => data.value === selectedretailerValue
      );
      const selectedretailer = {
        id: keys.key,
        medicineid: keys.text,
        medicinename: keys.value,
      };
      this.state.selectedRetailerName = keys.text;
      this.state.selectedRetailerId = selectedretailerValue;
      this.forceUpdate();
      this.getAllOrders();
      this.orderStatusCount();
    }
  };

  // get all the orders
  getAllOrders() {
    let retailerId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = this.state.selectedStatus;
    let searched = this.state.searchString;
    let pageNumber = this.state.pageNumber;
    let PaidAmount = "";
    let finalordersarray = [];
    if (searched == "") {
      searched = $("#input-search1").val();
      this.state.searchString = searched;
      this.forceUpdate();
    }

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDashboardDetails",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus,
        order_search_id: searched,
        page_number: 1,
      }
    )
      .then((response) => {
        this.state.currentOrders = response.data.data;
        const ordersView = response.data.data.map((LoadedData, num) => {
          PaidAmount = parseInt(LoadedData.payable_amount);
          if (LoadedData.payable_amount) {
            PaidAmount = PaidAmount.toFixed(2).replace(
              /\d(?=(\d{3})+\.)/g,
              "$&,"
            );
          }

          let hoursDiff = moment(
            LoadedData.order_date,
            "YYYY-MM-DD hh:mm:ss"
          ).fromNow();

          finalordersarray.push({
            Sno: num + 1,
            "Customer Name":
              LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
              LoadedData.patient_tbl.name.slice(1) +
              "," +
              LoadedData.patient_tbl.phone_number,
            Retailer:
              LoadedData.retailer_registration_tbl != null
                ? LoadedData.retailer_registration_tbl.storename +
                  "," +
                  LoadedData.retailer_registration_tbl.address +
                  "," +
                  LoadedData.retailer_registration_tbl.email +
                  "," +
                  LoadedData.retailer_registration_tbl.mobile_number
                : "--",
            "Pharmacy Location":
              LoadedData.retailer_registration_tbl != null
                ? LoadedData.retailer_registration_tbl.address +
                  "," +
                  LoadedData.retailer_registration_tbl.email +
                  "," +
                  LoadedData.retailer_registration_tbl.mobile_number
                : "--",
            "Order Id": LoadedData.id,
            "Order Date":
              LoadedData.order_date != null
                ? moment(LoadedData.order_date).format("DD-MM-YYYY")
                : "--",
            Amount: PaidAmount,
            Items: Object.keys(LoadedData.order_processing_tbls).length,
            "Deduction points": LoadedData.wallet_points_deducted,
            "Wallet point value": LoadedData.wallet_amount_perpoint,
            "wallet points": LoadedData.totwallet_amount,
            "Order Status": LoadedData.order_status_tbl.status,
          });
          return (
            <tr>
              <td>{num + 1}</td>
              <td>
                {LoadedData.patient_tbl.name
                  ? LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                    LoadedData.patient_tbl.name.slice(1)
                  : "--"}{" "}
                <h6 class="td_down">
                  {LoadedData.patient_tbl.phone_number
                    ? LoadedData.patient_tbl.phone_number
                    : "--"}
                </h6>
              </td>
              <td>
                {LoadedData.retailer_registration_tbl != null ? (
                  <div class="dropdown">
                    <p class="dropbtn">
                      {LoadedData.retailer_registration_tbl.storename
                        ? LoadedData.retailer_registration_tbl.storename
                        : "--"}{" "}
                    </p>

                    <div class="dropdown-content">
                      <h3>
                        {LoadedData.retailer_registration_tbl.storename
                          ? LoadedData.retailer_registration_tbl.storename
                          : "--"}
                      </h3>
                      <p>
                        {}
                        {LoadedData.retailer_registration_tbl.address
                          ? LoadedData.retailer_registration_tbl.address
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.email
                          ? LoadedData.retailer_registration_tbl.email
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.mobile_number
                          ? LoadedData.retailer_registration_tbl.mobile_number
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  "--"
                )}
              </td>
              <td>
                {LoadedData.retailer_registration_tbl != null ? (
                  <div>
                    <div>
                      <p>
                        {LoadedData.retailer_registration_tbl.address
                          ? LoadedData.retailer_registration_tbl.address
                          : ""}
                      </p>
                      <p class="td_down">
                        {LoadedData.retailer_registration_tbl.email
                          ? LoadedData.retailer_registration_tbl.email
                          : ""}
                      </p>
                      <p class="td_down">
                        {LoadedData.retailer_registration_tbl.mobile_number
                          ? LoadedData.retailer_registration_tbl.mobile_number
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  "--"
                )}
              </td>
              <td>
                <p onClick={this.orderDetailsView.bind(this, LoadedData.id)}>
                  {LoadedData.id}
                </p>
              </td>
              <td>
                {LoadedData.order_date != null
                  ? moment(LoadedData.order_date).format("DD-MM-YYYY")
                  : "--"}
              </td>

              <td>{Object.keys(LoadedData.order_processing_tbls).length}</td>
              <td>
                {LoadedData.retailer_registration_tbl != null
                  ? LoadedData.retailer_registration_tbl.currency
                  : "--"}
                {LoadedData.payable_amount ? PaidAmount : "--"}
              </td>
              <td>{LoadedData.wallet_points_deducted}</td>
              <td>{LoadedData.wallet_amount_perpoint}</td>
              <td>{LoadedData.totwallet_amount}</td>
              <td>
                {LoadedData.order_status < 5 ? (
                  <div class="dropdown">
                    <p class="dropbtn">
                      {LoadedData.order_status_tbl.status}{" "}
                      <h6 class="td_down">{hoursDiff}</h6>
                    </p>
                  </div>
                ) : (
                  <p class="">
                    {LoadedData.order_status_tbl.status == "Cancelled" &&
                    LoadedData.refund_status == 6 ? (
                      <p class="verify_btn">
                        Cancelled <br />
                        Refund Queued For Processing
                      </p>
                    ) : LoadedData.order_status_tbl.status == "Cancelled" &&
                      LoadedData.refund_status == 7 ? (
                      <p class="verify_btn">
                        Cancelled <br />
                        Amound Refunded
                      </p>
                    ) : (
                      LoadedData.order_status_tbl.status
                    )}{" "}
                    <h6 class="td_down"></h6>
                  </p>
                )}
              </td>
            </tr>
          );
        });
        this.state.ordersView = ordersView;
        this.state.finalordersarray = finalordersarray;
        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // Handle Selected status
  handleSelectedStatus = (status) => {
    this.state.selectedStatus = status;
    $(".stat").removeClass("active");
    $(".status-" + status).addClass("active");
    this.forceUpdate();
    this.getAllOrders();
    this.orderStatusCount();
  };
  // Handle searched string
  handleSearchedString = (event) => {
    let searched = event.target.value;
    this.setState({
      searchString: searched,
    });
    this.state.searchString = searched;
    this.forceUpdate();
  };

  /* change order status */

  ChangeOrderStatus = (orderId, status) => {
    Httpconfig.httptokenput(
      Constant.siteurl + "api/OM/orderProcess/retailerOrderStatus/" + orderId,
      { order_status: status }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.success(response.data.message);
          this.getAllOrders();
          this.orderStatusCount();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // Reject Order list

  getRejectionResonslist = () => {
    Httpconfig.httptokenget(Constant.siteurl + "api/OM/cancellationReason/")
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            const retailerRejectList = response.data.data.map(
              (finalLoadedData, num) => {
                return (
                  <p>
                    <input
                      type="checkbox"
                      class="rej_check"
                      name="cancelReasons"
                      id={finalLoadedData.id}
                      onChange={this.cancelReason}
                    />{" "}
                    {finalLoadedData.reason}
                  </p>
                );
              }
            );
            this.state.retailerRejectList = retailerRejectList;
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // update the cancellation reaon

  cancelReason = (event) => {
    let id = event.currentTarget.id;
    if (document.getElementById(id).checked == true) {
      this.state.cancelleReason = id;
    } else {
      this.state.cancelleReason = "";
    }
    this.forceUpdate();
  };

  // Reject order
  rejectOrder = () => {
    let cancelReasonsCheckedCount = $(
      'input[name="cancelReasons"]:checked'
    ).length;
    let otherReason = $("#otherReason").val();
    let orderId = this.state.selectedCancelOrderId;
    if (cancelReasonsCheckedCount == 0 && otherReason == "") {
      toast.error("Select Reason for Cancellation");
      return;
    } else {
      let cancelledReason = this.state.cancelleReason;
      Httpconfig.httptokenpost(
        Constant.siteurl + "api/OM/cancellationReason/orderDetails/" + orderId,
        { order_status: "6", cancelled_reason: cancelledReason }
      )
        .then((response) => {
          if ((response.data.status = 200)) {
            toast.success(response.data.message);
            $("#reject_reason").hide();
            $('input[name="cancelReasons"]:checked').prop("checked", "");
            $("#otherReason").val("");
            this.state.cancelleReason = "";
            this.state.selectedCancelOrderId = "";
            this.forceUpdate();
            this.getAllOrders();
            this.orderStatusCount();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  // Order details view

  orderDetailsView = (orderId) => {
    this.state.orderDetailsView = "collapse-show";
    this.state.orderListView = "container collapse-hide";
    this.forceUpdate();
    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDetail/" + orderId
    )
      .then((response) => {
        if ((response.data.status = 200)) {
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  showListView = () => {
    this.state.orderDetailsView = "collapse-hide";
    this.state.orderListView = "container collapse-show";
    this.forceUpdate();
  };
  startDate = (event) => {
    let startDate = event.target.value;

    this.state.selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (this.state.selectedStartDate && this.state.selectedEndDate) {
      if (this.state.selectedStartDate <= this.state.selectedEndDate) {
        if (
          this.state.selectedEndDate != "" &&
          this.state.selectedStartDate != ""
        ) {
          this.getAllOrders();
          this.orderStatusCount();
        }
      } else {
        toast.error("from date cannot be greater than to date");
      }
    }
  };

  // Start date selection
  endDate = (event) => {
    let endDate = event.target.value;
    this.state.selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    if (this.state.selectedStartDate <= this.state.selectedEndDate) {
      this.forceUpdate();
      if (
        this.state.selectedStartDate != "" &&
        this.state.selectedEndDate != ""
      ) {
        this.getAllOrders();
        this.orderStatusCount();
      }
    } else {
      toast.error("from date cannot be greater than to date");
    }
  };
  // for handleScroll
  handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      2;
    let page = this.state.pageNumber;
    if (bottom) {
      let page = this.state.pageNumber;
      page = page + 1;
      this.setState({ pageNumber: page });
      this.getAllOrdersbyPage(page);
    }
  };
  //  for handleSelectedOrderId
  handleSelectedOrderId = (orderId) => {
    this.state.selectedCancelOrderId = orderId;
    this.forceUpdate();
  };

  //  For handleClear
  handleClear = (id) => {
    if (id == "start_date") {
      this.state.selectedStartDate = "";
      $("#" + id).val("");
    }
    if (id == "end_date") {
      this.state.selectedEndDate = "";
      $("#" + id).val("");
    }
    if (id == "input-search") {
      this.state.searchString = "";
      $("#" + id).val("");
    }
    this.forceUpdate();
    this.getAllOrders();
    this.orderStatusCount();
  };

  // get all the orders
  getAllOrdersbyPage(pageNumber) {
    let retailerId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = this.state.selectedStatus;
    let searched = this.state.searchString;
    pageNumber = pageNumber + 1;
    this.state.pageNumber = pageNumber;
    let PaidAmount = "";
    let finalordersarray = [];
    let orderViews = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDashboardDetails",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus,
        order_search_id: searched,
        page_number: pageNumber,
      }
    )
      .then((response) => {
        if (Object.keys(response.data.data).length > 0 && pageNumber > 1) {
          orderViews = this.state.currentOrders.concat(response.data.data);
          this.state.currentOrders = orderViews;
          this.forceUpdate();
        } else {
          if (Object.keys(response.data.data).length > 0) {
            orderViews = response.data.data;
            this.state.currentOrders = orderViews;
            this.forceUpdate();
          }
        }
        if (Object.keys(orderViews).length > 0) {
          if (Object.keys(orderViews).length >= 20) {
            this.state.loadshow = "load_sec collapse-show";
          } else {
            this.state.loadshow = "load_sec collapse-hide";
          }
          const ordersView = orderViews.map((LoadedData, num) => {
            PaidAmount = parseInt(LoadedData.payable_amount);
            if (LoadedData.payable_amount) {
              PaidAmount = PaidAmount.toFixed(2).replace(
                /\d(?=(\d{3})+\.)/g,
                "$&,"
              );
            }
            finalordersarray.push({
              Sno: num + 1,
              "Customer Name":
                LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                LoadedData.patient_tbl.name.slice(1) +
                "," +
                LoadedData.patient_tbl.phone_number,
              Retailer:
                LoadedData.retailer_registration_tbl.storename +
                "," +
                LoadedData.retailer_registration_tbl.address +
                "," +
                LoadedData.retailer_registration_tbl.email +
                "," +
                LoadedData.retailer_registration_tbl.mobile_number,
              "Pharmacy Location":
                LoadedData.retailer_registration_tbl.address +
                "," +
                LoadedData.retailer_registration_tbl.email +
                "," +
                LoadedData.retailer_registration_tbl.mobile_number,
              "Order Id": LoadedData.id,

              "Order Date": LoadedData.order_date,
              Amount: PaidAmount,
              Items: Object.keys(LoadedData.order_processing_tbls).length,
              "Deduction points": LoadedData.wallet_points_deducted,
              "Wallet point value": LoadedData.wallet_amount_perpoint,
              "wallet points": LoadedData.totwallet_amount,

              "Order Status": LoadedData.order_status_tbl.status,
            });
            return (
              <tr>
                <td>{num + 1}</td>
                <td>
                  {LoadedData.patient_tbl.name
                    ? LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                      LoadedData.patient_tbl.name.slice(1)
                    : "--"}{" "}
                  <h6 class="td_down">
                    {LoadedData.patient_tbl.phone_number
                      ? LoadedData.patient_tbl.phone_number
                      : "--"}
                  </h6>
                </td>
                <td>
                  <div class="dropdown">
                    <p class="dropbtn">
                      {LoadedData.retailer_registration_tbl.storename
                        ? LoadedData.retailer_registration_tbl.storename
                        : "--"}{" "}
                    </p>
                    <div class="dropdown-content">
                      <h3>
                        {LoadedData.retailer_registration_tbl.storename
                          ? LoadedData.retailer_registration_tbl.storename
                          : "--"}
                      </h3>
                      <p>
                        {}
                        {LoadedData.retailer_registration_tbl.address
                          ? LoadedData.retailer_registration_tbl.address
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.email
                          ? LoadedData.retailer_registration_tbl.email
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.mobile_number
                          ? LoadedData.retailer_registration_tbl.mobile_number
                          : ""}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      {}
                      {LoadedData.retailer_registration_tbl.address
                        ? LoadedData.retailer_registration_tbl.address
                        : ""}
                    </p>
                    <p class="td_down">
                      {" "}
                      {LoadedData.retailer_registration_tbl.email
                        ? LoadedData.retailer_registration_tbl.email
                        : ""}
                    </p>
                    <p class="td_down">
                      {" "}
                      {LoadedData.retailer_registration_tbl.mobile_number
                        ? LoadedData.retailer_registration_tbl.mobile_number
                        : ""}
                    </p>
                  </div>
                </td>
                <td>
                  <p onClick={this.orderDetailsView.bind(this, LoadedData.id)}>
                    {LoadedData.id}
                  </p>
                </td>
                <td>{LoadedData.order_date}</td>

                <td>{Object.keys(LoadedData.order_processing_tbls).length}</td>
                <td>
                  {LoadedData.retailer_registration_tbl.currency}{" "}
                  {LoadedData.payable_amount ? PaidAmount : "--"}
                </td>
                <td>{LoadedData.wallet_points_deducted}</td>
                <td>{LoadedData.wallet_amount_perpoint}</td>
                <td>{LoadedData.totwallet_amount}</td>
                <td>
                  {LoadedData.order_status < 5 ? (
                    <div class="dropdown">
                      <p class="dropbtn">
                        {LoadedData.order_status_tbl.status}{" "}
                        <h6 class="td_down">2 hrs</h6>
                      </p>
                    </div>
                  ) : (
                    <p class="">
                      {LoadedData.order_status_tbl.status}{" "}
                      <h6 class="td_down"></h6>
                    </p>
                  )}
                </td>
              </tr>
            );
          });
          this.state.ordersView = ordersView;
          this.state.finalordersarray = finalordersarray;
          this.forceUpdate();
        } else {
          this.state.loadshow = "load_sec collapse-hide";
          this.forceUpdate();
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <section id="main_dashboard">
        <div class={this.state.orderListView}>
          <div class="row">
            <div class="col-md-12 no_padding">
              <div class="sorting_content">
                <ol class="breadcrumb">
                  <li class="active">
                    <a href="/admin"> Dashboard</a> &gt; Reports &gt; Pharmacy
                    Order Report
                  </li>
                </ol>
              </div>
              <div id="order_section">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6 date_sec">
                      <h2>Select Date</h2>
                      <form>
                        <div class="form-group">
                          <input
                            type="date"
                            id="start_date"
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            onChange={this.startDate}
                            max={moment().format("YYYY-MM-DD")}
                          />
                          <img
                            class="remove_icon"
                            src="https://www.flaticon.com/svg/static/icons/svg/59/59836.svg"
                            onClick={this.handleClear.bind(this, "start_date")}
                          />
                        </div>
                        <p class="to_txt">To</p>
                        <div class="form-group">
                          <input
                            type="date"
                            id="end_date"
                            class="form-control"
                            onChange={this.endDate}
                            max={moment().format("YYYY-MM-DD")}
                          />
                          <img
                            class="remove_icon"
                            src="https://www.flaticon.com/svg/static/icons/svg/59/59836.svg"
                            onClick={this.handleClear.bind(this, "end_date")}
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-lg-4 select_ret_sec">
                      <div class="form-group">
                        <label>Select Pharmacy</label>
                        {this.state.retailersListData ? (
                          <Dropdown
                            class="form-control"
                            id="medicines"
                            placeholder={"Select Pharmacy"}
                            fluid
                            clearable
                            search
                            selection
                            options={this.state.retailersListData}
                            onChange={this.onRetailerListChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="col-lg-2 generate_sec">
                      <ExcelFile
                        element={
                          <a href="#">
                            {" "}
                            <h2>
                              <img src="../images/patient/img/blue-download.svg" />
                              Download
                            </h2>
                          </a>
                        }
                      >
                        <ExcelSheet
                          data={this.state.finalordersarray}
                          name="Order Request"
                        >
                          <ExcelColumn label="Sno" value="Sno" />
                          <ExcelColumn
                            label="Customer Name"
                            value="Customer Name"
                          />
                          <ExcelColumn label="Pharmacy" value="Retailer" />
                          <ExcelColumn
                            label="Pharmacy Location"
                            value="Pharmacy Location"
                          />
                          <ExcelColumn label="Order Id" value="Order Id" />
                          <ExcelColumn label="Order Date" value="Order Date" />
                          <ExcelColumn label="Amount" value="Amount" />
                          <ExcelColumn label="Items" value="Items" />
                          <ExcelColumn
                            label="Deduction points"
                            value="Deduction points"
                          />
                          <ExcelColumn
                            label="Wallet point value"
                            value="Wallet point value"
                          />
                          <ExcelColumn
                            label="Wallet points"
                            value="wallet points"
                          />
                          <ExcelColumn
                            label="Order Status"
                            value="Order Status"
                          />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                </div>
              </div>

              <div id="sorting_sec">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 no_padding">
                      <div class="sorting_content">
                        <div class="sorting_head"></div>
                        <ul>
                          <li
                            class="stat status-0 active"
                            onClick={this.handleSelectedStatus.bind(this, "")}
                          >
                            All ({this.state.all ? this.state.all : "0"})
                          </li>
                          <li
                            class="stat status-1"
                            onClick={this.handleSelectedStatus.bind(this, "1")}
                          >
                            Pending (
                            {this.state.pending ? this.state.pending : "0"})
                          </li>

                          <li
                            class="stat status-3"
                            onClick={this.handleSelectedStatus.bind(this, "3")}
                          >
                            Processing (
                            {this.state.processing
                              ? this.state.processing
                              : "0"}
                            )
                          </li>
                          <li
                            class="stat status-4"
                            onClick={this.handleSelectedStatus.bind(this, "4")}
                          >
                            Shipped (
                            {this.state.shipping ? this.state.shipping : "0"})
                          </li>
                          <li
                            class="stat status-5"
                            onClick={this.handleSelectedStatus.bind(this, "5")}
                          >
                            Delivered (
                            {this.state.delivered ? this.state.delivered : "0"})
                          </li>

                          <li></li>
                          <li></li>

                          <li class="stat status-7">
                            Available Wallet Points (
                            {this.state.wallet_points
                              ? this.state.wallet_points
                              : "0"}
                            )
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="search_order">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 no_padding">
                      <form>
                        <div class="form-group">
                          <label>Search order</label>
                          <div class="input-group">
                            <input
                              type="text"
                              id="input-search1"
                              class="form-control"
                              placeholder="Search for Order Id"
                              onKeyUp={this.handleSearchedString}
                            />
                            <img
                              class="remove_icon"
                              src="https://www.flaticon.com/svg/static/icons/svg/59/59836.svg"
                              onClick={this.handleClear.bind(
                                this,
                                "input-search"
                              )}
                            />
                            <div class="input-group-btn">
                              <div class="btn-group" role="group">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={this.getAllOrders}
                                >
                                  search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div id="CMS_tab">
                <div class="CMS_content">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div id="reg_form">
                          <div class="table-responsive dataTables_wrapper load_sec">
                            <table class="table table-bordered table-sm table-striped load_sec">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Customer</th>
                                  <th>Pharmacy</th>
                                  <th>Pharmacy Location</th>
                                  <th>Order ID</th>
                                  <th>Order Date</th>
                                  <th>Items</th>
                                  <th>Amount</th>
                                  <th>Deduction points</th>
                                  <th>Wallet point value</th>
                                  <th>Wallet points</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(this.state.ordersView).length >
                                0 ? (
                                  this.state.ordersView
                                ) : (
                                  <React.Fragment>
                                    <td>No orders found</td>
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>
                            {this.state.ordersView ? (
                              <div
                                class={this.state.loadshow}
                                onClick={this.getAllOrdersbyPage.bind(
                                  this,
                                  this.state.pageNumber
                                )}
                              >
                                <a id="loadMore">
                                  Load More{" "}
                                  <img src="https://static.thenounproject.com/png/427197-200.png" />
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button onClick="topFunction()" id="myBtn" title="Go to top">
                  <img src="https://i.pinimg.com/originals/c4/f0/4b/c4f04b83f93836a05b4f188180708e0e.png" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="main_dashboard" class={this.state.orderDetailsView}>
          <div class="container">
            <div class="row">
              <div class="col-md-12 no_padding">
                <section id="retailer_invoice">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="ret_inv_section">
                          <span
                            class="ret_inv_back"
                            onClick={this.showListView}
                          >
                            {" "}
                            <a href="#">
                              <img src="https://i.pinimg.com/736x/9e/b6/0e/9eb60ee1602cb5370382c2582ee2d0d1.jpg" />
                              Back to Order
                            </a>{" "}
                          </span>
                          <div class="ret_inv_head">
                            <h2> Order Details</h2>
                          </div>
                          <div class="ret_pharm_det">
                            <div class="row">
                              <div class="col-md-8">
                                <div class="pharm_add">
                                  <h2>New Pharmacy</h2>
                                  <h5>
                                    D NO. 131, Axis Bank, Madhapur, Hyderabad
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="pharm_order">
                                  <p>
                                    Order No: <span>524163</span>
                                  </p>
                                  <p>
                                    Order Date: <span>14 Dec 2020</span>
                                  </p>
                                  <p>
                                    Invoice Date:{" "}
                                    <span>14 Dec 2020 02:30 PM</span>
                                  </p>
                                  <p>
                                    Invoice No: <span>5421525</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ret_invoice_table" id="reg_form">
                            <div class="table-responsive dataTables_wrapper">
                              <table class="table table-bordered table-sm table-striped">
                                <thead>
                                  <tr>
                                    <th>Sl.No</th>
                                    <th>Product / Manufacturer</th>
                                    <th>Batch / Expiry</th>
                                    <th>MRP</th>
                                    <th>Qty</th>
                                    <th>Taxable Amnt</th>
                                    <th>Vat %</th>
                                    <th>Vat Amount</th>
                                    <th>Discount</th>
                                    <th>Total Amnt</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      Dolo 650 <h6>Micro Labs ltd</h6>
                                    </td>
                                    <td class="batch_inp">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value="88789- 23/5"
                                      />
                                    </td>
                                    <td>₹ 400</td>
                                    <td>2</td>
                                    <td>₹ 400</td>
                                    <td>5</td>
                                    <td>₹ 55.32</td>
                                    <td>10</td>
                                    <td>₹ 75.30</td>
                                  </tr>

                                  <tr>
                                    <td>2</td>
                                    <td>
                                      Dolo 650 <h6>Micro Labs ltd</h6>
                                    </td>
                                    <td class="batch_inp">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value="88789- 23/5"
                                      />
                                    </td>
                                    <td>₹ 400</td>
                                    <td>2</td>
                                    <td>₹ 400</td>
                                    <td>5</td>
                                    <td>₹ 55.32</td>
                                    <td>10</td>
                                    <td>₹ 75.30</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="ret_inv_footer">
                              <div class="row">
                                <div class="col-md-offset-7 col-md-5 amount__right">
                                  <div class="total_tax">
                                    <h5>
                                      Total Taxable Amount :{" "}
                                      <span>₹ 71.64</span>
                                    </h5>
                                    <h5>
                                      Total Taxes : <span>₹ 71.64</span>
                                    </h5>
                                  </div>
                                  <div class="total_amnt">
                                    <p>
                                      Sub Total : <span>₹ 71.64</span>
                                    </p>
                                    <p>
                                      Discount : <span> - ₹ 71.64</span>
                                    </p>
                                    <h3>
                                      Total Paid : <span>₹ 223.00</span>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="ret_inv_pay">
                              <div class="row">
                                <div class="col-md-8">
                                  <div class="payment_mode">
                                    <p>
                                      Payment mode: <span>Card</span>
                                    </p>
                                    <p>
                                      Address : <span>Madhapur, Hyderabad</span>
                                    </p>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="ret_inv_btns">
                                    <p
                                      class="process_btn"
                                      data-toggle="modal"
                                      data-target="#reject_reason"
                                    >
                                      Cancel Order
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="reject_reason" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
                <h2>Reason For Cancellation</h2>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="reject_content">
                        <div class="rej_main">
                          {this.state.retailerRejectList}

                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Type Reason"
                            id="otherReason"
                          ></textarea>
                        </div>
                      </div>
                      <div class="reject_bottom">
                        <div class="row">
                          <div class="col-md-4">
                            <button
                              type="button"
                              class="cancel_btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                          <div class="col-md-8">
                            <button
                              type="button"
                              class="save_btn"
                              onClick={this.rejectOrder}
                            >
                              Initiate Refund
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </section>
    );
  }
}
