import React, { Component } from 'react';
//import Header from './components/Header';
import Footer from './components/Footer';
//import Login from './components/login';
import Approuter from  './components/Approuter';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  constructor(props){
    super(props);
    if( localStorage.getItem("validated")) {
      
      const status=localStorage.getItem("validated");
      if(status==null) { status=false}
      this.state = {
        validated : status
      }
    }else{
      this.state = { 
        validated : false
      }
    }
    console.log(this.state.validated);
  }
  
  render() {
    return (
      <div>
      <body>
      <section className="content_wrapper">
      {/* <header id="header"> */}
      <Approuter /> 
      {/* { this.state.validated ? this.state.validated : <Login/> }
      { this.state.validated==="false" ? <Login/> : this.state.validated }
      { this.state.validated==="true" ? <Header /> : null }
      
      { this.state.validated==="true" ? <Approuter />  : null } */}
      {/* { this.state.validated=="true" ? <Footer /> : null } */}
      {/* <Footer /> */}
      {/* </header> */}

      </section>
      </body>
      </div>
    );
  }
}

export default App;