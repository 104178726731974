import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import RetailerCss from "../../public/css/retailer/retailer_style.css";
import DiagnosisHeader from "../diagnostics/DiagnosisHeader";
import DiagnosisMenu from "../diagnostics/DiagnosisMenu";
import Footer from "../patient/Patientfooter";
import { Multiselect } from "multiselect-react-dropdown";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactExport from "react-data-export";
import dateformatter from "../helpers/dateformatter";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require("moment");

export default class ViewAgents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      datatable: {
        columns: [
          {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Mobile Number ",
            field: "mobile_number ",
            sort: "asc",
            width: 150,
          },
          {
            label: "Email ",
            field: "email ",
            sort: "asc",
            width: 150,
          },
          {
            label: "Age",
            field: "age",
            sort: "asc",
            width: 150,
          },

          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
      },
    };
    this.deleteRetailer = this.deleteAgent.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    let userData = reactLocalStorage.getObject("retuserObj");
    this.fetchpagesdata(userData.diagnosis_id);
    if (userData) {
      if (userData != "") {
        this.setState({
          retailerId: userData.diagnosis_id,
        });

        this.forceUpdate();
      }
    }
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fetchpagesdata(retailerId) {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/fetchdata/" + retailerId
    )
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) =>
          assignvalues.push({
            name: item.name,
            "mobile_number ": item.mobile_number,
            "email ": item.email,

            age: item.age,

            status: item.is_active == "0" ? "Active" : "In Active",
            actions: (
              <div>
                <span>
                  <Link
                    to={"/diagnostics/CreateAgents/" + item.id}
                    className="fa fa-edit point-cursor"
                    title={"Update " + item.name}
                  ></Link>
                </span>
                {/* {" "}
                &nbsp;
                <span>
                  <i
                    onClick={() => this.deleteAgent(item.id, item.name)}
                    className="fa fa-trash point-cursor"
                    title={"Delete " + item.name}
                  ></i>
                </span>{" "} 
                &nbsp;*/}
              </div>
            ),
          })
        );

        let retailerState = Object.assign({}, this.state);
        retailerState.datatable.rows = assignvalues;
        this.setState(retailerState);
      })
      .catch((error) => {});
  }

  //delete controller
  deleteAgent(userId, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/diagnosis/deleteAgent/" + userId
      )
        .then((response) => {
          toast.success("Successfully Deleted Agent");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {});
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <main id="main">
        <DiagnosisHeader />
        <DiagnosisMenu />
        <section id="Pharmacy_dashboard">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-10 col-md-10 Pharma_Content">
                <section id="CMS_tab">
                  <div className="CMS_content">
                    <div className="container">
                      <div className="row">
                        <div className="tab-header">
                          <h3> Create Agent</h3>
                        </div>
                        <div id="reg_form">
                          <form>
                            <div className="row">
                              <div className="form-group col-md-12">
                                <Link
                                  to="/diagnostics/CreateAgents"
                                  className="btn  btn-primary fright"
                                >
                                  Create Agent
                                </Link>
                              </div>
                            </div>
                          </form>
                          {datatable.rows.length === 0 ? (
                            <p>Loading............</p>
                          ) : (
                            <MDBDataTable
                              striped
                              responsive
                              bordered
                              small
                              data={datatable}
                            />
                          )}
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="update_btn"
                                style={{ textAlign: "right" }}
                              ></div>
                            </div>
                          </div>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </main>
    );
  }
}
