import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Patcss from "../../public/css/patient/style_pat.css";
import Constant from "../../constants";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";

export default class Patientmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Language: "",
    };
  }

  // To get detais after first render

  componentDidMount = () => {
    this.state = {
      Language: localStorage.getItem("Language_selected"),
    };
  };

  // called after the React component props or state has changed.

  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
  };

  render() {
    return (
      <div>
        <I18nPropvider locale={this.state.Language}>
          <section id="footer">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="col2 col-md-3">
                    <h2 class="footer_head">{translate("Our Services")}</h2>
                    {/* <p>{translate("Consult a Doctor")}</p> */}
                    <a href={Constant.imgurl + "/patientConsultationPurpose"}>
                      <p>{translate("Consult a Health Care Provider")}</p>
                    </a>
                    {/* <p>{translate("Book for Diagnostic")}</p> */}
                    <a href={Constant.imgurl + "/Diagnostichome"}>
                      <p>{translate("Book for Diagnostic")}</p>
                    </a>
                  </div>
                  <div class="col2 col-md-3">
                    <h2 class="footer_head">{translate("Our Policies")}</h2>

                    <a
                      href={Constant.imgurl + "/patient/PrivacyPolicy"}
                      target="_blank"
                    >
                      <p>{translate("Privacy Policy")}</p>
                    </a>

                    <a
                      href={Constant.imgurl + "/patient/TermsConditions"}
                      target="_blank"
                    >
                      <p>{translate("Terms & Conditions")}</p>
                    </a>
                  </div>
                  <div class="col2 col-md-3">
                    <h2 class="footer_head">{translate("Social")}</h2>

                    <h3>{translate("Need Help")} ?</h3>
                    <p>
                      <span>
                        <i class="fa fa-phone"></i>
                      </span>
                      +91 – 9841401190
                    </p>
                    <a href="mailto:support@remotelhealthcare.com">
                      <p>
                        <span>
                          <i class="fa fa-envelope"></i>
                        </span>
                        admin@remotelhealthcare.com
                      </p>
                    </a>
                  </div>
                  <div class="col2 col-md-3">
                    <h2 class="footer_head">{translate("Our App")}</h2>

                    <p>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.healthcare.remotelpatient"
                        target="_blank"
                      >
                      <img src="/images/patient/img/android.svg" />
                       </a>
                       <a
                        href="https://apps.apple.com/in/app/remotel-user/id6544799030"
                        target="_blank"
                      >
                      <img src="/images/patient/img/apple.svg" />
                        </a> 
                    </p>
                    <p className="pat_ver">Version - V1.1.4</p>
                   
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="footer_text">
                    <h5>
                   
                      {translate("Copyright")} © 2022,{" "}
                      {/* {translate("Remotel HealthCare")}.{" "} */}
                      {translate("All Rights Reserved")}.
                      Remotel Healthcare, A Unit of Prajiv Ventures Private Limited.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </I18nPropvider>
      </div>
    );
  }
}
