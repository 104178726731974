import React, { Component } from "react";
import "../../public/css/patient/paymentsuccess.css";

export default class paymentFailure extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="paymentbox container-fluid mt-5">
        <div class="row">
          <div class="col-md-6 mx-auto mt-5 ">
            <div class="failurepayment">
              <div class="payment_header">
                <div class="check mt-2">
                  <i class="fa fa-times mt-1" aria-hidden="true"></i>
                </div>
              </div>
              <div class="content text-danger">
                <h1>Payment Failure !</h1>
                <h1>Something went wrong</h1>
                <a href="/Patientconfirmappointment">
                  <button class="buttonf" href="#">
                    Retry
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
