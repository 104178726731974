import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, convertFromHTML, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
const moment = require("moment");

export default class createCoupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            editSubscriptionId: "",
        };

    }

    //setting the date fields on change
    dateOnChange = (field, value) => {
        let fields = this.state.fields;
        fields[field] = value;
        this.setState({ fields });
    };

    // To get detais after first render
    componentDidMount = () => {
        const { handle } = this.props.match.params;
        this.state.editSubscriptionId = handle
        console.log("handle...", handle)
        if (this.state.editSubscriptionId) this.getSubscriptionInfo(this.state.editSubscriptionId);

    };

    // When value changes of the fields
    handleChange = (field, event) => {
        let fields = this.state.fields;
        fields[field] = event.target.value;
        this.setState({ fields });
    };

    // To get all the subscription info
    getSubscriptionInfo(handle) {
        Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/getsubscription", { "id": handle, userType: "admin" })
            .then((response) => {
                this.setState({
                    fields: {
                        subscription_name: response.data.data[0].subscription_name,
                        subscription_amount: response.data.data[0].subscription_amount,
                        subscription_type: response.data.data[0].subscription_type,
                        //subscription_todate: new Date(response.data.data[0].subscription_todate), //moment(response.data.data[0].subscription_todate).format("YYYY-MM-DD"),
                        //subscription_fromdate: new Date(response.data.data[0].subscription_fromdate), //moment(response.data.data[0].subscription_fromdate).format("YYYY-MM-DD"),
                        subscription_days: response.data.data[0].subscription_days,
                        status: response.data.data[0].status,
                        subscription_description: response.data.data[0].description,

                    },
                    editorState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(response.data.data[0].description)
                        )
                    ),
                });
            })
            .catch((error) => { });
    }

    // create or update
    checkSubmit(event) {
        event.preventDefault();
        console.log("fields...", this.state.fields)
        const { handle } = this.props.match.params;
        if (this.handleValidation() && handle) {
            this.updateSubscription(event);
        } else if (this.handleValidation() && handle == undefined) {
            this.createSubscription(event);
        } else {
            toast.warn("Form has errors.");
        }
    }

    // creates new subscription
    createSubscription = (event) => {
        event.preventDefault();

        const { fields, errors } = this.state;
        // alert(fields["subscription_description"]);
        Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/create", {
            subscription_name: fields["subscription_name"],
            subscription_amount: fields["subscription_amount"],
            subscription_type: fields["subscription_type"],
            //subscription_todate: moment(fields["subscription_todate"]).format("YYYY-MM-DD"),
            //subscription_fromdate: moment(fields["subscription_fromdate"]).format("YYYY-MM-DD"),
            subscription_days: fields["subscription_days"],
            status: fields["status"],
            description: fields["subscription_description"],
        }).then((response) => {
            if (response.data.status === 200 && response.data.error == false) {
                toast.success(response.data.message);
                setTimeout(() => this.props.history.push("/admin/Viewsubscriptions"));
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
        });
    };

    //updates subscription
    updateSubscription = (event) => {
        event.preventDefault();
        const { handle } = this.props.match.params;
        const { fields, errors } = this.state;

        Httpconfig.httptokenput(Constant.siteurl + "api/subscription/update/" + handle, {
            subscription_name: fields["subscription_name"],
            subscription_amount: fields["subscription_amount"],
            subscription_type: fields["subscription_type"],
            subscription_days: fields["subscription_days"],
            // subscription_todate: moment(fields["subscription_todate"]).format("YYYY-MM-DD"),
            // subscription_fromdate: moment(fields["subscription_fromdate"]).format("YYYY-MM-DD"),
            subscription_days: fields["subscription_days"],
            status: fields["status"],
            description: fields["subscription_description"],
        })
            .then((response) => {
                if (response.data.status === 200 && response.data.error == false) {
                    toast.success(response.data.message);
                    setTimeout(() => this.props.history.push("/admin/Viewsubscriptions"), 1000);
                }
                else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            });
    };

    //To validating the fields
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["subscription_name"]) {
            formIsValid = false;
            errors["subscription_name"] = "subscription name cannot be empty";
        }
        if (!fields["subscription_amount"]) {
            formIsValid = false;
            errors["subscription_amount"] = "subscription amount cannot be empty";
        }
        if (!fields["subscription_description"]) {
            formIsValid = false;
            errors["subscription_description"] =
                "subscription description cannot be empty";
        }
        if (!fields["subscription_type"]) {
            formIsValid = false;
            errors["subscription_type"] = "subscription type cannot be empty";
        }
        // if (!fields["subscription_fromdate"]) {
        //     formIsValid = false;
        //     errors["subscription_fromdate"] = "subscription start Date cannot be empty";
        // }
        // if (!fields["subscription_todate"]) {
        //     formIsValid = false;
        //     errors["subscription_todate"] = "subscription end Date cannot be empty";
        // }
        if (!fields["subscription_days"]) {
            formIsValid = false;
            errors["subscription_days"] = "subscription Days cannot be empty";
        } else if (fields["subscription_days"] == 0) {
            formIsValid = false;
            errors["subscription_days"] = "subscription Days cannot be zero";
        }
        if (!fields["status"]) {
            formIsValid = false;
            errors["status"] = "status cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //onchange in editor
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState 
          });
        this.state.fields['subscription_description'] = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.forceUpdate();
 
        //console.log(this.state.fields['subscription_description']);
        this.state.editorState = editorState;
        this.state.fields['subscription_description'] = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.forceUpdate();
    };


    render() {
        const { fields, errors, coupon_data } = this.state;
        const { handle } = this.props.match.params;
        return (
            <section id="main_dashboard">
                <div className="container" id="main_front">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dash-section">
                                <div className="section-header">
                                    <ol className="breadcrumb">
                                        <li className="active">
                                            <Link to="/admin"> Dashboard</Link> &gt;
                                            <a> Subscription </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <section id="CMS_tab">
                                <div className="CMS_content create_calender">
                                    <div className="container">
                                        <div className="row">
                                            <div className="tab-header">
                                                {this.state.editSubscriptionId ? <h3>Update Subscription </h3> : <h3>Create Subscription </h3>}
                                            </div>
                                            <div id="reg_form">
                                                <form onSubmit={this.checkSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group ">
                                                                <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="subscription_name"
                                                                    value={this.state.fields["subscription_name"] || ""}
                                                                    onChange={this.handleChange.bind(
                                                                        this,
                                                                        "subscription_name"
                                                                    )}
                                                                    className="form-control"
                                                                    placeholder="Subscription Name"
                                                                />
                                                                <span className="cRed">
                                                                    {this.state.errors["subscription_name"]}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group ">
                                                                <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription Amount
                                                                </label>
                                                                <div className="col-md-12 p-0">
                                                                    <input
                                                                        type="number"
                                                                        name="subscription_amount"
                                                                        value={
                                                                            this.state.fields["subscription_amount"] ||
                                                                            ""
                                                                        }
                                                                        onChange={this.handleChange.bind(
                                                                            this,
                                                                            "subscription_amount"
                                                                        )}
                                                                        className="form-control"
                                                                        placeholder="Subscription Amount"
                                                                    />
                                                                    <span className="cRed">
                                                                        {this.state.errors["subscription_amount"]}
                                                                    </span>
                                                                </div>
                                                            </div>{" "}
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group ">
                                                                <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription Type
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="subscription_type"
                                                                    value={this.state.fields["subscription_type"] || ""}
                                                                    onChange={this.handleChange.bind(
                                                                        this,
                                                                        "subscription_type"
                                                                    )}
                                                                    className="form-control"
                                                                    placeholder="Subscription Type"
                                                                />
                                                                <span className="cRed">
                                                                    {this.state.errors["subscription_type"]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <div className="form-group ">
                                                                <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription Days
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="subscription_days"
                                                                    value={this.state.fields["subscription_days"] || ""}
                                                                    onChange={this.handleChange.bind(
                                                                        this,
                                                                        "subscription_days"
                                                                    )}
                                                                    className="form-control"
                                                                    placeholder="subscription Days"
                                                                />
                                                                <span className="cRed">
                                                                    {this.state.errors["subscription_days"]}
                                                                </span>
                                                                {/* <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription Start Date
                                                                </label>
                                                                <DatePicker
                                                                    name="subscription_fromdate"
                                                                    autoComplete="off"
                                                                    className="dateInput"
                                                                    placeholderText="Subscription Start Date"
                                                                    popperPlacement="bottom"
                                                                    minDate={new Date()}
                                                                    popperModifiers={{
                                                                        flip: {
                                                                            behavior: ["bottom"],
                                                                        },
                                                                    }}
                                                                    selected={this.state.fields["subscription_fromdate"] || ""}
                                                                    onChange={this.dateOnChange.bind(this, "subscription_fromdate")}
                                                                    dateFormat="yyyy-MM-d"
                                                                    calendarIcon
                                                                    showMonthDropdown
                                                                    adjustDateOnChange
                                                                />

                                                                <span className="cRed">
                                                                    {this.state.errors["subscription_fromdate"]}
                                                                </span> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group ">
                                                                <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription Description
                                                                </label>
                                                                {/* <textarea id="inputDescription" class="form-control" rows="4"
                                                                    value={this.state.fields["subscription_description"] || ""}
                                                                    onChange={this.handleChange.bind(
                                                                        this,
                                                                        "subscription_description"
                                                                    )}
                                                                ></textarea> */}

                                                                {handle ?
                                                                    <Editor
                                                                        editorState={this.state.editorState}
                                                                        toolbar={{
                                                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                                        }}
                                                                        //defaultEditorState={this.state.editorState ? this.state.editorState :"" }
                                                                        toolbarClassName="toolbarClassName"
                                                                        wrapperClassName="wrapperClassName"
                                                                        editorClassName="editorClassName"
                                                                        wrapperStyle={{ width: 800, border: "1px solid black" }}
                                                                        onEditorStateChange={this.onEditorStateChange}
                                                                    />
                                                                    :
                                                                    <Editor
                                                                        toolbar={{
                                                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                                        }}
                                                                        // editorState={this.state.editorState}
                                                                        //defaultEditorState={this.state.editorState ? this.state.editorState :"" }
                                                                        toolbarClassName="toolbarClassName"
                                                                        wrapperClassName="wrapperClassName"
                                                                        editorClassName="editorClassName"
                                                                        wrapperStyle={{ width: 800, border: "1px solid black" }}
                                                                        onEditorStateChange={this.onEditorStateChange}
                                                                    />
                                                                }
                                                                <span className="cRed">
                                                                    {this.state.errors["subscription_description"]}
                                                                </span>

                                                                {/* <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Subscription End Date
                                                                </label>
                                                                <DatePicker
                                                                    name="subscription_todate"
                                                                    autoComplete="off"
                                                                    className="dateInput"
                                                                    placeholderText="Subscription End Date"
                                                                    popperPlacement="bottom"
                                                                    minDate={new Date()}
                                                                    popperModifiers={{
                                                                        flip: {
                                                                            behavior: ["bottom"],
                                                                        },
                                                                    }}
                                                                    selected={this.state.fields["subscription_todate"] || ""}
                                                                    onChange={this.dateOnChange.bind(this, "subscription_todate")}
                                                                    dateFormat="yyyy-MM-d"
                                                                    calendarIcon
                                                                    showMonthDropdown
                                                                    adjustDateOnChange
                                                                />

                                                                <span className="cRed">
                                                                    {this.state.errors["subscription_todate"]}
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group ">
                                                                <label
                                                                    htmlFor="customRadio2"
                                                                    className="custom-control-label"
                                                                >
                                                                    Status
                                                                </label>
                                                                <div
                                                                    className="custom-control custom-radio"
                                                                    onChange={this.handleChange.bind(
                                                                        this,
                                                                        "status"
                                                                    )}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        value="1"
                                                                        checked={
                                                                            this.state.fields["status"] ==
                                                                            "1"
                                                                        }
                                                                        name="active"
                                                                    />{" "}
                                                                    Active &nbsp;&nbsp;&nbsp;
                                                                    <input
                                                                        type="radio"
                                                                        value="0"
                                                                        checked={
                                                                            this.state.fields["status"] ==
                                                                            "0"
                                                                        }
                                                                        name="inactive"
                                                                    />{" "}
                                                                    Inactive
                                                                </div>
                                                                <span className="cRed">
                                                                    {this.state.errors["status"]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-12">
                                                            <button
                                                                type="submit"
                                                                className="btn  btn-primary save_btn"
                                                            >
                                                                {this.state.editSubscriptionId ? "Update Subscription" : "Save Subscription"}
                                                            </button>{" "}
                                                            &nbsp;
                                                            <Link
                                                                to="/admin/Viewsubscriptions"
                                                                className="cancel_btn"
                                                            >
                                                                Cancel
                                                            </Link>{" "}
                                                            &nbsp; &nbsp;
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <ToastContainer />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
