import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH] :  {
    'hello' :  'Hello',
    'Login' :  'Login',
    'Language' :  'Language',
    'Consult a Doctor' :  'Consult a Doctor',
    'Video Consultation' :  'Video Consultation',
    'Video Consultaion over the app' :  'Video Consultaion over the app',
    'In person Visit' :  'In person Visit',
    'Meet the doctor in Clinic' :  'Meet the doctor in Clinic',
    'Order Medicine' :  'Order Medicine',
    'Book For Diagnostic' :  'Book For Diagnostic',
    'Care at Home' :  'Care at Home',
    'Consult Top Specialists now' : "Consult Top Specialists now",
    'Cough and cold' : "Cough and cold",

    "Book Full Body Checkups":"Book Full Body Checkups",
    "Book Diagnostic Tests":"Book Diagnostic Tests",
    "Home Care":"Home Care",
    "Trending Products":"Trending Products",
    "Doctors":"Doctors",

    'Consult now' : "Consult now",
    'Best Doctors from your nearest clinic' : "Best Doctors from your nearest clinic",
    'Jaya Clinic' : "Jaya Clinic",
    'Madhapur' : "Madhapur",
    'Directions' : "Directions",
    'ENT' : "ENT",
    'Cardiologist' : "Cardiologist",
    'MRP' : "MRP",
    'Add' : "Add",
    'off' : "off",
    'Download the MedleyMed app' : "Download the MedleyMed app",
    'Book appointments and health checkups' : "Book appointments and health checkups",
    "Order medicines and consult doctors online":"Order medicines and consult doctors online",
    'Get the link to download the app' : "Get the link to download the app",
    'Get App link' : "Get App link",
    'For patients' : "For patients",
    'Search for doctors' : "Search for doctors",
    'Search for Clinics' : "Search for Clinics",
    'Search for hospitals' : "Search for hospitals",
    'Our Services' : "Our Services",
    'Order Medicines' : "Order Medicines",
    'Book Lab Tests' : "Book Lab Tests",
    'Consult a Doctor' : "Consult a Doctor",
    'Our Policies' : "Our Policies",
    'Privacy Policy' : "Privacy Policy",
    'Terms and Conditions' : "Terms and Conditions",
    'Return Policy' : "Return Policy",
    'Social' : "Social",
    'Need Help' : "Need Help",
    'Our App' : "Our App",
    'App Store' : "App Store",
    'Play Store' : "Play Store",
    "Copyright":"Copyright",
    "MedleyMed":"MedleyMed",
    "All rights reserved":"All rights reserved",
    "This number will be used for doctor consultation related information, new offers. We will send a code for verification to this number":
    "This number will be used for doctor consultation related information, new offers. We will send a code for verification to this number",
    "Signup":"Signup",
    "Next":"Next",
    "Use Email ID":"Use Email ID",
    "Mobile Number":"Mobile Number",
    "We will send you an SMS with a confirmation code to this number":"We will send you an SMS with a confirmation code to this number",
    "Enter Mobile number":"Enter Mobile number",
    "Name":"Name",
    "Email":"Email",
    "Enter email id":"Enter email id",
    "Mobile number":"Mobile number",
    "Please keep your health profile updated for better consultation experience":"Please keep your health profile updated for better consultation experience",
    "Profile Completed":"Profile Completed",
    "Who is the Patient":"Who is the Patient",
    "Specify any long-term illness or chronic conditions present":"Specify any long-term illness or chronic conditions present",
    "if any":"If any",
    "Optional":"Optional",
    "Related medication":"Related medication",
    "Drug allergies present":"Drug allergies present",
    "Back":"Back",
    "Do it later":"Do it later",
    "Save":"Save",
    "Next":"Next",
    "Diabetes":"Diabetes",
    "Blood Pressure":"Blood Pressure",
    "Headache":"Headache",
    "Type here eg. Diabetes":"Type here eg. Diabetes",
    "Add family Member":"Add family Member",

    "Enter code":"Enter code",
    "Continue":"Continue",
    "Please enter your confirmation code":"Please enter your confirmation code",
    "Type here eg. Fever" :"Type here eg. Fever",
    "Enter the details for":"Enter the details for",
    "Patient Intake Process":"Patient Intake Process",
    "Purpose of consultation":"Purpose of consultation",
    "Specify Symptoms":"Specify Symptoms",
    "Provide Vital Informations":"Provide Vital Informations",
    "History of Past Illness":"History of Past Illness",
    "Enter your vital information":"Enter your vital information",
    "Height":"Height",
    "Weight":"Weight",
    "BMI":"BMI",
    "Breath Per Minute":"Breath Per Minute",

    "Normal":"Normal",
    "Medium":"Medium",
    "High":"High",
    "Very high":"Very high",
    
    "Obese":"Obese",
    "Over weight":"Over weight",
    "Underweight":"Underweight",
    "Normal weight":"Normal weight",

    "cm":"cm",
    "kg":"kg",

    "Temperature":"Temperature",
    "Pulse":"Pulse",
    "Blood Sugar":"Blood Sugar",
    "Respiratory Rate":"Respiratory Rate",
    "Systolic":"Systolic",

    "Specify Symptoms":"Specify Symptoms",
    "Symptoms related to":"Symptoms related to",
    "or Choose common symptoms":"or Choose common symptoms",
    "Search Symptom":"Search Symptom",
    "Select Symptom from body":"Select Symptom from body",
    "Add New Symptoms":"Add New Symptoms",

    "Rotate Model":"Rotate Model",
    "Click on the body model":"Click on the body model",
    "Abdominal pain":"Abdominal pain",
    "select body part":"select body part",
    "Search your symptoms by search or":"Search your symptoms by search or",

    "History of Past Illness":"History of Past Illness",
    "Past Medical History":"Past Medical History",
    "Social History":"Social History",
    "Chronic conditions":"Chronic conditions",
    "Chronic medication":"Chronic medication",
    "Add Current Medication":"Add Current Medication",
    "Drug Allergies":"Drug Allergies",
    "Add Medical Document":"Add Medical Document",
    "Upload Document":"Upload Document",

    "Logout":"Logout",

    "mg/dL":"mg/dL",
    "bpm":"bpm",
    "F":"F",
    "Kg/m2":"Kg/m2",
    "Feet.Inch":"Feet.Inch",

    "Patient Details":"Patient Details",
    "Patient Intake Process":"Patient Intake Process",
    "Filters":"Filters",
    "Clear all":"Clear all",
    "Languages":"Languages",
    "Sorting":"Sorting",
    "Choose Specialist":"Choose Specialist",
    "Gender":"Gender",
    "Experience":"Experience",
    "Mode":"Mode",
    "Ratings":"Ratings",
    "View Profile":"View Profile",
    "Book an Appointment":"Book an Appointment",
    "reviews":"reviews",
    "Registration No":"Registration No",
    "Fees":"Fees",
    "Education":"Education",
    "I can speak":"I can speak",
    "Practice":"Practice",
    "Feedback":"Feedback",
    "My Area of expertise":"My Area of expertise",
    "When do you want to consult a doctor":"When do you want to consult a doctor",
    "Please wait for doctor confirmation":"Please wait for doctor confirmation",
    "Doctor will confirm shortly":"Doctor will confirm shortly",
    "Doctor confirmed":"Doctor confirmed",
    "Please proceed to pay for the consultation":"Please proceed to pay for the consultation",
    "Pay Now":"Pay Now",
    "Schedule your appointment":"Schedule your appointment",
    "Or":"Or",
    "Consult later":"Consult later",
    "Afternoon":"Afternoon",
    "Evening":"Evening",
    "Morning":"Morning",
    "Night":"Night",
    "am":"am",
    "pm":"pm",


    "Select Doctor":"Select Doctor",
    "Available Doctors for Telemedicine":"Available Doctors for Telemedicine",
    "Dr":"Dr",
    "appointment with":"appointment with",
    "Consultation Time":"Consultation Time",
    "Purpose of visit":"Purpose of visit",
    "Symptoms":"Symptoms",
    "Vital Informations":"Vital Informations",
    "Social History":"Social History",
    "Patient Drug Allergies":"Patient Drug Allergies",
    "Uploaded Document":"Uploaded Document",
    "Fee Details":"Fee Details",
    "Doctor consultation Fees":"Doctor consultation Fees",
    "Have a Coupon Code":"Have a Coupon Code",
    "Enter Your Code":"Enter Your Code",
    "Apply":"Apply",
    "Net Payable Amount":"Net Payable Amount",
    "Accept Terms and conditions":"Accept Terms and conditions",
    "Book Now":"Book Now",
    "Male":"Male",
    "Female":"Female",
    "Your appointment will be start at":"Your appointment will be start at",
    "All":"All",
    "Choose Specialist":"Choose Specialist",
    "Find the doctor near you":"Find the doctor near you",
    "Diabetes":"Diabetes",
    "eg. I had jaundice 2 months back":"eg. I had jaundice 2 months back",



  },
};
// ENGLISH
