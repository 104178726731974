import React, { Component, useState } from "react";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import PatientHeader from "../patient/Sanarheader";
import PatientFooter from "../patient/Patientfooter";



export default class Diagnosticorderplaced extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidUpdate = () => {

  };

  // To get detais after first render
  componentDidMount = () => {

  };


  render() {
    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        {/* <I18nPropvider locale={this.state.Language}> */}
        <section id="order_placed">
          <div className="orderplaced_block">
            <div className="orderplaced_img">
              <img src="../images/patient/img/diagnostics/orderplaced.svg"></img>
            </div>
            <div className="orderplaced_desc">
              <h5>Order Successfully Placed</h5>
              <p>We will keep you posted about the status of the order via email</p>
            </div>
            {/* <div className="orderplaced_id">
              <span className="order_id">Order Id:</span>
              <span className="orderid_result">ID7689435784</span>
            </div> */}
            <div className="btn_continue">
              <button onClick={() => {
                window.location.href = Constant.imgurl + "/myOrders";
              }}>Diagnostic Orders</button>
            </div>
          </div>
        </section>

        <PatientFooter />
        {/* </I18nPropvider> */}
      </main>
    );
  }
}
