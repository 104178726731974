import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Patcss from "../../public/css/patient/style_pat.css";
import Constant from "../../constants";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import { reactLocalStorage } from "reactjs-localstorage";
import { Dropdown } from "semantic-ui-react";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const moment = require("moment");

export default class Patientheader extends Component {
  constructor(props) {
    super(props);

    const userData = ls.get("userObjEnc");

    this.state = {
      Language: "ENGLISH",
      Language_sel: "",
      userData: userData,
      showFacebookButton: true,
      userName: "",
      patientId: "",
      friendOptions: [
        {
          key: "en-us",
          text: " English ",
          value: "ENGLISH",
          image: {
            avatar: true,
            src: "https://icons.iconarchive.com/icons/icons-land/vista-flags/256/English-Language-Flag-1-icon.png",
          },
        },
        {
          key: "fr-ca",
          text: " German",
          value: "GERMAN",
          image: {
            avatar: true,
            src: "https://icons.iconarchive.com/icons/custom-icon-design/all-country-flag/256/Germany-Flag-icon.png",
          },
        },
        {
          key: "de-de",
          text: " French",
          value: "FRENCH",
          image: {
            avatar: true,
            src: "https://icons.iconarchive.com/icons/custom-icon-design/all-country-flag/256/France-Flag-icon.png",
          },
        },
        {
          key: "ja-jp",
          text: " Japanese",
          value: "JAPANESE",
          image: {
            avatar: true,
            src: "https://icons.iconarchive.com/icons/custom-icon-design/flag/256/Japan-Flag-icon.png",
          },
        },
      ],
    };

    this.goHome = this.goHome.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);

    if (Object.keys(userData).length > 0) {
      this.state.userName = userData.name;
    }
  }
  //  for remove LoggedSession
  removeLoggedSession = () => {
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == "") {
      window.location.href = "./patientlogin";
    }
    let userData = retrievedObject;

    let userId = userData.userID;
    Httpconfig.httptokenget(Constant.siteurl + "api/Users/logout/" + userId)
      .then((response) => {
        ls.remove("userObjEnc");
        // ls.remove("cartIdEnc");
        ls.remove("SelectedPatientIdEnc");
        ls.remove("PatientselectedAppointmentEnc");
        ls.remove("DoctorSelectedEnc");
        ls.remove("appointment_unique_id");
        ls.remove("ResubmitCount");
        localStorage.removeItem("patientToken");
        // localStorage.removeItem("cartIdEnc");

        setTimeout(() => (window.location.href = "./patientlogin"), 1000);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validateToken

  validateToken = () => {
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == null) {
      window.location.href = "./Patientlogin";
    }
    let userData = retrievedObject;
    let userId = userData.userID;
    if (localStorage.getItem("patientToken")) {
      let token = localStorage.getItem("patientToken");

      Httpconfig.httptokenpost(Constant.siteurl + "api/Users/checkToken", {
        userid: userId,
        token: token,
      })
        .then((response) => {
          if (response.data.status == "200" && response.data.error === true) {
            toast.error(response.data.data);

            ls.remove("userObjEnc");
            ls.remove("SelectedPatientIdEnc");
            ls.remove("PatientselectedAppointmentEnc");
            ls.remove("DoctorSelectedEnc");
            localStorage.removeItem("patientToken");

            setTimeout(() => (window.location.href = "./Patientlogin"), 3000);
            return;
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Your Session has timed out.Please Relogin");

      ls.remove("userObjEnc");
      ls.remove("SelectedPatientIdEnc");
      ls.remove("PatientselectedAppointmentEnc");
      ls.remove("DoctorSelectedEnc");
      localStorage.removeItem("patientToken");
      toast.error("Your Session has timed out.Please Relogin");
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  //  For selectedLanguage
  selectedLanguage = (e) => {
    this.state.Language = e.target.value;

    if (e.target.value === "ENGLISH") {
      this.state.Language_sel = e.target.value;
      this.state.Language = "en-us";
    } else if (e.target.value === "GERMAN") {
      this.state.Language = "de-de";
      this.state.Language_sel = e.target.value;
    } else if (e.target.value === "FRENCH") {
      this.state.Language = "fr-ca";
      this.state.Language_sel = e.target.value;
    } else if (e.target.value === "JAPANESE") {
      this.state.Language = "ja-jp";
      this.state.Language_sel = e.target.value;
    }

    localStorage.setItem("Language_selected", this.state.Language);
  };
  // make API calls once the component is initiated and configure the values into the state.

  ComponentWillMount = () => {
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == "") {
      window.location.href = "/";
      return false;
    }
  };
  // To get detais after first render

  componentDidMount() {
    let page = "";
    let patientId = "";
    let menuId = "";
    let url_path = window.location.pathname;
    if (url_path == "/") {
      page = "";
    } else {
      page = url_path.split("/");
    }
    var retrievedObject = "";
    retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == "") {
      toast.error("Please login to book an appointment", {});
      const interval = setInterval(() => {
        var retrievedObject1 = "";
        retrievedObject1 = ls.get("userObjEnc");
        if (page != "") {
          if (retrievedObject1 == "" && page != ",Patientlogin") {
            if (page != ",careathome") {
              window.location.href = "/Patientlogin";
            } else {
              window.location.href = "/careathome";
            }
            ///window.location.href = "/Patientlogin";
          }
        }
      }, 1000);
    }

    if (page[1]) {
      if (page[1] == "ordermedicinehome" || page[1] == "selectretailer") {
        menuId = 2;
      } else if (
        page[1] == "Patientlogin" ||
        page[1] == "" ||
        page[1] == "sanar"
      ) {
        menuId = "";
      } else if (
        page[1] == "Diagnostichome" ||
        page[1] == "Diagnosticcart" ||
        page[1] == "Diagnosticorderplaced" ||
        page[1] == "myOrders" ||
        page[1] == "manageAddress" ||
        page[1] == "Populartestviewall" ||
        page[1] == "Popularpackageviewall"
      ) {
        // alert(page[1]);

        menuId = 3;
      } else {
        menuId = 1;
      }
    }

    const interval = setInterval(() => {
      let patientId = "";
      if (retrievedObject != "") {
        let userData = retrievedObject;
        patientId = userData.patient_id;
        if (patientId != "") {
          if (menuId === 1) {
            this.getNotifications(patientId);
          }
          this.validateToken();
        }
      }
    }, 30000);
    if (retrievedObject != "") {
      let userData = retrievedObject;
      patientId = userData.patient_id;
      if (patientId != "") {
        let patientId = userData.patient_id;
      }
    }
    this.getProfileDetails(patientId);
    this.setState({
      patientId: patientId,
    });
    var lang = localStorage.getItem("Language_selected");
    if (lang == "undefined") {
      localStorage.setItem("Language_selected", "en-us");
      lang = "en-us";
    }
    if (lang != undefined) {
      if (lang == "en-us") {
        this.state.Language_sel = "ENGLISH";
      } else if (lang == "de-de") {
        this.state.Language_sel = "GERMAN";
      } else if (lang == "fr-ca") {
        this.state.Language_sel = "FRENCH";
      } else if (lang == "ja-jp") {
        this.state.Language_sel = "JAPANESE";
      } else {
        this.state.Language_sel = "ENGLISH";
      }
    } else {
      this.state.Language_sel = "ENGLISH";
      localStorage.setItem("Language_selected", "en-us");
    }
    this.onLoadModules();
    this.forceUpdate();
  }
  // For getting profil details
  getProfileDetails = (patientId) => {
    if (patientId != "") {
      let url_path = "api/Patients/" + patientId;
      let patientDob = "";
      Httpconfig.httptokenget(Constant.siteurl + url_path, {}).then(
        (response) => {
          let patientDob = "";

          if (response.data.status == 200 && response.data.error == false) {
            let patientProfileImage = "";
            let PatientGender = response.data.data[0].gender;
            let PatientName = response.data.data[0].name;
            if (response.data.data[0].profile_pic != null) {
              patientProfileImage =
                //Constant.imgurl + response.data.data[0].profile_pic;
                response.data.data[0].profile_pic;
            } else {
              if (PatientGender != null) {
                if (PatientGender.toLowerCase() == "female") {
                  patientProfileImage =
                    "/images/patient/img/Profile/Female_patient.svg";
                } else {
                  patientProfileImage =
                    "/images/patient/img/Profile/Male_patient.svg";
                }
              } else {
                patientProfileImage =
                  "/images/patient/img/Profile/Male_patient.svg";
              }
            }

            this.setState({
              patientProfileImage: patientProfileImage,
              PatientGender: PatientGender,
              PatientName: PatientName,
            });
          } else {
            if (
              response.data.status == 401 &&
              (response.data.message == "Token is Expair! Login Again." ||
                response.data.message ==
                  "Please Token is not valid! Login Again.")
            ) {
              ls.remove("userObjEnc");
              window.location.href = "./patientlogin";
            }
          }
        }
      );
    }
  };
  redirectDiagnostic = () => {
    if (this.state.patientId == "") {
      window.location.href = "./patientlogin";
    }
  };
  //  For getting notifications
  getNotifications = (patientId) => {
    let timeZone = moment.tz.guess();
    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/PatientAppointment/getPatientConsultNowAppointments/",
      {
        timeZone: timeZone,
        patient_id: patientId,
      }
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (response.data.data.length > 0) {
            response.data.data.map((notifcation) => {
              var dateTime =
                notifcation.appointment_date +
                " " +
                notifcation.appointment_time;
              let appointmentId = ls.get("appointmentIdEnc");
              var mindiff = moment().diff(dateTime, "minutes");
              if (mindiff < 5) {
                if (
                  (notifcation.status == 2 || notifcation.status == 3) &&
                  appointmentId == notifcation.appointment_id
                ) {
                  toast.dismiss();
                  toast.success(
                    "Dr." +
                      notifcation.doctor_name.charAt(0).toUpperCase() +
                      notifcation.doctor_name.slice(1) +
                      " has accepted your request \n Continue booking of Appointment",
                    { autoClose: 1000 }
                  );
                }
                if (
                  notifcation.status == 1 &&
                  appointmentId == notifcation.appointment_id
                ) {
                  toast.dismiss();
                  toast.error(
                    "Dr." +
                      notifcation.doctor_name.charAt(0).toUpperCase() +
                      notifcation.doctor_name.slice(1) +
                      " has declined your request \n Continue booking other Doctor",
                    { autoClose: 1000 }
                  );
                  this.updateNotificationStatus(notifcation.appointment_id);
                }
                if (notifcation.status == 0) {
                  toast.dismiss();
                  toast.warn(
                    "your Appointment request is in pending waiting for Dr." +
                      notifcation.doctor_name.charAt(0).toUpperCase() +
                      notifcation.doctor_name.slice(1) +
                      " confirmation",
                    { autoClose: 1000 }
                  );
                }
              } else {
                if (notifcation.status != 3)
                  this.updateNotificationStatus(notifcation.appointment_id);
              }
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For home page
  goHome = () => {
    window.location.href = Constant.imgurl;
  };

  //  For handling the fields
  handleOnChange = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);
    this.setState({ Language_sel: value });
    this.setState({ Language: key });
    this.props.onSelectLanguage(key);
    localStorage.setItem("Language_selected", key);
  };

  // For updating the notifications
  updateNotificationStatus = (appointmentId) => {};

  // for logout
  handleLogout = () => {
    $("#myDropdown").toggle();
  };
  // For redirect
  doRedirect = (field, event) => {
    window.location.href = "/" + field;
  };
  // For checking profile status
  checkProfileStatus = (patientId, field) => {
    if (patientId == "" && field != "careathome") {
      window.location.href = "/patientLogin";
      //return;
    } else {
      if (field == "careathome") {
        window.location.href = "/" + field;
        // return;
      }
    }
    //else {
    //   if (patientId == "" && field != "Patientprofile") {
    //     window.location.href = "/" + field;
    //     return;
    //   }
    // }

    Httpconfig.httptokenget(
      Constant.siteurl + "api/problem/checkIsProfileUpdated/" + patientId
    )
      .then((responseData) => {
        if (responseData.data.status == "200") {
          let healthProfileStatus = responseData.data.healthProfileStatus;
          if (healthProfileStatus == 1 && field == "Patientprofile") {
            window.location.href = "/patientConsultationPurpose";
          } else {
            window.location.href = "/" + field;
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For getting modules
  onLoadModules = () => {
    let page = "";
    let menuId = "";
    let url_path = window.location.pathname;
    if (url_path == "/") {
      page = "";
    } else {
      page = url_path.split("/");
    }

    this.state.clinicId = 1;
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Clinic/dashboard/" + this.state.clinicId
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        let modulesCount = Object.keys(response.data.data).length;
        let menuArray = [];
        let divDataStart = "";
        let divDataEnd = "";
        let modulesList = "";
        let classdropdown = "";
        let divsubmenu = "";
        if (modulesCount > 0) {
          modulesList = response.data.data.map((modulesData, num) => {
            if (modulesData.master_sub_module_tbls) {
              if (Object.keys(modulesData.master_sub_module_tbls).length > 0) {
                classdropdown = "nav-link dropdown-toggle";
                divDataStart = '<div class="dropdown-menu drop_bar_menu" >';
                divDataEnd = "</div>";
                divsubmenu = "dropdown-menu drop_bar_menu";
              } else {
                classdropdown = "nav-link";
                divDataStart = "";
                divDataEnd = "";
                divsubmenu = "dropdown-menu drop_bar_menu collapse-hide";
              }
            } else {
              classdropdown = "nav-link";
              divDataStart = "";
              divDataEnd = "";
              divsubmenu = "dropdown-menu drop_bar_menu collapse-hide";
            }

            if (page[1]) {
              if (
                page[1] == "ordermedicinehome" ||
                page[1] == "selectretailer"
              ) {
                menuId = 2;
              } else if (
                page[1] == "Patientlogin" ||
                page[1] == "" ||
                page[1] == "sanar"
              ) {
                menuId = "";
              } else if (
                page[1] == "Diagnostichome" ||
                page[1] == "Diagnosticcart" ||
                page[1] == "Diagnosticorderplaced" ||
                page[1] == "myOrders" ||
                page[1] == "manageAddress"
              ) {
                // alert(page[1]);

                menuId = 3;
              } else if (page[1] == "careathome") {
                menuId = 4;
              } else {
                menuId = 1;
              }
            }

            return (
              <div class="flex-column left_dropdown" id="navbar">
                <li
                  class={
                    modulesData.id == menuId
                      ? "nav-item dropdown active"
                      : "nav-item dropdown"
                  }
                  id={"menu-" + modulesData.id}
                >
                  {Object.keys(modulesData.master_sub_module_tbls).length >
                  0 ? (
                    <a
                      class={classdropdown}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div class="header_drop_img consult_doctor_img">
                        <img
                          class="nav_img"
                          src={Constant.imgurl + modulesData.image_path}
                        />
                      </div>
                      <div class="header_drop_content">
                        <p> {translate(modulesData.module_name)}</p>
                        <h6>
                          Book an appointment with health care provider
                          <br /> for online consultation{" "}
                        </h6>
                      </div>
                    </a>
                  ) : (
                    <a
                      class={classdropdown}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={this.checkProfileStatus.bind(
                        this,
                        this.state.patientId,
                        modulesData.master_sub_module_pages_tbls[0]
                          .web_reference_page_name
                        // "Diagnostichome"
                      )}
                    >
                      <div class="header_drop_img order_img">
                        <img
                          class="nav_img"
                          src={Constant.imgurl + modulesData.image_path}
                        />
                      </div>
                      <div class="header_drop_content">
                        {/* <p>Book for Diagnostic</p> */}
                        <p> {translate(modulesData.module_name)}</p>
                        {modulesData.module_name == "Book For Diagnostic" ? (
                          <h6>
                            Book lab tests & health
                            <br /> checkup packages
                          </h6>
                        ) : (
                          <h6>
                            Health care at your
                            <br /> door step.
                          </h6>
                        )}
                      </div>
                    </a>
                  )}

                  <div class={divsubmenu}>
                    <div
                      class=" dropdown-item "
                      onClick={this.checkProfileStatus.bind(
                        this,
                        this.state.patientId,
                        modulesData.master_sub_module_pages_tbls[0]
                          .web_reference_page_name
                        // "Diagnostichome"
                      )}
                    >
                      {Object.keys(modulesData.master_sub_module_tbls).length >
                      0
                        ? modulesData.master_sub_module_tbls.map(
                            (subData, num) => {
                              return (
                                <div class="row drop_flex vid_consult">
                                  <div class="col-md-2 col-sm-2 col-xs-2 no_padding">
                                    <img
                                      src={Constant.imgurl + subData.image_path}
                                    />
                                  </div>
                                  <div class="col-md-7 col-sm-7 col-xs-8 no_padding">
                                    <h4>
                                      {translate(subData.sub_module_name)}
                                    </h4>
                                    <h6>{translate(subData.tag_line)}</h6>
                                  </div>
                                  <div class="col-md-3 col-sm-3 col-xs-2 no_padding">
                                    <img
                                      class="selection_img"
                                      src="../images/patient/img/Homepage/Selection.svg"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </div>
                </li>
              </div>
            );
          });

          this.state.modulesList = modulesList;
          this.forceUpdate();
        }
      }
    });
  };
  //  For validate token
  validateToken = () => {
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == null) {
      window.location.href = "./Patientlogin";
    }
    let userData = retrievedObject;
    let userId = userData.userID;
    if (localStorage.getItem("patientToken")) {
      let token = localStorage.getItem("patientToken");

      Httpconfig.httptokenpost(Constant.siteurl + "api/Users/checkToken", {
        userid: userId,
        token: token,
      })
        .then((response) => {
          if (response.data.status == "200" && response.data.error === true) {
            ls.remove("userObjEnc");
            ls.remove("SelectedPatientIdEnc");
            ls.remove("PatientselectedAppointmentEnc");
            ls.remove("DoctorSelectedEnc");
            localStorage.removeItem("patientToken");
            toast.error(response.data.data);

            setTimeout(() => (window.location.href = "./Patientlogin"), 3000);
            return;
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      if (retrievedObject != null) {
        ls.remove("userObjEnc");
        ls.remove("SelectedPatientIdEnc");
        ls.remove("PatientselectedAppointmentEnc");
        ls.remove("DoctorSelectedEnc");
        localStorage.removeItem("patientToken");
        toast.error("Your Session has timed out.Please Relogin");
        setTimeout(() => window.location.reload(), 1000);
      }
    }
  };

  render() {
    const isLoggedIn = Object.keys(this.state.userData).length;
    let displayButton = "";
    if (isLoggedIn == 0) {
      displayButton = <div></div>;

      this.state.showFacebookButton = true;
    } else {
      displayButton = (
        <li class="log_li" onClick={this.handleLogout.bind(this)}>
          <div id="pro_dropdown">
            <div class="dropdown">
              <button onclick="myFunction()" class="dropbtn">
                {this.state.patientProfileImage != null ? (
                  <img src={this.state.patientProfileImage} />
                ) : this.state.PatientGender == "Female" ? (
                  <img
                    class="user_img"
                    src="/images/patient/img/Profile/Female_patient.svg"
                  />
                ) : (
                  <img
                    class="user_img"
                    src={
                      Constant.imgurl +
                      "/images/patient/img/Profile/Male_patient.svg"
                    }
                  />
                )}
                {this.state.PatientName ? this.state.PatientName : ""}
              </button>
              <img
                class="drop_img"
                src={
                  Constant.imgurl + "/images/patient/img/Homepage/Dropdown.svg"
                }
              />
              <div id="myDropdown" className="dropdown-content">
                <a href="/Patientprofile">Profile</a>
                <a href="#" onClick={this.removeLoggedSession}>
                  {translate("Logout")}
                </a>
              </div>
            </div>
          </div>
        </li>
      );

      this.state.showFacebookButton = false;
    }

    return (
      <div>
        <I18nPropvider locale={this.state.Language}>
          <section>
            <main id="main_ord_nav1">
              <header id="header" class="fixed-top">
                <div class="container">
                  <div
                    class="logo main_logo-r float-left"
                    onClick={this.goHome}
                  >
                    <a class="scrollto">
                      <img
                        src={
                          Constant.imgurl + "/images/patient/img/main-logo.png"
                        }
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                  </div>
                  {/* <section class="order_head_tabs float-left order-right_mob">
                    <div class="flex-column left_dropdown" id="navbar">
                      <li
                        class="nav-item dropdown"
                        onClick={this.redirectDiagnostic}
                      >
                        <a>
                          <div class="header_drop_img order_img">
                            <img
                              class="nav_img"
                              src="./images/patient/img/book-diagnostic.png"
                            />
                          </div>
                          <div class="header_drop_content">
                            <p>Book for Diagnostics</p>
                            <h6>
                              Get medicines delivered
                              <br /> To your door step
                            </h6>
                          </div>
                        </a>
                      </li>
                    </div>
                  </section> */}
                  <section class="order_head_tabs float-left">
                    {this.state.modulesList}
                  </section>
                  <nav class="main-nav float-right  d-lg-block order_nav">
                    {}
                    <ul class="second_menu">
                      {displayButton}
                      <li class="log_li drop_login_signup">
                        {!this.state.userData ? (
                          <div class="dropdown mx-1">
                            <button
                              class="btn dropdown-toggle login_btn"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Other Logins
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href={Constant.imgurl + "/doctor/login"}
                              >
                                Health Care Provider
                              </a>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href={Constant.imgurl + "/diagnostics/login"}
                              >
                                Diagnostic
                              </a>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href={Constant.imgurl + "/agent/login"}
                              >
                                Agent
                              </a>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href={Constant.imgurl + "/admin"}
                              >
                                Admin
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </header>
            </main>
          </section>
        </I18nPropvider>
        <ToastContainer />
      </div>
    );
  }
}
