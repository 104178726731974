import React, { Component, useState } from "react";
import { Slider } from "react-semantic-ui-range";

import { ToastContainer, toast } from "react-toastify";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Patientheader";
import PatientSideMenu from "../patient/Patientsidemenu";
import PatientFooter from "../patient/Patientfooter";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const numberRegExp = new RegExp("^[0-9]+$");

export default class PatientVitalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Language: "",
      userData: "",
      patientRelatives: [],
      vitalEditId: "",
      totalfilesLength: 0,
      invalidVital: true,
      masterPatientId: "",
      formErrors: {
        temperatureIsInvalid: false,
        pulserateIsInvalid: false,
        respiratoryrateIsInvalid: false,
        bloodsugarIsInvalid: false,
        bmiIsInvalid: false,
        iopIsInvalid: false,
        DiastolicIsInvalid: false,
        SystolicIsInvalid: false,
      },
      childFormErrors: {
        fetalheartrateIsInvalid: false,
      },
      formFields: {
        height: "",
        weight: "",
        bmi: "",
        temperature: "",
        blood_pressure_systolic: "",
        blood_pressure_diastolic: "",
        pulse_rate: "",
        blood_sugar: "",
        respiratory_rate: "",
        feet: "",
        inches: "",
        spo2: "",
        steps: "",
        patient_type: "adult",
        patient_id: "",
        clinic_id: "",
        ecg_reports: [],
        saved_ecg_reports: [],
        pain_rating_scale: "",
        IOP: "",
      },
      childFormFields: {
        fetal_heart_rate: "",
        head_circumference: "",
      },
      isUserSubscribed: false,
    };
  }

  // To get detais after first render
  componentDidMount = async () => {
    console.log("componentDidMount");
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject) {
      const { handle } = this.props.match.params;
      if (handle && handle != "undefined") {
        this.state.vitalEditId = handle;
        this.getVitalInfo();
      } else {
        this.state.formFields.clinic_id = retrievedObject.clinic_id;
        this.state.formFields.patient_id = retrievedObject.patient_id;
      }
      this.state.masterPatientId = retrievedObject.patient_id;
      await this.getUserSubscription();
      if (!this.state.isUserSubscribed) {
        window.location.href = "/ViewVitals";
      } else {
        this.getPatientRelatives();
      }
    } else {
      window.location.href = "/login";
    }
  };
  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  //To return colour codes based on vital values
  getClourCodes = (vitalName, value) => {
    console.log("vitalName, value", vitalName, value);
    if (vitalName === "bmi") {
      this.state.formErrors.bmiIsInvalid = false;
      if (Number(value) >= 18.5 && Number(value) <= 24.99) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 25 && Number(value) <= 29.99) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) > 30 && Number(value) <= 40) ||
        (Number(value) >= 15 && Number(value) <= 18.49)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.bmiIsInvalid = false;
          return <hr class="red_line"></hr>;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "bloodsugar") {
      this.state.formErrors.bloodsugarIsInvalid = false;
      if (Number(value) >= 90 && Number(value) <= 140) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 140 && Number(value) <= 199) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 200 && Number(value) <= 800) ||
        (Number(value) >= 40 && Number(value) <= 89)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.bloodsugarIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "temperature") {
      this.state.formErrors.temperatureIsInvalid = false;
      if (Number(value) >= 97 && Number(value) <= 99) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 99.1 && Number(value) <= 100.99) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 101 && Number(value) <= 110) ||
        (Number(value) >= 90 && Number(value) <= 96.99)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.temperatureIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "blood_pressure_systolic") {
      this.state.formErrors.SystolicIsInvalid = false;
      if (Number(value) >= 60 && Number(value) <= 89) {
        return <hr class="red_line"></hr>;
      } else if (Number(value) >= 90 && Number(value) <= 119) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 120 && Number(value) <= 129) {
        return <hr class="yellow_line"></hr>;
      } else if (Number(value) >= 130 && Number(value) <= 139) {
        return <hr class="orange_line"></hr>;
      } else if (Number(value) >= 140 && Number(value) <= 180) {
        return <hr class="red_line"></hr>;
      } else if (Number(value) >= 180 && Number(value) <= 200) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.SystolicIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "blood_pressure_diastolic") {
      this.state.formErrors.DiastolicIsInvalid = false;
      if (Number(value) >= 40 && Number(value) <= 59) {
        return <hr class="red_line"></hr>;
      } else if (Number(value) >= 60 && Number(value) <= 79) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 60 && Number(value) <= 79) {
        return <hr class="yellow_line"></hr>;
      } else if (Number(value) >= 80 && Number(value) <= 89) {
        return <hr class="orange_line"></hr>;
      } else if (Number(value) >= 90 && Number(value) <= 120) {
        return <hr class="red_line"></hr>;
      } else if (Number(value) >= 121 && Number(value) <= 150) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.DiastolicIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "pulse") {
      this.state.formErrors.pulserateIsInvalid = false;
      if (Number(value) >= 60 && Number(value) <= 80) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 81 && Number(value) <= 90) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 91 && Number(value) <= 150) ||
        (Number(value) >= 40 && Number(value) <= 59)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.pulserateIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "respiratory") {
      this.state.formErrors.respiratoryrateIsInvalid = false;
      if (Number(value) >= 15 && Number(value) <= 20) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 21 && Number(value) <= 25) {
        return <hr class="yellow_line"></hr>;
      } else if (
        (Number(value) >= 26 && Number(value) <= 40) ||
        (Number(value) >= 10 && Number(value) <= 14)
      ) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.formErrors.respiratoryrateIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "IOP") {
      this.state.formErrors.iopIsInvalid = false;
      if (Number(value) > 0 && Number(value) <= 21) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) > 21) {
        return <hr class="red_line"></hr>;
      } else {
        if (value === "0") {
          this.state.formErrors.iopIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    } else if (vitalName === "fetal_heart_rate") {
      this.state.childFormErrors.fetalheartrateIsInvalid = false;
      if (Number(value) >= 110 && Number(value) <= 169) {
        return <hr class="green_line"></hr>;
      } else if (Number(value) >= 170 && Number(value) <= 180) {
        return <hr class="red_line"></hr>;
      } else {
        if (Number(value)) {
          this.state.childFormErrors.fetalheartrateIsInvalid = true;
        }
        return <hr class="brown_line"></hr>;
      }
    }
    this.forceUpdate();
  };

  // When value changes in the fields
  handleChange = (field, event) => {
    if (
      event.target.value != "" &&
      !numberRegExp.test(event.target.value) &&
      field != "patient_type"
    ) {
      return;
    }
    if (this.state.formFields.patient_type === "adult") {
      let formFields = this.state.formFields;
      formFields[field] = event.target.value;
      if (field === "feet" || field === "inches" || field === "weight") {
        formFields["bmi"] = "";
        if (field === "inches")
          formFields["inches"] =
            formFields["inches"] > 12 ? 12 : formFields["inches"];
        formFields["height"] = "";
        if (formFields["feet"] && formFields["inches"]) {
          formFields["height"] = (
            Number(formFields["feet"]) * 30.48 +
            Number(formFields["inches"]) * 2.54
          ).toFixed(2);
        }
        if (formFields["height"] && formFields["weight"]) {
          formFields["bmi"] = (
            formFields["weight"] / Math.pow(formFields["height"] / 100, 2)
          ).toFixed(2);
        }
      }
      this.setState({ formFields });
    } else {
      let childFormFields = this.state.childFormFields;
      childFormFields[field] = event.target.value;
      this.setState({ childFormFields });
    }
  };

  //To create or update vital info
  createVitals = () => {
    let formErrorIsEmpty = true;
    let formIsInvalid = false;
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (this.state.formFields.patient_type === "adult") {
      for (const key in this.state.formFields) {
        if (key == "ecg_reports" || key == "saved_ecg_reports") {
          if (key == "saved_ecg_reports") {
            if (this.state.formFields[key].length > 0) {
              formData.append(
                `${key}`,
                `${JSON.stringify(this.state.formFields[key])}`
              );
              formErrorIsEmpty = false;
            } else {
              formData.append("saved_ecg_reports", JSON.stringify([]));
            }
          } else {
            if (this.state.formFields[key].length > 0) {
              this.state.formFields.ecg_reports.forEach((item) => {
                formData.append("ecg_reports", item);
              });
              formErrorIsEmpty = false;
            } else {
              formData.append("ecg_reports", JSON.stringify([]));
            }
          }
        } else {
          if (
            key != "patient_type" &&
            key != "patient_id" &&
            key != "clinic_id" &&
            key != "pain_rating_scale"
          ) {
            if (key === "feet" || key === "inches") {
              if (
                this.state.formFields["feet"] ||
                this.state.formFields["inches"]
              ) {
                if (
                  !this.state.formFields["feet"] ||
                  !this.state.formFields["inches"]
                ) {
                  toast.error("for height feet and inches required");
                  return;
                }
              }
            } else if (Number(this.state.formFields[key]) > 0) {
              formErrorIsEmpty = false;
            }
          }
          formData.append(`${key}`, `${this.state.formFields[key]}`);
        }
      }
      formIsInvalid = Object.keys(this.state.formErrors).some(
        (item) => this.state.formErrors[item]
      );
    } else {
      for (const key in this.state.childFormFields) {
        if (Number(this.state.childFormFields[key]) > 0) {
          formErrorIsEmpty = false;
        }
        formData.append(`${key}`, `${this.state.childFormFields[key]}`);
      }
      formData.append(`patient_id`, `${this.state.formFields.patient_id}`);
      formData.append(`clinic_id`, `${this.state.formFields.clinic_id}`);
      formData.append(`patient_type`, `${this.state.formFields.patient_type}`);
      formIsInvalid = Object.keys(this.state.childFormErrors).some(
        (item) => this.state.childFormErrors[item]
      );
    }

    if (this.state.totalfilesLength > 5) {
      toast.error("You can't add morethen 5 files");
      return;
    }
    if (formErrorIsEmpty) {
      toast.error("Minimum one vital is required");
      return;
    } else if (formIsInvalid) {
      toast.error("Invalid Vital");
      return;
    }

    //console.log("this.state.formFields", this.state.formFields);
    //return;
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/VitalInformation/createVitals",
      formData,
      config
    ).then((response) => {
      if (response.data.status == 200 && !response.data.error) {
        toast.success(response.data.message);
        setTimeout(() => this.props.history.push("/ViewVitals"), 2000);
      } else {
        toast.error(response.data.message);
      }
    });
  };

  //To get Patient relatives info
  getPatientRelatives = () => {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/Patients/findpatientrealtives/" +
        this.state.masterPatientId
    ).then((response) => {
      this.setState({ patientRelatives: response.data.data });
    });
  };

  //To get vitals for edits the data
  getVitalInfo = () => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/VitalInformation/" + this.state.vitalEditId
    ).then((response) => {
      if (response.data.data[0].patient_type === "adult") {
        let realFeet = (response.data.data[0].height * 0.3937) / 12;
        let feet = Math.floor(realFeet);
        let inches = Math.round((realFeet - feet) * 12);
        response.data.data[0].feet = feet;
        response.data.data[0].inches = inches;
        this.setState({ formFields: response.data.data[0] });
        this.state.formFields.saved_ecg_reports = this.state.formFields
          .ecg_reports
          ? JSON.parse(this.state.formFields.ecg_reports)
          : [];
        this.state.formFields.ecg_reports = [];
      } else {
        let { fetal_heart_rate, head_circumference, id } =
          response.data.data[0];
        let childFormFields = { fetal_heart_rate, head_circumference, id };
        this.setState({ childFormFields: childFormFields });
        this.state.formFields.patient_type = response.data.data[0].patient_type;
        this.state.formFields.patient_id = response.data.data[0].patient_id;
        this.state.formFields.clinic_id = response.data.data[0].clinic_id;
      }
    });
  };

  //To upload ECG reports
  uploadFile = (e) => {
    this.state.totalfilesLength = e.target.files.length;
    if (
      this.state.formFields.saved_ecg_reports &&
      this.state.formFields.saved_ecg_reports.length > 0
    )
      this.state.totalfilesLength += this.state.totalfilesLength;
    if (this.state.totalfilesLength <= 5) {
      this.state.formFields.ecg_reports = [...e.target.files];
    } else {
      toast.error("You can't add morethen 5 files");
    }
  };

  // Pain Rating scale
  painRatingScale = (id) => {
    this.state.formFields.pain_rating_scale = id;
    this.forceUpdate();
  };

  // Get subscription details of the Patient
  getUserSubscription = async () => {
    let userSubscriptionData = await Httpconfig.httptokenpost(
      Constant.siteurl + "api/subscription/getusersubscription",
      {
        patient_id: this.state.masterPatientId,
        status: 1,
      }
    ).catch((error) => {
      console.log(error);
    });
    if (userSubscriptionData.data.data.length > 0) {
      this.state.isUserSubscribed = true;
    }
    // .then((response) => {
    //   var daysDifference = 0;
    //   var subscriptionName = "";
    //   this.items = response.data.data.map((item, key) => {
    //     var currentDate = new Date();
    //     daysDifference = item.subscription_todate ? moment(item.subscription_todate).diff(currentDate, "days") : 0;
    //     subscriptionName = item.master_subscription_tbl ? item.master_subscription_tbl.subscription_name : "";
    //   });
    //   //alert(daysDifference);
    //   this.setState({
    //     daysDifference: daysDifference,
    //     subscriptionName: subscriptionName
    //   })
    // })
  };

  render() {
    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="add_vital_page">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <PatientSideMenu />
                </div>
                <div class="col-lg-9 col-md-12">
                  <div class="container">
                    <div class="row">
                      <div class="vital_head">
                        <h2>
                          <span>
                            <img
                              src="../images/patient/img/arrow-back.svg"
                              onClick={() => {
                                window.location.href = "/ViewVitals";
                              }}
                            />
                          </span>
                          {this.props.match.params.handle
                            ? "Update Vital Sheet"
                            : "Add Vital Sheet"}
                        </h2>
                      </div>
                      <div class="vital_radio">
                        <form>
                          <div class="form-group">
                            <input
                              type="radio"
                              id="adult"
                              name="fav_language"
                              value="adult"
                              checked={
                                this.state.formFields.patient_type === "adult"
                              }
                              onChange={(e) => {
                                this.state.formFields.patient_type =
                                  e.target.value;
                                this.forceUpdate();
                              }}
                            />
                            <label for="adult">Adult</label>
                          </div>
                          <div class="form-group">
                            <input
                              type="radio"
                              id="child"
                              name="fav_language"
                              value="child"
                              checked={
                                this.state.formFields.patient_type === "child"
                              }
                              onChange={(e) => {
                                this.state.formFields.patient_type =
                                  e.target.value;
                                this.forceUpdate();
                              }}
                            />
                            <label for="child">Child</label>
                          </div>
                          <div class="form-group">
                            <label>Select Member</label>
                            <select
                              onChange={(e) => {
                                this.state.formFields.patient_id =
                                  e.target.value;
                              }}
                              name="slot_time"
                              defaultValue=""
                              className="form-control"
                            >
                              <option value="" disabled={true}>
                                Select Member
                              </option>
                              {this.state.patientRelatives.map((item) => {
                                return (
                                  <option
                                    value={item.id}
                                    selected={
                                      this.state.formFields.patient_id ==
                                      item.id
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </form>
                      </div>
                      {this.state.formFields.patient_type === "adult" ? (
                        <div class="vital_main">
                          <div class="vital_list_box">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}

                                    <h2>Height</h2>
                                    <div class="row">
                                      <div class="item col-md-6">
                                        <input
                                          type="text"
                                          class="form-control vit_inp"
                                          value={this.state.formFields.feet}
                                          maxLength="1"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "feet"
                                          )}
                                        />
                                        <span>Feet</span>
                                      </div>
                                      <div class="item col-md-6">
                                        <input
                                          type="text"
                                          class="form-control vit_inp"
                                          value={this.state.formFields.inches}
                                          maxLength="2"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "inches"
                                          )}
                                        />
                                        <span>Inches</span>
                                      </div>
                                    </div>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Weight</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.weight}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "weight"
                                      )}
                                    />
                                    <span>kg</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>BMI</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      disabled={true}
                                      value={this.state.formFields.bmi}
                                      // onChange={this.handleChange.bind(
                                      //   this,
                                      //   "bmi"
                                      // )}
                                    />
                                    <span>kg/m2</span>
                                  </div>
                                  {this.getClourCodes(
                                    "bmi",
                                    this.state.formFields.bmi
                                  )}
                                </div>
                                {this.state.formErrors.bmiIsInvalid ? (
                                  <span className="cRed">
                                    BMI should be 15-40
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Temperature</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.temperature}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "temperature"
                                      )}
                                    />
                                    <span>°F</span>
                                  </div>
                                  {this.getClourCodes(
                                    "temperature",
                                    this.state.formFields.temperature
                                  )}
                                </div>
                                {this.state.formErrors.temperatureIsInvalid ? (
                                  <span className="cRed">
                                    Temperature should be 90-110
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Blood Pressure</h2>
                                    <div class="row  b_list">
                                      <div class="item col-md-6 no_padding">
                                        <input
                                          type="text"
                                          class="form-control vit_inp"
                                          value={
                                            this.state.formFields
                                              .blood_pressure_systolic
                                          }
                                          onChange={this.handleChange.bind(
                                            this,
                                            "blood_pressure_systolic"
                                          )}
                                        />
                                        <span>mmHg</span>

                                        <div>
                                          {this.getClourCodes(
                                            "blood_pressure_systolic",
                                            this.state.formFields
                                              .blood_pressure_systolic
                                          )}
                                        </div>
                                        {this.state.formErrors
                                          .SystolicIsInvalid ? (
                                          <span className="cRed">
                                            Systolic Should be 60-200
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div class="item col-md-6 no_padding">
                                        <input
                                          type="text"
                                          class="form-control vit_inp"
                                          value={
                                            this.state.formFields
                                              .blood_pressure_diastolic
                                          }
                                          onChange={this.handleChange.bind(
                                            this,
                                            "blood_pressure_diastolic"
                                          )}
                                        />
                                        <span>mmHg</span>
                                        <div>
                                          {this.getClourCodes(
                                            "blood_pressure_diastolic",
                                            this.state.formFields
                                              .blood_pressure_diastolic
                                          )}
                                        </div>
                                        {this.state.formErrors
                                          .DiastolicIsInvalid ? (
                                          <span className="cRed">
                                            Diastolic Should be 40-150
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Pulse</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.pulse_rate}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "pulse_rate"
                                      )}
                                    />
                                    <span>beats per minute</span>
                                  </div>
                                  {this.getClourCodes(
                                    "pulse",
                                    this.state.formFields.pulse_rate
                                  )}
                                </div>
                                {this.state.formErrors.pulserateIsInvalid ? (
                                  <span className="cRed">
                                    Pulse should be 40-150
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Blood Sugar</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.blood_sugar}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "blood_sugar"
                                      )}
                                    />
                                    <span>mg/dL</span>
                                  </div>
                                  {this.getClourCodes(
                                    "bloodsugar",
                                    this.state.formFields.blood_sugar
                                  )}
                                </div>
                                {this.state.formErrors.bloodsugarIsInvalid ? (
                                  <span className="cRed">
                                    Blood Sugar should be 40-800
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Respiratory Rate</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={
                                        this.state.formFields.respiratory_rate
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "respiratory_rate"
                                      )}
                                    />
                                    <span>breaths per minute</span>
                                  </div>
                                  {this.getClourCodes(
                                    "respiratory",
                                    this.state.formFields.respiratory_rate
                                  )}
                                </div>
                                {this.state.formErrors
                                  .respiratoryrateIsInvalid ? (
                                  <span className="cRed">
                                    Respiratory Rate should be 10-40
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>ECG</h2>
                                    <input
                                      type="file"
                                      class="form-control"
                                      multiple="true"
                                      onChange={this.uploadFile}
                                    />
                                    {this.state.formFields.saved_ecg_reports &&
                                    this.state.formFields.saved_ecg_reports
                                      .length > 0
                                      ? this.state.formFields.saved_ecg_reports.map(
                                          (item, index) => {
                                            return (
                                              <a
                                                href={Constant.imgurl + item}
                                                class="pr-3"
                                                target="_blank"
                                              >
                                                file {index + 1}
                                              </a>
                                            );
                                          }
                                        )
                                      : ""}
                                    {/* <button type="button" class="view_file_btn">View File</button> */}
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Heart Rate</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.heartrate}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "heart_rate"
                                      )}
                                    />
                                    <span>/min</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>SpO2</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.spo2}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "spo2"
                                      )}
                                    />
                                    <span>%</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Steps</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.steps}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "steps"
                                      )}
                                    />
                                    <span>/day</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>IOP</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={this.state.formFields.IOP}
                                      onChange={this.handleChange.bind(
                                        this,
                                        "IOP"
                                      )}
                                    />
                                    <span>mmHg</span>
                                  </div>
                                  {this.getClourCodes(
                                    "IOP",
                                    this.state.formFields.IOP
                                  )}
                                </div>
                                {this.state.formErrors.iopIsInvalid ? (
                                  <span className="cRed">
                                    IOP should be grater then 0
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="vital_painbox">
                                  <div className="painface_header">
                                    <p>Wong-Baker FACES® Pain Rating Scale</p>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div
                                        className="pain_box"
                                        onClick={this.painRatingScale.bind(
                                          this,
                                          0
                                        )}
                                      >
                                        {this.state.formFields
                                          .pain_rating_scale === 0 ? (
                                          <img src="../images/patient/img/diagnostics/0pain_blue_face.svg" />
                                        ) : (
                                          <img src="../images/patient/img/diagnostics/0pain.svg" />
                                        )}
                                        <p>No Hurt</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div
                                        className="pain_box"
                                        onClick={this.painRatingScale.bind(
                                          this,
                                          2
                                        )}
                                      >
                                        {this.state.formFields
                                          .pain_rating_scale === 2 ? (
                                          <img src="../images/patient/img/diagnostics/2pain_blue_face.svg" />
                                        ) : (
                                          <img src="../images/patient/img/diagnostics/2pain.svg" />
                                        )}
                                        <p>Hurts Little Bit</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div
                                        className="pain_box"
                                        onClick={this.painRatingScale.bind(
                                          this,
                                          4
                                        )}
                                      >
                                        {this.state.formFields
                                          .pain_rating_scale === 4 ? (
                                          <img src="../images/patient/img/diagnostics/4pain_blue_face.svg" />
                                        ) : (
                                          <img src="../images/patient/img/diagnostics/4pain.png" />
                                        )}
                                        <p>Hurts Little More</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div
                                        className="pain_box"
                                        onClick={this.painRatingScale.bind(
                                          this,
                                          6
                                        )}
                                      >
                                        {this.state.formFields
                                          .pain_rating_scale === 6 ? (
                                          <img src="../images/patient/img/diagnostics/6pain_blue_face.svg" />
                                        ) : (
                                          <img src="../images/patient/img/diagnostics/6pain.png" />
                                        )}
                                        <p>Hurts Even More</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div
                                        className="pain_box"
                                        onClick={this.painRatingScale.bind(
                                          this,
                                          8
                                        )}
                                      >
                                        {this.state.formFields
                                          .pain_rating_scale === 8 ? (
                                          <img src="../images/patient/img/diagnostics/8pain_blue_face.svg" />
                                        ) : (
                                          <img src="../images/patient/img/diagnostics/8pain.svg" />
                                        )}
                                        <p>Hurts Whole Lot</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div
                                        className="pain_box"
                                        onClick={this.painRatingScale.bind(
                                          this,
                                          10
                                        )}
                                      >
                                        {this.state.formFields
                                          .pain_rating_scale === 10 ? (
                                          <img src="../images/patient/img/diagnostics/10pain_blue_face.svg" />
                                        ) : (
                                          <img src="../images/patient/img/diagnostics/10pain.png" />
                                        )}
                                        <p>Hurts Worst</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="vital_btns">
                            <button
                              type="button"
                              className="save_btn"
                              onClick={this.createVitals}
                            >
                              Save
                            </button>
                            <button type="button" className="cancel_btn" onClick={() => {
                                window.location.href = "/ViewVitals";
                              }}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div class="vital_main">
                          <div class="vital_list_box">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Fetal heart rate</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={
                                        this.state.childFormFields
                                          .fetal_heart_rate
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "fetal_heart_rate"
                                      )}
                                    />
                                    <span>Beats/min</span>
                                  </div>
                                  {this.getClourCodes(
                                    "fetal_heart_rate",
                                    this.state.childFormFields.fetal_heart_rate
                                  )}
                                </div>
                                {this.state.childFormErrors
                                  .fetalheartrateIsInvalid ? (
                                  <span className="cRed">
                                    Fetal heart rate should be 110-180
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Head Circumference</h2>
                                    <input
                                      type="text"
                                      class="form-control vit_inp"
                                      value={
                                        this.state.childFormFields
                                          .head_circumference
                                      }
                                      onChange={this.handleChange.bind(
                                        this,
                                        "head_circumference"
                                      )}
                                    />
                                    <span>cm</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="vital_btns">
                            <button
                              type="button"
                              className="save_btn"
                              onClick={this.createVitals}
                            >
                              Save
                            </button>
                            <button type="button" className="cancel_btn">
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
