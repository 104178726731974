import React, { Component } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import FileBase64 from "react-file-base64";

const fs = require("fs");

export default class CreateDoctorAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      clinic_data: "",
      files: [],
      fields: {},
      type: "password",
      img: "/images/doctor-img/Login screens/hide_icon.svg",
      update: 0,
      options: [],
    };
    this.showPassword = this.showPassword.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.fetchDoctorListData();
    this.getDoctorAdminInfo(handle);

    if (handle) {
      this.setState({
        update: 1,
      });
    }
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;

    this.setState({ fields });
  };

  //  For getting the data
  fetchDoctorListData() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Doctor/getAllDoctors")
      .then((response) => {
        this.setState({
          clinic_details: response.data.data,
        });
      })
      .catch((error) => { });
  }

  // To get all the ResponderInfo
  getDoctorAdminInfo(handle) {
    if (handle) {
      Httpconfig.httptokenget(
        Constant.siteurl + "api/AdminDoctors/" + handle
      ).then((response) => {
        this.setState({
          fields: {
            name: response.data.data.tbl_user.name,
            mobile_number: response.data.data.tbl_user.mobile_number,
            email: response.data.data.tbl_user.email,
            password: response.data.data.tbl_user.password,
            user_type: "doctor",
            clinic_id: response.data.data.admin_doctorid,
            gender: response.data.data.gender,
            dob: response.data.data.dob,
          },
        });
      });
    }
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateDoctorAdmin(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createDoctorAdmin(event);
    } else {
      toast.warn("Form has errors.");
    }
  }

  // creates new controller
  createDoctorAdmin = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/AdminDoctors/AdminDoctorRegistration",
      {
        name: fields["name"],
        mobile_no: fields["mobile_number"],
        email: fields["email"],
        password: fields["password"],
        clinic_id: 1,
        admin_doctorid: fields["clinic_id"],
        user_type: "admindoctor",
        gender: fields["gender"],
        dob: fields["dob"],
        state: "",
        language: "",
      }
    )
      .then((response) => {
        if (response.data.status === 204) {
          toast.error(response.data.message);
          return false;
        } else {
          toast.success("Successfully Created Doctor Admin");
          setTimeout(
            () => this.props.history.push("/admin/ViewDoctorAdmin"),
            1500
          );
        }
      })
      .catch((error) => { });
  };

  // updates controller
  updateDoctorAdmin = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    Httpconfig.httptokenput(
      Constant.siteurl +
      "api/AdminDoctors/AdminDoctorRegistrationUpdate/" +
      handle,
      {
        name: fields["name"],
        mobile_number: fields["mobile_number"],
        email: fields["email"],
        password: fields["password"],
        clinic_id: 1,
        admin_doctorid: fields["clinic_id"],
        doctor_name: fields["doctor_name"],
        user_type: "admindoctor",
        gender: fields["gender"],
        dob: fields["dob"],
        state: "",
        language: "",
      }
    )
      .then((response) => {
        toast.success("Successfully Updated Doctor Admin");
        setTimeout(
          () => this.props.history.push("/admin/ViewDoctorAdmin"),
          2000
        );
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // For handling the fields

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    var pattern = /^[a-zA-Z0-9]{3,20}$/g;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    } else if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is invalid";
      }
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    } else if (
      fields["password"].length < 8 ||
      fields["password"].length > 20
    ) {
      formIsValid = false;
      errors["password"] = "Password shuold contain 8-20 characters";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20}$/.exec(
        fields["password"]
      )
    ) {
      formIsValid = false;
      errors["password"] =
        "Required one upper case, one small case, one number and one special character";
    }
    if (!fields["mobile_number"]) {
      formIsValid = false;
      errors["mobile_number"] = "Phone number cannot be empty";
    } else if (fields["mobile_number"].length < 10) {
      formIsValid = false;
      errors["mobile_number"] = "Phone number invalid";
    }

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Doctor Admin Name cannot be empty";
    }

    if (!fields["clinic_id"]) {
      formIsValid = false;
      errors["clinic_id"] = "Doctor cannot be empty";
    }

    if (!fields["gender"]) {
      formIsValid = false;
      errors["gender"] = "gender cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  //  For showing the password
  showPassword() {
    let type = this.state.type;
    if (this.state.type) {
      if (this.state.type == "input") {
        this.state.type = "password";
        this.state.img = "/images/doctor-img/Login screens/hide_icon.svg";
      } else {
        this.state.type = "input";
        this.state.img = "/images/doctor-img/Login screens/unhide_icon.svg";
      }
    }
    this.forceUpdate();
  }

  render() {
    const { fields, errors, clinic_details } = this.state;

    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      {this.state.update == 1 ? (
                        <a> Update Doctor Admin </a>
                      ) : (
                        <a> Create Doctor Admin </a>
                      )}
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        {this.state.update == 1 ? (
                          <h3>Update Doctor Admin </h3>
                        ) : (
                          <h3>Create Doctor Admin </h3>
                        )}
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Doctor Admin Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  value={this.state.fields["name"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "name"
                                  )}
                                  className="form-control"
                                  placeholder="Doctor Admin Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["name"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Select Gender</label>
                                <select
                                  name="gender"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "gender"
                                  )}
                                  value={this.state.fields["gender"] || ""}
                                  className="form-control"
                                >
                                  <option value="">Select Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                                <span className="cRed">
                                  {this.state.errors["gender"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Select Doctor</label>
                                <select
                                  name="clinic_id"
                                  className="form-control"
                                  value={this.state.fields["clinic_id"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_id"
                                  )}
                                >
                                  <option value="">Select Doctor</option>
                                  {clinic_details &&
                                    clinic_details.map((clinic_details, i) => {
                                      return (
                                        <option value={clinic_details.id}>
                                          {clinic_details.tbl_user.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["clinic_id"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Mobile Number</label>
                                <input
                                  type="text"
                                  name="mobile_number"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  minLength="10"
                                  maxlength="10"
                                  value={
                                    this.state.fields["mobile_number"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "mobile_number"
                                  )}
                                  className="form-control"
                                  placeholder="Mobile Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["mobile_number"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  value={this.state.fields["email"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "email"
                                  )}
                                  className="form-control"
                                  placeholder="Email"
                                />
                                <span className="cRed">
                                  {this.state.errors["email"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Password</label>
                                <input
                                  type={this.state.type}
                                  name="password"
                                  value={this.state.fields["password"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "password"
                                  )}
                                  className="form-control log_input"
                                  placeholder="password"
                                />
                                <img
                                  class="password_view"
                                  onClick={this.showPassword}
                                  src={this.state.img}
                                />
                                <span className="cRed">
                                  {this.state.errors["password"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-12">
                              {this.state.update == 1 ? (
                                <button
                                  type="submit"
                                  className="btn  btn-primary save_btn"
                                >
                                  Update Doctor Admin
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn  btn-primary save_btn"
                                >
                                  Save Doctor Admin
                                </button>
                              )}{" "}
                              &nbsp;
                              <Link
                                to="/admin/ViewDoctorAdmin"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div
          id="lightbox"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <button
              type="button"
              class="close hidden"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
            <div class="modal-content">
              <div class="modal-body">
                <img src={this.state.zoomimage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
