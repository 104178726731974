import React, { Component } from "react";
import $ from "jquery";
import toast from "../helpers/toast";
import PatientHeader from "../patient/Patientheader";
import PatientSideMenu from "../patient/Patientsidemenu";
import * as SecureLS from "secure-ls";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import ReactExport from "react-data-export";
import { Link } from "react-router-dom";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");



export default class ViewClinics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      vitalInfo: "",
      patientRelatives: [],
      selectedRelativeId: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeBarPoints: [],
      selectedPtientVitalArray: [],
      vitalReportXlsArray: [],
      dateSelected: "",
    };

  }

  // To get detais after first render
  componentDidMount = () => {

    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject) {
      this.state.patientId = retrievedObject.patient_id
      this.state.selectedRelativeId = retrievedObject.patient_id
      this.getPatientVitals()
      this.getPatientRelatives()
      this.getSubscriptionDetails(retrievedObject.patient_id);
    } else {
      window.location.href = "/login";
    }
  }

  //To get Patient Vitals
  getPatientVitals = () => {
    let date = this.state.selectedDate.split("T")[0]
    if (!date) {
      date = this.state.selectedDate
    }
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/VitalInformation/getVitals", {
      patientId: this.state.selectedRelativeId,
      date: date
    }
    ).then((response) => {

      var allVitalsdownload = [];
      this.state.selectedPtientVitalArray = response.data.data.map((item, num) => {

        let realFeet = ((item.height * 0.393700) / 12);
        let feet = Math.floor(realFeet);
        let inches = Math.round((realFeet - feet) * 12);
        let feetInches = feet + "'" + inches
        item.feetInches = feetInches
        allVitalsdownload.push({
          sno: num + 1,
          height: feetInches,
          weight: item.weight ? item.weight : "",
          bmi: item.bmi ? item.bmi : "",
          temperature: item.temperature ? item.temperature : "",
          pulse: item.pulse_rate ? item.pulse_rate : "",
          blood_sugar: item.blood_sugar ? item.blood_sugar : "",
          respiratory_rate: item.respiratory_rate ? item.respiratory_rate : "",
          blood_pressure_diastolic: item.blood_pressure_diastolic ? item.blood_pressure_diastolic : "",
          blood_pressure_systolic: item.blood_pressure_systolic ? item.blood_pressure_systolic : "",
          heart_rate: item.heart_rate ? item.heart_rate : "",
          spo2: item.spo2 ? item.spo2 : " ",
          ecg: item.ecg_reports ? item.ecg_reports : "",
          steps: item.steps ? item.steps : "",
          iop: item.IOP ? item.IOP : "",
          name: item.patient_tbl['name'],
          pain_rating_scale: item.pain_rating_scale ? item.pain_rating_scale : "",
          fetal_heart_rate: item.fetal_heart_rate ? item.fetal_heart_rate : "",
          head_circumference: item.head_circumference ? item.head_circumference : "",
          date: item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY HH:mm A") : "",

        })
        return item
      })
      this.forceUpdate();
      if (allVitalsdownload.length > 0) {

        this.state.allVitalsdownload = allVitalsdownload;
      } else {
        this.state.allVitalsdownload = "";
      }

      this.forceUpdate();
      this.state.vitalInfo = this.state.selectedPtientVitalArray[0]
      if (!this.state.vitalInfo) this.state.vitalInfo = { patient_type: "adult" }
      if (this.state.vitalInfo) this.state.vitalReportXlsArray.push(this.state.vitalInfo)
      this.state.selectedDate = this.state.vitalInfo.createdAt ? this.state.vitalInfo.createdAt : this.state.selectedDate
      //this.forceUpdate();
      this.timeBarPointsRendering()
    })
  }

  //Filter vital info based on the time seleted in time bar
  vitalTimeFilter = (vitalId) => {
    let selctedTimeData = this.state.selectedPtientVitalArray.filter((vital) => {
      return vital.id == vitalId
    })
    selctedTimeData.push()
    selctedTimeData[0].date = moment(selctedTimeData[0].createdAt).format("DD/MM/YYYY HH:mm A");
    this.state.vitalInfo = selctedTimeData[0]
    this.state.vitalReportXlsArray = []
    this.state.vitalReportXlsArray.push(this.state.vitalInfo)
    this.timeBarPointsRendering()
  }

  //To render html time bar 
  timeBarPointsRendering = () => {
    this.state.timeBarPoints = this.state.selectedPtientVitalArray.map((vaital) => {
      return (<div class={this.state.vitalInfo.id == vaital.id ? "stepwizard-step active" : "stepwizard-step"} id={vaital.createdAt}
        onClick={() => { this.vitalTimeFilter(vaital.id) }}>
        <a type="button" class="btn btn-default btn-circle"></a>
        <p>{moment(vaital.createdAt).format("HH:mm A")}</p>
      </div>)
    })
    this.forceUpdate()
  }

  //To get Patient relatives info
  getPatientRelatives = () => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtives/" + this.state.patientId
    ).then((response) => {
      this.setState({ patientRelatives: response.data.data })
    })
  }

  //To return colour codes based on vital values
  getClourCodes = (vitalName, value) => {

    if (vitalName === "bmi") {
      if (Number(value) >= 18.50 && Number(value) <= 24.99) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) > 25 && Number(value) <= 29.99) {
        return (<hr class="yellow_line"></hr>)
      } else if (Number(value) > 30 && Number(value) <= 40 || Number(value) >= 15 && Number(value) <= 18.49) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    } else if (vitalName === "bloodsugar") {
      if (Number(value) >= 90 && Number(value) <= 140) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) > 140 && Number(value) <= 199) {
        return (<hr class="yellow_line"></hr>)
      } else if (Number(value) >= 200 && Number(value) <= 800 || Number(value) >= 40 && Number(value) <= 89) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    } else if (vitalName === "temperature") {
      if (Number(value) >= 97 && Number(value) <= 99) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) > 99.1 && Number(value) <= 100.99) {
        return (<hr class="yellow_line"></hr>)
      } else if (Number(value) >= 101 && Number(value) <= 110 || Number(value) >= 90 && Number(value) <= 96.99) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    } else if (vitalName === "pulse") {
      if (Number(value) >= 60 && Number(value) <= 80) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) >= 81 && Number(value) <= 90) {
        return (<hr class="yellow_line"></hr>)
      } else if (Number(value) >= 91 && Number(value) <= 150 || Number(value) >= 40 && Number(value) <= 59) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    } else if (vitalName === "respiratory") {
      if (Number(value) >= 15 && Number(value) <= 20) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) >= 21 && Number(value) <= 25) {
        return (<hr class="yellow_line"></hr>)
      } else if (Number(value) >= 26 && Number(value) <= 40 || Number(value) >= 10 && Number(value) <= 14) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    } else if (vitalName === "IOP") {
      if (Number(value) > 0 && Number(value) <= 21) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) > 21) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    } else if (vitalName === "fetal_heart_rate") {
      if (Number(value) >= 110 && Number(value) <= 169) {
        return (<hr class="green_line"></hr>)
      } else if (Number(value) >= 170 && Number(value) <= 180) {
        return (<hr class="red_line"></hr>)
      } else {
        return (<hr class="brown_line"></hr>)
      }
    }

  }

  //To delete the selected vital
  deleteVital = () => {
    Httpconfig.httptokendelete(
      Constant.siteurl + "api/VitalInformation/" + this.state.vitalInfo.id
    ).then((response) => {
      if (response.data.status == 200 && !response.data.error) {
        toast.success(response.data.message)
        this.state.selectedDate = moment().format("YYYY-MM-DD")
        this.getPatientVitals()
      } else {
        toast.error(response.data.message)
      }
    })
  }

  // Get subscription details of the Patient
  getSubscriptionDetails(patient_id) {
    Httpconfig.httptokenpost(Constant.siteurl + "api/subscription/getusersubscription", {
      patient_id: patient_id, status: 1
    })
      .then((response) => {
        var daysDifference = 0;
        var subscriptionName = "";
        this.items = response.data.data.map((item, key) => {
          var currentDate = new Date();
          daysDifference = item.subscription_todate ? moment(item.subscription_todate).format("YYYY-MM-DD") : 0;
          subscriptionName = item.master_subscription_tbl ? item.master_subscription_tbl.subscription_name : "";
        });
        if (!daysDifference) {
          toast.error("You have no subscriptions, please subscribe", {
            position: "top-center"
          })
        }
        this.setState({
          daysDifference: daysDifference,
          subscriptionName: subscriptionName
        })
      })
      .catch((error) => { });
  }

  // Redirect to subscription Plans
  redirectSubscription = () => {
    ls.set("backredirecturl", "Viewvitals");
    window.location.href = Constant.imgurl + "/ViewsubscriptionPlan";
  }
  // hide notification bar
  hidebar = () => {
    $('#notification').hide("slow")
  }

  render() {
    return (
      <main id="main_ord_nav">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <section id="order_profile" class="vital_main">
          <div className="container" id="main_front">
            <div className="row">
              <div class="col-lg-3 col-md-12">
                <PatientSideMenu />
              </div>
              <div class="col-lg-9 col-md-12">
                <section id="vital_sheet_history">
                  <div class="container">
                    <div class="row" id="notification">
                      <div class="col-md-12 no_padding">
                        <section id="medicine_reminder">
                          <div class="med_vitsub">

                            <div class="sub_plan">
                              {this.state.daysDifference ? <h2>Your current subscription plan <b>{this.state.subscriptionName}</b> expires on <b>{this.state.daysDifference}</b>
                              </h2> : <h2>You have no subscriptions please subscribe</h2>}
                              <p onClick={this.redirectSubscription}>Subscribe now </p>
                              <div class="close_bar" onClick={this.hidebar}>
                                <span><i class="fa fa-times" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-12">
                        <div class="vital_head">
                          <h2>Vital Sheet History
                            {this.state.daysDifference ?
                              <span onClick={() => { window.location.href = "./Patientaddvitals" }}><img src="../images/patient/img/Profile/add_member_white.svg" />Add</span>
                              : ""}
                          </h2>
                        </div>
                        <div class="vital_box">
                          <div class="select_mem">
                            <div class="form-group">
                              <label>Select Member</label>
                              <select
                                onChange={(e) => {
                                  this.state.selectedRelativeId = e.target.value;
                                }}
                                name="slot_time"
                                defaultValue=""
                                className="form-control"
                              >
                                <option value="" disabled={true}>
                                  Select Member
                                </option>
                                {this.state.patientRelatives.map((item) => {
                                  return <option value={item.id} selected={this.state.selectedRelativeId == item.id}>{item.name}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          <div class="choose_date">
                            <div class="form-group">
                              <label>Select Date</label>
                              <input type="date" class="form-control"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={(e) => {
                                  this.state.selectedDate =
                                    e.currentTarget.value;
                                }}
                              />
                              {/* <input type="date" class="form-control" /> */}
                            </div>
                          </div>
                          <div class="vital_submit_btn">
                            <button type="button" onClick={this.getPatientVitals}>Submit</button>
                          </div>


                          <div className="view_graph">
                            <Link to="/Vitalsheethistory">
                              <button type="button">
                                View Graphic Chart
                              </button>
                            </Link>
                            {"       "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="select_time_box">
                          <div class="form-group">
                            <label>Select Time</label>
                            <div class="time_bar">
                              <img class="clock_img" src="./images/patient/img/clock-time.svg" />
                              <div class="stepwizard">
                                <div class="stepwizard-row setup-panel">
                                  {this.state.timeBarPoints.length > 0 ? this.state.timeBarPoints : ""}
                                </div>
                              </div>
                              <img class="clock_img" src="./images/patient/img/clock-time.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="vit-list-section">
                          <div class="vit_option">
                            <h2>{moment(this.state.selectedDate).format("DD/MM/YYYY")} | {moment(this.state.selectedDate).format("HH:mm A")}</h2>
                            <div class="list">
                              {this.state.allVitalsdownload ?
                                <div class="vit_dropdown">
                                  {/* <h6 class="vit_dropbtn"> */}

                                  {/* <div class="vit_dropdown-content"> */}

                                  {this.state.vitalInfo.patient_type == "adult" ? <ExcelFile
                                    filename="Vital Report"
                                    element={
                                      // <a href="#">Download Excel</a>.
                                      <img src="../images/patient/img/blue-download.svg" />
                                    }
                                  >
                                    <ExcelSheet
                                      data={this.state.allVitalsdownload}
                                      name="Vitals"
                                    >
                                      <ExcelColumn label="Name" value="name" />
                                      <ExcelColumn label="Height" value="height" />
                                      <ExcelColumn label="Weight" value="weight" />
                                      <ExcelColumn label="BMI" value="bmi" />
                                      <ExcelColumn label="Temperature" value="temperature" />
                                      <ExcelColumn label="Pulse" value="pulse" />
                                      <ExcelColumn label="Blood Sugar" value="blood_sugar" />
                                      <ExcelColumn label="Respiratory Rate" value="respiratory_rate" />
                                      <ExcelColumn label="Blood Pressure(diastolic)" value={"blood_pressure_diastolic"} />
                                      <ExcelColumn label="Blood Pressure(systolic)" value={"blood_pressure_systolic"} />
                                      <ExcelColumn label="Heart Rate" value="heart_rate" />
                                      <ExcelColumn label="SpO2" value="spo2" />
                                      <ExcelColumn label="Steps" value="steps" />
                                      <ExcelColumn label="IOP" value="iop" />
                                      <ExcelColumn label="Pain Rating Scale" value="pain_rating_scale" />
                                      <ExcelColumn label="Fetal Heart Rate" value="fetal_heart_rate" />
                                      <ExcelColumn label="Head Circumference" value="head_circumference" />
                                      <ExcelColumn label="Created Date" value="date" />

                                    </ExcelSheet>
                                  </ExcelFile> : <ExcelFile
                                    filename="Vital Report"
                                    element={
                                      <img src="../images/patient/img/blue-download.svg" />
                                    }
                                  >
                                    <ExcelSheet
                                      data={this.state.vitalReportXlsArray}
                                      name="Order Request"
                                    >
                                      <ExcelColumn label="Fetal Heart Rate" value="fetal_heart_rate" />
                                      <ExcelColumn label="Head Circumference" value="head_circumference" />
                                      <ExcelColumn label="Created Date" value="date" />
                                    </ExcelSheet>
                                  </ExcelFile>
                                  }

                                  {/* <a href="#">Excel</a> */}
                                  {/* <a href="#">Pdf</a> */}

                                  {/* </div> */}
                                  {/* </h6> */}
                                </div>
                                : ""}
                              {/* {this.state.daysDifference ? <h6 onClick={() => {
                                if (this.state.vitalInfo.createdAt) window.location.href = "./Patientaddvitals/" + this.state.vitalInfo.id
                              }} >
                                <img src="./images/patient/img/vit-edit.svg" />
                              </h6> : ""} */}
                              {this.state.allVitalsdownload ?
                                <h6 data-toggle="modal" data-target="#vitdel_Modal">
                                  <img src="./images/patient/img/vit-delete.svg" />
                                </h6>
                                : ""}
                              <div class="modal fade in" id="vitdel_Modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-body vital_body">
                                      <img src="./images/patient/img/delete.svg" />
                                      <h3>Are you sure you want to delete vital sheet history</h3>
                                      <div class="vital_modalbtns">
                                        <button type="button" class="yes_btn" data-dismiss="modal" onClick={this.deleteVital}>Yes</button>
                                        <button type="button" class="no_btn" data-dismiss="modal">No</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.vitalInfo.patient_type === "adult" ? <div class="vital_list_box">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Height</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.feetInches ? this.state.vitalInfo.feetInches : "--" : 0}</p>
                                    <span>feet/inches</span>
                                  </div>
                                  {this.state.vitalInfo ? <hr class="green_line"></hr> : <hr class="brown_line"></hr>}
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Weight</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.weight ? this.state.vitalInfo.weight : "--" : 0}</p>
                                    <span>kg</span>
                                  </div>
                                  {this.state.vitalInfo ? <hr class="green_line"></hr> : <hr class="brown_line"></hr>}

                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>BMI</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.bmi ? this.state.vitalInfo.bmi : "--" : 0}</p>
                                    <span>kg/m2</span>
                                  </div>
                                  {this.state.vitalInfo ? this.getClourCodes("bmi", this.state.vitalInfo.bmi) : <hr class="brown_line"></hr>}
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Temperature</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.temperature ? this.state.vitalInfo.temperature : "--" : 0}</p>
                                    <span>°F</span>
                                  </div>

                                  {this.state.vitalInfo ? this.getClourCodes("temperature", this.state.vitalInfo.temperature) : <hr class="brown_line"></hr>}
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Pulse</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.pulse_rate ? this.state.vitalInfo.pulse_rate : 0 : 0}</p>
                                    <span>Beats per minute</span>
                                  </div>
                                  {this.state.vitalInfo ? this.getClourCodes("pulse", this.state.vitalInfo.pulse_rate) : <hr class="brown_line"></hr>}

                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Blood Sugar</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.blood_sugar ? this.state.vitalInfo.blood_sugar : "--" : 0}</p>
                                    <span>mg/dL</span>
                                  </div>
                                  {this.state.vitalInfo ? this.getClourCodes("bloodsugar", this.state.vitalInfo.blood_sugar) : <hr class="brown_line"></hr>}

                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Respiratory Rate</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.respiratory_rate ? this.state.vitalInfo.respiratory_rate : "--" : 0}</p>
                                    <span>breaths per minute</span>
                                  </div>
                                  {this.state.vitalInfo ? this.getClourCodes("respiratory", this.state.vitalInfo.respiratory_rate) : <hr class="brown_line"></hr>}
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Blood Pressure</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.blood_pressure_systolic ? this.state.vitalInfo.blood_pressure_systolic : "--" : 0}/{this.state.vitalInfo ? this.state.vitalInfo.blood_pressure_diastolic ? this.state.vitalInfo.blood_pressure_diastolic : "--" : 0}</p>
                                    <span>mmHg</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Heart Rate</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.heart_rate ? this.state.vitalInfo.heart_rate : "--" : 0}</p>

                                    <span>/min</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>SpO2</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.spo2 ? this.state.vitalInfo.spo2 : "--" : 0}</p>
                                    <span>%</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>ECG</h2>
                                    <button type="button" class="view_file_btn">View File</button>
                                  </div>
                                  {this.state.vitalInfo ? this.state.vitalInfo.ecg_reports && JSON.parse(this.state.vitalInfo.ecg_reports).length > 0 ? JSON.parse(this.state.vitalInfo.ecg_reports).map((item, index) => {
                                    return <a href={Constant.imgurl + item} class="pr-3" target="_blank">file {index + 1}</a>
                                  }) : "" : ""}
                                  <hr class="brown_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>Steps</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.steps ? this.state.vitalInfo.steps : "--" : 0}</p>
                                    <span>/day</span>
                                  </div>
                                  <hr class="green_line"></hr>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="vit_listitem">
                                  <div class="vit_listbox">
                                    {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                    <h2>IOP</h2>
                                    <p>{this.state.vitalInfo ? this.state.vitalInfo.IOP ? this.state.vitalInfo.IOP : "--" : 0}</p>
                                    <span>mmHg</span>
                                  </div>
                                  {this.getClourCodes("IOP", this.state.vitalInfo.IOP)}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="vital_painbox">
                                  <div className="painface_header">
                                    <p>Wong-Baker FACES® Pain Rating Scale</p>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="pain_box">
                                        <img className="img-top" src="./images/patient/img/diagnostics/0pain_blue_face.svg"></img>
                                        {this.state.vitalInfo.pain_rating_scale === 0 ? <img src="./images/patient/img/diagnostics/0pain_blue_face.svg" /> : <img className="black_painface0" src="./images/patient/img/diagnostics/0pain.svg" />}
                                        <p>No Hurt</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="pain_box">
                                        <img className="img-top" src="./images/patient/img/diagnostics/2pain_blue_face.svg"></img>
                                        {this.state.vitalInfo.pain_rating_scale === 2 ? <img src="./images/patient/img/diagnostics/2pain_blue_face.svg" /> : <img src="./images/patient/img/diagnostics/2pain.svg" />}
                                        <p>Hurts Little Bit</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="pain_box">
                                        <img className="img-top" src="./images/patient/img/diagnostics/4pain_blue_face.svg"></img>
                                        {this.state.vitalInfo.pain_rating_scale === 4 ? <img src="./images/patient/img/diagnostics/4pain_blue_face.svg" /> : <img src="./images/patient/img/diagnostics/4pain.png" />}
                                        <p>Hurts Little More</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="pain_box">
                                        <img className="img-top" src="./images/patient/img/diagnostics/6pain_blue_face.svg"></img>
                                        {this.state.vitalInfo.pain_rating_scale === 6 ? <img src="./images/patient/img/diagnostics/6pain_blue_face.svg" /> : <img src="./images/patient/img/diagnostics/6pain.png" />}
                                        <p>Hurts Even More</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="pain_box">
                                        <img className="img-top" src="./images/patient/img/diagnostics/8pain_blue_face.svg"></img>
                                        {this.state.vitalInfo.pain_rating_scale === 8 ? <img src="./images/patient/img/diagnostics/8pain_blue_face.svg" /> : <img src="./images/patient/img/diagnostics/8pain.svg" />}
                                        <p>Hurts Whole Lot</p>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="pain_box">
                                        <img className="img-top" src="./images/patient/img/diagnostics/10pain_blue_face.svg"></img>
                                        {this.state.vitalInfo.pain_rating_scale === 10 ? <img src="./images/patient/img/diagnostics/10pain_blue_face.svg" /> : <img src="./images/patient/img/diagnostics/10pain.png" />}
                                        <p>Hurts Worst</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            : <div class="vital_main">
                              <div class="vital_list_box">
                                <div class="row">
                                  <div class="col-md-3">
                                    <div class="vit_listitem">
                                      <div class="vit_listbox">
                                        {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                        <h2>Fetal heart rate</h2>
                                        <p>{this.state.vitalInfo ? this.state.vitalInfo.fetal_heart_rate ? this.state.vitalInfo.fetal_heart_rate : 0 : 0}</p>
                                        <span>Beats/min</span>
                                      </div>
                                      {this.state.vitalInfo ? this.getClourCodes("fetal_heart_rate", this.state.vitalInfo.fetal_heart_rate) : <hr class="brown_line"></hr>}
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="vit_listitem">
                                      <div class="vit_listbox">
                                        {/* <img src="../images/patient/img/vital-logo.svg" /> */}
                                        <h2>Head Circumference</h2>
                                        <p>{this.state.vitalInfo ? this.state.vitalInfo.head_circumference ? this.state.vitalInfo.head_circumference : 0 : 0}</p>
                                        <span>Inches</span>
                                      </div>
                                      <hr class="green_line"></hr>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
