import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Form, Button, Dropdown, Grid, Header } from "semantic-ui-react";
import ReactExport from "react-data-export";
import { Link } from "react-router-dom";
import $ from "jquery";

const moment = require("moment");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ClinicPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retailersListData: "",
      couponListData: "",
      selectedStatus: "",
      selectedRetailerId: "",
      selectedCouponId: "",
      selectedStartDate: "",
      selectedEndDate: "",
      searchString: "",
      loadshow: "load_sec collapse-show",
      pageNumber: 1,
      ordersView: [],
      currentOrders: [],
      xlsArray: [],
      selectedClinicId: "All",
    };
    this.getAllOrders = this.getAllOrders.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.getAllOrders();
    this.fetchClinicInfo();
  };

  // Get the coupons list
  fetchCouponInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Coupon/")
      .then((response) => {
        let listData = response.data.data;
        let couponListData = listData.map((item, index) => ({
          key: item.id,
          text: item.coupon_name,
          value: item.id,
        }));
        this.state.couponListData = couponListData;

        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // On retalier selection
  onCouponListChange = (event, data) => {
    let selectedCouponValue = data.value;

    if (selectedCouponValue != "") {
      const keys = data.options.find(
        (data) => data.value === selectedCouponValue
      );
      const selectedretailer = {
        id: keys.key,
        medicineid: keys.text,
        medicinename: keys.value,
      };
      this.state.selectedRetailerName = keys.text;
      this.state.selectedCouponId = selectedCouponValue;
      this.forceUpdate();
      this.getAllOrders();
    }
  };

  // Get the retailers list
  fetchRetailerInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/retailer/")
      .then((response) => {
        let listData = response.data.data;
        let retailersListData = listData.map((item, index) => ({
          key: item.id,
          text: item.storename,
          value: item.id,
        }));
        this.state.retailersListData = retailersListData;

        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // On retalier selection
  onRetailerListChange = (event, data) => {
    let selectedretailerValue = data.value;

    if (selectedretailerValue != "") {
      const keys = data.options.find(
        (data) => data.value === selectedretailerValue
      );
      const selectedretailer = {
        id: keys.key,
        medicineid: keys.text,
        medicinename: keys.value,
      };
      this.state.selectedRetailerName = keys.text;
      this.state.selectedRetailerId = selectedretailerValue;
      this.forceUpdate();
      this.getAllOrders();
    }
  };

  // get all the orders
  getAllOrders() {
    let clinicId = this.state.selectedClinicId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let finalordersarray = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Users/findAllClinicUsers",
      {
        clinic_id: clinicId,
        user_type: "patient",
        start_date: startDate,
        end_date: endDate,
      }
    )
      .then((response) => {
        this.state.loadshow = "load_sec collapse-show";
        this.forceUpdate();

        const ordersView = response.data.data.map((LoadedData, num) => {

          if (LoadedData.clinic_tbl!=null) {
            console.log("num",num);
            console.log("num",LoadedData.clinic_tbl);
            finalordersarray.push({
              Sno: num + 1,
              ClinicName: LoadedData.clinic_tbl
                ? LoadedData.clinic_tbl.clinic_name
                : "",
              ClinicAddress: LoadedData.clinic_tbl
                ? LoadedData.clinic_tbl.address
                : "",
              patientName: LoadedData.patient_tbl
                ? LoadedData.patient_tbl.name
                : "",
              gender: LoadedData.patient_tbl
                ? LoadedData.patient_tbl.gender
                : "",
              mobileNumber: LoadedData.mobile_number
                ? LoadedData.mobile_number
                : "",
              email: (LoadedData.email != null || LoadedData.email != '') ? LoadedData.email : "",
              Registrationdate: moment(LoadedData.createdAt).format(
                "DD-MM-YYYY hh:mm"
              ),
              status: LoadedData.status == 0 ? "Active" : "Inactive",
            });
        
            return (
              <tr>
                <td>{
                //num + 1
                LoadedData.id
                }</td>
                <td>
                  {LoadedData.clinic_tbl!=null
                    ? LoadedData.clinic_tbl.clinic_name
                    : ""}{" "}
                </td>
                <td>
                  <p>
                    {LoadedData.clinic_tbl!=null ? LoadedData.clinic_tbl.address : ""}
                  </p>{" "}
                </td>
                <td>
                  {LoadedData.patient_tbl  ? LoadedData.patient_tbl.name : ""}
                </td>
                <td>
                  {LoadedData.patient_tbl  ? LoadedData.patient_tbl.gender: ""}
                </td>
                <td>
                  {LoadedData.mobile_number ? LoadedData.mobile_number : ""}
                </td>
                <td>{(LoadedData.email != null || LoadedData.email != '')  ? LoadedData.email : ""}</td>
                <td>
                  {moment(LoadedData.createdAt).format("DD-MM-YYYY hh:mm")}
                </td>
                <td>{LoadedData.status == 0 ? "Active" : "Inactive"}</td>
              </tr>
            );
          }
        });

        this.state.ordersView = ordersView;
        this.state.xlsArray = finalordersarray;
        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // Handle searched string
  handleSearchedString = (event) => {
    let searched = event.target.value;
    this.state.searchString = searched;
    this.forceUpdate();
  };

  // For handleClear
  handleClear = (id) => {
    if (id == "start_date") {
      this.state.selectedStartDate = "";
      $("#" + id).val("");
    }
    if (id == "end_date") {
      this.state.selectedEndDate = "";
      $("#" + id).val("");
    }
    if (id == "input-search") {
      this.state.searchString = "";
      $("#" + id).val("");
    }
    this.getAllOrders();
  };
  //  For start date
  startDate = (event) => {
    let startDate = event.target.value;
    this.state.selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (this.state.selectedStartDate && this.state.selectedEndDate) {
      if (this.state.selectedStartDate <= this.state.selectedEndDate) {
        if (
          this.state.selectedEndDate != "" &&
          this.state.selectedStartDate != ""
        ) {
          this.getAllOrders();
        }
      } else {
        toast.error("from date cannot be greater than to date");
      }
    }
  };

  // Start date selection
  endDate = (event) => {
    let endDate = event.target.value;
    this.state.selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (this.state.selectedStartDate <= this.state.selectedEndDate) {
      if (
        this.state.selectedStartDate != "" &&
        this.state.selectedEndDate != ""
      ) {
        this.getAllOrders();
      }
    } else {
      toast.error("from date cannot be greater than to date");
    }
  };

  // Get the Clinic list
  fetchClinicInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Clinic/clinicList")
      .then((response) => {
        let listData = response.data.data;
        let ClinicListData = listData.map((item, index) => ({
          key: item.id,
          text: item.clinic_name,
          value: item.id,
        }));
        ClinicListData.unshift({ key: "All", text: "All", value: "All" });
        this.state.ClinicListData = ClinicListData;

        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // On clinic selection
  onClinicListChange = (event, data) => {
    let selectedClinicValue = data.value;
    this.state.pageNumber = 1;

    if (selectedClinicValue == "") {
      this.state.selectedClinicId = "All";
      this.forceUpdate();
      this.getAllOrders();
    } else {
      const keys = data.options.find(
        (data) => data.value === selectedClinicValue
      );
      const selectedretailer = {
        id: keys.key,
        clinicName: keys.text,
        clinicName: keys.value,
      };

      this.state.selectedClinicName = keys.text;
      this.state.selectedClinicId = selectedClinicValue;
      this.forceUpdate();
      this.getAllOrders();
    }
  };

  render() {
    //console.log('-----308------', this.state.ordersView)
    return (
      <section id="main_dashboard">
        <div class="container">
          <div class="row">
            <div class="col-md-12 no_padding">
              <div id="order_section">
                <div class="container no_padding">
                  <div class="row">
                    <div class="dash-section col-md-12">
                      <div class="section-header">
                        <ol class="breadcrumb">
                          <li class="active">
                            <a href="/admin"> Dashboard</a>&gt; Reports &gt;
                            Clinic Patients
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="row tab-header">
                    <div class="col-md-6 no_padding ">
                      <h3> Clinic Patients </h3>
                    </div>
                    <div class="col-md-6 no_padding download-btn">
                      <ExcelFile
                        filename="Clinic Patients"
                        element={
                          <a class="btn  btn-primary fright">Download Report</a>
                        }
                      >
                        <ExcelSheet
                          data={this.state.xlsArray}
                          name="Order Request"
                        >
                          <ExcelColumn label="Sno" value="Sno" />
                          <ExcelColumn label="Clinic Name" value="ClinicName" />
                          <ExcelColumn
                            label="Clinic Address"
                            value="ClinicAddress"
                          />
                          <ExcelColumn
                            label="patient Name"
                            value="patientName"
                          />
                          <ExcelColumn label="gender" value="gender" />
                          <ExcelColumn
                            label="mobile Number"
                            value="mobileNumber"
                          />
                          <ExcelColumn label="Email" value="email" />
                          <ExcelColumn
                            label="Registrationdate"
                            value="Registrationdate"
                          />
                          <ExcelColumn label="status" value="status" />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-5 date_sec">
                      <h2>Select Date</h2>
                      <form>
                        <div class="form-group">
                          <input
                            type="date"
                            id="start_date"
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            onChange={this.startDate}
                            max={moment().format("YYYY-MM-DD")}
                            value={this.state.selectedStartDate}
                          />
                        </div>
                        <p class="to_txt">To</p>
                        <div class="form-group">
                          <input
                            type="date"
                            id="end_date"
                            class="form-control"
                            onChange={this.endDate}
                            max={moment().format("YYYY-MM-DD")}
                            value={this.state.selectedEndDate}
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-lg-7 select_ret_sec">
                      <div class="form-group no_padding col-md-5">
                        <label>Clinic</label>
                        {this.state.ClinicListData ? (
                          <Dropdown
                            class="form-control"
                            id="Clinic"
                            placeholder={"All"}
                            fluid
                            clearable
                            search
                            selection
                            options={this.state.ClinicListData}
                            onChange={this.onClinicListChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="form-group no_padding col-md-3"></div>
                      <div class="form-group col-md-3 no_padding">
                        <a class="btn submit-btn" onClick={this.getAllOrders}>
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="CMS_tab">
                <div class="CMS_content">
                  <div class="">
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div id="reg_form">
                          <div class="table-responsive dataTables_wrapper load_sec">
                            <table class="table table-bordered table-sm table-striped">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Clinic Name</th>
                                  <th>Address</th>
                                  <th>Patient Name</th>
                                  <th>Gender</th>
                                  <th>Mobile Number</th>
                                  <th>Email Id</th>
                                  <th>Registration Date</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.ordersView != "" ? (
                                  this.state.ordersView
                                ) : (
                                  <React.Fragment>
                                    <td>No records found</td>
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button onclick="topFunction()" id="myBtn" title="Go to top">
                  <img src="https://i.pinimg.com/originals/c4/f0/4b/c4f04b83f93836a05b4f188180708e0e.png" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
