import React from "react";
import Httpconfig from "../helpers/Httpconfig";
import { reactLocalStorage } from "reactjs-localstorage";
import Constant from "../../constants";

import {
  usePaystackPayment,
  PaystackButton,
  PaystackConsumer,
} from "react-paystack";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const moment = require("moment");
const config = {
  reference: new Date().getTime(),
  email: "rakesh.n@medleymed.com",
  amount: 100,
  publicKey: "pk_test_b503e67b2f26e761101121c9704e9e06bc4c5b79",
};
var data = [];
const PaystackHookExample = () => {
  const initializePayment = usePaystackPayment(config);
  return (
    <div class="">
      <a
        href="#"
        onClick={() => {
          initializePayment();
        }}
      >
        <p>Pay </p>
      </a>
    </div>
  );
};
// For success handling

const handleSuccess = (reference, data) => {
  data.transaction_id = reference.reference;
  data.timeZone = moment.tz.guess();
  data.patientEmail = data.patientEmail;
  data.patient_address_id = data.patient_address_id;
  Httpconfig.httptokenpost(Constant.siteurl + "api/OM/orderProcess", data)
    .then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        toast.success(response.data.message);
        ls.set("cartIdEnc", "");
        setInterval(() => {
          window.location.href = "./myOrders";
        }, 1000);
      }
    })
    .catch((error) => {
      toast.error(error);
    });
};

//  For handling close
const handleClose = () => {};

function App(props) {
  data = props;
  var config1 = "";
  var userObj = reactLocalStorage.getObject("userObjEnc");
  if (Object.keys(data).length > 0) {
    let tobepaid = "";
    tobepaid = data.payable_amount * 100;
    config1 = {
      reference: new Date().getTime(),
      email: data.patientEmail,
      amount: tobepaid,
      publicKey: Constant.paymentkey,
    };
  } else {
    config1 = {
      reference: new Date().getTime(),
      email: "",
      amount: 0.0,
      publicKey: Constant.paymentkey,
    };
  }
  const componentProps = {
    ...config1,
    text: "Paystack Button Implementation",
    onSuccess: (reference) => handleSuccess(reference, data),
    onClose: () => handleClose,
  };

  return (
    <div className="App">
      <PaystackConsumer {...componentProps}>
        {({ initializePayment }) => (
          <div class="">
            <a
              href="#"
              onClick={() => {
                initializePayment();
              }}
            >
              <p>Pay</p>
            </a>
          </div>
        )}
      </PaystackConsumer>
    </div>
  );
}

export default App;
