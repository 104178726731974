import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const moment = require("moment");

export default class createCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      coupon_data: "",

      options: [],
      userarray: [],
      customersarray: [],
      selectedList: {},
      selectedValue: [],
    };
  }

  //  Set setting the start tinme
  setStartTimeOnChange = (value) => {
    this.setState({ start_date: value });
  };

  //  for setting the end time
  setEndTimeOnChange = (value) => {
    this.setState({ end_date: value });
  };

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.fetchusersdata();
    if (handle) this.getCouponInfo(handle);
  };

  // For getting the users data
  fetchusersdata() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findPatientName"
    ).then((response) => {
      this.setState({
        options: response.data,
        userarray: response.data,
      });
    });
  }

  //  For on select

  onSelect = (selectedListdata, selectedItem) => {
    this.setState({
      selectedList: selectedListdata.map((x) => x.id),
    });
  };

  //  For on remove
  onRemove = (deselectedList, removedItem) => {
    this.setState({
      selectedList: deselectedList.map((x) => x.id),
    });
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value.toUpperCase();
    // fields[field] = event.target.value;
    this.setState({ fields });
  };

  // To get all the ResponderInfo
  getCouponInfo(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Coupon/" + handle)
      .then((response) => {
        this.setState({
          fields: {
            coupon_name: response.data.data[0].coupon_name,
            module: response.data.data[0].module.toUpperCase(),
            is_percentage: response.data.data[0].is_percentage,
            numberof_user: response.data.data[0].numberof_user,
            numberof_times: response.data.data[0].numberof_times,
            discount: response.data.data[0].discount,
            is_all: response.data.data[0].is_all,
            status: response.data.data[0].status,
            minimumorder_value: response.data.data[0].minimumorder_value,
            maximumdiscount_amount:
              response.data.data[0].maximumdiscount_amount,
            couponlimit: response.data.data[0].coupon_expirity_count,
          },
        });

        this.setState({
          start_date: new Date(response.data.data[0].start_date),
        });
        this.setState({ end_date: new Date(response.data.data[0].end_date) });
        this.setState({ module: response.data.data[0].module });
        let editusersdata = [];
        let customerData = response.data.data[0].customers;
        let editUserList = customerData.split(",");

        var usersdata = this.state.userarray;
        editUserList.forEach(function (item, index) {
          usersdata.forEach(function (obj, i) {
            if (obj.id == item) {
              return editusersdata.push(obj);
            }
          });
        });
        this.setState({
          selectedValue: editusersdata,
          selectedList: editUserList,
        });
      })
      .catch((error) => { });
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateCoupons(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createCoupon(event);
    } else {
      toast.warn("Form has errors.");
    }
  }

  // creates new controller
  createCoupon = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    if (fields["is_percentage"] == "FLAT") {
      fields["maximumdiscount_amount"] = fields["discount"];
    }

    Httpconfig.httptokenpost(Constant.siteurl + "api/Coupon/", {
      coupon_name: fields["coupon_name"],
      module: fields["module"].toLowerCase(),
      minimumorder_value: fields["minimumorder_value"],
      maximumdiscount_amount: fields["maximumdiscount_amount"],
      coupon_expirity_count: fields["couponlimit"],

      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
      is_percentage: fields["is_percentage"],
      numberof_user: fields["numberof_user"],
      numberof_times: fields["numberof_times"],
      discount: fields["discount"],
      is_all: fields["is_all"],
      customers: "",
    })
      .then((response) => {
        if (response.data.status === 200 && response.data.error == false) {
          toast.success("Successfully Created Coupon");
          setTimeout(() => this.props.history.push("/admin/Viewcoupons"));
        } else {
          toast.error("Coupan is already exists !!");
        }
      })
      .catch((error) => { });
  };

  // updates controller
  updateCoupons = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    if (fields["is_percentage"] == "FLAT") {
      fields["maximumdiscount_amount"] = fields["discount"];
    }
    Httpconfig.httptokenput(Constant.siteurl + "api/Coupon/" + handle, {
      coupon_name: fields["coupon_name"],
      module: fields["module"].toLowerCase(),
      minimumorder_value: fields["minimumorder_value"],
      maximumdiscount_amount: fields["maximumdiscount_amount"],
      coupon_expirity_count: fields["couponlimit"],
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      is_percentage: fields["is_percentage"],
      numberof_user: fields["numberof_user"],
      numberof_times: fields["numberof_times"],
      discount: fields["discount"],
      is_all: fields["is_all"],
      customers: this.state.selectedList.toString(),
    })
      .then((response) => {
        // toast.success("Successfully Updated Coupon");
        // setTimeout(() => this.props.history.push("/admin/Viewcoupons"), 1000);
        if (response.data.status === 200 && response.data.error == false) {

          toast.success("Successfully Updated Coupon");

          setTimeout(() => this.props.history.push("/admin/Viewcoupons"), 1000);

        }

        else {

          toast.error("Coupan is already exists !!");

        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["coupon_name"]) {
      formIsValid = false;
      errors["coupon_name"] = "Coupon name cannot be empty";
    } else if (!/^[@ _ a-zA-Z0-9- ]*$/.exec(fields["coupon_name"])) {
      formIsValid = false;
      errors["coupon_name"] = "Coupon name is containing no special characters";
    }
    if (!fields["minimumorder_value"]) {
      formIsValid = false;
      errors["minimumorder_value"] = "Minimum value cannot be empty";
    }
    if (!fields["maximumdiscount_amount"]) {
      formIsValid = false;
      errors["maximumdiscount_amount"] =
        "Maximum discount amount value cannot be empty";
    }
    if (!fields["couponlimit"]) {
      formIsValid = false;
      errors["couponlimit"] = "Coupon limit  value cannot be empty";
    }
    if (!this.state.start_date) {
      formIsValid = false;
      errors["start_date"] = "Start Date cannot be empty";
    }
    if (!this.state.end_date) {
      formIsValid = false;
      errors["end_date"] = "End Date cannot be empty";
    }
    if (!fields["discount"]) {
      formIsValid = false;
      errors["discount"] = "Discount cannot be empty";
    }
    if (!fields["module"]) {
      formIsValid = false;
      errors["module"] = "Please Select Module type";
    }

    if (!fields["is_percentage"]) {
      formIsValid = false;
      errors["is_percentage"] = "Percentage cannot be empty";
    }
    if (!fields["numberof_user"]) {
      formIsValid = false;
      errors["numberof_user"] = "Number of Users cannot be empty";
    }
    if (!fields["numberof_times"]) {
      formIsValid = false;
      errors["numberof_times"] = "Number of Times cannot be empty";
    }

    if (fields["is_percentage"] == "PERCENTAGE") {
      if (fields["discount"] > 99) {
        formIsValid = false;
        errors["discount"] = "Invalid Discount Amount";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors, coupon_data } = this.state;

    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      <a> Coupons </a>
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content create_calender">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3>{this.props.match.params.handle ? "Update Coupon Management" : "Create Coupon Management"}</h3>
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Coupon Name
                                </label>
                                <input
                                  type="text"
                                  name="coupon_name"
                                  value={this.state.fields["coupon_name"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "coupon_name"
                                  )}
                                  className="form-control"
                                  placeholder="Coupon Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["coupon_name"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Start Date
                                </label>
                                <DatePicker
                                  name="start_date"
                                  autoComplete="off"
                                  className="dateInput"
                                  placeholderText="Start Date"
                                  popperPlacement="bottom"
                                  minDate={new Date()}
                                  popperModifiers={{
                                    flip: {
                                      behavior: ["bottom"],
                                    },
                                  }}
                                  selected={this.state.start_date}
                                  onChange={this.setStartTimeOnChange}
                                  dateFormat="yyyy-MM-d"
                                  calendarIcon
                                  showMonthDropdown
                                  adjustDateOnChange
                                />

                                <span className="cRed">
                                  {this.state.errors["start_date"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  End Date
                                </label>
                                <DatePicker
                                  name="end_date"
                                  autoComplete="off"
                                  className="dateInput"
                                  placeholderText="End Date"
                                  popperPlacement="bottom"
                                  minDate={new Date()}
                                  popperModifiers={{
                                    flip: {
                                      behavior: ["bottom"],
                                    },
                                  }}
                                  selected={this.state.end_date}
                                  onChange={this.setEndTimeOnChange}
                                  dateFormat="yyyy-MM-d"
                                  calendarIcon
                                  showMonthDropdown
                                  adjustDateOnChange
                                />

                                <span className="cRed">
                                  {this.state.errors["end_date"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Module Type
                                </label>
                                <div className="col-md-12 p-0">
                                  <select
                                    id="module"
                                    onChange={this.handleChange.bind(
                                      this,
                                      "module"
                                    )}
                                    value={this.state.fields["module"] || ""}
                                    className="form-control"
                                  >
                                    <option value="">Select Module</option>
                                    <option value="TELEMEDICINE">
                                      Tele Medicine
                                    </option>
                                    <option value="DIAGNOSTICS">
                                      Diagnostics
                                    </option>
                                  </select>

                                  <span className="cRed">
                                    {this.state.errors["module"]}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Minimum Value
                                </label>
                                <div className="col-md-12 p-0">
                                  <input
                                    type="number"
                                    name="minimumorder_value"
                                    value={
                                      this.state.fields["minimumorder_value"] ||
                                      ""
                                    }
                                    onChange={this.handleChange.bind(
                                      this,
                                      "minimumorder_value"
                                    )}
                                    className="form-control"
                                    placeholder="Minimum Order Value"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["minimumorder_value"]}
                                  </span>
                                </div>
                              </div>{" "}
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Maximum Discount Amount
                                </label>
                                <div className="col-md-12 p-0">
                                  <input
                                    type="number"
                                    name="maximumdiscount_amount"
                                    value={
                                      this.state.fields[
                                      "maximumdiscount_amount"
                                      ] || ""
                                    }
                                    onChange={this.handleChange.bind(
                                      this,
                                      "maximumdiscount_amount"
                                    )}
                                    className="form-control"
                                    placeholder="Maximum Discount Amount"
                                  />
                                  <span className="cRed">
                                    {
                                      this.state.errors[
                                      "maximumdiscount_amount"
                                      ]
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Discount Type
                                </label>
                                <div
                                  className="custom-control custom-radio"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "is_percentage"
                                  )}
                                >
                                  <input
                                    type="radio"
                                    value="Percentage"
                                    checked={
                                      this.state.fields["is_percentage"] ==
                                      "PERCENTAGE"
                                    }
                                    name="is_percentage"
                                  />{" "}
                                  Percentage &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="Flat"
                                    checked={
                                      this.state.fields["is_percentage"] ==
                                      "FLAT"
                                    }
                                    name="is_percentage"
                                  />{" "}
                                  Flat
                                </div>
                                <span className="cRed">
                                  {this.state.errors["is_percentage"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  No Of Users
                                </label>
                                <div
                                  className="custom-control custom-radio"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "numberof_user"
                                  )}
                                >
                                  <input
                                    type="radio"
                                    value="Single"
                                    checked={
                                      this.state.fields["numberof_user"] ==
                                      "SINGLE"
                                    }
                                    name="numberof_user"
                                  />{" "}
                                  Single Users &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="Multiple"
                                    checked={
                                      this.state.fields["numberof_user"] ==
                                      "MULTIPLE"
                                    }
                                    name="numberof_user"
                                  />{" "}
                                  Multiple Users
                                </div>
                                <span className="cRed">
                                  {this.state.errors["numberof_user"]}
                                </span>
                              </div>
                            </div>

                            {/* -------------------------------- */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  No Of Times
                                </label>
                                <div
                                  className="custom-control custom-radio"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "numberof_times"
                                  )}
                                >
                                  <input
                                    type="radio"
                                    value="Single"
                                    checked={
                                      this.state.fields["numberof_times"] ==
                                      "SINGLE"
                                    }
                                    name="numberof_times"
                                  />{" "}
                                  Single Time &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="Multiple"
                                    checked={
                                      this.state.fields["numberof_times"] ==
                                      "MULTIPLE"
                                    }
                                    name="numberof_times"
                                  />{" "}
                                  Multiple Time
                                </div>
                                <span className="cRed">
                                  {this.state.errors["numberof_times"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  {this.state.fields["is_percentage"] ==
                                    "PERCENTAGE"
                                    ? "Percentage Discount"
                                    : this.state.fields["is_percentage"] ==
                                      "FLAT"
                                      ? "Flat Discount"
                                      : "Discount"}
                                </label>

                                <input
                                  type="number"
                                  name="discount"
                                  value={this.state.fields["discount"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "discount"
                                  )}
                                  className="form-control"
                                  placeholder="Discount"
                                />
                                <span className="cRed">
                                  {this.state.errors["discount"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label
                                  htmlFor="customRadio2"
                                  className="custom-control-label"
                                >
                                  Coupon Limit
                                </label>
                                <div className="col-md-12 p-0">
                                  <input
                                    type="number"
                                    name="couponlimit"
                                    value={
                                      this.state.fields["couponlimit"] || ""
                                    }
                                    onChange={this.handleChange.bind(
                                      this,
                                      "couponlimit"
                                    )}
                                    className="form-control"
                                    placeholder="Coupon Limit"
                                  />
                                  <span className="cRed">
                                    {this.state.errors["couponlimit"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-12">
                              <button
                                type="submit"
                                className="btn  btn-primary save_btn"
                              >
                                Save Coupon
                              </button>{" "}
                              &nbsp;
                              <Link
                                to="/admin/Viewcoupons"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
