import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import RetailerCss from "../../public/css/retailer/retailer_style.css";
import DiagnosisHeader from "../diagnostics/DiagnosisHeader";
import DiagnosisMenu from "../diagnostics/DiagnosisMenu";
import Footer from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";

export default class Retailertransactionhistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        vat: "",
        slabvalue: "",
        slabdiscount: "",
        deliverySlab: "",
        charges: "",
        set_delivery_days: "",
        email: "",
        userId: "",
        oldPassword: "",
        otp: "",
        newPassword: "",
      },
      errors: {
        vat: "",
        discount_slab: "",
        discount: "",
        deliverySlab: "",
        charges: "",
        oldPassword: "",
        newPassword: "",
      },
    };
  }
  //   To get detais after first render

  componentDidMount = () => {
    let userData = reactLocalStorage.getObject("retuserObj");
    if (userData) {
      if (userData != "") {
        this.setState({
          retailerId: userData.diagnosis_id,
        });
        this.forceUpdate();
      }
    } else {
      window.location.href = "/login";
    }
    this.getRetailerDetails(userData.diagnosis_id);
  };

  // Get the Retailer Details
  getRetailerDetails = (retailerId) => {
    Httpconfig.httptokenget(Constant.siteurl + "api/diagnosis/" + retailerId)
      .then((response) => {
        if ((response.data.status = 200)) {
          this.state.set_delivery_slab =
            response.data.data[0].set_delivery_slab;
          let fields = this.state.fields;

          fields["slabvalue"] = response.data.data[0].discount_slab;
          fields["slabdiscount"] = response.data.data[0].discount_type;
          fields["deliverySlab"] = response.data.data[0].set_delivery_type;
          fields["charges"] = response.data.data[0].set_delivery_slab;
          fields["GST"] = response.data.data[0].gst_type;
          fields["set_delivery_days"] = response.data.data[0].set_delivery_days;
          fields["email"] = response.data.data[0].tbl_user.email;
          fields["userId"] = response.data.data[0].user_id;
          fields["wallet_points"] = response.data.data[0].wallet_points;
          this.setState({
            fields: fields,
          });

          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Update Vat Details
  saveRetailerDiscountDetails = (event) => {
    let retailerId = this.state.retailerId;
    let slabtype = this.state.fields.slabvalue;
    let discount = this.state.fields.slabdiscount;
    var errors = {};

    if (!Number(slabtype)) {
      errors["discount_slab"] = "Enter set slab";
      this.setState({ errors: errors });
    } else if (!Number(discount)) {
      errors["discount"] = "Enter discount";
      this.setState({ errors: errors });
    } else {
      this.setState({ errors: errors });
      Httpconfig.httptokenput(
        Constant.siteurl +
          "api/diagnosis/orderDiscountSlabUpdate/" +
          retailerId,
        {
          discount_type: discount,
          discount_slab: slabtype,
        }
      )
        .then((response) => {
          if ((response.data.status = 200 && !response.data.error)) {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  // Update Discounts Details
  saveRetailerGstDetails = (event) => {
    let retailerId = this.state.retailerId;
    let gst = this.state.fields.GST;
    let errors = {};
    if (!gst) {
      errors["GST"] = "Enter GST %";
      this.setState({ errors: errors });
    } else {
      this.setState({ errors: errors });

      Httpconfig.httptokenput(
        Constant.siteurl + "api/diagnosis/taxUpdate/" + retailerId,
        { gst_type: gst }
      )
        .then((response) => {
          if ((response.data.status = 200 && !response.data.error)) {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  // Update Delivery Details
  saveDeliveryDeliveryDetails = (event) => {
    let retailerId = this.state.retailerId;
    let formError = false;
    let errors = {};
    // if (!Number(this.state.fields.deliverySlab)) {
    //   formError = true;
    //   errors["deliverySlab"] = "Enter slab value";
    //   this.setState({ errors: errors });
    // }
    if (!Number(this.state.fields.charges)) {
      formError = true;
      errors["charges"] = "Enter charges";
      this.setState({ errors: errors });
    }

    // let vat=this.state.deliverySlab;
    if (!formError) {
      this.setState({ errors: errors });
      Httpconfig.httptokenput(
        Constant.siteurl +
          "api/diagnosis/setDeliveryChargesUpdate/" +
          retailerId,
        {
          set_delivery_type: this.state.fields.deliverySlab,
          set_delivery_slab: this.state.fields.charges,
        }
      )
        .then((response) => {
          if ((response.data.status = 200 && !response.data.error)) {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  //  doe handle delivery date
  handledeliverydate = (event) => {
    let retailerId = this.state.retailerId;
    let deliverydate = $("#defaultdeliveryDate").val();

    if (deliverydate == "") {
      toast.error("delivery date should not be empty");
      return;
    }
    if (deliverydate > 9) {
      toast.error("delivery date should not more than 9 days");
      return;
    }
    Httpconfig.httptokenput(
      Constant.siteurl + "api/diagnosis/SetOrderDeliveryDate/" + retailerId,
      {
        set_delivery_days: this.state.set_delivery_date,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  for handle delivery date value
  handleDeliveryDateValue = (event) => {
    let noDays = event.currentTarget.value;
    if (noDays > 9) {
      toast.error("Invalid Delivery days");
      $("#defaultdeliveryDate").val("");
      return;
    } else {
      this.state.set_delivery_date = noDays;
    }
  };
  //  for checking password
  checkPassword = () => {
    let formError = false;
    let errors = {};
    if (!this.state.fields.oldPassword) {
      formError = true;
      errors["oldPassword"] = "Enter old password";
      this.setState({ errors: errors });
    }
    if (!this.state.fields.newPassword) {
      formError = true;
      errors["newPassword"] = "Enter new password";
      this.setState({ errors: errors });
    } else if (
      this.state.fields.newPassword.length < 8 ||
      this.state.fields.newPassword.length > 20
    ) {
      formError = true;
      errors["newPassword"] = "Password shuold contain 8-20 characters";
      this.setState({ errors: errors });
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20}$/.exec(
        this.state.fields.newPassword
      )
    ) {
      formError = true;
      errors["newPassword"] =
        "Required one upper case, one small case, one number and one special character";
      this.setState({ errors: errors });
    }
    if (!formError) {
      this.setState({ errors: errors });
      Httpconfig.httptokenput(
        Constant.siteurl + "api/Users/user/verifyPassword",
        {
          userId: this.state.fields.userId,
          oldPassword: this.state.fields.oldPassword,
          email: this.state.fields.email,
        }
      )
        .then((response) => {
          if (response.data.status == 200 && !response.data.error)
            toast.success(response.data.message);
          else toast.error(response.data.message);
        })
        .catch((err) => {});
    }
  };

  //  for update password
  updatePassword = () => {
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Users/user/updatePassword",
      {
        userId: this.state.fields.userId,
        otp: this.state.fields.otp,
        newPassword: this.state.fields.newPassword,
      }
    )
      .then((response) => {
        if (response.data.status == 200 && !response.data.error)
          toast.success(response.data.message);
        else toast.error(response.data.message);
      })
      .catch((err) => {});
  };

  render() {
    return (
      <main id="main">
        <DiagnosisHeader />
        <DiagnosisMenu />
        <section id="Pharmacy_dashboard">
          <div class="col-lg-10 col-md-10 Pharma_Content">
            <section id="ret_settings">
              <div class="container-fluid">
                {/* <div class="row">
                  <div class="col-md-12">
                    <div class="set_main">
                      <div class="set_head">
                        <h2>Set GST type</h2>
                      </div>
                      <div class="setting_box">
                        <div class="set_content">
                          <div class="set_form">
                            <div class="form-group">
                              <select
                                name="GST"
                                className="form-control"
                                value={this.state.fields.GST}
                                onChange={(event) => {
                                  this.setState({
                                    fields: { GST: event.target.value },
                                  });
                                }}
                              >
                                <option value="" disabled selected>
                                  Select GST
                                </option>
                                <option value="GST">GST(CGST + SGST)</option>
                                <option value="IGST">IGST</option>
                              </select>
                              <div className="cRed">
                                {this.state.errors["GST"]}
                              </div>
                            </div>

                            <div class="set_save_btn">
                              <button
                                type="button"
                                onClick={this.saveRetailerGstDetails}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="cRed">{this.state.errors["vat"]}</div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="row">
                  <div class="col-md-12">
                    {/* <div class="set_main">
                      <div class="set_head">
                        <h2>Pharmacy Wallet Points</h2>
                      </div>
                      <div class="setting_box">
                        <div class="set_content">
                          <div class="set_form">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                defaultValue={this.state.fields.wallet_points}
                                name="wallet_points"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="set_main">
                      <div class="set_head">
                        <h2>Sample Collection Charges</h2>
                      </div>
                      <div class="setting_box">
                        <div class="set_content">
                          <div class="set_form">
                            <div class="form-group">
                              <select class="form-control">
                                <option>upto</option>
                              </select>
                            </div>

                            {/* <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Set Slab %"
                                defaultValue={this.state.fields.deliverySlab}
                                onKeyUp={(event) => {
                                  this.state.fields.deliverySlab =
                                    event.target.value;
                                }}
                                onKeyPress={(event) => {
                                  if (!/^[0-9]*$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <div className="cRed">
                                {this.state.errors["deliverySlab"]}
                              </div>
                            </div> */}

                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="₹ Charges"
                                autoComplete="new-password"
                                defaultValue={this.state.fields.charges}
                                onKeyUp={(event) => {
                                  this.state.fields.charges =
                                    event.target.value;
                                }}
                                onKeyPress={(event) => {
                                  if (!/^[0-9]*$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <div className="cRed">
                                {this.state.errors["charges"]}
                              </div>
                            </div>
                          </div>

                          <div class="set_delivery_btn">
                            <button
                              type="button"
                              onClick={this.saveDeliveryDeliveryDetails}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="row">
                  <div class="col-md-12">
                    <div class="set_main">
                      <div class="set_head">
                        <h2>Set Delivery Date</h2>
                      </div>
                      <div class="setting_box">
                        <div class="set_content">
                          <div class="set_form">
                            <div class="form-group">
                              <select class="form-control">
                                <option>Order date</option>
                              </select>
                            </div>

                            <div class="form-group">
                              <input
                                type="number"
                                class="form-control"
                                placeholder="1"
                                id="defaultdeliveryDate"
                                defaultValue={
                                  this.state.fields.set_delivery_days
                                }
                                maxLength={1}
                                onKeyPress={(event) => {
                                  if (!/^[0-9]*$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onKeyUp={this.handleDeliveryDateValue}
                              />
                            </div>
                          </div>

                          <div class="set_delivery_btn">
                            <button
                              type="button"
                              onClick={this.handledeliverydate}
                            >
                              Save
                            </button>
                          </div>
                          <div class="note_text">Note:Order date +1 day </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="row">
                  <div class="col-md-12">
                    <div class="set_main">
                      <div class="set_head">
                        <h2>Reset Password</h2>
                      </div>
                      <div class="setting_box">
                        <div class="set_content">
                          <div class="set_form">
                            <div class="form-group">
                              <input
                                type="Password"
                                class="form-control"
                                autoComplete="new-password"
                                placeholder="Type Old Password"
                                onKeyUp={(event) => {
                                  this.state.fields.oldPassword =
                                    event.target.value;
                                }}
                              />
                              <div className="cRed">
                                {this.state.errors["oldPassword"]}
                              </div>
                            </div>

                            <div class="form-group">
                              <input
                                type="Password"
                                class="form-control"
                                autoComplete="new-password"
                                placeholder="Type New Password"
                                onKeyUp={(event) => {
                                  this.state.fields.newPassword =
                                    event.target.value;
                                }}
                              />
                              <div className="cRed">
                                {this.state.errors["newPassword"]}
                              </div>
                            </div>

                            <div class="set_verify_btn">
                              <button
                                type="button"
                                onClick={this.checkPassword}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="set_otp">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter OTP"
                              onKeyUp={(event) => {
                                this.state.fields.otp = event.target.value;
                              }}
                            />
                          </div>

                          <div class="resend_otp"></div>
                          <div class="set_verify_btn">
                            <button type="button" onClick={this.updatePassword}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ToastContainer />
        </section>
      </main>
    );
  }
}
