import React, { Component } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import FileBase64 from "react-file-base64";

const moment = require("moment");

const fs = require("fs");

export default class Createdoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        profile_pic: "",
        signature_pic: "",
        currency_name: "India",
        currency_symbol: "₹",
        status: "",
      },
      errors: {},
      clinic_data: "",
      files: [],
      type: "password",
      img: "/images/doctor-img/Login screens/hide_icon.svg",

      options: [],
      speciality_options: [],

      langarray: [],
      languagesarray: [],
      selectedLangList: {},
      selectedLangValue: [],
      signatureUploaded: "0",
      profileUploaded: "0",
      selectedList: "",
      speciality_selectedList: "",
      SignaturesRelated: [],
      signatureImage: "",
      documentsRelated: [],
      profileImage: "",
      pageHeading: ""
    };
    this.showPassword = this.showPassword.bind(this);
  }

  // To get detais after first render
  componentDidMount = async () => {
    const { handle } = this.props.match.params;
    handle ? this.state.pageHeading = "Update Health Care Provider" : this.state.pageHeading = "Create Health Care Provider"
    this.fetchLanguagesData();
    await this.getClinicSpecialities();
    this.fetchClinicListData();
    this.fetchCountryCurrencyDetails();
    this.getDoctorInfo(handle);
  };

  //  For getting the profile images
  getProfileImageFile(files) {
    var fileFormate = false;
    if (files.type == "image/png" || files.type == "image/jpeg") {
      fileFormate = true;
    } else {
      fileFormate = false;
    }
    if (fileFormate) {
      this.setState({ ProfileImageFiles: files });
      this.state.fields.profile_pic = files;
      this.state.profileUploaded = 1;
      this.showProfileImg();
      this.forceUpdate();
    } else {
      toast.error("Upload files Jpeg / Png formate only!");
    }
  }

  //  For getting the profile soignatures
  getProfileSignatureFile(files) {
    var fileFormate = false;
    if (files.type == "image/png" || files.type == "image/jpeg") {
      fileFormate = true;
    } else {
      fileFormate = false;
    }
    if (fileFormate) {
      this.setState({ ProfileSignatureFiles: files });
      this.state.fields.signature_pic = files;
      this.state.signatureUploaded = 1;
      this.showProfileImg();
      this.forceUpdate();
    } else {
      toast.error("Upload files Jpeg / Png formate only!");
      return false;
    }
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    if (field == "zip_code") {
      if (Number(event.target.value) || event.target.value == "") {
        fields[field] = event.target.value;
      } else {
        event.preventDefault()
      }
    } else {
      fields[field] = event.target.value;
    }
    this.setState({ fields });
  };

  //  For getting th languages data
  fetchLanguagesData() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Languages").then(
      (response) => {
        this.setState({
          options: response.data,
          userarray: response.data,
        });
      }
    );
  }

  //  For getting clinic list

  fetchClinicListData() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Clinic/clinicList")
      .then((response) => {
        this.setState({
          clinic_details: response.data.data,
        });
      })
      .catch((error) => { });
  }

  //  For getting the currency
  fetchCountryCurrencyDetails() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country")
      .then((response) => {
        this.setState({
          countryData: response.data.data,
        });
      })
      .catch((error) => { });
  }

  // Get Specialities
  async getClinicSpecialities(handle) {
    await Httpconfig.httptokenget(Constant.siteurl + "api/Category/1")
      .then((response) => {
        this.setState({
          speciality_options: response.data.data,
          speciality_userarray: response.data.data,
        });
      })
      .catch((error) => { });
  }
  // Ends

  //  For select
  onSelect = (selectedListdata, selectedItem) => {
    this.setState({
      selectedLangName: selectedListdata.map((x) => x.name),
    });
    this.setState({
      selectedLangId: selectedListdata.map((x) => x.id),
      selectedList: selectedListdata.map((x) => x.id),
    });
  };
  //  For remove
  onRemove = (deselectedList, removedItem) => {
    this.setState({
      selectedList: deselectedList.map((x) => x.id),
    });
  };

  //  For select speciality
  onSpecialitySelect = (selectedSpecialityListdata, selectedItem) => {
    this.setState({
      selectedSpecialityName: selectedSpecialityListdata.map(
        (x) => x.category_type
      ),
    });
    this.setState({
      selectedSpecialityId: selectedSpecialityListdata.map((x) => x.id),
      speciality_selectedList: selectedSpecialityListdata.map((x) => x.id),
    });
  };

  //  For remobve speciality
  onSpecialityRemove = (deselectedSpecialityList, removedItem) => {
    this.setState({
      selectedSpecialityList: deselectedSpecialityList.map((x) => x.id),
      speciality_selectedList: deselectedSpecialityList.map((x) => x.id),
      selectedSpecialityName: deselectedSpecialityList.map(
        (x) => x.category_type
      ),
    });
    this.forceUpdate();
  };

  // To get all the ResponderInfo
  getDoctorInfo(handle) {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Doctor/getAllDoctors/" + handle
    )
      .then((response) => {
        var usersdata = this.state.userarray;
        var specialitydata = this.state.speciality_userarray;
        var doctorDob = moment(response.data.data[0].dob).format("YYYY-MM-DD");

        this.setState({
          fields: {
            name: response.data.data[0].doctor_name,
            mobile_number: response.data.data[0].mobile_no,

            currency_name: response.data.data[0].currency_name,
            currency_symbol: response.data.data[0].currency_symbol,

            email: response.data.data[0].tbl_user.email,
            password: response.data.data[0].tbl_user.password,
            user_type: "doctor",
            selected_language: "en-us",
            is_fingerprint_required: 0,
            clinic_id: response.data.data[0].clinic_id,

            speciality_id: response.data.data[0].speciality_id,
            speciality_name: response.data.data[0].speciality_name,

            default_language_id: response.data.data[0].default_language_id,
            default_language_name: response.data.data[0].default_language_name,
            selected_language_id: response.data.data[0].selected_language,
            selected_language_name: response.data.data[0].selected_language,

            gender: response.data.data[0].gender,
            dob: doctorDob,
            address: response.data.data[0].address,
            city: response.data.data[0].city,
            state: response.data.data[0].state,
            zip_code: response.data.data[0].zip_code,
            lat_long: "",
            education: response.data.data[0].course_name,
            experience: response.data.data[0].experience,
            registraion_no: response.data.data[0].registraion_no,
            practice: response.data.data[0].practice,
            area_of_expertise: response.data.data[0].area_of_expertise,
            fees: response.data.data[0].fees,
            commission: response.data.data[0].commission,

            languages: response.data.data[0].languages,
            languageids: response.data.data[0].languageids,
            status: response.data.data[0].tbl_user.status,
            slot_duration: response.data.data[0].slot_duration,
            break_duration: response.data.data[0].break_duration,
            profile_pic: response.data.data[0].profile_pic,
            signature_pic: response.data.data[0].signature_pic,
          },
        });

        // Edit Highlight Language Data
        let editLangData = [];
        let customerData = response.data.data[0].languageids;
        this.showProfileImg();
        let editLangList = customerData.split(",");
        editLangList.forEach(function (item, index) {
          usersdata.forEach(function (obj, i) {
            if (obj.id == item) {
              return editLangData.push(obj);
            }
          });
        });
        this.setState({
          selectedValue: editLangData,
          selectedList: editLangList,
        });
        // Ends

        // Edit Speciality Data
        let editSpecialityData = [];
        let specialityData = response.data.data[0].speciality_id;
        let editSpecialityList = specialityData.split(",");
        editSpecialityList.forEach(function (item, index) {
          specialitydata.forEach(function (obj, i) {
            if (obj.id == item) {
              return editSpecialityData.push(obj);
            }
          });
        });
        this.setState({
          speciality_selectedValue: editSpecialityData,
          speciality_selectedList: editSpecialityList,
        });
      })
      .catch((error) => { });
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateDoctor(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createDoctor(event);
    } else {
      toast.warn("Form has errors.");
    }
  }
  // creates new controller
  createDoctor = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;

    let profileImage = "";
    if (this.state.fields.profile_pic) {
      profileImage = this.state.fields.profile_pic;
    }

    let signatureImage = "";
    if (this.state.fields.signature_pic) {
      signatureImage = this.state.fields.signature_pic;
    }

    if (fields["clinic_id"] == undefined) {
      this.state.fields.clinic_id = 4;
    }
    Httpconfig.httptokenpost(Constant.siteurl + "api/Users/", {
      name: fields["name"],
      mobile_number: fields["mobile_number"],
      currency_name: fields["currency_name"],
      Country: fields["currency_name"],
      currency_symbol: fields["currency_symbol"],
      email: fields["email"],
      password: fields["password"],
      clinic_id: 1,
      user_type: "doctor",
      selected_language: "en-us",
      is_fingerprint_required: 0,
      gender: fields["gender"],
      dob: fields["dob"],
      address: fields["address"],
      city: fields["city"],
      state: fields["state"],
      zip_code: fields["zip_code"],
      lat_long: "",
      course_name: fields["education"],
      experience: fields["experience"],
      registraion_no: fields["registraion_no"],
      practice: fields["practice"],
      area_of_expertise: fields["area_of_expertise"],
      fees: fields["fees"],
      commission: fields["commission"],

      languages: this.state.selectedLangName.toString(),
      languageids: this.state.selectedLangId.toString(),

      selected_language_id: this.state.selectedLangId.toString(),
      selected_language_name: this.state.selectedLangName.toString(),

      speciality_id: this.state.selectedSpecialityId.toString(),
      speciality_name: this.state.selectedSpecialityName.toString(),

      slot_duration: fields["slot_duration"],
      break_duration: fields["break_duration"],

      status: fields["status"],
      is_available: 0,
      profile_pic: profileImage,
      signature_pic: signatureImage,
    })
      .then((response) => {
        if (response.data.status === 204) {
          toast.error(response.data.message);
          return false;
        } else {
          toast.success("Successfully Created Doctor");
          setTimeout(() => this.props.history.push("/admin/ViewDoctors"), 1000);
        }
      })
      .catch((error) => { });
  };

  // updates controller
  updateDoctor = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    let selectedLang = "";
    if (this.state.selectedLangName == undefined) {
      let stateData = this.state.selectedValue;
      let names = stateData.map((x) => x.name);
      selectedLang = names.toString();
    } else {
      selectedLang = this.state.selectedLangName.toString();
    }

    let selectedLangIds = "";
    if (this.state.selectedLangId == undefined) {
      let stateIdData = this.state.selectedList;
      selectedLangIds = stateIdData.toString();
    } else {
      selectedLangIds = this.state.selectedLangId.toString();
    }

    let selectedSpeciality = "";
    if (this.state.selectedSpecialityName == undefined) {
      let stateData = this.state.speciality_selectedValue;
      let names = stateData.map((x) => x.category_type);
      selectedSpeciality = names.toString();
    } else {
      selectedSpeciality = this.state.selectedSpecialityName.toString();
    }

    let selectedSpecialityIds = "";
    if (this.state.selectedSpecialityId == undefined) {
      let stateIdData = this.state.speciality_selectedList;
      selectedSpecialityIds = stateIdData.toString();
    } else {
      selectedSpecialityIds = this.state.selectedSpecialityId.toString();
    }

    let profileImage = "";
    let signatureImage = "";
    if (this.state.fields.profile_pic) {
      profileImage = this.state.fields.profile_pic;
    }
    if (this.state.fields.signature_pic) {
      signatureImage = this.state.fields.signature_pic;
    }
    if (this.state.signatureUploaded == 0) {
      signatureImage = "";
    }
    if (this.state.profileUploaded == 0) {
      profileImage = "";
    }

    Httpconfig.httptokenput(Constant.siteurl + "api/Doctor/" + handle, {
      doctor_name: fields["name"],
      mobile_no: fields["mobile_number"],
      currency_name: fields["currency_name"],
      Country: fields["currency_name"],
      currency_symbol: fields["currency_symbol"],
      email: fields["email"],
      password: fields["password"],
      clinic_id: 1,
      user_type: "doctor",
      selected_language: "en-us",
      is_fingerprint_required: 0,
      gender: fields["gender"],
      dob: fields["dob"],
      address: fields["address"],
      city: fields["city"],
      state: fields["state"],
      zip_code: fields["zip_code"],
      lat_long: "",
      course_name: fields["education"],
      experience: fields["experience"],
      registraion_no: fields["registraion_no"],
      practice: fields["practice"],
      area_of_expertise: fields["area_of_expertise"],
      fees: fields["fees"],
      commission: fields["commission"],
      status: fields["status"],
      languages: selectedLang,
      languageids: selectedLangIds,

      selected_language_id: selectedLangIds,
      selected_language_name: selectedLang,

      speciality_id: selectedSpecialityIds,
      speciality_name: selectedSpeciality,

      slot_duration: fields["slot_duration"],
      break_duration: fields["break_duration"],

      is_available: 0,
      profile_pic: profileImage,
      signature_pic: signatureImage,
    })
      .then((response) => {
        toast.success("Successfully Updated Doctor");
        setTimeout(() => this.props.history.push("/admin/ViewDoctors"), 1000);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    var pattern = /^[a-zA-Z0-9]{3,20}$/g;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    } else if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is invalid";
      }
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    } else if (
      fields["password"].length < 8 ||
      fields["password"].length > 20
    ) {
      formIsValid = false;
      errors["password"] = "Password shuold contain 8-20 characters";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20}$/.exec(
        fields["password"]
      )
    ) {
      formIsValid = false;
      errors["password"] =
        "Required one upper case, one small case, one number and one special character";
    }
    if (!fields["mobile_number"]) {
      formIsValid = false;
      errors["mobile_number"] = "Phone number cannot be empty";
    } else if (fields["mobile_number"].length < 10) {
      formIsValid = false;
      errors["mobile_number"] = "Phone number invalid";
    }
    var regex = /\d/g;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Health Care Provider Name cannot be empty";
    } else if (regex.test(fields["name"])) {
      formIsValid = false;
      errors["name"] = "Please enter only letters, numbers not allowed.";
    }

    if (!fields["gender"]) {
      formIsValid = false;
      errors["gender"] = "gender cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City cannot be empty";
    }
    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = "State cannot be empty";
    }
    if (!fields["zip_code"]) {
      formIsValid = false;
      errors["zip_code"] = "Pin code cannot be empty";
    }
    if (!fields["education"]) {
      formIsValid = false;
      errors["education"] = "Education cannot be empty";
    }
    if (!fields["experience"]) {
      formIsValid = false;
      errors["experience"] = "Experience cannot be empty";
    }
    if (!fields["registraion_no"]) {
      formIsValid = false;
      errors["registraion_no"] = "Registration Number cannot be empty";
    }
    if (!fields["practice"]) {
      formIsValid = false;
      errors["practice"] = "Practice cannot be empty";
    }
    if (!fields["area_of_expertise"]) {
      formIsValid = false;
      errors["area_of_expertise"] = "Experctise cannot be empty";
    }
    if (!fields["fees"]) {
      formIsValid = false;
      errors["fees"] = "Fees cannot be empty";
    }
    if (!fields["commission"]) {
      formIsValid = false;
      errors["commission"] = "Commission cannot be empty";
    }

    if (!fields["currency_symbol"]) {
      formIsValid = false;
      errors["currency_symbol"] = "Currency Symbol cannot be empty";
    }

    if (!fields["currency_name"]) {
      formIsValid = false;
      errors["currency_name"] = "Currency Name cannot be empty";
    }
    if (Object.keys(this.state.selectedList).length == 0) {
      formIsValid = false;
      errors["languages"] = "Select Language(s)";
    } else {
      errors["languages"] = "";
    }
    if (Object.keys(this.state.speciality_selectedList).length == 0) {
      formIsValid = false;
      errors["specialities"] = "Select Specialities";
    } else {
      errors["specialities"] = "";
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = "Please select status";
    }
    if (!fields["profile_pic"]) {
      formIsValid = false;
      errors["profile_pic"] = "Please upload profile image";
    }
    if (!fields["signature_pic"]) {
      formIsValid = false;
      errors["signature_pic"] = "Please upload Signature image";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  //  For click image
  ImageClick = (event) => {
    var array = this.state.files;
    var foundValue = array.filter((obj) => obj.name != event.target.id);
    this.setState({ files: foundValue });
  };

  //   For zoom image
  ImageZoomClick = (event) => {
    this.setState({
      zoomimage: event.currentTarget.src,
    });
  };

  //  For remove image
  removeImageOnClick = (imageName) => {
    if (imageName == "profile_img") {
      this.setState({ ProfileImageFiles: "" });
      this.state.fields.profile_pic = "";
      this.state.profileUploaded = 0;
    } else {
      this.setState({ ProfileSignatureFiles: "" });
      this.state.fields.signature_pic = "";
      this.setState({ signatureUploaded: 0 });
    }
  };

  //  For showing password
  showPassword() {
    let type = this.state.type;
    if (this.state.type) {
      if (this.state.type == "input") {
        this.state.type = "password";
        this.state.img = "/images/doctor-img/Login screens/hide_icon.svg";
      } else {
        this.state.type = "input";
        this.state.img = "/images/doctor-img/Login screens/unhide_icon.svg";
      }
    }
    this.forceUpdate();
  }

  // for date change
  onDateChange = (field, event) => {
    var { fields } = this.state;

    if (new Date(event.currentTarget.value) > new Date()) {
      fields["dob"] = moment().format("YYYY-MM-DD");
    } else {
      fields["dob"] = event.currentTarget.value;
    }

    this.setState({ fields: fields });
  };

  //  for entering the value
  inputAccess = (e) => {
    const re = /^[A-Za-z]+$/;
    if (this.state.fields["name"] === "" || re.test(this.state.fields["name"]))
      this.setState({ value: this.state.fields["name"] });
  };

  //  For showing profile image
  showProfileImg = () => {
    this.state.profileImage = "";
    this.state.signatureImage = "";

    if (this.state.fields.profile_pic && this.state.profileUploaded == 0) {
      let imageName = this.state.fields.profile_pic;
      const fileProfilepath =
        "./public/uploads/doctor/profile_image/" + imageName;
      this.state.profileImage = imageName;
    }

    if (this.state.fields.signature_pic && this.state.signatureUploaded == 0) {
      let signatureName = this.state.fields.signature_pic;
      const fileProfilepath =
        "./public/uploads/doctor/signatures/" + signatureName;
      this.state.signatureImage = signatureName;
    }
    this.state.documentsRelated = [];
    this.state.SignaturesRelated = [];
    let imageArray = "";
    let imageSigArray = [];

    imageArray = this.state.fields.profile_pic;
    imageSigArray = this.state.fields.signature_pic;

    if (imageArray && this.state.profileUploaded == 1) {
      if (imageArray) {
        let img = imageArray.name.split(".");
        let imgName = img[0].replace(/[^A-Za-z]+/g, "");
        let imageName = imageArray.name;
        let imagebase64 = imageArray.base64;
        let imageType = imageArray.type;
        let imageSize = imageArray.size;
        let imageId = imageArray;

        this.state.documentsRelated.push(
          <div class={"upload_presc_img " + imgName}>
            <a class="thumbnail" data-toggle="modal" data-target="#lightbox">
              <img
                src={imagebase64}
                id={imgName}
                value={imageName}
                alt={imageName}
                name={imageName}
                onClick={this.ImageZoomClick.bind(this)}
              />
            </a>
            <div class="upload_top_img">
              <a
                href="#"
                class="thumbnail"
                data-toggle="modal"
                data-target="#lightbox"
              ></a>
              <img
                class="del_img"
                id={imageName}
                onClick={this.removeImageOnClick.bind(this, "profile_img")}
                src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
              />
            </div>
          </div>
        );
      }
    }

    if (imageSigArray && this.state.signatureUploaded == 1) {
      if (imageSigArray) {
        let img = imageSigArray.name.split(".");
        let imgSigName = img[0].replace(/[^A-Za-z]+/g, "");
        let imageSigName = imageSigArray.name;
        let imageSigbase64 = imageSigArray.base64;
        let imageSigType = imageSigArray.type;
        let imageSigSize = imageSigArray.size;
        let imageSigId = imageSigArray;
        this.state.SignaturesRelated.push(
          <div class={"upload_presc_img " + imgSigName}>
            <a class="thumbnail" data-toggle="modal" data-target="#lightbox">
              <img
                src={imageSigbase64}
                id={imgSigName}
                value={imageSigName}
                alt={imageSigName}
                name={imageSigName}
                onClick={this.ImageZoomClick.bind(this)}
              />
            </a>
            <div class="upload_top_img">
              <a
                href="#"
                class="thumbnail"
                data-toggle="modal"
                data-target="#lightbox"
              ></a>
              <img
                class="del_img"
                id={imageSigName}
                onClick={this.removeImageOnClick.bind(this, "sig_img")}
                src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
              />
            </div>
          </div>
        );
      }
    }
    this.forceUpdate();
  };
  render() {
    const { fields, errors, specialities_data, clinic_details, countryData } =
      this.state;

    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      <a> Health Care Provider </a>
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3>{this.state.pageHeading} </h3>
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Health Care Provider Name</label>
                                <input
                                  name="name"
                                  value={this.state.fields["name"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "name"
                                  )}
                                  className="form-control"
                                  placeholder="Name"
                                  type="text"
                                />
                                <span className="cRed">
                                  {this.state.errors["name"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Select Gender</label>
                                <select
                                  name="gender"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "gender"
                                  )}
                                  value={this.state.fields["gender"] || ""}
                                  className="form-control"
                                >
                                  <option value="">Select Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                                <span className="cRed">
                                  {this.state.errors["gender"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Mobile Number</label>
                                <input
                                  type="text"
                                  name="mobile_number"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  minLength="10"
                                  maxlength="10"
                                  value={
                                    this.state.fields["mobile_number"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "mobile_number"
                                  )}
                                  className="form-control"
                                  placeholder="Mobile Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["mobile_number"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Select Country</label>

                                <select
                                  name="currency_name"
                                  className="form-control"
                                  value={this.state.fields.currency_name}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "currency_name"
                                  )}
                                >
                                  <option
                                    value={this.state.fields.currency_name}
                                  >
                                    {this.state.fields.currency_name}
                                  </option>
                                </select>
                                <span className="cRed">
                                  {this.state.errors["currency_name"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Select Currency</label>
                                <select
                                  name="currency_symbol"
                                  className="form-control"
                                  value={this.state.fields.currency_symbol}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "currency_symbol"
                                  )}
                                >
                                  <option
                                    value={this.state.fields.currency_symbol}
                                  >
                                    Indian rupee
                                  </option>
                                </select>

                                <span className="cRed">
                                  {this.state.errors["currency_symbol"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>email</label>
                                <input
                                  type="text"
                                  name="email"
                                  autoComplete="new-password"
                                  value={this.state.fields["email"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "email"
                                  )}
                                  className="form-control"
                                  placeholder="Email"
                                />
                                <span className="cRed">
                                  {this.state.errors["email"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group ">
                                <label>Password</label>
                                <input
                                  type={this.state.type}
                                  name="password"
                                  autoComplete="new-password"
                                  value={this.state.fields["password"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "password"
                                  )}
                                  className="form-control log_input"
                                  placeholder="password"
                                />
                                <img
                                  class="password_view"
                                  onClick={this.showPassword}
                                  src={this.state.img}
                                />
                                <span className="cRed">
                                  {this.state.errors["password"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  type="text"
                                  name="address"
                                  value={this.state.fields["address"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "address"
                                  )}
                                  className="form-control"
                                  placeholder="Address"
                                />
                                <span className="cRed">
                                  {this.state.errors["address"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>City</label>
                                <input
                                  type="text"
                                  name="city"
                                  value={this.state.fields["city"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "city"
                                  )}
                                  className="form-control"
                                  placeholder="City"
                                />
                                <span className="cRed">
                                  {this.state.errors["city"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>State</label>
                                <input
                                  type="text"
                                  name="state"
                                  value={this.state.fields["state"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "state"
                                  )}
                                  className="form-control"
                                  placeholder="State"
                                />
                                <span className="cRed">
                                  {this.state.errors["state"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Pin Code</label>
                                <input
                                  type="text"
                                  name="zip_code"
                                  maxlength="6"
                                  minLength="6"
                                  value={this.state.fields["zip_code"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "zip_code"
                                  )}
                                  className="form-control"
                                  placeholder="Pin Code"
                                />
                                <span className="cRed">
                                  {this.state.errors["zip_code"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Education</label>
                                <input
                                  type="text"
                                  name="education"
                                  value={this.state.fields["education"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "education"
                                  )}
                                  className="form-control"
                                  placeholder="Education"
                                />
                                <span className="cRed">
                                  {this.state.errors["education"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Experience</label>
                                <input
                                  type="number"
                                  name="experience"
                                  value={this.state.fields["experience"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "experience"
                                  )}
                                  className="form-control"
                                  placeholder="Experience"
                                />
                                <span className="cRed">
                                  {this.state.errors["experience"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Registration Number</label>
                                <input
                                  type="text"
                                  name="registraion_no"
                                  value={
                                    this.state.fields["registraion_no"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "registraion_no"
                                  )}
                                  className="form-control"
                                  placeholder="Registration Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["registraion_no"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Practice</label>
                                <input
                                  type="text"
                                  name="practice"
                                  value={this.state.fields["practice"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "practice"
                                  )}
                                  className="form-control"
                                  placeholder="Practice"
                                />
                                <span className="cRed">
                                  {this.state.errors["practice"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Expertise</label>
                                <input
                                  type="text"
                                  name="area_of_expertise"
                                  value={
                                    this.state.fields["area_of_expertise"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "area_of_expertise"
                                  )}
                                  className="form-control"
                                  placeholder="Expertise"
                                />
                                <span className="cRed">
                                  {this.state.errors["area_of_expertise"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Fees</label>
                                <input
                                  type="number"
                                  name="fees"
                                  value={this.state.fields["fees"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "fees"
                                  )}
                                  className="form-control"
                                  placeholder="Fees"
                                />
                                <span className="cRed">
                                  {this.state.errors["fees"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Select Specialities</label>
                                <Multiselect
                                  onChange={this.handleChange.bind(
                                    this,
                                    "specialities"
                                  )}
                                  name="specialities"
                                  options={this.state.speciality_options}
                                  value={
                                    this.state.speciality_selectedList || ""
                                  }
                                  selectedValues={
                                    this.state.speciality_selectedValue
                                  }
                                  onSelect={this.onSpecialitySelect}
                                  placeholder="Select Specialities"
                                  onRemove={this.onSpecialityRemove}
                                  displayValue="category_type"
                                />
                                <span className="cRed">
                                  {this.state.errors["specialities"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Commission</label>
                                <input
                                  type="number"
                                  name="commission"
                                  value={this.state.fields["commission"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "commission"
                                  )}
                                  className="form-control"
                                  placeholder="Commission %"
                                />
                                <span className="cRed">
                                  {this.state.errors["commission"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select Languages</label>
                                <Multiselect
                                  onChange={this.handleChange.bind(
                                    this,
                                    "languages"
                                  )}
                                  name="languages"
                                  options={this.state.options}
                                  value={this.state.selectedList || ""}
                                  selectedValues={this.state.selectedValue}
                                  onSelect={this.onSelect}
                                  placeholder="Select Languages"
                                  onRemove={this.onRemove}
                                  displayValue="name"
                                />
                                <span className="cRed">
                                  {this.state.errors["languages"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-4">
                              <label>Upload Signature</label>
                              <FileBase64
                                multiple={false}
                                onDone={this.getProfileSignatureFile.bind(this)}
                              />

                              <div class="upload_imgs" id="signature_pic_div">
                                {this.state.signatureUploaded == 1 ? (
                                  this.state.SignaturesRelated[0]
                                ) : this.state.signatureImage ? (
                                  <div class="upload_view_img">
                                    <a
                                      href="#"
                                      class="thumbnail"
                                      data-toggle="modal"
                                      data-target="#lightbox"
                                    >
                                      <img
                                        src={this.state.signatureImage}
                                        onClick={this.ImageZoomClick.bind(this)}
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <span className="cRed">
                                {this.state.errors["signature_pic"]}
                              </span>
                            </div>

                            <div className="form-group col-md-4">
                              <label>Upload Profile Image</label>
                              <FileBase64
                                multiple={false}
                                onDone={this.getProfileImageFile.bind(this)}
                              />

                              <div class="upload_imgs" id="profile_pic_div">
                                {this.state.profileUploaded == 1 ? (
                                  this.state.documentsRelated
                                ) : this.state.profileImage ? (
                                  <div class="upload_view_img">
                                    <a
                                      href="#"
                                      class="thumbnail"
                                      data-toggle="modal"
                                      data-target="#lightbox"
                                    >
                                      <img
                                        src={this.state.profileImage}
                                        onClick={this.ImageZoomClick.bind(this)}
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <span className="cRed">
                                {this.state.errors["profile_pic"]}
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Date of birth</label>
                                <br />
                                <input
                                  type="date"
                                  id="psdate"
                                  name="psdate"
                                  className="form-control"
                                  data-ng-model="date"
                                  value={this.state.fields["dob"]}
                                  max={moment().format("YYYY-MM-DD")}
                                  onChange={this.onDateChange.bind(
                                    this,
                                    "psdate"
                                  )}
                                />
                                <br />
                                <span className="cRed">
                                  {this.state.errors["registration_expirity"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 cr_mid">
                              <div className="form-group ">
                                <label
                                  for="customRadio2"
                                  class="custom-control-label"
                                >
                                  Status
                                </label>
                                <div
                                  class="custom-control custom-radio"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "status"
                                  )}
                                >
                                  <input
                                    type="radio"
                                    value="0"
                                    checked={this.state.fields["status"] == "0"}
                                    name="status"
                                  />{" "}
                                  Active &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="1"
                                    checked={this.state.fields["status"] == "1"}
                                    name="status"
                                  />{" "}
                                  In Active
                                  <span className="cRed">
                                    {this.state.errors["status"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <input
                                  type="hidden"
                                  name="slot_duration"
                                  value="10"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "slot_duration"
                                  )}
                                  className="form-control"
                                  placeholder="Slot Duration"
                                />
                                <span className="cRed">
                                  {this.state.errors["slot_duration"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="hidden"
                                  name="break_duration"
                                  value="5"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "break_duration"
                                  )}
                                  className="form-control"
                                  placeholder="Break Duration"
                                />
                                <span className="cRed">
                                  {this.state.errors["break_duration"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row"></div>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <button
                                type="submit"
                                className="btn  btn-primary save_btn"
                              >
                                Save Health Care Provider
                              </button>{" "}
                              &nbsp;
                              <Link
                                to="/admin/ViewDoctors"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div
          id="lightbox"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <button
              type="button"
              class="close hidden"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
            <div class="modal-content">
              <div class="modal-body">
                <img src={this.state.zoomimage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
