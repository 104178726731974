import React, { Component } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const moment = require("moment");

const morning_slots = [
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "11:59 AM",
];

const noon_slots = [
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
];

const evening_slots = [
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
];

const night_slots = [
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
  "11:59 PM",
];

const currentDDate = new Date(); // Now
const sevenDays = currentDDate.setDate(currentDDate.getDate() + 7);

export default class Createdoctorslots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        isMorningChecked: true,
        isAfternoonChecked: true,
        isEveningChecked: true,
        isNightChecked: true,

        isMrngRepeat: false,
        isAftrnRepeat: false,
        isEvngRepeat: false,
        isNghtRepeat: false,

        slot_hours: "00",
        slot_minutes: "15",
        break_minutes: "05",

        morning_from: "01:00 AM",
        morning_to: "01:30 AM",
        noon_from: "12:00 PM",
        noon_to: "12:30 PM",
        evening_from: "04:00 PM",
        evening_to: "04:30 PM",
        night_from: "08:00 PM",
        night_to: "08:30 PM",

        start_date: new Date(),
        end_date: sevenDays,
      },

      day_categories: [
        { id: "S", status: "S", displayName: "S", name: "Sunday" },
        { id: "M", status: "M", displayName: "M", name: "Monday" },
        { id: "T", status: "T", displayName: "T", name: "Tuesday" },
        { id: "W", status: "W", displayName: "W", name: "Wednesday" },
        { id: "T", status: "T", displayName: "T", name: "Thursday" },
        { id: "F", status: "F", displayName: "F", name: "Friday" },
        { id: "S", status: "S", displayName: "S", name: "Saturday" },
      ],
      errors: {},
      active: null,
      docData: "",
      selected: false,
      weekDay: "",
      value: "Select an Option",
      morningChecked: [],
      afternoonChecked: [],
      eveningChecked: [],
      nightChecked: [],
      MorningSelectStatus: "Select",
      AfternoonSelectStatus: "Select",
      EveningSelectStatus: "Select",
      NightSelectStatus: "Select",
      update: "",
    };
    this.checkMorningButtom = this.checkMorningButtom.bind(this);
    this.checkAfternoonButtom = this.checkAfternoonButtom.bind(this);
    this.checkEveningButtom = this.checkEveningButtom.bind(this);
    this.checkNightButtom = this.checkNightButtom.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    if (handle) {
      this.fetchDoctorSlots(handle);
      this.state.fields["doctor_id"] = handle;
      this.forceUpdate();
      this.state.update = "edit";
    }

    this.getDoctorDetails();
  };

  //Fetch the Doctor Appointment Slots Starts
  fetchDoctorSlots = (id) => {
    Httpconfig.httptokenpost(Constant.siteurl + "api/telemedicine/getSlots", {
      doctor_id: id,
    })
      .then((response) => {

        if (response.data.error == true) {
          toast.error(response.data.message);
          // setTimeout(
          //   () => this.props.history.push("/admin/ViewDoctorSlots"),
          //   2000
          // );
        }
        this.state.fields["isMorningChecked"] = false;
        this.state.fields["isAfternoonChecked"] = false;
        this.state.fields["isEveningChecked"] = false;
        this.state.fields["isNightChecked"] = false;
        var days = [];
        let responseData = response.data.result;
        let checkedMorningItems = [];
        let checkedAfternoonItems = [];
        let checkedEveningItems = [];
        let checkedNightItems = [];
        var daysExistsMorning = "";
        var duration = responseData.consultationDuration;
        duration = duration.replace("00:", "");
        duration = duration.replace(":00", "");
        var breakTime = responseData.breakTime;
        breakTime = breakTime.replace("00:", "");
        breakTime = breakTime.replace(":00", "");

        this.state.fields["start_date"] = moment(
          responseData.fromDate
        ).toDate();
        this.state.fields["end_date"] = moment(responseData.toDate).toDate();
        this.state.fields["slot_minutes"] = duration;
        this.state.fields["break_minutes"] = breakTime;

        this.forceUpdate();
        if (responseData.DateObject) {
          var datesLength = Object.keys(responseData.DateObject).length;

          for (let count = 0; count < datesLength; count++) {
            let getdetails = moment(responseData.fromDate)
              .add(count, "days")
              .format("YYYY-MM-DD");
            if (responseData.DateObject[getdetails]) {
              var slotsdatecount = Object.keys(
                responseData.DateObject[getdetails]
              ).length;
              var rawData = responseData.DateObject[getdetails];
              for (
                let slotbreakcount = 0;
                slotbreakcount < slotsdatecount;
                slotbreakcount++
              ) {
                if (responseData.DateObject[getdetails]["Morning"]) {
                  this.state.fields["isMorningChecked"] =
                    responseData.DateObject[getdetails]["Morning"][
                      "morningSlotStatus"
                    ];
                  this.state.fields["morning_from"] =
                    responseData.DateObject[getdetails]["Morning"]["startTime"][
                      "slotTime"
                    ];
                  this.state.fields["morning_to"] =
                    responseData.DateObject[getdetails]["Morning"]["endTime"][
                      "slotTime"
                    ];
                  if (
                    responseData.DateObject[getdetails]["Morning"][
                      "repeat_days"
                    ]
                  ) {
                    var weekDaysLength = Object.keys(
                      responseData.DateObject[getdetails]["Morning"][
                        "repeat_days"
                      ]
                    ).length;
                    for (
                      let weekDaysCount = 0;
                      weekDaysCount < weekDaysLength;
                      weekDaysCount++
                    ) {
                      if (
                        responseData.DateObject[getdetails]["Morning"][
                          "repeat_days"
                        ][weekDaysCount]["status"] === "true"
                      ) {
                        var name =
                          responseData.DateObject[getdetails]["Morning"][
                            "repeat_days"
                          ][weekDaysCount]["name"];
                        $(".MorningWeekDays")
                          .filter('[name="' + name + '"]')
                          .prop("checked", true);
                        if ($(".MorningWeekDays").prop("checked") == true) {
                          $("#selectMorningAllWeekDays").prop("checked", true);
                          this.state.MorningSelectStatus = "Deselect";
                        }
                        //this.state.morningChecked=[];
                        var morningObj = {
                          name: responseData.DateObject[getdetails]["Morning"][
                            "repeat_days"
                          ][weekDaysCount]["name"],
                          status: true,
                          displayName:
                            responseData.DateObject[getdetails]["Morning"][
                              "repeat_days"
                            ][weekDaysCount]["displayName"],
                        };
                        if (checkedMorningItems.length > 0) {
                          checkedMorningItems = this.getUnique(
                            checkedMorningItems,
                            "name"
                          );
                        }
                        checkedMorningItems.push(morningObj);
                      }
                    }
                  }
                  this.state.morningChecked = checkedMorningItems;
                }
                // For Morning Slots data loop
                if (responseData.DateObject[getdetails]["Afternoon"]) {
                  this.state.fields["isAfternoonChecked"] =
                    responseData.DateObject[getdetails]["Afternoon"][
                      "afternoonSlotStatus"
                    ];
                  this.state.fields["noon_from"] =
                    responseData.DateObject[getdetails]["Afternoon"][
                      "startTime"
                    ]["slotTime"];
                  this.state.fields["noon_to"] =
                    responseData.DateObject[getdetails]["Afternoon"]["endTime"][
                      "slotTime"
                    ];
                  if (
                    responseData.DateObject[getdetails]["Afternoon"][
                      "repeat_days"
                    ]
                  ) {
                    var weekDaysLength = Object.keys(
                      responseData.DateObject[getdetails]["Afternoon"][
                        "repeat_days"
                      ]
                    ).length;
                    for (
                      let weekDaysCount = 0;
                      weekDaysCount < weekDaysLength;
                      weekDaysCount++
                    ) {
                      if (
                        responseData.DateObject[getdetails]["Afternoon"][
                          "repeat_days"
                        ][weekDaysCount]["status"] === "true"
                      ) {
                        var name =
                          responseData.DateObject[getdetails]["Afternoon"][
                            "repeat_days"
                          ][weekDaysCount]["name"];
                        $(".AfternoonWeekDays")
                          .filter('[name="' + name + '"]')
                          .prop("checked", true);
                        if ($(".AfternoonWeekDays").prop("checked") == true) {
                          $("#selectAfternoonAllWeekDays").prop(
                            "checked",
                            true
                          );
                          this.state.AfternoonSelectStatus = "Deselect";
                        }
                        var afternoonObj = {
                          name: responseData.DateObject[getdetails][
                            "Afternoon"
                          ]["repeat_days"][weekDaysCount]["name"],
                          status: true,
                          displayName:
                            responseData.DateObject[getdetails]["Afternoon"][
                              "repeat_days"
                            ][weekDaysCount]["displayName"],
                        };

                        if (checkedAfternoonItems.length > 0) {
                          checkedAfternoonItems = this.getUnique(
                            checkedAfternoonItems,
                            "name"
                          );
                        }
                        checkedMorningItems.push(afternoonObj);
                      }
                    }
                  }
                  this.state.afternoonChecked = checkedMorningItems;
                }

                // For Evening Slots data loop

                if (responseData.DateObject[getdetails]["Evening"]) {
                  this.state.fields["isEveningChecked"] =
                    responseData.DateObject[getdetails]["Evening"][
                      "eveningSlotStatus"
                    ];
                  this.state.fields["evening_from"] =
                    responseData.DateObject[getdetails]["Evening"]["startTime"][
                      "slotTime"
                    ];
                  this.state.fields["evening_to"] =
                    responseData.DateObject[getdetails]["Evening"]["endTime"][
                      "slotTime"
                    ];
                  if (
                    responseData.DateObject[getdetails]["Evening"][
                      "repeat_days"
                    ]
                  ) {
                    var weekDaysLength = Object.keys(
                      responseData.DateObject[getdetails]["Evening"][
                        "repeat_days"
                      ]
                    ).length;
                    for (
                      let weekDaysCount = 0;
                      weekDaysCount < weekDaysLength;
                      weekDaysCount++
                    ) {
                      if (
                        responseData.DateObject[getdetails]["Evening"][
                          "repeat_days"
                        ][weekDaysCount]["status"] === "true"
                      ) {
                        var name =
                          responseData.DateObject[getdetails]["Evening"][
                            "repeat_days"
                          ][weekDaysCount]["name"];
                        $(".EveningWeekDays")
                          .filter('[name="' + name + '"]')
                          .prop("checked", true);
                        if ($(".EveningWeekDays").prop("checked") == true) {
                          $("#selectEveningAllWeekDays").prop("checked", true);
                          this.state.EveningSelectStatus = "Deselect";
                        }
                        var Obj = {
                          name: responseData.DateObject[getdetails]["Evening"][
                            "repeat_days"
                          ][weekDaysCount]["name"],
                          status: true,
                          displayName:
                            responseData.DateObject[getdetails]["Evening"][
                              "repeat_days"
                            ][weekDaysCount]["displayName"],
                        };

                        if (checkedEveningItems.length > 0) {
                          checkedEveningItems = this.getUnique(
                            checkedEveningItems,
                            "name"
                          );
                        }
                        checkedEveningItems.push(Obj);
                      }
                    }
                  }

                  this.state.eveningChecked = checkedEveningItems;
                }

                // For Night Slots data loop
                if (responseData.DateObject[getdetails]["Night"]) {
                  this.state.fields["isNightChecked"] =
                    responseData.DateObject[getdetails]["Night"][
                      "nightSlotStatus"
                    ];
                  this.state.fields["night_from"] =
                    responseData.DateObject[getdetails]["Night"]["startTime"][
                      "slotTime"
                    ];
                  this.state.fields["night_to"] =
                    responseData.DateObject[getdetails]["Night"]["endTime"][
                      "slotTime"
                    ];
                  if (
                    responseData.DateObject[getdetails]["Night"]["repeat_days"]
                  ) {
                    var weekDaysLength = Object.keys(
                      responseData.DateObject[getdetails]["Night"][
                        "repeat_days"
                      ]
                    ).length;
                    for (
                      let weekDaysCount = 0;
                      weekDaysCount < weekDaysLength;
                      weekDaysCount++
                    ) {
                      if (
                        responseData.DateObject[getdetails]["Night"][
                          "repeat_days"
                        ][weekDaysCount]["status"] === "true"
                      ) {
                        var name =
                          responseData.DateObject[getdetails]["Night"][
                            "repeat_days"
                          ][weekDaysCount]["name"];
                        $(".NightWeekDays")
                          .filter('[name="' + name + '"]')
                          .prop("checked", true);
                        if ($(".NightWeekDays").prop("checked") == true) {
                          $("#selectNightAllWeekDays").prop("checked", true);
                          this.state.NightSelectStatus = "Deselect";
                        }
                        var Obj = {
                          name: responseData.DateObject[getdetails]["Night"][
                            "repeat_days"
                          ][weekDaysCount]["name"],
                          status: true,
                          displayName:
                            responseData.DateObject[getdetails]["Night"][
                              "repeat_days"
                            ][weekDaysCount]["displayName"],
                        };
                        if (checkedNightItems.length > 0) {
                          checkedNightItems = this.getUnique(
                            checkedNightItems,
                            "name"
                          );
                        }
                        checkedNightItems.push(Obj);
                      }
                    }
                  }
                  this.state.nightChecked = checkedNightItems;
                }
                this.forceUpdate();
              }
            }
          }
        }
      })
      .catch((error) => {});
  };
  // Ends
  // check unique weekdays
  getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  // Get Doctor List
  getDoctorDetails() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Doctor/getAllDoctors")
      .then((response) => {
        this.setState({
          doctorNames: response.data.data,
        });
        let doctorId = this.props.match.params.handle;
        if (this.props.match.params.handle) {
          var docdata = response.data.data.find((item) => {
            if (item.id == doctorId) return item;
          });
          this.state.docData = docdata;
        }
        this.forceUpdate();
      })
      .catch((error) => {});
  }
  // Ends
  selectAllWeekDays = (type) => {
    this.state.daytype = type;
    var noData = [];
    var merged = [
      { name: "Sunday", status: true, displayName: "S" },
      { name: "Monday", status: true, displayName: "M" },
      { name: "Tuesday", status: true, displayName: "T" },
      { name: "Wednesday", status: true, displayName: "W" },
      { name: "Thursday", status: true, displayName: "T" },
      { name: "Friday", status: true, displayName: "F" },
      { name: "Saturday", status: true, displayName: "S" },
    ];
    // Morning Week days select all
    if (type == "morning") {
      var status = $("#selectMorningAllWeekDays").prop("checked");
      if (status === true) {
        this.state.MorningSelectStatus = "Deselect";
        $(".MorningWeekDays").prop("checked", true);
        this.state.morningChecked = merged;
        this.forceUpdate();
      } else {
        this.state.MorningSelectStatus = "Select";
        $(".MorningWeekDays").prop("checked", false);
        this.state.morningChecked = noData;
        this.forceUpdate();
      }
    }

    // After noon Week days select all
    if (type == "afternoon") {
      var status = $("#selectAfternoonAllWeekDays").prop("checked");

      if (status === true) {
        this.state.AfternoonSelectStatus = "Deselect";
        $(".AfternoonWeekDays").prop("checked", true);
        this.state.afternoonChecked = merged;
        this.forceUpdate();
      } else {
        this.state.AfternoonSelectStatus = "Select";
        $(".AfternoonWeekDays").prop("checked", false);
        this.state.afternoonChecked = noData;
        this.forceUpdate();
      }
    }
    // Evening Week days select all
    if (type == "evening") {
      var status = $("#selectEveningAllWeekDays").prop("checked");
      if (status === true) {
        this.state.EveningSelectStatus = "Deselect";
        $(".EveningWeekDays").prop("checked", true);
        this.state.eveningChecked = merged;
        this.forceUpdate();
      } else {
        this.state.EveningSelectStatus = "Select";
        $(".EveningWeekDays").prop("checked", false);
        this.state.eveningChecked = noData;
        this.forceUpdate();
      }
    }
    // Night Week days select all
    if (type == "night") {
      var status = $("#selectNightAllWeekDays").prop("checked");
      if (status === true) {
        this.state.NightSelectStatus = "Deselect";
        $(".NightWeekDays").prop("checked", true);
        this.state.nightChecked = merged;
        this.forceUpdate();
      } else {
        this.state.NightSelectStatus = "Select";
        $(".NightWeekDays").prop("checked", false);
        this.state.nightChecked = noData;
        this.forceUpdate();
      }
    }
  };

  // Section section and enable and disable
  selectDisableSlotsSection = (type) => {
    var noData = [];
    var merged = [
      { name: "Sunday", status: true, displayName: "S" },
      { name: "Monday", status: true, displayName: "M" },
      { name: "Tuesday", status: true, displayName: "T" },
      { name: "Wednesday", status: true, displayName: "W" },
      { name: "Thursday", status: true, displayName: "T" },
      { name: "Friday", status: true, displayName: "F" },
      { name: "Saturday", status: true, displayName: "S" },
    ];
    // Morning Week days select all
    if (type == "morning_slot") {
      var status = $("#morning_slot").prop("checked");

      if (status === false) {
        $("#selectMorningAllWeekDays").prop("checked", false);
        this.state.MorningSelectStatus = "Select";
        $(".MorningWeekDays").prop("checked", false);
        this.state.morningChecked = noData;
        this.forceUpdate();
      }
    }

    // After noon Week days select all
    if (type == "afternoon_slot") {
      var status = $("#afternoon_slot").prop("checked");

      if (status === false) {
        $("#selectAfternoonAllWeekDays").prop("checked", false);
        this.state.AfternoonSelectStatus = "Select";
        $(".AfternoonWeekDays").prop("checked", false);
        this.state.afternoonChecked = noData;
        this.forceUpdate();
      }
    }
    // Evening Week days select all
    if (type == "evening_slot") {
      var status = $("#evening_slot").prop("checked");
      if (status === false) {
        $("#selectEveningAllWeekDays").prop("checked", false);
        this.state.EveningSelectStatus = "Select";
        $(".EveningWeekDays").prop("checked", false);
        this.state.eveningChecked = noData;
        this.forceUpdate();
      }
    }
    // Night Week days select all
    if (type == "night_slot") {
      var status = $("#night_slot").prop("checked");
      if (status === false) {
        $("#selectNightAllWeekDays").prop("checked", false);
        this.state.NightSelectStatus = "Select";
        $(".NightWeekDays").prop("checked", false);
        this.state.nightChecked = noData;
        this.forceUpdate();
      }
    }
  };

  // Morning Days Selects Starts
  checkMorningButtom(event) {
    const target = event.target;
    var value = target.value;
    var valueName = target.name;
    let checkedMorningItems = [];
    if (target.checked) {
      var morningObj = {
        name: valueName,
        status: true,
        displayName: value,
      };
      checkedMorningItems.push(morningObj);
      var initialData = this.state.morningChecked;
      var newData = checkedMorningItems;
      var names = new Set(initialData.map((d) => d.name));
      var merged = [
        ...initialData,
        ...newData.filter((d) => !names.has(d.names)),
      ];
      this.state.morningChecked = merged;
      this.forceUpdate();
    } else {
      var arr = this.state.morningChecked;
      const filteredMorning = arr.filter((item) => item.name !== valueName);
      this.state.morningChecked = filteredMorning;
      this.forceUpdate();
    }
  }
  // Morning Days Selects Ends here

  // Afternoon Days Selects Starts
  checkAfternoonButtom(event) {
    const target = event.target;
    var value = target.value;
    var valueName = target.name;
    let checkedAfternoonItems = [];
    if (target.checked) {
      var noonObj = {
        name: valueName,
        status: true,
        displayName: value,
      };
      checkedAfternoonItems.push(noonObj);
      var initialData = this.state.afternoonChecked;
      var newData = checkedAfternoonItems;
      var names = new Set(initialData.map((d) => d.name));
      var merged = [
        ...initialData,
        ...newData.filter((d) => !names.has(d.names)),
      ];
      this.state.afternoonChecked = merged;
      this.forceUpdate();
    } else {
      var arr = this.state.afternoonChecked;
      const filteredNoon = arr.filter((item) => item.name !== valueName);
      this.state.afternoonChecked = filteredNoon;
      this.forceUpdate();
    }
  }
  // Afternoon Days Selects Ends here

  // Evening Days Selects Starts
  checkEveningButtom(event) {
    const target = event.target;
    var value = target.value;
    var valueName = target.name;
    let checkedEveningItems = [];
    if (target.checked) {
      var eveningObj = {
        name: valueName,
        status: true,
        displayName: value,
      };
      checkedEveningItems.push(eveningObj);
      var initialData = this.state.eveningChecked;
      var newData = checkedEveningItems;
      var names = new Set(initialData.map((d) => d.name));
      var merged = [
        ...initialData,
        ...newData.filter((d) => !names.has(d.names)),
      ];
      this.state.eveningChecked = merged;
      this.forceUpdate();
    } else {
      var arr = this.state.eveningChecked;
      const filteredEvening = arr.filter((item) => item.name !== valueName);
      this.state.eveningChecked = filteredEvening;
      this.forceUpdate();
    }
  }
  // Evening Days Selects Ends here

  // Night Days Selects Starts
  checkNightButtom(event) {
    const target = event.target;
    var value = target.value;
    var valueName = target.name;
    let checkedNightItems = [];
    if (target.checked) {
      var nightObj = {
        name: valueName,
        status: true,
        displayName: value,
      };
      checkedNightItems.push(nightObj);
      var initialData = this.state.nightChecked;
      var newData = checkedNightItems;
      var names = new Set(initialData.map((d) => d.name));
      var merged = [
        ...initialData,
        ...newData.filter((d) => !names.has(d.names)),
      ];
      this.state.nightChecked = merged;
      this.forceUpdate();
    } else {
      var arr = this.state.nightChecked;
      const filteredNight = arr.filter((item) => item.name !== valueName);
      this.state.nightChecked = filteredNight;
      this.forceUpdate();
    }
  }
  // Night Days Selects Ends here

  // Repeat Functionality Starts here
  isRepeatMorningChange = () => {
    this.state.fields["isMrngRepeat"] = !this.state.fields.isMrngRepeat;
    this.forceUpdate();
  };

  isRepeatAfternoonChange = () => {
    this.state.fields["isAftrnRepeat"] = !this.state.fields.isAftrnRepeat;
    this.forceUpdate();
  };

  isRepeatEveningChange = () => {
    this.state.fields["isEvngRepeat"] = !this.state.fields.isEvngRepeat;
    this.forceUpdate();
  };

  isRepeatNightChange = () => {
    this.state.fields["isNghtRepeat"] = !this.state.fields.isNghtRepeat;
    this.forceUpdate();
  };

  // Is repeat Functionality Ends here

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
    this.forceUpdate();
  };
  // For morning change
  toggleMorningChange = () => {
    this.state.fields["isMorningChecked"] = !this.state.fields.isMorningChecked;
    this.forceUpdate();
    this.selectDisableSlotsSection("morning_slot");
  };
  //  For afternoon change
  toggleAfternoonChange = () => {
    this.state.fields["isAfternoonChecked"] =
      !this.state.fields.isAfternoonChecked;
    this.forceUpdate();
    this.selectDisableSlotsSection("afternoon_slot");
  };
  //  For evening change
  toggleEveningChange = () => {
    this.state.fields["isEveningChecked"] = !this.state.fields.isEveningChecked;
    this.forceUpdate();
    this.selectDisableSlotsSection("evening_slot");
  };
  //  For night change

  toggleNightChange = () => {
    this.state.fields["isNightChecked"] = !this.state.fields.isNightChecked;
    this.forceUpdate();
    this.selectDisableSlotsSection("night_slot");
  };

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateDoctorSlots(handle);
    } else if (this.handleValidation() && handle == undefined) {
      this.createDoctorSlots(event);
    } else {
      toast.warn("Form has errors.");
    }
  }

  // Delete All Doctor Slots
  deleteDoctorSlots = (event) => {
    let doctorId = this.state.fields.doctor_id;
    if (doctorId) {
      var isConfirm = window.confirm(
        "Are you sure to Delete all Doctor Slots."
      );
      if (isConfirm) {
        Httpconfig.httptokendelete(
          Constant.siteurl +
            "api/telemedicine/deleteAllScheduledSlots/" +
            doctorId
        ).then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            toast.success("Slots Deleted Sucessfully.", {
              position: "bottom-center",
            });
            setTimeout(
              () => this.props.history.push("/admin/ViewDoctorSlots"),
              2000
            );
          }
        });
      }
    } else {
      alert("Please select Health Care Provider ");
    }
  };
  // Ends here
  //  For view slots
  viewSlots = () => {
    setTimeout(() => this.props.history.push("/admin/ViewDoctorSlots"), 2000);
  };
  //update slots
  updateDoctorSlots = (event) => {
    let doctorId = this.state.fields.doctor_id;
    if (doctorId) {
      var isConfirm = window.confirm(
        "Are you sure to update all Doctor Slots."
      );
      if (isConfirm) {
        Httpconfig.httptokendelete(
          Constant.siteurl +
            "api/telemedicine/deleteAllScheduledSlots/" +
            doctorId
        ).then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            this.createDoctorSlots(event);
          }
        });
      }
    } else {
      alert("Please select Health Care Provider ");
    }
  };

  // creates new controller
  createDoctorSlots = (event) => {
    const { fields, errors } = this.state;
    let start_date = new Date(this.state.fields.start_date);
    let selectedStartDate =
      start_date.getFullYear() +
      "-" +
      Number(start_date.getMonth() + 1) +
      "-" +
      start_date.getDate();

    let end_date = new Date(this.state.fields.end_date);
    let selectedEndDate =
      end_date.getFullYear() +
      "-" +
      Number(end_date.getMonth() + 1) +
      "-" +
      end_date.getDate();

    // Morning Object Starts
    let morningCheckedDays = this.state.morningChecked;
    let morningCheckedDaysLength = morningCheckedDays.length;

    let morningObject = {};
    morningObject.morningSlotStatus =
      "" + this.state.fields.isMorningChecked + "";
    morningObject.repeat_days = this.state.morningChecked;
    morningObject.repeat = "" + this.state.fields.isMrngRepeat + "";
    morningObject.startTime = {
      slotTime: this.state.fields.morning_from,
    };
    morningObject.endTime = {
      slotTime: this.state.fields.morning_to,
    };
    // Morning Object Ends

    // Afternoon Object Starts
    let afternoonCheckedDays = this.state.afternoonChecked;
    let afternoonCheckedDaysLength = afternoonCheckedDays.length;

    let afternoonObject = {};
    afternoonObject.afternoonSlotStatus =
      "" + this.state.fields.isAfternoonChecked + "";
    afternoonObject.repeat_days = this.state.afternoonChecked;
    afternoonObject.repeat = "" + this.state.fields.isAftrnRepeat + "";
    afternoonObject.startTime = {
      slotTime: this.state.fields.noon_from,
    };
    afternoonObject.endTime = {
      slotTime: this.state.fields.noon_to,
    };
    // Afternoon Object Ends here

    // Evening Object Starts
    let eveningCheckedDays = this.state.eveningChecked;
    let eveningCheckedDaysLength = eveningCheckedDays.length;

    let eveningObject = {};
    eveningObject.eveningSlotStatus =
      "" + this.state.fields.isEveningChecked + "";
    eveningObject.repeat_days = this.state.eveningChecked;
    eveningObject.repeat = "" + this.state.fields.isEvngRepeat + "";
    eveningObject.startTime = {
      slotTime: this.state.fields.evening_from,
    };
    eveningObject.endTime = {
      slotTime: this.state.fields.evening_to,
    };
    // Evening Object Ends here

    // Night Object Starts
    let nightCheckedDays = this.state.nightChecked;
    let nightCheckedDaysLength = nightCheckedDays.length;

    let nightObject = {};
    nightObject.nightSlotStatus = "" + this.state.fields.isNightChecked + "";
    nightObject.repeat_days = this.state.nightChecked;
    nightObject.repeat = "" + this.state.fields.isNghtRepeat + "";
    nightObject.startTime = {
      slotTime: this.state.fields.night_from,
    };
    nightObject.endTime = {
      slotTime: this.state.fields.night_to,
    };
    // Night Object Ends here

    let startDate = new Date(selectedStartDate);
    let endDate = new Date(selectedEndDate);

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (startDate < endDate && diffDays < 7) {
      alert(
        "End Date Greater than Start Date and difference Should Minimum Week.."
      );
      return;
    }

    let finalSlotArray = {
      fromDate: selectedStartDate,
      toDate: selectedEndDate,
      doctorId: this.state.fields.doctor_id,
      module_type: "telemedicine-app",
      Morning: morningObject,
      Afternoon: afternoonObject,
      Evening: eveningObject,
      Night: nightObject,
      consultationDuration:
        this.state.fields.slot_hours + ":" + this.state.fields.slot_minutes,
      timezone_offset: "Asia/Kolkata",
      eachTimeslotHours: this.state.fields.slot_hours,
      eachTimeslotMinutes: this.state.fields.slot_minutes,
      breakTime: "00:" + this.state.fields.break_minutes,
      notificationTime: "00:10:00",
    };

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/telemedicine/createDoctorSlots",
      finalSlotArray
    )
      .then((response) => {
        if (response.data.status === 200) {
          const { handle } = this.props.match.params;
          if (this.state.update == "edit") {
            toast.success("Slots updated successfully");
            setTimeout(
              () => this.props.history.push("/admin/ViewDoctorSlots"),
              2000
            );
            return;
          }
          toast.success("Slots successfully created..");
          setTimeout(
            () => this.props.history.push("/admin/ViewDoctorSlots"),
            2000
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // Ends here
  //  For set start date change
  setStartDateOnChange = (value) => {
    this.state.fields["start_date"] = value;
    this.state.fields["end_date"] = this.state.fields.end_date;
    this.forceUpdate();
  };
  //  For set end date change
  setEndDateOnChange = (value) => {
    this.state.fields["start_date"] = this.state.fields.start_date;
    this.state.fields["end_date"] = value;
    this.forceUpdate();
  };
  //  For select week day
  onSelectWeekDay = (weekDay) => {
    this.setState({
      selected: true,
      weekDay: weekDay,
    });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["start_date"]) {
      formIsValid = false;
      errors["start_date"] = "Start Date cannot be empty";
    }
    if (!fields["end_date"]) {
      formIsValid = false;
      errors["end_date"] = "End Date cannot be empty";
    }
    if (!fields["slot_hours"]) {
      formIsValid = false;
      errors["slot_hours"] = "Slot Hours cannot be empty";
    }
    if (!fields["slot_minutes"]) {
      formIsValid = false;
      errors["slot_minutes"] = "Slot Minutes cannot be empty";
    }
    if (!fields["break_minutes"]) {
      formIsValid = false;
      errors["break_minutes"] = "Break Minutes cannot be empty";
    }
    if (!fields["doctor_id"]) {
      formIsValid = false;
      errors["doctor_id"] = "Doctor Id cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors, doctorNames } = this.state;

    return (
      <main>
        <section id="create_slot_sec" class="container">
          {this.state.docData != "" ? (
            <p className="create_doc_slot_name">
              Dr.{this.state.docData.doctor_name}
            </p>
          ) : (
            <span></span>
          )}
            <div class="container">
            <div class="row">
              <div class="col-md-12">
                
            
           <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt; Clinic Setup &gt;
                       {this.state.docData != "" ? "Edit Slots" : "Create Slots"}
                    </li>
                  </ol>
                </div>
              </div>
              </div>
            </div>
          </div>
          {/* <ol className="create_doc_slots">
            <li className="active">
              <Link to="/admin"> Dashboard</Link> &gt;{" "}
              {this.state.docData != "" ? "Edit Slots" : "Create Slots"}
            </li>
          </ol> */}

          <form onSubmit={this.checkSubmit.bind(this)}>
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <div className="create_new_slotbox create_calender">
                    <div className="row  cr_mid">
                      <div
                        className="col-lg-2 col-md-2 cr_mid"
                        style={{ display: "contents" }}
                      >
                        {this.state.docData == "" ? (
                          <h2>Create Slots </h2>
                        ) : (
                          <h2>Edit Slots </h2>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 cr_mid">
                        <p className="cr_slot_calendar">
                          <DatePicker
                            minDate={new Date()}
                            name="start_date"
                            autoComplete="off"
                            className="dateInput"
                            placeholderText="Start Date"
                            selected={this.state.fields["start_date"]}
                            onChange={this.setStartDateOnChange}
                            dateFormat="d-MM-yyyy"
                            calendarIcon
                            showMonthDropdown
                            adjustDateOnChange
                          />
                          <br />
                          <span className="cRed">
                            {this.state.errors["start_date"]}
                          </span>
                        </p>
                        <p className="cr_slot_or">to</p>
                        <p href="#" className="cr_slot_calendar">
                          {" "}
                          <DatePicker
                            minDate={new Date()}
                            name="end_date"
                            autoComplete="off"
                            className="dateInput"
                            placeholderText="End Date"
                            selected={this.state.fields["end_date"]}
                            onChange={this.setEndDateOnChange}
                            dateFormat="d-MM-yyyy"
                            calendarIcon
                            showMonthDropdown
                            adjustDateOnChange
                          />
                          <br />
                          <span className="cRed">
                            {this.state.errors["end_date"]}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {this.state.update == "" ? (
                    <div className="col-lg-3 col-md-3 cr_mid">
                      <label>Select Health Care Provider</label>
                      <div className="form-group">
                        <select
                          name="doctor_id"
                          className="form-control"
                          value={this.state.fields["doctor_id"] || ""}
                          onChange={this.handleChange.bind(this, "doctor_id")}
                        >
                          <option value="">Select</option>
                          {doctorNames &&
                            doctorNames.map((doctorNames, i) => {
                              return (
                                <option value={doctorNames.id}>
                                  {doctorNames.doctor_name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="cRed">
                          {this.state.errors["doctor_id"]}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="create_sch_box">
                    <div className="slot_box">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 slot_mid">
                          <p>
                            <input
                              type="checkbox"
                              name="morning_slot"
                              id="morning_slot"
                              className="slot_check"
                              checked={this.state.fields["isMorningChecked"]}
                              onChange={this.toggleMorningChange}
                            />{" "}
                            Morning
                          </p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 slot_mid">
                          <select
                            value={this.state.fields["morning_from"]}
                            onChange={this.handleChange.bind(
                              this,
                              "morning_from"
                            )}
                            className="form-control"
                          >
                            {morning_slots.map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                          <span className="slot_or">
                            <img src="https://cdn2.iconfinder.com/data/icons/interface-line-arrows/24/icn-arrow-forward-512.png" />
                          </span>

                          <select
                            value={this.state.fields["morning_to"]}
                            onChange={this.handleChange.bind(
                              this,
                              "morning_to"
                            )}
                            className="form-control"
                          >
                            {morning_slots.slice(1).map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="slot_days">
                      <div>
                        {" "}
                        {this.state.MorningSelectStatus} all Week days{" "}
                        <input
                          type="checkbox"
                          id="selectMorningAllWeekDays"
                          name="selectMorningAllWeekDays"
                          onChange={this.selectAllWeekDays.bind(
                            this,
                            "morning"
                          )}
                        />{" "}
                      </div>
                      <br />
                      {this.state.day_categories.map((item) => (
                        <label>
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            value={item.displayName}
                            onChange={this.checkMorningButtom}
                            className="MorningWeekDays"
                          />
                          {item.id} &nbsp;&nbsp;
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="create_sch_box">
                    <div className="slot_box">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 slot_mid">
                          <p>
                            <input
                              type="checkbox"
                              name="afternoon_slot"
                              id="afternoon_slot"
                              className="slot_check"
                              checked={this.state.fields["isAfternoonChecked"]}
                              onChange={this.toggleAfternoonChange}
                            />{" "}
                            Afternoon
                          </p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 slot_mid">
                          <select
                            value={this.state.fields["noon_from"]}
                            onChange={this.handleChange.bind(this, "noon_from")}
                            className="form-control"
                          >
                            {noon_slots.map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                          <span className="slot_or">
                            <img src="https://cdn2.iconfinder.com/data/icons/interface-line-arrows/24/icn-arrow-forward-512.png" />
                          </span>

                          <select
                            value={this.state.fields["noon_to"]}
                            onChange={this.handleChange.bind(this, "noon_to")}
                            className="form-control"
                          >
                            {noon_slots.slice(1).map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="slot_days">
                      <div>
                        {" "}
                        {this.state.AfternoonSelectStatus} all Week days{" "}
                        <input
                          type="checkbox"
                          id="selectAfternoonAllWeekDays"
                          name="selectAfternoonAllWeekDays"
                          onChange={this.selectAllWeekDays.bind(
                            this,
                            "afternoon"
                          )}
                        />{" "}
                      </div>
                      <br />
                      {this.state.day_categories.map((item) => (
                        <label>
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            value={item.displayName}
                            onChange={this.checkAfternoonButtom}
                            className="AfternoonWeekDays"
                          />
                          {item.id} &nbsp;&nbsp;
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="create_sch_box">
                    <div className="slot_box">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 slot_mid">
                          <p>
                            <input
                              type="checkbox"
                              name="evening_slot"
                              id="evening_slot"
                              className="slot_check"
                              checked={this.state.fields["isEveningChecked"]}
                              onChange={this.toggleEveningChange}
                            />
                            Evening
                          </p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 slot_mid">
                          <select
                            value={this.state.fields["evening_from"]}
                            onChange={this.handleChange.bind(
                              this,
                              "evening_from"
                            )}
                            className="form-control"
                          >
                            {evening_slots.map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                          <span className="slot_or">
                            <img src="https://cdn2.iconfinder.com/data/icons/interface-line-arrows/24/icn-arrow-forward-512.png" />
                          </span>

                          <select
                            value={this.state.fields["evening_to"]}
                            onChange={this.handleChange.bind(
                              this,
                              "evening_to"
                            )}
                            className="form-control"
                          >
                            {evening_slots.slice(1).map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="slot_days">
                      <div>
                        {" "}
                        {this.state.EveningSelectStatus} all Week days{" "}
                        <input
                          type="checkbox"
                          id="selectEveningAllWeekDays"
                          name="selectEveningAllWeekDays"
                          onChange={this.selectAllWeekDays.bind(
                            this,
                            "evening"
                          )}
                        />{" "}
                      </div>
                      <br />
                      {this.state.day_categories.map((item) => (
                        <label>
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            value={item.displayName}
                            onChange={this.checkEveningButtom}
                            className="EveningWeekDays"
                          />
                          {item.id} &nbsp;&nbsp;
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="create_sch_box">
                    <div className="slot_box">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 slot_mid">
                          <p>
                            <input
                              type="checkbox"
                              name="night_slot"
                              id="night_slot"
                              className="slot_check"
                              checked={this.state.fields["isNightChecked"]}
                              onChange={this.toggleNightChange}
                            />{" "}
                            Night
                          </p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 slot_mid">
                          <select
                            value={this.state.fields["night_from"]}
                            onChange={this.handleChange.bind(
                              this,
                              "night_from"
                            )}
                            className="form-control"
                          >
                            {night_slots.map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                          <span className="slot_or">
                            <img src="https://cdn2.iconfinder.com/data/icons/interface-line-arrows/24/icn-arrow-forward-512.png" />
                          </span>

                          <select
                            value={this.state.fields["night_to"]}
                            onChange={this.handleChange.bind(this, "night_to")}
                            className="form-control"
                          >
                            {night_slots.slice(1).map((option) => {
                              return (
                                <option value={option} key={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="slot_days">
                      <div>
                        {" "}
                        {this.state.NightSelectStatus} all Week days{" "}
                        <input
                          type="checkbox"
                          id="selectNightAllWeekDays"
                          name="selectNightAllWeekDays"
                          onChange={this.selectAllWeekDays.bind(this, "night")}
                        />{" "}
                      </div>
                      <br />
                      {this.state.day_categories.map((item) => (
                        <label>
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            value={item.displayName}
                            onChange={this.checkNightButtom}
                            className="NightWeekDays"
                          />
                          {item.id} &nbsp;&nbsp;
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="standard_box">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="standard_slot">
                          <h3>Each Time Slot</h3>
                          <p>
                            <input
                              type="text"
                              className="standard_input"
                              value={this.state.fields["slot_hours"]}
                              onChange={this.handleChange.bind(
                                this,
                                "slot_hours"
                              )}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              minLength="1"
                              maxlength="2"
                            />{" "}
                            Hours
                            <br />
                            <span className="cRed">
                              {this.state.errors["slot_hours"]}
                            </span>
                          </p>
                          <p>
                            <input
                              type="text"
                              className="standard_input"
                              value={this.state.fields["slot_minutes"]}
                              onChange={this.handleChange.bind(
                                this,
                                "slot_minutes"
                              )}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              minLength="1"
                              maxlength="2"
                            />{" "}
                            Minutes
                            <br />
                            <span className="cRed">
                              {this.state.errors["slot_minutes"]}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="standard_slot">
                          <h3>Break Time between Consultation</h3>
                          <p>
                            <input
                              type="text"
                              className="standard_input"
                              value={this.state.fields["break_minutes"]}
                              onChange={this.handleChange.bind(
                                this,
                                "break_minutes"
                              )}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              minLength="1"
                              maxlength="2"
                            />{" "}
                            Minutes
                            <br />
                            <span className="cRed">
                              {this.state.errors["break_minutes"]}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="standard_btn">
                      {this.state.update == "" ? (
                        <button type="submit">Save</button>
                      ) : (
                        <button type="submit">Update</button>
                      )}
                     {" "}
                      <button type="button" onClick={this.viewSlots}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </main>
    );
  }
}
