import { LOCALES } from '../constants';

export default {
  [LOCALES.FRENCH] :  {
    'hello' :  'Bonjour',
    'Login' :  "S'identifier",
    'Language' :  "Langue",
    'Consult a Doctor' :  'Consultez un docteur',
    'Video consultation' :  "Consultation vidéo",
    'Video consultation over app' :  "Consultaion vidéo via l'application",
    'In person visit' :  'Visite en personne',
    'Meet The Doctor In Clinic' :  'Rencontrez le docteur en clinique',
    'Order Medicine' :  "Commander des médicaments",
    'Book For Diagnostic' :  "Réservez pour le diagnostic",
    'Care at Home' :  "Soins à domicile",
    'Consult Top Specialists now' : "Consultez les meilleurs spécialistes maintenant",
    'Cough and cold' : "Toux et rhume",
    'Consult now' : "Consulter maintenant",
    'Best Doctors from your nearest clinic' : "Best Doctors de la clinique la plus proche",
    "Book Full Body Checkups":"Réserver des bilans corporels complets",
    "Book Diagnostic Tests":"Réserver des tests de diagnostic",
    "Home Care":"Soins à domicile",
    "Trending Products":"Produits tendance",
    "Doctors":"Médecins",
    'Jaya Clinic' : "Clinique Jaya",
    'Madhapur' : "Madhapur",
    'Directions' : "instructions",
    'ENT' : "HNO",
    'Cardiologist' : "Cardiologue",
    'MRP' : "MRP",
    'Add' : "Ajouter",
    'off' : "de",
    'Download the MedleyMed app' : "Téléchargez l'application MedleyMed",
    'Book appointments and health checkups' : "Prendre des rendez-vous et des bilans de santé",
    'Order medicines and consult doctors online' : "Commander des médicaments et consulter des médecins en ligne",
    'Get the link to download the app' : "Obtenez le lien pour télécharger l'application",
    'Get App link' : "Obtenir le lien vers l'application",
    'For patients' : "Pour les patients",
    'Search for doctors' : "Rechercher des médecins",
    'Search for Clinics' : "Rechercher des cliniques",
    'Search for hospitals' : "Recherche d'hôpitaux",
    'Our Services' : "Nos services",
    'Order Medicines' : "Commander des médicaments",
    'Book Lab Tests' : "Réserver des tests de laboratoire",
    'Consult a Doctor' : "Consultez un docteur",
    'Our Policies' : "Nos politiques",
    'Privacy Policy' : "Politique de confidentialité",
    'Terms and Conditions' : "Termes et conditions",
    'Return Policy' : "Politique de retour",
    'Social' : "Social",
    'Need Help' : "Besoin d'aide",
    'Our App' : "Notre appli",
    'App Store' : "Magasin d'applications",
    'Play Store' : "Play Store",
    "Copyright":"droits d'auteur",
    "MedleyMed":"MedleyMed",
    "All rights reserved":"Tous les droits sont réservés",
    "This number will be used for doctor consultation related information, new offers. We will send a code for verification to this number":
    "Ce numéro sera utilisé pour les informations liées à la consultation du médecin, les nouvelles offres. Nous enverrons un code de vérification à ce numéro",
    "Login":"S'identifier",
    "Signup":"S'inscrire",
    "Next":"Prochain",
    "Use Email ID":"Utiliser l'ID de messagerie",
    "Mobile Number":"Numéro de portable",
    "We will send you an SMS with a confirmation code to this number":"Nous vous enverrons un SMS avec un code de confirmation à ce numéro",
    "Enter Mobile number":"Entrez le numéro de mobile",
    "Name":"Nom",
    "Email":"Email",
    "Enter email id":"Entrez l'identifiant de l'e-mail",
    "Mobile number":"Numéro de portable",
    "Please keep your health profile updated for better consultation experience":"Veuillez garder votre profil de santé à jour pour une meilleure expérience de consultation",
    "Profile Completed":"Profil terminé",
    "Who is the Patient":"Qui est le patient",
    "Specify any long-term illness or chronic conditions present":"Précisez toute maladie à long terme ou état chronique présent",
    "if any":"Si seulement",
    "Optional":"Optionnelle",
    "Related medication":"Médicaments associés",
    "Drug allergies present":"Allergies médicamenteuses présentes",
    "Back":"Arrière",
    "Do it later":"Fais-le plus tard",
    "Save":"sauver",
    "Next":"Prochain",
    "Diabetes":"Diabète",
    "Blood Pressure":"Pression artérielle",
    "Headache":"Mal de crâne",
    "Type here eg. Diabetes":"Type ici, par exemple. Diabétestes",
    "Add family Member":"Ajouter un membre de la famille",
    "Help us with your information":"Aidez-nous avec vos informations",
    "Enter family member name":"Entrez le nom du membre de la famille",
    "Enter mobile number":"Entrez le numéro de mobile",
    "Date of birth":"Date de naissance",
    "Gender":"Le sexe",
    "Male":"Masculin",
    "Female":"Femme",
    "Other":"Autre",
    "Save & Next":"Enregistrer et suivant",
    "Who is the Patient?":"Qui est le patient?",
    "Logout":"Se déconnecter",
    "Login":"S'identifier",
    "Hello!":"salut!",
    "My Appointment":"Mon rendez-vous",
    "My Profile":"Mon profil",
    "Privacy Policy":"Politique de confidentialité",
    "Terms & Conditions":"termes et conditions",
    "Upcoming":"A venir",
    "Tele medicine":"Télé médecine",
    "In Person Visit":"Visite en personne",
    "Your appointment scheduled on":"Votre rendez-vous prévu le",
    "Join Now":"Adhérer maintenant",
    "Past":"Passé",
    "appointment with":"rendez-vous avec",
    "Directions":"instructions",
    "Doctor is Available now":"Le docteur est disponible maintenant",
    "View Prescription":"Voir la prescription",
    "Please wait for the call from clinic front desk":"Veuillez attendre l'appel de la réception de la clinique",
    "You will receive call from a clinics front desk and confirm your time slot":"Vous recevrez un appel de la réception d'une clinique et confirmerez votre créneau horaire",
   
    "Enter the details for":"Entrez les détails pour",
    "Patient Intake Process":"Processus d'admission des patients",
    "Purpose of consultation":"Objet de la consultation",
    "Specify Symptoms":"Spécifier les symptômes",
    "Provide Vital Informations":"Fournir des informations vitales",
    "History of Past Illness":"Histoire des maladies passées",
    "Enter your vital information":"Entrez vos informations vitales",
    "Height":"la taille",
    "Weight":"Poids",
    "BMI":"IMC",
    "Breath Per Minute":"Souffle par minute",

    "Normal":"Ordinaire",
    "Medium":"Moyenne",
    "High":"Haute",
    "Very high":"Très haut",

    "Obese":"Obèse",
    "Over weight":"En surpoids",
    "Underweight":"Poids insuffisant",

    "cm":"cm",
    "kg":"kg",

    "Temperature":"Température",
    "Pulse":"Impulsion",
    "Blood Sugar":"Glycémie",
    "Respiratory Rate":"Fréquence respiratoire",
    "Systolic":"Systolique",

    "Specify Symptoms":"Spécifier les symptômes",
    "Symptoms related to":"Symptômes liés à",
    "or Choose common symptoms":"ou Choisissez des symptômes courants",
    "Search Symptom":"Rechercher un symptôme",
    "Select Symptom from body":"Sélectionnez le symptôme du corps",
    "Add New Symptoms":"Ajouter de nouveaux symptômes",

    "Rotate Model":"Faire pivoter le modèle",
    "Click on the body model":"Cliquez sur le modèle du corps",
    "Abdominal pain":"Douleur abdominale",
    "select body part":"sélectionner une partie du corps",
    "Search your symptoms by search or":"Recherchez vos symptômes par recherche ou",

    "History of Past Illness":"Histoire des maladies passées",
    "Past Medical History":"Antécédents médicaux",
    "Social History":"Histoire sociale",
    "Chronic conditions":"Conditions chroniques",
    "Chronic medication":"Médicaments chroniques",
    "Add Current Medication":"Ajouter le médicament actuel",
    "Drug Allergies":"Allergies médicamenteuses",
    "Add Medical Document":"Ajouter un document médical",
    "Upload Document":"Télécharger le document",

    "Enter code":"Entrez le code",
    "Continue":"Continuer",
    "Please enter your confirmation code":"Veuillez saisir votre code de confirmation",

    "Type here eg. Fever" : "Tapez ici par exemple. Fièvre",

    "mg/dL":"mg/dL",
    "bpm":"bpm",
    "F":"F",
    "Kg/m2":"Kg/m2",
    "Feet.Inch":"Feet.Inch",


    "Patient Details":"Détails du patient",
    "Patient Intake Process":"Processus d'admission des patients",
    "Filters":"Filtres",
    "Clear all":"Tout effacer",
    "Languages":"Langues",
    "Sorting":"Tri",
    "Choose Specialist":"Choisissez un spécialiste",
    "Gender":"Le sexe",
    "Experience":"Expérience",
    "Mode":"Mode",
    "Ratings":"Évaluations",
    "View Profile":"Voir le profil",
    "Book an Appointment":"Prenez rendez-vous",
    "reviews":"Commentaires",
    "Registration No":"N ° d'enregistrement",
    "Fees":"Honoraires",
    "Education":"Éducation",
    "I can speak":"je sais parler",
    "Practice":"Entraine toi",
    "Feedback":"Retour d'information",
    "My Area of expertise":"Mon domaine d'expertise",
    "When do you want to consult a doctor":"Quand voulez-vous consulter un médecin",
    "Please wait for doctor confirmation":"Veuillez attendre la confirmation du médecin",
    "Doctor will confirm shortly":"Le médecin confirmera sous peu",
    "Doctor confirmed":"Docteur confirmé",
    "Please proceed to pay for the consultation":"Veuillez procéder au paiement de la consultation",
    "Pay Now":"Payez maintenant",
    "Schedule your appointment":"Planifiez votre rendez-vous",
    "Or":"Ou",
    "Consult later":"Consulter plus tard",
    "Afternoon":"Après midi",
    "Evening":"Soirée",
    "Morning":"Matin",
    "Night":"Nuit",
    "am":"un m",
    "pm":"pm",

    "Select Doctor":"Sélectionnez un médecin",
    "Available Doctors for Telemedicine":"Médecins disponibles pour la télémédecine",
    "Dr":"Dr",
    "appointment with":"rendez-vous avec",
    "Consultation Time":"Temps de consultation",
    "Purpose of visit":"But de la visite",
    "Symptoms":"Symptômes",
    "Vital Informations":"Vital Informations",
    "Social History":"Histoire sociale",
    "Patient Drug Allergies":"Allergies médicamenteuses des patients",
    "Uploaded Document":"Document téléchargé",
    "Fee Details":"Détails des frais",
    "Doctor consultation Fees":"Frais de consultation médicale",
    "Have a Coupon Code":"Avoir un code promo",
    "Enter Your Code":"Entrez votre code",
    "Apply":"Appliquer",
    "Net Payable Amount":"Montant net à payer",
    "Accept Terms and conditions":"Accepter les termes et conditions",
    "Book Now":"Reserve maintenant",
    "Your appointment will be start at":"Votre rendez-vous débutera à",
    "All" : "Tout" ,
    "Choose Specialist":"Choisissez un spécialiste",
    "Find the doctor near you":"Trouvez le médecin près de chez vous",
    "Diabetes":"Diabète",
    "eg. I had jaundice 2 months back":"par exemple. J'ai eu la jaunisse il y a 2 mois"

  },
};
// FRENCH