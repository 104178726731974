import React, { Component, useState } from "react";
import { ToastContainer } from "react-toastify";
import $ from "jquery";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Form, Button, Dropdown, Grid, Header } from "semantic-ui-react";
import ReactExport from "react-data-export";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require("moment");

export default class Searchappointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DoctorsListData: "",
      selectedStatus: "",
      selectedRetailerId: "",
      selectedDoctorId: "",
      selectedStartDate: "",
      selectedEndDate: "",
      searchString: "",
      orderDetailsView: "collapse-hide",
      orderListView: "container",
      loadshow: "load_sec collapse-hide",
      pageNumber: 1,
      ordersView: [],
      currentOrders: [],
      xlsArray: [],
      datatableProps: {},
      tableArray: [],
    };

    this.getAllOrders = this.getAllOrders.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.orderStatusCount();
    this.fetchDoctorInfo();
    this.getAllOrders();
    this.getRejectionResonslist();
  };

  //  for orderStatusCount
  orderStatusCount = () => {
    let doctorId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = this.state.selectedStatus;
    let searched = this.state.searchString;

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getAdminPatientSearchAppoint",
      {
        appointmentid: searched,
        appointmentStatus: orderStatus,
        start_date: startDate,
        end_date: endDate,
        doctorid: doctorId,
        page_number: 1,
      }
    )
      .then((response) => {
        this.state.all = response.data.data[0].all;
        this.state.pending = response.data.data[0].pending;
        this.state.reviewed = response.data.data[0].reviewed;
        this.state.processing = response.data.data[0].processing;
        this.state.shipping = response.data.data[0].shipping;
        this.state.delivered = response.data.data[0].delivered;
        this.state.cancelled = response.data.data[0].cancelled;

        this.forceUpdate();
      })
      .catch((error) => {});
  };

  // Get the retailers list
  fetchDoctorInfo() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Doctor/getAllDoctors")
      .then((response) => {
        let listData = response.data.data;
        let DoctorsListData = listData.map((item, index) => ({
          key: item.id,
          text: item.doctor_name,
          value: item.id,
        }));

        this.state.DoctorsListData = DoctorsListData;

        this.forceUpdate();
      })
      .catch((error) => {});
  }
  // On retalier selection
  onDoctorListChange = (event, data) => {
    let selectedDoctorValue = data.value;

    if (selectedDoctorValue != "") {
      const keys = data.options.find(
        (data) => data.value === selectedDoctorValue
      );
      const selectedretailer = {
        id: keys.key,
        doctorName: keys.text,
        doctorName: keys.value,
      };
      this.state.selectedDoctorName = keys.text;
      this.state.selectedDoctorId = selectedDoctorValue;
      this.forceUpdate();
      this.getAllOrders();
    }
  };

  // get all the orders
  getAllOrders() {
    let doctorId = this.state.selectedDoctorId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = this.state.selectedStatus;
    let searched = this.state.searchString;
    let pageNumber = this.state.pageNumber;
    let PaidAmount = "";
    let finalordersarray = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/getAdminPatientSearchAppoint",
      {
        appointmentid: searched,
        appointmentStatus: orderStatus,
        start_date: startDate,
        end_date: endDate,
        doctorid: doctorId,
        page_number: pageNumber,
      }
    )
      .then((response) => {
        this.state.currentOrders = response.data.data;
        let PaidAmount = "0.00";
        if (Object.keys(response.data.data).length >= 20) {
          this.state.loadshow = "load_sec collapse-show";
          this.forceUpdate();
        } else {
          this.state.loadshow = "load_sec collapse-hide";
          this.forceUpdate();
        }
        const ordersView = response.data.data.map((LoadedData, num) => {
          
          if (LoadedData.TotPaidAmount != null) {
            PaidAmount = parseFloat(LoadedData.TotPaidAmount);
            PaidAmount = PaidAmount.toFixed(2).replace(
              /\d(?=(\d{3})+\.)/g,
              "$&,"
            );
          }
          let status = "";

          if (LoadedData.status == 1) {
            status = "Upcoming";
          } else if (LoadedData.status == 2) {
            status = "Ongoing";
          } else if (LoadedData.status == 3) {
            status = "Completed";
          } else if (LoadedData.status == 4) {
            status = "Cancelled";
          } else {
            status = LoadedData.status;
          }

          return {
            SINO: num + 1,
            Doctor: (
              <React.Fragment>
                {LoadedData.doctor_tbl.doctor_name}
                <h6 class="td_down">
                  {LoadedData.doctor_tbl.mobile_no
                    ? LoadedData.doctor_tbl.mobile_no
                    : ""}
                </h6>
                <h6 class="td_down">
                  {LoadedData.doctor_tbl.email
                    ? LoadedData.doctor_tbl.email
                    : ""}
                </h6>
              </React.Fragment>
            ),
            Appointmentdate: moment(LoadedData.appointment_datetime).format(
              "YYYY-MMM-DD hh:mm a"
            ),
            Patient: (
              <React.Fragment>
                {LoadedData.patient_tbl ? LoadedData.patient_tbl.name :""}
                <h6 class="td_down">
                  {LoadedData.patient_tbl 
                    ? LoadedData.patient_tbl.phone_number
                    : "--"}
                </h6>
              </React.Fragment>
            ),

            AppointmentID: LoadedData.appointment_confirm_id,

            Amount: PaidAmount,
            Status: status,
          };
        });
        this.state.ordersView = ordersView;
        if (
          (this.state.searchString == "" || this.state.searchString != "") &&
          this.state.pageNumber == 1
        ) {
          this.state.tableArray = [];
          ordersView.forEach((element) => {
            this.state.tableArray.push(element);
          });
        } else {
          ordersView.forEach((element) => {
            this.state.tableArray.push(element);
          });
        }

        this.setDataTable(this.state.tableArray);

        var sampleData = JSON.parse(JSON.stringify(this.state.tableArray));
        this.state.xlsArray = sampleData.map((item) => {
          let docterName = item.Doctor.props.children[0];
          let doctorEmail = item.Doctor.props.children[1].props.children;
          let doctorPhono = item.Doctor.props.children[2].props.children;
          item.Doctor = docterName + ", " + doctorEmail + ", " + doctorPhono;
          let userName = item.Patient.props.children[0];
          let userPhone = item.Patient.props.children[1].props.children;
          item.Patient = userName + ", " + userPhone;
          return item;
        });

        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // Setting data table data
  setDataTable = (tableData) => {
    const datatable = {
      columns: [
        {
          label: "Health Care Provider",
          field: "Doctor",
          sort: "asc",
          width: 150,
        },
        {
          label: "Appointmentdate/time",
          field: "Appointmentdate",
          sort: "asc",
          width: 10,
        },
        {
          label: "Patient",
          field: "Patient",
          sort: "asc",
          width: 10,
        },
        {
          label: "AppointmentID",
          field: "AppointmentID",
          sort: "asc",
          width: 10,
        },
        {
          label: "Amount",
          field: "Amount",
          sort: "asc",
          width: 10,
        },
        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 10,
        },
      ],
      rows: tableData,
    };
    this.state.datatableProps = {
      columns: [
        {
          label: "S.No",
          field: "SINO",
        },
        ...datatable.columns,
      ],
      rows: [
        ...datatable.rows.map((item, index) => {
          item.SINO = index + 1;
          return item;
        }),
      ],
    };
  };
  // Handle Selected status
  handleSelectedStatus = (status) => {
    this.state.selectedStatus = status;
    $(".stat").removeClass("active");
    $(".status-" + status).addClass("active");
    this.forceUpdate();
    this.getAllOrders();
  };
  // Handle searched string
  handleSearchedString = (event) => {
    this.state.pageNumber = 1;
    let searched = event.target.value;
    this.state.searchString = searched;
    this.getAllOrders();
    this.forceUpdate();
  };

  /* change order status */

  ChangeOrderStatus = (orderId, status) => {
    Httpconfig.httptokenput(
      Constant.siteurl + "api/OM/orderProcess/retailerOrderStatus/" + orderId,
      { order_status: status }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.success(response.data.message);
          this.getAllOrders();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // Reject Order list

  getRejectionResonslist = () => {
    Httpconfig.httptokenget(Constant.siteurl + "api/OM/cancellationReason/")
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            const retailerRejectList = response.data.data.map(
              (finalLoadedData, num) => {
                return (
                  <p>
                    <input
                      type="checkbox"
                      class="rej_check"
                      name="cancelReasons"
                      id={finalLoadedData.id}
                      onChange={this.cancelReason}
                    />{" "}
                    {finalLoadedData.reason}
                  </p>
                );
              }
            );
            this.state.retailerRejectList = retailerRejectList;
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // update the cancellation reaon

  cancelReason = (event) => {
    let id = event.currentTarget.id;
    if (document.getElementById(id).checked == true) {
      this.state.cancelleReason = id;
    } else {
      this.state.cancelleReason = "";
    }
    this.forceUpdate();
  };

  // Reject order
  rejectOrder = () => {
    let cancelReasonsCheckedCount = $(
      'input[name="cancelReasons"]:checked'
    ).length;
    let otherReason = $("#otherReason").val();
    let orderId = this.state.selectedCancelOrderId;
    if (cancelReasonsCheckedCount == 0 && otherReason == "") {
      toast.error("Select Reason for Cancellation");
      return;
    } else {
      let cancelledReason = this.state.cancelleReason;
      Httpconfig.httptokenpost(
        Constant.siteurl + "api/OM/cancellationReason/orderDetails/" + orderId,
        { order_status: "6", cancelled_reason: cancelledReason }
      )
        .then((response) => {
          if ((response.data.status = 200)) {
            toast.success(response.data.message);
            $("#reject_reason").hide();
            $('input[name="cancelReasons"]:checked').prop("checked", "");
            $("#otherReason").val("");
            this.state.cancelleReason = "";
            this.state.selectedCancelOrderId = "";
            this.forceUpdate();
            this.getAllOrders();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  // Order details view

  orderDetailsView = (orderId) => {
    this.state.orderDetailsView = "collapse-show";
    this.state.orderListView = "container collapse-hide";
    this.forceUpdate();
    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDetail/" + orderId
    )
      .then((response) => {
        if ((response.data.status = 200)) {
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  for showListView
  showListView = () => {
    this.state.orderDetailsView = "collapse-hide";
    this.state.orderListView = "container collapse-show";
    this.forceUpdate();
  };
  // for startDate
  startDate = (event) => {
    let startDate = event.target.value;
    this.state.selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (
      this.state.selectedEndDate != "" &&
      this.state.selectedStartDate != ""
    ) {
      this.getAllOrders();
    }
  };

  // Start date selection
  endDate = (event) => {
    let endDate = event.target.value;
    this.state.selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    this.forceUpdate();
    if (
      this.state.selectedStartDate != "" &&
      this.state.selectedEndDate != ""
    ) {
      this.getAllOrders();
    }
  };

  //  for handleScroll
  handleScroll(e) {
    let page = this.state.pageNumber;

    page = page + 1;
    this.state.pageNumber = page;
    this.getAllOrdersbyPage(page);
    this.getAllOrders();
  }

  //  for handleSelectedOrderId
  handleSelectedOrderId = (orderId) => {
    this.state.selectedCancelOrderId = orderId;
    this.forceUpdate();
  };
  //  for handleClear
  handleClear = (id) => {
    if (id == "input-search") {
      this.state.pageNumber = 1;
      this.state.searchString = "";
      $("#" + id).val("");
    }
    this.forceUpdate();
    this.getAllOrders();
  };

  // get all the orders
  getAllOrdersbyPage(pageNumber) {
    let retailerId = this.state.selectedRetailerId;
    let startDate = this.state.selectedStartDate;
    let endDate = this.state.selectedEndDate;
    let orderStatus = this.state.selectedStatus;
    let searched = this.state.searchString;
    pageNumber = pageNumber + 1;
    let PaidAmount = "";
    let finalordersarray = [];
    let orderViews = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/orderProcess/getAdminOrderDashboardDetails",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus,
        order_search_id: searched,
        page_number: pageNumber,
      }
    )
      .then((response) => {
        if (Object.keys(response.data.data).length > 0 && pageNumber > 1) {
          orderViews = this.state.currentOrders.concat(response.data.data);
          this.state.currentOrders = orderViews;
          this.forceUpdate();
        } else {
          if (Object.keys(response.data.data).length > 0) {
            orderViews = response.data.data;
            this.state.currentOrders = orderViews;
            this.forceUpdate();
          }
        }
        if (Object.keys(orderViews).length > 0) {
          const ordersView = orderViews.map((LoadedData, num) => {
            PaidAmount = parseInt(LoadedData.payable_amount);
            if (LoadedData.payable_amount) {
              PaidAmount = PaidAmount.toFixed(2).replace(
                /\d(?=(\d{3})+\.)/g,
                "$&,"
              );
            }
            finalordersarray.push({
              "Customer Name":
                LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                LoadedData.patient_tbl.name.slice(1) +
                "," +
                LoadedData.patient_tbl.phone_number,
              Retailer:
                LoadedData.retailer_registration_tbl.storename +
                "," +
                LoadedData.retailer_registration_tbl.address +
                "," +
                LoadedData.retailer_registration_tbl.email +
                "," +
                LoadedData.retailer_registration_tbl.mobile_number,
              "Order Id": LoadedData.id,
              "Order Date": LoadedData.order_date,
              Amount: PaidAmount,
              Items: Object.keys(LoadedData.order_processing_tbls).length,
              "Order Status": LoadedData.order_status_tbl.status,
            });
            return (
              <tr>
                <td>{num + 1}</td>
                <td>
                  {LoadedData.patient_tbl.name
                    ? LoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                      LoadedData.patient_tbl.name.slice(1)
                    : "--"}{" "}
                  <h6 class="td_down">
                    {LoadedData.patient_tbl.phone_number
                      ? LoadedData.patient_tbl.phone_number
                      : "--"}
                  </h6>
                </td>
                <td>
                  <div class="dropdown">
                    <p class="dropbtn">
                      {LoadedData.retailer_registration_tbl.storename
                        ? LoadedData.retailer_registration_tbl.storename
                        : "--"}{" "}
                    </p>
                    <h6 class="caret">
                      <img src="https://static.thenounproject.com/png/427197-200.png" />
                    </h6>
                    <div class="dropdown-content">
                      <h3>
                        {LoadedData.retailer_registration_tbl.storename
                          ? LoadedData.retailer_registration_tbl.storename
                          : "--"}
                      </h3>
                      <p>
                        {}
                        {LoadedData.retailer_registration_tbl.address
                          ? LoadedData.retailer_registration_tbl.address
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.email
                          ? LoadedData.retailer_registration_tbl.email
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {LoadedData.retailer_registration_tbl.mobile_number
                          ? LoadedData.retailer_registration_tbl.mobile_number
                          : ""}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p onClick={this.orderDetailsView.bind(this, LoadedData.id)}>
                    {LoadedData.id}
                  </p>
                </td>
                <td>{LoadedData.order_date}</td>
                <td class="presc_field">
                  {LoadedData.cart_prescriptions_tbl
                    ? Object.keys(LoadedData.cart_prescriptions_tbl).length > 0
                      ? LoadedData.cart_prescriptions_tbl.medical_document.map(
                          (presImages, num) => {
                            return (
                              <a
                                href={
                                  presImages ? Constant.imgurl + presImages : ""
                                }
                                target="_blank"
                              >
                                <img
                                  class="presc_img"
                                  src="https://cdn.onlinewebfonts.com/svg/img_491633.png"
                                />
                              </a>
                            );
                          }
                        )
                      : "--"
                    : "--"}
                </td>
                <td>{Object.keys(LoadedData.order_processing_tbls).length}</td>
                <td>
                  {LoadedData.retailer_registration_tbl.currency}{" "}
                  {LoadedData.payable_amount ? PaidAmount : "--"}
                </td>
                <td>
                  {LoadedData.order_status < 5 ? (
                    <div class="dropdown">
                      <p class="dropbtn">
                        {LoadedData.order_status_tbl.status}{" "}
                        <h6 class="td_down">2 hrs</h6>
                      </p>
                      <h6 class="caret">
                        <img src="https://static.thenounproject.com/png/427197-200.png" />
                      </h6>

                      <div class="dropdown-content">
                        {LoadedData.order_status == 1 ? (
                          <React.Fragment>
                            <a
                              href="#"
                              onClick={this.ChangeOrderStatus.bind(
                                this,
                                LoadedData.id,
                                2
                              )}
                            >
                              Prescription Review
                            </a>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {LoadedData.order_status == 2 ? (
                          <React.Fragment>
                            <a
                              href="#"
                              onClick={this.ChangeOrderStatus.bind(
                                this,
                                LoadedData.id,
                                3
                              )}
                            >
                              Processing
                            </a>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {LoadedData.order_status == 3 ? (
                          <React.Fragment>
                            <a
                              href="#"
                              onClick={this.ChangeOrderStatus.bind(
                                this,
                                LoadedData.id,
                                4
                              )}
                            >
                              Shipped
                            </a>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {LoadedData.order_status == 4 ? (
                          <React.Fragment>
                            <a
                              href="#"
                              onClick={this.ChangeOrderStatus.bind(
                                this,
                                LoadedData.id,
                                5
                              )}
                            >
                              Delivered
                            </a>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <p class="">
                      {LoadedData.order_status_tbl.status}{" "}
                      <h6 class="td_down"></h6>
                    </p>
                  )}
                </td>
              </tr>
            );
          });
          this.state.ordersView = ordersView;
          this.state.finalordersarray = finalordersarray;
          this.forceUpdate();
        } else {
          this.forceUpdate();
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <section id="main_dashboard">
        <div class={this.state.orderListView}>
          <div class="row">
            <div class="col-md-12 no_padding">
              <div id="sorting_sec">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 no_padding">
                      <div class="sorting_content">
                        <ol class="breadcrumb">
                          <li class="active">
                            <a href="/admin"> Dashboard</a> &gt; Reports &gt;
                            Search Appointments
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="search_order">
                <div class="">
                  <div class="row">
                    <div class="col-md-10">
                      <form>
                        <div class="form-group">
                          <label>Search Appointment</label>
                          <div class="input-group">
                            <input
                              type="text"
                              id="input-search"
                              class="form-control"
                              placeholder="Search for Appointment Id or Patient Phone number"
                              onKeyUp={this.handleSearchedString}
                            />
                            <img
                              style={{ cursor: "pointer" }}
                              class="remove_icon"
                              src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                              onClick={this.handleClear.bind(
                                this,
                                "input-search"
                              )}
                            />
                            <div class="input-group-btn"></div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-2 download_btn">
                      <ExcelFile
                        element={
                          <a href="#">
                            {" "}
                            {/* <img
                              src="https://webstockreview.net/images/png-images-download-9.png"
                            /> */}
                            <h2 >
                              Download XLS
                            </h2>
                          </a>
                        }
                      >
                        <ExcelSheet
                          data={this.state.xlsArray}
                          name="Order Request"
                        >
                          <ExcelColumn label="SINO" value="SINO" />
                          <ExcelColumn label="Health Care Provider" value="Doctor" />
                          <ExcelColumn
                            label="Appointmentdate"
                            value="Appointmentdate"
                          />
                          <ExcelColumn label="Patient" value="Patient" />
                          <ExcelColumn
                            label="AppointmentID"
                            value="AppointmentID"
                          />
                          <ExcelColumn label="Amount" value="Amount" />
                          <ExcelColumn label="Status" value="Status" />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                </div>
              </div>

              <div id="CMS_tab">
                <div class="CMS_content">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div id="reg_form">
                          <div class="table-responsive dataTables_wrapper load_sec">
                            {this.state.datatableProps != "" ? (
                              <MDBDataTable
                                striped
                                responsive
                                bordered
                                paging={false}
                                searching={false}
                                data={this.state.datatableProps}
                              />
                            ) : (
                              <table class="table table-bordered table-sm table-striped load_sec">
                                <thead>
                                  <tr>
                                    <th>Sl.NO</th>
                                    <th>Doctor</th>
                                    <th>Appointment Date / Time</th>
                                    <th>Patient</th>
                                    <th>Appointment ID</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <React.Fragment>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No Appointments found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </React.Fragment>
                                </tbody>
                              </table>
                            )}

                            {this.state.ordersView ? (
                              <div
                                class={this.state.loadshow}
                                onClick={this.handleScroll.bind(this)}
                              >
                                <a id="loadMore">
                                  Load More....{" "}
                                  <img src="https://static.thenounproject.com/png/427197-200.png" />
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button onClick="topFunction()" id="myBtn" title="Go to top">
                  <img src="https://i.pinimg.com/originals/c4/f0/4b/c4f04b83f93836a05b4f188180708e0e.png" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="main_dashboard" class={this.state.orderDetailsView}>
          <div class="container">
            <div class="row">
              <div class="col-md-12 no_padding">
                <section id="retailer_invoice">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="ret_inv_section">
                          <span
                            class="ret_inv_back"
                            onClick={this.showListView}
                          >
                            {" "}
                            <a href="#">
                              <img src="https://i.pinimg.com/736x/9e/b6/0e/9eb60ee1602cb5370382c2582ee2d0d1.jpg" />
                              Back to Order
                            </a>{" "}
                          </span>
                          <div class="ret_inv_head">
                            <h2> Order Details</h2>
                          </div>
                          <div class="ret_pharm_det">
                            <div class="row">
                              <div class="col-md-8">
                                <div class="pharm_add">
                                  <h2>New Pharmacy</h2>
                                  <h5>
                                    D NO. 131, Axis Bank, Madhapur, Hyderabad
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="pharm_order">
                                  <p>
                                    Order No: <span>524163</span>
                                  </p>
                                  <p>
                                    Order Date: <span>14 Dec 2020</span>
                                  </p>
                                  <p>
                                    Invoice Date:{" "}
                                    <span>14 Dec 2020 02:30 PM</span>
                                  </p>
                                  <p>
                                    Invoice No: <span>5421525</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ret_invoice_table" id="reg_form">
                            <div class="table-responsive dataTables_wrapper">
                              <table class="table table-bordered table-sm table-striped">
                                <thead>
                                  <tr>
                                    <th>Sl.No</th>
                                    <th>Product / Manufacturer</th>
                                    <th>Batch / Expiry</th>
                                    <th>MRP</th>
                                    <th>Qty</th>
                                    <th>Taxable Amnt</th>
                                    <th>Vat %</th>
                                    <th>Vat Amount</th>
                                    <th>Discount</th>
                                    <th>Total Amnt</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      Dolo 650 <h6>Micro Labs ltd</h6>
                                    </td>
                                    <td class="batch_inp">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value="88789- 23/5"
                                      />
                                    </td>
                                    <td>₹ 400</td>
                                    <td>2</td>
                                    <td>₹ 400</td>
                                    <td>5</td>
                                    <td>₹ 55.32</td>
                                    <td>10</td>
                                    <td>₹ 75.30</td>
                                  </tr>

                                  <tr>
                                    <td>2</td>
                                    <td>
                                      Dolo 650 <h6>Micro Labs ltd</h6>
                                    </td>
                                    <td class="batch_inp">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value="88789- 23/5"
                                      />
                                    </td>
                                    <td>₹ 400</td>
                                    <td>2</td>
                                    <td>₹ 400</td>
                                    <td>5</td>
                                    <td>₹ 55.32</td>
                                    <td>10</td>
                                    <td>₹ 75.30</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="ret_inv_footer">
                              <div class="row">
                                <div class="col-md-offset-7 col-md-5 amount__right">
                                  <div class="total_tax">
                                    <h5>
                                      Total Taxable Amount :{" "}
                                      <span>₹ 71.64</span>
                                    </h5>
                                    <h5>
                                      Total Taxes : <span>₹ 71.64</span>
                                    </h5>
                                  </div>
                                  <div class="total_amnt">
                                    <p>
                                      Sub Total : <span>₹ 71.64</span>
                                    </p>
                                    <p>
                                      Discount : <span> - ₹ 71.64</span>
                                    </p>
                                    <h3>
                                      Total Paid : <span>₹ 223.00</span>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="ret_inv_pay">
                              <div class="row">
                                <div class="col-md-8">
                                  <div class="payment_mode">
                                    <p>
                                      Payment mode: <span>Card</span>
                                    </p>
                                    <p>
                                      Address : <span>Madhapur, Hyderabad</span>
                                    </p>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="ret_inv_btns">
                                    <p
                                      class="process_btn"
                                      data-toggle="modal"
                                      data-target="#reject_reason"
                                    >
                                      Cancel Order
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="reject_reason" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
                <h2>Reason For Cancellation</h2>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="reject_content">
                        <div class="rej_main">
                          {this.state.retailerRejectList}

                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Type Reason"
                            id="otherReason"
                          ></textarea>
                        </div>
                      </div>
                      <div class="reject_bottom">
                        <div class="row">
                          <div class="col-md-4">
                            <button
                              type="button"
                              class="cancel_btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                          <div class="col-md-8">
                            <button
                              type="button"
                              class="save_btn"
                              onClick={this.rejectOrder}
                            >
                              Initiate Refund
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </section>
    );
  }
}
