import React, { Component } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import PatientHeader from "../patient/Patientheader";
import PatientSideMenu from "../patient/Patientsidemenu";
import ReactExport from "react-data-export";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ViewClinics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      fields: {},
      errors: {},
      allReminderForHtmlRendering: [],
      datatable: {
        columns: [
          {
            label: "Medicine Name",
            field: "medicine_name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Patient Id",
            field: "patient_id",
            sort: "asc",
            width: 150,
          },
          // {
          //   label: "Doctor Id",
          //   field: "doctor_id",
          //   sort: "asc",
          //   width: 150,
          // },
          {
            label: "Number Of Times",
            field: "numberoftimes",
            sort: "asc",
            width: 150,
          },
          {
            label: "Start Date",
            field: "start_date",
            sort: "asc",
            width: 150,
          },
          {
            label: "End Date",
            field: "end_date",
            sort: "asc",
            width: 150,
          },
          {
            label: "Time",
            field: "time",
            sort: "asc",
            width: 150,
          },
          {
            label: "Intake Instraction",
            field: "intake_instraction",
            sort: "asc",
            width: 150,
          },
          {
            label: "Dose",
            field: "dose",
            sort: "asc",
            width: 150,
          },

          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
        xlsArray: [],
      },
    };
    this.deleteReminder = this.deleteReminder.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == null) {
      window.location.href = "./login";
    }
    let userData = retrievedObject;

    this.setState({
      patient_id: userData.patient_id,
    });

    this.getSubscriptionDetails(userData.patient_id);
    var type = "";
    this.fetchpagesdata(type);
  };
  // Get subscription details of the Patient
  getSubscriptionDetails(patient_id) {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/subscription/getusersubscription",
      {
        patient_id: patient_id,
        status: 1
      }
    )
      .then((response) => {
        var daysDifference = "0";
        var subscriptionName = "";
        var subscriptionEnddate = "";
        this.items = response.data.data.map((item, key) => {
          var currentDate = new Date();
          //var cdate=moment(currentDate,'mm/dd/yyyy');
          var cdate=moment(currentDate).format('MM/DD/YYYY');
          var gdate=moment(item.subscription_todate).format('MM/DD/YYYY');
         
          daysDifference = moment(gdate).diff(
            moment(cdate),
            "days"
          );
          subscriptionName = item.master_subscription_tbl
            ? item.master_subscription_tbl.subscription_name
            : "";
          subscriptionEnddate = item.subscription_todate
            ? moment(item.subscription_todate).format("YYYY-MM-DD")
            : "";
        });
         
        if(daysDifference == 0)
        {
          
          toast.error("You have no subscriptions, please subscribe", {
            position: "top-center"
          })
        }
        this.setState({
          daysDifference: daysDifference,
          subscriptionName: subscriptionName,
          subscriptionEnddate: subscriptionEnddate,
        });
      })
      .catch((error) => { });
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    // this.setState({ [event.target.name]: event.target.value });
    let fields = this.state.fields;

    fields[field] = event.target.value;
    this.setState({ fields });
    if (fields["type"]) {
      this.fetchpagesdata(fields["type"]);
    }
  };

  fetchpagesdata(type) {
    const { fields, errors } = this.state;
    var retrievedObject = ls.get("userObjEnc");
    var Timings = "";
    var patientId = retrievedObject.patient_id;
    //Httpconfig.httptokenget(Constant.siteurl + "api/Remindersetting/" + patientId)
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/Remindersetting/viewReminderall",
      {
        patientId: patientId,
        type: type, //fields["type"], // "completed",
        //  iso_val: fields["iso_val"],
      }
    )
      .then((response) => {
        let reminderSetting = Object.assign({}, this.state);
        if (Object.keys(response.data.data).length > 0) {
          this.setState({
            page_data: response.data.data,
          });
          let assignvalues = [];
          let assignXlsxvalues = [];
          this.state.allReminderForHtmlRendering = response.data.data.map(
            (item, key) => {
              let reminderTimings = item.time;
              //-------- xslx data is pushed-------------//
              //console.log("---------208----", reminderTimings);

              //   //var Timings=item.time.join();
              //   if(item.length>0){
              //   if(item.time[key]!=""){
              //   Timings=item.time[key].replace(/[&\/\\#+()$~%.'"*?<>{}]/g, "");
              //   Timings=Timings.replace('[',"");
              //   Timings= Timings.replace(']',"");

              //   }
              // }

              assignXlsxvalues.push({
                Sno: key + 1,
                medicine_name: item.medicine_name,
                patient_id: item.patient_tbl.name,
                //doctor_id:item.doctor_id,
                numberoftimes: item.numberoftimes,
                start_date: item.fromdate, //moment(item.fromdate).format("DD-MMM-YYYY hh:mm a"),
                end_date: item.todate,
                time: item.time.toString(),
                intake_instraction: item.intake_instraction,
                dose: item.dose,
                status: item.status == "1" ? "Yes" : "No",
                description:item.description,
              });

              //  console.log("---- assignXlsxvalues ----", assignXlsxvalues);

              assignvalues.push(
                <div class="sample_box" key={item.id}>
                  <div class="med_box">
                    {/* <div class="med_icon">
                      <img src="./images/patient/img/med-icon.svg" />
                    </div> */}
                    <div class="med_main">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="med_details">
                            <h2>{item.medicine_name}</h2>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="med_session">
                            <p>M</p>
                            <p>A</p>
                            <p>E</p>
                            <p>N</p>
                          </div>
                          <div class="med_option">
                            {/* <h6><img src="./images/patient/img/vit-download.svg" /></h6> */}
                            <h6>
                              {/* <img to={"/Createreminder/" + item.id} src="./images/patient/img/vit-edit.svg"  title={"Update " + item.medicine_name}/> */}
                              <Link
                                to={"/Createreminder/" + item.id}
                                // className="fa fa-edit point-cursor"
                                title={"Update " + item.medicine_name}
                              >
                                <img src="./images/patient/img/vit-edit.svg" />
                              </Link>
                            </h6>
                            <h6>
                              {" "}
                              <img
                                onClick={() =>
                                  this.deleteReminder(
                                    item.id,
                                    item.medicine_name
                                  )
                                }
                                src="./images/patient/img/vit-delete.svg"
                              />
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="med_schedule">
                        <div class="row">
                          <div class="col-md-4">
                            <p class="list">
                              <img src="./images/patient/img/med-calender.svg" />
                              {item.fromdate} to {item.todate}
                            </p>
                          </div>

                          {reminderTimings
                            ? reminderTimings.map((timings, num) => {
                              return (
                                <div class="row">
                                  <div class="col-md-3">
                                    <p class="list">
                                      <img src="./images/patient/img/med-clock.svg" />
                                      {timings}
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                            : ""}
                          <div class="col-md-3">
                            <p class="list">
                              <img src="./images/patient/img/med-doc.svg" />
                              {item.intake_instraction}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="list">
                              <img src="./images/patient/img/med-pill.svg" />
                              {/* 1 Pill */}
                              {item.dose}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          );

          //reminderSetting.datatable.rows = assignvalues;

          reminderSetting.allReminderForHtmlRendering = assignvalues;

          reminderSetting.datatable.xlsArray = assignXlsxvalues;

          var todate = "";
          // if (response.data.data[0].patient_tbl.user_subscription_tbls.length > 0) {

          //   var todate = response.data.data[0].patient_tbl.user_subscription_tbls[0].subscription_todate;
          // }
          reminderSetting.PatientPlanDate = todate;
          this.forceUpdate();
          this.setState(reminderSetting);
        } else {
          reminderSetting.allReminderForHtmlRendering = "";
          this.forceUpdate();
          this.setState(reminderSetting);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //delete controller
  deleteReminder(Id, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(Constant.siteurl + "api/Remindersetting/" + Id)
        //axios.delete(Constant.siteurl+'api/Users/'+clinicId)
        .then((response) => {
          toast.success("Successfully Deleted Patient Reminder Setting");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  // Redirect to subscription Plans
  redirectSubscription = () => {
    ls.set("backredirecturl", "Viewremindersetting");
    window.location.href = Constant.imgurl + "/ViewsubscriptionPlan";
  };

  // hide notification bar
  hidebar = () => {
    $("#notification").hide("slow");
  };
  render() {
    const { datatable } = this.state;
    //  var currentDate = new Date();
    //  const daysDifference = moment(this.state.PatientPlanDate).diff(currentDate,"days")
    // if(daysDifference < 0)
    // {
    //   var days = "Plan already Expired";
    // }
    // else{
    //   var days = daysDifference+ " days left";
    // }

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <section id="main_dashboard">
          <div className="container medicine_main" id="main_front">
            <div className="row">
              <div class="col-lg-3 col-md-12">
                <PatientSideMenu />
              </div>
              <div class="col-lg-9 col-md-12">
                <section id="medicine_reminder">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div class="med_head">
                          <h2>Medicine Reminder</h2>
                          <div class="med_det">
                            {/* {this.state.daysDifference ? this.state.daysDifference  > 0 ?
                              <Link
                                to="/Createreminder">
                                <span class="add_btn"><img src="../images/patient/img/plus-icon.svg" />Add</span>
                              </Link>
                              : ""
                              : ""} */}

                            {this.state.daysDifference > 0 ? (
                              <Link to="/Createreminder">
                                <span class="add_btn">
                                  <img src="../images/patient/img/plus-icon.svg" />{" "}
                                  Add
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            {"       "}
                            {/* <span class="download_btn"><img src="../images/patient/img/blue-download.svg"/>Download</span> */}

                            {/* <div class="col-md-6 no_padding download-btn"> */}

                            {this.state.allReminderForHtmlRendering.length > 0 ?
                              <ExcelFile
                                filename="ViewReminderConfirmationReport"
                                element={
                                  <a class="download_btn">
                                    {" "}
                                    <img src="../images/patient/img/blue-download.svg" />{" "}
                                    Download{" "}
                                  </a>
                                }
                              >
                                <ExcelSheet
                                  data={datatable.xlsArray}
                                  name="ViewReminderConfirmation Request"
                                >
                                  <ExcelColumn label="Sno" value="Sno" />
                                  <ExcelColumn
                                    label="Patient Name"
                                    value="patient_id"
                                  />
                                  <ExcelColumn
                                    label="Medicine Name"
                                    value="medicine_name"
                                  />

                                  <ExcelColumn
                                    label="Start Date"
                                    value="start_date"
                                  />
                                  <ExcelColumn
                                    label="End Date"
                                    value="end_date"
                                  />
                                  <ExcelColumn label="Time" value="time" />
                                  <ExcelColumn
                                    label="Intake Instructions"
                                    value="intake_instraction"
                                  />
                                  <ExcelColumn label="Dose" value="dose" />

                                  <ExcelColumn label="Status" value="status" />
                                  <ExcelColumn label="Other Instructions" value="description" />
                                </ExcelSheet>
                              </ExcelFile>
                              : ""}
                            {/* </div> */}


                            <div class="form-group">
                              <select
                                name="type"
                                class="form-control"
                                onChange={this.handleChange.bind(this, "type")}
                              >
                                <option value="All">All</option>
                                <option value="upcoming">Upcoming</option>
                                <option value="completed">Completed</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" id="notification">
                      <div class="col-md-12 no_padding">
                        <div class="med_sub">
                          <div class="sub_plan">
                            
                        
                              {/* {this.state.daysDifference > 0 ? <h2>Your current subscription plan <b>{this.state.subscriptionName}</b> expires on <b>{this.state.subscriptionEnddate}</b></h2>:""}  */}
                             
                              {this.state.daysDifference  ? <h2>Your current subscription plan <b>{this.state.subscriptionName}</b> expires on <b>{this.state.subscriptionEnddate}</b> </h2>: <h2>You have no subscriptions please subscribe</h2>} 
                            <p onClick={this.redirectSubscription}>
                              Subscribe now{" "}
                            </p>
                            <div class="close_bar" onClick={this.hidebar}>
                              <span>
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 no_padding">
                        <div class="med_rem_section">
                          {/* <div class="med_icon">
                         <img src="./images/patient/img/med-icon.svg"/>
                         </div> */}
                          <div class="med_main">
                            {this.state.allReminderForHtmlRendering
                              ? this.state.allReminderForHtmlRendering
                              : "Data is not available"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
