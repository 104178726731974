import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const moment = require("moment");

export default class CreateDiagnostics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      retailer_data: "",
      type: "password",
      options: [],
      img: "/images/doctor-img/Login screens/hide_icon.svg",
    };
    this.showPassword = this.showPassword.bind(this);
  }
  //  for setStartTimeOnChange
  setStartTimeOnChange = (field, event) => {
    let expDate = event.target.value;
    this.setState({ registration_expirity: expDate });
    this.state.fields["registration_expirity"] =
      moment(expDate).format("YYYY-MM-DD");
    let count = expDate.search("-");
    if (count > 0) {
      let dateString = expDate.split("-");
      if (dateString[0].length != 4) {
        toast.error("Invalid expired date");
        return;
      }
    }

    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
    this.state.errors[field] = "";
  };
  // for showPassword
  showPassword() {
    let type = this.state.type;
    if (this.state.type) {
      if (this.state.type == "input") {
        this.state.type = "password";
        this.state.img = "/images/doctor-img/Login screens/hide_icon.svg";
      } else {
        this.state.type = "input";
        this.state.img = "/images/doctor-img/Login screens/unhide_icon.svg";
      }
    }
    this.forceUpdate();
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    this.getDiagnosisInfo(handle);
    this.fetchpagesdata();
    this.fetchCountryCurrencyDetails();
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  //  for emailValidation
  emailValidation = (field, event) => {
    var email = document.getElementById("txtEmailId").value;
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
      toast.error("Please provide a valid email address");
      return false;
    }
  };
  // To get all the ResponderInfo
  getDiagnosisInfo(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/diagnosis/" + handle)
      .then((response) => {
        console.log("--87--", response);
        this.state.labname = response.data.data[0].labname;

        this.setState({
          fields: {
            user_id: response.data.data[0].user_id,
            labname: response.data.data[0].labname,
            mobile_number: response.data.data[0].mobile_number,
            email: response.data.data[0].email,
            password: response.data.data[0].tbl_user.password,
            registration_number: response.data.data[0].registration_number,
            registration_expirity: response.data.data[0].registration_expirity,
            address: response.data.data[0].address,
            latitude: response.data.data[0].latitude,
            longitude: response.data.data[0].longitude,
            bank_name: response.data.data[0].bank_name,
            account_number: response.data.data[0].account_number,
            account_holder_name: response.data.data[0].account_holder_name,
            ifsc_code: response.data.data[0].ifsc_code,
            upi_number: response.data.data[0].upi_number,
            gst_number: response.data.data[0].gst_number,
            currency: response.data.data[0].currency,
            commission: response.data.data[0].commission,
            pan_number: response.data.data[0].pan_number,
          },
        });
        this.forceUpdate();
      })
      .catch((error) => {});
  }
  fetchpagesdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/diagnosis/")
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) =>
          assignvalues.push({
            labname: item.labname,
            "mobile_number ": item.mobile_number,
            "email ": item.email,
            registration_number: item.registration_number,
            registration_expirity: moment(item.registration_expirity).format(
              "YYYY-MM-DD"
            ),

            status: item.is_active == "1" ? "Active" : "In Active",
            actions: (
              <div>
                <span>
                  <Link
                    to={"/admin/Creatediagnostics/" + item.id}
                    className="fa fa-edit point-cursor"
                    title={"Update " + item.labname}
                  ></Link>
                </span>{" "}
                &nbsp;
                <span>
                  <i
                    onClick={() => this.deleteRetailer(item.id, item.labname)}
                    className="fa fa-trash point-cursor"
                    title={"Delete " + item.labname}
                  ></i>
                </span>{" "}
                &nbsp;
              </div>
            ),
          })
        );

        this.state.totalDiagnosis = assignvalues;
      })
      .catch((error) => {});
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateRetailers(event);
    } else if (this.handleValidation() && handle == undefined) {
      if (this.state.totalDiagnosis.length < 0) {
        this.createRetailer(event);
      } else {
        toast.error("You can't create more than one Diagnostics");
      }
    } else {
      //toast.warn("Form has errors.");
    }
  }
  //  for getting country currency details
  fetchCountryCurrencyDetails() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country")
      .then((response) => {
        this.setState({
          countryData: response.data.data,
        });
      })
      .catch((error) => {});
  }

  // creates new controller
  createRetailer = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    let expDate = moment(this.state.registration_expirity).format("YYYY-MM-DD");
    Httpconfig.httptokenpost(Constant.siteurl + "api/diagnosis/", {
      labname: fields["labname"],
      email: fields["email"],
      mobile_number: fields["mobile_number"],
      password: fields["password"],
      registration_expirity: expDate,
      registration_number: fields["registration_number"],
      address: fields["address"],
      latitude: fields["latitude"],
      longitude: fields["longitude"],
      bank_name: fields["bank_name"],
      account_number: fields["account_number"],
      account_holder_name: fields["account_holder_name"],
      ifsc_code: fields["ifsc_code"],
      upi_number: fields["upi_number"],
      gst_number: fields["gst_number"],
      pan_number: fields["pan_number"],
      commission: fields["commission"],
    })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success("Successfully Created Diagnostic Lab");
          setTimeout(
            () => this.props.history.push("/admin/ViewDiagnostics"),
            1000
          );
        } else if (response.status == 204) {
          toast.error("Email or Mobile Number already assosiated with us...");
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateRetailers = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    let expDate = moment(this.state.registration_expirity).format("YYYY-MM-DD");
    Httpconfig.httptokenput(Constant.siteurl + "api/diagnosis/" + handle, {
      labname: fields["labname"],
      email: fields["email"],
      mobile_number: fields["mobile_number"],
      password: fields["password"],
      registration_expirity: expDate,
      registration_number: fields["registration_number"],
      address: fields["address"],
      latitude: fields["latitude"],
      longitude: fields["longitude"],

      bank_name: fields["bank_name"],
      account_number: fields["account_number"],
      account_holder_name: fields["account_holder_name"],
      ifsc_code: fields["ifsc_code"],
      upi_number: fields["upi_number"],
      gst_number: fields["gst_number"],
      pan_number: fields["pan_number"],
      commission: fields["commission"],
    })
      .then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          toast.success(response.data.message);
          setTimeout(
            () => this.props.history.push("/admin/ViewDiagnostics"),
            2000
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["labname"]) {
      formIsValid = false;
      errors["labname"] = "Lab Name cannot be empty";
    }
    if (!fields["mobile_number"]) {
      formIsValid = false;
      errors["mobile_number"] = "Mobile Number cannot be empty";
    }
    if (!fields["registration_number"]) {
      formIsValid = false;
      errors["registration_number"] = "Registration Number cannot be empty";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }
    if (!fields["registration_expirity"]) {
      formIsValid = false;
      errors["registration_expirity"] = "Expiry Date cannot be empty";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { fields, errors, coupon_data, countryData } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      <a> Diagnostics </a>
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3>
                          {" "}
                          {this.state.fields["user_id"]
                            ? "Update Diagnostic"
                            : "Create Diagnostic"}{" "}
                        </h3>
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Diagnostic Name</label>
                                <input
                                  type="text"
                                  name="labname"
                                  value={this.state.fields["labname"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "labname"
                                  )}
                                  className="form-control"
                                  placeholder="Lab Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["labname"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Mobile Number</label>
                                <input
                                  type="text"
                                  name="mobile_number"
                                  value={
                                    this.state.fields["mobile_number"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "mobile_number"
                                  )}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  minLength="10"
                                  maxlength="10"
                                  className="form-control"
                                  placeholder="Mobile Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["mobile_number"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Email address</label>
                                <input
                                  type="text"
                                  name="email"
                                  id="txtEmailId"
                                  autoComplete="new-password"
                                  value={this.state.fields["email"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "email"
                                  )}
                                  className="form-control"
                                  placeholder="Email address"
                                />
                                <span className="cRed">
                                  {this.state.errors["email"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Enter Password</label>
                                <input
                                  type={this.state.type}
                                  name="password"
                                  autoComplete="new-password"
                                  value={this.state.fields["password"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "password"
                                  )}
                                  className="form-control"
                                  placeholder="Enter Password"
                                />
                                <img
                                  // style={{ marginTop: "33px" }}
                                  class="password_view_retailer"
                                  onClick={this.showPassword}
                                  src={this.state.img}
                                />
                                <span className="cRed">
                                  {this.state.errors["password"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Registration Number</label>
                                <input
                                  type="text"
                                  name="registration_number"
                                  value={
                                    this.state.fields["registration_number"] ||
                                    ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "registration_number"
                                  )}
                                  className="form-control"
                                  placeholder="Registration Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["registration_number"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <label>Registration Expiry</label>
                              <div className="form-group">
                                {/* <DatePicker
                                  name="registration_expirity"
                                  minDate={new Date()}
                                  autoComplete="off"
                                  className="dateInput"
                                  
                                  placeholderText="Registration Expiry"
                                  selected={this.state.registration_expirity}
                                  onChange={this.setStartTimeOnChange}
                                  value={
                                    this.state.fields["registration_expirity"]
                                  }
                                  dateFormat="dd-MM-yyyy"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  showYearDropdown
                                  showMonthDropdow
                                /> */}
                                <input
                                  type="date"
                                  id="registration_expirity"
                                  name="registration_expirity"
                                  className="form-control"
                                  value={
                                    this.state.fields["registration_expirity"]
                                  }
                                  min={moment().format("YYYY-MM-DD")}
                                  onChange={this.setStartTimeOnChange.bind(
                                    this,
                                    "registration_expirity"
                                  )}
                                />
                                <span className="cRed">
                                  {this.state.errors["registration_expirity"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Address</label>
                                <input
                                  type="text"
                                  name="address"
                                  value={this.state.fields["address"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "address"
                                  )}
                                  className="form-control"
                                  placeholder="Address"
                                />
                                <span className="cRed">
                                  {this.state.errors["address"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Latitude</label>
                                <input
                                  type="text"
                                  name="latitude"
                                  value={this.state.fields["latitude"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "latitude"
                                  )}
                                  className="form-control"
                                  placeholder="Latitude"
                                />
                                <span className="cRed">
                                  {this.state.errors["latitude"]}
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="row"> */}
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Longitude</label>
                                <input
                                  type="text"
                                  name="longitude"
                                  value={this.state.fields["longitude"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "longitude"
                                  )}
                                  className="form-control"
                                  placeholder="Longitude"
                                />
                                <span className="cRed">
                                  {this.state.errors["longitude"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Extra fields Start */}

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Bank Name</label>
                                <input
                                  type="text"
                                  name="bank_name"
                                  value={this.state.fields["bank_name"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "bank_name"
                                  )}
                                  className="form-control"
                                  placeholder="Bank Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["bank_name"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Account Number</label>
                                <input
                                  type="number"
                                  name="account_number"
                                  value={
                                    this.state.fields["account_number"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "account_number"
                                  )}
                                  className="form-control"
                                  placeholder="Account Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["account_number"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Account Holder Name</label>
                                <input
                                  type="text"
                                  name="account_holder_name"
                                  value={
                                    this.state.fields["account_holder_name"] ||
                                    ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "account_holder_name"
                                  )}
                                  className="form-control"
                                  placeholder="Account Holder Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["account_holder_name"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  name="ifsc_code"
                                  value={this.state.fields["ifsc_code"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "ifsc_code"
                                  )}
                                  className="form-control"
                                  placeholder="IFSC Code"
                                />
                                <span className="cRed">
                                  {this.state.errors["ifsc_code"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>UPI Number</label>
                                <input
                                  type="number"
                                  name="upi_number"
                                  value={this.state.fields["upi_number"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "upi_number"
                                  )}
                                  className="form-control"
                                  placeholder="UPI Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["upi_number"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>GST Number</label>
                                <input
                                  type="text"
                                  name="gst_number"
                                  value={this.state.fields["gst_number"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "gst_number"
                                  )}
                                  className="form-control"
                                  placeholder="GST Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["gst_number"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>PAN Number</label>
                                <input
                                  type="text"
                                  name="pan_number"
                                  value={this.state.fields["pan_number"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "pan_number"
                                  )}
                                  className="form-control"
                                  placeholder="PAN Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["pan_number"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Currency</label>
                                <select
                                  name="currency"
                                  className="form-control"
                                  value={this.state.fields["currency"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "currency"
                                  )}
                                >
                                  <option value="">Select</option>
                                  {countryData &&
                                    countryData.map((countryData, i) => {
                                      return (
                                        <option
                                          value={countryData.currency_symbol}
                                        >
                                          {countryData.currency_name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["currency"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>commission %</label>
                                <input
                                  type="number"
                                  name="commission"
                                  value={this.state.fields["commission"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "commission"
                                  )}
                                  className="form-control"
                                  placeholder="commission %"
                                />
                                <span className="cRed">
                                  {this.state.errors["commission"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Ends here */}

                          <div className="row">
                            <div className="form-group col-md-12">
                              <button
                                type="submit"
                                className="btn  btn-primary save_btn"
                                onClick={this.emailValidation.bind(this, "")}
                              >
                                {this.state.fields["user_id"]
                                  ? "Update Diagnostic"
                                  : "Save Diagnostic"}
                              </button>{" "}
                              &nbsp;
                              <Link
                                to="/admin/ViewDiagnostics"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
