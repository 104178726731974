import React, { Component } from "react";
import axios from "axios";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
export default {
  httpPost(url, postdata) {
    return axios.post(url, postdata);
  },
  httptokenpost(url, postdata) {
    const options = {
      headers: {
        Authorization: ls.get("AgentToken") ? ls.get("AgentToken") : "",
      },
    };
    return axios.post(url, postdata, options);
  },
  httptokenget(url) {
    const options = {
      headers: {
        Authorization: ls.get("AgentToken") ? ls.get("AgentToken") : "",
      },
    };
    return axios.get(url, options);
  },
  httptokendelete(url) {
    const options = {
      headers: {
        Authorization: ls.get("AgentToken") ? ls.get("AgentToken") : "",
      },
    };
    return axios.delete(url, options);
  },
  httptokenput(url, putdata) {
    const options = {
      headers: {
        Authorization: ls.get("AgentToken") ? ls.get("AgentToken") : "",
      },
    };
    return axios.put(url, putdata, options);
  },
};
