import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { MDBDataTable, MDBBadge, MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";

export default class Viewcoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_data: "",
      datatable: {
        columns: [
          {
            label: "Coupon Name ",
            field: "coupon_name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Module",
            field: "module",
            sort: "asc",
            width: 150,
          },
          {
            label: "Start Date",
            field: "start_date",
            sort: "asc",
            width: 150,
          },
          {
            label: "End Date",
            field: "end_date",
            sort: "asc",
            width: 150,
          },
          {
            label: "Discount Type",
            field: "is_percentage",
            sort: "asc",
            width: 150,
          },
          {
            label: "Discount % / Flat",
            field: "discount",
            sort: "asc",
            width: 150,
          },
          {
            label: "Minimum Value",
            field: "minimumorder_value",
            sort: "asc",
            width: 150,
          },
          {
            label: "Maximum Discount Amount",
            field: "maximumdiscount_amount",
            sort: "asc",
            width: 150,
          },
          {
            label: "No Of Users",
            field: "numberof_user",
            sort: "asc",
            width: 150,
          },
          {
            label: "No Of Times",
            field: "numberof_times",
            sort: "asc",
            width: 150,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 250,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 250,
          },
        ],
        rows: [],
      },
    };
    this.deleteCoupon = this.deleteCoupon.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    this.fetchpagesdata();
  };

  // When value changes of the fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //update status for patient

  updateStatus(id, status) {
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Coupon/ActiveInactive/" + status + "/" + id
    )

      .then((response) => {
        toast.success("Successfully updates Status");

        setTimeout(() => window.location.reload(false), 2000);
      })

      .catch((error) => {});
  }
  // For getting the data
  fetchpagesdata() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Coupon/")
      .then((response) => {
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) => {
          if (item.module == "telemedicine") {
            var moduleName = "Tele Medicine";
          } else if (item.module == "ordermedician") {
            var moduleName = "Order Medicine";
          } else if (item.module == "diagnostics") {
            var moduleName = "Diagnostics";
          } else {
            var moduleName = "";
          }
          assignvalues.push({
            coupon_name: item.coupon_name,
            module: moduleName,
            start_date: item.start_date,
            end_date: item.end_date,
            is_percentage: item.is_percentage,
            discount: item.discount,
            minimumorder_value: item.minimumorder_value,
            maximumdiscount_amount: item.maximumdiscount_amount,
            numberof_user: item.numberof_user,
            numberof_times: item.numberof_times,
            id: item.id,
            status: item.status,

            status:
              item.status == 1 ? (
                <MDBBadge
                  color="success"
                  onClick={() => this.updateStatus(item.id, false)}
                >
                  <a>
                    <h5>Active</h5>
                  </a>
                </MDBBadge>
              ) : (
                <MDBBadge
                  class="badge"
                  color="warning"
                  onClick={() => this.updateStatus(item.id, true)}
                >
                  <a>
                    <h5>In Active</h5>
                  </a>
                </MDBBadge>
              ),

            actions: (
              <div>
                <span>
                  <Link
                    to={"/admin/Createcoupons/" + item.id}
                    className="fa fa-edit point-cursor"
                    title={"Update " + item.coupon_name}
                  ></Link>
                </span>{" "}
                &nbsp;
                <span>
                  <i
                    onClick={() => this.deleteCoupon(item.id, item.coupon_name)}
                    className="fa fa-trash point-cursor"
                    title={"Delete " + item.coupon_name}
                  ></i>
                </span>{" "}
                &nbsp;
              </div>
            ),
          });
        });

        let couponState = Object.assign({}, this.state);
        couponState.datatable.rows = assignvalues;
        this.setState(couponState);
      })
      .catch((error) => {});
  }

  //delete controller
  deleteCoupon(userId, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(Constant.siteurl + "api/coupon/" + userId)
        .then((response) => {
          toast.success("Successfully Deleted coupon");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {});
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt; Clinic Setup &gt;
                      Coupons
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3> Create Coupon</h3>
                      </div>
                      <div id="reg_form" class="viewcoupons">
                        <form>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <Link
                                to="/admin/Createcoupons"
                                className="btn  btn-primary fright"
                              >
                                Create Coupons
                              </Link>
                            </div>
                          </div>
                        </form>
                        {datatable.rows.length === 0 ? (
                          <p>Loading............</p>
                        ) : (
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            small
                            data={datatable}
                          />
                        )}

                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="update_btn"
                              style={{ textAlign: "right" }}
                            ></div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
