import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import VideoConference from "../../VideoConference";
import VideoComponent from "../VideoComponent";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import PatientHeader from "../patient/Patientheader";
import PatientFooter from "../patient/Patientfooter";
import { json } from "body-parser";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const appointmentId = ls.get("PatientselectedAppointmentEnc");

export default class Patientvideocall extends Component {
  // make API calls once the component is initiated and configure the values into the state.

  componentWillMount = () => {
    this.state.meetingId = ls.get("PatientselectedAppointmentEnc");
  };
  // To get detais after first render

  componentDidMount() {
    const docName = localStorage.getItem("DoctotconsultName");
    this.state.docName = docName;
    var retrievedObject = ls.get("userObjEnc");
    if (retrievedObject == "" || retrievedObject == null) {
      toast.error("Please login to book an appointment", {});
      const interval = setInterval(() => {
        window.location.href = "/Patientlogin";
      }, 1000);
    }

    var appointmentId = ls.get("PatientselectedAppointmentEnc");
    const appointmentStatus = setInterval(() => {
      this.checkAppointmentStatus(appointmentId);
    }, 1000);

    this.state.meetingId = appointmentId;
    this.forceUpdate();
  }
  constructor(props) {
    super(props);
    this.state = {
      IsJitsiVideo: false,
    };
  }
  //  For handling the language change
  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  //  For checking the appointment status
  checkAppointmentStatus = (appointmentId) => {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/PatientAppointment/PatientAppointmentDetails/" +
        JSON.parse(appointmentId)
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          if (response.data.data[0].status == 3) {
            ls.remove("PatientselectedAppointmentEnc");
            window.location.href = "/Patientdashboard";
          }
          if (response.data.data[0].status == 4) {
            ls.remove("PatientselectedAppointmentEnc");
            window.location.href = "/Patientdashboard";
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  render() {
    const IsJitsiVideo = this.state.IsJitsiVideo;

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <section id="main_dashboard">
          <div className="container" id="main_front">
            <div className="row">
              <div className="col-md-12">
                <section id="CMS_tab">
                  <div className="CMS_content">
                    <div className="container">
                      <div className="row">

                        {!IsJitsiVideo ? (
                          <div id="reg_form">
                            <h6>Dr.{this.state.docName}</h6>
                            {this.state.meetingId}
                            {this.state.meetingId ? (
                              <VideoConference
                                meetingid={this.state.meetingId}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <MuiThemeProvider>
                            <div>
                              <VideoComponent />
                            </div>
                          </MuiThemeProvider>
                        )}
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <PatientFooter />
      </main>
    );
  }
}
