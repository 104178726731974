import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import Httpconfig from "../helpers/HttpconfigRetailer";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import RetailerCss from "../../public/css/retailer/retailer_style.css";
import DiagnosisHeader from "../diagnostics/DiagnosisHeader";
import DiagnosisMenu from "../diagnostics/DiagnosisMenu";
import Footer from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import * as CurrencyFormat from "react-currency-format";
import dateformatter from "../helpers/dateformatter";
const moment = require("moment");

export default class Diagnosisdashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCashcolleted: false,
      fields: {},
      currentHome: "nav-item nav-link active",
      prescriptionHome: "nav-item nav-link",
      shippedHome: "nav-item nav-link",
      deliverHome: "nav-item nav-link",
      cancelHome: "nav-item nav-link",
      processedHome: "nav-item nav-link",
      currentHomePanel: "tab-pane fade show active",
      prescriptionHomePanel: "tab-pane fade",
      processedHomePanel: "tab-pane fade",
      shippedHomePanel: "tab-pane fade",
      deliverHomePanel: "tab-pane fade",
      cancelHomePanel: "tab-pane fade",
      showOrderDetailsProcess: " pharmacy-tab collapse-hide",
      showOrdersGrid: "row showorder-list collapse-show",
      showRejectReasons: "collapse-hide",
      ordereditems: "",
      startDate: "",
      endDate: "",
      deliveryType: "",
      testStatus: "",
      agentID: "",
      retailerRejectList: "",
      orderId: "",
      invoiceDiv: "collapse-hide",
      invoicePDFPreview: "collapse-hide",
      retailerOrderMedicineInvoiceDetailsView: "",
      totalpaid: "",
      orderRejectReason: "",
      customerAddressId: "",
      patientAddressId: "",
      invoiceBtn: true,
      retailerDetails: [],
      DiagnosisAgents: [],
      orderDetails: [],
      file: null,
      couponDiscountAmount: "",
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.getTemplateRow = this.getTemplateRow.bind(this);
    this.caliculateInvoiceAmountsNewRows =
      this.caliculateInvoiceAmountsNewRows.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {
    window.addEventListener("popstate", function (e) {
      window.location.reload();
    });
    let userData = reactLocalStorage.getObject("retuserObj");

    if (userData) {
      if (userData != "") {
        this.setState({
          retailerId: userData.diagnosis_id,
        });
        this.getRetailerDetails(userData.diagnosis_id);
        this.getCompleteAgents(userData.diagnosis_id);
        this.forceUpdate();
      }
    } else {
      window.location.href = "/login";
    }

    var pdfgenerate = 0;
    pdfgenerate = localStorage.getItem("pdfgenerate");
    if (pdfgenerate == 1) {
      this.state.currentHome = "nav-item nav-link";
      this.state.prescriptionHome = "nav-item nav-link";
      this.state.processedHome = "nav-item nav-link";
      this.state.shippedHome = "nav-item nav-link";
      this.state.deliverHome = "nav-item nav-link";
      this.state.cancelHome = "nav-item nav-link";
      this.state.currentHomePanel = "tab-pane fade";
      this.state.shippedHomePanel = "tab-pane fade";
      this.state.deliverHomePanel = "tab-pane fade";
      this.state.cancelHomePanel = "tab-pane fade";
      this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
      this.state.showRejectReasons = "collapse-hide";
      this.state.showOrdersGrid = "row showorder-list collapse-show";
      this.state.invoiceDiv = "collapse-hide";
      this.state.processedHomePanel = "collapse-hide";
      this.state.processedHome = "nav-item nav-link active";
      this.state.processedHomePanel = "tab-pane fade show active";
      $("#prescription_home").removeClass("collapse-show");
      $("#prescription_home").addClass("collapse-hide");
      this.retailerOrderDetails(userData.diagnosis_id, 3);
      this.getAllDashboardDetails(userData.diagnosis_id);
      localStorage.removeItem("pdfgenerate");
      this.forceUpdate();
    } else {
      this.retailerOrderDetails(userData.diagnosis_id, 1);
      //this.getAllDashboardDetails(userData.retailer_id);
      // this.getAllDashboardDetails(userData.diagnosis_id);
    }
  };
  // called after the React component props or state has changed.

  //stating targetted files
  onChange(e) {
    var totFiles = [];
    var specialNames = [];
    var fileCount = 0;
    var selectFiles = e.target.files;
    this.setState({ file: e.target.files });
    var input = document.getElementById("file-upload");
    var output = document.getElementById("fileList");
    var files = $("#file-upload")[0].files;
    $.each(selectFiles, (i, file) => totFiles.push(file));
    var totalSize = 0;
    totFiles.map((file) => (totalSize += file.size));
    this.state.totalSize = totalSize;
    var totSize = (totalSize / 1024 / 1024).toFixed(2);
    $("#fileList").text(`Total size: ${totSize} MB`);
    var reportsCount = $("#lab_reports div").length;
    this.state.reportsCount = reportsCount;

    var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?/\s]+/;
    totFiles.map((file) => {
      let test = format.test(file.name);
      if (test) {
        specialNames.push(file);
      }
    });
    if (specialNames.length > 0) {
      toast.error("file name should not have special characters and spaces");
      document.getElementById("file-upload").value = "";
      return false;
    }
    // for (var i = 0; i < input.files.length; ++i) {
    //   var fileSize = (files[i].size / 1024 / 1024).toFixed(2);
    //   $("#upload_prev").append(
    //     "<span>" +
    //       '<div class="filenameupload">' +
    //       files[i].name +
    //       " (" +
    //       fileSize +
    //       " MB)</div>" +
    //       '<p class="close" >X</p></span>'
    //   );
    // }
  }
  // download Invoice
  downloadInvoice = (invoice) => {
    alert(invoice);
    var link = document.createElement("a");
    let name = invoice?.split("/") || [];
    name = name[name?.length - 1];
    link.setAttribute("download", name);
    link.href = invoice;
    document.body.appendChild(link);
    link.click();
    link.remove();
    //     var link=document.createElement('a');
    // document.body.appendChild(link);
    // link.href=invoice ;
    // link.click();
    //     return;
    //   return fetch(invoice).then((response) => {
    //     return response.blob();
    // }).then(blob => {
    //     return URL.createObjectURL(blob);
    // });
    return;
    Httpconfig.httptokenpost(Constant.siteurl + "api/Users/AWS_download/", {
      imageUrl: JSON.stringify(invoice),
    })
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // submitting lab reports
  onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    var uploadData = [];
    var fileLenth = this.state.file != null ? this.state.file : [];
    var totalLenght = fileLenth.length + this.state.labReportArray.length;
    this.state.uploadReports = fileLenth;
    var fileInput = document.getElementById("file-upload");
    var fileName = $("#file-upload").val();
    if (fileLenth == null || fileName == "") {
      e.preventDefault();
      toast.error("Please select files");
      return false;
    }
    if (this.state.totalSize >= 5000000) {
      toast.error("file size should be less than 5Mb");
      document.getElementById("file-upload").value = "";
      return false;
    }

    if (totalLenght > 10) {
      e.preventDefault();
      toast.error("More than 10 files not allowed");
      return false;
    } else if (this.state.reportsCount >= 10 || this.state.reportsCount > 10) {
      e.preventDefault();
      toast.error("More than 10 files not allowed");
      return false;
    }

    for (var x = 0; x < fileLenth.length; x++) {
      formData.append("myImage", this.state.file[x]);
    }
    formData.append("order_id", this.state.UploadId);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/uploadLabReports",
      formData,
      config
    )
      .then((response) => {
        toast.dismiss();
        toast.success("successfully uploaded content");
        $("#upload_reports").show();
        document.getElementById("file-upload").value = "";
        document.getElementById("fileList").innerHTML = "";
        this.getCompleteOrderDetails(
          this.state.retailerId,
          this.state.UploadId
        );
      })
      .catch((error) => {});
  }
  getCompleteOrderDetails = (retailerId, orderId) => {
    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
        retailerId +
        "/" +
        orderId
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        const orderDetails = response.data.data.map((orderDetails, num) => {
          let labReportArray = orderDetails.lab_reports
            ? JSON.parse(orderDetails.lab_reports)
            : "";
          this.state.labReportArray = orderDetails.lab_reports
            ? JSON.parse(orderDetails.lab_reports)
            : [];
          //console.log("--202--", this.state.labReportArray);
          this.state.uploadOrderID = orderDetails.id;
          this.state.reportOrderStatus = orderDetails.order_status;
          this.state.uploadDeliveryType = orderDetails.delivery_type;
          this.state.uploadPyamentMode = orderDetails.payment_mode;
          if (orderDetails.patient_tbl) {
            this.state.patientEmail = orderDetails.patient_tbl.tbl_user.email;
          }
          return (
            <div id="lab_reports">
              {labReportArray ? (
                labReportArray.map((labImages, num) => {
                  return (
                    <div class="presc_img">
                      <p>
                        <img
                          class="pdf"
                          src="../images/patient/img/file-pdf.svg"
                        />
                        {labImages.replace(/^.*[\\\/]/, "")}
                      </p>
                      {this.state.reportOrderStatus == 5 ? (
                        <a
                          href={labImages}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={labImages}
                        >
                          <i
                            className="fa fa-download"
                            //class="fa fa-eye"
                            class="fa fa-eye"
                            title="View"
                            aria-hidden="true"
                            //onClick={this.downloadInvoice.bind(this, labImages)}
                          ></i>
                        </a>
                      ) : (
                        ""
                      )}

                      {this.state.reportOrderStatus == 4 ? (
                        <img
                          class="delete"
                          src="../images/patient/img/delete-sweep.svg"
                          onClick={this.removeImageOnClick.bind(
                            this,
                            this.state.uploadOrderID,
                            labImages
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              ) : (
                <center>No Reports Found</center>
              )}
            </div>
          );
        });
        this.setState({
          orderDetails: orderDetails,
        });
      }
    });
  };
  // called after the React component props or state has changed.

  getCompleteAgents(retailerId) {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/diagnosis/fetchdata/" + retailerId
    )
      .then((response) => {
        this.setState({
          DiagnosisAgents: response.data.data,
        });
        this.forceUpdate();
      })
      .catch((error) => {});
  }
  componentDidUpdate = () => {
    // console.log("--124--", this.state.DiagnosisAgents);
    var batchList = document.getElementsByClassName("batches-list");

    var sort = document.getElementsByClassName("newrows");
  };

  removeImageOnClick = (OrderId, imgName) => {
    // alert(OrderId);
    // alert(imgName);
    let data = {
      OrderId: OrderId,
      image_name: imgName,
    };
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/deleteUploadreportsImage",
      data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.getCompleteOrderDetails(this.state.retailerId, OrderId);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  handleChange = (event) => {
    let fields = this.state.fields;
    let agentName = this.state.selectAgent;
    let agentSelect = $(".agent_sel").val();
    fields["orderId"] = event.target.id;

    this.setState({ fields });

    if (agentSelect != "") {
      this.agentSelection(agentName, fields["orderId"], this.state.retailerId);
    } else {
      toast.dismiss();
      toast.error("Please Select Agent");
    }
  };

  getAgentData(agent_id, orderId) {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/diagnosis/fetchAgentdata/" +
        agent_id +
        "/" +
        orderId
    )
      .then((response) => {
        this.state.agent_orderId = response.data.data[0].id;
        // this.state.agentId = response.data.data[0].agent_id;
        $(".appr_home").hide();
        this.state.agentName =
          response.data.data[0].agent_registration_tbl.name;
        localStorage.setItem(
          "agentName",
          response.data.data[0].agent_registration_tbl.id
        );
        this.forceUpdate();
      })
      .catch((error) => {});
  }

  agentSelection = (agentId, orderId, retailerId) => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/agentAllots/",
      {
        agentId: agentId,
        orderId: orderId,
        retailerId: retailerId,
        agent_status: "1",
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.dismiss();
          toast.success("Agent Alloted Successfully");
          this.getAgentData(agentId, orderId);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  handleClick = (event) => {
    let id = event.currentTarget.id;
    this.state.currentHome = "";
    this.state.prescriptionHome = "";
    this.state.processedHome = "";
    this.state.shippedHome = "";
    this.state.deliverHome = "";
    this.state.cancelHome = "";
    this.forceUpdate();
    this.state.currentHomePanel = "tab-pane fade";
    this.state.shippedHomePanel = "tab-pane fade";
    this.state.deliverHomePanel = "tab-pane fade";
    this.state.cancelHomePanel = "tab-pane fade";
    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
    this.state.showRejectReasons = "collapse-hide";
    this.state.showOrdersGrid = "row showorder-list collapse-show";
    this.state.invoiceDiv = "collapse-hide";
    this.state.processedHomePanel = "collapse-hide";
    this.state.currentHome = "nav-item nav-link";
    this.state.prescriptionHome = "nav-item nav-link";
    this.state.processedHome = "nav-item nav-link";
    this.state.shippedHome = "nav-item nav-link";
    this.state.deliverHome = "nav-item nav-link";
    this.state.cancelHome = "nav-item nav-link";
    if (id == "current_home-tab") {
      this.state.currentHome = "nav-item nav-link active";
      this.state.prescriptionHome = "nav-item nav-link";
      this.state.currentHomePanel = "tab-pane fade show active";
      $("#prescription_home").removeClass("collapse-show");
      $("#prescription_home").removeClass("collapse-hide");
      $("#prescription_home").removeClass("active");
      this.retailerOrderDetails(this.state.retailerId, 1);
      $(".tableFixHead").show();
      $("#upload_reports").hide();
    }
    if (id == "prescription_home-tab") {
      this.state.prescriptionHome = "nav-item nav-link active";
      this.state.prescriptionPanel = "tab-pane fade show active";
      $("#prescription_home").addClass("collapse-show");
      $("#prescription_home").removeClass("collapse-hide");
      $("#current_home").removeClass("active");
      $(".tableFixHead").show();
      $("#upload_reports").hide();
      this.retailerOrderDetails(this.state.retailerId, 2);
    }
    if (id == "processed_home-tab") {
      this.state.processedHome = "nav-item nav-link active";
      this.state.processedHomePanel = "tab-pane fade show active";
      $("#prescription_home").removeClass("collapse-show");
      $("#prescription_home").addClass("collapse-hide");
      $(".tableFixHead").show();
      $("#upload_reports").hide();
      this.retailerOrderDetails(this.state.retailerId, 3);
    }
    if (id == "shipped_home-tab") {
      this.state.shippedHome = "nav-item nav-link active";
      this.state.shippedHomePanel = "tab-pane fade show active";
      $("#prescription_home").removeClass("collapse-show");
      $("#prescription_home").addClass("collapse-hide");
      $(".tableFixHead").show();
      $("#upload_reports").hide();
      this.retailerOrderDetails(this.state.retailerId, 4);
    }
    if (id == "deliver_home-tab") {
      this.state.deliverHome = "nav-item nav-link active";
      this.state.deliverHomePanel = "tab-pane fade show active";
      $("#prescription_home").removeClass("collapse-show");
      $("#prescription_home").addClass("collapse-hide");
      $(".tableFixHead").show();
      $("#upload_reports").hide();
      this.retailerOrderDetails(this.state.retailerId, 5);
    }
    if (id == "cancel_home-tab") {
      this.state.cancelHome = "nav-item nav-link active";
      this.state.cancelHomePanel = "tab-pane fade show active";
      $("#prescription_home").removeClass("collapse-show");
      $("#prescription_home").addClass("collapse-hide");
      $(".tableFixHead").show();
      $("#upload_reports").hide();
      this.retailerOrderDetails(this.state.retailerId, 6);
    }

    this.forceUpdate();
  };
  Uploadlabreports = (orderID, status) => {
    this.state.UploadId = orderID;
    this.forceUpdate();
    if (this.state.OrderStatus == status) {
      $(".tableFixHead").hide();
      $("#upload_reports").show();
      this.getCompleteOrderDetails(this.state.retailerId, this.state.UploadId);
    }
  };
  // Get the Order Dashboard Details
  getAllDashboardDetails = (retailerId) => {
    var startDate = this.state.startDate;
    var endDate = this.state.endDate;
    let timeZone = moment.tz.guess();
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/getOrderDashboardDetails/",
      {
        retailerId: retailerId,
        start_date: startDate,
        end_date: endDate,
        timeZone: timeZone,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          this.setState({
            pendingOrder: response.data.data[0].pendingOrder,
            shippedOrder: response.data.data[0].shippedOrder,
            cancelledOrder: response.data.data[0].cancelledOrder,
            deliveredOrder: response.data.data[0].deliveredOrder,
            PrescriptionVerifiedOrder: response.data.data[0].previewOrder,
            processingOrder: response.data.data[0].processingOrder,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // order details list
  retailerOrderDetails = (retailerId, status) => {
    //alert("Hi");
    let agentName = localStorage.getItem("agentName");
    let startDate = "";
    let endDate = "";
    let finalorderarray = [];
    let timeZone = moment.tz.guess();
    startDate = this.state.startDate;
    endDate = this.state.endDate;
    this.getAllDashboardDetails(retailerId);
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/retailerOrders",
      {
        retailer_id: retailerId,
        start_date: startDate,
        end_date: endDate,
        status: status,
        timeZone: timeZone,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            const retailerFinalOrderMedicineDetailsView =
              response.data.data.map((finalLoadedData, num) => {
                var patientEmail = "";
                if (!finalLoadedData.patient_tbl) {
                  patientEmail = "";
                } else {
                  patientEmail = finalLoadedData.patient_tbl.tbl_user
                    ? finalLoadedData.patient_tbl.tbl_user.email
                    : "";
                }

                let labReportArray = finalLoadedData.lab_reports
                  ? JSON.parse(finalLoadedData.lab_reports)
                  : "";
                this.state.OrderStatus = finalLoadedData.order_status;
                if (finalLoadedData.order_status == status) {
                  return (
                    <tr>
                      {" "}
                      <td>{num + 1}</td>
                      <td>
                        {finalLoadedData.patient_tbl
                          ? finalLoadedData.patient_tbl.name
                              .charAt(0)
                              .toUpperCase() +
                            finalLoadedData.patient_tbl.name.slice(1)
                          : ""}{" "}
                        <h6>
                          {finalLoadedData.patient_tbl
                            ? finalLoadedData.patient_tbl.phone_number
                            : ""}
                        </h6>
                      </td>
                      <td>{finalLoadedData.id}</td>
                      <td>
                        {dateformatter.asianDateFormat(
                          finalLoadedData.order_date
                        )}
                      </td>
                      <td>
                        <p>
                          {dateformatter.asianDateFormatnotime(
                            finalLoadedData.slot_date
                          )}
                        </p>
                        <p>{finalLoadedData.slot_time}</p>
                      </td>
                      <td class="presc_field">
                        {finalLoadedData.cart_prescriptions_tbl
                          ? finalLoadedData.cart_prescriptions_tbl.medical_document.map(
                              (presImages, num) => {
                                return (
                                  <a
                                    href={presImages ? presImages : ""}
                                    target="_blank"
                                  >
                                    <img
                                      class="presc_img"
                                      src="../images/retailer/Invoice.svg"
                                    />
                                  </a>
                                );
                              }
                            )
                          : "--"}
                      </td>
                      <td>
                        {
                          Object.keys(finalLoadedData.order_processing_tbls)
                            .length
                        }
                      </td>
                      <td>
                        {Constant.currency}{" "}
                        {parseFloat(finalLoadedData.payable_amount)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      {finalLoadedData.order_status != 1 ? (
                        <td class="presc_datafield">
                          {finalLoadedData.invoice != null ? (
                            <a
                              href={
                                finalLoadedData.invoice != null
                                  ? finalLoadedData.invoice
                                  : ""
                              }
                              target="_blank"
                            >
                              {" "}
                              <img
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Invoice"
                                class="presc_img"
                                src="../images/retailer/RxFile.svg"
                              />
                            </a>
                          ) : (
                            "--"
                          )}
                          {finalLoadedData.order_status == 5 ? (
                            <a
                              class="shipped_box"
                              id={finalLoadedData.id}
                              onClick={this.Uploadlabreports.bind(
                                this,
                                finalLoadedData.id,
                                "5"
                              )}
                            >
                              <img
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Reports"
                                class="rep_img"
                                src="../images/patient/img/reports.svg"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td>--</td>
                      )}
                      {finalLoadedData.delivery_type == "center_visit" ? (
                        <td>--</td>
                      ) : (
                        <td>
                          {finalLoadedData.agent_registration_tbl &&
                          finalLoadedData.agent_registration_tbl
                            ? finalLoadedData.agent_registration_tbl.name
                            : "unassigned"}
                          {finalLoadedData.order_status == 1 &&
                          finalLoadedData.pickup_cancel_reason != null ? (
                            <p title={finalLoadedData.pickup_cancel_reason}>
                              Unable to Collect
                            </p>
                          ) : (
                            ""
                          )}
                        </td>
                      )}
                      {/* <td>
                        {finalLoadedData.invoice != null
                          ? "Samples Collected"
                          : "--"}
                      </td> */}
                      {finalLoadedData.order_status == 1 ? (
                        <td class="pending_td">
                          {" "}
                          <a
                            class="pending_box"
                            id={finalLoadedData.id}
                            name={
                              finalLoadedData.patient_address_id
                                ? finalLoadedData.patient_address_id
                                : ""
                            }
                            onClick={this.showOrderdetails}
                          >
                            Pending{" "}
                            <img src="../images/retailer/Icon-ionic-ios-arrow-forward.svg" />
                          </a>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {(finalLoadedData.order_status == 2 &&
                        finalLoadedData.test_status == "picked-up") ||
                      (finalLoadedData.order_status == 2 &&
                        finalLoadedData.delivery_type == "center_visit") ? (
                        <td class="pending_td">
                          {" "}
                          <a
                            class="presc_box"
                            id={finalLoadedData.id}
                            name={finalLoadedData.patient_address_id}
                            onClick={this.prescriptionVerified}
                          >
                            {finalLoadedData.delivery_type == "center_visit"
                              ? "Center Visit"
                              : "Sample collected"}
                            <img src="../images/retailer/Icon-ionic-ios-arrow-forward.svg" />
                          </a>{" "}
                        </td>
                      ) : finalLoadedData.order_status == 2 ? (
                        <td class="pending_td">
                          {" "}
                          <a
                            class="presc_box"
                            id={finalLoadedData.id}
                            name={
                              finalLoadedData.patient_address_id
                                ? finalLoadedData.patient_address_id
                                : ""
                            }
                            // onClick={this.showOrderdetails}
                          >
                            sample collection pending
                            <img src="../images/retailer/Icon-ionic-ios-arrow-forward.svg" />
                          </a>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {finalLoadedData.order_status == 3 ? (
                        <td class="pending_td">
                          {" "}
                          <a
                            class="process_box"
                            id={finalLoadedData.id}
                            onClick={this.ChangeOrderStatus.bind(
                              this,
                              finalLoadedData.id,
                              "4",
                              "Gokada",
                              patientEmail
                            )}
                          >
                            Processed{" "}
                            <img src="../images/retailer/Icon-ionic-ios-arrow-forward.svg" />
                          </a>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {finalLoadedData.order_status == "4" ? (
                        <td class="pending_td dropdown">
                          {" "}
                          <a
                            class="shipped_box"
                            id={finalLoadedData.id}
                            onClick={this.Uploadlabreports.bind(
                              this,
                              finalLoadedData.id,
                              "4"
                            )}
                          >
                            Upload{" "}
                            <i class="fa fa-upload" aria-hidden="true"></i>
                          </a>
                          {/* <div
                            class="dropdown-menu shipped_item"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              id={finalLoadedData.id}
                              onClick={this.ChangeOrderStatus.bind(
                                this,
                                finalLoadedData.id,
                                "5",
                                "",
                                patientEmail
                              )}
                            >
                              <img src="https://cdn4.iconfinder.com/data/icons/text-editor/154/additional-vertical-menu-dots-512.png" />
                              Completed
                            </a>
                          </div> */}
                        </td>
                      ) : (
                        ""
                      )}
                      {finalLoadedData.order_status == 5 ? (
                        <td class="pending_td">
                          {" "}
                          <a class="delivery_box" id={finalLoadedData.id}>
                            Completed{" "}
                          </a>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {finalLoadedData.order_status == 6 ? (
                        <td class="pending_td">
                          {" "}
                          <a class="cancelled_box" id={finalLoadedData.id}>
                            Cancelled{" "}
                          </a>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                }
              });
            this.state.retailerFinalOrderMedicineDetailsView = [];
            this.state.retailerFinalOrderMedicineDetailsView =
              retailerFinalOrderMedicineDetailsView;
            this.state.finalorderarray = finalorderarray;
          } else {
            this.state.retailerFinalOrderMedicineDetailsView = "";
          }
          this.forceUpdate();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // show order details for process

  showOrderdetails = (event) => {
    //console.log("showOrderdetails....");
    let orderId = event.currentTarget.id;
    let retailerId = this.state.retailerId;

    this.hidegridpendingorders();
    this.state.orderId = orderId;
    let customerAddressId = event.currentTarget.name;
    this.setState({ customerAddressId: customerAddressId });

    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
        retailerId +
        "/" +
        orderId,
      { customerAddressId }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            this.setState({
              custmerEmail: response.data.data[0].patient_tbl.tbl_user.email,
              customerMobileNumber:
                response.data.data[0].patient_tbl.phone_number,
            });

            const retailerOrderMedicineDetailsView = response.data.data.map(
              (finalLoadedData, num) => {
                this.state.customerName =
                  finalLoadedData.patient_tbl.name.charAt(0).toUpperCase() +
                  finalLoadedData.patient_tbl.name.slice(1);
                this.state.customermobile =
                  finalLoadedData.patient_tbl.phone_number;

                this.state.retailerName =
                  finalLoadedData.diagnosis_registration_tbl.labname;
                this.state.delivery_type = finalLoadedData.delivery_type;

                this.state.amount = parseFloat(finalLoadedData.payable_amount)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                // this.state.itemDiscount = finalLoadedData.discount
                //   ? finalLoadedData.discount
                //   : 0;

                this.state.itemsCount = Object.keys(
                  finalLoadedData.order_processing_tbls
                ).length;
                this.state.customerId = finalLoadedData.patient_tbl.id;
                this.state.deliveryType = finalLoadedData.delivery_type;
                this.state.testStatus = finalLoadedData.test_status;
                this.state.agentID = finalLoadedData.agent_id;
                this.state.testStatus = finalLoadedData.test_status;
                return (
                  <React.Fragment>
                    {Object.keys(finalLoadedData.order_processing_tbls).length >
                    0
                      ? finalLoadedData.order_processing_tbls.map(
                          (subData, num) => {
                            this.state.itemDiscountper = subData.discount
                              ? subData.discount
                              : 0; //subData.retailer_stock_tbl[0].discount;
                            return (
                              <React.Fragment>
                                {subData.diagnosis_products_master_tbl ? (
                                  <tr>
                                    <td class="check_bar">
                                      {num + 1}
                                      {/* <input
                                        type="checkbox"
                                        class="medidinesList"
                                        name="medidinesList"
                                        id={
                                          subData.diagnosis_products_master_tbl
                                            .id +
                                          "-" +
                                          subData.diagnosis_products_master_tbl
                                            .testname
                                        }
                                        onChange={(event) => {
                                          $(".medidinesList").prop(
                                            "checked",
                                            true
                                          );
                                        }}
                                      /> */}
                                    </td>
                                    <td class="test_name">
                                      {
                                        subData.diagnosis_products_master_tbl
                                          .testname
                                      }{" "}
                                      <h6>
                                        {
                                          subData.diagnosis_products_master_tbl
                                            .manufacturer
                                        }
                                      </h6>
                                    </td>

                                    <td>
                                      <div class="qty ">
                                        <input
                                          type="number"
                                          class="count"
                                          name="qty"
                                          id={"txt" + "-" + finalLoadedData.id}
                                          defaultValue={subData.quantity}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {Constant.currency}{" "}
                                      {this.state.itemDiscountper
                                        ? parseFloat(subData.amount) -
                                          (subData.amount *
                                            this.state.itemDiscountper) /
                                            100
                                        : subData.amount}
                                      <br />
                                      {Constant.currency}{" "}
                                      <del>{subData.amount}</del>
                                      <span class="dis_data">
                                        {""}{" "}
                                        {this.state.itemDiscountper
                                          ? this.state.itemDiscountper +
                                            " % off"
                                          : ""}{" "}
                                      </span>
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            );
                          }
                        )
                      : ""}
                  </React.Fragment>
                );
              }
            );

            const retailerPrescriptionDetailsView = response.data.data.map(
              (finalLoadedData, num) => {
                return (
                  <React.Fragment>
                    {finalLoadedData.cart_prescriptions_tbl
                      ? finalLoadedData.cart_prescriptions_tbl.medical_document.map(
                          (presImages, num) => {
                            return (
                              <a
                                href={presImages ? presImages : ""}
                                target="_blank"
                              >
                                <img class="presc_img" src={presImages} />
                              </a>
                            );
                          }
                        )
                      : "Prescription not available"}
                  </React.Fragment>
                );
              }
            );

            $(".nav-item,.nav-link").removeClass("active");
            $("#prescription_home-tab").addClass("active");
            this.state.ordereditems = retailerOrderMedicineDetailsView;
            this.state.retailerPrescriptionDetailsView =
              retailerPrescriptionDetailsView;
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // shortage book quanatity decrement

  qtyDecrement = (event) => {
    let data = event.currentTarget.id.split("-");
    let id = data[0];
    let orderId = data[1];
    let qty = $("#txt-" + id).val();

    if (qty > 0) {
      qty = qty - 1;
      $("#txt-" + id).val(qty);
    }
  };

  // shortage book quanatity Increment
  qtyIncrement = (event) => {
    let data = event.currentTarget.id.split("-");
    let id = data[0];
    let orderId = data[1];
    let qty = $("#txt-" + orderId).val();

    if (qty >= 0) {
      qty = parseInt(qty) + 1;
      $("#txt-" + orderId).val(qty);
    }
  };

  // End date selection

  startDate = (field, event) => {
    let startDate = event.target.value;
    this.state.startDate = moment(startDate).format("YYYY-MM-DD");

    if (field == "startDate") {
      let count = startDate.search("-");
      if (count > 0) {
        let dateString = startDate.split("-");
        if (dateString[0].length != 4) {
          toast.error("Invalid date formate");
        }
      }
    }
    this.forceUpdate();
  };

  // Start date selection
  endDate = (field, event) => {
    let endDate = event.target.value;
    this.state.endDate = moment(endDate).format("YYYY-MM-DD");

    if (field == "endDate") {
      let count = endDate.search("-");

      if (count > 0) {
        let dateString = endDate.split("-");
        if (dateString[0].length != 4) {
          toast.error("Invalid date formate");
        }
      }
    }
    this.forceUpdate();
  };
  //  For show order dates
  showOrderbyDates = () => {
    let start_date = $("#start_date").val();
    let end_date = $("#end_date").val();

    if (start_date != "" && end_date != "") {
      if (start_date > end_date) {
        toast.error("From date  do not  greater than todate ");
        return;
      }
      let id = $(".p_nav").find(".active").attr("id");

      if (id == "current_home-tab") {
        this.retailerOrderDetails(this.state.retailerId, 1);
        this.showgridpendingorders();
      }
      if (id == "prescription_home-tab") {
        this.retailerOrderDetails(this.state.retailerId, 2);
        this.showgridpendingorders();
      }
      if (id == "processed_home-tab") {
        this.retailerOrderDetails(this.state.retailerId, 3);
        this.showgridpendingorders();
      }

      if (id == "shipped_home-tab") {
        this.retailerOrderDetails(this.state.retailerId, 4);
        this.showgridpendingorders();
      }
      if (id == "deliver_home-tab") {
        this.retailerOrderDetails(this.state.retailerId, 5);
        this.showgridpendingorders();
      }
      if (id == "cancel_home-tab") {
        this.retailerOrderDetails(this.state.retailerId, 6);
        this.showgridpendingorders();
      }
    } else {
      toast.error("Please select dates");
    }
  };

  // show grid and hide pending order details

  showgridpendingorders = () => {
    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
    this.state.showOrdersGrid = "row showorder-list collapse-show";
    this.state.showRejectReasons = "collapse-hide";
    this.state.invoiceDiv = "collapse-hide";
    this.state.invoicePDFPreview = "collapse-hide";
    this.forceUpdate();
  };

  // hide grid and show pending order details

  hidegridpendingorders = () => {
    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-show";
    this.state.showOrdersGrid = "row showorder-list collapse-hide";
    this.state.showRejectReasons = "collapse-hide";
    this.forceUpdate();
  };
  //  For navigation
  back = () => {
    this.showgridpendingorders();
  };

  // update the cancellation reaon

  cancelReason = (event) => {
    $(".rej_check").prop("checked", false);
    $("#" + event.currentTarget.id).prop("checked", true);

    let id = event.currentTarget.id.split("_")[1];
    this.setState({ orderRejectReason: event.currentTarget.value });

    if (document.getElementById(event.currentTarget.id).checked == true) {
      this.state.cancelleReason = id;
    } else {
      this.state.cancelleReason = "";
    }
  };
  // reject order show div

  rejectOrderShow = () => {
    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
    this.state.showOrdersGrid = "row showorder-list collapse-hide";
    this.state.showRejectReasons = "collapse-show";
    this.getRejectionResonslist();
    this.forceUpdate();
  };
  //  For getting rejection reason list
  getRejectionResonslist = () => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/cancel/cancellationReason/"
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            const retailerRejectList = response.data.data.map(
              (finalLoadedData, num) => {
                return (
                  <p>
                    <input
                      type="checkbox"
                      class="rej_check"
                      name="cancelReasons"
                      id={"rejid_" + finalLoadedData.id}
                      value={finalLoadedData.reason}
                      onChange={this.cancelReason}
                    />{" "}
                    {finalLoadedData.reason}
                  </p>
                );
              }
            );
            this.state.retailerRejectList = retailerRejectList;
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For reject order
  rejectOrder = () => {
    let cancelReasonsCheckedCount = $(
      'input[name="cancelReasons"]:checked'
    ).length;
    if (cancelReasonsCheckedCount == 0) {
      toast.error("Select Reason for Cancellation");
      return;
    } else {
      let orderId = this.state.orderId;
      let cancelledReason = this.state.cancelleReason;
      let orderRejectReason = this.state.orderRejectReason;
      let custmerEmail = this.state.custmerEmail;
      //let customerMobile = this.state.customerMobileNumber;
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/cancel/cancellationReason/orderDetails/" +
          orderId,
        {
          order_status: "6",
          cancelled_reason: cancelledReason,
          orderRejectReason,
          custmerEmail,
        }
      )
        .then((response) => {
          if ((response.data.status = 200)) {
            toast.dismiss();
            toast.success(response.data.message);
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  // Create Order in Gokada
  createOrder = (orderId, deliveryAgent) => {
    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
        this.state.retailerId +
        "/" +
        orderId
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          let pickupAddress =
            response.data.data[0].diagnosis_registration_tbl.address;
          let pickupLatitude =
            response.data.data[0].diagnosis_registration_tbl.latitude;
          let pickupLongitude =
            response.data.data[0].diagnosis_registration_tbl.longitude;
          let pickupName =
            response.data.data[0].diagnosis_registration_tbl.labname;
          let pickupPhone =
            response.data.data[0].diagnosis_registration_tbl.mobile_number;
          let pickupEmail = "rakesh.n@medleymed.com";
          let deliveryAddress =
            response.data.data[0].patient_tbl.delivery_address_tbls[0].address;
          let deliveryLatitude =
            response.data.data[0].patient_tbl.delivery_address_tbls[0].latitude;
          let deliveryLongitude =
            response.data.data[0].patient_tbl.delivery_address_tbls[0]
              .longitude;
          let deliveryName =
            response.data.data[0].patient_tbl.delivery_address_tbls[0].name;
          let deliveryPhone =
            response.data.data[0].patient_tbl.delivery_address_tbls[0]
              .mobile_no;
          let deliveryEmail = "rakesh.n@medleymed.com";
          let apiKey = Constant.gokadaApiKey;

          let delivery = {
            api_key: apiKey,
            pickup_address: pickupAddress,
            pickup_latitude: pickupLatitude,
            pickup_longitude: pickupLongitude,
            delivery_address: deliveryAddress,
            delivery_latitude: deliveryLatitude,
            delivery_longitude: deliveryLongitude,
            pickup_name: pickupName,
            pickup_phone: "+234" + pickupPhone,
            pickup_email: pickupEmail,
            delivery_name: deliveryName,
            delivery_phone: "+234" + deliveryPhone,
            delivery_email: deliveryEmail,
            description: orderId,
          };

          Httpconfig.httptokenpost(
            "https://api.gokada.ng/api/developer/order_create",
            delivery
          )
            .then((response) => {})
            .catch((error) => {
              toast.error(error);
            });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  /* change order status */

  ChangeOrderStatus = (orderId, status, deliveryAgent, patientEmail) => {
    let checkAttachments =
      this.state.labReportArray != undefined ? this.state.labReportArray : [];
    if (status == 5 && checkAttachments.length == 0) {
      toast.error("Please Select files");
      return false;
    } else if (
      status == 5 &&
      this.state.labReportArray == "[]" &&
      checkAttachments.length == 0
    ) {
      toast.error("Please Select files");
      return false;
    }
    Httpconfig.httptokenput(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/retailerOrderStatus/" +
        orderId,
      { order_status: status, patientEmail: patientEmail }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.dismiss();
          toast.success(response.data.message);
          $(".nav-item,.nav-link").removeClass("active");
          if (status == 1) {
            $("#current_home-tab").addClass("active");
          }
          if (status == 2) {
            $("#prescription_home-tab").addClass("active");
          }
          if (status == 3) {
            $("#processed_home-tab").addClass("active");
            $("#prescription_home").addClass("collapse-hide");
          }
          if (status == 4) {
            $("#shipped_home-tab").addClass("active");
          }
          if (status == 5) {
            $("#deliver_home-tab").addClass("active");
            $("#upload_reports").hide();
            $(".tableFixHead").show();
          }
          if (status == 6) {
            $("#cancel_home-tab").addClass("active");
          }
          this.getAllDashboardDetails(this.state.retailerId);
          this.retailerOrderDetails(this.state.retailerId, status);

          if (deliveryAgent == "Gokada") {
            this.createOrder(orderId, deliveryAgent);
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For addrow
  addRow = (event) => {
    let data = event.currentTarget.id;
    let rawData = data.split("-");
    let rowName = rawData[0];
    let rowId = rawData[1];
    let quantity = $("#medqty-" + rowId).val();
    let vat = $("#medvat-" + rowId).val();
    let dynamicadded = $("#medadded-" + rowId).val();
    dynamicadded = Number(dynamicadded) + 1;
    $("#medadded-" + rowId).val(dynamicadded);
    let medpname = $("#medpname-" + rowId).html();

    const newrow = (
      <tr id={dynamicadded + "#new-" + rowId}>
        <td></td>
        <td class="item_inp">
          <div class="collapse-hide">{medpname}</div>
        </td>

        <td class="batch_inp">
          <select
            class="form-control newrowsselect batches-list"
            id={dynamicadded + "#new~medbatch-" + rowId}
            onClick={this.getBatches.bind(this, "new~medbatch-" + rowId)}
          >
            <option>select batch</option>
          </select>
        </td>
        <td class="oqty_inp">
          <input
            type="hidden"
            class="form-control newrows"
            id={dynamicadded + "#new~medqty-" + rowId}
            defaultValue={quantity}
            readOnly={true}
          />
        </td>
        <td class="aqty_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~medaqty-" + rowId}
            defaultValue="0"
            readOnly="readOnly"
            onBlur={this.caliculateInvoiceAmounts}
          />
        </td>
        <td class="bqty_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~medbqty-" + rowId}
            defaultValue="0"
            onBlur={this.caliculateInvoiceAmounts}
          />
        </td>

        <td class="tax_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~medtaxamt-" + rowId}
            defaultValue="0"
            readOnly={true}
          />
        </td>
        <td class="vat_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~medvat-" + rowId}
            defaultValue={vat}
            onBlur={this.caliculateInvoiceAmounts}
          />
        </td>
        <td class="vatamt_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~medvatamt-" + rowId}
            defaultValue="0"
            readOnly={true}
          />
        </td>
        <td class="mrp_inp">
          <input
            type="text "
            class="form-control newrows"
            id={dynamicadded + "#new~medmrp-" + rowId}
            defaultValue=""
            onBlur={this.caliculateInvoiceAmounts}
          />
        </td>
        <td class="disc_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~meddiscount-" + rowId}
            defaultValue="0.00"
            onBlur={this.caliculateInvoiceAmounts}
          />{" "}
        </td>
        <td class="amount_inp">
          <input
            type="text"
            class="form-control newrows"
            id={dynamicadded + "#new~medamount-" + rowId}
            value="0"
            readOnly={true}
          />
        </td>
        <td class="amounts_inp">
          <img
            class="plus_img removebatch newrows"
            onClick={this.removeBatch}
            src="https://cdn1.iconfinder.com/data/icons/essential-free/48/12-Delete-512.png"
            id={dynamicadded + "#new~medadd-" + rowId}
          />
        </td>
      </tr>
    );

    this.state.newRow = newrow;
    this.forceUpdate();
    let parentTR = $("#" + event.currentTarget.id).closest("tr");
    $("#newRow").children().clone(true, true).insertAfter(parentTR);
  };
  //  For get template row
  getTemplateRow = () => {
    let maxID = 0;
    var x = document.getElementById("templateRow").cloneNode(true);
    x.id = "";
    x.style.display = "";
    x.innerHTML = x.innerHTML.replace(/{​​​​​​​​id}​​​​​​​​/, ++maxID);
    return x;
  };

  orderApproveAgent = (event) => {
    let agentSelect = $(".agent_sel").val();

    if (agentSelect == "") {
      toast.dismiss();
      toast.error("Please Select Agent");
    } else if (this.state.testStatus != "picked-up") {
      toast.dismiss();
      toast.error("Agent pickup pending");
    }
  };
  // Approve order items
  orderApprove = (event) => {
    const addressId = event.currentTarget.name;
    let agentSelect = $(".agent_sel").val();
    toast.dismiss();
    if (agentSelect == "" && this.state.deliveryType == "home_visit") {
      toast.error("Please Select Agent");
    } else {
      let medicine_details = [];
      let rawData = "";
      let medicineId = "";
      let medicineName = "";
      $(".test_name").each(function () {
        var test = $(this).text();
        medicine_details.push(test);
      });
      // $("input[name='medidinesList']:checked").each(function () {
      //   rawData = $(this).attr("id").split("-");
      //   medicineId = rawData[0];
      //   medicineName = rawData[1];
      //   medicine_details.push({
      //     medicine_id: medicineId,
      //     medicine_name: medicineName,
      //   });
      // });
      let data = {
        patient_id: this.state.customerId,
        retailer_id: this.state.retailerId,
        order_id: this.state.orderId,
        medicine_details: medicine_details,
        agentId: this.state.selectAgent,
        agent_status: "1",
      };
      Httpconfig.httptokenpost(
        Constant.siteurl + "api/DIAGNOSIS/orderProcess/updateOrderStatus",
        data
      )
        .then((response) => {
          if ((response.data.status = 200 && !response.data.error)) {
            toast.dismiss();
            toast.success(response.data.message);
            this.state.showOrdersGrid = "row showorder-list collapse-show";
            this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
            this.retailerOrderDetails(this.state.retailerId, 2);
            this.showOrderdetailsInvoice(addressId);
          } else {
            toast.dismiss();
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  // Get the Retailer Details
  getRetailerDetails = (retailerId) => {
    Httpconfig.httptokenget(Constant.siteurl + "api/diagnosis/" + retailerId)
      .then((response) => {
        if ((response.data.status = 200 && !response.data.error)) {
          this.setState({ retailerDetails: response.data.data[0] });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For verfied prescription
  prescriptionVerified = (event) => {
    // console.log("showOrderDetailsProcess...");

    this.state.invoiceDiv = "collapse-show";
    this.state.invoicePDFPreview = "collapse-hide";
    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
    this.state.orderId = event.currentTarget.id;
    this.forceUpdate();
    $(".nav-item, .nav-link").removeClass("active");
    $("#processed_home-tab").addClass("active");
    this.forceUpdate();
    this.state.showOrdersGrid = "row showorder-list collapse-hide";
    this.state.showRejectReasons = "collapse-hide";
    //this.hidegridpendingorders();
    this.state.patientAddressId = event.currentTarget.name;
    this.showOrderdetailsInvoice(this.state.patientAddressId);
  };

  // show order details for Create Invoice process

  showOrderdetailsInvoice = (addressId) => {
    let orderId = "";
    let discount = "";
    let retailerId = this.state.retailerId;
    this.setState({ customerAddressId: addressId });
    let totaldiscount = 0;
    let totalTaxableAmt = 0;
    let subTotal = 0;
    let deliveryCharges = 0;
    orderId = this.state.orderId;
    let customerAddressId = addressId;

    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
        retailerId +
        "/" +
        orderId,
      { customerAddressId }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            const retailerOrderMedicineInvoiceDetailsView =
              response.data.data.map((finalLoadedData, count) => {
                this.state.customerName =
                  finalLoadedData.delivery_address_tbl != null
                    ? finalLoadedData.delivery_address_tbl.name
                        .charAt(0)
                        .toUpperCase() +
                      finalLoadedData.delivery_address_tbl.name.slice(1)
                    : "";
                this.state.customermobile =
                  finalLoadedData.delivery_address_tbl != null
                    ? finalLoadedData.delivery_address_tbl.mobile_no
                    : "";
                this.state.zipcode =
                  finalLoadedData.delivery_address_tbl != null
                    ? finalLoadedData.delivery_address_tbl.zipcode
                    : "";
                this.state.deliveryCharges = finalLoadedData.delivery_charges;
                // this.state.discount = finalLoadedData.discount;
                this.state.discount = finalLoadedData.cart_level_discount;
                this.state.couponDiscountAmount = finalLoadedData.coupon_amount;
                this.state.payment_mode = finalLoadedData.payment_method;
                this.state.coupan_value = finalLoadedData.coupan_value.replace(
                  /%/g,
                  ""
                );
                this.state.agentName =
                  finalLoadedData.agent_registration_tbl != null
                    ? finalLoadedData.agent_registration_tbl.name
                    : "";
                this.state.agentMobile =
                  finalLoadedData.agent_registration_tbl != null
                    ? finalLoadedData.agent_registration_tbl.mobile_number
                    : "";
                this.state.agentEmail =
                  finalLoadedData.agent_registration_tbl != null
                    ? finalLoadedData.agent_registration_tbl.email
                    : "";
                this.state.agentDeliveryType = finalLoadedData.delivery_type;
                this.state.couponDiscountAmount = finalLoadedData.coupon_amount;
                this.state.SampleCollectionCharges =
                  finalLoadedData.delivery_charges;
                if (finalLoadedData.delivery_address_tbl != null) {
                  if (
                    Object.keys(finalLoadedData.delivery_address_tbl).length > 0
                  ) {
                    this.state.customerAddress1 =
                      finalLoadedData.delivery_address_tbl != null
                        ? finalLoadedData.delivery_address_tbl.location
                        : "";
                    this.state.customerAddress2 =
                      finalLoadedData.delivery_address_tbl != null
                        ? finalLoadedData.delivery_address_tbl.address
                        : "";
                    this.state.customerLandmark =
                      finalLoadedData.delivery_address_tbl != null
                        ? finalLoadedData.delivery_address_tbl.landmark
                        : "";
                  } else {
                    this.state.customerAddress1 = "";
                    this.state.customerAddress2 = "";
                    this.state.customerLandmark = "";
                  }
                } else {
                  this.state.customerAddress1 = "";
                  this.state.customerAddress2 = "";
                  this.state.customerLandmark = "";
                }
                this.state.orderId = finalLoadedData.id;
                this.state.patient_id = finalLoadedData.patient_id;
                this.state.orderDate = finalLoadedData.order_date;
                this.state.invoiceDateTime = moment().format(
                  "YYYY-MM-DD HH:mm:ss"
                );

                if (
                  Object.keys(finalLoadedData.diagnosis_registration_tbl)
                    .length > 0
                ) {
                  this.state.labname =
                    finalLoadedData.diagnosis_registration_tbl.labname;
                  // this.state.SampleCollectionCharges = finalLoadedData
                  //   .diagnosis_registration_tbl.set_delivery_slab
                  //   ? finalLoadedData.diagnosis_registration_tbl
                  //       .set_delivery_slab
                  //     ? finalLoadedData.diagnosis_registration_tbl
                  //         .set_delivery_slab
                  //     : 0
                  //   : 0;
                  this.state.storeAddress =
                    finalLoadedData.diagnosis_registration_tbl.address;
                  this.state.storeMobileNumber =
                    finalLoadedData.diagnosis_registration_tbl.mobile_number;
                  this.state.storeregistrationNumber =
                    finalLoadedData.diagnosis_registration_tbl.registration_number;
                } else {
                  this.state.labname = "";
                  this.state.storeAddress = "";
                  this.state.storeMobileNumber = "";
                  this.state.storeregistrationNumber = "";
                }
                this.forceUpdate();

                return (
                  <React.Fragment>
                    {Object.keys(finalLoadedData.order_processing_tbls).length >
                    0
                      ? finalLoadedData.order_processing_tbls.map(
                          (subData, num) => {
                            this.state.itemDiscountper = subData.discount
                              ? subData.discount
                              : 0; //subData.retailer_stock_tbl[0].discount;
                            let afterDicount = this.state.itemDiscountper
                              ? parseFloat(subData.amount) -
                                (subData.amount * this.state.itemDiscountper) /
                                  100
                              : subData.amount;
                            let amounttot =
                              subData.quantity *
                              parseFloat(subData.amount.replace(/,/g, ""));
                            let netbill = amounttot - subData.discount;
                            let GST = 0;
                            let totalGST = 0;
                            let CGST = 0;
                            let SGST = 0;
                            if (
                              this.state.retailerDetails["gst_type"] == "GST"
                            ) {
                              if (subData.SGST != "" && subData.CGST != "")
                                GST =
                                  parseFloat(subData.SGST) +
                                  parseFloat(subData.CGST);
                            } else {
                              if (subData.ISGST != "")
                                GST = parseFloat(subData.IGST);
                            }
                            let taxableamt = netbill / (1 + GST);
                            if (
                              this.state.retailerDetails["gst_type"] == "GST"
                            ) {
                              if (subData.SGST != "" && subData.CGST != "") {
                                CGST = taxableamt * parseFloat(subData.CGST);
                                SGST = taxableamt * parseFloat(subData.SGST);
                                totalGST = CGST + SGST;
                              }
                            } else {
                              totalGST = netbill - taxableamt;
                            }

                            subTotal = subTotal + amounttot;
                            if (subData.discount == null) {
                              subData.discount = 0;
                            }
                            totaldiscount =
                              parseFloat(totaldiscount) +
                              parseFloat(subData.discount);
                            totalTaxableAmt = totalTaxableAmt + taxableamt;
                            this.state.totaldiscount = totaldiscount
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                            this.state.subTotal = subTotal
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                            this.state.totalTaxableAmount = totalTaxableAmt
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                            let totalpaid =
                              this.state.agentDeliveryType == "home_visit"
                                ? Number(this.state.SampleCollectionCharges) +
                                  Number(subTotal)
                                : Number(subTotal);
                            let discount = this.state.discount
                              ? this.state.discount
                              : "0";
                            let couponDiscountAmount = this.state
                              .couponDiscountAmount
                              ? this.state.couponDiscountAmount
                              : "0";

                            totalpaid =
                              totalpaid -
                              Number(discount) -
                              Number(couponDiscountAmount);
                            this.state.totalpaid = totalpaid
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                            this.forceUpdate();
                            this.state.totalTaxes = totalGST
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,");

                            return (
                              <React.Fragment>
                                <tr id={"tr-" + num + 1}>
                                  <td>{num + 1}</td>
                                  <td class="item_inp">
                                    <div
                                      className="test_id"
                                      id={"medpname-" + subData.medicine_id}
                                    >
                                      {" "}
                                      {
                                        subData.diagnosis_products_master_tbl
                                          .testname
                                      }{" "}
                                      <h6>
                                        {
                                          subData.diagnosis_products_master_tbl
                                            .manufacturer
                                        }
                                      </h6>
                                    </div>
                                  </td>

                                  {/* <td class="tax_inp">
                                    <input
                                      type="text"
                                      disabled={true}
                                      class="form-control"
                                      id={"medtaxamt-" + subData.medicine_id}
                                      defaultValue={taxableamt.toFixed(2)}
                                      readOnly={true}
                                    />
                                  </td> */}
                                  {/* {this.state.retailerDetails["gst_type"] ==
                                  "GST" ? (
                                    <React.Fragment>
                                      <td class="cgst_inp">
                                        <input
                                          type="text"
                                          disabled={true}
                                          class="form-control"
                                          id={"medCGST-" + subData.medicine_id}
                                          defaultValue={
                                            subData.CGST != "" &&
                                            subData.SGST != ""
                                              ? parseFloat(subData.CGST)
                                              : "0"
                                          }
                                          onBlur={this.caliculateInvoiceAmounts}
                                        />
                                      </td>
                                      <td class="sgst_inp">
                                        <input
                                          type="text"
                                          disabled={true}
                                          class="form-control"
                                          id={"medSGST-" + subData.medicine_id}
                                          defaultValue={
                                            subData.CGST != "" &&
                                            subData.SGST != ""
                                              ? parseFloat(subData.SGST)
                                              : "0"
                                          }
                                          onBlur={this.caliculateInvoiceAmounts}
                                        />
                                      </td>
                                    </React.Fragment>
                                  ) : (
                                    <td class="igst_inp">
                                      <input
                                        type="text"
                                        disabled={true}
                                        class="form-control"
                                        id={"medIGST-" + subData.medicine_id}
                                        defaultValue={parseFloat(subData.IGST)}
                                        onBlur={this.caliculateInvoiceAmounts}
                                      />
                                    </td>
                                  )} */}

                                  {/* <td class="vatamt_inp">
                                    <input
                                      type="text"
                                      disabled={true}
                                      class="form-control"
                                      id={"medvatamt-" + subData.medicine_id}
                                      defaultValue={totalGST.toFixed(2)}
                                      readOnly={true}
                                    />
                                  </td> */}
                                  <td class="mrp_inp">
                                    <input
                                      type="text"
                                      disabled={true}
                                      class="form-control"
                                      id={"medmrp-" + subData.medicine_id}
                                      defaultValue={subData.amount}
                                      readOnly={true}
                                      onBlur={this.caliculateInvoiceAmounts}
                                    />
                                  </td>
                                  <td class="disc_inp">
                                    <input
                                      type="text"
                                      disabled={true}
                                      class="form-control"
                                      id={"meddiscount-" + subData.medicine_id}
                                      defaultValue={
                                        this.state.itemDiscountper
                                          ? this.state.itemDiscountper
                                          : "0.00"
                                      }
                                      onBlur={this.caliculateInvoiceAmounts}
                                    />
                                  </td>
                                  <td class="amount_inp">
                                    <input
                                      type="text"
                                      disabled={true}
                                      class="form-control"
                                      id={"medamount-" + subData.medicine_id}
                                      value={parseFloat(afterDicount)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                      readOnly={true}
                                    />
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                      : ""}
                  </React.Fragment>
                );
              });
            this.state.retailerOrderMedicineInvoiceDetailsView = [];
            this.setState({
              retailerOrderMedicineInvoiceDetailsView:
                retailerOrderMedicineInvoiceDetailsView,
            });

            this.forceUpdate();
            this.getTotals();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // line wide and column wide

  caliculateInvoiceAmounts = (event) => {
    let data = event.currentTarget.id;
    let rawData = data.split("-");
    let rowName = rawData[0];
    let rowId = rawData[1];
    let subTotal = 0;
    let totaldiscount = 0;
    let totalTaxableAmt = 0;
    let search = data.search("~");
    let mrp = "";
    let billedQty = "";
    let taxamount = "";
    let medvat = "";
    let medvatamt = "";
    let meddiscount = "";
    let medamount = "";
    let availableQty = "";

    if (search > 0) {
      mrp = document.getElementById("new~medmrp-" + rowId).value;
      billedQty = document.getElementById("new~medbqty-" + rowId).value;
      taxamount = document.getElementById("new~medtaxamt-" + rowId).value;
      if (this.state.retailerDetails["gst_type"] == "GST") {
        medvat =
          parseFloat(document.getElementById("new~medCGST-" + rowId).value) +
          parseFloat(document.getElementById("new~medSGST-" + rowId).value);
      } else {
        medvat = parseFloat(
          document.getElementById("new~medIGST-" + rowId).value
        );
      }
      medvatamt = document.getElementById("new~medvatamt-" + rowId).value;
      meddiscount = document.getElementById("new~meddiscount-" + rowId).value;
      medamount = document.getElementById("new~medamount-" + rowId).value;
      availableQty = document.getElementById("new~medaqty-" + rowId).value;
      if (parseInt(billedQty) > parseInt(availableQty)) {
        toast.error(
          "Billed Quantity should not greater than Available Quantity"
        );
        return false;
      }
    } else {
      mrp = document.getElementById("medmrp-" + rowId).value;
      billedQty = document.getElementById("medbqty-" + rowId).value;
      taxamount = document.getElementById("medtaxamt-" + rowId).value;
      if (this.state.retailerDetails["gst_type"] == "GST") {
        medvat =
          parseFloat(document.getElementById("medCGST-" + rowId).value) +
          parseFloat(document.getElementById("medSGST-" + rowId).value);
      } else {
        medvat = parseFloat(document.getElementById("medIGST-" + rowId).value);
      }
      medvatamt = document.getElementById("medvatamt-" + rowId).value;
      meddiscount = document.getElementById("meddiscount-" + rowId).value;
      medamount = document.getElementById("medamount-" + rowId).value;
      availableQty = document.getElementById("medaqty-" + rowId).value;
      if (parseInt(billedQty) > parseInt(availableQty)) {
        toast.error(
          "Billed Quantity should not greater than Available Quantity"
        );
        return false;
      }
    }

    let amt = mrp * billedQty;

    totaldiscount = parseInt((amt * meddiscount) / 100);
    let netbill = amt - parseInt((amt * meddiscount) / 100);
    let taxableamt = netbill / (1 + medvat / 100);
    let vatamount = netbill - taxableamt;
    let amounttot = netbill;
    subTotal = subTotal + netbill;

    this.state.totaldiscount = totaldiscount
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");

    if (search > 0) {
      document.getElementById("new~medtaxamt-" + rowId).value =
        vatamount.toFixed(2);
      document.getElementById("new~medvatamt-" + rowId).value =
        taxableamt.toFixed(2);
      document.getElementById("new~medamount-" + rowId).value =
        amounttot.toFixed(2);
    } else {
      document.getElementById("medtaxamt-" + rowId).value =
        vatamount.toFixed(2);
      document.getElementById("medvatamt-" + rowId).value =
        taxableamt.toFixed(2);
      document.getElementById("medamount-" + rowId).value =
        amounttot.toFixed(2);
    }
    this.getTotals();
    this.forceUpdate();
  };

  //caliculate dynamic values
  caliculateInvoiceAmountsNewRows = (id) => {
    let data = id;
    let rawData = data.split("-");
    let rowName = rawData[0];
    let rowId = rawData[1];
    let subTotal = 0;
    let totaldiscount = 0;
    let totalTaxableAmt = 0;
    let search = data.search("~");
    let mrp = "";
    let billedQty = "";
    let taxamount = "";
    let medvat = "";
    let medvatamt = "";
    let meddiscount = "";
    let medamount = "";
    let discountCaliculation = "";
    let availableQty = "0";

    if (search > 0) {
      let batchRow = rowName[0].split("#");
      let batchRowId = batchRow[0] + "#";
      meddiscount = document.getElementById(
        batchRowId + "new~meddiscount-" + rowId
      ).value;
      discountCaliculation =
        (document.getElementById("medmrp-" + rowId).value * meddiscount) / 100;
      mrp = document.getElementById(batchRowId + "new~medmrp-" + rowId).value;
      billedQty = document.getElementById(
        batchRowId + "new~medbqty-" + rowId
      ).value;
      taxamount = document.getElementById(
        batchRowId + "new~medtaxamt-" + rowId
      ).value;
      if (this.state.retailerDetails["gst_type"] == "GST") {
        medvat =
          parseFloat(
            document.getElementById(batchRowId + "new~medCGST-" + rowId).value
          ) +
          parseFloat(
            document.getElementById(batchRowId + "new~medSGST-" + rowId).value
          );
      } else {
        medvat = parseFloat(
          document.getElementById(batchRowId + "new~medIGST-" + rowId).value
        );
      }
      medvatamt = document.getElementById(
        batchRowId + "new~medvatamt-" + rowId
      ).value;
      medamount = document.getElementById(
        batchRowId + "new~medamount-" + rowId
      ).value;
      availableQty = document.getElementById(
        batchRowId + "new~medaqty-" + rowId
      ).value;
      if (parseInt(billedQty) > parseInt(availableQty)) {
        toast.error(
          "Billed Quantity should not greater than Available Quantity"
        );
        return false;
      }
    } else {
      meddiscount = document.getElementById("meddiscount-" + rowId).value;
      mrp = document.getElementById("medmrp-" + rowId).value;
      billedQty = document.getElementById("medbqty-" + rowId).value;
      taxamount = document.getElementById("medtaxamt-" + rowId).value;
      if (this.state.retailerDetails["gst_type"] == "GST") {
        medvat =
          parseFloat(document.getElementById("medCGST-" + rowId).value) +
          parseFloat(document.getElementById("medSGST-" + rowId).value);
      } else {
        medvat = parseFloat(document.getElementById("medIGST-" + rowId).value);
      }
      medvatamt = document.getElementById("medvatamt-" + rowId).value;

      medamount = document.getElementById("medamount-" + rowId).value;
      availableQty = document.getElementById("medaqty-" + rowId).value;
      if (parseInt(billedQty) > parseInt(availableQty)) {
        toast.error(
          "Billed Quantity should not greater than Available Quantity"
        );
        return false;
      }
    }

    let amt = mrp * billedQty;

    totaldiscount = parseFloat((amt * meddiscount) / 100);
    let netbill = parseFloat(amt) - parseFloat((amt * meddiscount) / 100);
    let taxableamt = parseFloat(netbill) / (1 + medvat / 100);
    let vatamount = parseFloat(netbill) - parseFloat(taxableamt);
    let amounttot = netbill;
    subTotal = parseFloat(subTotal) + parseFloat(netbill);

    this.state.totaldiscount = totaldiscount
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");

    if (search > 0) {
      let batchRow = rowName[0].split("#");
      let batchRowId = batchRow[0] + "#";
      document.getElementById(batchRowId + "new~medtaxamt-" + rowId).value =
        vatamount.toFixed(2);
      document.getElementById(batchRowId + "new~medvatamt-" + rowId).value =
        taxableamt.toFixed(2);
      document.getElementById(batchRowId + "new~medamount-" + rowId).value =
        amounttot.toFixed(2);
    } else {
      document.getElementById("medtaxamt-" + rowId).value =
        vatamount.toFixed(2);
      document.getElementById("medvatamt-" + rowId).value =
        taxableamt.toFixed(2);
      document.getElementById("medamount-" + rowId).value =
        amounttot.toFixed(2);
    }
    this.getTotals();
    this.forceUpdate();
  };

  // get totals

  //  For preview Invoice
  previewInvoice = () => {
    let productName = "";
    let manafacturer = "";
    let batchExpiry = "";
    let mrp = "";
    let oqty = "";
    let aqty = "";
    let bqty = "";
    let taxableAmount = "";
    let vat = "";
    let vatAmount = "";
    let discountAmount = "";
    var lineAmounts = "";
    let invoice = "";
    let invoiceData = "";
    let batch = "";
    let expiredate = "";
    let medicinestockid = "";
    let medicineId = "";
    let Invoicearray = [];
    let error = 0;

    $("#theTable > tbody >tr").each(function (row, tr) {
      $(this)
        .find("td")
        .each(function () {
          let className = $(this).attr("class");

          if (className == "item_inp") {
            productName = $(this).text();

            manafacturer = "";
            let search = productName.search("<h6>");
            if (search > 0) {
              productName = productName
                .replace("</h6>", "")
                .replace("<h6>", "")
                .replace("&amp;", "&");
            }
          }
          if (className == "mrp_inp") {
            mrp = $(this).find(".form-control").val();
          }

          if (className == "oqty_inp") {
            oqty = $(this).find(".form-control").val();
          }
          if (className == "aqty_inp") {
            aqty = $(this).find(".form-control").val();
          }
          if (className == "bqty_inp") {
            bqty = $(this).find(".form-control").val();
          }
          if (className == "tax_inp") {
            taxableAmount = $(this).find(".form-control").val();
          }
          if (className == "vat_inp") {
            vat = $(this).find(".form-control").val();
          }
          if (className == "vatamt_inp") {
            vatAmount = $(this).find(".form-control").val();
          }
          if (className == "disc_inp") {
            discountAmount = $(this).find(".form-control").val();
          }
          if (className == "amount_inp") {
            lineAmounts = $(this).find(".form-control").val();
          }
          if (className == "bqty_inp") {
            if (parseInt(bqty) > parseInt(aqty)) {
              toast.error(
                "Billed Quantity should not greater than Available Quantity"
              );
              error = error + 1;
            }
          }
        });
      invoiceData = {
        medicineId: medicineId,
        medicinestockid: medicinestockid,
        productName: productName,
        manafacturer: manafacturer,
        batchExpiry: batchExpiry,
        batch: batch,
        expiredate: expiredate,
        mrp: mrp,
        oqty: oqty,
        bqty: bqty,
        taxableAmount: taxableAmount,
        vat: vat,
        vatAmount: vatAmount,
        discountAmount: discountAmount,
        lineAmount: lineAmounts,
      };

      Invoicearray.push(invoiceData);
    });
    if (error > 0) {
      return false;
    }

    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
    this.state.showOrdersGrid = "row showorder-list collapse-hide";
    this.state.showRejectReasons = "collapse-hide";
    this.state.invoiceDiv = "collapse-hide";
    this.state.invoicePDFPreview = "collapse-show";
    this.state.invoiceRows = Invoicearray;
    this.forceUpdate();
  };
  //  For Show Invoice page
  showInvoicePage = () => {
    this.state.showOrderDetailsProcess = "pharmacy-tab collapse-hide";
    this.state.showOrdersGrid = "row showorder-list collapse-hide";
    this.state.showRejectReasons = "collapse-hide";
    this.state.invoiceDiv = "collapse-show";
    this.state.invoicePDFPreview = "collapse-hide";
    this.forceUpdate();
  };

  //sending prescription request
  sendPrescriptionReq = () => {
    toast.dismiss();
    toast.success("Email Sending.....");
    let emailSub = "Prescription request";

    let messageBody = "";
    messageBody += "<p>Hello " + this.state.customerName + ",</p>";
    messageBody +=
      "<p>" + this.state.retailerName + " ask you a prescription</p>";
    messageBody +=
      "<p>We request you to please upload a valid prescription from the link below to help us process your order. for the Order number :" +
      this.state.orderId +
      "</p><br><br>";
    messageBody +=
      "<a href='" +
      Constant.imgurl +
      "/myOrders'>" +
      Constant.imgurl +
      "/myOrders</a>";
    messageBody += "<p>Thanks,<br>Remotel Team</p>";

    let patientSMSMessageBody = `Hello ${this.state.customerName},We request you to please upload a valid prescription to help us process your order no ${this.state.orderId}.Regards, Team MedleyMed`;
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/sendPrescriptionReq",
      {
        customerEmail: this.state.custmerEmail,
        emailSub: emailSub,
        emailBody: messageBody,
        customerMobile: this.state.customerMobileNumber,
        messageBody: patientSMSMessageBody,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.dismiss();
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  processOrder = () => {
    let productName = "";
    let manafacturer = "";
    let batchExpiry = "";
    let mrp = "";
    let oqty = "";
    let aqty = "";
    let bqty = "";
    let taxableAmount = "";
    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    let vatAmount = "";
    let discountAmount = "";
    var lineAmounts = "";
    let invoice = "";
    let invoiceData = "";
    let batch = "";
    let test_id = "";
    let expiredate = "";
    let medicinestockid = "";
    let medicineId = "";
    let Invoicearray = [];
    let error = 0;
    let gstType = this.state.retailerDetails["gst_type"];
    let deliveryAgentCheckedCount = $(
      'input[name="cash_collected"]:checked'
    ).length;
    if (
      this.state.agentDeliveryType == "center_visit" &&
      this.state.payment_mode == "COD"
    ) {
      if (deliveryAgentCheckedCount == 0) {
        toast.error("Confirm Cash Colleted?");
        return false;
      }
    }
    $("#theTable > tbody >tr").each(function (row, tr) {
      $(this)
        .find("td")
        .each(function () {
          let className = $(this).attr("class");
          if (className == "item_inp") {
            productName = $(this).text();
            let testID = $(this).find(".test_id").attr("id");
            test_id = testID.split("-")[1];
            manafacturer = "";
            let search = productName.search("<h6>");
            if (search > 0) {
              productName = productName
                .replace("</h6>", "")
                .replace("<h6>", "")
                .replace("&amp;", "&");
            }
          }
          if (className == "mrp_inp") {
            mrp = $(this).find(".form-control").val();
          }
          if (className == "oqty_inp") {
            oqty = $(this).find(".form-control").val();
          }
          if (className == "aqty_inp") {
            aqty = $(this).find(".form-control").val();
          }
          if (className == "bqty_inp") {
            bqty = $(this).find(".form-control").val();
          }
          if (className == "tax_inp") {
            taxableAmount = $(this).find(".form-control").val();
          }
          if (gstType == "GST") {
            igst = 0;
            if (className == "cgst_inp") {
              cgst = $(this).find(".form-control").val();
            }
            if (className == "sgst_inp") {
              sgst = $(this).find(".form-control").val();
            }
          } else {
            cgst = 0;
            sgst = 0;
            if (className == "igst_inp") {
              igst = $(this).find(".form-control").val();
            }
          }
          if (className == "vatamt_inp") {
            vatAmount = $(this).find(".form-control").val();
          }
          if (className == "disc_inp") {
            discountAmount = $(this).find(".form-control").val();
          }
          if (className == "amount_inp") {
            lineAmounts = $(this).find(".form-control").val();
          }
          if (className == "bqty_inp") {
            if (parseInt(bqty) > parseInt(aqty)) {
              toast.error(
                "Billed Quantity should not greater than Available Quantity"
              );
              error = error + 1;
            }
          }
        });

      invoiceData = {
        medicineId: test_id,
        medicinestockid: medicinestockid,
        productName: productName,
        manafacturer: manafacturer,
        batchExpiry: batchExpiry,
        batch: batch,
        expiredate: expiredate,
        mrp: mrp,
        oqty: "1",
        bqty: "1",
        taxableAmount: taxableAmount,
        CGST: cgst,
        SGST: sgst,
        IGST: igst,
        vatAmount: vatAmount,
        discountAmount: discountAmount,
        lineAmount: lineAmounts,
        vat: 0,
      };

      Invoicearray.push(invoiceData);
    });

    if (error > 0) {
      return false;
    }
    this.state.invoiceRows = Invoicearray;
    this.forceUpdate();
    let invoice_details = [];
    let stk_details = [];
    this.state.invoiceRows.map((itemsdata, num) => {
      let inv_detail = {
        retailer_id: this.state.retailerId,
        order_id: this.state.orderId,
        patient_id: this.state.patient_id,
        order_date: moment(this.state.orderDate).format("YYYY/MM/DD HH:mm:ss"),
        invoice_date: moment(this.state.invoiceDateTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        medicine_id: itemsdata.medicineId,
        batch: itemsdata.batch,
        expiry: itemsdata.expiredate,
        mrp: itemsdata.mrp.replace(",", ""),
        quantity: itemsdata.bqty,
        taxable_rate: itemsdata.taxableAmount.replace(",", ""),
        CGST: cgst,
        SGST: sgst,
        IGST: igst,
        discount: itemsdata.discountAmount.replace(",", ""),
        amount: itemsdata.lineAmount.replace(",", ""),
        vat: 0,
      };
      invoice_details.push(inv_detail);
    });
    this.state.invoiceRows.map((itemsdata, num) => {
      let stock_detail = {
        retailer_id: this.state.retailerId,
        order_id: this.state.orderId,
        patient_id: this.state.customerId,
        order_date: moment(this.state.orderDate).format("YYYY/MM/DD HH:mm:ss"),
        invoice_date: moment(this.state.invoiceDateTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        medicine_id: itemsdata.medicineId,
        batch: itemsdata.batch,
        expiry: itemsdata.expiredate,
        mrp: itemsdata.mrp.replace(",", ""),
        quantity: itemsdata.bqty,
        taxable_rate: itemsdata.taxableAmount.replace(",", ""),
        CGST: cgst,
        SGST: sgst,
        IGST: igst,
        discount: itemsdata.discountAmount.replace(",", ""),
        amount: itemsdata.lineAmount.replace(",", ""),
        id: itemsdata.medicinestockid,
      };
      stk_details.push(stock_detail);
    });
    let data = {
      retailer_id: this.state.retailerId,
      order_id: this.state.orderId,
      patient_id: this.state.customerId,
      order_date: moment(this.state.orderDate).format("YYYY/MM/DD HH:mm:ss"),
      invoice_date: moment(this.state.invoiceDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      payment_mode: this.state.payment_mode,
      total_taxable_amount: this.state.totalTaxableAmount,
      total_taxes: this.state.totalTaxes,
      sub_total: this.state.subTotal,
      discount: this.state.totaldiscount,
      total_paid: this.state.totalpaid,
      invoice_details: invoice_details,
      stock_details: stk_details,
      delivery_agent: this.state.deliveryAgent,
    };
    this.setState({ invoiceBtn: false });
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderInvoice/",
      data
    )
      .then((response) => {
        if (response.invoiceId != "") {
          this.generatePDF();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  generatePDF = () => {
    let retailerId = this.state.retailerId;
    let orderId = this.state.orderId;
    let customerAddressId = this.state.customerAddressId;
    let timeZone = moment.tz.guess();

    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderInvoice/generatePDF/" +
        retailerId +
        "/" +
        orderId,
      { timeZone: timeZone, customerAddressId }
    )
      .then((response) => {
        if (response.status == 200) {
          this.showgridpendingorders();
          $(".nav-item ,.nav-link").removeClass("active");
          //$("#processed_home-tab").addClass("show active");
          $("#processe_home").addClass("show active");
          //$("#processe_home").addClass("show active");
          $("#prescription_home").addClass("collapse-hide");

          this.retailerOrderDetails(this.state.retailerId, 3);
          this.setState({ invoiceBtn: true });
          toast.success("Invoice is generated successfully");
          localStorage.setItem("pdfgenerate", 1);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // checkdelivery = (event) => {
  //   let id = event.currentTarget.id;
  //   if (id == "retailerAgent") {
  //     if ($("#" + id).is(":checked") == true)
  //       $("#Gokada").prop("checked", false);
  //   } else {
  //     if ($("#" + id).is(":checked") == true)
  //       $("#retailerAgent").prop("checked", false);
  //   }

  //   this.state.deliveryAgent = id;
  //   console.log("--2492--", this.state.deliveryAgent);
  // };

  render() {
    return (
      <main id="main">
        <DiagnosisHeader />
        <DiagnosisMenu />
        <section id="Pharmacy_dashboard">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-10 col-md-10 Pharma_Content">
                <section id="pharm_tabs" class="pharmacy-tab">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-6 col-md-12 p_schedule ">
                        <label>Select Date</label>
                        <div class="manage_stck_form">
                          <div class="form-group">
                            <input
                              type="date"
                              name="startDate"
                              id="start_date"
                              class="form-control"
                              max={moment().format("YYYY-MM-DD")}
                              onChange={this.startDate.bind(this, "startDate")}
                            />

                            <p>To</p>
                            <input
                              type="date"
                              id="end_date"
                              name="endDate"
                              class="form-control"
                              max={moment().format("YYYY-MM-DD")}
                              onChange={this.endDate.bind(this, "endDate")}
                            />
                          </div>
                          <div
                            class="rgt_arrow_btn"
                            onClick={this.showOrderbyDates}
                          >
                            <p>find</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <nav class="p_nav">
                          <div
                            class="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              class={this.state.currentHome}
                              id="current_home-tab"
                              data-toggle="tab"
                              href="#current_home"
                              role="tab"
                              aria-controls="current-home"
                              aria-selected="true"
                              onClick={this.handleClick}
                            >
                              Current<h6>(Pending)</h6>
                              <p>{this.state.pendingOrder}</p>
                            </a>
                            <a
                              class={this.state.prescriptionHome}
                              id="prescription_home-tab"
                              data-toggle="tab"
                              href="#prescription_home"
                              role="tab"
                              aria-controls="prescription-home"
                              aria-selected="true"
                              onClick={this.handleClick}
                            >
                              Samples <h6>(Collection)</h6>
                              <p>{this.state.PrescriptionVerifiedOrder}</p>
                            </a>
                            <a
                              class={this.state.processedHome}
                              id="processed_home-tab"
                              data-toggle="tab"
                              href="#processed_home"
                              role="tab"
                              aria-controls="processed-home"
                              aria-selected="true"
                              onClick={this.handleClick}
                            >
                              Processed <h6> (Invoiced) </h6>
                              <p>{this.state.processingOrder}</p>
                            </a>
                            <a
                              class={this.state.shippedHome}
                              id="shipped_home-tab"
                              data-toggle="tab"
                              href="#shipped_home"
                              role="tab"
                              aria-controls="shipped-home"
                              aria-selected="true"
                              onClick={this.handleClick}
                            >
                              Upload <h6>(Reports)</h6>
                              <p>{this.state.shippedOrder}</p>
                            </a>
                            <a
                              class={this.state.deliverHome}
                              id="deliver_home-tab"
                              data-toggle="tab"
                              href="#deliver_home"
                              role="tab"
                              aria-controls="deliver_home"
                              aria-selected="false"
                              onClick={this.handleClick}
                            >
                              Completed<h6></h6>
                              <p>{this.state.deliveredOrder}</p>
                            </a>
                            <a
                              class={this.state.cancelHome}
                              id="cancel_home-tab"
                              data-toggle="tab"
                              href="#cancel_home"
                              role="tab"
                              aria-controls="cancel_home"
                              aria-selected="false"
                              onClick={this.handleClick}
                            >
                              Cancelled <p>{this.state.cancelledOrder}</p>
                            </a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div class={this.state.showOrdersGrid}>
                      <div class="col-md-12 no_padding">
                        <div
                          class="container"
                          id="upload_reports"
                          style={{ display: "none" }}
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <section id="upload_tests">
                                <div class="upload_field">
                                  {this.state.reportOrderStatus == 5 ? (
                                    <h3>Reports</h3>
                                  ) : (
                                    ""
                                  )}
                                  <p>Order ID : {this.state.UploadId}</p>
                                  {this.state.reportOrderStatus == 4 ? (
                                    <form onSubmit={this.onFormSubmit}>
                                      <div class="form-group">
                                        <div class="custom-file-upload">
                                          <input
                                            type="file"
                                            name="myImage"
                                            accept="image/jpeg,image/png,application/pdf"
                                            id="file-upload"
                                            data-max-size="500000"
                                            onChange={this.onChange}
                                            className="form-control"
                                            multiple
                                          />
                                        </div>

                                        <button
                                          type="submit"
                                          className="sub_btn"
                                        >
                                          Upload
                                        </button>
                                      </div>
                                    </form>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.reportOrderStatus == 4 ? (
                                    <div id="fileList"></div>
                                  ) : (
                                    ""
                                  )}
                                  <div id="upload_prev"></div>
                                </div>
                                <div class="presc_upload">
                                  <div class="row">
                                    <div class="col-md-12">
                                      {this.state.orderDetails}
                                      <p className="noted_text">
                                        Note : File size should be less than 5Mb
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {this.state.reportOrderStatus == 4 ? (
                                  <div class="row">
                                    <div class="upload_secc">
                                      <button
                                        type="button"
                                        class="btn btn-success"
                                        id="upload_btn_complete"
                                      >
                                        <a
                                          id={this.state.uploadOrderID}
                                          onClick={this.ChangeOrderStatus.bind(
                                            this,
                                            this.state.uploadOrderID,
                                            "5",
                                            "",
                                            this.state.patientEmail
                                          )}
                                        >
                                          Completed
                                        </a>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </section>
                              {/* {this.state.uploadDeliveryType ==
                                "center_visit" &&
                              this.state.uploadPyamentMode == "COD" ? (
                                <input
                                  type="checkbox"
                                  name="cash_collected"
                                  id="cash_collected_box"
                                  value="1"
                                />
                              ) : (
                                ""
                              )}
                              {this.state.uploadDeliveryType ==
                                "center_visit" &&
                              this.state.uploadPyamentMode == "COD" ? (
                                <span>Cash Collected</span>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </div>
                        </div>
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class={this.state.currentHomePanel}
                            id="current_home1"
                            role="tabpanel"
                            aria-labelledby="current_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead>
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date & Time</th>
                                    <th>Slot Date & Time</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Invoice</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state
                                    .retailerFinalOrderMedicineDetailsView ? (
                                    this.state
                                      .retailerFinalOrderMedicineDetailsView
                                  ) : (
                                    <React.Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No Orders</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>{" "}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class={this.state.prescriptionHomePanel}
                            id="prescription_home"
                            role="tabpanel"
                            aria-labelledby="prescription_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead>
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Slot Date & Time</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Invoice</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state
                                    .retailerFinalOrderMedicineDetailsView ? (
                                    this.state
                                      .retailerFinalOrderMedicineDetailsView
                                  ) : (
                                    <React.Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No Orders</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>{" "}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class={this.state.processedHomePanel}
                            id="processe_home"
                            role="tabpanel"
                            aria-labelledby="processed_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead>
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Slot Date & Time</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Invoice</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state
                                    .retailerFinalOrderMedicineDetailsView ? (
                                    this.state
                                      .retailerFinalOrderMedicineDetailsView
                                  ) : (
                                    <React.Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No Orders</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>{" "}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class={this.state.shippedHomePanel}
                            id="shipped_home"
                            role="tabpanel"
                            aria-labelledby="shipped_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead>
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Slot Date & Time</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Invoice</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state
                                    .retailerFinalOrderMedicineDetailsView ? (
                                    this.state
                                      .retailerFinalOrderMedicineDetailsView
                                  ) : (
                                    <React.Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No Orders</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>{" "}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div
                            class={this.state.deliverHomePanel}
                            id="deliver_home"
                            role="tabpanel"
                            aria-labelledby="deliver_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead>
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Slot Date & Time</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Invoice & Reports</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state
                                    .retailerFinalOrderMedicineDetailsView ? (
                                    this.state
                                      .retailerFinalOrderMedicineDetailsView
                                  ) : (
                                    <React.Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No Orders</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>{" "}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class={this.state.cancelHomePanel}
                            id="cancel_home"
                            role="tabpanel"
                            aria-labelledby="cancel_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead>
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Slot Date & Time</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Invoice</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state
                                    .retailerFinalOrderMedicineDetailsView ? (
                                    this.state
                                      .retailerFinalOrderMedicineDetailsView
                                  ) : (
                                    <React.Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No Orders</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>{" "}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  id="pharm_tabs"
                  class={this.state.showOrderDetailsProcess}
                >
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class={this.state.currentHomePanel}
                            id="current_home"
                            role="tabpanel"
                            aria-labelledby="current_home-tab"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <div class="row">
                                  <div class="col-md-12">
                                    <a onClick={this.back}>
                                      {" "}
                                      <h2 class="order_back">
                                        <img src="../images/retailer/back_arrow.svg" />
                                        Back to Order's List
                                      </h2>
                                    </a>
                                  </div>
                                  {this.state.delivery_type !=
                                  "center_visit" ? (
                                    <div class="col-md-8">
                                      <div class="agent_details">
                                        <label>Sample Collection Agent</label>
                                        <select
                                          name="agent_name"
                                          class="form-control agent_sel"
                                          value={
                                            this.state.fields["agent_name"]
                                          }
                                          disabled={
                                            this.state.testStatus == "picked-up"
                                          }
                                          onChange={(e) => {
                                            this.state.selectAgent =
                                              e.target.value;
                                          }}
                                        >
                                          <option value="">
                                            Select Agents
                                          </option>
                                          {this.state.DiagnosisAgents
                                            ? this.state.DiagnosisAgents.map(
                                                (agentList, i) => {
                                                  if (
                                                    agentList.is_active == 0
                                                  ) {
                                                    return (
                                                      <option
                                                        value={agentList.id}
                                                        selected={
                                                          agentList.id ==
                                                          this.state.agentID
                                                        }
                                                      >
                                                        {agentList.name +
                                                          "-" +
                                                          agentList.mobile_number}
                                                      </option>
                                                    );
                                                  }
                                                }
                                              )
                                            : ""}
                                        </select>

                                        {/* {this.state.testStatus !=
                                          "picked-up" ? (
                                          <button
                                            type="button"
                                            id={this.state.orderId}
                                            onClick={this.handleChange.bind(
                                              this
                                            )}
                                            class="assign_btn"
                                          >
                                            Assign
                                          </button>
                                        ) : (
                                          ""
                                        )} */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="col-md-12">
                                      <div class="visit_status">
                                        <p>
                                          Visit Status : <span>Lab Visit </span>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div class="Prescription_bar">
                                  <div class="presc_head">
                                    <h2>Prescription</h2>
                                  </div>
                                  <a>
                                    {" "}
                                    <p
                                      class="send_rqst_btn"
                                      onClick={this.sendPrescriptionReq}
                                    >
                                      Send Request
                                    </p>
                                  </a>
                                  <h6>
                                    Send a request to the customer to upload the
                                    Prescription
                                  </h6>
                                  <div class="prescription_img">
                                    {this.state.retailerPrescriptionDetailsView
                                      ? this.state
                                          .retailerPrescriptionDetailsView
                                      : "No Prescription Uploaded"}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="tableFixHead_Order">
                                  <table>
                                    <thead class="order_table">
                                      <tr>
                                        <th>
                                          {this.state.customerName}{" "}
                                          <h6> {this.state.customermobile}</h6>
                                        </th>
                                        <th>
                                          Order ID:{" "}
                                          <span>{this.state.orderId}</span>
                                        </th>
                                        <th>
                                          Items :
                                          <span>{this.state.itemsCount}</span>
                                        </th>
                                        <th>
                                          Price:{" "}
                                          <span>
                                            {Constant.currency}{" "}
                                            {this.state.amount}
                                          </span>
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>{this.state.ordereditems}</tbody>
                                  </table>
                                  <div class="row pres_btns">
                                    <div class="col-md-6">
                                      <div class="rej_btn">
                                        <a onClick={this.rejectOrderShow}>
                                          <img src="../images/retailer/close_reject.svg" />
                                          Reject
                                        </a>
                                      </div>
                                    </div>
                                    {/* {this.state.testStatus == "picked-up" &&
                                      this.state.deliveryType == "home_visit" ? ( */}
                                    <div class="col-md-6 appr_home">
                                      <div class="app_btn">
                                        <a
                                          name={this.state.customerAddressId}
                                          onClick={this.orderApprove}
                                        >
                                          <img src="../images/retailer/tick_approved.svg" />
                                          Approve
                                        </a>
                                      </div>
                                    </div>
                                    {/* ) : this.state.deliveryType ==
                                      "center_visit" ? (
                                      <div class="col-md-6">
                                        <div class="app_btn">
                                          <a
                                            name={this.state.customerAddressId}
                                            onClick={this.orderApprove}
                                          > */}
                                    {/* <img src="../images/retailer/tick_approved.svg" />
                                            Approve
                                          </a>
                                        </div>
                                      </div>
                                    ) : (
                                      <div class="col-md-6">
                                        <div class="app_btn">
                                          <a
                                            name={this.state.customerAddressId}
                                            onClick={this.orderApproveAgent}
                                          >
                                            <img src="../images/retailer/tick_approved.svg" />
                                            Approve
                                          </a>
                                        </div>
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class={this.state.deliverHomePanel}
                            id="deliver_home"
                            role="tabpanel"
                            aria-labelledby="deliver_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead class="order_table">
                                  <tr>
                                    <th>NO</th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>invoiceData</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      John <h6>9876542587</h6>
                                    </td>
                                    <td>0070859</td>
                                    <td>20-05-2020</td>
                                    <td>
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                    </td>
                                    <td>8</td>
                                    <td>{Constant.currency} 890</td>
                                    <td class="pending_td">Delivered </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>
                                      John <h6>9876542587</h6>
                                    </td>
                                    <td>0070859</td>
                                    <td>20-05-2020</td>
                                    <td>
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                    </td>
                                    <td>8</td>
                                    <td>{Constant.currency} 890</td>
                                    <td class="pending_td">Delivered </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>
                                      John <h6>9876542587</h6>
                                    </td>
                                    <td>0070859</td>
                                    <td>20-05-2020</td>
                                    <td>
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                    </td>
                                    <td>8</td>
                                    <td>{Constant.currency} 890</td>
                                    <td class="pending_td">Delivered </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class={this.state.cancelHomePanel}
                            id="cancel_home"
                            role="tabpanel"
                            aria-labelledby="cancel_home-tab"
                          >
                            <div class="tableFixHead">
                              <table>
                                <thead class="order_table">
                                  <tr>
                                    <th></th>
                                    <th>Customer</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Prescription</th>
                                    <th>Items</th>
                                    <th>Amount</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      John <h6>9876542587</h6>
                                    </td>
                                    <td>0070859</td>
                                    <td>20-05-2020</td>
                                    <td>
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                    </td>
                                    <td>8</td>
                                    <td>{Constant.currency} 890</td>
                                    <td class="pending_td">Cancelled </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>
                                      John <h6>9876542587</h6>
                                    </td>
                                    <td>0070859</td>
                                    <td>20-05-2020</td>
                                    <td>
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                    </td>
                                    <td>8</td>
                                    <td>{Constant.currency} 890</td>
                                    <td class="pending_td">Cancelled </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>
                                      John <h6>9876542587</h6>
                                    </td>
                                    <td>0070859</td>
                                    <td>20-05-2020</td>
                                    <td>
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                      <img
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Invoice"
                                        class="presc_img"
                                        src="../images/retailer/RxFile.svg"
                                      />
                                    </td>
                                    <td>8</td>
                                    <td>{Constant.currency} 890</td>
                                    <td class="pending_td">Cancelled </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  id="reject_reason"
                  class={this.state.showRejectReasons}
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="reject_back">
                          <a onClick={this.back}>
                            {" "}
                            <h2>
                              <img src="../images/retailer/back_arrow.svg" />
                              Back to Order's List
                            </h2>
                          </a>
                        </div>
                        <div class="reject_head">
                          <h2>
                            <img
                              src="../images/retailer/back_arrow.svg"
                              onClick={this.hidegridpendingorders}
                            />
                          </h2>
                        </div>
                        <div class="reject_content">
                          <div class="rej_main">
                            <h2>Reasons For the rejection</h2>
                            {this.state.retailerRejectList
                              ? this.state.retailerRejectList
                              : "no"}
                          </div>
                        </div>
                        <div class="reject_bottom">
                          <div class="submit_btn">
                            <button type="button" onClick={this.rejectOrder}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="retailer_invoice" class={this.state.invoiceDiv}>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="ret_inv_section">
                          <div class="ret_inv_head">
                            <h2>
                              <h6 class="ret_inv_back">
                                {" "}
                                <a onClick={this.showgridpendingorders}>
                                  <img src="../images/retailer/back_arrow.svg" />
                                  Back to Order's List
                                </a>{" "}
                              </h6>{" "}
                              Invoice
                            </h2>
                          </div>
                          <div class="ret_pharm_det">
                            <div class="row">
                              <div class="col-md-8">
                                <div class="pharm_add">
                                  <h2>{this.state.labname}</h2>
                                  <h5>{this.state.storeAddress}</h5>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="pharm_order">
                                  <p>
                                    Order No: <span>{this.state.orderId}</span>
                                  </p>
                                  <p>
                                    Order Date :{" "}
                                    <span>
                                      {dateformatter.asianDateFormat(
                                        this.state.orderDate
                                      )}
                                    </span>
                                  </p>
                                  <p>
                                    Invoice Date:{" "}
                                    <span>
                                      {dateformatter.asianDateFormat(
                                        this.state.invoiceDateTime
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ret_invoice_table">
                            <div class="tab-pane">
                              <div class="tableFixHead">
                                <table
                                  class="table table-bordered"
                                  id="theTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>S.No</th>
                                      <th>Test Name</th>

                                      {/* {this.state.retailerDetails["gst_type"] ==
                                      "GST" ? (
                                        <th>GST (SGST+CGST)</th>
                                      ) : (
                                        <th>IGST %</th>
                                      )}
                                      {this.state.retailerDetails["gst_type"] ==
                                      "GST" ? (
                                        <React.Fragment>
                                          <th>CGST %</th>
                                          <th>SGST %</th>
                                        </React.Fragment>
                                      ) : (
                                        <th>IGST Amt</th>
                                      )} */}

                                      {/* <th>Taxable Amt {Constant.currency}</th> */}
                                      <th>MRP {Constant.currency}</th>
                                      <th>Discount %</th>
                                      <th>Amount {Constant.currency}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state
                                        .retailerOrderMedicineInvoiceDetailsView
                                    }

                                    <div
                                      id="newRow"
                                      style={{ display: "none" }}
                                    >
                                      {this.state.newRow}
                                    </div>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div class="ret_inv_footer">
                              <div class="row">
                                <div class="col-md-12 amount__right">
                                  <div class="total_tax">
                                    {/* <h5>
                                      Total Taxes :{" "}
                                      <span>
                                        {Constant.currency}{" "}
                                        {this.state.totalTaxableAmount}
                                      </span>
                                    </h5> */}
                                    {/* <h5>
                                      Total Taxable Amount :{" "}
                                      <span>
                                        {Constant.currency}{" "}
                                        {this.state.totalTaxes}
                                      </span>
                                    </h5> */}
                                    <h5>
                                      Payment Method :{" "}
                                      <span>{this.state.payment_mode}</span>
                                    </h5>
                                    {this.state.agentDeliveryType !=
                                      "center_visit" &&
                                    this.state.payment_mode == "COD" ? (
                                      <h5>Cash Collected By Agent</h5>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div class="total_amnt">
                                    <p>
                                      Sub Total :{" "}
                                      <span>
                                        {Constant.currency}{" "}
                                        {this.state.subTotal}
                                      </span>
                                    </p>
                                    <p>
                                      Discount :{" "}
                                      <span>
                                        {" "}
                                        - {Constant.currency}{" "}
                                        {this.state.discount
                                          ? parseFloat(this.state.discount)
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )
                                          : "0.00"}
                                      </span>
                                    </p>
                                    <p>
                                      Coupon Discount
                                      <span>
                                        {" "}
                                        : {Constant.currency}{" "}
                                        {this.state.couponDiscountAmount
                                          ? this.state.couponDiscountAmount +
                                            ".00"
                                          : "0.00"}
                                      </span>
                                    </p>
                                    {this.state.agentDeliveryType ==
                                    "home_visit" ? (
                                      <p>
                                        Sample Collection Charges :{" "}
                                        <span>
                                          {" "}
                                          {Constant.currency}{" "}
                                          {this.state.SampleCollectionCharges
                                            ? this.state
                                                .SampleCollectionCharges + ".00"
                                            : "0.00"}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <h3>
                                      Total Paid :{" "}
                                      <span>
                                        {Constant.currency}{" "}
                                        {/* {parseFloat(this.state.totalpaid)
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} */}
                                        {this.state.totalpaid
                                          ? this.state.totalpaid
                                          : "0.00"}
                                      </span>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="ret_inv_pay">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="payment_mode">
                                    <p>
                                      Payment mode:{" "}
                                      <span>
                                        {this.state.payment_mode
                                          ? this.state.payment_mode
                                          : "Online"}
                                      </span>
                                      {this.state.agentDeliveryType !=
                                      "center_visit" ? (
                                        <div class="agent_details">
                                          <p>
                                            Agent Details:{" "}
                                            <span>
                                              {this.state.agentName
                                                ? this.state.agentName
                                                : ""}
                                              {","}
                                              {this.state.agentMobile
                                                ? this.state.agentMobile
                                                : ""}
                                            </span>
                                            <span>
                                              {this.state.agentEmail
                                                ? this.state.agentEmail
                                                : ""}
                                            </span>
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                    <p>
                                      Address :{" "}
                                      <span>{this.state.customerName}</span>
                                      <span>{this.state.customerAddress2}</span>
                                      <span>{this.state.customerLandmark}</span>
                                      <span>{this.state.zipcode}</span>
                                      <span>
                                        Mobile:{this.state.customermobile}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                {/* <div class="col-md-5">
                                  <div className="upload-group">
                                    <form onSubmit={this.onFormSubmit}>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group col-md-12 payment_mode">
                                            <p>Upload Reports </p>

                                            <input
                                              type="file"
                                              name="myImage"
                                              onChange={this.onChange}
                                              className="form-control"
                                              multiple
                                            />

                                            <button
                                              type="submit"
                                              className="upload_btn"
                                            >
                                              Upload
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div> */}
                                {this.state.agentDeliveryType ==
                                  "center_visit" &&
                                this.state.payment_mode == "COD" ? (
                                  <div class="col-md-4">
                                    <div class="delivery_agent">
                                      <p>
                                        <input
                                          type="checkbox"
                                          name="cash_collected"
                                          id="retailerAgent"
                                          onChange={this.checkdelivery}
                                        />{" "}
                                        Cash Collected
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div class="col-md-4">
                                    <div class="delivery_agent">
                                      <p>
                                        <input
                                          type="hidden"
                                          name="cash_collected"
                                        />{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <div class="col-md-4">
                                  <div class="ret_inv_btns">
                                    <a>
                                      {" "}
                                      <p
                                        class="preview_btn"
                                        onClick={this.previewInvoice}
                                      >
                                        Preview
                                      </p>
                                    </a>
                                    {this.state.invoiceBtn ? (
                                      <a id="disable-invoice-btn">
                                        {" "}
                                        <p
                                          class="processs_btn"
                                          onClick={this.processOrder.bind(this)}
                                        >
                                          Generate Invoice
                                        </p>
                                      </a>
                                    ) : (
                                      <a id="disable-invoice-btn">
                                        {" "}
                                        <p class="processs_btn">
                                          Generate Invoice
                                        </p>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  id="invoicepdf"
                  style={{ padding: "0px 0px 30px 0px", display: "block" }}
                  class={this.state.invoicePDFPreview}
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <a onClick={this.showInvoicePage}>
                          {" "}
                          <h2 class="order_back">
                            <img src="../images/retailer/back_arrow.svg" />
                            Back to Order's List
                          </h2>
                        </a>
                      </div>
                    </div>

                    <div style={{ margin: "10px", display: "block" }}>
                      <div>
                        <h2
                          style={{
                            fontSize: "17px",
                            margin: "0px 0px 10px 0px",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {this.state.labname}
                        </h2>
                      </div>
                      <div
                        style={{
                          margin: "0px",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%", float: "left" }}>
                          <h4
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              margin: "5px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                              fontWeight: 500,
                              display: "inline-block",
                              marginRight: "20px",
                            }}
                          >
                            Invoice number :{" "}
                            <span
                              style={{
                                fontWeight: 300,
                                fontSize: "15px",
                                color: "#212121",
                                fontFamily: "sans-serif",
                                letterSpacing: "0px",
                                opacity: 1,
                              }}
                            >
                              5842158
                            </span>{" "}
                          </h4>
                          <h4
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "16px",
                              margin: "5px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                              fontWeight: 600,
                              display: "inline-block",
                              marginRight: "20px",
                            }}
                          ></h4>
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              margin: "5px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                              fontWeight: 300,
                              lineHeight: "22px",
                            }}
                          >
                            {this.state.storeAddress}
                          </p>
                        </div>
                        <div
                          style={{
                            float: "right",
                            width: "50%",
                            textAlign: "right",
                          }}
                        >
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              margin: "2px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                              fontWeight: 300,
                              lineHeight: "22px",
                            }}
                          >
                            Order NO: <span>{this.state.orderId}</span>
                          </p>
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              margin: "2px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                              fontWeight: 300,
                              lineHeight: "22px",
                            }}
                          >
                            Order Date:{" "}
                            <span>
                              {dateformatter.asianDateFormat(
                                this.state.orderDate
                              )}
                            </span>
                          </p>
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              margin: "2px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                              fontWeight: 300,
                              lineHeight: "22px",
                            }}
                          >
                            Invoice Date:{" "}
                            <span>{this.state.invoiceDateTime}</span>
                          </p>
                        </div>
                      </div>
                      <div style={{ margin: "10px 0px 0px 0px" }}>
                        <div
                          className="table-responsive"
                          style={{
                            display: "block",
                            width: "100%",
                            overflowX: "auto",
                          }}
                        >
                          <table
                            className="table "
                            style={{
                              width: "100%",
                              marginBottom: "0px",
                              borderCollapse: "collapse",
                              backgroundColor: "transparent",
                              border: "1px solid #ddd",
                            }}
                          >
                            <thead
                              style={{
                                background:
                                  "#4481fa 0% 0% no-repeat padding-box",
                                opacity: 1,
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    borderBottom: "none",
                                    padding: "14px 10px",
                                    color: "#fff",
                                    border: "1px solid #ddd",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                  }}
                                >
                                  Sl.NO
                                </th>
                                <th
                                  style={{
                                    borderBottom: "none",
                                    padding: "14px 10px",
                                    color: "#fff",
                                    border: "1px solid #ddd",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                  }}
                                >
                                  Test Name
                                </th>
                                {/* <th
                                style={{
                                  borderBottom: "none",
                                  padding: "14px 10px",
                                  color: "#fff",
                                  border: "1px solid #ddd",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  fontFamily: "sans-serif",
                                  textAlign: "left",
                                }}
                              >
                                Batch &amp; Expiry
                              </th> */}

                                {/* {this.state.retailerDetails["gst_type"] ==
                                "GST" ? (
                                  <React.Fragment>
                                    <th
                                      style={{
                                        borderBottom: "none",
                                        padding: "14px 10px",
                                        color: "#fff",
                                        border: "1px solid #ddd",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        fontFamily: "sans-serif",
                                        textAlign: "left",
                                      }}
                                    >
                                      CGST %
                                    </th>
                                    <th
                                      style={{
                                        borderBottom: "none",
                                        padding: "14px 10px",
                                        color: "#fff",
                                        border: "1px solid #ddd",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        fontFamily: "sans-serif",
                                        textAlign: "left",
                                      }}
                                    >
                                      SGST %
                                    </th>
                                  </React.Fragment>
                                ) : (
                                  <th
                                    style={{
                                      borderBottom: "none",
                                      padding: "14px 10px",
                                      color: "#fff",
                                      border: "1px solid #ddd",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      fontFamily: "sans-serif",
                                      textAlign: "left",
                                    }}
                                  >
                                    IGST %
                                  </th>
                                )}
                                {this.state.retailerDetails["gst_type"] ==
                                "GST" ? (
                                  <th
                                    style={{
                                      borderBottom: "none",
                                      padding: "14px 10px",
                                      color: "#fff",
                                      border: "1px solid #ddd",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      fontFamily: "sans-serif",
                                      textAlign: "left",
                                    }}
                                  >
                                    GST Amt.
                                  </th>
                                ) : (
                                  <th
                                    style={{
                                      borderBottom: "none",
                                      padding: "14px 10px",
                                      color: "#fff",
                                      border: "1px solid #ddd",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      fontFamily: "sans-serif",
                                      textAlign: "left",
                                    }}
                                  >
                                    IGST Amt.
                                  </th>
                                )} */}
                                {/* <th
                                  style={{
                                    borderBottom: "none",
                                    padding: "14px 10px",
                                    color: "#fff",
                                    border: "1px solid #ddd",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                  }}
                                >
                                  Taxable Amt.
                                </th> */}
                                <th
                                  style={{
                                    borderBottom: "none",
                                    padding: "14px 10px",
                                    color: "#fff",
                                    border: "1px solid #ddd",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                  }}
                                >
                                  MRP
                                </th>
                                <th
                                  style={{
                                    borderBottom: "none",
                                    padding: "14px 10px",
                                    color: "#fff",
                                    border: "1px solid #ddd",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                  }}
                                >
                                  Discount %
                                </th>
                                <th
                                  style={{
                                    borderBottom: "none",
                                    padding: "14px 10px",
                                    color: "#fff",
                                    border: "1px solid #ddd",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                  }}
                                >
                                  Total Amt.
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.invoiceRows
                                ? this.state.invoiceRows.map(
                                    (itemsdata, num) => (
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {num + 1}
                                        </td>
                                        <td
                                          style={{
                                            padding: "14px 10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {itemsdata.productName}
                                          <p
                                            style={{
                                              fontSize: "15px",
                                              fontFamily: "sans-serif",
                                              color: "#000",
                                              fontWeight: 500,
                                              letterSpacing: "0px",
                                              margin: "5px 0px 0px 0px",
                                            }}
                                          >
                                            {itemsdata.manafacturer}
                                          </p>
                                        </td>
                                        {/* <td
                                          style={{
                                            padding: "10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {Constant.currency}{" "}
                                          {itemsdata.taxableAmount}
                                        </td> */}
                                        {/* {this.state.retailerDetails[
                                          "gst_type"
                                        ] == "GST" ? (
                                          <React.Fragment>
                                            <td
                                              style={{
                                                padding: "10px",
                                                fontSize: "15px",
                                                fontFamily: "sans-serif",
                                                color: "#000",
                                                fontWeight: 500,
                                                letterSpacing: "0px",
                                                border: "1px solid #ddd",
                                              }}
                                            >
                                              {itemsdata.discountAmount / 2}
                                            </td>{" "}
                                            <td
                                              style={{
                                                padding: "10px",
                                                fontSize: "15px",
                                                fontFamily: "sans-serif",
                                                color: "#000",
                                                fontWeight: 500,
                                                letterSpacing: "0px",
                                                border: "1px solid #ddd",
                                              }}
                                            >
                                              {itemsdata.discountAmount / 2}
                                            </td>
                                          </React.Fragment>
                                        ) : (
                                          <td
                                            style={{
                                              padding: "10px",
                                              fontSize: "15px",
                                              fontFamily: "sans-serif",
                                              color: "#000",
                                              fontWeight: 500,
                                              letterSpacing: "0px",
                                              border: "1px solid #ddd",
                                            }}
                                          >
                                            {itemsdata.discountAmount}
                                          </td>
                                        )}{" "} */}
                                        {/* <td
                                          style={{
                                            padding: "10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {Constant.currency}{" "}
                                          {itemsdata.vatAmount}
                                        </td> */}
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {Constant.currency} {itemsdata.mrp}
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {itemsdata.discountAmount}
                                        </td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontSize: "15px",
                                            fontFamily: "sans-serif",
                                            color: "#000",
                                            fontWeight: 500,
                                            letterSpacing: "0px",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {Constant.currency}{" "}
                                          {itemsdata.lineAmount}
                                        </td>
                                      </tr>
                                    )
                                  )
                                : ""}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br />
                      {this.state.agentDeliveryType != "center_visit" ? (
                        <div
                          class="agent_details_prew"
                          style={{
                            display: "inline-block",
                          }}
                        >
                          <p>
                            Agent Details: <br />
                            <span>
                              {this.state.agentName ? this.state.agentName : ""}
                              {","}
                              {this.state.agentMobile
                                ? this.state.agentMobile
                                : ""}
                            </span>
                            <br />
                            <span>
                              {this.state.agentEmail
                                ? this.state.agentEmail
                                : ""}
                            </span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div style={{ margin: "10px 0px", float: "right" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            marginRight: "25px",
                          }}
                        >
                          {/* <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Total Taxes:{" "}
                            <span>
                              {Constant.currency}{" "}
                              {this.state.totalTaxableAmount}
                            </span>
                          </p> */}
                          {/* <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Total Taxable Amount:{" "}
                            <span>
                              {Constant.currency} {this.state.totalTaxes}
                            </span>
                          </p> */}
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Payment Method:{" "}
                            <span>{this.state.payment_mode}</span>
                          </p>
                          {this.state.agentDeliveryType != "center_visit" &&
                          this.state.payment_mode == "COD" ? (
                            <p>Cash Collected By Agent</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                          }}
                        >
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Sub Total :{" "}
                            <span>
                              {Constant.currency} {this.state.subTotal}
                            </span>
                          </p>
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Discount :{" "}
                            <span>
                              {Constant.currency}{" "}
                              {this.state.discount
                                ? parseFloat(this.state.discount)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : "0.00"}
                            </span>
                          </p>
                          <p>
                            Coupon Discount
                            <span>
                              - {Constant.currency}{" "}
                              {this.state.couponDiscountAmount + ".00"
                                ? this.state.couponDiscountAmount
                                : "0.00"}
                            </span>
                          </p>
                          {this.state.agentDeliveryType == "home_visit" ? (
                            <p
                              style={{
                                letterSpacing: "0px",
                                color: "#000000",
                                fontSize: "15px",
                                fontWeight: 500,
                                margin: "10px 0px",
                                opacity: 1,
                                fontFamily: "sans-serif",
                              }}
                            >
                              Sample Collection Charges :{" "}
                              <span>
                                {Constant.currency}{" "}
                                {this.state.SampleCollectionCharges
                                  ? this.state.SampleCollectionCharges + ".00"
                                  : "0.00"}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}
                          <h2
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Total Paid:{" "}
                            <span>
                              {Constant.currency}{" "}
                              {/* {parseFloat(this.state.totalpaid)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} */}
                              {this.state.totalpaid}
                            </span>
                          </h2>
                        </div>
                      </div>
                      <div
                        style={{
                          margin: "10px 0px",
                          float: "left",
                          width: "100%",
                          borderTop: "1px solid #3b3b3b",
                          borderBottom: "1px solid #3b3b3b",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                          }}
                        >
                          <h4
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "16px",
                              fontWeight: 600,
                              margin: "10px 25px 10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Payment Mode :
                            <span
                              style={{
                                letterSpacing: "0px",
                                color: "#000000",
                                fontSize: "15px",
                                fontWeight: 500,
                                margin: "10px 0px",
                                opacity: 1,
                                fontFamily: "sans-serif",
                              }}
                            >
                              {this.state.payment_mode
                                ? this.state.payment_mode
                                : "Online"}{" "}
                            </span>
                          </h4>
                        </div>
                        <div style={{ display: "inline-block", width: "50%" }}>
                          <h4
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "16px",
                              fontWeight: 600,
                              margin: "10px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          >
                            Delivery Address:
                            <span
                              style={{
                                display: "block",
                                letterSpacing: "0px",
                                color: "#000000",
                                fontSize: "15px",
                                fontWeight: 500,
                                margin: "10px 0px",
                                opacity: 1,
                                fontFamily: "sans-serif",
                                lineHeight: "22px",
                              }}
                            >
                              {this.state.customerName != ""
                                ? this.state.customerName + ", "
                                : ""}
                              {this.state.customerAddress2 != ""
                                ? this.state.customerAddress2 + ", "
                                : ""}
                              {this.state.customerLandmark != ""
                                ? this.state.customerLandmark + ", "
                                : ""}
                              {this.state.zipcode != ""
                                ? this.state.zipcode + ", "
                                : ""}{" "}
                              Mobile:
                              {this.state.customermobile != ""
                                ? this.state.customermobile
                                : "--"}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div
                        style={{
                          marginBottom: "60px",
                          width: "100%",
                          float: "left",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              letterSpacing: "0px",
                              color: "#000000",
                              fontSize: "15px",
                              fontWeight: 500,
                              margin: "6px 0px",
                              opacity: 1,
                              fontFamily: "sans-serif",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
        <Footer />
      </main>
    );
  }
}
