import React, { Component, useState } from "react";
import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/HttpconfigDoctorAdmin";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/doctor/doctor.css";
import DoctorAdminHeader from "../doctorAdmin/DoctorAdminHeader";

import DoctorSideMenu from "./DoctorSideMenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const moment = require("moment");

export default class DoctorAdminviewslots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      files: [],
      doctor_id: "",
    };
  }
  componentDidMount = () => {
    var retrievedObject = localStorage.getItem("doctorAdmin");
    if (retrievedObject == null) {
      window.location.href = "./login";
    }
    let userData = JSON.parse(retrievedObject);
    this.state.doctor_id = userData.doctor_id;
    this.setState({
      date: moment().format("Do MMMM , YYYY"),
    });
    Httpconfig.httptokenget(
      Constant.siteurl +
      "api/telemedicine/getCalendarDates/" +
      userData.doctor_id
    )
      .then((response) => {
        const specificdates = response.data.data.map(
          (dates) => new Date(dates.calendardate)
        );
        console.log("rakesh:" + specificdates);
        this.setState({
          sdate: specificdates,
        });

        console.log("error" + this.state.sdate);

        if (response.data.status == "200" && response.data.error == false) {
        } else {

        }
      })
      .catch((error) => {
        toast.error(error);
      });
    this.fetchUserSelectedSlots(moment().format("YYYY-MM-DD"));
  };
  getUserSelectedDate = (value) => {
    let date = new Date(value);
    let selectedDate =
      date.getFullYear() +
      "-" +
      Number(date.getMonth() + 1) +
      "-" +
      date.getDate();
    this.fetchUserSelectedSlots(selectedDate);
    this.state.selectedDate = selectedDate;
  };
  fetchUserSelectedSlots(selectedDate) {
    let doctorId = this.state.doctor_id;
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/telemedicine/patientAppointmentSlots",
      {
        doctor_id: doctorId,
        consultation_date: selectedDate,
        module_type: "telemedicine-app",
      }
    ).then((response) => {
      if (response.status == 200) {
        let resultCount = Object.keys(response.data.result).length;
        if (resultCount > 0) {
          const morningSlots = response.data.result[0];
          const afternoonSlots = response.data.result[1];
          const eveningSlots = response.data.result[2];
          const nightSlots = response.data.result[3];

          this.setState({
            morningSlotsLabel: morningSlots.Label,
            morningSlots: morningSlots.time_slots,
            afternoonLabel: afternoonSlots.Label,
            afternoonSlots: afternoonSlots.time_slots,
            eveningSlotsLabel: eveningSlots.Label,
            eveningSlots: eveningSlots.time_slots,
            nightSlotsLabel: nightSlots.Label,
            nightSlots: nightSlots.time_slots,
          });
          this.forceUpdate();
          var i = 0;
          var time = document.getElementsByClassName("timings");
          for (i = 0; i < time.length; i++) {
            time[i].addEventListener("click", function (event) {
              var selelcedT = this.innerHTML;
              let sTime = moment(selelcedT, ["h:mm A"]).format("HH:mm:ss");
              let seldate = selectedDate + sTime;
              let seletedSlot_time = moment(seldate, [
                "YYYY-MM-DD HH:mm A",
              ]).format("YYYY-MM-DD HH:mm:ss");
              reactLocalStorage.setObject("seletedSlot_time", seletedSlot_time);
              var lang = localStorage.getItem("seletedSlot_time");
              $(".timings").removeClass("highlet_button");
              $(this).addClass("highlet_button");
            });
          }
        } else {
          toast.error("Slots not Available");
          this.setState({
            morningSlotsLabel: "",
            morningSlots: "",
            afternoonLabel: "",
            afternoonSlots: "",
            eveningSlotsLabel: "",
            eveningSlots: "",
            nightSlotsLabel: "",
            nightSlots: "",
          });
        }
      }
    });
  }
  render() {
    var currentDate = new Date();
    var numberOfDaysToAdd = 45;
    var slotsAvailable = 0;
    const daysHighlighted = new Array(numberOfDaysToAdd).fill(currentDate);

    var slotsMorningArray = [];
    var slotsNoonArray = [];
    var slotsEveningArray = [];
    var slotsNightArray = [];
    var morningArr = this.state.morningSlots;
    var afternoonArr = this.state.afternoonSlots;
    var eveningArr = this.state.eveningSlots;
    var nightArr = this.state.nightSlots;

    if (morningArr) {
      var nightLength = morningArr.length;
      var mrng = "";
      if (nightLength > 0) {
        mrng += '<div class="schedule_content">';
        for (var i = 0; i < nightLength; i++) {
          if (i == 0) {
            mrng += "<h2>Morning</h2>";
          }
          mrng += "<p class='timings'>" + morningArr[i] + "</p>";
        }
        mrng += "</div>";
      }
      slotsMorningArray = mrng;
    } else {
      morningArr = "";
      slotsAvailable = slotsAvailable + 1;
    }

    if (afternoonArr) {
      var noonLength = afternoonArr.length;
      var noon = "";
      if (noonLength > 0) {
        noon += '<div class="schedule_content">';
        for (var i = 0; i < noonLength; i++) {
          if (i == 0) {
            noon += "<h2>After noon</h2>";
          }
          noon += "<p class='timings'>" + afternoonArr[i] + "</p>";
        }
        noon += "</div>";
      }
      slotsNoonArray = noon;
    } else {
      afternoonArr = "";
      slotsAvailable = slotsAvailable + 1;
    }

    if (eveningArr) {
      var evngLength = eveningArr.length;
      var evng = "";
      if (evngLength > 0) {
        evng += '<div class="schedule_content">';
        for (var i = 0; i < evngLength; i++) {
          if (i == 0) {
            evng += "<h2>Evening</h2>";
          }
          evng += "<p class='timings'>" + eveningArr[i] + "</p>";
        }
        evng += "</div>";
      }
      slotsEveningArray = evng;
    } else {
      eveningArr = "";
      slotsAvailable = slotsAvailable + 1;
    }

    if (nightArr) {
      var nightLength = nightArr.length;
      var night = "";
      if (nightLength > 0) {
        night += '<div class="schedule_content">';
        for (var i = 0; i < nightLength; i++) {
          if (i == 0) {
            night += "<h2>Night</h2>";
          }
          night += "<p class='timings'>" + nightArr[i] + "</p>";
        }
        night += "</div>";
      }
      slotsNightArray = night;
    } else {
      nightArr = "";
      slotsAvailable = slotsAvailable + 1;
    }
    console.log("slotsAvailable", slotsAvailable);
    return (
      <main id="main">
        <DoctorAdminHeader onSelectLanguage={this.handleLanguage} />

        <I18nPropvider locale={this.state.Language}>
          <section id="doctor_dashboard">
            <div class="container-fluid">
              <div class="row">
                <DoctorSideMenu onSelectLanguage={this.handleLanguage} />
                <div class="col-lg-10 col-md-9 no_padding">
                  <div class="overview_left">
                    <section id="create_slot">
                      <div class="container no_padding">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="avail_head">
                              <h2>Available Time Slot</h2>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-md-8">
                            <div class="create_slot_cal doctorviewslots_datepicker">
                              <DatePicker
                                name="start_date"
                                autoComplete="off"
                                className="dateInput"
                                placeholderText="Start Date"
                                selected={this.state.start_date}
                                onChange={this.getUserSelectedDate}
                                dateFormat="d-MM-yyyy"
                                highlightDates={[
                                  {
                                    "react-datepicker__day--highlighted": this
                                      .state.sdate
                                      ? this.state.sdate
                                      : new Date(),
                                  },
                                ]}
                                inline
                                calendarIcon
                                minDate={
                                  this.state.sdate
                                    ? this.state.sdate[0]
                                    : new Date()
                                }
                                maxDate={
                                  this.state.sdate
                                    ? this.state.sdate[
                                    this.state.sdate.length - 1
                                    ]
                                    : new Date()
                                }
                              />
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-4">
                            <div class="diff_cal_img">
                              <img src="../images/doctor-img/View slot/slot.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="slot_schedule">
                      <div class="container no_padding">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="schedule_box">
                              <div class="schedule_head">
                                <h2>
                                  {moment(this.state.selectedDate).format(
                                    "Do ,MMM YYYY"
                                  )}
                                </h2>
                                <p id="slotsData">
                                  {slotsAvailable > 0 ? "No slots found" : ""}
                                </p>
                              </div>

                              <div class="row">
                                {slotsMorningArray ? (
                                  <div
                                    class="col-md-3"
                                    dangerouslySetInnerHTML={{
                                      __html: slotsMorningArray,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {slotsNoonArray ? (
                                  <div
                                    class="col-md-3"
                                    dangerouslySetInnerHTML={{
                                      __html: slotsNoonArray,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {slotsEveningArray ? (
                                  <div
                                    class="col-md-3"
                                    dangerouslySetInnerHTML={{
                                      __html: slotsEveningArray,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {slotsNightArray ? (
                                  <div
                                    class="col-md-3"
                                    dangerouslySetInnerHTML={{
                                      __html: slotsNightArray,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <section id="slot_bottom_modal">
                  <div
                    class="modal bottom fade slot_modals"
                    id="slotModasl"
                    role="dialogs"
                  >
                    <div class="modal-dialog modal-lgs">
                      {/* <!-- Modal content--> */}
                      <div class="modal-contentdd">
                        <div class="modal-headerddd">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                          <h4 class="modal-title">Sat, 1 July</h4>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="time_slot">
                                <h3>Afternoon time slot</h3>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="time_slot">
                                <h3>Night time slot</h3>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* modal for Slot */}
                <section id="slot_bottom_modal">
                  <div
                    class="modal bottom fade slot_modal"
                    id="slotModal"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg">
                      {/* <!-- Modal content--> */}
                      <div class="modal-content">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                          <h4 class="modal-title">Sat, 1 July</h4>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="time_slot">
                                <h3>Afternoon time slot</h3>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="time_slot">
                                <h3>Night time slot</h3>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                  <div class="slot_check">
                                    <p>
                                      2 30 PM{" "}
                                      <input
                                        class="input_check"
                                        type="checkbox"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </I18nPropvider>
        <PatientFooter />
      </main>
    );
  }
}
