import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAgent";
import Constant from "../../constants";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import RetailerCss from "../../public/css/retailer/retailer_style.css";
import AgentHeader from "../agent/AgentHeader";
import AgentMenu from "./AgentMenu";
import Footer from "../patient/Patientfooter";
import { Multiselect } from "multiselect-react-dropdown";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactExport from "react-data-export";
import dateformatter from "../helpers/dateformatter";
import * as SecureLS from "secure-ls";
import { Divider } from "semantic-ui-react";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require("moment");

export default class ViewAgents extends Component {
  constructor(props) {
    super(props);
    this.showDirections = this.showDirections.bind(this);
    this.state = {
      fields: {},
      page_data: "",
      datatable: {
        columns: [
          {
            label: "Order ID",
            field: "order_id",
            sort: "asc",
            width: 150,
          },
          {
            label: "Patient Name",
            field: "name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Mobile Number ",
            field: "mobile_number ",
            sort: "asc",
            width: 150,
          },

          // {
          //   label: "Patient Address",
          //   field: "patient_address ",
          //   sort: "asc",
          //   width: 150,
          // },

          // {
          //   label: "Order Date & Time",
          //   field: "order_date",
          //   sort: "asc",
          //   width: 150,
          // },
          {
            label: "Slot Date",
            field: "slot_date",
            sort: "asc",
            width: 10,
          },
          {
            label: "Slot Time",
            field: "slot_time",
            sort: "asc",
            width: 10,
          },
          {
            label: "Directions",
            field: "view_test",
            sort: "asc",
            width: 10,
          },
          {
            label: "Payment Mode",
            field: "payment_method",
            sort: "asc",
            width: 10,
          },
          {
            label: "View Details",
            field: "test_details",
            sort: "asc",
            width: 10,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 10,
          },
        ],
        rows: [],
      },
    };
    this.deleteRetailer = this.deleteAgent.bind(this);
  }
  // To get detais after first render
  componentDidMount = () => {
    let userData = ls.get("agentuserObj");
    this.fetchpagesdata(userData.agent_id);

    if (userData) {
      if (userData != "") {
        this.setState({
          agentId: userData.agent_id,
        });

        this.forceUpdate();
      }
    }
    this.getRejectionResonslist();
  };
  getTimeSlots() {
    var startTime = moment("09:00", "HH:mm");
    var endTime = moment("23:59", "HH:mm");
    var timeSlots = [];
    while (startTime <= endTime) {
      timeSlots.push(new moment(startTime).format("HH:mm a"));
      startTime.add(60, "minutes");
    }
    return timeSlots;
  }
  showDirections = (latitude, longitude) => {
    let lat = latitude;
    let long = longitude;
    // window.open("https://maps.google.com?q=17.3529,78.5357", "_blank");
    window.open(
      "https://maps.google.com?q= " + lat + "," + long + ",",
      "_blank"
    );
  };
  viewTestDetails = (retailer_id, event) => {
    // window.location.href = "/productdescription" + "?id=" + product_id;
    let Order_Id = event.target.id;
    if (retailer_id != "") {
      window.location.href = Constant.imgurl + "/agent/PatientTestdetails"; //+ "?id=" + retailer_id;
      localStorage.setItem("AgentRetailerId", retailer_id);
      localStorage.setItem("AgentOrderID", Order_Id);
    }
  };

  getRejectionResonslist = () => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/cancel/cancellationReason/"
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          if (Object.keys(response.data.data).length > 0) {
            const retailerRejectList = response.data.data.map(
              (finalLoadedData, num) => {
                return (
                  <p>
                    <input
                      type="checkbox"
                      class="rej_check"
                      name="cancelReasons"
                      id={finalLoadedData.id}
                      onChange={this.cancelReason}
                    />{" "}
                    {finalLoadedData.reason}
                  </p>
                );
              }
            );
            this.state.retailerRejectList = retailerRejectList;
            this.forceUpdate();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For selected order id

  // When value changes of the fields
  handleChange = (event, deliveryType, paymentMode) => {
    let fields = this.state.fields;
    fields["test_status"] = event.target.value;
    fields["orderId"] = event.target.id;

    this.setState({ fields });
    if (fields["test_status"] == "6") {
      $("#reject_reason").show("modal");
      this.state.selectedCancelOrderId = fields["orderId"];
    } else if (fields["test_status"] == "1") {
      $("#pickup_reason").show("modal");
      this.state.selectedCancelOrderId = fields["orderId"];
    } else if (fields["test_status"] == "picked-up") {
      this.agentStatusupdate(
        fields["test_status"],
        fields["orderId"],
        deliveryType,
        paymentMode
      );
    }
  };

  //reject pickup
  rejectPickup = () => {
    let otherReason = $("#otherReason").val();
    let orderId = this.state.selectedCancelOrderId;
    if (otherReason == "") {
      toast.error("Please Enter Reason");
      return;
    } else {
      let cancelledReason = otherReason;
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/cancel/cancellationReason/pickupDetails/" +
          orderId,
        { order_status: "1", pickup_cancel_reason: cancelledReason }
      )
        .then((response) => {
          if ((response.data.status = 200)) {
            toast.success(response.data.message);
            $("#pickup_reason").hide();
            $("#otherReason").val("");
            this.state.cancelleReason = "";
            this.state.selectedCancelOrderId = "";
            this.forceUpdate();
            // this.getAllOrders();
            // this.orderStatusCount();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  // Reject order
  rejectOrder = () => {
    let cancelReasonsCheckedCount = $(
      'input[name="cancelReasons"]:checked'
    ).length;
    // let otherReason = $("#otherReason").val();
    let orderId = this.state.selectedCancelOrderId;
    if (cancelReasonsCheckedCount == 0) {
      toast.error("Select Reason for Cancellation");
      return;
    } else {
      let cancelledReason = this.state.cancelleReason;
      Httpconfig.httptokenpost(
        Constant.siteurl +
          "api/DIAGNOSIS/cancel/cancellationReason/orderDetails/" +
          orderId,
        { order_status: "6", cancelled_reason: cancelledReason }
      )
        .then((response) => {
          if ((response.data.status = 200)) {
            toast.success(response.data.message);
            $("#reject_reason").hide();
            $('input[name="cancelReasons"]:checked').prop("checked", "");
            $("#otherReason").val("");
            this.state.cancelleReason = "";
            this.state.selectedCancelOrderId = "";
            this.forceUpdate();
            // this.getAllOrders();
            // this.orderStatusCount();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  // update the cancellation reaon

  cancelReason = (event) => {
    let id = event.currentTarget.id;
    if (document.getElementById(id).checked == true) {
      this.state.cancelleReason = id;
    } else {
      this.state.cancelleReason = "";
    }
    this.forceUpdate();
  };

  closeModal = (event) => {
    $("#reject_reason").hide();
    $("#pickup_reason").hide();
  };
  agentStatusupdate = (testStatus, orderId, deliveryType, paymentMode) => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/agentStatusupdate/",
      {
        testStatus: testStatus,
        orderId: orderId,
        deliveryType: deliveryType,
        paymentMode: paymentMode,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          if (testStatus == "picked-up" || testStatus == 1) {
            toast.success("Status Updated Successfully");
          }
          this.fetchpagesdata(this.state.agentId);
          // this.getAgentData(agentId, orderId);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  fetchpagesdata(agentId) {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/getAgentallots/" + agentId
    )
      .then((response) => {
        console.log("--328--", response);
        this.setState({
          page_data: response.data.data,
        });
        let assignvalues = [];

        this.items = response.data.data.map((item, key) => {
          this.state.labStatus = item.test_status;
          this.forceUpdate();
          assignvalues.push({
            order_id: item.order_processing_tbls[0].order_id,
            name: item.patient_tbl ? item.patient_tbl.name : "",
            "mobile_number ": item.patient_tbl
              ? item.patient_tbl.phone_number
              : "",
            slot_date: item.slot_date ? item.slot_date : "Lab Visit",
            slot_time: item.slot_time ? item.slot_time : "Lab Visit",
            view_test: (
              <span>
                <a
                  onClick={this.showDirections.bind(
                    this,
                    item.delivery_address_tbl
                      ? item.delivery_address_tbl.latitude
                      : "",
                    item.delivery_address_tbl
                      ? item.delivery_address_tbl.longitude
                      : ""
                  )}
                >
                  <img
                    class="loc_img"
                    src="../images/patient/img/Doctors_List/location.svg"
                  />{" "}
                  Directions
                </a>
              </span>
            ),
            payment_method: item.payment_method,
            test_details: (
              <span>
                <img
                  style={{ cursor: "pointer" }}
                  src="/images/patient/img/diagnostics/unhide_icon.svg"
                  alt=""
                  class="img-fluid"
                  id={item.id}
                  onClick={this.viewTestDetails.bind(this, item.retailer_id)}
                />
              </span>
            ),
            actions: (
              <div class="col-md-12 no_padding">
                {item.slot_date && item.slot_time ? (
                  <select
                    name="test_status"
                    class="form-control agent_sel"
                    disabled={
                      item.test_status == "picked-up" ||
                      item.order_status == "6" ||
                      item.order_status == "1"
                    }
                    value={this.state.fields["test_status"]}
                    data={item.delivery_type}
                    id={
                      item.order_processing_tbls
                        ? item.order_processing_tbls[0].order_id
                        : ""
                    }
                    onChange={(event) =>
                      this.handleChange(
                        event,
                        item.delivery_type,
                        item.payment_method
                      )
                    }
                  >
                    <option value="">Select Status</option>
                    {item.payment_method == "COD" ? (
                      <option
                        value="picked-up"
                        selected={item.test_status == "picked-up"}
                      >
                        Samples Collected With Cash
                      </option>
                    ) : (
                      <option
                        value="picked-up"
                        selected={item.test_status == "picked-up"}
                      >
                        Samples Collected
                      </option>
                    )}
                    <option value="1" selected={item.order_status == "1"}>
                      Unable to collect
                    </option>
                    <option value="6" selected={item.order_status == "6"}>
                      Cancel
                    </option>
                  </select>
                ) : (
                  ""
                )}
              </div>
            ),
          });
        });

        // let retailerState = Object.assign({}, this.state);
        this.state.datatable.rows = assignvalues;
        this.forceUpdate();
        // this.setState({ datatable: this.state.datatable.rows });
      })
      .catch((error) => {});
  }

  //delete controller
  deleteAgent(userId, name) {
    var isConfirm = window.confirm("Are you sure to delete " + name + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/diagnosis/deleteAgent/" + userId
      )
        .then((response) => {
          toast.success("Successfully Deleted Agent");
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {});
    }
  }
  render() {
    const { datatable } = this.state;
    return (
      <main id="main">
        <AgentHeader />
        <AgentMenu />
        <div class="col-lg-10 col-md-10 agent_main">
          <section id="main_dashboard">
            <div id="CMS_tab">
              <div className="CMS_content">
                <div className="container">
                  <div className="row">
                    <div className="tab-header">
                      <h3> Order Details</h3>
                      <div class="refresh_page">
                        <button onClick={() => window.location.reload(false)}>
                          <i class="fa fa-refresh" aria-hidden="true">
                            {" "}
                            Refresh!
                          </i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div id="reg_form">
                          {datatable.rows.length === 0 ? (
                            <p className="no_record">No Records Found</p>
                          ) : (
                            <MDBDataTable
                              striped
                              responsive
                              bordered
                              small
                              data={datatable}
                            />
                          )}
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="update_btn"
                                style={{ textAlign: "right" }}
                              ></div>
                            </div>
                          </div>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="modal fade" id="pickup_reason" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  onClick={this.closeModal}
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
                <h2 class="modal-title">Reasons</h2>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="reject_content">
                        <div class="rej_main">
                          <textarea
                            class="form-control"
                            rows="2"
                            placeholder="Type Reason"
                            id="otherReason"
                          ></textarea>{" "}
                        </div>
                      </div>
                      <div class="reject_bottom">
                        <div class="row">
                          <div class="col-md-6">
                            <button
                              type="button"
                              class="cancel_btn"
                              data-dismiss="modal"
                              onClick={this.closeModal}
                            >
                              Close
                            </button>
                          </div>
                          <div class="col-md-6">
                            <button
                              type="button"
                              class="save_btn"
                              onClick={this.rejectPickup}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
        <div class="modal fade" id="reject_reason" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  onClick={this.closeModal}
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
                <h2 class="modal-title">Reason For Cancellation</h2>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="reject_content">
                        <div class="rej_main">
                          {this.state.retailerRejectList}

                          {/* <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Type Reason"
                            id="otherReason"
                          ></textarea> */}
                        </div>
                      </div>
                      <div class="reject_bottom">
                        <div class="row">
                          <div class="col-md-6">
                            <button
                              type="button"
                              class="cancel_btn"
                              data-dismiss="modal"
                              onClick={this.closeModal}
                            >
                              Close
                            </button>
                          </div>
                          <div class="col-md-6">
                            <button
                              type="button"
                              class="save_btn"
                              onClick={this.rejectOrder}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}
