import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Link } from "react-router-dom";

export default class Createmastersubpagemodule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    if (handle) {
      this.fetchRetailerListOne(handle);
    }
    this.fetchRetailerListData();
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;

    this.setState({ fields });
  };

  // Get Clinic Details
  fetchRetailerListData() {
    Httpconfig.httptokenget(Constant.siteurl + "api/retailer")
      .then((response) => {
        this.setState({
          retailer_details: response.data.data,
        });
      })
      .catch((error) => {});
  }
  //  For getting the retailer list
  fetchRetailerListOne(handle) {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/OM/RetailerWalletlist/" + handle
    )
      .then((response) => {
        this.setState({
          fields: {
            id: response.data.data[0].retailer_id,
            wallet_points: response.data.data[0].wallet_points,
            wallet_points_amount: response.data.data[0].wallet_points_amount,
            deduct_points: response.data.data[0].deduct_points,
            mimimum_wpoints: response.data.data[0].mimimum_wpoints,
            status: response.data.data[0].status,
          },
        });
      })
      .catch((error) => {});
  }
  // Ends
  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateRetailerWallet(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createRetailerWallet(event);
    } else {
      toast.warn("Form has errors.");
    }
  }
  // creates new controller
  createRetailerWallet = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;

    Httpconfig.httptokenpost(Constant.siteurl + "api/OM/RetailerWalletlist", {
      retailer_id: fields["id"],
      wallet_points: fields["wallet_points"],
      wallet_points_amount: fields["wallet_points_amount"],
      deduct_points: fields["deduct_points"],
      mimimum_wpoints: fields["mimimum_wpoints"],
      status: 1,
    })
      .then((response) => {
        toast.success("Successfully Created Pharmacy Walletlist");
        setTimeout(
          () => this.props.history.push("/admin/ViewRetailersWalletList"),
          2000
        );
      })
      .catch((error) => {});
  };

  // updates controller
  updateRetailerWallet = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    Httpconfig.httptokenput(
      Constant.siteurl + "api/OM/RetailerWalletlist/" + handle,
      {
        retailer_id: fields["id"],
        wallet_points: fields["wallet_points"],
        wallet_points_amount: fields["wallet_points_amount"],
        deduct_points: fields["deduct_points"],
        mimimum_wpoints: fields["mimimum_wpoints"],
        status: 1,
      }
    )
      .then((response) => {
        toast.success("Successfully Updated Pharmacy Walletlist");
        setTimeout(
          () => this.props.history.push("/admin/ViewRetailersWalletList"),
          2000
        );
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["id"]) {
      formIsValid = false;
      errors["id"] = "Pharmacy name cannot be empty";
    }
    if (!fields["wallet_points"]) {
      formIsValid = false;
      errors["wallet_points"] = "Wallet Points cannot be empty";
    } else if (!/^[0-9]+$/g.exec(fields["wallet_points"])) {
      formIsValid = false;
      errors["wallet_points"] = "Special characters not allowed";
    } else if (fields["wallet_points"] == 0) {
      formIsValid = false;
      errors["wallet_points"] = "Minimum required wallet points is 1";
    }
    if (!fields["wallet_points_amount"]) {
      formIsValid = false;
      errors["wallet_points_amount"] = "Wallet Points Amount cannot be empty";
    } else if (!/^[0-9]+$/g.exec(fields["wallet_points_amount"])) {
      formIsValid = false;
      errors["wallet_points_amount"] = "Special characters not allowed";
    } else if (fields["wallet_points_amount"] == 0) {
      formIsValid = false;
      errors["wallet_points_amount"] =
        "Minimum required wallet points value is 1";
    }
    if (!fields["deduct_points"]) {
      formIsValid = false;
      errors["deduct_points"] = "Deduct Points cannot be empty";
    } else if (!/^[0-9]+$/g.exec(fields["deduct_points"])) {
      formIsValid = false;
      errors["deduct_points"] = "Special characters not allowed";
    } else if (fields["deduct_points"] == 0) {
      formIsValid = false;
      errors["deduct_points"] = "Minimum required deduct points is 1";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  render() {
    const { fields, errors, retailer_details, retailer_wallet } = this.state;

    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      <a> Create Pharmacy Wallet </a>
                    </li>
                  </ol>
                </div>
              </div>

              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3>Create Pharmacy Wallet</h3>
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select Pharmacy</label>
                                <select
                                  name="id"
                                  className="form-control"
                                  value={this.state.fields["id"] || ""}
                                  onChange={this.handleChange.bind(this, "id")}
                                >
                                  <option value="">Select</option>
                                  {retailer_details &&
                                    retailer_details.map(
                                      (retailer_details, i) => {
                                        return (
                                          <option value={retailer_details.id}>
                                            {retailer_details.storename}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["id"]}
                                </span>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div className="form-group">
                                <label>Wallet Points</label>
                                <input
                                  type="ftext"
                                  name="wallet_points"
                                  className="form-control"
                                  value={
                                    this.state.fields["wallet_points"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "wallet_points"
                                  )}
                                  placeholder="Wallet Points"
                                />
                                <span className="cRed">
                                  {this.state.errors["wallet_points"]}
                                </span>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div className="form-group">
                                <label>Wallet point value</label>
                                <input
                                  type="ftext"
                                  name="wallet_points_amount"
                                  className="form-control"
                                  value={
                                    this.state.fields["wallet_points_amount"] ||
                                    ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "wallet_points_amount"
                                  )}
                                  placeholder="Wallet point value"
                                />
                                <span className="cRed">
                                  {this.state.errors["wallet_points_amount"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div class="col-md-4">
                              <div className="form-group">
                                <label>Deduction points</label>
                                <input
                                  type="ftext"
                                  name="deduct_points"
                                  className="form-control"
                                  value={
                                    this.state.fields["deduct_points"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "deduct_points"
                                  )}
                                  placeholder="Deduction points"
                                />
                                <span className="cRed">
                                  {this.state.errors["deduct_points"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row"></div>

                          <div className="row">
                            <div className="form-group col-md-12">
                              <button type="submit" className="save_btn">
                                Save
                              </button>{" "}
                              <Link
                                to="/admin/ViewRetailersWalletList"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <ToastContainer />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
