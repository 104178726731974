import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import { Carousel } from "react-responsive-carousel";
import Patnewcss from "../../public/css/patient/style-new.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl";
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Patientheader";
import PatientMenu from "../patient/Patientmenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientSideMenu from "../patient/Patientsidemenu";
import FileBase64 from "react-file-base64";
import { target } from "glamor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

const upComingDetailsView = [];
const pastAppointmentsDetailsView = [];
export default class patientdashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showlogin: true,
      showsignup: false,
      Language: "",
      name: "",
      user_mobile: "",
      email_id: "",
      token: "",
      userId: "",
      profile_image: "",
      upComing: [],
      patientProfileImage: "",
      rating: "",
      hasAppointment: "collapse-hide",
      joinCheck: false,
      doctorSlotsfromdate: new Date(),
      selectedAppointmentId: "",
    };

    this.addPatient = this.addPatient.bind(this);
    this.navigate = this.navigate.bind(this);
    this.getAppointmentDetails = this.getAppointmentDetails.bind(this);
    this.getAppointmentDetailsUpcoming =
      this.getAppointmentDetailsUpcoming.bind(this);
    this.openMap = this.openMap.bind(this);
  }
  componentDidUpdate = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
      //
    } else {
      this.state.Language = "en-us";
    }
  };
  openMap = (event) => {
    window.open("https://maps.google.com?q=17.3529,78.5357", "_blank");
  };

  // To get detais after first render
  componentDidMount = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }

    var retrievedObject = ls.get("userObjEnc");
    let userObj = retrievedObject;
    if (userObj == "") {
      window.location.href = "./patientLogin";
    }
    if (typeof userObj != undefined) {
      this.setState({
        name: userObj.name,
        user_mobile: userObj.mobile_number,
        email_id: userObj.email,
        token: userObj.accessToken,
        patientId: userObj.patient_id,
        profile_image: "",
      });
      this.forceUpdate();
    }

    this.getAppointmentDetails(userObj.patient_id);
    this.getAppointmentDetailsUpcoming(userObj.patient_id);
    this.getProfileDetails(userObj.patient_id);
    const interval = setInterval(() => {
      this.getAppointmentDetailsUpcoming(userObj.patient_id);
    }, 15000);
    const intervalUpcoming = setInterval(() => {
      this.getAppointmentDetails(userObj.patient_id);
    }, 25000);
  };
  getProfileDetails = (patientId) => {
    if (patientId != "") {
      let url_path = "api/Patients/" + patientId;
      let patientDob = "";
      Httpconfig.httptokenget(Constant.siteurl + url_path, {}).then(
        (response) => {
          let patientDob = "";
          if (response.data.status == 200 && response.data.error == false) {
            let PatientGender = response.data.data[0].gender;
            let patientProfileImage = "";
            if (response.data.data[0].profile_pic != null) {
              patientProfileImage =
                response.data.data[0].profile_pic;
            }
            this.setState({
              patientProfileImage: patientProfileImage,
              PatientGender: PatientGender,
            });
          }
        }
      );
    }
  };
  getAppointmentDetails = (patientId) => {
    var timezone = moment.tz.guess();
    var postData = {
      patient_id: patientId,
      timezone: timezone,
      status: [3, 4, 6, 7],
      orderBy: "DESC",
    };
    var slotsMorningArray = [];
    var slotsNoonArray = [];
    var slotsEveningArray = [];
    var slotsNightArray = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/displayupcomingappointments",
      postData
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let DocName = "";

          /*Past Appointments */
          const pastAppointmentsDetailsView = response.data.data.map(
            (pastDetails) => {
              let patientAge = "";

              if (pastDetails.patient_tbl.dob != null) {
                let dob = pastDetails.patient_tbl.dob.split("T");
                let age = dob[0];
                let ageYears = moment().diff(age, "years");
                let ageMonths = moment().diff(age, "months");
                let ageDays = moment().diff(age, "days");

                if (ageYears > 0) {
                  if (ageYears == 1) {
                    patientAge = ageYears + " Year";
                  } else {
                    patientAge = ageYears + " Years";
                  }
                } else if (ageMonths > 0) {
                  if (ageMonths == 1) {
                    patientAge = ageMonths + " Month";
                  } else {
                    patientAge = ageMonths + " Months";
                  }
                } else if (ageDays) {
                  patientAge = ageDays + " Days";
                }
              }
              var now = moment(new Date());
              var end = moment(pastDetails.appointment_datetime);
              var duration = moment.duration(now.diff(end));
              var days = duration.asDays();

              var mindiff = moment().diff(end, "minutes");

              if (pastDetails.status == 3 || pastDetails.status == 4) {
                DocName =
                  pastDetails.doctor_tbl.tbl_user.name.charAt(0).toUpperCase() +
                  pastDetails.doctor_tbl.tbl_user.name.slice(1);
                let doctorImage = "";
                let doctorAvailable = "";
                let patientImage = "";
                let gender = "";
                let docId = pastDetails.doctor_tbl.id;

                if (pastDetails.doctor_tbl) {
                  if (pastDetails.doctor_tbl.profile_pic != "") {
                    let img = pastDetails.doctor_tbl.profile_pic;
                    doctorImage = img;
                  } else {
                    if (pastDetails.doctor_tbl.gender == "Male") {
                      doctorImage =
                        "../images/patient/img/Profile/Male_doctor.svg";
                    } else {
                      doctorImage =
                        "../images/patient/img/Profile/Female_doctor.svg";
                    }
                  }
                } else {
                  if (pastDetails.doctor_tbl.gender == "Male") {
                    doctorImage =
                      "../images/patient/img/Profile/Male_doctor.svg";
                  } else {
                    doctorImage =
                      "../images/patient/img/Profile/Female_doctor.svg";
                  }
                }

                if (pastDetails.doctor_tbl.is_available != null) {
                  doctorAvailable = pastDetails.doctor_tbl.is_available;
                }

                if (pastDetails.patient_tbl.profile_pic != null) {
                  patientImage = pastDetails.patient_tbl.profile_pic;
                } else {
                  if (pastDetails.patient_tbl.gender.toLowerCase() == "male") {
                    patientImage =
                      "../images/patient/img/Profile/Male_patient.svg";
                  } else {
                    patientImage =
                      "../images/patient/img/Profile/Female_patient.svg";
                  }
                }

                return (
                  <React.Fragment>
                    <div class="schedule_box">
                      <div class="appoint_bar">
                        <p>
                          <span>
                            {moment(pastDetails.appointment_datetime).format(
                              "dddd"
                            )}{" "}
                            {moment(pastDetails.appointment_datetime).format(
                              "Do MMMM,YYYY"
                            )}
                          </span>{" "}
                          <span>
                            {moment(pastDetails.appointment_datetime).format(
                              "LT"
                            )}{" "}
                          </span>
                          {pastDetails.status != 4 ? (
                            <span class="result_text">
                              {translate("Completed")}
                            </span>
                          ) : (
                            <span class="result_cancelled result_text">
                              {pastDetails.status == 4 &&
                                pastDetails.refund_status == 6
                                ? translate(
                                  "Cancelled, Refund Queued For Processing"
                                )
                                : pastDetails.status == 4 &&
                                  pastDetails.refund_status == 7
                                  ? translate("Cancelled,Amount Refunded")
                                  : translate("Cancelled")}
                            </span>
                          )}
                        </p>
                      </div>
                      <div class="appoint_main">
                        <div class="doctor_content">
                          <div class="doct_img">
                            <img src={doctorImage} />
                          </div>
                          <div class="doct_det">
                            <h3>Dr. {DocName}</h3>
                            <p>{pastDetails.doctor_tbl.speciality_name}</p>
                          </div>
                        </div>
                        <div class="app_wth">
                          <img src="../images/patient/img/Ordermedicine/video.svg" />
                          <p>
                            {translate("You have")} <br />
                            {translate("appointment with")}
                          </p>
                        </div>
                        <div class="pat_content">
                          <div class="pat_img">
                            <img src={patientImage} />
                          </div>
                          <div class="pat_det">
                            <h3>
                              {pastDetails.patient_tbl.gender.toLowerCase() ==
                                "male"
                                ? "Mr."
                                : "Ms."}{" "}
                              {pastDetails.patient_tbl.name
                                .charAt(0)
                                .toUpperCase() +
                                pastDetails.patient_tbl.name.slice(1)}{" "}
                              <span>
                                (
                                {pastDetails.patient_tbl.master_relationship
                                  ? pastDetails.patient_tbl.master_relationship
                                    .relation_name
                                  : ""}
                                )
                              </span>
                            </h3>
                            <p>
                              {patientAge},{" "}
                              {translate(
                                pastDetails.patient_tbl.gender
                                  .charAt(0)
                                  .toUpperCase() +
                                pastDetails.patient_tbl.gender.slice(1)
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      {pastDetails.next_appointment_datetime != null &&
                        pastDetails.prescription_id != null ? (
                        <div class="book_sec" onClick={this.navigatepursose}>
                          <p>
                            {translate(
                              "The doctor advised you for a followup appointment on "
                            )}
                            {moment(
                              pastDetails.next_appointment_datetime
                            ).format("Do MMMM,YYYY")}
                          </p>

                          <p class="book_btn">{translate("Book")}</p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div class="pro_sec_details">
                        <div class="row">
                          {pastDetails.status != 4 &&
                            pastDetails.prescription_id != null ? (
                            <div class="col-md-6 col-sm-6 col-xs-6 pro_mid">
                              <a
                                href="#"
                                onClick={this.showPrescription.bind(
                                  this,
                                  pastDetails.prescription_id
                                )}
                              >
                                {" "}
                                <h4
                                  data-toggle="modal"
                                  data-target="#presc_preview_Modal"
                                >
                                  <img src="../images/patient/img/Ordermedicine/prescription_upload.svg" />
                                  {translate("Prescription")}
                                </h4>
                              </a>
                            </div>
                          ) : (
                            <div class="col-md-6 col-sm-6 col-xs-6 pro_mid"></div>
                          )}

                          {Object.keys(pastDetails.appointment_feedback_tbls)
                            .length > 0 ? (
                            <div class="col-md-6 col-sm-6 col-xs-6 pro_mid">
                              {" "}
                              <h4>
                                <img src="../images/patient/img/Ordermedicine/feedback_img.svg" />{" "}
                                Thank you for the feedback
                              </h4>{" "}
                            </div>
                          ) : (
                            <div class="col-md-6 col-sm-6 col-xs-6 pro_mid">
                              <h4
                                data-toggle="modal"
                                data-target="#feedback_modal"
                                onClick={this.handleFeedback.bind(
                                  this,
                                  docId,
                                  pastDetails.id
                                )}
                              >
                                <img src="../images/patient/img/Ordermedicine/feedback_img.svg" />
                                {translate("Feedback")}
                              </h4>
                            </div>
                          )}
                        </div>

                        <div
                          class="collapse multi-collapse"
                          id={pastDetails.appointment_confirm_id}
                        >
                          <div class="">
                            <div class="order_pro_panel">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="ord_calendar">
                                    <h2>
                                      <img src="img/My Appointment.svg" />{" "}
                                      Schedule your Appointment
                                    </h2>
                                    <div class="wrapper">
                                      <DatePicker
                                        name="start_date"
                                        autoComplete="off"
                                        id={docId}
                                        className="dateInput"
                                        placeholderText="Start Date"
                                        onChange={(value, e) =>
                                          this.getUserSelectedDate(
                                            value,
                                            e,
                                            docId,
                                            pastDetails.appointment_confirm_id
                                          )
                                        }
                                        dateFormat="d-MM-yyyy"
                                        highlightDates={[
                                          {
                                            "react-datepicker__day--highlighted":
                                              new Array(this.state.numberOfDays)
                                                .fill()
                                                .map((_, i) => {

                                                  let fromdate = moment(
                                                    this.state
                                                      .doctorSlotsfromdate
                                                  ).format("YYYY-MM-DD");
                                                  const d = new Date(fromdate);
                                                  d.setDate(d.getDate() + i);
                                                  return d;
                                                }),
                                          },
                                        ]}
                                        inline
                                        calendarIcon
                                        minDate={this.state.doctorSlotsfromdate}
                                        maxDate={this.state.doctorSlotstodate}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                  <div
                                    id={
                                      "morning-" +
                                      pastDetails.appointment_confirm_id
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: slotsMorningArray,
                                    }}
                                  />

                                  <div
                                    id={
                                      "afternoon-" +
                                      pastDetails.appointment_confirm_id
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: slotsNoonArray,
                                    }}
                                  />

                                  <div
                                    id={
                                      "evening-" +
                                      pastDetails.appointment_confirm_id
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: slotsEveningArray,
                                    }}
                                  />

                                  <div
                                    id={
                                      "night-" +
                                      pastDetails.appointment_confirm_id
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: slotsNightArray,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            }
          );

          this.setState({
            pastAppointmentsDetailsView: pastAppointmentsDetailsView,
          });
        } else {
          return false;
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  getAppointmentDetailsUpcoming = (patientId) => {
    var timezone = moment.tz.guess();
    var postData = {
      patient_id: patientId,
      timezone: timezone,
      status: [1, 2],
      orderBy: "ASC",
    };
    var slotsMorningArray = [];
    var slotsNoonArray = [];
    var slotsEveningArray = [];
    var slotsNightArray = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/displayupcomingappointments",
      postData
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          let DocName = "";

          const upComingDetailsView = response.data.data.map(
            (upComingDetails, num) => {
              if (upComingDetails.doctor_tbl != null) {
                if (
                  upComingDetails.status == 1 ||
                  upComingDetails.status == 2
                ) {
                  if (upComingDetails.doctor_tbl.tbl_user.name != null) {
                    DocName =
                      upComingDetails.doctor_tbl.tbl_user.name
                        .charAt(0)
                        .toUpperCase() +
                      upComingDetails.doctor_tbl.tbl_user.name.slice(1);
                  }
                  if (upComingDetails.status == 2 && !this.state.joinCheck) {
                    toast.success(
                      "Dr." +
                      DocName +
                      " has started the video consultation call , please join the call to connect to the consultation."
                    );
                  }

                  let patientAge = "";
                  if (upComingDetails.patient_tbl.dob != null) {
                    let dob = upComingDetails.patient_tbl.dob.split("T");
                    let age = dob[0];
                    let ageYears = moment().diff(age, "years");
                    let ageMonths = moment().diff(age, "months");
                    let ageDays = moment().diff(age, "days");
                    if (ageYears > 0) {
                      if (ageYears == 1) {
                        patientAge = ageYears + " Year";
                      } else {
                        patientAge = ageYears + " Years";
                      }
                    } else if (ageMonths > 0) {
                      if (ageMonths == 1) {
                        patientAge = ageMonths + " Month";
                      } else {
                        patientAge = ageMonths + " Months";
                      }
                    } else if (ageDays) {
                      patientAge = ageDays + " Days";
                    }
                  }
                  var now = moment(new Date());
                  var end = moment(upComingDetails.appointment_datetime);
                  var duration = moment.duration(now.diff(end));
                  var days = duration.asDays();
                  var mindiff = end.diff(moment(), "minutes");

                  var two_hrs = duration.asHours();

                  var apptShow = "Hide";
                  var two_hrs = -duration.asMinutes();
                  if (parseInt(two_hrs) >= 120) {
                    apptShow = "show";
                  }

                  let doctorImage = "";

                  let doctorAvailable = "";
                  let patientImage = "";
                  let docpic = "";
                  let doctorId = upComingDetails.doctor_tbl.id;

                  docpic = upComingDetails.doctor_tbl.profile_pic;

                  if (docpic != "") {
                    doctorImage =  docpic;
                  } else {
                    if (
                      upComingDetails.doctor_tbl.gender.toLowerCase() == "male"
                    ) {
                      doctorImage =
                        "../images/patient/img/Profile/Male_doctor.svg";
                    } else {
                      doctorImage =
                        "../images/patient/img/Profile/Female_doctor.svg";
                    }
                  }

                  if (upComingDetails.doctor_tbl.is_available != null) {
                    doctorAvailable = upComingDetails.doctor_tbl.is_available;
                  }

                  if (upComingDetails.patient_tbl.profile_pic != null) {
                    patientImage = upComingDetails.patient_tbl.profile_pic;
                  } else {
                    if (
                      upComingDetails.patient_tbl.gender.toLowerCase() == "male"
                    ) {
                      patientImage =
                        "../images/patient/img/Profile/Male_patient.svg";
                    } else {
                      patientImage =
                        "../images/patient/img/Profile/Female_patient.svg";
                    }
                  }

                  return (
                    <React.Fragment>
                      <div class="schedule_box">
                        <div class="appoint_bar">
                          <p>
                            {translate("Your appointment scheduled on")}{" "}
                            <span>
                              <img src="../images/patient/img/Profile/Date.svg" />
                              {moment(
                                upComingDetails.appointment_datetime
                              ).format("dddd")}{" "}
                              {moment(
                                upComingDetails.appointment_datetime
                              ).format("Do MMMM,YYYY")}
                            </span>{" "}
                            <span>
                              <img src="../images/patient/img/Profile/Time.svg" />
                              {moment(
                                upComingDetails.appointment_datetime
                              ).format("LT")}{" "}
                            </span>
                          </p>
                        </div>
                        <div class="appoint_main">
                          <div class="doctor_content">
                            <div class="doct_img">
                              <img src={doctorImage} />
                            </div>
                            <div class="doct_det">
                              <h3>Dr. {DocName}</h3>
                              <p>
                                {upComingDetails.doctor_tbl.speciality_name}
                              </p>
                            </div>
                          </div>
                          <div class="app_wth">
                            <img src="../images/patient/img/Ordermedicine/video.svg" />
                            <p>
                              {translate("You have")} <br />
                              {translate("appointment with")}
                            </p>
                          </div>
                          <div class="pat_content">
                            <div class="pat_img">
                              <img src={patientImage} />
                            </div>
                            <div class="pat_det">
                              <h3>
                                {upComingDetails.patient_tbl.gender.toLowerCase() ==
                                  "male"
                                  ? "Mr. "
                                  : "Ms. "}{" "}
                                {upComingDetails.patient_tbl.name
                                  .charAt(0)
                                  .toUpperCase() +
                                  upComingDetails.patient_tbl.name.slice(
                                    1
                                  )}{" "}
                                <span>
                                  (
                                  {
                                    upComingDetails.patient_tbl
                                      .master_relationship.relation_name
                                  }
                                  )
                                </span>
                              </h3>
                              <p>
                                {patientAge},{" "}
                                {translate(
                                  upComingDetails.patient_tbl.gender
                                    .charAt(0)
                                    .toUpperCase() +
                                  upComingDetails.patient_tbl.gender.slice(1)
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="cancel_app">
                          <p>
                            {translate("Medium")} :{" "}
                            <span>{translate("Audio & Video")}</span>
                          </p>
                          {/* {upComingDetails.module_type == "consult_later" &&
                          upComingDetails.reschedule_status == 1 &&
                          apptShow == "show" ? ( */}
                          {upComingDetails.module_type == "consult_later" ?
                            <p
                              data-toggle="collapse"
                              onClick={this.showCalender.bind(
                                this,
                                upComingDetails.appointment_confirm_id,
                                doctorId
                              )}
                              href={
                                "#" + upComingDetails.appointment_confirm_id
                              }
                              role="button"
                              aria-expanded="false"
                              aria-controls="multiCollapseExample1"
                            >
                              <img src="../images/patient/img/Ordermedicine/My Appointment.svg" />{" "}
                              Reschedule
                            </p> : ""}
                          {/* ) : (
                            <p>
                              You can cancel the appointment before 2 Hrs of
                              slot time.
                            </p>
                          )} */}

                          {/* {upComingDetails.module_type == "consult_later" &&
                          apptShow == "show" ? ( */}
                          {upComingDetails.module_type == "consult_later" ?
                          <p
                            class="cancel_btn"
                            data-toggle="modal"
                            data-target="#cancel_app"
                            id={upComingDetails.appointment_confirm_id}
                            onClick={this.handleClick.bind(
                              this,
                              upComingDetails.appointment_confirm_id
                            )}
                          >
                            {translate("Cancel Appointment")}
                          </p>
                          :""}
{/* ) : (
                            ""
                          )} */}
                        </div>

                        {upComingDetails.status == 2 ? (
                          <div
                            class="join_section"
                            id={upComingDetails.appointment_confirm_id}
                            onClick={this.navigate.bind(this, DocName)}
                          >
                            <a href="#">
                              {upComingDetails.status == 2 ? (
                                <p id={upComingDetails.appointment_confirm_id}>
                                  {translate("Join Now")}
                                </p>
                              ) : (
                                <p id={upComingDetails.appointment_confirm_id}>
                                  {translate("Please wait for Doctor to join")}
                                </p>
                              )}
                            </a>
                          </div>
                        ) : (
                          <div
                            class="join_section"
                            id={upComingDetails.appointment_confirm_id}
                          >
                            <a>
                              <p id={upComingDetails.appointment_confirm_id}>
                                {translate("Appointment Booked")}
                              </p>
                            </a>
                          </div>
                        )}

                        <div
                          class="collapse multi-collapse"
                          id={
                            "reschedule-" +
                            upComingDetails.appointment_confirm_id
                          }
                        >
                          <div class="">
                            <div class="order_pro_panel">
                              <div className="cross_bar">
                                <img
                                  class="remove_icon"
                                  src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                                  onClick={this.hideCalender.bind(
                                    this,
                                    upComingDetails.appointment_confirm_id
                                  )}
                                />
                              </div>
                              <div class="row">
                                <div class="col-md-7 col-lg-7">
                                  <div class="ord_calendar">
                                    <h2>
                                      <img src="../images/patient/img/Doctors_List/Schedule_appointment.svg" />{" "}
                                      Schedule your Appointment
                                    </h2>
                                    <div class="wrapper">
                                      <DatePicker
                                        name="start_date"
                                        autoComplete="off"
                                        id={doctorId}
                                        className="dateInput"
                                        placeholderText="Start Date"
                                        onChange={(value, e) =>
                                          this.getUserSelectedDate(
                                            value,
                                            e,
                                            doctorId,
                                            upComingDetails.appointment_confirm_id
                                          )
                                        }
                                        dateFormat="d-MM-yyyy"
                                        highlightDates={[
                                          {
                                            "react-datepicker__day--highlighted":
                                              new Array(this.state.numberOfDays)
                                                .fill()
                                                .map((_, i) => {
                                                  let fromdate = moment(
                                                    this.state
                                                      .doctorSlotsfromdate
                                                  ).format("YYYY-MM-DD");
                                                  const d = new Date(fromdate);
                                                  d.setDate(d.getDate() + i);
                                                  return d;
                                                }),
                                          },
                                        ]}
                                        inline
                                        calendarIcon
                                        minDate={this.state.doctorSlotsfromdate}
                                        maxDate={this.state.doctorSlotstodate}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div
                                        id={
                                          "morning-" +
                                          upComingDetails.appointment_confirm_id
                                        }
                                      />
                                    </div>
                                    <div class="col-md-12">
                                      <div
                                        id={
                                          "afternoon-" +
                                          upComingDetails.appointment_confirm_id
                                        }
                                      />
                                    </div>
                                    <div class="col-md-12">
                                      <div
                                        id={
                                          "evening-" +
                                          upComingDetails.appointment_confirm_id
                                        }
                                      />
                                    </div>
                                    <div class="col-md-12">
                                      <div
                                        id={
                                          "night-" +
                                          upComingDetails.appointment_confirm_id
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div
                                    className="collapse-hide next_btn text-right "
                                    id={
                                      "hasAppointmentSec-" +
                                      upComingDetails.appointment_confirm_id
                                    }
                                  >
                                    <a>
                                      {" "}
                                      <button
                                        className="pay_now prof_btn"
                                        id={doctorId}
                                        onClick={this.rescheduelAppointment.bind(
                                          this,
                                          upComingDetails.appointment_confirm_id
                                        )}
                                      >
                                        {translate("Continue")}
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              }
            }
          );

          this.setState({
            upComingDetailsView: upComingDetailsView,
          });
        } else {
          return false;
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  addPatient = () => {
    const userObj = {
      name: this.state.name,
      user_mobile: this.state.mobile_number,
      email_id: this.state.email,
      token: this.state.accessToken,
      patient_id: this.state.patient_id,
      profile_image: "",
    };
    this.props.history.push("/Patientprofile", { userData: userObj });
  };
  navigate = (DocName, event) => {
    this.state.joinCheck = true;
    this.forceUpdate();
    let id = event.currentTarget.id;
    let doc_name = DocName;
    var retrievedselectedAppointment = localStorage.getItem(
      "PatientselectedAppointmentEnc"
    );
    if (retrievedselectedAppointment != null) {
      ls.remove("PatientselectedAppointmentEnc");
    }
    localStorage.setItem("DoctotconsultName", doc_name);
    ls.set("PatientselectedAppointmentEnc", id);

    this.props.history.push("/Patientchecksettings");
  };

  handleLanguage = (langValue) => {
    this.setState({ Language: langValue });
  };

  getFiles(files) {
    this.setState({ files: files });

    let patientId = this.state.patientId;
    let patientProfileImage = this.state.files[0].base64;
    this.state.patientProfileImage = patientProfileImage;
    this.forceUpdate();
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Users/uploadimages/" + patientId,
      {
        profile_pic: this.state.files[0].base64,
      }
    )
      .then((response) => {
        toast.success("👌 Profile Image Updated Successfully", {
          position: "bottom-center",
        });
      })
      .catch((error) => {
        this.props.history.push("/patienthealthprofile");
        toast.error(error);
      });
  }
  selectedReason = (event) => {
    var reason = $("input[name='cancelappointment']:checked").val();
    if (reason == "other") {
      $("#otherCancellationReasons").removeClass("collapse-hide");
      $("#otherCancellationReasons").addClass("collapse-show");
    } else {
      $("#otherCancellationReasons").removeClass("collapse-show");
      $("#otherCancellationReasons").addClass("collapse-hide");
    }
  };
  cancelAppointment = (event) => {
    var reason = "";
    $("#otherCancellationReasons").removeClass("collapse-hide");
    if (!$("input[name='cancelappointment']:checked").val()) {
      $("#otherCancellationReasons").removeClass("collapse-show");
      $("#otherCancellationReasons").addClass("collapse-hide");
      toast.error("select reason for cancellation");
      return false;
    } else {
      reason = $("input[name='cancelappointment']:checked").val();

      if (reason == "other")
        if ($("#otherCancellationReasons").val() != "") {
          reason = $("#otherCancellationReasons").val();
        } else {
          $("#otherCancellationReasons").removeClass("collapse-show");
          $("#otherCancellationReasons").addClass("collapse-hide");
          toast.error("select reason for cancellation");
          return false;
        }
    }
    if (reason != "") {
      $("#cancel_app").hide();
      let refundAmount = 0;

      let appointmentId = this.state.selectedAppointmentId;
      let patientId = this.state.patientId;
      let timeZone = moment.tz.guess();

      Httpconfig.httptokenput(
        Constant.siteurl +
        "api/PatientAppointment/updateConsultationStatus/" +
        appointmentId,
        {
          status: "4",
          reason: reason,
          timeZone,
        }
      )
        .then((response) => {
          if (!response.data.error && response.data.status == 200) {
            this.getAppointmentDetails(patientId);
            toast.success("Appointment cancelled successfully", {});
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          this.props.history.push("/patienthealthprofile");
          toast.error(error);
        });
    } else {
    }
  };
  handleClick = (appoinmentData) => {
    this.state.selectedAppointmentId = appoinmentData;
    $("input:radio[name='cancelappointment']").each(function (i) {
      this.checked = false;
    });
    this.forceUpdate();
  };
  closePopup = () => {
    $("#cancel_app").hide();
  };
  showPrescription = (url) => {
    $("#prescriptionImg").attr("src", url);
    this.forceUpdate();
  };
  feedBack = (event) => {
    let id = event.currentTarget.id;
    if (id > 0) {
      for (let i = 0; i <= id; i++) {
        $("#" + i).addClass("active");
      }
      this.state.rating = id;
      this.forceUpdate();
    } else {
      this.state.rating = id;
      this.forceUpdate();
    }
  };
  saveFeedBack = () => {
    var comment = "";
    comment = this.state.feedbackComment;
    var rating = "";
    rating = this.state.rating;
    let feedbackDoctorId = this.state.feedbackDoctorId;
    let patientId = this.state.patientId;
    let appointmentId = this.state.selectedFeedBackAppointmentId;
    if (rating == "") {
      toast.error("Please give rating to submit");
      return false;
    }
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/PatientAppointment/createAppointmentFeedback",
      {
        doctor_id: feedbackDoctorId,
        patient_id: patientId,
        title: "",
        feedback: comment,
        rating: rating,
        row_id: appointmentId,
      }
    )
      .then((response) => {
        toast.success(response.data.message, {
          position: "bottom-center",
        });
        $("#feedback_modal").hide();
        this.state.feedbackComment = "";
        this.state.rating = "";
        $("#feedbackComment").val("");
        this.forceUpdate();
      })
      .catch((error) => {
        this.props.history.push("/patienthealthprofile");
        toast.error(error);
      });
  };
  handleComment = (event) => {
    this.state.feedbackComment = event.target.value;
    this.forceUpdate();
  };

  handleFeedback = (docId, appointmentid) => {
    for (let i = 0; i <= 5; i++) {
      $("#" + i).removeClass("active");
    }
    $("#feedbackComment").val("");
    this.state.selectedFeedBackAppointmentId = appointmentid;
    this.state.feedbackDoctorId = docId;
    this.forceUpdate();
  };

  navigatepursose = () => {
    this.props.history.push("/Patientconsultationpurpose");
  };

  //Get user selected dates
  getUserSelectedDate = (value, event, id, appointmentId) => {
    let doctorId = id;
    let date = new Date(value);
    let selectedDate =
      date.getFullYear() +
      "-" +
      Number(date.getMonth() + 1) +
      "-" +
      date.getDate();

    this.fetchUserSelectedSlots(selectedDate, doctorId, appointmentId);
  };

  // Fetche the selelcted date slots
  fetchUserSelectedSlots(selectedDate, doctorId, appointmentId) {
    let timeZone = moment.tz.guess();
    var slotsMorningArray = [];
    var slotsNoonArray = [];
    var slotsEveningArray = [];
    var slotsNightArray = [];

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/telemedicine/patientAppointmentSlots",
      {
        doctor_id: doctorId,
        consultation_date: selectedDate,
        module_type: "telemedicine-app",
        timeZone: timeZone,
      }
    ).then((response) => {
      if (response.status == 200) {
        let resultCount = Object.keys(response.data.result).length;
        let slotsCount = Object.keys(response.data.result).length;
        if (resultCount > 0) {
          var hasAppointment = 0;
          const morningSlots = response.data.result[0];
          const afternoonSlots = response.data.result[1];
          const eveningSlots = response.data.result[2];
          const nightSlots = response.data.result[3];
          $("#" + doctorId).addClass("collapse-show");
          var morningArr = morningSlots["time_slots"];
          var afternoonArr = afternoonSlots["time_slots"];
          var eveningArr = eveningSlots["time_slots"];
          var nightArr = nightSlots["time_slots"];

          if (morningArr) {
            var nightLength = morningArr.length;
            if (nightLength > 0) {
              hasAppointment = hasAppointment + 1;
            }
            var mrng = "";
            mrng += '<div class="sch_time">';
            for (var i = 0; i < nightLength; i++) {
              if (i == 0) {
                mrng += "<h3>Morning</h3>";
              }
              mrng += "<p class='timings'>" + morningArr[i] + "</p>";
            }
            mrng += "</div>";
            slotsMorningArray = mrng;

            $("#morning-" + appointmentId).html(mrng);
            this.state.processlater = "col-md-12 collapse-show";
          } else {
            $("#morning-" + appointmentId).html("");
          }

          if (afternoonArr) {
            var noonLength = afternoonArr.length;
            if (noonLength > 0) {
              hasAppointment = hasAppointment + 1;
            }

            var noon = "";
            noon += '<div class="sch_time">';
            for (var i = 0; i < noonLength; i++) {
              if (i == 0) {
                noon += "<h3>After noon</h3>";
              }
              noon += "<p class='timings'>" + afternoonArr[i] + "</p>";
            }
            noon += "</div>";
            slotsNoonArray = noon;
            $("#afternoon-" + appointmentId).html(noon);
            this.state.processlater = "col-md-12 collapse-show";
          } else {
            $("#afternoon-" + appointmentId).html("");
          }

          if (eveningArr) {
            var evngLength = eveningArr.length;
            if (evngLength > 0) {
              hasAppointment = hasAppointment + 1;
            }

            var evng = "";
            evng += '<div class="sch_time">';
            for (var i = 0; i < evngLength; i++) {
              if (i == 0) {
                evng += "<h3>Evening</h3>";
              }
              evng += "<p class='timings'>" + eveningArr[i] + "</p>";
            }
            evng += "</div>";
            slotsEveningArray = evng;
            $("#evening-" + appointmentId).html(evng);
            this.state.processlater = "col-md-12 collapse-show";
          } else {
            $("#evening-" + appointmentId).html("");
          }

          if (nightArr) {
            var nightLength = nightArr.length;
            if (nightLength > 0) {
              hasAppointment = hasAppointment + 1;
            }

            var night = "";
            night += '<div class="sch_time">';
            for (var i = 0; i < nightLength; i++) {
              if (i == 0) {
                night += "<h3>Night</h3>";
              }
              night += "<p class='timings'>" + nightArr[i] + "</p>";
            }
            night += "</div>";
            slotsNightArray = night;
            $("#night-" + appointmentId).html(night);
            this.state.processlater = "col-md-12 collapse-show";
          } else {
            $("#night-" + appointmentId).html("");
          }

          if (hasAppointment > 0) {
            $("#hasAppointmentFirst-" + appointmentId).removeClass(
              "collapse-hide"
            );
            $("#hasAppointmentFirst-" + appointmentId).addClass(
              "collapse-show"
            );
            $("#hasAppointmentSec-" + appointmentId).removeClass(
              "collapse-hide"
            );
            $("#hasAppointmentSec-" + appointmentId).addClass("collapse-show");
          }

          var i = 0;

          var time = document.getElementsByClassName("timings");
          for (i = 0; i < time.length; i++) {
            time[i].addEventListener("click", function (event) {
              var selelcedT = this.innerHTML;
              let sTime = moment(selelcedT, ["h:mm A"]).format("HH:mm:ss");
              let seldate = selectedDate + " " + sTime;

              let seletedSlot_time = moment(seldate, [
                "YYYY-MM-DD HH:mm A",
              ]).format("YYYY-MM-DD HH:mm:ss");
              ls.set("seletedSlot_timeEnc", seletedSlot_time);
              var lang = localStorage.getItem("seletedSlot_time");
              $(".timings").removeClass("highlet_button");
              $(this).addClass("highlet_button");
              $("#hasAppointment-" + appointmentId).remove("collapse-hide");
            });
            ls.set("seletedSlot_timeEnc", "");
          }
        } else {
          toast.error("Slots not Available");
          $("#hasAppointmentFirst-" + appointmentId).removeClass(
            "collapse-show"
          );
          $("#hasAppointmentFirst-" + appointmentId).addClass("collapse-hide");
          $("#hasAppointmentSec-" + appointmentId).removeClass("collapse-show");
          $("#hasAppointmentSec-" + appointmentId).addClass("collapse-hide");
          $("#morning-" + appointmentId).html("");
          $("#afternoon-" + appointmentId).html("");
          $("#evening-" + appointmentId).html("");
          $("#night-" + appointmentId).html("");

          this.setState({
            morningSlotsLabel: "",
            morningSlots: "",
            afternoonLabel: "",
            afternoonSlots: "",
            eveningSlotsLabel: "",
            eveningSlots: "",
            nightSlotsLabel: "",
            nightSlots: "",
            slotsCount: "",
          });
          this.forceUpdate();
        }
      }
    });
  }
  //show reschedule calender
  showCalender = (id, doctorId) => {
    this.fetchDoctorSlots(id, doctorId);
  };

  // hide reschedule calender
  hideCalender = (id) => {
    $("#reschedule-" + id).removeClass("show");
  };

  //get the available slots days
  fetchDoctorSlots = (id, doctorId) => {
    Httpconfig.httptokenpost(Constant.siteurl + "api/telemedicine/getSlots", {
      doctor_id: doctorId,
    }).then((response) => {
      if (response.data.result) {
        if (response.data.result) {
          var doctorSlotsfromdate = new Date(response.data.result.fromDate);
          if (new Date(doctorSlotsfromdate) < new Date()) {
            doctorSlotsfromdate = new Date();
          }
          var doctorSlotstodate = new Date(response.data.result.toDate);
          var numberOfDays = 0;
          if (
            moment(doctorSlotstodate).diff(
              moment(doctorSlotsfromdate).format("YYYY-MM-DD"),
              "days"
            ) >= 0
          ) {
            var numberOfDays = moment(doctorSlotstodate)
              .add(1, "days")
              .diff(moment(doctorSlotsfromdate).format("YYYY-MM-DD"), "days");
          }

          if (numberOfDays > 0) {
            this.state.hasAppointment = "collapse-show";
          }
          this.setState({
            doctorSlotsfromdate: doctorSlotsfromdate,
            doctorSlotstodate: doctorSlotstodate,
            numberOfDays: numberOfDays,
          });
          this.forceUpdate();
          this.getAppointmentDetails(this.state.patientId);
          $("#reschedule-" + id).addClass("show");
        }
      }
    });
  };

  // Reschedule Appointment
  rescheduelAppointment = (appointmentId) => {
    var selectedDatetime = ls.get("seletedSlot_timeEnc");
    var timezone = moment.tz.guess();
    if (selectedDatetime) {
      Httpconfig.httptokenput(
        Constant.siteurl +
        "api/PatientAppointment/patientAppointmentreschedule/" +
        appointmentId,
        {
          appointment_datetime: selectedDatetime,
          timezone: timezone,
        }
      )
        .then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            toast.success("Your Appointment has rescheduled successfully");
            this.hideCalender(appointmentId);
            this.getAppointmentDetails(this.state.patientId);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("please select time slot");
    }
  };

  render() {
    const { languages_data } = this.state;
    const BLOCK = { diplay: "block" };
    const NONE = { diplay: "none" };
    var currentDate = new Date();
    var numberOfDaysToAdd = 45;
    const daysHighlighted = new Array(numberOfDaysToAdd).fill(currentDate);

    return (
      <main id="main_ord_nav">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="order_profile">
            <div class="pro_section">
              <div class="container">
                <div class="row">
                  <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <PatientSideMenu />
                  </div>

                  <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane show active"
                        id="tele_med"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div id="appoint_box" class="appoint_box">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="schedule_heading">
                                <h2>My Appointments</h2>
                                <h4>{translate("Upcoming")}</h4>
                              </div>
                              {this.state.upComingDetailsView
                                ? this.state.upComingDetailsView
                                : "No Appointments"}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="schedule_heading">
                                <h4>{translate("Past")}</h4>
                              </div>
                              {this.state.pastAppointmentsDetailsView
                                ? this.state.pastAppointmentsDetailsView
                                : "No Appointments"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="in_person_tab"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div id="appoint_box">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="schedule_heading">
                                <h4>{translate("Upcoming")}</h4>
                              </div>
                              <div class="schedule_box">
                                <div class="appoint_bar">
                                  <div class="row appoint_wait">
                                    <div class="col-md-1 app_1">
                                      <img src="../images/patient/img/Profile/Date.svg" />
                                    </div>
                                    <div class="col-md-11 app_11">
                                      <p>Mon, 16 June, 2020</p>
                                      <h4>
                                        {translate(
                                          "Please wait for the call from clinic front desk"
                                        )}
                                      </h4>
                                      <p>
                                        {translate(
                                          "You will receive call from a clinics front desk and confirm your time slot"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="appoint_main">
                                  <div class="row">
                                    <div class="col-md-3  col-sm-4 col-xs-12">
                                      <div class="appoint_avtar">
                                        <img
                                          src="../images/patient/img/Profile/doctor.png"
                                          alt=""
                                        />
                                        <p class="online">
                                          <img src="../images/patient/img/Profile/available.svg" />
                                        </p>
                                        <h5 class="doc_available">
                                          {translate("Doctor is Available now")}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <div class="appoint_content">
                                        <h2>{translate("Dr. Srikar")}</h2>
                                        <p>{translate("Cardiologist, MD")}</p>
                                        <p>
                                          {translate("14 years experience")}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-5 col-sm-4 col-xs-12 text-right">
                                      <div class="appoint_hosp">
                                        <img src="../images/patient/img/Profile/hospital.png" />
                                        <h5>
                                          {translate(
                                            "Plot No:266,3rd Floor,Prakash Nilayam,Guttala Begumpet"
                                          )}
                                        </h5>
                                        <p class="directions">
                                          <img src="../images/patient/img/Homepage/Direction.svg" />
                                          {translate("Directions")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <p class="subtitle appoint_txt">
                                        <span>
                                          {translate("appointment with")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-3 col-sm-4 col-xs-12">
                                      <div class="appoint_avtar">
                                        <img
                                          src="../images/patient/img/Profile/patient.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-9 col-sm-8 col-xs-12">
                                      <div class="appoint_content">
                                        <h2>
                                          {translate("Samir Saxena")}{" "}
                                          <span>{translate("(Wife)")}</span>
                                        </h2>
                                        <p>{translate("55 Female")}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="schedule_heading">
                                <h4>{translate("Past")}</h4>
                              </div>
                              <div class="schedule_box">
                                <div class="appoint_bar">
                                  <p>
                                    <b>
                                      {translate(
                                        "Your appointment scheduled on"
                                      )}
                                    </b>{" "}
                                    <span>Mon 16 June, 2019</span>{" "}
                                    <span>03:30 PM</span>
                                  </p>
                                </div>
                                <div class="appoint_main">
                                  <div class="row">
                                    <div class="col-md-3  col-sm-4 col-xs-12">
                                      <div class="appoint_avtar">
                                        <img
                                          src="../images/patient/img/Profile/doctor.png"
                                          alt=""
                                        />
                                        <p class="online">
                                          <img src="../images/patient/img/Profile/available.svg" />
                                        </p>
                                        <h5 class="doc_available">
                                          {translate("Doctor is Available now")}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <div class="appoint_content">
                                        <h2>
                                          {translate("Dr. Carla Houston")}
                                        </h2>
                                        <p>{translate("Cardiologist, MD")}</p>
                                        <p>
                                          {translate("14 years experience")}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-5 col-sm-4 col-xs-12 text-right">
                                      <div class="appoint_hosp">
                                        <img src="../images/patient/img/Profile/hospital.png" />
                                        <h5>
                                          {translate(
                                            "Plot No:266,3rd Floor,Prakash Nilayam,Guttala Begumpet"
                                          )}
                                        </h5>
                                        <p class="directions">
                                          <img src="../images/patient/img/Homepage/Direction.svg" />
                                          {translate("Directions")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <p class="subtitle appoint_txt">
                                        <span>
                                          {translate("appointment with")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-3 col-sm-4 col-xs-12">
                                      <div class="appoint_avtar">
                                        <img
                                          src="../images/patient/img/Profile/patient.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-9 col-sm-8 col-xs-12">
                                      <div class="appoint_content">
                                        <h2>
                                          {translate("Samir Saxena")}{" "}
                                          <span>{translate("(Wife)")}</span>
                                        </h2>
                                        <p>{translate("55 Female")}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="modal fade" id="cancel_app" role="dialog">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="cancel_head">
                    <h2>{translate("Select Reason For Cancel")}</h2>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div class="cancel_body">
                    <p>
                      <input
                        type="radio"
                        name="cancelappointment"
                        class="c_radio"
                        value="I am not available at this time"
                        onChange={this.selectedReason}
                      />
                      {translate("I am not available at this time")}
                    </p>
                    <p>
                      <input
                        type="radio"
                        name="cancelappointment"
                        class="c_radio"
                        value="something more important came up"
                        onChange={this.selectedReason}
                      />
                      {translate("Something more important came up")}
                    </p>
                    <p>
                      <input
                        type="radio"
                        name="cancelappointment"
                        class="c_radio"
                        value="other"
                        onChange={this.selectedReason}
                      />
                      {translate("Other")}
                    </p>
                    <FormattedMessage id="Write here">
                      {(placeholder) => (
                        <textarea
                          class="form-control collapse-hide"
                          id="otherCancellationReasons"
                          rows="3"
                          placeholder={placeholder}
                        ></textarea>
                      )}
                    </FormattedMessage>

                    <div class="cancel_btn">
                      <p onClick={this.cancelAppointment}>{translate("OK")}</p>
                      &nbsp;
                      <p onClick={this.closePopup}>{translate("Cancel")}</p>
                    </div>
                    <div
                      class="cancel_btn"
                      onClick={this.cancelAppointment}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="presc_preview_Modal" class="modal fade" role="dialog">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <iframe
                  width="800"
                  height="600"
                  src=""
                  id="prescriptionImg"
                ></iframe>
              </div>
            </div>
          </div>

          <div class="modal fade" id="feedback_modal" role="dialog">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div class="feedback_head">
                    <h2>{translate("Give Your Feedback")}</h2>
                    <h6>{translate("to this consultation")}</h6>
                    <div class="rating_ico">
                      <span>
                        <i
                          class="fa fa-star"
                          aria-hidden="true"
                          id="1"
                          onClick={this.feedBack}
                        ></i>
                      </span>
                      <span>
                        <i
                          class="fa fa-star"
                          aria-hidden="true"
                          id="2"
                          onClick={this.feedBack}
                        ></i>
                      </span>
                      <span>
                        <i
                          class="fa fa-star"
                          aria-hidden="true"
                          id="3"
                          onClick={this.feedBack}
                        ></i>
                      </span>
                      <span>
                        <i
                          class="fa fa-star"
                          aria-hidden="true"
                          id="4"
                          onClick={this.feedBack}
                        ></i>
                      </span>
                      <span>
                        <i
                          class="fa fa-star"
                          aria-hidden="true"
                          id="5"
                          onClick={this.feedBack}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div class="feedback_body">
                    <form>
                      <div class="form-group">
                        <label>
                          {translate("Please comment on the consultation")}
                        </label>
                        <FormattedMessage id="Write here">
                          {(placeholder) => (
                            <textarea
                              class="form-control"
                              rows="3"
                              placeholder={placeholder}
                              id="feedbackComment"
                              onKeyUp={this.handleComment}
                            ></textarea>
                          )}
                        </FormattedMessage>
                      </div>
                      <div class="submit_btn" onClick={this.saveFeedBack}>
                        <p>{translate("Submit")}</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
