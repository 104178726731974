import React, { Component, useState } from "react";
import $, { data } from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Patientheader";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientMenu from "../patient/Patientmenu";
import PatientSideMenu from "../patient/Patientsidemenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import FileBase64 from "react-file-base64";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

const moment = require("moment");
const orderDetailsView = [];
const orderDetails = [];
const documentsRelated = [];

export default class Vieworders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_view_profile: true,
      selectedPatient: "",
      slotBookingTime: "",
      //openStatus:"",
    };
  }
  // To get detais after first render
  componentDidMount = () => {
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }
    // console.log("didmount..");
    var retrievedObject = ls.get("userObjEnc");
    let userObj = retrievedObject;
    if (userObj == null) {
      window.location.href = "./patientLogin";
    }

    if (typeof userObj != undefined) {
      this.setState({
        name: userObj.name,
        user_mobile: userObj.mobile_number,
        email_id: userObj.email,
        token: userObj.accessToken,
        patientId: userObj.patient_id,
        profile_image: "",
      });
      this.forceUpdate();
    }
    let viewOrderId = this.props.match.params.orderId;
    let viewRetailerId = this.props.match.params.retailerId;
    if (viewOrderId != undefined && viewRetailerId != undefined) {
      this.getCompleteOrderDetails(viewOrderId, viewRetailerId);
    }

    this.getOrderDetails(userObj.patient_id);
    this.getProfileDetails(userObj.patient_id);
    const interval = setInterval(() => {
      this.getOrderDetails(userObj.patient_id);
    }, 10000);
  };

  getTimeSlots(startTime, noOfiterations) {
    var startTime = moment(startTime, "HH:mm");
    var endTime = moment("23:59", "HH:mm");
    var timeSlots = [];
    let i = 0;
    while (i < noOfiterations) {
      let start = new moment(startTime).format("hh:mm A");
      startTime.add(60, "minutes");
      let end = new moment(startTime).format("hh:mm A");
      timeSlots.push(`${start} - ${end}`);
      i++;
    }
    return timeSlots;
  }
  // For getting the profile details
  getProfileDetails = (patientId) => {
    if (patientId != "") {
      let url_path = "api/Patients/" + patientId;
      let patientDob = "";
      Httpconfig.httptokenget(Constant.siteurl + url_path, {}).then(
        (response) => {
          let patientDob = "";
          if (response.data.status == 200 && response.data.error == false) {
            let PatientGender = response.data.data[0].gender;
            let patientProfileImage = "";
            if (response.data.data[0].profile_pic != null) {
              patientProfileImage = response.data.data[0].profile_pic;
            }
            this.setState({
              patientProfileImage: patientProfileImage,
              PatientGender: PatientGender,
            });
          }
        }
      );
    }
  };

  // For getting the user relationships
  getPatientRelations = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Patients/findpatientrealtives/" + patientId,
      {}
    )
      .then((response) => {
        /* Assiging consulation purpose to the state */
        let pName = "";
        let relationName = "";
        let selectedPatient = "";
        let id = "";
        let dataCount = Object.keys(response.data.data).length;
        for (let rel = 0; rel < dataCount; rel++) {
          if (response.data.data[rel].id != "") {
            id = response.data.data[rel].id;
          }
          if (response.data.data[rel].name != null) {
            pName = response.data.data[rel].name;
          }
          if (response.data.data[rel].master_relationship != null) {
            relationName =
              "(" +
              response.data.data[rel].master_relationship.relation_name +
              ")";
          }

          if (patientId == id) {
            selectedPatient = pName + relationName;
          }
        }

        this.state.selectedPatient = selectedPatient;
        $("#patientRelations").html(selectedPatient);

        this.forceUpdate();
      })

      .catch((error) => {
        toast.error(error);
      });
  };

  // For cancel the order

  cancelCurrentOrder = (orderid, orderCreatedAt) => {
    let appointmentCreatedAt = moment(orderCreatedAt);
    let mindiff = moment().diff(appointmentCreatedAt, "minutes");
    let constTime = Constant.orderCancelationTime.split(":");
    let totalCancelationMinutes =
      Number(constTime[0]) * 60 + Number(constTime[1]);
    if (mindiff <= totalCancelationMinutes) {
      let orderId = orderid;
      var isConfirm = window.confirm("Are you sure to Cancel the Order.");
      if (isConfirm) {
        Httpconfig.httptokenput(
          Constant.siteurl +
            "api/DIAGNOSIS/orderProcess/retailerOrderStatus/" +
            orderId,
          {
            order_status: 6,
          }
        ).then((response) => {
          if (response.data.status == "200" && response.data.error == false) {
            toast.success(
              "Order Cancelled Successfully & Refund has been queued for processing.",
              {
                position: "bottom-center",
              }
            );
            setTimeout(function () {
              window.location.href = "/myOrders";
            }, 2000);
          } else {
            toast.error(response.data.message, {});
          }
        });
      }
    } else {
      toast.error("Your cancellation time is over.", {});
    }
  };

  // For go back the previous page

  goBackPage = (e) => {
    $("#first_set").show();
    $(".profile_menu_sec1").show();

    // this.setState({
    //   is_view_profile: true,
    // });
    ls.remove("orderAndRetailerId");
    this.props.history.push("/myOrders");

    $("#second_set").hide();
  };

  //  For getting order details while reload
  getCompleteOrderDetailsReload = () => {
    let retailerId = this.state.retailerId;
    let orderId = this.state.selectedOrderId;

    $(".profile_menu_sec").css("display", "none");
    $("#first_set").hide();
    let orderedQuantity = "";

    $(".profile_menu_sec1").hide();
    this.setState({
      is_view_profile: false,
      selectedOrderId: orderId,
    });

    $("#second_set").show();
    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
        retailerId +
        "/" +
        orderId
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        const orderDetails = response.data.data.map((orderDetails, num) => {
          let orderDate = "";
          let userOrderDate = "";
          let total = "";
          orderDate = orderDetails.order_date;
          let isPrescriptionImages = "0";
          let isPrescriptionImagesList = "0";
          this.setState({ cartId: response.data.data[0].cart_id });
          this.getPatientRelations(orderDetails.relative_id);

          if (orderDetails.cart_prescriptions_tbl) {
            isPrescriptionImages = Object.keys(
              orderDetails.cart_prescriptions_tbl
            ).length;
            if (isPrescriptionImages > 0) {
              isPrescriptionImagesList = Object.keys(
                orderDetails.cart_prescriptions_tbl.medical_document
              ).length;
              this.setState({
                files: orderDetails.cart_prescriptions_tbl.medical_document,
              });
            }
          }

          let slotDateTime = "";
          let isRetailerData = Object.keys(
            orderDetails.diagnosis_registration_tbl
          ).length;
          if (isRetailerData > 0) {
            let slabDate =
              orderDetails.diagnosis_registration_tbl.set_delivery_days;
            if (slabDate) {
              var nowPlusOneDay = moment(orderDate).add("days", slabDate);
              slotDateTime = nowPlusOneDay.format("YYYY-MM-DD");
            }
          }

          let newdate = orderDate.split("-").reverse().join("-");
          userOrderDate = moment(orderDate).format("dddd, MMMM Do YYYY");
          slotDateTime = moment(slotDateTime).format("dddd, MMMM Do YYYY");
          var retailerCurrency = "";
          if (orderDetails.diagnosis_registration_tbl.currency) {
            retailerCurrency = orderDetails.diagnosis_registration_tbl.currency;
          }
          let orderMedicinesLength = orderDetails.order_processing_tbls.length;

          let deliveryAddressLength = Object.keys(
            orderDetails.delivery_address_tbl
          ).length;

          let relation_name = "Self";
          let deliveryAddress = "";
          if (
            orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]
              .discount == ""
          ) {
            orderDetails.order_processing_tbls[0].retailer_stock_tbl[0].discount = 0;
          }
          let price_discount =
            parseFloat(orderDetails.net_amount).toFixed(2).replace(/,/g, "") *
            parseFloat(
              orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]
                .discount
            )
              .toFixed(2)
              .replace(/,/g, "");
          let Tot_discount = parseFloat(
            orderDetails.order_processing_tbls[0].retailer_stock_tbl[0].discount
          )
            .toFixed(2)
            .replace(/,/g, "");
          let coupanAmt =
            orderDetails.net_amount.replace(/,/g, "") - Tot_discount;
          let coupanTotal = (coupanAmt * orderDetails.coupan_value) / 100;
          if (orderMedicinesLength > 0) {
            const retailerOrderMedicineDetailsView =
              orderDetails.order_processing_tbls.map((finalLoadedData, num) => {
                total = parseFloat(finalLoadedData.amount.replace(/,/g, ""));
                orderedQuantity = finalLoadedData.quantity;
                return (
                  <div class="med_list">
                    <div class="med_name">
                      <div className="med_amount">
                        <h2>
                          {/* {num + 1}. */}{" "}
                          {
                            finalLoadedData.diagnosis_products_master_tbl
                              .testname
                          }{" "}
                        </h2>
                        <h4 class="mrp">
                          MRP:{retailerCurrency} {finalLoadedData.amount}
                        </h4>
                        <span>
                          {retailerCurrency}{" "}
                          {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </span>
                      </div>
                      <h6>
                        {finalLoadedData.diagnosis_products_master_tbl
                          .manufacturer
                          ? finalLoadedData.diagnosis_products_master_tbl
                              .manufacturer
                          : ""}
                      </h6>
                      <p>
                        {finalLoadedData.diagnosis_products_master_tbl.form
                          ? finalLoadedData.diagnosis_products_master_tbl.form
                          : ""}
                      </p>
                      <p>
                        {finalLoadedData.diagnosis_products_master_tbl.size
                          ? finalLoadedData.diagnosis_products_master_tbl.size
                          : ""}
                      </p>
                      <h6>
                        {orderedQuantity
                          ? "Ordered Quantity: " + orderedQuantity
                          : ""}
                      </h6>
                      {}
                    </div>
                  </div>
                );
              });
            this.setState({
              retailerOrderMedicineDetailsView:
                retailerOrderMedicineDetailsView,
            });
          }

          let progressBar = "";
          if (orderDetails.order_status === 1) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">2</a> <p class="p_light">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">5</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 2) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 3) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 4) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 5) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Completed</p></div></div>';
          } else if (orderDetails.order_status === 6) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_light">Cancelled </p></div></div>';
          }
          let appointmentCreatedAt = moment(orderDetails.order_date);
          let mindiff = moment().diff(appointmentCreatedAt, "minutes");
          let constTime = Constant.orderCancelationTime.split(":");
          let totalCancelationMinutes =
            Number(constTime[0]) * 60 + Number(constTime[1]);
          return (
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="back_head" onClick={this.goBackPage.bind(this)}>
                    <h2>
                      <img src="/images/patient/img/arrow-back.svg" />
                      Back to Order List
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="order_det_list">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="order_det_head">
                          <div class="presc_img">
                            <img
                              src={
                                Constant.imgurl +
                                "/images/patient/img/Ordermedicine/pdf.svg"
                              }
                            />
                            {orderDetails.invoice ? (
                              <a
                                href={
                                  orderDetails.invoice
                                    ? orderDetails.invoice
                                    : ""
                                }
                                target="_blank"
                              ></a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="presc_det">
                            <h2>
                              Order ID: <span>{orderDetails.id}</span>
                            </h2>
                            <h2>
                              Order Date: <span>{userOrderDate}</span>
                            </h2>
                          </div>

                          {orderDetails.order_status == 1 ? (
                            <p class="pending_btns verify_btn">
                              Order verification pending..
                            </p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 2 ? (
                            <p class="verify_btn">Order verified</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 3 ? (
                            <p class="verify_btn">Samples Collected</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 4 ? (
                            <p class="verify_btn">Processed</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 5 ? (
                            <p class="verify_btn">Completed</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 6 &&
                          orderDetails.refund_status == 6 ? (
                            <p class="pending_btns verify_btn">
                              Cancelled : <br />
                              Refund Queued For Processing..
                            </p>
                          ) : orderDetails.order_status == 6 &&
                            orderDetails.refund_status == 7 ? (
                            <p class="pending_btns verify_btn ">
                              Cancelled : <br /> Amount Refunded
                            </p>
                          ) : orderDetails.order_status == 6 ? (
                            <p class="pending_btns verify_btn">Cancelled </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="delivery_det">
                          <div class="expect_head">
                            <h2>Slot Date & Time {slotDateTime}</h2>
                            <h6>
                              You will received an invoice with order details
                              after verified the Order by our Diagnostic Center
                            </h6>
                          </div>

                          <div class="expect_wizard">
                            <div class="stepwizard">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: progressBar,
                                }}
                              />
                            </div>
                          </div>
                          <div class="retailer_address">
                            <h4>Diagnostic Center :</h4>
                            <React.Fragment>
                              <h4>
                                {
                                  orderDetails.diagnosis_registration_tbl
                                    .labname
                                }
                              </h4>
                              <p>
                                {
                                  orderDetails.diagnosis_registration_tbl
                                    .address
                                }
                              </p>
                            </React.Fragment>
                          </div>
                          <div class="delivery_address">
                            <h2>Address</h2>
                            {orderDetails.delivery_address_tbl ? (
                              <React.Fragment>
                                <h4>
                                  {orderDetails.delivery_address_tbl.name
                                    ? orderDetails.delivery_address_tbl.name
                                    : "--"}
                                </h4>
                                <p>
                                  {orderDetails.delivery_address_tbl.address
                                    ? orderDetails.delivery_address_tbl.address
                                    : ""}
                                  <br />
                                  {orderDetails.delivery_address_tbl.location
                                    ? orderDetails.delivery_address_tbl.location
                                    : ""}
                                  <br />
                                  {orderDetails.delivery_address_tbl.landmark
                                    ? orderDetails.delivery_address_tbl.landmark
                                    : ""}
                                </p>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          {orderDetails.order_status != 6 &&
                          orderDetails.order_status != 5 ? (
                            <div class="delivery_presc">
                              <div class="presc_head">
                                <div class="presc_img">
                                  <img
                                    src={
                                      Constant.imgurl +
                                      "/images/patient/img/Ordermedicine/upload_icon.png"
                                    }
                                  />
                                </div>
                                <div class="presc_det">
                                  <h2>UPLOAD &amp; SEND PRESCRIPTION</h2>
                                  {/* <h6>You have one Rx medicine in the item</h6> */}
                                </div>
                              </div>
                              <div class="delivery_upload_img">
                                {isPrescriptionImagesList > 0
                                  ? orderDetails.cart_prescriptions_tbl.medical_document.map(
                                      (presImages, num) => {
                                        return (
                                          <div className="upload_presc_img consult_doctor_banner">
                                            <a
                                              href={
                                                presImages ? presImages : ""
                                              }
                                              target="_blank"
                                            >
                                              <img src={presImages} />
                                            </a>

                                            <img
                                              class="delete_img"
                                              id={presImages}
                                              onClick={this.removeImageOnClick}
                                              src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                              <div class="delivery_upload_btn">
                                <div class="custom-file-upload">
                                  <label>
                                    Upload
                                    <FileBase64
                                      multiple={true}
                                      onDone={this.getFiles.bind(this)}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="payment_det">
                          <div class="pay_head">
                            <h2>Products & Payment details</h2>
                            <p>
                              Medicines for{" "}
                              <span id="patientRelations">
                                {this.state.selectedPatient
                                  ? this.state.selectedPatient
                                  : ""}
                              </span>
                            </p>
                          </div>
                          <div class="medicine_sec">
                            {this.state.retailerOrderMedicineDetailsView}
                          </div>
                          <div class="price_det_head">
                            <h2>Price details</h2>
                          </div>
                          <div class="pay_list">
                            <p>
                              Total M.R.P.{" "}
                              <span class="tot_amnt">
                                {retailerCurrency}{" "}
                                {parseFloat(orderDetails.net_amount)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </span>
                            </p>
                            <p>
                              Coupon code applied (
                              {orderDetails.coupan_value
                                ? orderDetails.coupan_value + "%"
                                : "0"}
                              ) :
                              <span>
                                {retailerCurrency}{" "}
                                {coupanTotal
                                  ? parseFloat(coupanTotal)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : "0"}
                              </span>
                            </p>
                            <p>
                              Shipping Charges
                              <span>
                                + {retailerCurrency}{" "}
                                {orderDetails.delivery_charges
                                  ? parseFloat(orderDetails.delivery_charges)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : "0.00"}
                              </span>
                            </p>
                          </div>
                          <h4 class="total_payable">
                            Discount{" "}
                            <span>
                              {parseFloat(Tot_discount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </span>
                          </h4>
                          <h4 class="total_payable">
                            Total Amount{" "}
                            <span>
                              {orderDetails.payable_amount
                                ? retailerCurrency +
                                  " " +
                                  parseFloat(orderDetails.payable_amount)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : "0.00"}
                            </span>
                          </h4>

                          {orderDetails.order_status > 2 ? (
                            <React.Fragment>
                              <h4 class="total_payable">
                                Billed Amount{" "}
                                <span>
                                  {orderDetails.total_paid
                                    ? retailerCurrency +
                                      " " +
                                      parseFloat(orderDetails.total_paid)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : "0.00"}
                                </span>
                              </h4>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          <div className="row  view_ord">
                            <div className="col-md-6">
                              <div class="pay_mode">
                                <h2>Payment Mode</h2>
                                <h3>
                                  Paid Through :
                                  <span>
                                    {orderDetails.payment_method
                                      ? orderDetails.payment_method
                                      : "Card"}
                                  </span>
                                </h3>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="download_btn">
                                <button type="button">Download Invoice</button>
                              </div>
                            </div>
                          </div>
                          <div className="labtest_section">
                            <div className="lab_heading">
                              <h2>Lab Test(s) Reports</h2>
                            </div>
                            <div className="lab_rep">
                              <div class="lab_img">
                                <img
                                  src={
                                    Constant.imgUrl +
                                    "/images/patient/img/Ordermedicine/pdf.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <a
                              orderId={orderDetails.id}
                              retailerId={orderDetails.retailer_id}
                              href="javascript:void(0);"
                              onClick={this.cancelCurrentOrder.bind(
                                this,
                                orderDetails.id,
                                orderDetails.order_date
                              )}
                            >
                              {" "}
                              {orderDetails.order_status != 5 &&
                              orderDetails.order_status != 6 &&
                              orderDetails.order_status != 4 &&
                              orderDetails.order_status != 2 ? (
                                <p class="cancel_txt">Cancel Order</p>
                              ) : (
                                ""
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        this.state.orderDetails = "";
        this.forceUpdate();
        this.setState({
          orderDetails: orderDetails,
        });
      }
    });
  };

  //Diagnostics Center Map direction

  showDirections = (latitude, longtitude) => {
    let lat = latitude;
    let long = longtitude;
    // window.open("https://maps.google.com?q=17.3529,78.5357", "_blank");
    window.open(
      "https://maps.google.com?q= " + lat + "," + long + ",",
      "_blank"
    );
  };

  openOrderView = (orderId, retailerId) => {
    this.props.history.push("/myOrders/" + orderId + "/" + retailerId);
  };

  // For complete the order details
  getCompleteOrderDetails = (orderId, retailerId) => {
    // const anchor = e.target.closest("a");
    // let retailerId = anchor.getAttribute("retailerId");
    // let orderId = anchor.getAttribute("orderId");

    $(".profile_menu_sec").css("display", "none");
    $("#first_set").hide();

    let orderedQuantity = "";
    $(".profile_menu_sec1").hide();
    //alert("open-"+orderId+"-"+retailerId);
    this.setState({
      is_view_profile: false,
      selectedOrderId: orderId,
      retailerId: retailerId,
    });

    $("#second_set").show();
    Httpconfig.httptokenpost(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getOrderDetails/" +
        retailerId +
        "/" +
        orderId
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        const orderDetails = response.data.data.map((orderDetails, num) => {
          let discountAmount = 0;
          let orderDate = "";
          let userOrderDate = "";
          let total = "";
          orderDate = orderDetails.order_date;
          let isPrescriptionImages = "0";
          let isPrescriptionImagesList = "0";
          this.setState({ cartId: response.data.data[0].cart_id });
          this.getPatientRelations(orderDetails.relative_id);
          let labReportArray = orderDetails.lab_reports
            ? JSON.parse(orderDetails.lab_reports)
            : "";
          if (orderDetails.cart_prescriptions_tbl) {
            isPrescriptionImages = Object.keys(
              orderDetails.cart_prescriptions_tbl
            ).length;
            if (isPrescriptionImages > 0) {
              isPrescriptionImagesList = Object.keys(
                orderDetails.cart_prescriptions_tbl.medical_document
              ).length;
              this.setState({
                files: orderDetails.cart_prescriptions_tbl.medical_document,
              });
            }
          }
          let slotDateTime = "";
          let isRetailerData = Object.keys(
            orderDetails.diagnosis_registration_tbl
          ).length;
          // if (isRetailerData > 0) {
          //   let slabDate =
          //     orderDetails.diagnosis_registration_tbl.set_delivery_days;
          //   if (slabDate) {
          //     var nowPlusOneDay = moment(orderDate).add("days", slabDate);
          //     slotDateTime = nowPlusOneDay.format("YYYY-MM-DD");
          //   }
          // }
          slotDateTime =
            moment(orderDetails.slot_date).format("dddd, MMMM Do YYYY") +
            "" +
            " , " +
            orderDetails.slot_time;
          let newdate = orderDate.split("-").reverse().join("-");
          userOrderDate = moment(orderDate).format("dddd, MMMM Do YYYY");
          // slotDateTime = moment(slotDateTime).format("dddd, MMMM Do YYYY");
          var retailerCurrency = "";
          if (orderDetails.diagnosis_registration_tbl.currency) {
            retailerCurrency = orderDetails.diagnosis_registration_tbl.currency;
          }
          let orderMedicinesLength = orderDetails.order_processing_tbls.length;
          let agentName = orderDetails.agent_registration_tbl
            ? orderDetails.agent_registration_tbl.name
            : "";
          let agentMobile = orderDetails.agent_registration_tbl
            ? orderDetails.agent_registration_tbl.mobile_number
            : "";
          // let deliveryAddressLength = Object.keys(
          //   orderDetails.delivery_address_tbl
          // ).length;

          // let relation_name = "Self";
          // let deliveryAddress = "";

          if (
            orderDetails.order_processing_tbls[0].retailer_stock_tbl.length > 0
          ) {
            if (
              orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]
                .discount == ""
            ) {
              orderDetails.order_processing_tbls[0].retailer_stock_tbl[0].discount = 0;
            }
          }
          // discountAmountAmt=(parseFloat(orderDetails.order_processing_tbls[num].retailer_stock_tbl[0]
          //   .mrp).toFixed(2).replace(/,/g, "") * parseFloat(orderDetails.order_processing_tbls[num].retailer_stock_tbl[0].quantity))
          //   *
          // (parseFloat(
          //   orderDetails.order_processing_tbls[num].retailer_stock_tbl[0]
          //     .discount
          // )/parseFloat(100));
          //alert(discountAmountAmt);

          // let price_discount =
          //   parseFloat(orderDetails.net_amount).toFixed(2).replace(/,/g, "") *
          //   parseFloat(
          //     orderDetails.order_processing_tbls[0].retailer_stock_tbl[0]
          //       .discount
          //   )
          //     .toFixed(2)
          //     .replace(/,/g, "");
          let Tot_discount = parseFloat(orderDetails.discount)
            .toFixed(2)
            .replace(/,/g, "");

          let totalDiscount = orderDetails.cart_level_discount
            ? orderDetails.cart_level_discount
            : "0.00";

          // let coupanAmt =
          //   orderDetails.net_amount.replace(/,/g, "") - Tot_discount;
          // if (orderDetails.coupan_value != "") {
          //   orderDetails.coupan_value = orderDetails.coupan_value.replace(
          //     /%/g,
          //     ""
          //   );
          // }

          let coupanTotal = orderDetails.coupon_amount
            ? orderDetails.coupon_amount
            : "0.00";
          if (orderMedicinesLength > 0) {
            const retailerOrderMedicineDetailsView =
              orderDetails.order_processing_tbls.map((finalLoadedData, num) => {
                let discount = 0;
                // if(finalLoadedData.retailer_stock_tbl.length){
                //   discount = finalLoadedData.retailer_stock_tbl[0].discount
                // }
                if (finalLoadedData.discount) {
                  discount = finalLoadedData.discount;
                }
                total =
                  parseFloat(finalLoadedData.amount.replace(/,/g, "")) -
                  (parseFloat(finalLoadedData.amount.replace(/,/g, "")) *
                    parseFloat(discount)) /
                    100;
                orderedQuantity = finalLoadedData.quantity;
                if (finalLoadedData.retailer_stock_tbl.length > 0) {
                  discountAmount =
                    discountAmount +
                    finalLoadedData.retailer_stock_tbl[0].mrp *
                      (discount / 100);
                }
                return (
                  <div class="med_list">
                    <div class="med_name">
                      <div className="med_amount">
                        <span>{num + 1}. </span>
                        <h2>
                          {finalLoadedData.diagnosis_products_master_tbl
                            ? finalLoadedData.diagnosis_products_master_tbl
                                .testname
                            : ""}{" "}
                        </h2>
                        <h4 class="mrp">
                          MRP:{" "}
                          <del>
                            {retailerCurrency} {finalLoadedData.amount}
                          </del>
                          <h6 class="dis_amount">
                            {finalLoadedData.discount
                              ? "" + finalLoadedData.discount + "%"
                              : ""}
                            off
                          </h6>
                        </h4>
                        <h5>
                          {retailerCurrency}{" "}
                          {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </h5>
                      </div>
                      <div className="order_descp">
                        <h6>
                          {finalLoadedData.diagnosis_products_master_tbl
                            ? finalLoadedData.diagnosis_products_master_tbl
                                .manufacturer
                            : ""}
                        </h6>
                        <p>
                          {finalLoadedData.diagnosis_products_master_tbl
                            ? finalLoadedData.diagnosis_products_master_tbl.form
                            : ""}
                        </p>
                        <p>
                          {finalLoadedData.diagnosis_products_master_tbl
                            ? finalLoadedData.diagnosis_products_master_tbl.size
                            : ""}
                        </p>
                        <h6>
                          {orderedQuantity
                            ? "Ordered Quantity: " + orderedQuantity
                            : ""}
                        </h6>
                      </div>
                      {/* <h6>
                      {finalLoadedData.retailer_stock_tbl
                          ? "Discount: " +
                          finalLoadedData.retailer_stock_tbl[0].discount +
                          "%"
                          : ""}
                      </h6> */}
                      {}
                    </div>
                  </div>
                );
              });
            this.setState({
              retailerOrderMedicineDetailsView:
                retailerOrderMedicineDetailsView,
            });
          }

          let progressBar = "";

          if (orderDetails.order_status === 1) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '"/></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">2</a> <p class="p_light">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">5</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 2) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div>';
            if (
              orderDetails.delivery_type == "home_visit" &&
              orderDetails.test_status == "picked-up"
            ) {
              progressBar +=
                '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
                Constant.imgurl +
                '/images/patient/img/Patient Intake Process/tickmark.svg"' +
                '"></a><p class="p_dark">Samples Collected </p></div>';
            } else {
              progressBar +=
                '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div>';
            }
            progressBar +=
              '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 3) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 4) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 5) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Completed</p></div></div>';
          } else if (orderDetails.order_status === 6) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_light">Cancelled </p></div></div>';
          }
          let orderCreatedAt = moment(orderDetails.order_date);
          let mindiff = moment().diff(orderCreatedAt, "minutes");
          let constTime = Constant.orderCancelationTime.split(":");
          let totalCancelationMinutes =
            Number(constTime[0]) * 60 + Number(constTime[1]);
          return (
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="back_head" onClick={this.goBackPage.bind(this)}>
                    <h2>
                      <img src="/images/patient/img/arrow-back.svg" />
                      Back to Order List
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="order_det_list">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="order_det_head">
                          {/* <div class="presc_img">
                            <img src="../images/patient/img/Ordermedicine/pdf.svg" />
                            {orderDetails.invoice ? (
                              <a
                                href={
                                  orderDetails.invoice
                                    ? Constant.imgurl + orderDetails.invoice
                                    : ""
                                }
                                target="_blank"
                              ></a>
                            ) : (
                              ""
                            )}
                          </div> */}
                          <div class="presc_det">
                            <h2>
                              Order ID: <span>{orderDetails.id}</span>
                            </h2>
                            <h2>
                              Order Date: <span>{userOrderDate}</span>
                            </h2>
                          </div>

                          {orderDetails.order_status == 1 ? (
                            <p class="pending_btns verify_btn">
                              Order verification pending..
                            </p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 2 &&
                          orderDetails.test_status == "picked-up" &&
                          orderDetails.payment_method == "COD" ? (
                            <p class="verify_btn">Samples Collected</p>
                          ) : orderDetails.order_status == 2 ? (
                            <p class="verify_btn">Order Verified</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 3 ? (
                            <p class="verify_btn">Samples Collected</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 4 ? (
                            <p class="verify_btn">Processed</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 5 ? (
                            <p class="verify_btn">Completed</p>
                          ) : (
                            ""
                          )}
                          {orderDetails.order_status == 6 &&
                          orderDetails.refund_status == 6 ? (
                            <p class="pending_btns verify_btn">
                              Cancelled : <br />
                              Refund Queued For Processing..
                            </p>
                          ) : orderDetails.order_status == 6 &&
                            orderDetails.refund_status == 7 ? (
                            <p class="pending_btns verify_btn ">
                              Cancelled : <br /> Amount Refunded
                            </p>
                          ) : orderDetails.order_status == 6 ? (
                            <p class="pending_btns verify_btn">Cancelled </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="delivery_det">
                          <div class="expect_head">
                            <h2>Slot Date & Time {slotDateTime}</h2>
                            <h6>
                              You will received an invoice with order details
                              after verified by our Diagnostic Center
                            </h6>
                          </div>
                          <div class="expect_wizard">
                            <div class="stepwizard">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: progressBar,
                                }}
                              />
                            </div>
                          </div>
                          <div class="row retailer_address">
                            <div class="col-md-6">
                              <div class="details">
                                <h4>Diagnostic Center</h4>
                                <React.Fragment>
                                  <h5>
                                    {
                                      orderDetails.diagnosis_registration_tbl
                                        .labname
                                    }
                                  </h5>
                                  <p>
                                    {
                                      orderDetails.diagnosis_registration_tbl
                                        .address
                                    }
                                  </p>{" "}
                                  <p>
                                    {
                                      orderDetails.diagnosis_registration_tbl
                                        .mobile_number
                                    }
                                  </p>{" "}
                                  <p>
                                    {
                                      orderDetails.diagnosis_registration_tbl
                                        .email
                                    }
                                  </p>
                                  <a
                                    href="#"
                                    onClick={this.showDirections.bind(
                                      this,
                                      orderDetails.diagnosis_registration_tbl
                                        .latitude,
                                      orderDetails.diagnosis_registration_tbl
                                        .longitude
                                    )}
                                  >
                                    Direction
                                  </a>
                                </React.Fragment>
                              </div>
                            </div>
                            {orderDetails.agent_registration_tbl ? (
                              <div class="col-md-6">
                                <div className="agent_box">
                                  <h2>Agent Details</h2>
                                  <div class="list">
                                    <h6>
                                      Name: <span>{agentName}</span>
                                    </h6>
                                    <h6>
                                      Contact: <span>{agentMobile}</span>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div class="delivery_address">
                            <h2>Address</h2>
                            {orderDetails.delivery_address_tbl ? (
                              <React.Fragment>
                                <h4>
                                  {orderDetails.delivery_address_tbl.name
                                    ? orderDetails.delivery_address_tbl.name
                                    : "--"}
                                </h4>
                                <p>
                                  {orderDetails.delivery_address_tbl.address
                                    ? orderDetails.delivery_address_tbl.address
                                    : ""}

                                  <br />
                                  {orderDetails.delivery_address_tbl.landmark
                                    ? orderDetails.delivery_address_tbl.landmark
                                    : ""}
                                  <br />
                                  {orderDetails.delivery_address_tbl.zipcode
                                    ? orderDetails.delivery_address_tbl.zipcode
                                    : ""}
                                  <br />
                                  <span>Mobile :</span>
                                  {orderDetails.delivery_address_tbl.mobile_no
                                    ? orderDetails.delivery_address_tbl
                                        .mobile_no
                                    : ""}
                                </p>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>

                          {orderDetails.order_status != 6 &&
                          orderDetails.order_status != 5 ? (
                            <div class="delivery_presc">
                              <div class="presc_head">
                                <div class="presc_img">
                                  <img
                                    src={
                                      Constant.imgurl +
                                      "/images/patient/img/Ordermedicine/upload_icon.png"
                                    }
                                  />
                                </div>
                                <div class="presc_det">
                                  <h2>UPLOAD &amp; SEND PRESCRIPTION</h2>
                                  {/* <h6>You have one Rx medicine in the item</h6> */}
                                </div>
                              </div>

                              <div class="delivery_upload_img">
                                {isPrescriptionImagesList > 0
                                  ? orderDetails.cart_prescriptions_tbl.medical_document.map(
                                      (presImages, num) => {
                                        return (
                                          <div className="upload_presc_img consult_doctor_banner">
                                            <a
                                              href={
                                                presImages
                                                  ? presImages //Constant.imgurl +
                                                  : ""
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  presImages //Constant.imgurl +
                                                }
                                              />
                                            </a>

                                            <img
                                              class="delete_img"
                                              id={presImages}
                                              onClick={this.removeImageOnClick}
                                              src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                              <div class="delivery_upload_btn">
                                <div class="custom-file-upload">
                                  <label>
                                    Upload
                                    <FileBase64
                                      multiple={true}
                                      onDone={this.getFiles.bind(this)}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="payment_det">
                          <div class="pay_head">
                            <h2>
                              Test(s) & Payment details{" "}
                              <span>
                                {orderDetails.delivery_type == "home_visit"
                                  ? "Home Visit"
                                  : "Center Visit"}
                              </span>
                            </h2>
                            <p>
                              Test(s) for{" "}
                              <span id="patientRelations">
                                {this.state.selectedPatient
                                  ? this.state.selectedPatient
                                  : ""}
                              </span>
                            </p>
                          </div>
                          <div class="medicine_sec">
                            {this.state.retailerOrderMedicineDetailsView}
                          </div>
                          <div class="price_det_head">
                            <h2>Price details</h2>
                          </div>
                          <div class="pay_list">
                            <p>
                              Total M.R.P.{" "}
                              <span class="tot_amnt">
                                {retailerCurrency}{" "}
                                {parseFloat(orderDetails.net_amount)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </span>
                            </p>
                            <p>
                              Coupon Discount (-) :
                              <span>
                                {retailerCurrency}{" "}
                                {coupanTotal
                                  ? parseFloat(coupanTotal)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : "0"}
                              </span>
                            </p>
                            <p>
                              Sample Collection Charges
                              <span>
                                + {retailerCurrency}{" "}
                                {orderDetails.delivery_type !== "center_visit"
                                  ? orderDetails.delivery_charges
                                    ? parseFloat(
                                        orderDetails.delivery_charges.replaceAll(
                                          ",",
                                          ""
                                        )
                                      ).toFixed(2)
                                    : "0.00"
                                  : "0.00"}
                              </span>
                            </p>
                          </div>
                          <h4 class="total_payable">
                            Discount (-){" "}
                            <span>
                              {retailerCurrency}{" "}
                              {parseFloat(totalDiscount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              {/* {parseFloat(Tot_discount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")} */}
                            </span>
                          </h4>
                          <h4 class="total_payable">
                            Total Amount{" "}
                            <span>
                              {orderDetails.payable_amount
                                ? retailerCurrency +
                                  " " +
                                  parseFloat(orderDetails.payable_amount)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : "0.00"}
                            </span>
                          </h4>
                          <div className="row view_ord">
                            <div className="col-md-6 no_padding">
                              {orderDetails.order_status == 4 ? (
                                <div class="pay_mode">
                                  <h2>Payment Mode</h2>
                                  <h3>
                                    Paid Through :
                                    <span>
                                      {orderDetails.payment_method
                                        ? orderDetails.payment_method
                                        : ""}
                                    </span>
                                  </h3>
                                </div>
                              ) : (
                                ""
                              )}

                              <div>
                                <a
                                  orderId={orderDetails.id}
                                  retailerId={orderDetails.retailer_id}
                                  href="javascript:void(0);"
                                  onClick={this.cancelCurrentOrder.bind(
                                    this,
                                    orderDetails.id,
                                    orderDetails.order_date
                                  )}
                                >
                                  {" "}
                                  {orderDetails.order_status != 5 &&
                                  orderDetails.order_status != 6 &&
                                  orderDetails.order_status != 4 &&
                                  orderDetails.order_status != 2 ? (
                                    <p class="cancel_txt">Cancel Order</p>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </div>
                            </div>

                            {orderDetails.invoice != null &&
                            orderDetails.order_status != 6 ? (
                              <div className="col-md-6  no_padding">
                                <div className="download_btn">
                                  <button>
                                    <a
                                      href={
                                        orderDetails.invoice
                                          ? orderDetails.invoice
                                          : ""
                                      }
                                      target="_blank"
                                      download
                                      type="button"
                                    >
                                      Download Invoice
                                    </a>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {orderDetails.lab_reports &&
                          orderDetails.order_status != 6 ? (
                            <div className="labtest_section">
                              <div className="lab_heading">
                                <h2>Lab Test(s) Reports</h2>
                              </div>
                              {}
                              {labReportArray
                                ? labReportArray.map((labImages, num) => {
                                    return (
                                      <div className="lab_rep">
                                        <div class="lab_img">
                                          <a
                                            href={labImages ? labImages : ""}
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                Constant.imgurl +
                                                "/images/patient/img/Ordermedicine/pdf.svg"
                                              }
                                            />
                                            <p>Report {num + 1}</p>
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                              {/* <div className="lab_rep">
                              <div class="lab_img">
                                <img src="../images/patient/img/Ordermedicine/pdf.svg" />
                                <p>Report 1</p>
                              </div>
                            </div> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        this.setState({
          orderDetails: orderDetails,
        });
      }
    });
  };

  // for getting the order details
  getOrderDetails = (patientId) => {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/DIAGNOSIS/orderProcess/getPatientOrderDetails/" +
        patientId
    ).then((response) => {
      if (response.data.status == "200" && response.data.error == false) {
        this.setState({ completeOrderDate: response.data.data });
        const orderDetailsView = response.data.data.map((orderDetails, num) => {
          let labReportArray = orderDetails.lab_reports
            ? JSON.parse(orderDetails.lab_reports)
            : "";
          let orderItemsLength = orderDetails.order_processing_tbls.length;
          let retailerCurrency = "";
          if (orderDetails.diagnosis_registration_tbl) {
            retailerCurrency = orderDetails.diagnosis_registration_tbl.currency;
          }
          let total = "";

          let orderDate = "";
          let userOrderDate = "";
          // let slotDateTime = "";
          let slotDateTime = "";
          orderDate = orderDetails.order_date;
          if (orderItemsLength > 0) {
            let newdate = orderDate.split("-").reverse().join("-");
          }
          userOrderDate = moment(orderDate).format("dddd, MMMM Do YYYY");
          let isRetailerData = "";
          if (orderDetails.diagnosis_registration_tbl) {
            isRetailerData = Object.keys(
              orderDetails.diagnosis_registration_tbl
            ).length;

            // if (isRetailerData > 0) {
            //   let slabDate =
            //     orderDetails.diagnosis_registration_tbl.set_delivery_days;
            //   if (slabDate) {
            //     var nowPlusOneDay = moment(orderDetails.order_date).add(
            //       "days",
            //       slabDate
            //     );
            //     slotDateTime = nowPlusOneDay.format("YYYY-MM-DD");
            //   }
            //   slotDateTime =
            //     moment(slotDateTime).format("dddd, MMMM Do YYYY");
            // }
            slotDateTime =
              moment(orderDetails.slot_date).format("dddd, MMMM Do YYYY") +
              "" +
              " , " +
              orderDetails.slot_time;
          }

          let progressBar = "";
          if (orderDetails.order_status === 1) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">2</a> <p class="p_light">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">5</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 2) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div>';
            if (
              orderDetails.delivery_type == "home_visit" &&
              orderDetails.test_status == "picked-up"
            ) {
              progressBar +=
                '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
                Constant.imgurl +
                '/images/patient/img/Patient Intake Process/tickmark.svg"' +
                '"></a><p class="p_dark">Samples Collected </p></div>';
            } else {
              progressBar +=
                '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Samples Collected </p></div>';
            }
            progressBar +=
              '<div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">3</a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 3) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle">4</a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 4) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-default btn-circle"></a><p class="p_light">Completed</p></div></div>';
          } else if (orderDetails.order_status === 5) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Verified </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Samples Collected </p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Processed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Completed</p></div></div>';
          } else if (orderDetails.order_status === 6) {
            progressBar +=
              '<div class="stepwizard-row setup-panel"><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_dark">Order Placed</p></div><div class="stepwizard-step"><a href="javascript:void(0)" type="" class="btn btn-primary btn-circle"><img src="' +
              Constant.imgurl +
              '/images/patient/img/Patient Intake Process/tickmark.svg"' +
              '" /></a><p class="p_light">Cancelled </p></div></div>';
          }

          total = parseFloat(orderDetails.payable_amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");

          return (
            <div class="my_order_box">
              <div class="order_box_head">
                {/* <div class="presc_img">
                  {orderDetails.invoice ? (
                    <a
                      href={
                        orderDetails.invoice
                          ? Constant.imgurl + orderDetails.invoice
                          : ""
                      }
                      target="_blank"
                    >
                      <img src="../images/patient/img/Ordermedicine/pdf.svg" />
                    </a>
                  ) : (
                    ""
                  )}
                  {labReportArray
                    ? labReportArray.map((labImages, num) => {
                      return (
                        <a
                          href={labImages ? Constant.imgurl + labImages : ""}
                          target="_blank"
                        >
                          <img
                            class="presc_img"
                            src="../images/retailer/Invoice.svg"
                          />
                        </a>
                      );
                    })
                    : ""}
                </div> */}
                <div class="presc_det">
                  <h2>
                    Order ID: <span>{orderDetails.id}</span>
                  </h2>
                  <h2>
                    Order Date: <span>{userOrderDate}</span>
                  </h2>
                </div>
                {orderDetails.order_status == 1 ? (
                  <p class="pending_btns verify_btn">
                    Order verification pending..
                  </p>
                ) : (
                  ""
                )}
                {orderDetails.order_status == 2 &&
                orderDetails.test_status == "picked-up" &&
                orderDetails.payment_method == "COD" ? (
                  <p class="verify_btn">Samples Collected</p>
                ) : orderDetails.order_status == 2 ? (
                  <p class="verify_btn">Order Verified</p>
                ) : (
                  ""
                )}
                {orderDetails.order_status == 3 ? (
                  <p class="verify_btn">Samples Collected</p>
                ) : (
                  ""
                )}
                {orderDetails.order_status == 4 ? (
                  <p class="verify_btn">Processed</p>
                ) : (
                  ""
                )}
                {orderDetails.order_status == 5 ? (
                  <p class="verify_btn">Completed</p>
                ) : (
                  ""
                )}
                {orderDetails.order_status == 6 &&
                orderDetails.refund_status == 6 ? (
                  <p class="pending_btns verify_btn">
                    Cancelled <br />
                    Refund Queued For Processing..
                  </p>
                ) : orderDetails.order_status == 6 &&
                  orderDetails.refund_status == 7 ? (
                  <p class="pending_btns verify_btn">
                    Cancelled <br />
                    Amount Refunded
                  </p>
                ) : orderDetails.order_status == 6 ? (
                  <p class="pending_btns verify_btn">Cancelled</p>
                ) : (
                  ""
                )}
              </div>

              <div class="expect_delivery">
                <div class="row">
                  <div class="col-md-12">
                    <div class="slot_detail">
                      <h3>Slot Date & Time : {slotDateTime}</h3>
                      {orderDetails.order_status != 2 &&
                      orderDetails.order_status != 3 &&
                      orderDetails.order_status != 4 &&
                      orderDetails.order_status != 5 &&
                      orderDetails.order_status != 6 ? (
                        <a
                          onClick={this.changeSlot.bind(this, orderDetails.id)}
                        >
                          <h5>Reschedule slot</h5>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <h6>
                  You will received an invoice with order details after verified
                  by our Diagnostic Center.
                </h6>

                <div
                  class="row order_sch presc_det"
                  id={"update_time_date-" + orderDetails.id}
                  style={{ display: "none" }}
                >
                  <div class="col-md-4">
                    <div class="time_info">
                      <h2>Time Slot</h2>
                      <select
                        // onChange={this.changeTimeSlots.bind(this)}
                        name="slot_time"
                        id="slote_time"
                        defaultValue={orderDetails.slot_time}
                        className="form-control"
                        onChange={(e) => {
                          this.state.selectSlotTime = e.target.value;
                        }}
                      >
                        <option value="" disabled={true}>
                          Select Time
                        </option>
                        <optgroup label="Early Morning">
                          {this.getTimeSlots("07:00", 3).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>
                        <optgroup label="Morning">
                          {this.getTimeSlots("10:00", 2).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>
                        <optgroup label="Afternoon">
                          {this.getTimeSlots("12:00", 3).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>
                        <optgroup label="Evening">
                          {this.getTimeSlots("15:00", 3).map((times) => {
                            return <option value={times}>{times}</option>;
                          })}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="date_info">
                      <h2>Date Slot</h2>
                      <input
                        type="date"
                        id="slote_date"
                        defaultValue={orderDetails.slot_date}
                        name="slot_date"
                        min={moment().format("YYYY-MM-DD")}
                        onChange={(e) => {
                          this.state.selectSlotDate = e.target.value;
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <button
                      type="button"
                      id={orderDetails.id}
                      onClick={this.changeTimeSlots.bind(this)}
                      class="assign_btn1"
                    >
                      Update
                    </button>
                  </div>
                </div>

                <div class="delivery_list">
                  <div class="item_amnt">
                    <p>{orderItemsLength} items</p>
                    <h2>
                      {retailerCurrency} {total}
                    </h2>
                  </div>

                  <div class="item_flow">
                    <div class="stepwizard">
                      <div dangerouslySetInnerHTML={{ __html: progressBar }} />
                    </div>
                  </div>
                  <div class="view_det_sec">
                    <div class="view_details_btn">
                      <a
                        orderId={orderDetails.id}
                        retailerId={orderDetails.retailer_id}
                        href="javascript:void(0);"
                        onClick={this.openOrderView.bind(
                          this,
                          orderDetails.id,
                          orderDetails.retailer_id
                        )}
                      >
                        <p>view details </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });

        this.setState({
          orderDetailsView: orderDetailsView,
        });
      }
    });
  };

  // For getting the files
  getFiles(files) {
    let patientId = this.state.patientId;
    let cartId = this.state.cartId;
    let uploadedFiles = [];
    let medicaldoc_pic = [];
    let data = "";

    var objLen = 0;
    if (this.state.files) {
      objLen = Object.keys(this.state.files).length;
    }
    if (objLen > 0) {
      this.state.files = files.concat(this.state.files);
    } else {
      this.setState({ files: files });
    }
    uploadedFiles = files;
    let totalFiles = this.state.files;
    this.forceUpdate();
    if (totalFiles.length <= 5) {
      var fileFormate = false;
      for (let i = 0; i <= uploadedFiles.length - 1; i++) {
        if (uploadedFiles[i].type) {
          if (
            uploadedFiles[i].type == "image/png" ||
            uploadedFiles[i].type == "image/jpeg"
          ) {
            fileFormate = true;
          } else {
            fileFormate = false;
            break;
          }
        }
      }

      if (fileFormate) {
        //$(".delivery_upload_img").empty();
        for (var cnt = 0; cnt < uploadedFiles.length; cnt++) {
          if (uploadedFiles[cnt].base64) {
            medicaldoc_pic.push({
              name: uploadedFiles[cnt].name,
              base64: uploadedFiles[cnt].base64,
              type: uploadedFiles[cnt].type,
              size: uploadedFiles[cnt].size,
            });
          } else {
            medicaldoc_pic.push({
              imgUrl: uploadedFiles[cnt],
            });
          }
        }

        data = {
          patient_id: patientId,
          cart_id: cartId,
          medicaldoc_pic: medicaldoc_pic,
          retailerEmail:
            this.state.completeOrderDate[0].diagnosis_registration_tbl.email,
          retailerName:
            this.state.completeOrderDate[0].diagnosis_registration_tbl
              .storename,
          orderId: this.state.selectedOrderId,
          customerName: this.state.completeOrderDate[0].patient_tbl.name,
        };

        Httpconfig.httptokenpost(
          Constant.siteurl + "api/DIAGNOSIS/Cart/prescriptionImage",
          data
        )
          .then((response) => {
            if (response.data.status == "200" && response.data.error == false) {
              this.getCompleteOrderDetails(
                this.state.selectedOrderId,
                this.state.retailerId
              );
              //this.getCompleteOrderDetailsReload();
              toast.success(response.data.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        toast.error("Upload files Jpeg / Png formate only!");
      }
    } else {
      toast.error("More than 5 Files not allowed!");
    }
  }

  //  for click on image

  ImageClick = (event) => {
    var array = this.state.files;
    var foundValue = array.filter((obj) => obj.name != event.target.id);
    this.setState({ files: foundValue });
  };

  //  for image zoom
  ImageZoomClick = (event) => {
    this.setState({
      zoomimage: event.currentTarget.src,
    });
  };

  changeSlot = (slotOrderId, event) => {
    this.state.slotOrderId = slotOrderId;
    $("#update_time_date-" + slotOrderId).toggle();
  };
  changeTimeSlots = (event) => {
    let timeSlot = $("#slote_time").val();
    let dateSlot = $("#slote_date").val();

    let slot_time =
      this.state.selectSlotTime != undefined
        ? this.state.selectSlotTime
        : timeSlot;
    let slot_date =
      this.state.selectSlotDate != undefined
        ? this.state.selectSlotDate
        : dateSlot;
    let orderId = event.target.id;
    if (slot_time == undefined) {
      var selectedStartTime = timeSlot.split(":")[0];
      var splitTime = timeSlot.split(" ");
    } else {
      var selectedStartTime = slot_time.split(":")[0];
      var splitTime = slot_time.split(" ");
    }

    let selectedAmPm = splitTime[splitTime.length - 1];
    let hours = moment(selectedStartTime + " " + selectedAmPm, [
      "h:mm A",
    ]).format("HH");
    if (slot_date == undefined) {
      var addedHoursToSelectedDate = moment(dateSlot).add(hours, "hours");
    } else {
      var addedHoursToSelectedDate = moment(slot_date).add(hours, "hours");
    }

    let currentDateTime = new Date(moment().format("YYYY-MM-DD HH:mm:ss"));
    let selectedSlotDateTime = new Date(addedHoursToSelectedDate);
    if (currentDateTime <= selectedSlotDateTime) {
      this.updateTimeSlots(slot_time, slot_date, orderId);
    } else {
      toast.error("selected slot time and date are not available");
      return;
    }
  };

  //update patient time slots

  updateTimeSlots = (slot_time, slot_date, orderId) => {
    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/orderProcess/updateTimeslot/",
      {
        slot_time: slot_time,
        slot_date: slot_date,
        orderId: orderId,
        email: this.state.email_id,
        name: this.state.name,
      }
    )
      .then((response) => {
        if ((response.data.status = 200)) {
          toast.success("Slots Updated Successfully");
          $(".presc_det").hide();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  //  For rendering the upload images
  renderingUploadedImgs() {
    var documentsRelated = [];
    if (this.state.files) {
      let imageArray = this.state.files;
      this.state.uploadedImages = [];
      if (imageArray.length > 0) {
        for (var i = 0; i < imageArray.length; i++) {
          let img = imageArray[i].name.split(".");
          let imgName = img[0];
          let imageName = imageArray[i].name;
          let imagebase64 = imageArray[i].base64;
          let imageType = imageArray[i].type;
          let imageSize = imageArray[i].size;
          let imageId = imageArray[i];

          documentsRelated.push(
            <div class={"upload_presc_img " + imgName}>
              <a
                href="#"
                class="thumbnail"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <img
                  src={imagebase64}
                  id={imgName}
                  value={imageName}
                  alt={imageName}
                  name={imageName}
                  onClick={this.ImageZoomClick.bind(this)}
                />
              </a>
              <div class="upload_top_img">
                <a
                  href="#"
                  class="thumbnail"
                  data-toggle="modal"
                  data-target="#lightbox"
                ></a>
                <img
                  class="delete_img"
                  id={"img_" + imageArray[i].name}
                  onClick={this.removeImageOnClick}
                  src="https://icons.iconarchive.com/icons/danieledesantis/playstation-flat/512/playstation-cross-black-and-white-icon.png"
                />
              </div>
            </div>
          );
        }
        $(".delivery_upload_img").after(documentsRelated);
      }
      this.setState({ uploadedImages: [] });
    }
  }

  // For removing the image
  removeImageOnClick = (event) => {
    let imageName = event.currentTarget.id.replace(
      "/uploads/patient/cart_prescriptions/",
      ""
    );
    let data = {
      image_name: imageName,
      cart_id: this.state.cartId,
    };

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/OM/Cart/deleteCartPrescriptionImageUploaded",
      data
    )
      .then((response) => {
        if (response.data.status == "200" && response.data.error == false) {
          this.getCompleteOrderDetails(
            this.state.selectedOrderId,
            this.state.retailerId
          );
          //this.getCompleteOrderDetailsReload();
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  render() {
    return (
      <main id="main_ord_nav">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          {this.state.is_view_profile == true ? (
            <section id="order_profile">
              <div class="pro_section">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <PatientSideMenu />
                    </div>

                    <div
                      class="col-lg-9 col-md-12 col-sm-12 col-xs-12"
                      id="first_set"
                    >
                      <div class="my_orders">
                        <div class="order_head">
                          <h2>My Orders</h2>
                          {/* <h5>Upcoming</h5> */}
                        </div>
                        <div class="my_order_list">
                          {this.state.orderDetailsView != "" ? (
                            this.state.orderDetailsView
                          ) : (
                            <h5>No orders found </h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <React.Fragment>
              {" "}
              <section id="order_view_det">
                <div class="pro_section">
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        id="second_set"
                      >
                        {this.state.orderDetails}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </React.Fragment>
          )}
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
