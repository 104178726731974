import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
//import toast from "../../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import Constant from "../../constants";
import { FormattedMessage, IntlProvider } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import Patnewcss from "../../public/css/patient/style-new.css";
import PatientHeader from "../patient/Sanarheader";
import PatientFooter from "../patient/Patientfooter";

import * as SecureLS from "secure-ls";
import Patcss from "../../public/css/patient/order-medicine.css";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});

export default class Careathome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},

    };
    this.handleValidation = this.handleValidation.bind(this);
  }

  // To get detais after first render
  componentDidMount = () => {

  };

  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };

  handleValidation(e) {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    
    if (!fields["name"]) {
      errors["name"] = "";
      formIsValid = false;
      errors["name"] = "Name cannot be empty";
    }
    if (!fields["emailid"]) {
      formIsValid = false;
      errors["emailid"] = "Email id is invalid";
    }

    if (typeof fields["emailid"] !== "undefined") {
      let lastAtPos = fields["emailid"].lastIndexOf("@");
      let lastDotPos = fields["emailid"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["emailid"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["emailid"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["emailid"] = "Email id is invalid";
      }
    }
    if (!fields["phonenumber"]) {
      errors["phonenumber"] = "";
      formIsValid = false;
      errors["phonenumber"] = "Mobile number cannot be empty";
    }
    if (typeof fields["phonenumber"] != "undefined") {
      if (fields["phonenumber"].length < 10) {
        formIsValid = false;
        errors["phonenumber"] = "Mobile number invalid";
      } else if (fields["phonenumber"].length > 10) {
        formIsValid = false;
        errors["phonenumber"] = "Mobile number invalid";
      }
    }
    if (!fields["typeofservice"]) {
      errors["typeofservice"] = "";
      formIsValid = false;
      errors["typeofservice"] = "Type of service cannot be empty";
    }
    
    if(fields["typeofservice"]=="Other"){
      errors["description"] = "";
      formIsValid = false;
      errors["description"] = "Description cannot be empty";
      
    }
    this.setState({ errors: errors });

    //return formIsValid;

    if (formIsValid == true) {
      Httpconfig.httptokenpost(
        Constant.siteurl + "api/Users/sendservicerequest", // + this.state.id
        {
          name: fields["name"],
          phonenumber: fields["phonenumber"],
          emailid:fields["emailid"] ,
          typeofservice: fields["typeofservice"],
          description: fields["description"] ? fields["description"] :"",
          address: fields["address"],
        }
      ).then((response) => {
        if (response.status == 200 && response.data.error == false) {
          toast.success(response.data.message);
          this.setState({
              fields: {},
          })
          //$("#homeatcare").reset()
          $("#homeatcare").get(0).reset()
        } else {
          toast.warn(response.data.message);
        }
      });
    }
  }

  render() {

    return (
      <main id="main">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>

          <section id="careathome">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="careathome_header">
                    <h4>Care at Home</h4>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-md-6">
                  <div className="careathome_form">
                    <form id="homeatcare">
                      <div class="form-group">
                        <label>Name<sup>*</sup></label>
                        <input type="text" class="form-control" placeholder="Enter Name" onChange={this.handleChange.bind(
                          this,
                          "name"
                        )} />
                        <span className="cRed">
                          {this.state.errors["name"]}
                        </span>
                      </div>
                      <div class="form-group">
                        <label>Mobile No<sup>*</sup></label>
                        <input type="number" class="form-control" placeholder="Mobile Number" onChange={this.handleChange.bind(
                          this,
                          "phonenumber"
                        )} />
                        <span className="cRed">
                          {this.state.errors["phonenumber"]}
                        </span>
                      </div>
                      <div class="form-group">
                        <label>Email Id<sup>*</sup></label>
                        <input type="email" class="form-control" placeholder="Email Id" onChange={this.handleChange.bind(
                          this,
                          "emailid"
                        )} />
                        <span className="cRed">
                          {this.state.errors["emailid"]}
                        </span>
                      </div>
                      <div class="form-group">
                        <label>Address</label>
                        <textarea class="form-control" placeholder="Address" cols="5" rows= "3" onChange={this.handleChange.bind(
                          this,
                          "address"
                        )}> </textarea>
                       
                        <span className="cRed">
                          {this.state.errors["address"]}
                        </span>
                      </div>
                      <div class="form-group">
                        <label>Type of Service<sup>*</sup></label>
                        <select class="form-control" id="typeofservice" onChange={this.handleChange.bind(
                          this,
                          "typeofservice"
                        )}>
                          {/*<option>Select</option>
                          <option>COVID Management</option>
                          <option>Specialist at Home</option>
                          <option>Specialty Nursing</option>
                          <option>Physiotherapist at home</option>
                          <option>Lab test at home</option>
                          <option>ICU at Home</option>
                          <option>Home Heatlh Aide services at Home</option>
                          <option>Doctor consulation at home</option>
                      <option>Other</option>*/}
                      <option>Select</option>
                          <option>Nursing attendants at home (Supportive and palliative care)</option>
                          <option>Medical Nursing Service at home (Nurse for Tracheostomy care)</option>
                          <option>Medical Nursing Service at home (ICU set up at home)</option>
                          <option>Physiotherapy at home</option>
                          <option>Eldercare at home</option>
                          <option>Doctor at home</option>
                          <option>Medical Equipment-Respiratory Care-Oxygen Concentrator</option>
                          <option>Medical Equipment-Respiratory Care-CPAP and BiPAP</option>
                          <option>Medical Equipment-Other-Hospital cot</option>
                          <option>Medical Equipment-Other-wheel chair</option>
                          <option>Medical Equipment-Other-monitor</option>
                          <option>Lab tests at home</option>
                          <option>Other</option>
                        </select>
                        <span className="cRed">
                          {this.state.errors["typeofservice"]}
                        </span>
                      </div>
                      <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" rows="3" onChange={this.handleChange.bind(
                          this,
                          "description"
                        )} ></textarea>
                        <span className="cRed">
                          {this.state.errors["description"]}
                        </span>
                      </div>
                      <div className="careathome_btn">
                        <button type="button" onClick={this.handleValidation}>Submit</button>
                      </div>

                    </form>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="careathome_img">
                    <img src="../images/patient/img//diagnostics/Carevector.svg"></img>
                  </div>
                </div>
              </div>



            </div>

          </section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
