import React, { Component, useState } from "react";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import { Link } from "react-router-dom";
import Httpconfig from "../helpers/Httpconfig";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import Constant from "../../constants";
import { FormattedMessage } from "react-intl"; // Backup Way to Convert
import { I18nPropvider, LOCALES } from "../../i18nProvider";
import translate from "../../i18nProvider/translate";
import PatientHeader from "../patient/Patientheader";
import Patcss from "../../public/css/patient/order-medicine.css";
import PatientMenu from "../patient/Patientmenu";
import PatientSideMenu from "../patient/Patientsidemenu";
import PatientFooter from "../patient/Patientfooter";
import { reactLocalStorage } from "reactjs-localstorage";
import FileBase64 from "react-file-base64";
import * as SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "medleymed",
});
const moment = require("moment");

const addressDetailsView = [];

export default class ManageAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { country_name: "India", stateName: "" },
      indianStates: [],
      indianCities: [],
      errors: {},
      add_address_form: "add_address_form collapse-hide",
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    var lang = localStorage.getItem("Language_selected");
    if (lang != null) {
      if (this.state.Language != lang) {
        this.state.Language = lang;
        this.forceUpdate();
      }
    } else {
      this.state.Language = "en-us";
    }

    var retrievedObject = ls.get("userObjEnc");
    let userObj = retrievedObject;
    if (userObj == "") {
      window.location.href = "./patientLogin";
    }
    if (userObj != "") {
      if (this.props.match.params.id) {
        this.editAddressBox("", this.props.match.params.id);
      }

      this.setState({
        name: userObj.name,
        user_mobile: userObj.mobile_number,
        email_id: userObj.email,
        token: userObj.accessToken,
        patientId: userObj.patient_id,
        profile_image: "",
      });
      this.forceUpdate();
    }

    this.getaddressInfo(userObj.patient_id);
    this.getCompleteStates();
    this.getProfileDetails(userObj.patient_id);
    const interval = setInterval(() => {
      this.getaddressInfo(userObj.patient_id);
    }, 10000);
  };

  // For getting the profile details
  getProfileDetails = (patientId) => {
    if (patientId != "") {
      let url_path = "api/Patients/" + patientId;
      let patientDob = "";
      Httpconfig.httptokenget(Constant.siteurl + url_path, {}).then(
        (response) => {
          let patientDob = "";
          if (response.data.status == 200 && response.data.error == false) {
            let PatientGender = response.data.data[0].gender;
            let patientProfileImage = "";
            if (response.data.data[0].profile_pic != null) {
              patientProfileImage =
                Constant.imgurl + response.data.data[0].profile_pic;
            }
            this.setState({
              patientProfileImage: patientProfileImage,
              PatientGender: PatientGender,
            });
          }
        }
      );
    }
  };

  // For removing the address
  removeAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let clickValue = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    var isConfirm = window.confirm("Are you sure to delete " + clickName + "?");
    if (isConfirm) {
      Httpconfig.httptokendelete(
        Constant.siteurl + "api/DIAGNOSIS/deliveryAddress/" + clickValue
      )
        .then((response) => {
          toast.success("Successfully Deleted Manage Address");
          this.getaddressInfo(this.state.patientId);
        })
        .catch((error) => {});
    }
  };

  // For getting default address
  defaultAddressBox = (e) => {
    const anchor = e.target.closest("a");
    let rowId = anchor.getAttribute("value");
    let clickName = anchor.getAttribute("name");
    let patientId = this.state.patientId;
    var isConfirm = window.confirm(
      "Are you sure to set Default address " + clickName + "?"
    );
    if (isConfirm) {
      Httpconfig.httptokenput(
        Constant.siteurl +
          "api/DIAGNOSIS/deliveryAddress/" +
          patientId +
          "/" +
          rowId
      )
        .then((response) => {
          toast.success("Successfully updated the Default Address ");
          this.getaddressInfo(this.state.patientId);
        })
        .catch((error) => {});
    }
  };

  // For updating the address
  editAddressBox = (e, id) => {
    let clickValue = "";
    if (!e) {
      clickValue = id;
    } else {
      const anchor = e.target.closest("a");
      clickValue = anchor.getAttribute("value");
      this.state.editId = clickValue;
      let clickName = anchor.getAttribute("name");
    }

    this.state.add_address_form = "add_address_form collapse-show";
    Httpconfig.httptokenget(
      Constant.siteurl + "api/DIAGNOSIS/deliveryAddress/" + clickValue
    )
      .then((response) => {
        this.getCitiesbasedonStateId(response.data.data[0].state);
        this.getaddressInfo(this.state.patientId);
        this.setState({
          fields: {
            patient_id: response.data.data[0].patient_id,
            username: response.data.data[0].name,
            mobile_no: response.data.data[0].mobile_no,
            location: response.data.data[0].location,
            address: response.data.data[0].address,
            landmark: response.data.data[0].landmark,
            latitude: response.data.data[0].latitude,
            longitude: response.data.data[0].longitude,
            city: response.data.data[0].city,
            stateName: response.data.data[0].state,
            country_name: response.data.data[0].country,
            zipcode: response.data.data[0].zipcode,
            address_type: response.data.data[0].address_type,
            isEdit: true,
            rowId: response.data.data[0].id,
          },
        });
      })
      .catch((error) => {});
  };

  // To get all the Patient Addresses
  getaddressInfo(patientId) {
    Httpconfig.httptokenget(
      Constant.siteurl +
        "api/DIAGNOSIS/deliveryAddress/findAllDeliveryAddress/" +
        patientId
    )
      .then((response) => {
        if (response.data.status == "200") {
          const addressDetailsView = response.data.data.map(
            (addressDetails, num) => {
              return (
                <React.Fragment>
                  {this.state.editId != addressDetails.id ? (
                    <div className="add_address_box">
                      <div className="add_address_det">
                        <h2>
                          {addressDetails.name}{" "}
                          <p>({addressDetails.address_type})</p>
                          <span>+{addressDetails.mobile_no}</span>
                        </h2>
                        <h6>
                          {addressDetails.address}
                          {addressDetails.landmark}
                        </h6>
                      </div>

                      <div className="add_address_bar">
                        <li className="nav-item dropdown">
                          <a href="#" id="navbardrop" data-toggle="dropdown">
                            <img src="../images/patient/img/Ordermedicine/orangedotmenu.svg" />
                          </a>

                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              name={addressDetails.name}
                              value={addressDetails.id}
                              href="javascript:void(0);"
                              onClick={this.editAddressBox.bind(this)}
                            >
                              Edit
                            </a>

                            <a
                              className="dropdown-item"
                              name={addressDetails.name}
                              value={addressDetails.id}
                              href="javascript:void(0);"
                              onClick={this.defaultAddressBox.bind(this)}
                            >
                              Set as Default
                            </a>
                            <a
                              className="dropdown-item"
                              name={addressDetails.name}
                              value={addressDetails.id}
                              href="javascript:void(0);"
                              onClick={this.removeAddressBox.bind(this)}
                            >
                              Delete
                            </a>
                          </div>
                        </li>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            }
          );
          this.setState({
            addressDetailsView: addressDetailsView,
          });
        } else if (
          response.data.status == "401" &&
          response.data.token == false
        ) {
          Httpconfig.httptokenget(
            Constant.siteurl +
              "api/Users/logout/" +
              localStorage.getItem("userID")
          );
          window.location.href = "./patientLogin";
        }
      })
      .catch((error) => {});
  }

  // For getting the files
  getFiles(files) {
    this.setState({ files: files });
    let patientId = this.state.patientId;
    let patientProfileImage = this.state.files[0].base64;
    this.state.patientProfileImage = patientProfileImage;
    this.forceUpdate();
    Httpconfig.httptokenput(
      Constant.siteurl + "api/Users/uploadimages/" + patientId,
      {
        profile_pic: this.state.files[0].base64,
      }
    )
      .then((response) => {
        toast.success("👌 Profile Image Updated Successfully", {
          position: "bottom-center",
        });
      })
      .catch((error) => {
        this.props.history.push("/patienthealthprofile");
        toast.error(error);
      });
  }

  //  For submitting the form
  checkSubmit(event) {
    event.preventDefault();
    let isEditEnabled = this.state.fields.isEdit;
    if (this.handleValidation()) {
      if (isEditEnabled) {
        this.updateAddressModule(event);
      } else {
        this.createAddressModule(event);
      }
    }
  }

  // creates address Module
  createAddressModule = async (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
    let enteredAddress = $('textarea[name="address"]').val();
    await this.getLatLangfromAddress(address);
    let address_latitude = localStorage.getItem("address_latitude");
    let address_longitude = localStorage.getItem("address_longitude");

    Httpconfig.httptokenpost(
      Constant.siteurl + "api/DIAGNOSIS/deliveryAddress",
      {
        patient_id: this.state.patientId,
        name: fields["username"],
        mobile_no: fields["mobile_no"],
        // location: fields["location"] ? fields["location"] : "",
        location: fields["address"],
        address: fields["address"],
        city: fields["city"],
        state: fields["stateName"],
        country: fields["country_name"],
        zipcode: fields["zipcode"],
        landmark: fields["landmark"],
        // latitude: address_latitude ? address_latitude : "",
        // longitude: address_longitude ? address_longitude : "",
        latitude: this.state.fields.latitude ? this.state.fields.latitude : "",
        longitude: this.state.fields.longitude
          ? this.state.fields.latitude
          : "",
        address_type: fields["address_type"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Successfully Created Manage Address");
          this.getaddressInfo(this.state.patientId);
          this.setState({
            add_address_form: "add_address_form collapse-hide",
          });

          this.forceUpdate();
          window.location.reload();
        } else if (response.data.status == 200 && response.data.error == true) {
          toast.error(response.data.message);
          this.state.fields = {
            username: fields["username"],
            mobile_no: fields["mobile_no"],
            location: fields["address"],
            address: fields["address"],
            city: fields["city"],
            stateName: fields["stateName"],
            country: fields["country_name"],
            zipcode: "",
            landmark: fields["landmark"],
            address_type: fields["address_type"],
          };
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateAddressModule = async (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;
    let rowId = this.state.fields.rowId;
    let address =
      fields["address"] +
      "," +
      fields["city"] +
      "," +
      $("#state-dropdown :selected").text() +
      "," +
      fields["zipcode"] +
      "," +
      fields["country_name"];
 await this.getLatLangfromAddress(address);
   setTimeout(()=>{
    Httpconfig.httptokenput(
      Constant.siteurl + "api/DIAGNOSIS/deliveryAddress/" + rowId,
      {
        patient_id: this.state.patientId,
        name: fields["username"],
        mobile_no: fields["mobile_no"],
        location: fields["location"] ? fields["location"] : "",
        address: fields["address"],
        landmark: fields["landmark"],
        city: fields["city"],
        state: fields["stateName"],
        country: fields["country_name"],
        zipcode: fields["zipcode"],
        // latitude: fields["latitude"] ? fields["latitude"] : "",
        // longitude: fields["longitude"] ? fields["longitude"] : "",
        latitude: this.state.fields.latitude ? this.state.fields.latitude : "",
        longitude: this.state.fields.longitude
          ? this.state.fields.longitude
          : "",
        address_type: fields["address_type"],
      }
    )
      .then((response) => {
        if (response.data.status == 200 && response.data.error == false) {
          toast.success("Successfully Updated Manage Address");
          this.setState({
            add_address_form: "add_address_form collapse-hide",
          });
          window.location.reload();

          this.getaddressInfo(this.state.patientId);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
   }, 2500)

return
 
  };

  // For getting the latlong from address
  getLatLangfromAddress = (enteredAddress) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("address_latitude");
      localStorage.removeItem("address_longitude");
      var latitude = "";
      var longitude = "";
      var url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        enteredAddress +
        "&key=" +
        Constant.googleAPiKey;
      $.getJSON(url, function (data, textStatus) {
        var responseStatus = data.status;
      
        if (responseStatus === "OK") {

          latitude = data.results[0].geometry.location.lat;
          longitude = data.results[0].geometry.location.lng;
          localStorage.setItem("address_latitude", data.results[0].geometry.location.lat);
          localStorage.setItem("address_longitude", data.results[0].geometry.location.lng);
          resolve();
        }
      });

      setTimeout(()=>{
        let address_latitude = localStorage.getItem("address_latitude");
        let address_longitude = localStorage.getItem("address_longitude");
       if (address_latitude && address_longitude) {
          this.state.fields.latitude = address_latitude;
          this.state.fields.longitude = address_longitude;
          this.state.fields.country_name = "India";
          this.forceUpdate();
          // setTimeout(function () {
          //   localStorage.removeItem("address_latitude");
          //   localStorage.removeItem("address_longitude");
          // }, 1000);
        }
      },1000)
      

    });
  };

  // For getting the state information

  getCompleteStates() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Country/states/" + Constant.countryID
    )
      .then((response) => {
        this.setState({
          indianStates: response.data.data,
        });
      })
      .catch((error) => {});
  }

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;

    if (field == "stateName") {
      this.getCitiesbasedonStateId(event.target.value);
    }
    // if (field == "zipcode") {
    //   this.displayPostcode(event.target.value);
    // }

    this.setState({ fields });
  };

  // For getting the state id based on citi
  getCitiesbasedonStateId(stateId) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country/cities/" + stateId)
      .then((response) => {
        this.setState({
          indianCities: response.data.data,
        });
      })
      .catch((error) => {});
  }
  //  For validating the fields
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Name cannot be empty";
    }
    if (!fields["mobile_no"]) {
      formIsValid = false;
      errors["mobile_no"] = "Mobile No cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address cannot be empty";
    }
    if (!fields["address_type"]) {
      formIsValid = false;
      errors["address_type"] = "Address type cannot be empty";
    }
    if (!fields["zipcode"]) {
      formIsValid = false;
      errors["zipcode"] = "Pincode cannot be empty";
    }
    if (!fields["stateName"]) {
      formIsValid = false;
      errors["statename"] = "State name cannot be empty";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  // For adding the new address
  addNewAddress = () => {
    this.state.add_address_form = "add_address_form collapse-show";
  };

  render() {
    return (
      <main id="main_ord_nav">
        <PatientHeader onSelectLanguage={this.handleLanguage} />
        <I18nPropvider locale={this.state.Language}>
          <section id="order_profile">
            <div className="appoint_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <PatientSideMenu />
                  </div>

                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <section id="order_add_address">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="add_address_sec">
                              <div className="add_address_head">
                                <h2>Manage Address</h2>
                              </div>
                              <a href="#" onClick={this.addNewAddress}>
                                <div className="add_address_btn">
                                  <p>Add New Address</p>
                                </div>
                              </a>

                              <div className={this.state.add_address_form}>
                                <form onSubmit={this.checkSubmit.bind(this)}>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-7">
                                        <label>New User</label>
                                        <input
                                          type="text"
                                          name="username"
                                          value={this.state.fields["username"]}
                                          className="form-control"
                                          placeholder="Name"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "username"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["username"]}
                                        </span>
                                      </div>

                                      <div className="col-md-5">
                                        <label>+91</label>
                                        <input
                                          type="text"
                                          name="mobile_no"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          // minLength="10"
                                          // maxLength="10"
                                          value={
                                            this.state.fields["mobile_no"] || ""
                                          }
                                          maxlength="10"
                                          className="form-control"
                                          placeholder="Mobile number"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "mobile_no"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["mobile_no"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label>Complete Address</label>
                                    <textarea
                                      className="form-control"
                                      rows="3"
                                      name="address"
                                      value={this.state.fields["address"] || ""}
                                      placeholder="Enter Full address"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "address"
                                      )}
                                      // onBlur={() =>
                                      //   this.getLatLangfromAddress.bind(this)
                                      // }
                                    ></textarea>
                                    <span className="cRed">
                                      {this.state.errors["address"]}
                                    </span>
                                  </div>
                                  <div className="form-group">
                                    <label>
                                      Land Mark<span>(Optional)</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="landmark"
                                      value={
                                        this.state.fields["landmark"] || ""
                                      }
                                      placeholder="Enter Landmark"
                                      onChange={this.handleChange.bind(
                                        this,
                                        "landmark"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>State</label>

                                        <select
                                          name="statename"
                                          id="state-dropdown"
                                          className="form-control"
                                          value={this.state.fields["stateName"]}
                                          onChange={this.handleChange.bind(
                                            this,
                                            "stateName"
                                          )}
                                        >
                                          <option value="">Select State</option>
                                          {this.state.indianStates.map(
                                            (stateList, i) => {
                                              return (
                                                <option value={stateList.id}>
                                                  {stateList.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        <span className="cRed">
                                          {this.state.errors["statename"]}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <label>City</label>

                                        <select
                                          name="city"
                                          className="form-control"
                                          value={this.state.fields["city"]}
                                          onChange={this.handleChange.bind(
                                            this,
                                            "city"
                                          )}
                                        >
                                          <option>Select City</option>
                                          {this.state.indianCities.map(
                                            (cityData, i) => {
                                              return (
                                                <option value={cityData.name}>
                                                  {cityData.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        <span className="cRed">
                                          {this.state.errors["city"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Pincode</label>
                                        <input
                                          type="text"
                                          name="zipcode"
                                          value={this.state.fields["zipcode"]}
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          minLength="6"
                                          maxLength="6"
                                          className="form-control"
                                          placeholder="Enter Pincode"
                                          onChange={this.handleChange.bind(
                                            this,
                                            "zipcode"
                                          )}
                                        />
                                        <span className="cRed">
                                          {this.state.errors["zipcode"]}
                                        </span>
                                      </div>
                                      <div className="col-md-6">
                                        <label>Country</label>
                                        <select
                                          name="country_name"
                                          className="form-control"
                                          value={
                                            this.state.fields["country_name"]
                                          }
                                          onChange={this.handleChange.bind(
                                            this,
                                            "country_name"
                                          )}
                                        >
                                          <option>
                                            {this.state.fields.country_name}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="form-group"
                                    onChange={this.handleChange.bind(
                                      this,
                                      "address_type"
                                    )}
                                  >
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="address_type"
                                        value="Home"
                                        checked={
                                          this.state.fields["address_type"] ===
                                          "Home"
                                        }
                                      />
                                      Home
                                    </label>
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="address_type"
                                        value="Work"
                                        checked={
                                          this.state.fields["address_type"] ===
                                          "Work"
                                        }
                                      />
                                      Work
                                    </label>
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="address_type"
                                        value="Other"
                                        checked={
                                          this.state.fields["address_type"] ===
                                          "Other"
                                        }
                                      />
                                      Other
                                    </label>
                                    <span className="cRed">
                                      {this.state.errors["address_type"]}
                                    </span>
                                  </div>

                                  <div className="">
                                    <button
                                      type="submit"
                                      className="btn  btn-primary padTopCategorySave"
                                    >
                                      Save Address
                                    </button>{" "}
                                  </div>
                                </form>
                              </div>

                              {this.state.addressDetailsView}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PatientFooter />
        </I18nPropvider>
      </main>
    );
  }
}
