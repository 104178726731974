import { LOCALES } from '../constants';

export default {
  [LOCALES.JAPANESE]: {
    'hello': 'こんにちは',
    'Login': "ログインする",
    "Logout":"ログアウト",
    "Please enter your confirmation code":"確認コードを入力してください",
    "Enter code":"コードを入力する",
    "Continue":"続けてください",
    'Language': "言語",
    'Consult a Doctor': '医師に相談する',
    'Video consultation': "ビデオ相談",
    'Video consultation over app': "アプリに関するビデオ相談",
    'In person visit': '直接訪問',
    'Meet The Doctor In Clinic': 'クリニックで医者に会う',
    'Order Medicine': "薬を注文する",
    'Book For Diagnostic': "診断書",
    'Care at Home': "在宅ケア",
    'Consult Top Specialists now' : "今すぐトップスペシャリストにご相談ください",
    'Cough and cold' : "咳と風邪",
    "Book Full Body Checkups":"全身検診の予約",
    "Book Diagnostic Tests":"本の診断テスト",
    "Home Care":"ホームケア",
    "Trending Products":"トレンドの製品",
    "Doctors":"医師",
    'Consult now' : "今相談する",
    'Best Doctors from your nearest clinic' : "最寄りのクリニックの最高の医師",
    'Jaya Clinic' : "ジャヤクリニック",
    'Madhapur' : "マダプール",
    'Directions' : "行き方",
    'ENT' : "耳鼻咽喉科",
    'Cardiologist' : "心臓専門医",
    'MRP' : "MRP",
    'Add' : "追加",
    'off' : "オフ",
    'Download the MedleyMed app' : "MedleyMedアプリをダウンロード",
    'Book appointments and health checkups' : "本の予約と健康診断",
    "Order medicines and consult doctors online":"薬を注文して医師にオンラインで相談する",
    'Get the link to download the app' : "アプリをダウンロードするためのリンクを取得する",
    'Get App link' : "アプリリンクを取得",
    'For patients' : "患者様向け",
    'Search for doctors' : "医師を探す",
    'Search for Clinics' : "クリニックを検索",
    'Search for hospitals' : "病院を探す",
    'Our Services' : "私たちのサービス",
    'Order Medicines' : "医薬品を注文する",
    'Book Lab Tests' : "ラボテストの予約",
    'Consult a Doctor' : "医師に相談する",
    'Our Policies' : "私たちの方針",
    'Privacy Policy' : "個人情報保護方針",
    'Terms and Conditions' : "規約と条件",
    'Return Policy' : "返品規則",
    'Social' : "ソーシャル",
    'Need Help' : "助けが必要",
    'Our App' : "私たちのアプリ",
    'App Store' : "App Store",
    'Play Store' : "プレイストア",
    "Copyright":"著作権",
    "MedleyMed":"メドレーメッド",
    "All rights reserved":"全著作権所有",
    "This number will be used for doctor consultation related information, new offers. We will send a code for verification to this number":"この番号は、医師の診察に関連する情報、新しいオファーに使用されます。確認用のコードをこの番号に送信します",
    "Login":"ログインする",
    "Signup":"サインアップ",
    "Next":"次",
    "Use Email ID":"メールIDを使用",
    "Mobile Number":"携帯電話番号",
    "We will send you an SMS with a confirmation code to this number":"この番号に確認コードを記載したSMSを送信します",
    "Please keep your health profile updated for better consultation experience":"診療体験を向上させるために、健康プロファイルを更新してください。",
    "Who is the Patient?":"患者は誰ですか？",
    "Profile Completed":"プロファイルが完了しました",
    "Add family Member":"家族会員を追加",
    "Help us with your information":"あなたの情報を手伝ってください",
    "You Can Select or Write The Date of Birth":"生年月日を選択または書き込むことができます",
    "Gender":"性別",
    "Male":"男性",
    "Female":"女性",
    "Other":"その他の",
    "Date of birth":"生年月日",
    "Save & Next":"保存して次へ",
    "(Me)":"わたし",
    "Enter Mobile number":"携帯番号を入力してください",
    "Name":"名前",
    "Email":"Eメール",
    "Enter email id":"メールIDを入力してください",
    "Mobile number":"携帯電話番号",
    "Please keep your health profile updated for better consultation experience":"診療体験を向上させるために、健康プロファイルを更新してください。",
    "Profile Completed":"プロファイルが完了しました",
    "Who is the Patient":"患者は誰ですか",
    "Specify any long-term illness or chronic conditions present":"存在する長期的な病気または慢性状態を特定する",
    "if any":"もしあれば",
    "Optional":"オプション",
    "Related medication":"関連薬",
    "Drug allergies present":"薬物アレルギーの存在",
    "Back":"バック",
    "Do it later":"後でやる",
    "Save":"保存する",
    "Next":"次",
    "Add family Member":"家族会員を追加",
    "Diabetes":"糖尿病",
    "Blood Pressure":"血圧",
    "Headache":"頭痛",
    "Type here eg. Diabetes":"ここにType。糖尿病",
    "Enter family member name":"家族の名前を入力してください",
    "Enter mobile number":"家族の名前を入力してください",
     "Hello!":"こんにちは",
 "My Appointment":"私の約束",
 "My Profile":"私のプロフィール",
 "Privacy Policy":"個人情報保護方針",
 "Terms & Conditions":"利用規約",
 "Upcoming":"今後の",
 "Tele medicine":"遠隔医療",
 "In Person Visit":"直接訪問",
 "Your appointment scheduled on":"予定されている予定",
 "Join Now":"今すぐ参加",
 "Past":"過去",
 "appointment with":"との約束",
 "Directions":"行き方",
 "Doctor is Available now":"医者は今利用可能です",
 "View Prescription":"処方箋を見る",
 "Please wait for the call from clinic front desk":"クリニックフロントからのお電話お待ちしております",
 "You will receive call from a clinics front desk and confirm your time slot":"診療所のフロントから電話を受け、時間帯を確認します",
 "Dr. Carla Houston":"カーラヒューストン博士",
 "Dr. Carla Houston":"カーラヒューストン博士",
 "Cardiologist, MD":"心臓専門医、MD",
 "14 years experience":"14年の経験",
 "Plot No:266,3rd Floor,Prakash Nilayam,Guttala Begumpet":"プロットNo：266、3階、プラカシュ・ニラヤム、グッタラ・ベガンペット",
 "Samir Saxena":"サミル・サクセナ",
 "(Wife)":"（妻）",
 "55 Female":"55女性",
 "Dr. Srikar":"スリカー博士",
 "Find the doctor near you":"お近くの医者を見つけてください",
 "Meet The Doctor In Clinic":"クリニックで医師に会います",

 "Enter the details for":"詳細を入力してください",
 "Patient Intake Process":"患者の摂取プロセス",
 "Purpose of consultation":"相談の目的",
 "Specify Symptoms":"症状を指定する",
 "Provide Vital Informations":"重要な情報を提供する",
 "History of Past Illness":"過去の病歴",
 "Enter your vital information":"重要な情報を入力してください",
 "Height":"高さ",
 "Weight":"重量",
 "BMI":"BMI",
 "Breath Per Minute":"毎分呼吸",

  "Normal":"正常",
  "Medium":"中",
  "High":"高い",
  "Very high":"非常に高い",

  "Obese":"肥満",
  "Over weight":"太りすぎ",
  "Underweight":"低体重",

  "cm":"CM",
  "kg":"kg",

 "Temperature":"温度",
 "Pulse":"脈動",
 "Blood Sugar":"血糖",
 "Respiratory Rate":"呼吸数",
 "Systolic":"シストリック",
 "Specify Symptoms":"症状を指定する",
    "Symptoms related to":"に関連する症状",
    "or Choose common symptoms":"または一般的な症状を選択します",
    "Search Symptom":"症状を検索",
    "Select Symptom from body":"身体から症状を選択",
    "Add New Symptoms":"新しい症状を追加",
    "Rotate Model":"モデルを回転",
    "Click on the body model":"ボディモデルをクリックします",
    "Abdominal pain":"腹痛",
    "select body part":"体の部分を選択",
    "Search your symptoms by search or":"検索で症状を検索するか",
    "History of Past Illness":"過去の病歴",
    "Past Medical History":"既往歴",
    "Social History":"社会の歴史",
    "Chronic conditions":"慢性状態",
    "Chronic medication":"慢性的な薬",
    "Add Current Medication":"現在の薬を追加",
    "Drug Allergies":"薬物アレルギー",
    "Add Medical Document":"医療文書を追加",
    "Upload Document":"ドキュメントをアップロード",
    "Enter code":"コードを入力する",
    "Continue":"継続する",
    "Please enter your confirmation code":"確認コードを入力してください",
    "Type here eg. Fever" : "ここに入力してください。熱",
    "mg/dL":"mg/dL",
    "bpm":"bpm",
    "F":"F",
    "Kg/m2":"Kg/m2",
    "Feet.Inch":"Feet.Inch",

    "Patient Details":"患者の詳細",
    "Patient Intake Process":"患者の摂取プロセス",
    "Filters":"フィルター",
    "Clear all":"すべてクリア",
    "Languages":"言語",
    "Sorting":"仕分け",
    "Choose Specialist":"スペシャリストを選択",
    "Gender":"性別",
    "Experience":"経験",
    "Mode":"モード",
    "Ratings":"評価",
    "View Profile":"プロフィールを見る",
    "Book an Appointment":"予約を取る",
    "reviews":"レビュー",
    "Registration No":"登録番号",
    "Fees":"料金",
    "Education":"教育",
    "I can speak":"私は話せる",
    "Practice":"練習",
    "Feedback":"フィードバック",
    "My Area of expertise":"私の専門分野",
    "When do you want to consult a doctor":"いつ医師に相談したいですか",
    "Please wait for doctor confirmation":"医師の確認をお待ちください",
    "Doctor will confirm shortly":"医師はまもなく確認します",
    "Doctor confirmed":"医師確認済み",
    "Please proceed to pay for the consultation":"相談の支払いに進んでください",
    "Pay Now":"今払う",
    "Schedule your appointment":"あなたの予定をスケジュールする",
    "Or":"または",
    "Consult later":"後で相談する",
    "Afternoon":"午後",
    "Evening":"イブニング",
    "Morning":"朝",
    "Night":"夜",
    "am":"午前",
    "pm":"午後",

    "Select Doctor":"ドクターを選択",
    "Available Doctors for Telemedicine":"遠隔医療で利用可能な医師",
    "Dr":"博士",
    "appointment with":"との約束",
    "Consultation Time":"相談時間",
    "Purpose of visit":"滞在目的",
    "Symptoms":"症状",
    "Vital Informations":"重要な情報",
    "Social History":"社会の歴史",
    "Patient Drug Allergies":"患者の薬物アレルギー",
    "Uploaded Document":"アップロードされたドキュメント",
    "Fee Details":"料金詳細",
    "Doctor consultation Fees":"医師の診察料",
    "Have a Coupon Code":"クーポンコードを持っている",
    "Enter Your Code":"コードを入力してください",
    "Apply":"申し込む",
    "Net Payable Amount":"正味支払額",
    "Accept Terms and conditions":"利用規約に同意する",
    "Book Now":"今予約する",
    "Your appointment will be start at":"あなたの約束はから始まります",
    "All" : "すべて" ,
    "Choose Specialist":"スペシャリストを選択",
    "Find the doctor near you":"お近くの医者を探します",
    "Diabetes":"糖尿病",
    "eg. I had jaundice 2 months back":"例えば。 2ヶ月前に黄疸が出ました",
    "You have":"あなたが持っている",
    "appointment with":"との約束",
    "Paid":"有料",
    "Paid through":"支払い済み",
    "Online Payment":"オンライン支払い",
    "Medium":"中",
    "Audio & Video":"オーディオビデオ",
    "Cancel Appointment":"予約をキャンセルする",
    "Appointment Booked":"予約済みの予定",
    "The doctor advised you for a followup appointment on":"医者はあなたにフォローアップの約束をするようにアドバイスしました",
    "Completed":"完了",
    "Cancelled":"キャンセル",
    "Book":"本",
    "Prescription":"処方",
    "Past Medical History":"既往歴",
    "Feedback":"フィードバック",
    "Give Your Feedback":"フィードバックをお寄せください",
    "to this consultation":"この相談に",
    "Please comment on the consultation":"相談にコメントしてください",
    "Submit":"参加する",
    "Write here":"ここに書く",
    "My Orders":"私の注文",
    "Manage Address":"住所の管理",
    "(Me)":"（私）",
    "You Can":"あなたはできる",
    "Select":"選択する",
    "or":"または",
    "Write":"書く",
    "The Date of Birth":"生年月日",
    "kilograms":"キログラム",
    "Doctor is busy now":'医者は今忙しい',
    "years experience":"長年の経験",
    "reviews":"レビュー",
    "years":"年",
    "Above":"上",
    "English":"英語",
    "Japanese":"日本語",
    "Choose Specialist":"スペシャリストを選択",
    "Common Health Problems":"一般的な健康上の問題",
    "Select your specialist":"スペシャリストを選択してください",
    "Rating":"評価",
    "Price - Low to high":"価格-低から高",
    "Price - High to low":"価格-高から低",
    "Experience":"経験",
    "Proceed":"続行します",
    "consultation Fees":"相談料",
    "Consent to view health details":"健康の詳細を表示することに同意します",
    "Uncheck if you do not want to allow the doctor to see your health details":"医師にあなたの健康の詳細を見せたくない場合は、チェックを外してください",
    "Blood pressure":"血圧",
    "Height":"高さ",
    "Pulse Rate":"脈拍数",
    "Temperature":"温度",
    "Respiratory Rate":"呼吸数",
    "Blood Glucose":"血糖値",
    "Body Mass Index":"ボディ・マス・インデックス",
    "Select Reason For Cancel":"キャンセルの理由を選択",
    "I am not available at this time":"現時点ではご利用いただけません",
    "Something more important came up":"もっと重要なことが思い浮かびました",
    "Reschedule later":"後で再スケジュールする",
    "Other":"その他",
    "OK":"",
    "Cancel":"キャンセル",











  },
};
// JAPANESE
