import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../helpers/toast";
import Httpconfig from "../helpers/HttpconfigAdmin";
import Constant from "../../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import FileBase64 from "react-file-base64";
import constants from "../../constants";
const fs = require("fs");

export default class Createclinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      clinic_data: "",

      address: "",

      options: [],
      speciality_options: [],

      langarray: [],
      languagesarray: [],
      selectedLangList: {},
      selectedLangValue: [],
      indianStates: [],
      indianCities: [],
      pageHeading: "",
    };
  }

  // To get detais after first render
  componentDidMount = () => {
    const { handle } = this.props.match.params;
    handle
      ? (this.state.pageHeading = "Update Clinic")
      : (this.state.pageHeading = "Create Clinic");
    this.getClinicSpecialities();
    this.fetchLanguagesData();
    this.getClinicTimezones();
    this.getCompleteStates();
    this.getClinicInfo(handle);
  };

  // When value changes of the fields
  handleChange = (field, event) => {
    let fields = this.state.fields;
    if (field == "citycode")
      fields[field] = event.target.value.toLocaleUpperCase();
    else fields[field] = event.target.value;
    this.setState({ fields });
    if (field == "clinic_state") {
      this.getCitiesbasedonStateId(event.target.value);
    }
  };
  //  For fetching the lamguages data
  fetchLanguagesData() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Languages").then(
      (response) => {
        this.setState({
          options: response.data,
          userarray: response.data,
        });
      }
    );
  }

  //  For getting the clininc specialities
  getClinicSpecialities(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Category")
      .then((response) => {
        this.setState({
          speciality_options: response.data.data,
          speciality_userarray: response.data.data,
        });
      })
      .catch((error) => {});
  }

  //  For onselect

  onSelect = (selectedListdata, selectedItem) => {
    this.setState({
      selectedLangName: selectedListdata.map((x) => x.name),
    });
    this.setState({
      selectedLangId: selectedListdata.map((x) => x.id),
    });
  };

  //  For on remove
  onRemove = (deselectedList, removedItem) => {
    this.setState({
      selectedList: deselectedList.map((x) => x.id),
    });
  };

  //  For select speciality
  onSpecialitySelect = (selectedSpecialityListdata, selectedItem) => {
    this.setState({
      selectedSpecialityName: selectedSpecialityListdata.map(
        (x) => x.category_type
      ),
    });
    this.setState({
      selectedSpecialityId: selectedSpecialityListdata.map((x) => x.id),
    });
  };

  //  For speciality remove
  onSpecialityRemove = (deselectedSpecialityList, removedItem) => {
    this.setState({
      selectedSpecialityList: deselectedSpecialityList.map((x) => x.id),
    });
  };

  // Get Timezone
  getClinicTimezones() {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country/timezoneList")
      .then((response) => {
        this.setState({
          timezone: response.data.data,
        });
      })
      .catch((error) => {});
  }

  //  For getting clinic images
  getClinicImageFile(files) {
    var fileFormate = false;
    if (files[0].type == "image/png" || files[0].type == "image/jpeg") {
      fileFormate = true;
    } else {
      fileFormate = false;
    }
    if (fileFormate) {
      this.setState({ ClinicImageFiles: files });
    } else {
      toast.error("Upload files Jpeg/Png formate only!");
    }
  }

  // To get all the ResponderInfo
  getClinicInfo(handle) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Clinic/" + handle)
      .then((response) => {
        this.getCitiesbasedonStateId(response.data.data[0].state_code);
        this.setState({
          fields: {
            clinic_name: response.data.data[0].clinic_name,
            address: response.data.data[0].address,
            clinic_state: response.data.data[0].state_code,
            clinic_city: response.data.data[0].city_name,
            citycode: response.data.data[0].city_code,
            pincode: response.data.data[0].pincode,
            kyc_status: response.data.data[0].kyc_status,
            clinic_phone_no: response.data.data[0].clinic_phone_no,
            email_id: response.data.data[0].email_id,
            password: response.data.data[0].password,
            latitude: response.data.data[0].latitude,
            longitude: response.data.data[0].longitude,
            timezone_name: response.data.data[0].timezone_name,
            clinic_registration_no:
              response.data.data[0].clinic_registration_no,
            clinic_type: response.data.data[0].clinic_type,
            clinic_logo: response.data.data[0].clinic_logo,

            selected_language_id: response.data.data[0].selected_language_id,
            selected_language_name:
              response.data.data[0].selected_language_name,

            speciality_id: response.data.data[0].speciality_id,
            speciality_name: response.data.data[0].speciality_name,
          },
        });

        var usersdata = this.state.userarray;
        var specialitydata = this.state.speciality_userarray;

        let editLangData = [];
        let customerData = response.data.data[0].selected_language_id;

        let editLangList = customerData.split(",");
        editLangList.forEach(function (item, index) {
          usersdata.forEach(function (obj, i) {
            if (obj.id == item) {
              return editLangData.push(obj);
            }
          });
        });
        this.setState({
          selectedValue: editLangData,
          selectedList: editLangList,
        });
        // Ends

        // Edit Speciality Data
        let editSpecialityData = [];
        let specialityData = response.data.data[0].speciality_id;
        let editSpecialityList = specialityData.split(",");
        editSpecialityList.forEach(function (item, index) {
          specialitydata.forEach(function (obj, i) {
            if (obj.id == item) {
              return editSpecialityData.push(obj);
            }
          });
        });
        this.setState({
          speciality_selectedValue: editSpecialityData,
          speciality_selectedList: editSpecialityList,
        });

        this.forceUpdate();
      })
      .catch((error) => {});
  }

  // create or update
  checkSubmit(event) {
    event.preventDefault();
    const { handle } = this.props.match.params;
    if (this.handleValidation() && handle) {
      this.updateClinic(event);
    } else if (this.handleValidation() && handle == undefined) {
      this.createClinic(event);
    } else {
      toast.warn("Form has errors.");
    }
  }
  // creates new controller
  createClinic = (event) => {
    event.preventDefault();
    const { fields, errors } = this.state;

    let clinicLogo = "";
    if (this.state.ClinicImageFiles != undefined) {
      clinicLogo = this.state.ClinicImageFiles[0];
    }

    Httpconfig.httptokenpost(Constant.siteurl + "api/Clinic/", {
      clinic_name: fields["clinic_name"],
      address: fields["address"],
      state_code: fields["clinic_state"],
      city_name: fields["clinic_city"],
      city_code: fields["citycode"],
      pincode: fields["pincode"],
      clinic_phone_no: fields["clinic_phone_no"],
      email_id: fields["email_id"],
      password: fields["password"],
      latitude: fields["latitude"],
      longitude: fields["longitude"],
      kyc_status: fields["kyc_status"],
      clinic_registration_no: fields["clinic_registration_no"],
      clinic_type: fields["clinic_type"],
      timezone_name: fields["timezone_name"],

      speciality_id: this.state.selectedSpecialityId.toString(),
      speciality_name: this.state.selectedSpecialityName.toString(),

      selected_language_name: this.state.selectedLangName.toString(),
      selected_language_id: this.state.selectedLangId.toString(),

      clinic_pic: clinicLogo,
    })
      .then((response) => {
        if (response.status == 200) {
          toast.success("Successfully Created Clinic");
          setTimeout(
            () => this.props.history.push("/admin/ViewClinics"),
            10000
          );
        }
      })
      .catch((error) => {});
  };

  // updates controller
  updateClinic = (event) => {
    event.preventDefault();
    const { handle } = this.props.match.params;
    const { fields, errors } = this.state;

    let clinicLogo = "";
    if (this.state.ClinicImageFiles != undefined) {
      clinicLogo = this.state.ClinicImageFiles[0];
    }

    let selectedLang = "";
    if (this.state.selectedLangName == undefined) {
      let stateData = this.state.selectedValue;
      let names = stateData.map((x) => x.name);
      selectedLang = names.toString();
    } else {
      selectedLang = this.state.selectedLangName.toString();
    }

    let selectedLangIds = "";
    if (this.state.selectedLangId == undefined) {
      let stateIdData = this.state.selectedList;
      selectedLangIds = stateIdData.toString();
    } else {
      selectedLangIds = this.state.selectedLangId.toString();
    }

    let selectedSpeciality = "";
    if (this.state.selectedSpecialityName == undefined) {
      let stateData = this.state.speciality_selectedValue;
      let names = stateData.map((x) => x.category_type);
      selectedSpeciality = names.toString();
    } else {
      selectedSpeciality = this.state.selectedSpecialityName.toString();
    }

    let selectedSpecialityIds = "";
    if (this.state.selectedSpecialityId == undefined) {
      let stateIdData = this.state.speciality_selectedList;
      selectedSpecialityIds = stateIdData.toString();
    } else {
      selectedSpecialityIds = this.state.selectedSpecialityId.toString();
    }

    Httpconfig.httptokenput(Constant.siteurl + "api/Clinic/" + handle, {
      clinic_name: fields["clinic_name"],
      address: fields["address"],
      state_code: fields["clinic_state"],
      city_name: fields["clinic_city"],
      city_code: fields["citycode"],
      pincode: fields["pincode"],
      clinic_phone_no: fields["clinic_phone_no"],
      email_id: fields["email_id"],
      password: fields["password"],
      latitude: fields["latitude"],
      longitude: fields["longitude"],
      kyc_status: fields["kyc_status"],
      clinic_registration_no: fields["clinic_registration_no"],
      clinic_type: fields["clinic_type"],
      clinic_pic: clinicLogo,

      timezone_name: fields["timezone_name"],

      selected_language_id: selectedLangIds,
      selected_language_name: selectedLang,

      speciality_id: selectedSpecialityIds,
      speciality_name: selectedSpeciality,
    })
      .then((response) => {
        toast.success("Successfully Updated Clinic");
        setTimeout(() => this.props.history.push("/admin/ViewClinics"), 2000);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //  For handling the form
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    var pattern = /^[a-zA-Z0-9]{3,20}$/g;
    if (!fields["email_id"]) {
      formIsValid = false;
      errors["email_id"] = "Email cannot be empty";
    } else if (typeof fields["email_id"] !== "undefined") {
      let lastAtPos = fields["email_id"].lastIndexOf("@");
      let lastDotPos = fields["email_id"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email_id"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email_id"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email_id"] = "Email is invalid";
      }
    }

    if (!fields["clinic_phone_no"]) {
      formIsValid = false;
      errors["clinic_phone_no"] = "Phone number cannot be empty";
    } else if (fields["clinic_phone_no"].length < 10) {
      formIsValid = false;
      errors["clinic_phone_no"] = "Phone number invalid";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    } else if (
      fields["password"].length < 8 ||
      fields["password"].length > 20
    ) {
      formIsValid = false;
      errors["password"] = "Password shuold contain 8-20 characters";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20}$/.exec(
        fields["password"]
      )
    ) {
      formIsValid = false;
      errors["password"] =
        "Required one upper case, one small case, one number and one special character";
    }

    if (!fields["clinic_name"]) {
      formIsValid = false;
      errors["clinic_name"] = "Clinic name cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Clinic address cannot be empty";
    }
    if (!fields["clinic_state"]) {
      formIsValid = false;
      errors["clinic_state"] = "Clinic state cannot be empty";
    }
    if (!fields["clinic_city"]) {
      formIsValid = false;
      errors["clinic_city"] = "Clinic city cannot be empty";
    }
    if (!fields["citycode"]) {
      formIsValid = false;
      errors["citycode"] = "Clinic city code cannot be empty";
    } else if (fields["citycode"].length != 3) {
      formIsValid = false;
      errors["citycode"] = "Clinic city code should be three lettres";
    }
    if (!fields["pincode"]) {
      formIsValid = false;
      errors["pincode"] = "Clinic pincode cannot be empty";
    }

    if (!fields["latitude"]) {
      formIsValid = false;
      errors["latitude"] = "Clinic latitude cannot be empty";
    }
    if (!fields["longitude"]) {
      formIsValid = false;
      errors["longitude"] = "Clinic longitude cannot be empty";
    }
    if (!fields["kyc_status"]) {
      formIsValid = false;
      errors["kyc_status"] = "Clinic Kyc cannot be empty";
    }

    if (!fields["timezone_name"]) {
      formIsValid = false;
      errors["timezone_name"] = "Timezone cannot be empty";
    }

    this.setState({ errors: errors });

    return formIsValid;
  }
  //  For handling the address change
  handleAddressChange = (address) => {
    this.setState({ address });
  };

  //To fetch all states data
  getCompleteStates() {
    Httpconfig.httptokenget(
      Constant.siteurl + "api/Country/states/" + Constant.countryID
    )
      .then((response) => {
        this.setState({
          indianStates: response.data.data,
        });
      })
      .catch((error) => {});
  }

  //To fetch all cities data based on state id
  getCitiesbasedonStateId(stateId) {
    Httpconfig.httptokenget(Constant.siteurl + "api/Country/cities/" + stateId)
      .then((response) => {
        this.setState({
          indianCities: response.data.data,
        });
      })
      .catch((error) => {});
  }
  //  For handling address select
  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  render() {
    const { fields, errors, coupon_data, specialities_data, timezone } =
      this.state;
    let clinicLogoImage = "";
    if (this.state.fields.clinic_logo) {
      let imageName = this.state.fields.clinic_logo;
      const fileProfilepath = "./public/uploads/clinic/" + imageName;
      clinicLogoImage = constants.imgurl + imageName;
    }
    return (
      <section id="main_dashboard">
        <div className="container" id="main_front">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-section">
                <div className="section-header">
                  <ol className="breadcrumb">
                    <li className="active">
                      <Link to="/admin"> Dashboard</Link> &gt;
                      <a> Clinic </a>
                    </li>
                  </ol>
                </div>
              </div>
              <section id="CMS_tab">
                <div className="CMS_content">
                  <div className="container">
                    <div className="row">
                      <div className="tab-header">
                        <h3>{this.state.pageHeading} </h3>
                      </div>
                      <div id="reg_form">
                        <form onSubmit={this.checkSubmit.bind(this)}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Clinic Name</label>
                                <input
                                  type="text"
                                  name="clinic_name"
                                  value={this.state.fields["clinic_name"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_name"
                                  )}
                                  className="form-control"
                                  placeholder="Clinic Name"
                                />
                                <span className="cRed">
                                  {this.state.errors["clinic_name"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Select Timezone</label>
                                <select
                                  name="timezone_name"
                                  className="form-control"
                                  value={
                                    this.state.fields["timezone_name"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "timezone_name"
                                  )}
                                >
                                  <option value="">Select</option>
                                  {timezone &&
                                    timezone.map((timezone, i) => {
                                      return (
                                        <option value={timezone.value}>
                                          {timezone.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["timezone_name"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select Clinic Type</label>
                                <select
                                  name="clinic_type"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_type"
                                  )}
                                  value={this.state.fields["clinic_type"] || ""}
                                  className="form-control"
                                >
                                  <option value="">Select Clinic Type</option>
                                  <option value="Clinic">Clinic</option>
                                  <option value="Hospital">Hospital</option>
                                </select>
                                <span className="cRed">
                                  {this.state.errors["clinic_type"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select Verification Status</label>
                                <select
                                  name="kyc_status"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "kyc_status"
                                  )}
                                  value={this.state.fields["kyc_status"] || ""}
                                  className="form-control"
                                >
                                  <option value="">Select KYC Status</option>
                                  <option value="Updated">Updated</option>
                                  <option value="Not Updated">
                                    Not Updated
                                  </option>
                                </select>
                                <span className="cRed">
                                  {this.state.errors["kyc_status"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Latitude</label>
                                <input
                                  type="text"
                                  name="latitude"
                                  value={this.state.fields["latitude"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "latitude"
                                  )}
                                  className="form-control"
                                  placeholder="Latitude"
                                />
                                <span className="cRed">
                                  {this.state.errors["latitude"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Longitude</label>
                                <input
                                  type="text"
                                  name="longitude"
                                  value={this.state.fields["longitude"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "longitude"
                                  )}
                                  className="form-control"
                                  placeholder="Longitude"
                                />
                                <span className="cRed">
                                  {this.state.errors["longitude"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Address</label>
                                <input
                                  type="text"
                                  name="address"
                                  value={this.state.fields["address"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "address"
                                  )}
                                  className="form-control"
                                  placeholder="Address"
                                />
                                <span className="cRed">
                                  {this.state.errors["address"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select State</label>
                                <select
                                  name="clinic_state"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_state"
                                  )}
                                  value={
                                    this.state.fields["clinic_state"] || ""
                                  }
                                  className="form-control"
                                >
                                  <option value="" disabled>
                                    Select Clinic State
                                  </option>
                                  {this.state.indianStates.map((stateList) => {
                                    return (
                                      <option value={stateList.id}>
                                        {stateList.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["clinic_state"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select City</label>
                                <select
                                  name="clinic_city"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_city"
                                  )}
                                  value={this.state.fields["clinic_city"] || ""}
                                  className="form-control"
                                >
                                  <option value="" disabled>
                                    Select Clinic City
                                  </option>
                                  {this.state.indianCities.map(
                                    (cityData, i) => {
                                      return (
                                        <option value={cityData.name}>
                                          {cityData.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                <span className="cRed">
                                  {this.state.errors["clinic_city"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>City Code</label>
                                <input
                                  type="text"
                                  name="citycode"
                                  value={this.state.fields["citycode"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "citycode"
                                  )}
                                  className="form-control"
                                  placeholder="City Code"
                                />
                                <span className="cRed">
                                  {this.state.errors["citycode"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Pin Code</label>
                                <input
                                  type="text"
                                  name="pincode"
                                  value={this.state.fields["pincode"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "pincode"
                                  )}
                                  className="form-control"
                                  placeholder="Pincode"
                                />
                                <span className="cRed">
                                  {this.state.errors["pincode"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Phone Number</label>
                                <input
                                  type="text"
                                  name="clinic_phone_no"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  minLength="10"
                                  maxlength="10"
                                  value={
                                    this.state.fields["clinic_phone_no"] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_phone_no"
                                  )}
                                  className="form-control"
                                  placeholder="Phone Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["clinic_phone_no"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  autoComplete="new-password"
                                  value={this.state.fields["email_id"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "email_id"
                                  )}
                                  className="form-control"
                                  placeholder="Email Id"
                                />
                                <span className="cRed">
                                  {this.state.errors["email_id"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Password</label>
                                <input
                                  type="password"
                                  name="password"
                                  autoComplete="new-password"
                                  value={this.state.fields["password"] || ""}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "password"
                                  )}
                                  className="form-control"
                                  placeholder="Password"
                                />
                                <span className="cRed">
                                  {this.state.errors["password"]}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select Specialities</label>
                                <Multiselect
                                  onChange={this.handleChange.bind(
                                    this,
                                    "specialities"
                                  )}
                                  name="specialities"
                                  options={this.state.speciality_options}
                                  value={this.state.speciality_selectedList}
                                  selectedValues={
                                    this.state.speciality_selectedValue
                                  }
                                  onSelect={this.onSpecialitySelect}
                                  placeholder="Select Specialities"
                                  onRemove={this.onSpecialityRemove}
                                  displayValue="category_type"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Registration Number</label>
                                <input
                                  type="text"
                                  name="clinic_registration_no"
                                  value={
                                    this.state.fields[
                                      "clinic_registration_no"
                                    ] || ""
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "clinic_registration_no"
                                  )}
                                  className="form-control"
                                  placeholder="Registration Number"
                                />
                                <span className="cRed">
                                  {this.state.errors["clinic_registration_no"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group ">
                                <label>Select Languages</label>
                                <Multiselect
                                  onChange={this.handleChange.bind(
                                    this,
                                    "languages"
                                  )}
                                  name="languages"
                                  options={this.state.options}
                                  value={this.state.selectedList || ""}
                                  selectedValues={this.state.selectedValue}
                                  onSelect={this.onSelect}
                                  placeholder="Select Languages"
                                  onRemove={this.onRemove}
                                  displayValue="name"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div class="col-md-4">
                              <div className="form-group ">
                                <FileBase64
                                  multiple={true}
                                  onDone={this.getClinicImageFile.bind(this)}
                                />
                                <span className="cRed">
                                  {this.state.errors["clinic_logo"]}
                                </span>
                                {clinicLogoImage ? (
                                  <img
                                    src={clinicLogoImage}
                                    style={{ height: "100px", width: "140px" }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-12">
                              <button
                                type="submit"
                                className="btn  btn-primary save_btn"
                              >
                                Save Clinic
                              </button>{" "}
                              &nbsp;
                              <Link
                                to="/admin/ViewClinics"
                                className="cancel_btn"
                              >
                                Cancel
                              </Link>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
